import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {fetchCreateTwin, fetchDeleteTwin, fetchGetTwins, fetchNextPage, fetchUpdateTwin} from '@fetch/index'
import {TwinsResponse} from '@fetch/SameNamesAPI/SameNamesAPI.types'
import {SameNamesInputs} from './SameNamesInputs'
import {List} from './List'
import {ListSameNamesProps} from './ListSameNamesProps'
import {InputsContainer, ListContainer, ScrollableListContainer} from './ListSameNames.styles'

/**
 * Список тезок
 */
const ListSameNames: FC<ListSameNamesProps> = () => {
  const [isOrganization, setIsOrganization] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [firstNameValue, setFirstNameValue] = useState('')
  const [lastNameValue, setLastNameValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  const [isEmpty, setIsEmpty] = useState(true)
  const [twins, setTwins] = useState<TwinsResponse>({} as TwinsResponse)
  const [idTwinUpdate, setIdTwinUpdate] = useState('')

  useEffect(() => {
    fetchGetTwins().then(data => {
      if (data) {
        setTwins(data)
      }
    })
  }, [])

  const [initialValues, setInitialValues] = useState({
    isOrganization: false,
    firstName: '',
    lastName: '',
    description: ''
  })

  const hasChanges = useMemo(() => {
    if (!isUpdate) return false

    return (
      initialValues.isOrganization !== isOrganization ||
      initialValues.firstName !== firstNameValue ||
      initialValues.lastName !== lastNameValue ||
      initialValues.description !== descriptionValue
    )
  }, [isUpdate, initialValues, isOrganization, firstNameValue, lastNameValue, descriptionValue])

  const handleEmptyInputs = () => {
    setFirstNameValue('')
    setLastNameValue('')
    setDescriptionValue('')
    setIsEmpty(true)
  }

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, action: 'firstName' | 'lastName' | 'description') => {
      // Получаем новое значение из события
      const newValue = e.target.value

      // Обновляем соответствующее поле
      if (action === 'firstName') setFirstNameValue(newValue)
      if (action === 'lastName') setLastNameValue(newValue)
      if (action === 'description') setDescriptionValue(newValue)

      // Проверяем заполненность всех полей с УЧЕТОМ НОВОГО ЗНАЧЕНИЯ
      const allFieldsFilled =
        (action === 'firstName' ? newValue : firstNameValue || isOrganization) &&
        (action === 'lastName' ? newValue : lastNameValue) &&
        (action === 'description' ? newValue : descriptionValue)

      setIsEmpty(!allFieldsFilled)
    },
    [firstNameValue, lastNameValue, descriptionValue, isOrganization] // Зависимости от значений полей
  )

  const onClickCreate = useCallback(() => {
    setIsLoading(true)
    fetchCreateTwin({first_name: firstNameValue, last_name: lastNameValue, description: descriptionValue})
      .then(data => {
        if (data) {
          setTwins(prev => ({...prev, results: [data, ...prev.results]}))
          handleEmptyInputs()
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [firstNameValue, lastNameValue, descriptionValue])

  const onCLickUpdate = useCallback(() => {
    setIsLoading(true)
    fetchUpdateTwin({first_name: firstNameValue, last_name: lastNameValue, description: descriptionValue}, idTwinUpdate)
      .then(data => {
        if (data) {
          setTwins(prev => {
            const index = prev.results.findIndex(twin => twin.id === idTwinUpdate)

            const newResults = [...prev.results]
            if (index !== -1) {
              newResults[index] = data
            }

            return {...prev, results: newResults}
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsUpdate(false)
        handleEmptyInputs()
      })
  }, [firstNameValue, lastNameValue, descriptionValue, idTwinUpdate])

  const onCancelUpdate = useCallback(() => {
    setIsUpdate(false)
    setIsOrganization(false)
    setIdTwinUpdate('')
    handleEmptyInputs()
  }, [])

  const onClickListItem = useCallback(
    (id: string) => {
      setIsUpdate(true)
      setIdTwinUpdate(id)
      const newListItem = twins.results.filter(item => item.id === id)
      const isOrg = !newListItem[0].first_name

      setInitialValues({
        isOrganization: isOrg,
        firstName: newListItem[0].first_name || '',
        lastName: newListItem[0].last_name,
        description: newListItem[0].description
      })

      if (newListItem[0].first_name) {
        setFirstNameValue(newListItem[0].first_name)
        setIsOrganization(false)
      } else {
        setIsOrganization(true)
      }
      setLastNameValue(newListItem[0].last_name)
      setDescriptionValue(newListItem[0].description)
    },
    [twins]
  )

  const handleDelete = useCallback((e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation()
    setIsLoading(true)
    fetchDeleteTwin(id)
      .then(data => {
        if (data === null) {
          return
        }
        setTwins(prev => ({...prev, results: [...prev.results.filter(twin => twin.id !== id)]}))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const addNewUsers = useCallback((nextPage: string) => {
    setIsLoading(true)
    fetchNextPage(nextPage[nextPage.length - 1])
      .then(data => {
        if (data) {
          setTwins(prev => ({
            ...prev,
            results: [...prev.results, ...data.results],
            next: data.next
          }))
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (isOrganization) {
      setFirstNameValue('')
    }
  }, [isOrganization])

  const handleOrganization = () => {
    setIsOrganization(prev => !prev)
    handleEmptyInputs()
  }

  return (
    <ListContainer>
      <InputsContainer>
        <SameNamesInputs
          isUpdate={isUpdate}
          isLoading={isLoading}
          isOrganization={isOrganization}
          setIsOrganization={handleOrganization}
          firstNameValue={firstNameValue}
          lastNameValue={lastNameValue}
          descriptionValue={descriptionValue}
          handleChange={handleChange}
          isEmpty={isEmpty}
          onClickCreate={onClickCreate}
          onClickUpdate={onCLickUpdate}
          onCancelUpdate={onCancelUpdate}
          hasChanges={hasChanges}
        />
      </InputsContainer>
      <ScrollableListContainer>
        <List
          isLoading={isLoading}
          listItems={twins.results}
          nextPage={twins.next}
          addNewUsers={addNewUsers}
          onClickListItem={onClickListItem}
          handleDelete={handleDelete}
        />
      </ScrollableListContainer>
    </ListContainer>
  )
}

export default ListSameNames
