import {FormControl, FormHelperText, InputLabel} from '@mui/material'
import {useKeycloak} from '@react-keycloak/web'
import {redColor, warnColor} from '@theme/vars'
import {ValidationStatus} from '@utils/models/errorStatus'
import {validateStringByMaxLength} from '@utils/validation/validateByStringMaxLength'
import {FC, useEffect, useState} from 'react'
import {YANDEX_DZEN_TITLE_CONSTS} from './YandexDzenTitle.consts'
import {OutlinedInputWrapper, TitleLength} from './YandexDzenTitle.styles'
import {YandexDzenTitleProps} from './YandexDzenTitleProps'

const YandexDzenTitle: FC<YandexDzenTitleProps> = ({
  titleLabel,
  field,
  maxErrorLength,
  maxWarningLength,
  setTextError
}) => {
  const [validateStatus, setValidateStatus] = useState(ValidationStatus.Default)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const validateStringByMaxLengthRes = validateStringByMaxLength(
      field?.value,
      maxErrorLength,
      maxWarningLength,
      YANDEX_DZEN_TITLE_CONSTS.maxErrorMessage,
      YANDEX_DZEN_TITLE_CONSTS.maxWarningMessage
    )
    setValidateStatus(validateStringByMaxLengthRes.status)
    setMessage(validateStringByMaxLengthRes.message)
    setTextError(
      validateStringByMaxLengthRes.status === ValidationStatus.Error ? validateStringByMaxLengthRes.message : ''
    )
  }, [field, maxErrorLength, maxWarningLength, setTextError])

  const {keycloak} = useKeycloak()

  return (
    <FormControl
      fullWidth
      style={{
        marginTop: '16px'
      }}
    >
      <div
        style={{
          flexDirection: 'row',
          color:
            validateStatus === ValidationStatus.Error
              ? redColor
              : validateStatus === ValidationStatus.Warning
              ? warnColor
              : 'inherit'
        }}
      >
        <InputLabel
          style={{
            width: 'calc(100% - 70px)'
          }}
        >
          {titleLabel}
        </InputLabel>
        <OutlinedInputWrapper
          label={titleLabel}
          {...field}
          error={!!message}
          multiline
          disabled={false}
          className={validateStatus === ValidationStatus.Warning ? 'yandex-dzen-warning' : ''}
          style={{
            color:
              validateStatus === ValidationStatus.Error
                ? redColor
                : validateStatus === ValidationStatus.Warning
                ? warnColor
                : 'inherit'
          }}
        />
        <div>
          <TitleLength
            style={{
              display: 'flex',
              float: 'right',
              color:
                validateStatus === ValidationStatus.Error
                  ? redColor
                  : validateStatus === ValidationStatus.Warning
                  ? warnColor
                  : 'inherit'
            }}
          >
            {`${field?.value?.length || 0} / ${maxWarningLength}`}
          </TitleLength>
          {!!message && (
            <FormHelperText
              style={{
                float: 'left',
                color: validateStatus === ValidationStatus.Error ? redColor : warnColor
              }}
            >
              {message}
            </FormHelperText>
          )}
        </div>
      </div>
    </FormControl>
  )
}

export default YandexDzenTitle
