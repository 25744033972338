import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {ArrowRight} from '@mui/icons-material'
import {Tooltip} from '@mui/material'
import {format} from 'date-fns'
import CloseIcon from '@components/UI/Icons/CloseIcon'
import {PublicationsEdge, PublicationsSort, PublicationStatus} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {ReportListTableFields} from '../../../types/ReportListTableFields'
import {REPORTS_LIST_TABLE_CONSTS} from './ReportsListTable.consts'
import {
  ReportsListTableWrapper,
  RestoreAllFieldButton,
  RestoreFieldButton,
  RestoreFieldButtonsWrapper,
  TableWrapper
} from './ReportsListTable.styles'
import ReportListTableBody from './ReportListTableBody/ReportListTableBody'
import {ReportListTableHead} from './ReportListTableHead/ReportListTableHead'
import {ReportsListTableDataProps} from './ReportsListTableDataProps'
import {useReportPublicationsLazyQuery} from './gql/ReportsListTable.generated'
import {publicationsDataPrefer} from './hooks/publicationsDataPrefer'
import {exportToFile} from './hooks/exportToFile'
import {getMetrics} from './hooks/getMetrics'
import {getPublicationsSortByFieldName} from './hooks/getPublicationsSortByFieldName'
import {getFullMetrics} from './hooks/getFullMetrics'

/**
 * Компонент отрисовки таблицы списка публикаций с метриками на странице "Отчеты"
 * @param param0
 * @returns
 */
const ReportsListTable: FC = () => {
  const defaultFieldValues = useMemo<ReportListTableFields>(
    () => ({
      date: true,
      time: true,
      title: true,
      seoTitle: true,
      rubricAndSubrubric: true,
      authors: true,
      viewsCount: true,
      uniqueUsersCount: true,
      fromSocialNetworks: true,
      fromSocialNetworksPercent: true,
      withoutRecommendedSystems: true
    }),
    []
  )
  const {reportsListStore, tableStore} = useStore()
  const {
    clickExportBtnCounter,
    clickSearchBtnCounter,
    loadingData,
    setLoadingExport,
    setLoadingData,
    fileFormat,
    filters,
    processedRowsCount,
    exportListData,
    setIsEmptyList,
    setExportProgress,
    setProcessedRowsCount,
    setExportListData
  } = reportsListStore
  const {sortFieldName, sortDirection} = tableStore

  const [publicationsList, setPublicationsList] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [fields, setFields] = useState(defaultFieldValues)
  const [showRevertAllBtn, setShowRevertAllBtn] = useState(false)
  const [endCursor, setEndCursor] = useState<string | undefined>()
  const [hasMore, setHasMore] = useState(true)
  const [dataLength, setDataLength] = useState(0)
  const [publicationsData, setPublicationsData] = useState<PublicationsEdge[]>([])
  const [scrollPosition, setScrollPosition] = useState(0)
  const [sortField, setSortField] = useState<PublicationsSort>(PublicationsSort.PublishedAtDesc)
  // const [curFileFormat, setCurFileFormat] = useState<string>(fileFormat)

  // useEffect(() => {
  //   setCurFileFormat(fileFormat)
  // }, [fileFormat])

  useEffect(() => {
    const newSortField = getPublicationsSortByFieldName(sortFieldName, sortDirection)
    setSortField(newSortField)
  }, [sortFieldName, sortDirection])

  const getPublicationsListCallback = useCallback(
    data => {
      setPublicationsData((data.publications?.edges || []) as PublicationsEdge[])
      setIsEmptyList(data.publications?.edges?.length && data.publications?.edges?.length > 0 ? false : true)
      setEndCursor(data.publications?.pageInfo.endCursor || '')
      setHasMore(data?.publications?.pageInfo.hasNextPage || false)
      setDataLength(data?.publications?.edges.length || 0)
    },
    [setIsEmptyList]
  )

  const [getPublicationsList] = useReportPublicationsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      getPublicationsListCallback(data)
    },
    notifyOnNetworkStatusChange: true
  })

  const exportPublicationsListCallback = useCallback(
    async data => {
      setIsEmptyList(data.publications?.edges?.length && data.publications?.edges?.length > 0 ? false : true)
      setEndCursor(data.publications?.pageInfo.endCursor || '')
      setHasMore(data?.publications?.pageInfo.hasNextPage || false)
      setDataLength(data?.publications?.edges.length || 0)
      setTotalCount(data.publications?.totalCount || 0)

      const res = publicationsDataPrefer(data.publications?.edges || [])

      if (res) {
        const publicationsCountInRequest = Object.keys(res.publications).length

        if (publicationsCountInRequest > 0) {
          const metricList = await getMetrics(res.publications, res.publicationListData, filters)

          const newsExportListData = [...exportListData, ...metricList]

          setExportListData(newsExportListData)
          processMetricResult(
            newsExportListData,
            publicationsCountInRequest,
            true,
            data.publications?.totalCount || 0,
            processedRowsCount
          )

          if (data?.publications?.pageInfo.hasNextPage || false) {
            exportData(data.publications?.pageInfo.endCursor || '')
          }
        }
      }
    },
    [filters, processedRowsCount, exportListData, setExportListData]
  )

  const [exportPublicationsList] = useReportPublicationsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      exportPublicationsListCallback(data)
    },
    notifyOnNetworkStatusChange: true
  })

  const processMetricResult = useCallback(
    (
      list: ReportsListTableDataProps[],
      publicationsCountInRequest: number,
      forExport: boolean,
      totalCount,
      processedRowsCount: number
    ) => {
      if (list) {
        const newProcessedRowsCount = processedRowsCount + publicationsCountInRequest
        if (forExport) {
          const progressValue: number =
            newProcessedRowsCount > totalCount ? 100 : +(newProcessedRowsCount / totalCount).toFixed(2) * 100

          setProcessedRowsCount(newProcessedRowsCount)

          setExportProgress(Math.floor(progressValue))

          if (totalCount > 0 && newProcessedRowsCount === totalCount) {
            const fileName = `report_${format(new Date(filters.datePublishedFrom || ''), 'yyyy-MM-dd')}_${format(
              new Date(filters.datePublishedTo || ''),
              'yyyy-MM-dd'
            )}`
            exportToFile(list, fields, fileName, fileFormat)
            if (progressValue === 100) {
              setLoadingExport(false)
            }
          }

          return
        }
        setProcessedRowsCount(newProcessedRowsCount)
        setPublicationsList(prev => [...prev, ...list])
        setLoadingData(false)
      }
    },
    [fields, filters, fileFormat, setProcessedRowsCount, setLoadingData, setLoadingExport, setExportProgress]
  )

  useEffect(() => {
    const res = publicationsDataPrefer(publicationsData.slice(processedRowsCount))
    if (res) {
      const publicationsCountInRequest = Object.keys(res.publications).length

      if (publicationsCountInRequest > 0) {
        getMetrics(res.publications, res.publicationListData, filters).then(data => {
          processMetricResult(data, publicationsCountInRequest, false, totalCount, processedRowsCount)
        })
      }
    }
  }, [publicationsData, filters, processedRowsCount])

  const search = useCallback(
    (endCursor?: string) => {
      if (
        [
          'viewsCount',
          'uniqueUsersCount',
          'fromSocialNetworkCount',
          'fromSocialNetworksPercent',
          'withoutRecommendedSystems'
        ].includes(sortFieldName)
      ) {
        getFullMetrics(filters.viewsDateFrom, filters.viewsDateTo, sortField).then(data => console.log(data))
      } else {
        setLoadingData(true)
        const variables = {
          status: PublicationStatus.Published,
          authorUid: filters.authorUid,
          datePublishedFrom: format(new Date(filters.datePublishedFrom), 'yyyy-MM-dd'),
          datePublishedTo: format(new Date(filters.datePublishedTo), 'yyyy-MM-dd'),
          regionUid: filters.regionUid,
          rubric: filters.rubricUids,
          subrubric: filters.subrubricUids,
          sort: sortField
        }

        if (endCursor) {
          variables['after'] = endCursor
        } else {
          setScrollPosition(0)
        }
        getPublicationsList({variables})
      }
    },
    [filters, sortField, sortFieldName, setLoadingData, getPublicationsList]
  )

  const exportData = useCallback(
    async (endCursor?: string) => {
      setLoadingExport(true)

      const variables = {
        status: PublicationStatus.Published,
        authorUid: filters.authorUid,
        datePublishedFrom: format(new Date(filters.datePublishedFrom), 'yyyy-MM-dd'),
        datePublishedTo: format(new Date(filters.datePublishedTo), 'yyyy-MM-dd'),
        regionUid: filters.regionUid,
        rubric: filters.rubricUids,
        subrubric: filters.subrubricUids,
        sort: sortField
      }

      setScrollPosition(0)

      if (endCursor) {
        variables['after'] = endCursor
      }
      await exportPublicationsList({variables})
    },
    [filters, sortField, setLoadingExport, exportPublicationsList]
  )

  const fetchMore = useCallback(() => {
    search(endCursor)
  }, [endCursor])

  const restoreAll = useCallback(() => {
    setFields(defaultFieldValues)
  }, [defaultFieldValues])

  useEffect(() => {
    let uncheckedCount = 0

    Object.keys(fields).forEach(item => {
      if (fields[item] == false) {
        uncheckedCount++
      }
    })
    if (uncheckedCount > 2) {
      setShowRevertAllBtn(true)
    } else {
      setShowRevertAllBtn(false)
    }
  }, [fields])

  useEffect(() => {
    if (clickSearchBtnCounter > 0) {
      setProcessedRowsCount(0)
      // setItemProcessedCount(0)
      setPublicationsList([])
      setTotalCount(0)
      setHasMore(false)
      setDataLength(0)
      setPublicationsData([])
      setExportListData([])
      setExportProgress(0)
      search()
    }
  }, [clickSearchBtnCounter])

  useEffect(() => {
    if (clickExportBtnCounter > 0) {
      setProcessedRowsCount(0)
      // setItemProcessedCount(0)
      setPublicationsList([])
      setTotalCount(0)
      setHasMore(false)
      setDataLength(0)
      setPublicationsData([])
      setExportListData([])
      setExportProgress(0)
      exportData()
    }
  }, [clickExportBtnCounter])

  return (
    <ReportsListTableWrapper>
      <RestoreFieldButtonsWrapper>
        {Object.keys(fields).map(key => (
          <div key={key}>
            {fields[key] === false && (
              <Tooltip title={REPORTS_LIST_TABLE_CONSTS.restoreColumn}>
                <RestoreFieldButton onClick={() => setFields(prev => ({...prev, [key]: true}))}>
                  <div>{REPORTS_LIST_TABLE_CONSTS[key]}</div>
                  <CloseIcon />
                </RestoreFieldButton>
              </Tooltip>
            )}
          </div>
        ))}
        {showRevertAllBtn && (
          <div>
            <Tooltip title={REPORTS_LIST_TABLE_CONSTS.restoreAllColumns}>
              <RestoreAllFieldButton onClick={restoreAll}>
                <div>{REPORTS_LIST_TABLE_CONSTS.restoreAll}</div>
                <ArrowRight />
              </RestoreAllFieldButton>
            </Tooltip>
          </div>
        )}
      </RestoreFieldButtonsWrapper>
      <TableWrapper style={{borderSpacing: 0}}>
        <ReportListTableHead fields={fields} setFields={setFields} />
        <ReportListTableBody
          fields={fields}
          publicationList={publicationsList}
          dataLength={dataLength}
          hasMore={hasMore}
          fetchMore={fetchMore}
          setScrollPosition={setScrollPosition}
        />
      </TableWrapper>
      <div id='for-export-container'></div>
    </ReportsListTableWrapper>
  )
}

export default observer(ReportsListTable)
