import {CommentsSort} from '@graphql/types'
import {SortOrder} from '@types/SortOrder'

/**
 * @description Возвращает поле сортировки для запроса комментариев по публикации
 * @param fieldName - имя поля сортировки
 * @param sortDirection - направление сортировки
 * @returns поле сортировки
 */
const getCommentsGroupByPublicationUidSortByFieldName = (fieldName: string, sortDirection: SortOrder): CommentsSort => {
  switch (fieldName) {
    case 'allComments': {
      return sortDirection === SortOrder.Asc ? CommentsSort.GroupByAskTc : CommentsSort.GroupByDescTc
    }
    case 'newComments': {
      return sortDirection === SortOrder.Asc ? CommentsSort.GroupByAscRc : CommentsSort.GroupByDescRc
    }
    default: {
      return CommentsSort.GroupByDescRc
    }
  }
}

export {getCommentsGroupByPublicationUidSortByFieldName}
