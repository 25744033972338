import {Button, Menu, MenuItem} from '@mui/material'
import React, {FC, useState, MouseEvent, useCallback} from 'react'
import {EXPORT_TO_FILE_CONSTS} from './ExportToFile.consts'
import exportFromJSON, {ExportType} from 'export-from-json'
import {ExportToFileButtonProps} from './ExportToFileButtonProps'

export const ExportToFileButton: FC<ExportToFileButtonProps> = ({disabled, setFileFormat}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const exportToFileInFormat = useCallback(
    (format: string) => {
      setFileFormat(format)
      handleClose()
    },
    [setFileFormat]
  )

  return (
    <>
      <Button
        disabled={disabled}
        id='export-to-file-button'
        variant='outlined'
        color='primary'
        size='small'
        aria-controls={open ? 'export-to-file-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          height: '42px',
          width: '100%'
        }}
      >
        {EXPORT_TO_FILE_CONSTS.export}
      </Button>
      <Menu
        id='export-to-file-menu'
        aria-labelledby='export-to-file-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={() => exportToFileInFormat(exportFromJSON.types.csv)}>
          {EXPORT_TO_FILE_CONSTS.inCSV}
        </MenuItem>
        <MenuItem onClick={() => exportToFileInFormat('pdf')}>{EXPORT_TO_FILE_CONSTS.inPDF}</MenuItem>
        <MenuItem onClick={() => exportToFileInFormat(exportFromJSON.types.xls)}>
          {EXPORT_TO_FILE_CONSTS.inXLS}
        </MenuItem>
        <MenuItem onClick={() => exportToFileInFormat(exportFromJSON.types.xml)}>
          {EXPORT_TO_FILE_CONSTS.inXML}
        </MenuItem>
        <MenuItem onClick={() => exportToFileInFormat('docx')}>{EXPORT_TO_FILE_CONSTS.inWord}</MenuItem>
      </Menu>
    </>
  )
}
