import React, {FC, useCallback, useState} from 'react'
import {Box, LinearProgress} from '@mui/material'
import {useSnackbar} from 'notistack'
import {NetworkStatus} from '@apollo/client'
import {CreateOverviewBlockInput, OverviewBlock, UpdateOverviewBlockInput} from '@graphql/types'
import MaterialUrl from '../../MaterialUrl/MaterialUrl'
import DayTheme from '../DayTheme/DayTheme'
import OverviewGridDrop from '../OverviewGridDrop/OverviewGridDrop'
import OverviewBlockModal from '../OverviewBlockModal/OverviewBlockModal'
import {ModalMode} from '../../../../models/models'
import {DataOverviewModalProps} from '../OverviewBlockModal/DataOverviewModalProps'
import {
  useCreateOverviewBlockMutation,
  useMoveOverviewBlockMutation,
  useOverviewBlocksQuery,
  useRemoveOverviewBlockMutation,
  useUpdateOverviewBlockMutation
} from './gql/OverviewContent.generated'
import {Header, Wrapper} from './OverviewContent.styles'

type OverviewContent = {
  regionId?: string
}

const OverviewContent: FC<OverviewContent> = ({regionId}) => {
  const [url, setUrl] = useState('')
  const [overviewBlocks, setOverviewBlocks] = useState<OverviewBlock[]>([])
  const [isOpenEditorModal, setIsOpenEditorModal] = useState(false)
  const [dataModal, setDataModal] = useState({} as DataOverviewModalProps)

  const overview = useOverviewBlocksQuery({
    variables: {regionId},
    onCompleted: data =>
      setOverviewBlocks(data.overviewBlocks?.edges.map(edge => edge.node).slice() as OverviewBlock[]),
    fetchPolicy: 'cache-and-network'
  })

  const [createOverviewBlock] = useCreateOverviewBlockMutation()
  const [updateOverviewBlock] = useUpdateOverviewBlockMutation()
  const [deleteOverviewBlock] = useRemoveOverviewBlockMutation()
  const [moveOverviewBlock] = useMoveOverviewBlockMutation()

  const snackbar = useSnackbar()

  const handleCreateBlockModal = useCallback(async () => {
    try {
      const block = await createOverviewBlock({
        variables: {
          data: {
            url: url,
            figureVersionId: null,
            regionId: null,
            subtitle: '',
            title: ''
          } as CreateOverviewBlockInput
        }
      })
      setDataModal({
        mode: 'create',
        data: block.data?.createOverviewBlock?.block as OverviewBlock
      })
      setIsOpenEditorModal(true)
    } catch (e) {
      console.error(e)
    }
  }, [createOverviewBlock, snackbar, url])

  const handleEditBlockModal = useCallback(
    (uid: string) => {
      const dataBlock = overviewBlocks.find(block => block.uid === uid)
      setDataModal({mode: 'edit', data: dataBlock as OverviewBlock})
      setIsOpenEditorModal(true)
    },
    [overviewBlocks]
  )

  const handleDeleteBlock = useCallback(
    (blockId: string) => {
      try {
        void deleteOverviewBlock({
          variables: {overviewBlockId: blockId},
          refetchQueries: ['overviewBlocks'],
          awaitRefetchQueries: true
        })
      } catch (e) {
        console.error(e)
      }
    },
    [deleteOverviewBlock, snackbar]
  )

  const handleCloseModal = useCallback(() => {
    setIsOpenEditorModal(false)
    setDataModal({} as DataOverviewModalProps)
  }, [])

  const handleUpdateBlock = useCallback(
    async (data: OverviewBlock, mode: ModalMode) => {
      setIsOpenEditorModal(false)
      try {
        await updateOverviewBlock({
          variables: {
            data: {
              regionId: mode === 'create' ? regionId : null,
              overviewBlockId: data.uid,
              title: data.title,
              subtitle: data.subtitle,
              figureUid: data.figureVersion?.figure?.uid
            } as UpdateOverviewBlockInput
          },
          refetchQueries: ['overviewBlocks']
        })

        snackbar.enqueueSnackbar('Блок сохранен', {
          variant: 'success'
        })
        if (mode === 'create') {
          setUrl('')
        }
      } catch (e) {
        console.error(e)
      }
    },
    [regionId, snackbar, updateOverviewBlock]
  )

  const onChangeGridDrop = useCallback(
    (nextState: OverviewBlock[], overviewBlockId: string, position: number) => {
      try {
        void moveOverviewBlock({
          variables: {data: {overviewBlockId, position: position}}
        })
        setOverviewBlocks(nextState)
      } catch (e) {
        console.error(e)
      }
    },
    [moveOverviewBlock, snackbar]
  )

  if (overview.networkStatus === NetworkStatus.loading) return <LinearProgress />

  return (
    <Wrapper>
      <Header>
        <MaterialUrl url={url} setUrl={setUrl} handleAddMaterial={handleCreateBlockModal} />
      </Header>
      <Box display='flex'>
        <OverviewGridDrop
          overviewBlocks={overviewBlocks}
          handleDeleteBlock={handleDeleteBlock}
          handleOpenEditorCardModal={handleEditBlockModal}
          onChangeGridDrop={onChangeGridDrop}
        />
        <DayTheme regionId={regionId} />
      </Box>
      <OverviewBlockModal
        open={isOpenEditorModal}
        onClose={handleCloseModal}
        dataModal={dataModal}
        handleUpdateBlock={handleUpdateBlock}
      />
    </Wrapper>
  )
}

export default OverviewContent
