import PageContainer from '@components/UI/PageContainer/PageContainer'
import React, {FC, useEffect, useState} from 'react'
import ReferenceContent from '@components/Reference/ReferenceContent'
import {useSnackbar} from 'notistack'

const ReferencePage: FC = () => {
  const [references, setReferences] = useState([])
  const snackbar = useSnackbar()

  useEffect(() => {
    try {
      fetch(`${process.env.REACT_APP_REFERENCE}`)
        .then(result => result.json())
        .then(res => setReferences(res))
    } catch {
      snackbar.enqueueSnackbar(`Справка временно недоступна, обратитетсь в IT отдел`, {variant: 'error'})
    }
  }, [snackbar])
  return (
    <PageContainer>
      <ReferenceContent references={references} />
    </PageContainer>
  )
}

export default ReferencePage
