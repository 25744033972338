import styled from 'styled-components'
import {blackColor, blueColor, errorColor} from '../../../../../@theme/vars'

export const LinkWrapper = styled.div`
  padding-right: 8px;
  width: 380px;
  height: 133px;
`
export const MainHeaderWrapper = styled.div`
  color: ${blackColor};
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 8px;
`
export const HeaderWrapper = styled.div`
  margin: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${errorColor};
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${blueColor};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 5px;
  letter-spacing: 0px;
`
