import React, {FC} from 'react'

const ActiveSortIcon: FC<any> = () => {
  return (
    <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 6.22266L6 0.222656L0 6.22266L12 6.22266Z' fill='#1964E7' />
    </svg>
  )
}

export default ActiveSortIcon
