import React, {FC, useState, useCallback} from 'react'
import Tooltip from '@components/UI/Tooltip/Tooltip'
import TextIcon from '@components/UI/Icons/TextIcon'
import ChevronIcon from '@components/UI/Icons/Chevron'
import {MenuItem, Typography} from '@mui/material'
import {StyledMenu} from './ToolbarSize.styles'
import {StyledToggleButton} from '../Toolbar.styles'
import {SizeItem} from './SizeItem'
import {TOOLBAR_SIZE_CONSTS} from './ToolbarSize.consts'
import {Quill} from 'react-quill'

type ToolbarVideoProps = {
  editorRef: any
}

const ToolbarSize: FC<ToolbarVideoProps> = ({editorRef}) => {
  const [selectedSize, setSelectedSize] = useState({
    color: '',
    size: '',
    title: 'T'
  } as SizeItem)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const setSize = useCallback(
    (size: string, color: string, title: string) => {
      if (editorRef?.current) {
        const quill = (editorRef.current as any).getEditor()
        const range = quill.getSelection(true)
        if (range) {
          setTimeout(() => {
            const selectingText = quill.getText(range.index || 0, range.length || 0) || ''

            if (title === 'Скрыть в RSS') {
              const selectionNode = document.getSelection()

              if (selectionNode?.focusNode) {
                const blot = Quill.find(selectionNode?.focusNode)
                const index = blot.offset(quill.scroll)

                quill.formatText(
                  index,
                  selectionNode?.focusNode?.textContent?.length || 0,
                  'textrssblock',
                  {
                    text: selectionNode?.focusNode?.textContent || '',
                    attributes: [
                      {
                        key: 'class',
                        value: 'yandex-rss-hidden'
                      }
                    ]
                  },
                  'user'
                )
              }
            } else {
              quill.removeFormat(range.index, selectingText.length, 'api')
              quill.formatText(
                range.index,
                selectingText.length,
                {
                  color,
                  size
                },
                'user'
              )
            }

            quill.setSelection(range.index + 1, 0)
            quill.getSelection(true)
            quill.focus()
          }, 500)
        }
      }
    },
    [editorRef]
  )

  const selectSize = useCallback(
    (sizeItem: SizeItem) => {
      if (sizeItem) {
        setSelectedSize(sizeItem)
        setSize(sizeItem.size || '', sizeItem.color || '', sizeItem.title)
      }
      handleClose()
    },
    [setSize, setSelectedSize]
  )

  return (
    <>
      <Tooltip title={TOOLBAR_SIZE_CONSTS.textSize}>
        <StyledToggleButton
          id='basic-button'
          value=''
          aria-controls={open ? 'text-size-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography
            color='textPrimary'
            variant={selectedSize?.title === 'T' ? 'h1' : 'inherit'}
            style={{
              display: 'contents',
              verticalAlign: 'middle'
            }}
          >
            <TextIcon />
            <span>&nbsp;</span>
            <ChevronIcon />
          </Typography>
        </StyledToggleButton>
      </Tooltip>
      <StyledMenu
        id='text-size-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {TOOLBAR_SIZE_CONSTS.sizeItems.map(item => (
          <MenuItem onClick={() => selectSize(item)} key={item.title} value={item.title}>
            {item.title}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
export default ToolbarSize
