import {useCallback, useEffect, useState} from 'react'

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false)
  const [darkMode, setDarkMode] = useState(false)
  const [windowWidth, setWindowWidth] = useState<number>()

  const handleResize = useCallback(() => {
    if (window) {
      setWindowWidth(window.outerWidth)
      const media = window.matchMedia(`(max-width: 979px)`)
      setMobile(media.matches)
    }
    // const userAgent =
    //   typeof window.navigator === undefined ? '' : navigator.userAgent
    // const mobile = Boolean(
    //     userAgent.match(
    //         /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    //     )
    // )
    // setMobile(mobile)

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDarkMode(true)
    }
  }, [setDarkMode, setMobile])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  })

  return {isMobile, darkMode, windowWidth}
}
