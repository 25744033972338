import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type AutomoderatorDictionaryEntriesQueryVariables = Types.Exact<{
  filter?: Types.Maybe<Types.AutomoderatorDictionaryEntriesFilter>
  sort?: Types.Maybe<Types.AutomoderatorDictionaryEntriesSort>
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
}>

export type AutomoderatorDictionaryEntriesQuery = {__typename?: 'Query'} & {
  automoderatorDictionaryEntries?: Types.Maybe<
    {__typename?: 'AutomoderatorDictionaryEntriesConnection'} & {
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
      edges?: Types.Maybe<
        Array<
          Types.Maybe<
            {__typename?: 'AutomoderatorDictionaryEntriesEdge'} & {
              node?: Types.Maybe<
                {__typename?: 'AutomoderatorDictionaryEntry'} & Pick<Types.AutomoderatorDictionaryEntry, 'id' | 'word'>
              >
            }
          >
        >
      >
    }
  >
}

export type RemoveAutomoderatorDictionaryEntryMutationVariables = Types.Exact<{
  data: Types.RemoveAutomoderatorDictionaryEntryInput
}>

export type RemoveAutomoderatorDictionaryEntryMutation = {__typename?: 'Mutation'} & {
  removeAutomoderatorDictionaryEntry?: Types.Maybe<
    {__typename?: 'RemoveAutomoderatorDictionaryEntryPayload'} & Pick<
      Types.RemoveAutomoderatorDictionaryEntryPayload,
      'status'
    >
  >
}

export type AddAutomoderatorDictionaryEntryMutationVariables = Types.Exact<{
  data: Types.AddAutomoderatorDictionaryEntryInput
}>

export type AddAutomoderatorDictionaryEntryMutation = {__typename?: 'Mutation'} & {
  addAutomoderatorDictionaryEntry?: Types.Maybe<
    {__typename?: 'AddAutomoderatorDictionaryEntryPayload'} & Pick<
      Types.AddAutomoderatorDictionaryEntryPayload,
      'status'
    >
  >
}

export const AutomoderatorDictionaryEntriesDocument = gql`
  query automoderatorDictionaryEntries(
    $filter: AutomoderatorDictionaryEntriesFilter
    $sort: AutomoderatorDictionaryEntriesSort
    $first: Int
    $after: String
  ) {
    automoderatorDictionaryEntries(limit: 20, filter: $filter, sort: $sort, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          word
        }
      }
    }
  }
`

/**
 * __useAutomoderatorDictionaryEntriesQuery__
 *
 * To run a query within a React component, call `useAutomoderatorDictionaryEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutomoderatorDictionaryEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutomoderatorDictionaryEntriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAutomoderatorDictionaryEntriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AutomoderatorDictionaryEntriesQuery,
    AutomoderatorDictionaryEntriesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<AutomoderatorDictionaryEntriesQuery, AutomoderatorDictionaryEntriesQueryVariables>(
    AutomoderatorDictionaryEntriesDocument,
    options
  )
}
export function useAutomoderatorDictionaryEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutomoderatorDictionaryEntriesQuery,
    AutomoderatorDictionaryEntriesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<AutomoderatorDictionaryEntriesQuery, AutomoderatorDictionaryEntriesQueryVariables>(
    AutomoderatorDictionaryEntriesDocument,
    options
  )
}
export type AutomoderatorDictionaryEntriesQueryHookResult = ReturnType<typeof useAutomoderatorDictionaryEntriesQuery>
export type AutomoderatorDictionaryEntriesLazyQueryHookResult = ReturnType<
  typeof useAutomoderatorDictionaryEntriesLazyQuery
>
export type AutomoderatorDictionaryEntriesQueryResult = Apollo.QueryResult<
  AutomoderatorDictionaryEntriesQuery,
  AutomoderatorDictionaryEntriesQueryVariables
>
export const RemoveAutomoderatorDictionaryEntryDocument = gql`
  mutation removeAutomoderatorDictionaryEntry($data: RemoveAutomoderatorDictionaryEntryInput!) {
    removeAutomoderatorDictionaryEntry(data: $data) {
      status
    }
  }
`
export type RemoveAutomoderatorDictionaryEntryMutationFn = Apollo.MutationFunction<
  RemoveAutomoderatorDictionaryEntryMutation,
  RemoveAutomoderatorDictionaryEntryMutationVariables
>

/**
 * __useRemoveAutomoderatorDictionaryEntryMutation__
 *
 * To run a mutation, you first call `useRemoveAutomoderatorDictionaryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAutomoderatorDictionaryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAutomoderatorDictionaryEntryMutation, { data, loading, error }] = useRemoveAutomoderatorDictionaryEntryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveAutomoderatorDictionaryEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAutomoderatorDictionaryEntryMutation,
    RemoveAutomoderatorDictionaryEntryMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    RemoveAutomoderatorDictionaryEntryMutation,
    RemoveAutomoderatorDictionaryEntryMutationVariables
  >(RemoveAutomoderatorDictionaryEntryDocument, options)
}
export type RemoveAutomoderatorDictionaryEntryMutationHookResult = ReturnType<
  typeof useRemoveAutomoderatorDictionaryEntryMutation
>
export type RemoveAutomoderatorDictionaryEntryMutationResult =
  Apollo.MutationResult<RemoveAutomoderatorDictionaryEntryMutation>
export type RemoveAutomoderatorDictionaryEntryMutationOptions = Apollo.BaseMutationOptions<
  RemoveAutomoderatorDictionaryEntryMutation,
  RemoveAutomoderatorDictionaryEntryMutationVariables
>
export const AddAutomoderatorDictionaryEntryDocument = gql`
  mutation addAutomoderatorDictionaryEntry($data: AddAutomoderatorDictionaryEntryInput!) {
    addAutomoderatorDictionaryEntry(data: $data) {
      status
    }
  }
`
export type AddAutomoderatorDictionaryEntryMutationFn = Apollo.MutationFunction<
  AddAutomoderatorDictionaryEntryMutation,
  AddAutomoderatorDictionaryEntryMutationVariables
>

/**
 * __useAddAutomoderatorDictionaryEntryMutation__
 *
 * To run a mutation, you first call `useAddAutomoderatorDictionaryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAutomoderatorDictionaryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAutomoderatorDictionaryEntryMutation, { data, loading, error }] = useAddAutomoderatorDictionaryEntryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddAutomoderatorDictionaryEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAutomoderatorDictionaryEntryMutation,
    AddAutomoderatorDictionaryEntryMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<AddAutomoderatorDictionaryEntryMutation, AddAutomoderatorDictionaryEntryMutationVariables>(
    AddAutomoderatorDictionaryEntryDocument,
    options
  )
}
export type AddAutomoderatorDictionaryEntryMutationHookResult = ReturnType<
  typeof useAddAutomoderatorDictionaryEntryMutation
>
export type AddAutomoderatorDictionaryEntryMutationResult =
  Apollo.MutationResult<AddAutomoderatorDictionaryEntryMutation>
export type AddAutomoderatorDictionaryEntryMutationOptions = Apollo.BaseMutationOptions<
  AddAutomoderatorDictionaryEntryMutation,
  AddAutomoderatorDictionaryEntryMutationVariables
>
