import React, {FC, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {useSnackbar} from 'notistack'
import {useRegionCountBlocksQuery} from '../TopNews/TopNewsContent/gql/TopNewsContent.generated'
import {
  useDeleteHotNewsBlockMutation,
  useHotNewsBlockQuery,
  useUpdateHotNewsBlockForAllRegionsMutation,
  useUpdateHotNewsBlockMutation
} from './gql/HotNewsContent.generated'
import HotNewsHeader from './HotNewsHeader/HotNewsHeader'
import HotNewsItem from './HotNewsItem/HotNewsItem'

type TabPanelContentProps = {
  regionId?: string
}

type HotNewsValue = {
  description: string
  title: string
  uid: string
  url: string
  clickCount: number
  isHaveNews: boolean
  searchUrl: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 48px;
`

const HotNewsContent: FC<TabPanelContentProps> = ({regionId}) => {
  const hotNews = useHotNewsBlockQuery({
    variables: {
      regionUid: regionId
    },
    fetchPolicy: 'cache-and-network'
  })

  useRegionCountBlocksQuery({
    variables: {regionId},
    onCompleted: data => setRequiredCountBlocks(data.region?.requiredCountOfTopNewsBlocks as number)
  })

  const snackbar = useSnackbar()
  const [url, setUrl] = useState('')
  const [updateHotNews] = useUpdateHotNewsBlockMutation()
  const [deleteHotNews] = useDeleteHotNewsBlockMutation()
  const [updateHotNewsForAllRegions] = useUpdateHotNewsBlockForAllRegionsMutation()
  const [requiredCountBlocks, setRequiredCountBlocks] = useState(0)
  const defaultValue: HotNewsValue = {
    description: hotNews.data?.hotNewsBlock?.subtitle || '',
    title: hotNews.data?.hotNewsBlock?.title || '',
    uid: hotNews.data?.hotNewsBlock?.uid,
    url: hotNews.data?.hotNewsBlock?.url || '',
    clickCount: hotNews.data?.hotNewsBlock?.clickCount || 0,
    isHaveNews: false,
    searchUrl: hotNews.data?.hotNewsBlock?.url || ''
  }

  const {control, setValue, handleSubmit, formState, ...methodsForm} = useForm<any>({
    defaultValues: defaultValue
  })
  const fields = useWatch({control})

  const updateHotNewsAction = useCallback(async () => {
    try {
      await updateHotNews({
        variables: {
          data: {
            subtitle: fields.description,
            regionUid: regionId,
            title: fields.title,
            url: fields.url
          }
        }
      })
      snackbar.enqueueSnackbar('Сохранено', {variant: 'success'})
    } catch {}
  }, [fields, regionId, snackbar, updateHotNews])

  const deleteHotNewsAction = useCallback(async () => {
    try {
      await deleteHotNews({
        variables: {
          data: {
            regionUid: regionId
          }
        }
      })
      setValue('isHaveNews', false)
      setValue('searchUrl', '')
    } catch {}
  }, [deleteHotNews, regionId, setValue])

  const updateHotNewsForAllRegionsAction = useCallback(async () => {
    try {
      await updateHotNewsForAllRegions({
        variables: {
          data: {
            subtitle: fields.description,
            title: fields.title,
            url: fields.url
          }
        }
      })
      snackbar.enqueueSnackbar('Сохранено', {variant: 'success'})
    } catch {}
  }, [fields, updateHotNewsForAllRegions, snackbar])

  useEffect(() => {
    setValue('description', hotNews.data?.hotNewsBlock?.subtitle)
    setValue('clickCount', hotNews.data?.hotNewsBlock?.clickCount)
    setValue('url', hotNews.data?.hotNewsBlock?.url)
    setValue('searchUrl', hotNews.data?.hotNewsBlock?.url || '')
    setValue('title', hotNews.data?.hotNewsBlock?.title)
    setValue('uid', hotNews.data?.hotNewsBlock?.uid)
    setValue('isHaveNews', !!hotNews.data?.hotNewsBlock?.url)
  }, [hotNews, setValue])

  return (
    <FormProvider
      {...{
        control,
        setValue,
        handleSubmit,
        formState,
        ...methodsForm
      }}
    >
      <Wrapper>
        <HotNewsHeader
          updateHotNews={updateHotNewsAction}
          updateHotNewsForAllRegions={updateHotNewsForAllRegionsAction}
        />
        {fields.isHaveNews && <HotNewsItem deleteHotNews={deleteHotNewsAction} />}
      </Wrapper>
    </FormProvider>
  )
}

export default HotNewsContent
