export const PUBLICATION_FORM_DETAILS_CONSTS = {
  checkingPhotoEditor: 'Проверена бильдредактором',
  checkedByEditor: 'Проверена редактором',
  disableCommentsAutoPublishingMsg: 'Запретить автоматическую публикацию комментариев',
  disableCommentsMsg: 'Запретить обсуждение',
  includeMsg: 'Включить',
  insideMsg: 'Инсайд',
  isImportantMsg: 'Важная',
  onlineTranslation: 'Онлайн-трансляция'
} as const
