import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'

export const VisibleIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        d='M18 12.1333C22.1345 12.1333 25.8218 14.4053 27.6218 18C25.8218 21.5947 22.1345 23.8667 18 23.8667C13.8655 23.8667 10.1782 21.5947 8.37818 18C10.1782 14.4053 13.8655 12.1333 18 12.1333ZM18 10C12.5455 10 7.88727 13.3173 6 18C7.88727 22.6827 12.5455 26 18 26C23.4545 26 28.1127 22.6827 30 18C28.1127 13.3173 23.4545 10 18 10ZM18 15.3333C19.5055 15.3333 20.7273 16.528 20.7273 18C20.7273 19.472 19.5055 20.6667 18 20.6667C16.4945 20.6667 15.2727 19.472 15.2727 18C15.2727 16.528 16.4945 15.3333 18 15.3333ZM18 13.2C15.2945 13.2 13.0909 15.3547 13.0909 18C13.0909 20.6453 15.2945 22.8 18 22.8C20.7055 22.8 22.9091 20.6453 22.9091 18C22.9091 15.3547 20.7055 13.2 18 13.2Z'
        fill='#222'
      />
    </svg>
  )
}
