import React, {FC, useCallback, useEffect, useState} from 'react'
import {Box, LinearProgress} from '@mui/material'
import {History} from '@mui/icons-material'
import {useSnackbar} from 'notistack'
import {Prompt, useHistory, useParams} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {FigureVersion, SaveTopNewsBlockInput, TopNewsBlock} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import TopNewsGridDrop from '../TopNewsGridDrop/TopNewsGridDrop'
import MaterialUrl from '../../MaterialUrl/MaterialUrl'
import ButtonForInput from '../../../UI/styled/ButtonForInput'
import {
  usePublicationByUrlForBannerQuery,
  useRegionCountBlocksLazyQuery,
  useSaveTopNewsBlockMutation,
  useTopNewsSelectionLazyQuery,
  useUpdateTopNewsSelectionMutation
} from './gql/TopNewsContent.generated'
import {TOP_NEWS_CONTENT_CONSTS} from './TopNewsContent.consts'
import {Header, HistoryAndSaveBox, ModalDescription, Wrapper} from './TopNewsContent.styles'

const TopNewsContent: FC = () => {
  const {regionId} = useParams<{regionId: string}>()
  const [url, setUrl] = useState('')
  const {topNewsStore} = useStore()
  const {
    addingBlocksCache,
    deletingBlocksCache,
    topNewsBlocksUids,
    confirmSaveModalOpen,
    resetCache,
    setConfirmSaveModalOpen,
    setTopNewsBlocksUids
  } = topNewsStore
  const [topNewsBlocks, setTopNewsBlocks] = useState<TopNewsBlock[]>([])
  const [blocksChanged, setBlocksChanged] = useState(false)
  const [isSwapItems, setIsSwapItems] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [figureVersion, setFigureVersion] = useState({} as FigureVersion)
  const [requiredCountBlocks, setRequiredCountBlocks] = useState(0)
  const [currentPublication, setCurrentPublication] = useState({} as any)
  const history = useHistory()
  const snackbar = useSnackbar()
  const [updateTopNewsSelection] = useUpdateTopNewsSelectionMutation()
  const [saveTopNewsBlock] = useSaveTopNewsBlockMutation()
  const [isActiveBtn, setIsActiveBtn] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const [getRegionCountBlock] = useRegionCountBlocksLazyQuery()

  useEffect(() => {
    if (regionId) {
      getRegionCountBlock({
        variables: {regionId}
      }).then(res => setRequiredCountBlocks(res?.data?.region?.requiredCountOfTopNewsBlocks as number))
    }
  }, [regionId, getRegionCountBlock])

  const [getTopNewsByRegionId, {loading}] = useTopNewsSelectionLazyQuery()

  const topNewsSelection = useCallback(async () => {
    const getTopNewsByRegionIdData = await getTopNewsByRegionId({
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
      variables: {regionId}
    })

    if (getTopNewsByRegionIdData?.data?.topNewsSelection) {
      const blocks = getTopNewsByRegionIdData?.data?.topNewsSelection?.slice() as TopNewsBlock[]
      const newTopNewsBlocksUids = topNewsBlocksUids || []

      if (newTopNewsBlocksUids?.length) {
        newTopNewsBlocksUids.forEach(item => {
          const newBlock: TopNewsBlock | undefined = addingBlocksCache.get(item)

          if (!deletingBlocksCache.has(item)) {
            if (newBlock) {
              const existingBlockIndex = blocks.findIndex(block => block?.uid === newBlock?.uid)

              if (existingBlockIndex !== -1) {
                blocks[existingBlockIndex] = newBlock
              } else {
                blocks.push(newBlock)
              }
            }
          }
        })
        if (blocks.findIndex(b => b.position == null) > 0) {
          setBlocksChanged(true)
        }
      }
      setTopNewsBlocks(blocks)
    }
  }, [getTopNewsByRegionId, topNewsBlocksUids, regionId])

  useEffect(() => {
    if (regionId) {
      topNewsSelection()
    }
  }, [regionId])

  usePublicationByUrlForBannerQuery({
    variables: {url},
    onCompleted: data => {
      if (url) {
        setCurrentPublication(data.publicationByUrl)

        if (data?.publicationByUrl?.__typename == 'Publication') {
          setFigureVersion({
            crop: data.publicationByUrl.announceImage?.figureVersion?.crop,
            figure: data.publicationByUrl.announceImage?.figureVersion?.figure,
            hasDiagonalWatermark: false,
            hasWatermark: false,
            id: data.publicationByUrl.announceImage?.figureVersion?.id,
            primaryProcessedImage: data.publicationByUrl.announceImage?.figureVersion?.primaryProcessedImage,
            uid: data.publicationByUrl.announceImage?.figureVersion?.uid
          } as FigureVersion)
        }
        setDataLoaded(true)
      }
    }
  })

  const handleUpdateTopNewsSelection = useCallback(async () => {
    try {
      if (isValid) {
        const newBlockUids = await updateTopNewsSelection({
          variables: {
            data: {
              regionId: regionId,
              blockIds: topNewsBlocks.map(block => block.uid)
            }
          }
        })

        setTopNewsBlocksUids([])
        resetCache()
        await topNewsSelection()
        snackbar.enqueueSnackbar(TOP_NEWS_CONTENT_CONSTS.savedMsg, {
          variant: 'success'
        })
        setBlocksChanged(false)
        setIsSwapItems(false)
        setIsActiveBtn(false)
      }
    } catch (e) {
      console.error(e)
    }
  }, [
    isValid,
    regionId,
    snackbar,
    topNewsBlocks,
    topNewsBlocksUids,
    resetCache,
    setTopNewsBlocksUids,
    topNewsSelection,
    updateTopNewsSelection
  ])

  const handleCreateBlockModal = useCallback(async () => {
    try {
      const saveTopNewsBlockData = await saveTopNewsBlock({
        variables: {
          data: {
            topNewsBlockId: null,
            url: url,
            title: currentPublication.title,
            isExclusive: false,
            figureVersionId: figureVersion?.id
          } as SaveTopNewsBlockInput
        }
      })

      const updatedBlock = {
        ...saveTopNewsBlockData.data?.saveTopNewsBlock?.block,
        position: topNewsBlocks.length + 1
      }

      if (!saveTopNewsBlockData.errors?.length) {
        const newTopNewsBlocksUids = topNewsBlocksUids || []

        if (newTopNewsBlocksUids) {
          newTopNewsBlocksUids.push(updatedBlock?.uid)
          setTopNewsBlocksUids(newTopNewsBlocksUids)
        }
        addingBlocksCache.set(updatedBlock?.uid, updatedBlock as TopNewsBlock)

        setTopNewsBlocks(prevState => [...prevState, updatedBlock] as TopNewsBlock[])
        setBlocksChanged(true)
        setIsSwapItems(true)
        snackbar.enqueueSnackbar(TOP_NEWS_CONTENT_CONSTS.blockWasSavedMsg, {
          variant: 'success'
        })
      }
    } catch (e: any) {
      console.error(e)
    }
  }, [
    currentPublication,
    figureVersion,
    snackbar,
    topNewsBlocksUids,
    topNewsBlocks.length,
    url,
    addingBlocksCache,
    saveTopNewsBlock,
    setTopNewsBlocksUids
  ])

  const handleDeleteBlock = useCallback(
    (blockUid: string) => {
      deletingBlocksCache.add(blockUid)
      setTopNewsBlocks(prev => prev.filter(block => block.uid !== blockUid))
      setBlocksChanged(true)
      setIsSwapItems(true)

      const newTNBlocksUids = topNewsBlocksUids || []
      const updNewTNBlocksUids = newTNBlocksUids.filter(uid => uid !== blockUid)
      setTopNewsBlocksUids(updNewTNBlocksUids)
    },
    [setTopNewsBlocks, deletingBlocksCache, topNewsBlocksUids, setTopNewsBlocksUids]
  )

  useEffect(() => {
    setTopNewsBlocksUids(topNewsBlocks.map(b => b.uid))

    if (topNewsBlocks.find(item => item.title.length > 54)) {
      setIsValid(false)
      return
    }
    setIsValid(true)
  }, [topNewsBlocks, setIsValid, setTopNewsBlocksUids])

  const cancelChanges = useCallback(() => {
    resetCache()
    setConfirmSaveModalOpen(false)
    topNewsSelection()
  }, [resetCache, setConfirmSaveModalOpen, topNewsSelection])

  const approveChanges = useCallback(() => {
    setConfirmSaveModalOpen(false)
    handleUpdateTopNewsSelection()
  }, [setConfirmSaveModalOpen, handleUpdateTopNewsSelection])

  useEffect(() => {
    if (!isValid) {
      setIsActiveBtn(false)
      return
    }
    if (isSwapItems || addingBlocksCache.size > 0 || deletingBlocksCache.size > 0) {
      setIsActiveBtn(true)
      return
    }
    setIsActiveBtn(false)
  }, [addingBlocksCache, deletingBlocksCache, isValid, isSwapItems])

  if (loading) return <LinearProgress />

  return (
    <Wrapper>
      <Header>
        <MaterialUrl
          blockCount={topNewsBlocks.length}
          setUrl={setUrl}
          handleAddMaterial={handleCreateBlockModal}
          setDataLoaded={setDataLoaded}
          dataLoaded={dataLoaded}
        />
        <HistoryAndSaveBox>
          <ButtonForInput
            variant='outlined'
            startIcon={<History />}
            onClick={() => history.push(`/main-page/top-news/history/${regionId}`)}
          >
            {TOP_NEWS_CONTENT_CONSTS.blockHistoryMsg}
          </ButtonForInput>
          <ButtonForInput variant='contained' disabled={!isActiveBtn} onClick={handleUpdateTopNewsSelection}>
            {TOP_NEWS_CONTENT_CONSTS.saveMsg}
          </ButtonForInput>
        </HistoryAndSaveBox>
      </Header>
      <TopNewsGridDrop
        topNewsBlocks={topNewsBlocks}
        setTopNewsBlocks={setTopNewsBlocks}
        handleDeleteBlock={handleDeleteBlock}
        onSwapItems={setIsSwapItems}
        regionId={regionId}
      />
      <Prompt
        when={addingBlocksCache.keys.length > 0 || deletingBlocksCache.size > 0}
        message={TOP_NEWS_CONTENT_CONSTS.approveSaveChangesQuestion2}
      />
      <SimpleModal
        mode='dialog'
        open={confirmSaveModalOpen}
        title={TOP_NEWS_CONTENT_CONSTS.warning}
        onClose={() => setConfirmSaveModalOpen(false)}
        onNo={cancelChanges}
        onYes={approveChanges}
        yesLabel={TOP_NEWS_CONTENT_CONSTS.saveChangesMsg}
        noLabel={TOP_NEWS_CONTENT_CONSTS.cancelChangesConfirmation}
      >
        <Box textAlign='center'>
          <ModalDescription variant='h2'>{TOP_NEWS_CONTENT_CONSTS.approveSaveChangesQuestion}</ModalDescription>
        </Box>
      </SimpleModal>
    </Wrapper>
  )
}

export default observer(TopNewsContent)
