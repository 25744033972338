import React, {FC} from 'react'

const TrashFilledIcon: FC<any> = () => {
  return (
    <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.11111 18.2778C1.11111 19.5 2.11111 20.5 3.33333 20.5H12.2222C13.4444 20.5 14.4444 19.5 14.4444 18.2778V4.94444H1.11111V18.2778ZM3.84444 10.3667L5.41111 8.8L7.77778 11.1556L10.1333 8.8L11.7 10.3667L9.34444 12.7222L11.7 15.0778L10.1333 16.6444L7.77778 14.2889L5.42222 16.6444L3.85556 15.0778L6.21111 12.7222L3.84444 10.3667ZM11.6667 1.61111L10.5556 0.5H5L3.88889 1.61111H0V3.83333H15.5556V1.61111H11.6667Z'
        fill='#A9A9A9'
      />
    </svg>
  )
}

export default TrashFilledIcon
