import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

export const YandexZenFilledIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9008 9C14.1911 9.00035 11.6205 9.01269 10.301 10.3012C9.01257 11.6204 9.00023 14.3117 9.00023 17.9008C12.9855 17.7805 14.8079 17.6807 16.2615 16.2614C17.6807 14.8078 17.7806 12.9854 17.9008 9ZM10.3008 25.6991C9.01234 24.3799 9 21.6887 9 18.0996C12.9853 18.2198 14.8077 18.3196 16.2612 19.739C17.6805 21.1925 17.7803 23.0149 17.9006 27C14.1908 27 11.6203 26.988 10.3008 25.6991ZM18.0995 27C18.2198 23.0149 18.3196 21.1925 19.7389 19.739C21.1924 18.3196 23.0149 18.2198 27.0001 18.0996C26.9998 21.6887 26.9874 24.3799 25.699 25.6991C24.3795 26.988 21.8093 27 18.0995 27ZM18.0995 9C18.2198 12.9854 18.3196 14.8078 19.7389 16.2614C21.1924 17.6807 23.0149 17.7805 27.0001 17.9008C26.9998 14.3117 26.9874 11.6204 25.699 10.3012C24.3795 9.01269 21.8093 9.00035 18.0995 9Z'
        stroke='#1964E7'
        strokeWidth='2'
      />
    </svg>
  )
}

export const YandexZenOutlinedIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9008 9C14.1911 9.00035 11.6205 9.01269 10.301 10.3012C9.01257 11.6204 9.00023 14.3117 9.00023 17.9008C12.9855 17.7805 14.8079 17.6807 16.2615 16.2614C17.6807 14.8078 17.7806 12.9854 17.9008 9ZM10.3008 25.6991C9.01234 24.3799 9 21.6887 9 18.0996C12.9853 18.2198 14.8077 18.3196 16.2612 19.739C17.6805 21.1925 17.7803 23.0149 17.9006 27C14.1908 27 11.6203 26.988 10.3008 25.6991ZM18.0995 27C18.2198 23.0149 18.3196 21.1925 19.7389 19.739C21.1924 18.3196 23.0149 18.2198 27.0001 18.0996C26.9998 21.6887 26.9874 24.3799 25.699 25.6991C24.3795 26.988 21.8093 27 18.0995 27ZM18.0995 9C18.2198 12.9854 18.3196 14.8078 19.7389 16.2614C21.1924 17.6807 23.0149 17.7805 27.0001 17.9008C26.9998 14.3117 26.9874 11.6204 25.699 10.3012C24.3795 9.01269 21.8093 9.00035 18.0995 9Z'
        stroke='#A9A9A9'
        strokeWidth='2'
      />
    </svg>
  )
}
