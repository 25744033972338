import Keycloak, {KeycloakInitOptions, KeycloakInstance} from 'keycloak-js'

export const initOptions: KeycloakInitOptions = {
  checkLoginIframe: true,
  checkLoginIframeInterval: 1,
  onLoad: 'login-required'
}

/**
 * Функция клиента для keycloack
 * @returns {KeycloakInstance} KeycloakInstance
 */
const keycloakClient = (): KeycloakInstance => {
  const keycloak = Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URI,
    realm: 'uranews',
    clientId: 'backend'
  })

  setInterval(() => {
    keycloak.updateToken(70).catch(() => {
      keycloak.logout()
    })
  }, 5000)

  return keycloak
}

export default keycloakClient
