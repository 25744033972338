import React from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import PublicationCommentsContent from '@components/Comments/PublicationCommentsContent/PublicationCommentsContent'

const PublicationCommentsPage: React.FC = () => {
  return (
    <PageContainer>
      <PublicationCommentsContent />
    </PageContainer>
  )
}

export default PublicationCommentsPage
