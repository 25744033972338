import {ArrowBack} from '@mui/icons-material'
import {Box, IconButton, Typography} from '@mui/material'
import {cloneElement, FC} from 'react'
import {HeaderProps} from './HeaderProps'
import {HeaderWrapper} from './Header.styles'

/**
 * Компонент для заголовка страницы
 * @param param0
 * @returns
 */
const Header: FC<HeaderProps> = ({buttonsBlock, headerStyle, title, onBack}) => {
  return (
    <HeaderWrapper>
      <Box marginRight={'64px'}>
        <IconButton size='small' onClick={onBack}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Box style={headerStyle}>
        <Typography variant='text20M'>{title}</Typography>
      </Box>
      {buttonsBlock && cloneElement(buttonsBlock)}
    </HeaderWrapper>
  )
}

export default Header
