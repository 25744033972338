import {FC, useCallback, useEffect, useState} from 'react'
import {IconButton, Tooltip} from '@mui/material'
import ExpandCircleDown from '@components/UI/Icons/ExpandCircleDown'
import {grayColor} from '@theme/vars'
import {RandomSeoTitleExampleQuery} from '../../gql/PublicationEditor.generated'
import {SEO_TITLE_EXAMPLE_CONSTS} from './SeoTitleExample.consts'

type Props = {
  randomTitleExamleData: RandomSeoTitleExampleQuery | undefined
}

export const SeoTitleExample: FC<Props> = ({randomTitleExamleData}) => {
  const [showSeoTitleInfo, setShowSeoTitleInfo] = useState(false)
  const [randomSeoTitleExamle, setRandomSeoTitleExamle] = useState('')
  const [randomTitleExamle, setRandomTitleExamle] = useState('')

  const changeShowSeoTitleInfo = useCallback(() => {
    setShowSeoTitleInfo(prev => {
      return !prev
    })
  }, [])

  useEffect(() => {
    setRandomSeoTitleExamle(randomTitleExamleData?.randomTitleExamle?.seoTitle || '')
    setRandomTitleExamle(randomTitleExamleData?.randomTitleExamle?.title || '')
  }, [randomTitleExamleData])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1266px'
      }}
    >
      <div
        style={{
          width: 'calc(100% - 80px)',
          verticalAlign: 'middle',
          flexDirection: 'column'
        }}
      >
        <span
          style={{
            width: 'calc(100% - 20px)',
            float: 'left'
          }}
        >
          {SEO_TITLE_EXAMPLE_CONSTS.exampleMsg}
          :&nbsp;
          {randomSeoTitleExamle}
        </span>
        <Tooltip title={showSeoTitleInfo ? SEO_TITLE_EXAMPLE_CONSTS.hideMsg : SEO_TITLE_EXAMPLE_CONSTS.moreMsg}>
          <IconButton
            style={{
              float: 'right',
              padding: '3px',
              marginTop: '-3px',
              display: 'inline-block',
              transform: `${showSeoTitleInfo ? 'rotate(180deg)' : 'rotate(0deg)'}`
            }}
            onClick={changeShowSeoTitleInfo}
          >
            <ExpandCircleDown />
          </IconButton>
        </Tooltip>
      </div>
      {showSeoTitleInfo ? (
        <div
          style={{
            color: `${grayColor}`,
            width: 'calc(100% - 80px)',
            verticalAlign: 'middle'
          }}
        >
          <div style={{color: '#222222'}}>
            {SEO_TITLE_EXAMPLE_CONSTS.mainTitleMsg}: {randomTitleExamle}
          </div>
          <div
            style={{
              marginTop: '10px'
            }}
          >
            {SEO_TITLE_EXAMPLE_CONSTS.seoExampleMsg}
          </div>
        </div>
      ) : undefined}
    </div>
  )
}
