import exportFromJSON, {ExportType} from 'export-from-json'
import {REPORTS_LIST_TABLE_CONSTS} from '../ReportsListTable.consts'
import {ReportsListTableDataProps} from '../ReportsListTableDataProps'
import {ReportListTableFields} from '../../../../types/ReportListTableFields'
import {generatePdfFile} from './generatePdfFile'
import {generateWordFile} from './generateWordFile'

export const exportToFile = (
  tableData: ReportsListTableDataProps[],
  fields: ReportListTableFields,
  fileName: string,
  curFileFormat: string
): void => {
  const fieldsNames = Object.keys(fields).map(item => item)

  switch (curFileFormat) {
    case 'pdf': {
      generatePdfFile(tableData, fieldsNames, fileName)
      break
    }
    case 'docx': {
      generateWordFile(tableData, fieldsNames, fileName)
      break
    }
    default: {
      const fieldsNamesRu = Object.keys(fields).map(item => REPORTS_LIST_TABLE_CONSTS[item].replace(',', ''))
      const data = tableData.map(item => ({
        [REPORTS_LIST_TABLE_CONSTS.date]: item.date,
        [REPORTS_LIST_TABLE_CONSTS.time]: item.time,
        [REPORTS_LIST_TABLE_CONSTS.title]: item.title,
        [REPORTS_LIST_TABLE_CONSTS.seoTitle]: item.seoTitle,
        [REPORTS_LIST_TABLE_CONSTS.rubricAndSubrubric.replace(',', '')]:
          item.rubricTitle + `${item.subrubricTitle ? `, ${item.subrubricTitle}` : ''}`,
        [REPORTS_LIST_TABLE_CONSTS.authors]: item.authors.join('\n'),
        [REPORTS_LIST_TABLE_CONSTS.viewsCount]: (item.viewsCount || 0).toString(),
        [REPORTS_LIST_TABLE_CONSTS.uniqueUsersCount]: (item.uniqueUsersCount || 0).toString(),
        [REPORTS_LIST_TABLE_CONSTS.fromSocialNetworks]: (item.socialVisits || 0).toString(),
        [REPORTS_LIST_TABLE_CONSTS.fromSocialNetworksPercent]: (item.percentSocial || 0).toString(),
        [REPORTS_LIST_TABLE_CONSTS.withoutRecommendedSystems]: (item.withoutRecommendedSystems || 0).toString()
      }))
      exportFromJSON({data, fileName, exportType: curFileFormat as ExportType, fields: fieldsNamesRu})
    }
  }
}
