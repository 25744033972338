import React, {FC, useEffect, useState} from 'react'
import {UNPUBLISHED_NEWS_PICKER_CONSTS} from '../Pickers/UnpublishedNewsPicker/UnpublishedNewsPicker.consts'
import {PublicationHasBeenRemovedWrapper} from './UnpublishedNewsSignboard.styles'

export const UnpublishedNewsSignboard: FC<any> = ({redirectUrl}) => {
  const [currentRedirectUrl, setCurrentRedirectUrl] = useState('')

  useEffect(() => {
    setCurrentRedirectUrl(redirectUrl)
  }, [redirectUrl])

  return (
    <PublicationHasBeenRemovedWrapper>
      {UNPUBLISHED_NEWS_PICKER_CONSTS.alternativeLinkForRemovedPub}{' '}
      <a href={currentRedirectUrl} target='_blank' rel='noreferrer' style={{marginLeft: '5px'}}>
        ссылку.
      </a>
    </PublicationHasBeenRemovedWrapper>
  )
}
