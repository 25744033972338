import * as Types from '../../../../../@graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateOverviewBlockMutationVariables = Types.Exact<{
  data: Types.CreateOverviewBlockInput;
}>;


export type CreateOverviewBlockMutation = (
  { __typename?: 'Mutation' }
  & { createOverviewBlock?: Types.Maybe<(
    { __typename?: 'CreateOverviewBlockPayload' }
    & { block?: Types.Maybe<(
      { __typename?: 'OverviewBlock' }
      & OverviewBlockFragmentFragment
    )> }
  )> }
);

export type CreateOverviewBlockDraftMutationVariables = Types.Exact<{
  data: Types.CreateOverviewBlockDraftInput;
}>;


export type CreateOverviewBlockDraftMutation = (
  { __typename?: 'Mutation' }
  & { createOverviewBlockDraft?: Types.Maybe<(
    { __typename?: 'OverviewBlockDraft' }
    & OverviewBlockDraftFragmentFragment
  )> }
);

export type OverviewBlocksQueryVariables = Types.Exact<{
  regionId: Types.Scalars['UUID'];
}>;


export type OverviewBlocksQuery = (
  { __typename?: 'Query' }
  & { overviewBlocks?: Types.Maybe<(
    { __typename?: 'OverviewBlocksConnection' }
    & { edges: Array<(
      { __typename?: 'OverviewBlocksEdge' }
      & { node?: Types.Maybe<(
        { __typename?: 'OverviewBlock' }
        & OverviewBlockFragmentFragment
      )> }
    )> }
  )> }
);

export type OverviewBlocksByUrlOrNullQueryVariables = Types.Exact<{
  url: Types.Scalars['String'];
}>;


export type OverviewBlocksByUrlOrNullQuery = (
  { __typename?: 'Query' }
  & { overviewBlocksByUrlOrNull?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'OverviewBlock' }
    & Pick<Types.OverviewBlock, 'id' | 'position'>
    & { region: (
      { __typename?: 'Region' }
      & Pick<Types.Region, 'id' | 'uid' | 'title'>
    ) }
  )>>> }
);

export type OverviewBlocksDraftByPublicationOrNullQueryVariables = Types.Exact<{
  publicationId: Types.Scalars['UUID'];
}>;


export type OverviewBlocksDraftByPublicationOrNullQuery = (
  { __typename?: 'Query' }
  & { overviewBlocksDraftByPublicationOrNull?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'OverviewBlockDraft' }
    & OverviewBlockDraftFragmentFragment
  )>>> }
);

export type UpdateOverviewBlockMutationVariables = Types.Exact<{
  data: Types.UpdateOverviewBlockInput;
}>;


export type UpdateOverviewBlockMutation = (
  { __typename?: 'Mutation' }
  & { updateOverviewBlock?: Types.Maybe<(
    { __typename?: 'UpdateOverviewBlockPayload' }
    & { blocks?: Types.Maybe<Array<(
      { __typename?: 'OverviewBlock' }
      & OverviewBlockFragmentFragment
    )>> }
  )> }
);

export type RemoveOverviewBlockMutationVariables = Types.Exact<{
  overviewBlockId: Types.Scalars['UUID'];
}>;


export type RemoveOverviewBlockMutation = (
  { __typename?: 'Mutation' }
  & { removeOverviewBlock?: Types.Maybe<(
    { __typename?: 'RemoveOverviewBlockPayload' }
    & { blocks?: Types.Maybe<Array<(
      { __typename?: 'OverviewBlock' }
      & OverviewBlockFragmentFragment
    )>> }
  )> }
);

export type RemoveOverviewBlockDraftMutationVariables = Types.Exact<{
  overviewBlockId: Types.Scalars['UUID'];
}>;


export type RemoveOverviewBlockDraftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteOverviewBlockDraft'>
);

export type MoveOverviewBlockMutationVariables = Types.Exact<{
  data: Types.MoveOverviewBlockInput;
}>;


export type MoveOverviewBlockMutation = (
  { __typename?: 'Mutation' }
  & { moveOverviewBlock?: Types.Maybe<(
    { __typename?: 'MoveOverviewBlockPayload' }
    & Pick<Types.MoveOverviewBlockPayload, 'status'>
  )> }
);

export type PublishOverviewBlocksDraftMutationVariables = Types.Exact<{
  data: Types.PublishOverviewBlocksDraftInput;
}>;


export type PublishOverviewBlocksDraftMutation = (
  { __typename?: 'Mutation' }
  & { publishOverviewBlocksDraft?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'OverviewBlock' }
    & OverviewBlockFragmentFragment
  )>>> }
);

export type OverviewBlockFragmentFragment = (
  { __typename?: 'OverviewBlock' }
  & Pick<Types.OverviewBlock, 'id' | 'uid' | 'title' | 'subtitle' | 'clickCount' | 'position'>
  & { figureVersion?: Types.Maybe<(
    { __typename?: 'FigureVersion' }
    & Pick<Types.FigureVersion, 'id'>
    & { figure: (
      { __typename?: 'Figure' }
      & Pick<Types.Figure, 'uid' | 'src'>
      & { cropperPreview: (
        { __typename?: 'Image' }
        & Pick<Types.Image, 'url'>
      ), latestVersion: (
        { __typename?: 'FigureVersion' }
        & Pick<Types.FigureVersion, 'uid'>
        & { crop: (
          { __typename?: 'Crop' }
          & Pick<Types.Crop, 'height' | 'left' | 'top' | 'width'>
        ), primaryProcessedImage?: Types.Maybe<(
          { __typename?: 'Image' }
          & Pick<Types.Image, 'url'>
        )> }
      ) }
    ) }
  )> }
);

export type OverviewBlockDraftFragmentFragment = (
  { __typename?: 'OverviewBlockDraft' }
  & Pick<Types.OverviewBlockDraft, 'id' | 'position'>
  & { region: (
    { __typename?: 'Region' }
    & Pick<Types.Region, 'id' | 'uid'>
  ), publication: (
    { __typename?: 'Publication' }
    & Pick<Types.Publication, 'id' | 'uid'>
  ) }
);

export const OverviewBlockFragmentFragmentDoc = gql`
    fragment OverviewBlockFragment on OverviewBlock {
  id
  uid
  title
  subtitle
  clickCount
  position
  figureVersion {
    id
    figure {
      uid
      cropperPreview {
        url
      }
      src
      latestVersion {
        uid
        crop {
          height
          left
          top
          width
        }
        primaryProcessedImage {
          url
        }
      }
    }
  }
}
    `;
export const OverviewBlockDraftFragmentFragmentDoc = gql`
    fragment OverviewBlockDraftFragment on OverviewBlockDraft {
  id
  position
  region {
    id
    uid
  }
  publication {
    id
    uid
  }
}
    `;
export const CreateOverviewBlockDocument = gql`
    mutation createOverviewBlock($data: CreateOverviewBlockInput!) {
  createOverviewBlock(data: $data) {
    block {
      ...OverviewBlockFragment
    }
  }
}
    ${OverviewBlockFragmentFragmentDoc}`;
export type CreateOverviewBlockMutationFn = Apollo.MutationFunction<CreateOverviewBlockMutation, CreateOverviewBlockMutationVariables>;

/**
 * __useCreateOverviewBlockMutation__
 *
 * To run a mutation, you first call `useCreateOverviewBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOverviewBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOverviewBlockMutation, { data, loading, error }] = useCreateOverviewBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOverviewBlockMutation(baseOptions?: Apollo.MutationHookOptions<CreateOverviewBlockMutation, CreateOverviewBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOverviewBlockMutation, CreateOverviewBlockMutationVariables>(CreateOverviewBlockDocument, options);
      }
export type CreateOverviewBlockMutationHookResult = ReturnType<typeof useCreateOverviewBlockMutation>;
export type CreateOverviewBlockMutationResult = Apollo.MutationResult<CreateOverviewBlockMutation>;
export type CreateOverviewBlockMutationOptions = Apollo.BaseMutationOptions<CreateOverviewBlockMutation, CreateOverviewBlockMutationVariables>;
export const CreateOverviewBlockDraftDocument = gql`
    mutation createOverviewBlockDraft($data: CreateOverviewBlockDraftInput!) {
  createOverviewBlockDraft(data: $data) {
    ...OverviewBlockDraftFragment
  }
}
    ${OverviewBlockDraftFragmentFragmentDoc}`;
export type CreateOverviewBlockDraftMutationFn = Apollo.MutationFunction<CreateOverviewBlockDraftMutation, CreateOverviewBlockDraftMutationVariables>;

/**
 * __useCreateOverviewBlockDraftMutation__
 *
 * To run a mutation, you first call `useCreateOverviewBlockDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOverviewBlockDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOverviewBlockDraftMutation, { data, loading, error }] = useCreateOverviewBlockDraftMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOverviewBlockDraftMutation(baseOptions?: Apollo.MutationHookOptions<CreateOverviewBlockDraftMutation, CreateOverviewBlockDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOverviewBlockDraftMutation, CreateOverviewBlockDraftMutationVariables>(CreateOverviewBlockDraftDocument, options);
      }
export type CreateOverviewBlockDraftMutationHookResult = ReturnType<typeof useCreateOverviewBlockDraftMutation>;
export type CreateOverviewBlockDraftMutationResult = Apollo.MutationResult<CreateOverviewBlockDraftMutation>;
export type CreateOverviewBlockDraftMutationOptions = Apollo.BaseMutationOptions<CreateOverviewBlockDraftMutation, CreateOverviewBlockDraftMutationVariables>;
export const OverviewBlocksDocument = gql`
    query overviewBlocks($regionId: UUID!) {
  overviewBlocks(regionId: $regionId) {
    edges {
      node {
        ...OverviewBlockFragment
      }
    }
  }
}
    ${OverviewBlockFragmentFragmentDoc}`;

/**
 * __useOverviewBlocksQuery__
 *
 * To run a query within a React component, call `useOverviewBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewBlocksQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useOverviewBlocksQuery(baseOptions: Apollo.QueryHookOptions<OverviewBlocksQuery, OverviewBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewBlocksQuery, OverviewBlocksQueryVariables>(OverviewBlocksDocument, options);
      }
export function useOverviewBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewBlocksQuery, OverviewBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewBlocksQuery, OverviewBlocksQueryVariables>(OverviewBlocksDocument, options);
        }
export type OverviewBlocksQueryHookResult = ReturnType<typeof useOverviewBlocksQuery>;
export type OverviewBlocksLazyQueryHookResult = ReturnType<typeof useOverviewBlocksLazyQuery>;
export type OverviewBlocksQueryResult = Apollo.QueryResult<OverviewBlocksQuery, OverviewBlocksQueryVariables>;
export const OverviewBlocksByUrlOrNullDocument = gql`
    query overviewBlocksByUrlOrNull($url: String!) {
  overviewBlocksByUrlOrNull(url: $url) {
    id
    region {
      id
      uid
      title
    }
    position
  }
}
    `;

/**
 * __useOverviewBlocksByUrlOrNullQuery__
 *
 * To run a query within a React component, call `useOverviewBlocksByUrlOrNullQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewBlocksByUrlOrNullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewBlocksByUrlOrNullQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useOverviewBlocksByUrlOrNullQuery(baseOptions: Apollo.QueryHookOptions<OverviewBlocksByUrlOrNullQuery, OverviewBlocksByUrlOrNullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewBlocksByUrlOrNullQuery, OverviewBlocksByUrlOrNullQueryVariables>(OverviewBlocksByUrlOrNullDocument, options);
      }
export function useOverviewBlocksByUrlOrNullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewBlocksByUrlOrNullQuery, OverviewBlocksByUrlOrNullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewBlocksByUrlOrNullQuery, OverviewBlocksByUrlOrNullQueryVariables>(OverviewBlocksByUrlOrNullDocument, options);
        }
export type OverviewBlocksByUrlOrNullQueryHookResult = ReturnType<typeof useOverviewBlocksByUrlOrNullQuery>;
export type OverviewBlocksByUrlOrNullLazyQueryHookResult = ReturnType<typeof useOverviewBlocksByUrlOrNullLazyQuery>;
export type OverviewBlocksByUrlOrNullQueryResult = Apollo.QueryResult<OverviewBlocksByUrlOrNullQuery, OverviewBlocksByUrlOrNullQueryVariables>;
export const OverviewBlocksDraftByPublicationOrNullDocument = gql`
    query overviewBlocksDraftByPublicationOrNull($publicationId: UUID!) {
  overviewBlocksDraftByPublicationOrNull(publicationId: $publicationId) {
    ...OverviewBlockDraftFragment
  }
}
    ${OverviewBlockDraftFragmentFragmentDoc}`;

/**
 * __useOverviewBlocksDraftByPublicationOrNullQuery__
 *
 * To run a query within a React component, call `useOverviewBlocksDraftByPublicationOrNullQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewBlocksDraftByPublicationOrNullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewBlocksDraftByPublicationOrNullQuery({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useOverviewBlocksDraftByPublicationOrNullQuery(baseOptions: Apollo.QueryHookOptions<OverviewBlocksDraftByPublicationOrNullQuery, OverviewBlocksDraftByPublicationOrNullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewBlocksDraftByPublicationOrNullQuery, OverviewBlocksDraftByPublicationOrNullQueryVariables>(OverviewBlocksDraftByPublicationOrNullDocument, options);
      }
export function useOverviewBlocksDraftByPublicationOrNullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewBlocksDraftByPublicationOrNullQuery, OverviewBlocksDraftByPublicationOrNullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewBlocksDraftByPublicationOrNullQuery, OverviewBlocksDraftByPublicationOrNullQueryVariables>(OverviewBlocksDraftByPublicationOrNullDocument, options);
        }
export type OverviewBlocksDraftByPublicationOrNullQueryHookResult = ReturnType<typeof useOverviewBlocksDraftByPublicationOrNullQuery>;
export type OverviewBlocksDraftByPublicationOrNullLazyQueryHookResult = ReturnType<typeof useOverviewBlocksDraftByPublicationOrNullLazyQuery>;
export type OverviewBlocksDraftByPublicationOrNullQueryResult = Apollo.QueryResult<OverviewBlocksDraftByPublicationOrNullQuery, OverviewBlocksDraftByPublicationOrNullQueryVariables>;
export const UpdateOverviewBlockDocument = gql`
    mutation updateOverviewBlock($data: UpdateOverviewBlockInput!) {
  updateOverviewBlock(data: $data) {
    blocks {
      ...OverviewBlockFragment
    }
  }
}
    ${OverviewBlockFragmentFragmentDoc}`;
export type UpdateOverviewBlockMutationFn = Apollo.MutationFunction<UpdateOverviewBlockMutation, UpdateOverviewBlockMutationVariables>;

/**
 * __useUpdateOverviewBlockMutation__
 *
 * To run a mutation, you first call `useUpdateOverviewBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOverviewBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOverviewBlockMutation, { data, loading, error }] = useUpdateOverviewBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOverviewBlockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOverviewBlockMutation, UpdateOverviewBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOverviewBlockMutation, UpdateOverviewBlockMutationVariables>(UpdateOverviewBlockDocument, options);
      }
export type UpdateOverviewBlockMutationHookResult = ReturnType<typeof useUpdateOverviewBlockMutation>;
export type UpdateOverviewBlockMutationResult = Apollo.MutationResult<UpdateOverviewBlockMutation>;
export type UpdateOverviewBlockMutationOptions = Apollo.BaseMutationOptions<UpdateOverviewBlockMutation, UpdateOverviewBlockMutationVariables>;
export const RemoveOverviewBlockDocument = gql`
    mutation removeOverviewBlock($overviewBlockId: UUID!) {
  removeOverviewBlock(data: {overviewBlockId: $overviewBlockId}) {
    blocks {
      ...OverviewBlockFragment
    }
  }
}
    ${OverviewBlockFragmentFragmentDoc}`;
export type RemoveOverviewBlockMutationFn = Apollo.MutationFunction<RemoveOverviewBlockMutation, RemoveOverviewBlockMutationVariables>;

/**
 * __useRemoveOverviewBlockMutation__
 *
 * To run a mutation, you first call `useRemoveOverviewBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOverviewBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOverviewBlockMutation, { data, loading, error }] = useRemoveOverviewBlockMutation({
 *   variables: {
 *      overviewBlockId: // value for 'overviewBlockId'
 *   },
 * });
 */
export function useRemoveOverviewBlockMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOverviewBlockMutation, RemoveOverviewBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOverviewBlockMutation, RemoveOverviewBlockMutationVariables>(RemoveOverviewBlockDocument, options);
      }
export type RemoveOverviewBlockMutationHookResult = ReturnType<typeof useRemoveOverviewBlockMutation>;
export type RemoveOverviewBlockMutationResult = Apollo.MutationResult<RemoveOverviewBlockMutation>;
export type RemoveOverviewBlockMutationOptions = Apollo.BaseMutationOptions<RemoveOverviewBlockMutation, RemoveOverviewBlockMutationVariables>;
export const RemoveOverviewBlockDraftDocument = gql`
    mutation removeOverviewBlockDraft($overviewBlockId: UUID!) {
  deleteOverviewBlockDraft(id: $overviewBlockId)
}
    `;
export type RemoveOverviewBlockDraftMutationFn = Apollo.MutationFunction<RemoveOverviewBlockDraftMutation, RemoveOverviewBlockDraftMutationVariables>;

/**
 * __useRemoveOverviewBlockDraftMutation__
 *
 * To run a mutation, you first call `useRemoveOverviewBlockDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOverviewBlockDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOverviewBlockDraftMutation, { data, loading, error }] = useRemoveOverviewBlockDraftMutation({
 *   variables: {
 *      overviewBlockId: // value for 'overviewBlockId'
 *   },
 * });
 */
export function useRemoveOverviewBlockDraftMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOverviewBlockDraftMutation, RemoveOverviewBlockDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOverviewBlockDraftMutation, RemoveOverviewBlockDraftMutationVariables>(RemoveOverviewBlockDraftDocument, options);
      }
export type RemoveOverviewBlockDraftMutationHookResult = ReturnType<typeof useRemoveOverviewBlockDraftMutation>;
export type RemoveOverviewBlockDraftMutationResult = Apollo.MutationResult<RemoveOverviewBlockDraftMutation>;
export type RemoveOverviewBlockDraftMutationOptions = Apollo.BaseMutationOptions<RemoveOverviewBlockDraftMutation, RemoveOverviewBlockDraftMutationVariables>;
export const MoveOverviewBlockDocument = gql`
    mutation moveOverviewBlock($data: MoveOverviewBlockInput!) {
  moveOverviewBlock(data: $data) {
    status
  }
}
    `;
export type MoveOverviewBlockMutationFn = Apollo.MutationFunction<MoveOverviewBlockMutation, MoveOverviewBlockMutationVariables>;

/**
 * __useMoveOverviewBlockMutation__
 *
 * To run a mutation, you first call `useMoveOverviewBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveOverviewBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveOverviewBlockMutation, { data, loading, error }] = useMoveOverviewBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMoveOverviewBlockMutation(baseOptions?: Apollo.MutationHookOptions<MoveOverviewBlockMutation, MoveOverviewBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveOverviewBlockMutation, MoveOverviewBlockMutationVariables>(MoveOverviewBlockDocument, options);
      }
export type MoveOverviewBlockMutationHookResult = ReturnType<typeof useMoveOverviewBlockMutation>;
export type MoveOverviewBlockMutationResult = Apollo.MutationResult<MoveOverviewBlockMutation>;
export type MoveOverviewBlockMutationOptions = Apollo.BaseMutationOptions<MoveOverviewBlockMutation, MoveOverviewBlockMutationVariables>;
export const PublishOverviewBlocksDraftDocument = gql`
    mutation publishOverviewBlocksDraft($data: PublishOverviewBlocksDraftInput!) {
  publishOverviewBlocksDraft(data: $data) {
    ...OverviewBlockFragment
  }
}
    ${OverviewBlockFragmentFragmentDoc}`;
export type PublishOverviewBlocksDraftMutationFn = Apollo.MutationFunction<PublishOverviewBlocksDraftMutation, PublishOverviewBlocksDraftMutationVariables>;

/**
 * __usePublishOverviewBlocksDraftMutation__
 *
 * To run a mutation, you first call `usePublishOverviewBlocksDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishOverviewBlocksDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishOverviewBlocksDraftMutation, { data, loading, error }] = usePublishOverviewBlocksDraftMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePublishOverviewBlocksDraftMutation(baseOptions?: Apollo.MutationHookOptions<PublishOverviewBlocksDraftMutation, PublishOverviewBlocksDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishOverviewBlocksDraftMutation, PublishOverviewBlocksDraftMutationVariables>(PublishOverviewBlocksDraftDocument, options);
      }
export type PublishOverviewBlocksDraftMutationHookResult = ReturnType<typeof usePublishOverviewBlocksDraftMutation>;
export type PublishOverviewBlocksDraftMutationResult = Apollo.MutationResult<PublishOverviewBlocksDraftMutation>;
export type PublishOverviewBlocksDraftMutationOptions = Apollo.BaseMutationOptions<PublishOverviewBlocksDraftMutation, PublishOverviewBlocksDraftMutationVariables>;