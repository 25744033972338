import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import {onError} from '@apollo/client/link/error'
import {useKeycloak} from '@react-keycloak/web'
import {relayStylePagination} from '@apollo/client/utilities'
import {RetryLink} from '@apollo/client/link/retry'
import {toast} from 'react-toastify'
import {ToastError} from '@components/UI/ToastifyComponents/ToastError/ToastError'
import {generateGuid} from '@utils/generateGuid'
import {APOLLO_CLIENT_CONSTS} from './apollo-client.consts'

const useApollo = (): ApolloClient<NormalizedCacheObject> => {
  const {keycloak, initialized} = useKeycloak()

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({headers = {}}) => ({
      headers: {
        ...headers,
        authorization: initialized ? `Bearer ${keycloak.token}` : ''
      }
    }))

    return forward(operation)
  })

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI
  })

  const errorLink = onError(({graphQLErrors, networkError, operation}) => {
    if (graphQLErrors) {
      console.error(graphQLErrors)
      for (const graphQLError of graphQLErrors) {
        if (graphQLError.extensions?.code === 'UNAUTHENTICATED') {
          keycloak.logout()
          return
        }

        const errorMessage =
          graphQLError.extensions?.userMessage || graphQLError.message || graphQLError.extensions?.code || ''
        const detailInfo = graphQLError.extensions?.exception || graphQLError

        const guid = generateGuid()

        toast(
          <ToastError text={errorMessage} detailInfo={detailInfo} id={guid} canRetry={true} operation={operation} />,
          {
            toastId: guid,
            closeButton: true,
            containerId: 'error'
          }
        )
      }
    }
    if (networkError) {
      let errorMessage = ''
      let canRetry = false

      switch (networkError['statusCode']) {
        case 400: {
          errorMessage = APOLLO_CLIENT_CONSTS.error400
          break
        }
        case 403: {
          errorMessage = APOLLO_CLIENT_CONSTS.error403
          break
        }
        case 409: {
          errorMessage = APOLLO_CLIENT_CONSTS.error409
          break
        }
        case 501: {
          errorMessage = APOLLO_CLIENT_CONSTS.error501
          canRetry = true
          break
        }
        case 502: {
          errorMessage = APOLLO_CLIENT_CONSTS.error502
          canRetry = true
          break
        }
        case 503: {
          errorMessage = APOLLO_CLIENT_CONSTS.error503
          canRetry = true
          break
        }
        case 504: {
          errorMessage = APOLLO_CLIENT_CONSTS.error504
          canRetry = true
          break
        }
        default: {
          errorMessage = networkError['statusCode'].toString()
        }
      }
      console.error(`[Network error]: ${networkError}`)

      const guid = generateGuid()
      toast(
        <ToastError
          text={errorMessage}
          detailInfo={networkError}
          id={guid}
          canRetry={canRetry}
          operation={operation}
        />,
        {
          toastId: guid,
          containerId: 'error',
          closeButton: true
        }
      )
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error
    }
  })

  return new ApolloClient({
    link: from([retryLink, authLink, errorLink.concat(httpLink)]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            stories: relayStylePagination(),
            tags: relayStylePagination(),
            collections: relayStylePagination(),
            staffByCursor: relayStylePagination(),
            authorsFilterByCursor: relayStylePagination(),
            publications: relayStylePagination(),
            comments: relayStylePagination(),
            staff: relayStylePagination()
          }
        },
        PublicationsConnection: {
          merge: true
        }
      },
      dataIdFromObject(responseObject) {
        if (responseObject.cursor) {
          return `${responseObject.__typename}:${responseObject.cursor}`
        }
        if (responseObject.uid) {
          return `${responseObject.__typename}:${responseObject.uid}`
        }
        return defaultDataIdFromObject(responseObject)
      }
    }),
    // defaultOptions: {query: {fetchPolicy: 'no-cache'}},
    connectToDevTools: true
  })
}

export default useApollo
