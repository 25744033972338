import styled from 'styled-components'
import {mainTextColor} from '../../../@theme/vars'

export const NothinFoundedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${mainTextColor};
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 10px;

  & div {
    margin-bottom: 10px;
  }
`
