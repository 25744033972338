import React, {FC, useCallback} from 'react'
import styled from 'styled-components'
import {IconButton, Typography, Box} from '@mui/material'
import {dividerColor} from '../../../../@theme/vars'
import {TitleLink} from '../../../UI/styled'
import {useSnackbar} from 'notistack'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import RefreshIcon from '@mui/icons-material/Refresh'
import {useActivateTagMutation, useDeactivateTagMutation} from '@pages/Tags/gql/Tags.generated'
import {Tag} from '@graphql/types'

type TagItemProps = {
  tag: Tag
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 5px 13px;

  border-bottom: 1px solid ${dividerColor};
`

const TagItem: FC<TagItemProps> = ({tag}) => {
  const snackbar = useSnackbar()
  const [deactivateTag] = useDeactivateTagMutation()
  const [activateTag] = useActivateTagMutation()

  const handleDeleteTag = useCallback(async () => {
    try {
      await deactivateTag({
        variables: {uid: tag.uid},
        refetchQueries: ['tagsList']
      })
      snackbar.enqueueSnackbar('Тэг удален', {
        variant: 'success'
      })
    } catch (e) {
      console.error(e)
    }
  }, [deactivateTag, tag.uid, snackbar])
  const handleActivateTag = useCallback(async () => {
    try {
      await activateTag({
        variables: {uid: tag.uid},
        refetchQueries: ['tagsList']
      })
      snackbar.enqueueSnackbar('Тэг удален', {
        variant: 'success'
      })
    } catch (e) {
      console.error(e)
    }
  }, [activateTag, tag.uid, snackbar])

  return (
    <Wrapper>
      <Box>
        <Typography variant='h2'>
          <TitleLink to={`/tag/editor/${tag.uid}`}>{tag.title}</TitleLink>
        </Typography>
        <Box marginTop='7px'>
          <Typography variant='subtitle1'>{tag.description}</Typography>
        </Box>
      </Box>
      {tag.isActive && (
        <IconButton aria-label='Перейти к истории изменений' onClick={handleDeleteTag}>
          <DeleteForeverIcon className='icon' color='disabled' />
        </IconButton>
      )}
      {!tag.isActive && (
        <IconButton aria-label='Перейти к истории изменений' onClick={handleActivateTag}>
          <RefreshIcon className='icon' color='disabled' />
        </IconButton>
      )}
    </Wrapper>
  )
}

export default TagItem
