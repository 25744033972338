import React, {FC, useEffect, useMemo, useState} from 'react'
import {useHistory, useParams} from 'react-router'
import {useTopNewsBlockHistoryQuery} from '../../../../pages/MainSelection/TopNewsBlockHistory/gql/TopNewsBlockHistory.generated'
import {TopNewsBannerHistoryItem} from './TopNewsBannerHistoryItem/TopNewsBannerHistoryItem'
import {CreatedInfoWrapper, HeaderWithBorder, TitleWrapper} from './TopNewsBannerHistoryContent.styles'
import {IconButton} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {TOP_NEWS_BANNER_HISTORY_CONTENT} from './TopNewsBannerHistoryContent.consts'
import {FigureVersion, Region, TopNewsBlock, User} from '../../../../@graphql/types'
import {ru} from 'date-fns/locale'
import {format} from 'date-fns'

/**
 * Компонент для контента страницы истории изменений баннера
 * @returns
 */
export const TopNewsBannerHistoryContent: FC = () => {
  const history = useHistory()
  const {regionId, uid} = useParams<{regionId: string; uid: string}>()

  const [authorFullName, setAuthorFullName] = useState('')
  const [createdDate, setCreatedDate] = useState('')

  const variables = useMemo(
    () => ({
      topNewsBlockId: uid
    }),
    [uid]
  )

  const {data, loading} = useTopNewsBlockHistoryQuery({
    variables: variables,
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (data?.topNewsBlockHistory?.length && data?.topNewsBlockHistory?.length > 0) {
      const firstName = data.topNewsBlockHistory[data.topNewsBlockHistory.length - 1].user.firstname || ''
      const lastName = data.topNewsBlockHistory[data.topNewsBlockHistory.length - 1].user.lastname || ''
      setAuthorFullName(`${firstName} ${lastName}`)
      setCreatedDate(
        format(
          new Date(data.topNewsBlockHistory[data.topNewsBlockHistory.length - 1].createdAt),
          'dd MMMM yyyy HH:mm',
          {
            locale: ru
          }
        )
      )
    }
  }, [data])

  return (
    <div>
      <HeaderWithBorder>
        <IconButton onClick={() => history.push(`/main-page/top-news/history/${regionId}`)}>
          <ArrowBackIcon />
        </IconButton>
        <TitleWrapper>{TOP_NEWS_BANNER_HISTORY_CONTENT.titleMsg}</TitleWrapper>
      </HeaderWithBorder>
      {data?.topNewsBlockHistory?.length && (
        <CreatedInfoWrapper>
          <div>{`${TOP_NEWS_BANNER_HISTORY_CONTENT.pasteOnSite} ${authorFullName}`}</div>
          <div>&nbsp;</div>
          <div>{` • ${createdDate} • `}</div>
          <div>&nbsp;</div>
          <div>{`${data?.topNewsBlockHistory?.length} ${TOP_NEWS_BANNER_HISTORY_CONTENT.changesMsg}`}</div>
        </CreatedInfoWrapper>
      )}
      <div style={{maxHeight: '75vh', overflowY: 'auto'}}>
        {data?.topNewsBlockHistory?.length &&
          data?.topNewsBlockHistory?.map((item, ind) => (
            <div key={item.uid}>
              <TopNewsBannerHistoryItem
                id={item.id}
                block={
                  ind < (data?.topNewsBlockHistory?.length || 0) - 1
                    ? ({
                        id: data?.topNewsBlockHistory[ind + 1].id,
                        position: data?.topNewsBlockHistory[ind + 1].newPosition,
                        url: data?.topNewsBlockHistory[ind + 1].newUrl,
                        title: data?.topNewsBlockHistory[ind + 1].newTitle,
                        isExclusive: data?.topNewsBlockHistory[ind + 1].newIsExclusive,
                        figureVersion: data?.topNewsBlockHistory[ind + 1].newFigureVersion
                      } as TopNewsBlock)
                    : undefined
                }
                user={item.user as User}
                isFigureVersionChanged={item.isFigureVersionChanged}
                newFigureVersion={item.newFigureVersion as FigureVersion}
                isTitleChanged={item.isTitleChanged}
                newTitle={item.newTitle || ''}
                isUrlChanged={item.isUrlChanged}
                newUrl={item.newUrl || ''}
                isRegionChanged={item.isRegionChanged}
                newRegion={item.newRegion as Region}
                isPositionChanged={item.isPositionChanged}
                newPosition={item.newPosition || 0}
                isIsExclusiveChanged={item.isIsExclusiveChanged}
                newIsExclusive={item.newIsExclusive || false}
                createdAt={item.createdAt}
              />
            </div>
          ))}
      </div>
    </div>
  )
}
