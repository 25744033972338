import styled from 'styled-components'
import {screenS} from '../../../@theme/vars'

export const VideoLoaderWrapper = styled.div`
  max-width: 652px;
  @media (max-width: ${screenS}) {
    margin: 0 auto;
    max-width: 320px;
  }
`
export const ImageLoaderWrapper = styled.div`
  max-width: 652px;
  @media (max-width: ${screenS}) {
    margin: 0 auto;
    max-width: 320px;
  }
`
