import {makeAutoObservable} from 'mobx'
import {CommentsStates} from '@entities/types/CommentsStates'
import {ICommentsStore} from './ICommentsStore'

class CommentsStore implements ICommentsStore {
  totalCount = 0
  commentsStates: CommentsStates[] = []
  moderateCommentsActionLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  setCommentsStates = (commentsStates: CommentsStates[]): void => {
    this.commentsStates = commentsStates
  }

  setCommentsStatesWithPrevState = (cb: (prev: CommentsStates[]) => CommentsStates[]): void => {
    this.commentsStates = cb(this.commentsStates)
  }

  moderateCommentByUid = (index: number, commentUid: string, viewed: boolean, value: boolean): void => {
    const item = this.commentsStates.findIndex(comment => comment.commentUid === commentUid)
    const isBadValue = viewed === true ? undefined : value === false ? true : false

    if (item > -1) {
      this.commentsStates[item].isBad = isBadValue
      this.commentsStates[item].viewed = viewed
      return
    }
    this.commentsStates.push({
      index,
      commentUid,
      isBad: isBadValue,
      viewed
    })
  }

  resetCommentUids = (): void => {
    this.commentsStates = this.commentsStates.filter(comment => comment.viewed === true)
  }

  setModerateCommentsActionLoading = (value: boolean): void => {
    this.moderateCommentsActionLoading = value
  }

  setTotalCount = (val: number): void => {
    this.totalCount = val
  }
}

export default CommentsStore
