import styled from 'styled-components'
import {blueColor} from '@theme/vars'

export const PublicationOverviewBlockFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 24px;

  @media screen and (max-width: 750px) {
    padding-left: 0px;
  }
`

export const PublicationOverviewBlockInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${blueColor};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: -15px;
`
