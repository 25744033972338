import React, {FC, useEffect, useMemo, useState} from 'react'
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material'
import {useKeycloak} from '@react-keycloak/web'
import {redColor, warnColor} from '@theme/vars'
import {TitleLength} from './PublicationOverviewBlockFieldsTitle.styles'
import {PUBLICATION_OVERVIEW_BLOCK_FIELDS_TITLE_CONSTS} from './PublicationOverviewBlockFieldsTitle.consts'
import {PublicationOverviewBlockFieldsTitleProps} from './PublicationOverviewBlockFieldsTitleProps'

const PublicationOverviewBlockFieldsTitle: FC<PublicationOverviewBlockFieldsTitleProps> = ({
  titleLabel,
  titleMaxLength,
  field,
  required,
  textErrorChange
}) => {
  const [errorText, setErrorText] = useState('')
  const [warningText, setWarningText] = useState('')

  useEffect(() => {
    textErrorChange(errorText || warningText)
  }, [textErrorChange, errorText, warningText])

  useEffect(() => {
    if (field?.value?.length > titleMaxLength) {
      setWarningText(PUBLICATION_OVERVIEW_BLOCK_FIELDS_TITLE_CONSTS.characterLimitReachedMsg)
      setErrorText('')
    } else {
      if (required && !field?.value?.length) {
        setErrorText(PUBLICATION_OVERVIEW_BLOCK_FIELDS_TITLE_CONSTS.fieldIsRequiredMsg)
        setWarningText('')
      } else {
        setErrorText('')
        setWarningText('')
      }
    }
  }, [field, titleMaxLength, required])

  const {keycloak} = useKeycloak()

  const cantEditPublication = useMemo(
    () => !keycloak.hasResourceRole('edit-published', 'publications'),

    [keycloak]
  )

  return (
    <FormControl
      fullWidth
      style={{
        marginBottom: '10px'
      }}
    >
      <div style={{flexDirection: 'row'}}>
        <InputLabel
          style={{
            width: 'calc(100% - 70px)'
          }}
        >
          {titleLabel}
        </InputLabel>
        <OutlinedInput
          label={titleLabel}
          {...field}
          error={!!errorText || !!warningText}
          multiline
          disabled={cantEditPublication}
          style={{
            fontSize: '16px',
            fontStyle: 'inherit',
            float: 'left',
            width: 'calc(100% - 70px)',
            color: !!errorText ? redColor : !!warningText ? warnColor : 'inherit'
          }}
        />
        <div>
          <TitleLength
            style={{
              display: 'flex',
              float: 'right',
              color: field?.value?.length > titleMaxLength ? redColor : 'inherit'
            }}
          >
            {`${field?.value?.length || 0} / ${titleMaxLength}`}
          </TitleLength>
          {!!errorText && (
            <FormHelperText
              style={{
                float: 'left',
                color: redColor
              }}
            >
              {errorText}
            </FormHelperText>
          )}
          {!!warningText && (
            <FormHelperText
              style={{
                float: 'left',
                color: warnColor
              }}
            >
              {warningText}
            </FormHelperText>
          )}
        </div>
      </div>
    </FormControl>
  )
}

export default PublicationOverviewBlockFieldsTitle
