import {styled} from 'styled-components'
import {blackColor, screenS, secondTextColor} from '@theme/vars'

export const CropperContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-top: -8px;

  @media screen and (max-width: ${screenS}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const PreviewBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;

  @media screen and (max-width: ${screenS}) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
`

export const PreviewBlockImageWrapper = styled.div`
  width: 250px;
  height: 180px;
  margin-left: 16px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  gap: 4px;
  background-color: ${blackColor};
  justify-content: center;

  @media screen and (max-width: ${screenS}) {
    width: 100%;
    height: auto;
    aspect-ratio: 25 / 18;
    margin-left: 0px;
  }
`

export const PreviewBlockContainerWrapper = styled.div`
  width: auto;
  max-width: 250px;
  height: 100%;
  max-height: 180px;
  overflow: hidden;
  background-color: ${blackColor};
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${screenS}) {
    min-width: 250px;
    width: 100%;
    max-width: 100%;
    max-height: inherit;
    height: auto;
    aspect-ratio: 25 / 18;
  }
`

export const GalleryViewTitle = styled.div`
  margin-top: 8px;
  margin-left: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: ${secondTextColor};
`

export const ImagePreviewWrapper = styled.div`
  width: 250px;
  height: 182px;
  overflow: hidden;

  img {
    object-fit: contain;
  }

  @media screen and (max-width: ${screenS}) {
    width: 100%;
    height: auto;
    aspect-ratio: 25 / 18;
  }
`
