import {Box} from '@mui/material'
import styled from 'styled-components'

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
`
