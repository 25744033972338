import styled from 'styled-components'
import {blueColor, grey35, mainTextColor, whiteColor} from '../../../../../../@theme/vars'
import {Button, MenuItem} from '@mui/material'
import {Controller} from 'react-hook-form'

export const PlaceSelectBtn = styled(Button)`
  width: 106px;
  position: absolute;
  right: 24px;
  border-color: #c4c4c4;
`

export const PlaceSelectBtnSpan = styled.span`
  color: ${mainTextColor};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`
