import {makeAutoObservable} from 'mobx'
import PublicationStore from '@stores/publicationStore/publicationStore'
import {Figure, GalleryImage} from '@graphql/types'
import IModals from '../IModals'
import IGalleryModalStore from '../IGalleryModalStore'
import {GalleryItem} from '../../../../types/GalleryItem'

/**
 * Для работы с редактированием галерей
 */
class GalleryModalStore implements IGalleryModalStore, IModals {
  publicationStore: PublicationStore

  galleryDetail: GalleryItem = {
    id: '',
    uid: '',
    galleryImages: []
  }
  isModalDirty = false
  isOpen = false
  isImageEditorOpen = false
  isValid = false
  galleryModalLoading = false
  galleryErrors: Map<string, string> = new Map()
  galleryImageForImageEditor = {} as GalleryImage
  showUploadFields = true

  constructor(publicationStore: PublicationStore) {
    this.publicationStore = publicationStore
    makeAutoObservable(this)
  }
  setGalleryModalLoading = (val: boolean): void => {
    this.galleryModalLoading = val
  }

  setIsModalDirty = (value: boolean): void => {
    this.isModalDirty = value
  }
  setIsOpen = (value: boolean): void => {
    this.isOpen = value
  }

  setIsImageEditorOpen = (value: boolean): void => {
    this.isImageEditorOpen = value
  }

  setGalleryDetail = (value: GalleryItem): void => {
    this.galleryDetail = value
  }

  setGalleryDetailWithPrevState = (cb: (prev: GalleryItem) => GalleryItem): void => {
    this.galleryDetail = cb(this.galleryDetail)
    this.setIsModalDirty(true)
  }

  setGalleryImageById = (value: GalleryImage): void => {
    const curImage = this.galleryDetail.galleryImages.findIndex(val => val.id === value.id)

    this.setIsModalDirty(true)

    if (curImage > -1) {
      this.galleryDetail.galleryImages[curImage] = value
      return
    }

    this.galleryDetail.galleryImages.push(value)
  }
  setIsValid = (value: boolean): void => {
    this.isValid = value
  }

  setGalleryError = (uid: string, error: string): void => {
    this.galleryErrors.set(uid, error)
  }

  deleteGalleryError = (uid: string): void => {
    this.galleryErrors.delete(uid)
  }

  setGalleryImageForImageEditor = (value: GalleryImage): void => {
    this.galleryImageForImageEditor = value
  }

  setShowUploadFields = (value: boolean): void => {
    this.showUploadFields = value
  }

  reset = (): void => {
    this.galleryDetail = {
      id: '',
      uid: '',
      galleryImages: []
    }
    this.setIsModalDirty(false)
    this.galleryErrors.clear()
  }
}

export default GalleryModalStore
