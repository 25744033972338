import React, {FC} from 'react'
import {Box, IconButton, Popover, Typography} from '@mui/material'
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state'
import {InfoOutlined} from '@mui/icons-material'
import styled from 'styled-components'
import {secondTextColor} from '../../../@theme/vars'
import {helpTextType} from './TextInfo'

type PopoverPopupStateProp = {
  info: helpTextType
  isWarning: boolean
}

const Link = styled.a`
  margin-top: 6px;
  color: ${secondTextColor};
  text-decoration: underline;
`

const PopoverPopupState: FC<PopoverPopupStateProp> = ({info, isWarning = true}) => {
  return (
    <PopupState variant='popover' popupId='demo-popup-popover'>
      {popupState => (
        <div>
          <IconButton size='small' {...bindTrigger(popupState)}>
            <InfoOutlined
              htmlColor={isWarning ? '#EB3846' : '#707070'}
              style={{
                transform: 'scale(0.8)'
              }}
            />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Box maxWidth='360px' padding='10px'>
              <Typography
                color='secondary'
                style={{whiteSpace: 'pre-wrap'}}
                dangerouslySetInnerHTML={{__html: info.text}}
              />
              <Typography>
                {info.url !== '' ? (
                  <Link href={info.url} target='_blank'>
                    Полный текст правил
                  </Link>
                ) : (
                  <></>
                )}
              </Typography>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

export default PopoverPopupState
