import {
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Clear, HighlightOff} from '@mui/icons-material'
import {toast} from 'react-toastify'
import {StyledFormControl} from '@components/UI/styled'
import {useStore} from '@stores/rootStoreContext'
import {AcceptLinkOnNewsIcon} from '@components/UI/Icons/AcceptLinkOnNews'
import {PublicationStatus} from '@graphql/types'
import {useUnpublishPublicationMutation} from '../../gql/PublicationEditor.generated'
import {UNPUBLISHED_NEWS_PICKER_CONSTS} from './UnpublishedNewsPicker.consts'
import {HeaderWrapper, InfoWrapper, LinkWrapper, MainHeaderWrapper} from './UnpublishedNewsPicker.styles'

export type LinkType = {
  url: string
}

const UnpublishedNewsPicker: FC<any> = () => {
  const {publicationStore} = useStore()
  const {publication, setPublicationWithPrevState} = publicationStore
  const [urlInput, setUrlInput] = useState('')
  const [errorText, setErrorText] = useState('')
  const [link, setLink] = useState<string[]>([])
  const [unpublishPublication, {loading: unpublishLoading}] = useUnpublishPublicationMutation()

  useEffect(
    () => setLink([publication?.redirectUrl || '']),

    [publication?.redirectUrl, setLink]
  )
  const label = UNPUBLISHED_NEWS_PICKER_CONSTS.relatedLinkMsg
  const homePage = UNPUBLISHED_NEWS_PICKER_CONSTS.homePage

  const handleClearUrlErrors = useCallback((url: string) => {
    if (url && url.search(new RegExp(`${process.env.REACT_APP_NEWS_REGEX}`)) !== 0) {
      setErrorText(UNPUBLISHED_NEWS_PICKER_CONSTS.invalidLinkMsg)
    } else {
      setErrorText('')
    }
    setUrlInput(url)
  }, [])

  const unpublishPublicationAction = useCallback(
    async urlInput => {
      try {
        const unpublishPublicationResult = await unpublishPublication({
          variables: {
            data: {
              publicationUid: publication?.uid,
              redirectUrl: urlInput || UNPUBLISHED_NEWS_PICKER_CONSTS.homePage
            }
          }
        })
        setLink([urlInput])
        setUrlInput('')

        if (!unpublishPublicationResult.errors?.length) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            status:
              unpublishPublicationResult.data?.unpublishPublication?.publication?.status ||
              PublicationStatus.Unpublished,
            redirectUrl:
              unpublishPublicationResult.data?.unpublishPublication?.publication?.redirectUrl ||
              UNPUBLISHED_NEWS_PICKER_CONSTS.homePage
          }))
        }

        toast.success(UNPUBLISHED_NEWS_PICKER_CONSTS.savedMsg, {
          autoClose: 1000,
          hideProgressBar: true,
          containerId: 'success'
        })
      } catch {
        console.error(UNPUBLISHED_NEWS_PICKER_CONSTS.errorMsg)
      }
    },
    [publication, setPublicationWithPrevState, unpublishPublication]
  )

  const handleRemoveLink = useCallback(
    (linkId: string) => {
      const newLink = link.filter(el => el !== linkId)
      setLink(newLink)
      unpublishPublicationAction(homePage)
    },
    [link, homePage, unpublishPublicationAction]
  )
  return (
    <LinkWrapper>
      <Box position='relative'>
        <MainHeaderWrapper>{UNPUBLISHED_NEWS_PICKER_CONSTS.linkForRedirectMsg}</MainHeaderWrapper>
        <StyledFormControl width='315px'>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            label={label}
            onChange={e => handleClearUrlErrors(e.target.value)}
            value={urlInput}
            error={!!errorText}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton edge='end' size='small' onClick={() => handleClearUrlErrors('')}>
                  <Clear fontSize='small' />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{errorText}</FormHelperText>
        </StyledFormControl>
        <IconButton
          style={{
            float: 'right',
            padding: '3px',
            marginTop: '6px'
          }}
          disabled={!urlInput || !!errorText || unpublishLoading}
          onClick={() => unpublishPublicationAction(urlInput)}
        >
          {unpublishLoading ? (
            <CircularProgress size='1rem' />
          ) : (
            <AcceptLinkOnNewsIcon color={!urlInput || !!errorText ? 'disabled' : 'primary'} fontSize='small' />
          )}
        </IconButton>
        {(!link || !link.length) && (
          <HeaderWrapper>{UNPUBLISHED_NEWS_PICKER_CONSTS.addLinkForRedirection}</HeaderWrapper>
        )}
        <Typography color='primary' component='div'>
          <InfoWrapper>{UNPUBLISHED_NEWS_PICKER_CONSTS.ifNotExists}</InfoWrapper>
        </Typography>
        {link &&
          link.map(link =>
            link === homePage ? (
              (link = '')
            ) : (
              <Box key={link} display='flex' alignItems='center'>
                <IconButton style={{marginLeft: '-11px'}} onClick={() => handleRemoveLink(link)}>
                  <HighlightOff />
                </IconButton>
                <Typography color='secondary'>{link}</Typography>
              </Box>
            )
          )}
      </Box>
    </LinkWrapper>
  )
}
export default observer(UnpublishedNewsPicker)
