import Clear from '@mui/icons-material/Clear'
import {CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'
import {FC} from 'react'
import {StyledFormControl} from '../../UI/styled'

type KeyWordsProps = {
  words: string
  changeKeyWords: (value: string) => void
  width?: string
  isLoading: boolean
}

const KeyWords: FC<KeyWordsProps> = ({words, changeKeyWords, width, isLoading}) => {
  const titleLabel = 'Ключевые слова'

  let loading = null
  if (isLoading) {
    loading = <CircularProgress size='1rem' />
  } else if (words) {
    loading = (
      <IconButton edge='end' size='small' onClick={() => changeKeyWords('')}>
        <Clear fontSize='small' />
      </IconButton>
    )
  }
  return (
    <FormControl
      fullWidth
      style={{
        width: '100%',
        maxWidth: width || '1266px'
      }}
    >
      <StyledFormControl
        style={{
          width: '100%',
          maxWidth: width || '1266px'
        }}
      >
        <InputLabel>{titleLabel}</InputLabel>
        <OutlinedInput
          inputProps={{maxLength: 9999}}
          label={titleLabel}
          onChange={e => changeKeyWords(e.target.value)}
          disabled={isLoading}
          value={words}
          type='text'
          multiline={true}
          endAdornment={<InputAdornment position='end'>{loading}</InputAdornment>}
        />
      </StyledFormControl>
    </FormControl>
  )
}

export default KeyWords
