import React, {FC, useEffect, useRef, useState} from 'react'
import {isEqual} from 'lodash'
import {GridContextProvider, GridItem, swap} from '../../../../libs/react-dnd/grid'
import useWindowResize from '../../../../hooks/useWindowResize'
import MainSelectionCard from '../../MainSelectionCard/MainSelectionCard'
import {SizedDropZone} from '../../../UI/styled'
import {CardSize} from '../../../../components/MainSelection/MainSelectionCard/MainSelectionCardProps'
import {GridDropProp} from './TopNewsGridDropProps'
import {Wrapper} from './TopNewsGridDrop.styles'

const TopNewsGridDrop: FC<GridDropProp> = ({
  topNewsBlocks,
  setTopNewsBlocks,
  handleDeleteBlock,
  onSwapItems,
  regionId
}) => {
  const [width] = useWindowResize()

  const [heightZone, setHeightZone] = useState(500)

  const [boxesPerRow, setBoxesPerRow] = useState(4)
  const boxGridDropZoneRef = useRef<any>(null)

  useEffect(() => {
    const fullBoxWidth = Math.floor(boxGridDropZoneRef.current.clientWidth / CardSize.topNews.width)
    const countBoxes = fullBoxWidth < 4 ? fullBoxWidth : 4
    setBoxesPerRow(countBoxes)
    setHeightZone((topNewsBlocks.length / countBoxes) * CardSize.topNews.height + 30)
  }, [boxGridDropZoneRef?.current?.clientWidth, topNewsBlocks.length, width])

  const onChange = (sourceId: any, sourceIndex: number, targetIndex: number): void => {
    const nextState = swap(topNewsBlocks, sourceIndex, targetIndex)
    if (!isEqual(topNewsBlocks, nextState)) {
      onSwapItems(true)
    }
    const updatedBlocks = nextState.map((block, index) => ({
      ...block,
      position: index + 1
    }))
    setTopNewsBlocks(updatedBlocks)
  }

  return (
    <Wrapper ref={boxGridDropZoneRef}>
      <GridContextProvider onChange={onChange}>
        <SizedDropZone
          id='items'
          boxesPerRow={boxesPerRow}
          rowHeight={CardSize.topNews.height + 30}
          $heightZone={heightZone}
        >
          {topNewsBlocks.map((topNewsBlock, i) => (
            <GridItem key={topNewsBlock.uid}>
              <MainSelectionCard
                index={i}
                data={topNewsBlock}
                handleDeleteBlock={handleDeleteBlock}
                regionId={regionId}
              />
            </GridItem>
          ))}
        </SizedDropZone>
      </GridContextProvider>
    </Wrapper>
  )
}

export default TopNewsGridDrop
