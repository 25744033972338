import {Link} from 'react-router-dom'
import {styled} from 'styled-components'
import {
  blueColor,
  errorColor,
  lightblueColor2,
  lightRedColor2,
  lightRedColor3,
  mainHoverColor,
  screenS
} from '@theme/vars'

export const TableRowLeft = styled.div`
  width: calc(100% - 222px);

  @media screen and (max-width: ${screenS}) {
    width: 100%;
  }
`

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`

export const TableCellRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  & span {
    display: flex;

    &:not(:first-child) {
      margin-left: 28px;
    }
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
    align-items: flex-start;

    & span:not(:first-child) {
      margin-left: 0px;
      margin-top: 4px;
    }
  }
`

export const TitleLinkWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TitleLink = styled(Link)`
  color: ${mainHoverColor};

  &.is-automatic-comments-disabled,
  &.is-automatic-comments-disabled:hover {
    background-color: ${lightRedColor3};
  }

  &:hover {
    background-color: inherit;
    cursor: pointer;
    color: ${blueColor};
    text-decoration: underline;
  }
`

export const HtmlWrapper = styled.span`
  display: inline;

  .is_bad_word {
    color: ${errorColor};
    background-color: ${lightRedColor2};
    font-weight: 500;
  }
  .is_wary_word {
    background-color: ${lightblueColor2};
    font-weight: 500;
  }
`

export const AnswerFlagWrapper = styled.div`
  display: flex;
  margin-left: 46px;
`
