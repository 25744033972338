import React, {FC} from 'react'
import {User} from '@graphql/types'
import styled from 'styled-components'
import {dividerColor, grayColor, secondTextColor} from '../../../../@theme/vars'
import {Avatar, Grid, Typography} from '@mui/material'

const Wrapper = styled(Grid)<{$isFired: boolean}>`
  padding: 10px 0;
  border-bottom: 1px solid ${dividerColor};
  background-color: ${({$isFired}) => $isFired && `#f1f1f1`};
`

const ShortInfo = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${secondTextColor};

  :not(:last-child)::after {
    display: inline;
    content: ' • ';
  }
`

const StyledLink = styled.a`
  color: #1964e7;
`

const AvatarImage = styled.div<{src: string; isFired: boolean}>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-image: url('${({src}) => src}');
  background-size: cover;
  background-position: center;
  opacity: ${({isFired}) => isFired && 0.5};
`

const StyledAvatar = styled(Avatar)<{isFired: boolean}>`
  opacity: ${({isFired}) => isFired && 0.5};
`

const FiredTitle = styled(Typography)`
  margin-left: 6px;
  color: ${grayColor};
`

const StyledContactsInfo = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`

type StaffItemProps = {
  user: User
}

const StaffItem: FC<StaffItemProps> = ({user}) => {
  return (
    <Wrapper container direction='row' justifyContent='space-around' alignItems='center' $isFired={!!user.isFired}>
      <Grid item>
        {user.avatarUrl ? (
          <AvatarImage src={user.avatarUrl} isFired={!!user.isFired} />
        ) : (
          <StyledAvatar isFired={!!user.isFired} />
        )}
      </Grid>
      <Grid container item direction='column' sm={5} xs={10}>
        <Grid container item alignItems='center'>
          <Grid>
            <Typography variant='h2'>
              {user.lastname} {user.firstname}
            </Typography>
          </Grid>
          {user.isFired && (
            <Grid>
              <FiredTitle>уволен(а)</FiredTitle>
            </Grid>
          )}
        </Grid>
        <Grid item>
          {user.department?.name && <ShortInfo>{user.department?.name}</ShortInfo>}
          {user.accessLevel?.name && <ShortInfo>{user.accessLevel?.name}</ShortInfo>}
        </Grid>
      </Grid>
      <Grid item container sm={6} justifyContent='flex-end'>
        <StyledContactsInfo item container sm={6} xs={6}>
          {user.email && <StyledLink href={`mailto:${user.email}`}>{user.email}</StyledLink>}
          {user.phone && <StyledLink href={`tel:${user.phone}`}>{user.phone}</StyledLink>}
        </StyledContactsInfo>
      </Grid>
    </Wrapper>
  )
}

export default StaffItem
