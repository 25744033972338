import React, {FC, useMemo} from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import StarIcon from '@mui/icons-material/Star'
import styled from 'styled-components'
import {IconButton, Typography} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import {Story} from '@graphql/types'
import {TitleLink} from '@components/UI/styled'
import Tooltip from '@components/UI/Tooltip/Tooltip'

const ItemWrapper = styled.div`
  font-size: 14px;
  padding-left: 12px;
  line-height: 1.2;
  color: #222;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
`

const ItemContentWrapper = styled.div``

const StarIconStyled = styled(StarIcon)`
  width: 16px;
  height: 16px;
  color: #707070;
  margin-right: 10px;
  margin-top: 2px;
`

const ItemInfo = styled.div`
  display: flex;
`
const Title = styled.h3`
  grid-area: title;
  font-weight: 500;
  line-height: 1.2;
`

const StyledTooltip = styled(Tooltip)`
  font-size: 14px;
`

type StoryItemType = {
  story: Story
  deactivateStoryAction: (uid: string) => void
  activateStoryAction: (uid: string) => void
}

const StoriesItem: FC<StoryItemType> = ({story, deactivateStoryAction, activateStoryAction}) => {
  const allRegions = useMemo(() => {
    return story?.dayThemeRegions
      ?.map(region => {
        return region.title
      })
      .join(', ')
  }, [story?.dayThemeRegions])

  return (
    <ItemWrapper>
      <ItemInfo>
        {story?.dayThemeRegions?.length !== 0 && (
          <StyledTooltip title={allRegions} placement='top'>
            <ItemContentWrapper>
              <StarIconStyled className='icon' />
            </ItemContentWrapper>
          </StyledTooltip>
        )}
        <ItemContentWrapper>
          <Title>
            <TitleLink to={`story/editor/${story.uid}`} title='Перейти в редактирование сюжета'>
              {story.title || 'Без названия'}
            </TitleLink>
          </Title>
          <Typography color='secondary' component='span' variant='subtitle1'>
            Кол-во просмотров: {story.publicationsCount}
          </Typography>
        </ItemContentWrapper>
      </ItemInfo>
      {story.isActive && (
        <IconButton aria-label='Перейти к истории изменений' onClick={() => deactivateStoryAction(story.uid)}>
          <DeleteForeverIcon className='icon' color='disabled' />
        </IconButton>
      )}
      {!story.isActive && (
        <IconButton aria-label='Перейти к истории изменений' onClick={() => activateStoryAction(story.uid)}>
          <RefreshIcon className='icon' color='disabled' />
        </IconButton>
      )}
    </ItemWrapper>
  )
}

export default StoriesItem
