import {FC, useCallback, useEffect, useState} from 'react'
import {
  Box,
  ButtonGroup,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material'
import {useParams} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {Clear} from '@mui/icons-material'
import {getCommentsListAdapterToClient} from '@entities/api/comments/adapters/getCommentsListAdapterToClient'
import defaultComment from '@entities/api/comments/comments.consts'
import {getCountBadAndNewAdapterToClient} from '@entities/api/comments/adapters/getCountBadAndNewAdapterToClient'
import {moderateCommentsAdapterToClient} from '@entities/api/comments/adapters/moderateCommentsAdapterToClient'
import {CommentStatus} from '@graphql/types'
import {UiCommentsConnection} from 'entities/types/UiCommentsConnection'
import {useStore} from '@stores/rootStoreContext'
import {Spinner} from '@components/UI/Spinner/Spinner'
import InfiniteScrollWithScrollToTop from '@components/InfiniteScrollWithScrollToTop/InfiniteScrollWithScrollToTop'
import useWindowResize from '@hooks/useWindowResize'
import CommentItem from '../CommentItem/CommentItem'
import {
  useGetCommentsListLazyQuery,
  useGetCountBadAndNewLazyQuery,
  useModerateCommentsMutation
} from '../gql/Comments.generated'
import {COMMENTS_MODERATION_CONTENT_CONSTS} from './CommentsModerationContent.consts'
import {
  ButtonsWrapper,
  ButtonWrapper,
  FiltersWrapper,
  SpinnerWrapper,
  StyledFormControlWrapper
} from './CommentsModerationContent.styles'
import {useCommentsData, useFilters, useTableHeight} from './CommentsModerationContent.hooks'

const CommentsModerationContent: FC = () => {
  const {status} = useParams<{status: CommentStatus}>()
  const {commentsStore} = useStore()
  const {commentEdges, pageInfo, nothingFounded, setCommentEdges, setPageInfo, handleCommentsListData} =
    useCommentsData(status)

  const {isBad, setIsBad, findWord, setFindWord, findWordValue, setFindWordValue, selectedSortValue, handleSortChange} =
    useFilters()

  const [badCommentsCount, setBadCommentsCount] = useState(0)
  const [newCommentsCount, setNewCommentsCount] = useState(0)

  const {
    totalCount,
    commentsStates,
    setCommentsStates,
    setTotalCount,
    setCommentsStatesWithPrevState,
    resetCommentUids,
    moderateCommentsActionLoading,
    setModerateCommentsActionLoading
  } = commentsStore

  const [getCommentsListLazyQuery, {loading, fetchMore, refetch}] = useGetCommentsListLazyQuery()
  const [getCountBadAndNew, {refetch: refetchCountBadAndNew}] = useGetCountBadAndNewLazyQuery()

  const getCommentsListQueryAction = useCallback(
    async (word, status, isBad, sortValue) => {
      getCommentsListLazyQuery({
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
        variables: {
          findWord: word,
          isBad: status === CommentStatus.Review ? isBad : undefined,
          sort: sortValue,
          status: status ?? CommentStatus.Review
        },
        onCompleted: getCommentsListQueryResult => {
          if (getCommentsListQueryResult?.comments) {
            const commentsData: UiCommentsConnection = getCommentsListAdapterToClient(
              getCommentsListQueryResult.comments
            )
            const newStates = handleCommentsListData(commentsData)
            setCommentsStates(newStates)
          }
        }
      })
    },
    [getCommentsListLazyQuery, setCommentsStates, handleCommentsListData]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setCommentEdges([])
      getCommentsListQueryAction(findWordValue, status, isBad, selectedSortValue.value)
    }, 500)
    return () => clearTimeout(timer)
  }, [findWordValue, status, isBad, selectedSortValue.value, setCommentEdges, getCommentsListQueryAction])

  const getCountBadAndNewAction = useCallback(async () => {
    const getCountBadAndNewResult = await getCountBadAndNew()

    if (getCountBadAndNewResult) {
      const getCountBadAndNewData = getCountBadAndNewAdapterToClient(getCountBadAndNewResult.data ?? undefined)
      setTotalCount(getCountBadAndNewData.countNewComments)
      setNewCommentsCount(getCountBadAndNewData.countNewComments - getCountBadAndNewData.countBadComments)
      setBadCommentsCount(getCountBadAndNewData.countBadComments)
    }
  }, [getCountBadAndNew, setTotalCount])

  useEffect(() => {
    console.log('totalCount', totalCount)
    getCountBadAndNewAction()
  }, [totalCount])

  useEffect(() => {
    setFindWordValue(findWord.trim())
  }, [findWord, setFindWordValue])

  useEffect(() => {
    setCommentEdges([])
    setPageInfo({
      endCursor: '',
      hasNextPage: true,
      hasPreviousPage: false,
      startCursor: ''
    })
    void refetch({
      findWord: findWordValue,
      isBad: status === CommentStatus.Review ? isBad : undefined,
      status: status ?? CommentStatus.Review
    })
  }, [status, isBad, findWordValue, refetch])

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        after: pageInfo.endCursor,
        findWord: findWordValue,
        first: 10,
        sort: selectedSortValue.value,
        status: status ?? CommentStatus.Review
      }
    }).then(data => {
      if (data?.data?.comments) {
        const commentsData = getCommentsListAdapterToClient(data.data.comments)
        const newStates = handleCommentsListData(commentsData, true)
        setCommentsStatesWithPrevState(prev => [...prev, ...newStates])
      }
    })
  }, [pageInfo.endCursor, status, fetchMore, findWordValue, selectedSortValue, handleCommentsListData])

  const refetchData = useCallback(() => {
    refetch().then(data => {
      const getCommentsListData: UiCommentsConnection = getCommentsListAdapterToClient(data?.data?.comments)
      const newStates = handleCommentsListData(getCommentsListData)

      setCommentsStates(newStates)
    })
    refetchCountBadAndNew().then(data => {
      const getCountBadAndNewData = getCountBadAndNewAdapterToClient(data?.data ?? undefined)
      setTotalCount(getCountBadAndNewData.countNewComments)
      setNewCommentsCount(getCountBadAndNewData.countNewComments - getCountBadAndNewData.countBadComments)
      setBadCommentsCount(getCountBadAndNewData.countBadComments)
    })
  }, [refetch, handleCommentsListData, refetchCountBadAndNew])

  const [moderateComments] = useModerateCommentsMutation()

  const moderateCommentsAction = useCallback(
    async ind => {
      const toPublish: string[] = []
      const toReject: string[] = []
      const toViewed: string[] = []

      const commentsStatesToPublish = commentsStates.slice(0, ind + 1)

      commentsStatesToPublish.forEach(comment => {
        if (comment.viewed === true) {
          toViewed.push(comment.commentUid)
          return
        }
        if (comment.isBad === true) {
          toReject.push(comment.commentUid)
          return
        }
        if (comment.isBad === false) {
          toPublish.push(comment.commentUid)
          return
        }
        toViewed.push(comment.commentUid)
      })

      setModerateCommentsActionLoading(true)
      const moderateCommentsResult = await moderateComments({
        variables: {
          data: {
            commentUidsToPublish: toPublish,
            commentUidsToReject: toReject,
            commentUidsToViewed: toViewed
          }
        }
      })
      setModerateCommentsActionLoading(false)
      if (!moderateCommentsAdapterToClient(moderateCommentsResult)) {
        console.error('Ошибка при модерации')
        return
      }
      resetCommentUids()
      refetchData()
    },
    [commentsStates, moderateComments, resetCommentUids, setModerateCommentsActionLoading, refetchData]
  )

  const [width] = useWindowResize()
  const tableHeight = useTableHeight(width, status)

  return (
    <div>
      <Box display='flex' flexDirection='column'>
        <FiltersWrapper>
          <FormControl sx={{minWidth: 180, height: 42}}>
            <InputLabel id='sort-select-label'>{COMMENTS_MODERATION_CONTENT_CONSTS.sortFieldLabel}</InputLabel>
            <Select
              labelId='sort-select-label'
              id='sort-select'
              value={selectedSortValue}
              label={COMMENTS_MODERATION_CONTENT_CONSTS.sortFieldLabel}
              onChange={e => handleSortChange(e.target.value)}
            >
              {COMMENTS_MODERATION_CONTENT_CONSTS.sortValues.map((sortVal, index) => (
                <MenuItem key={index} value={sortVal} id={`sort-value-${sortVal.value}`}>
                  {sortVal.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <StyledFormControlWrapper>
            <InputLabel htmlFor='search-by-word-input'>{COMMENTS_MODERATION_CONTENT_CONSTS.searchByWord}</InputLabel>
            <OutlinedInput
              label={COMMENTS_MODERATION_CONTENT_CONSTS.searchByWord}
              id='search-by-word-input'
              onChange={e => setFindWord(e.target.value)}
              value={findWord}
              endAdornment={
                <InputAdornment position='end'>
                  {findWord && (
                    <IconButton edge='end' size='small' onClick={() => setFindWord('')}>
                      <Clear fontSize='small' />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </StyledFormControlWrapper>
        </FiltersWrapper>
        {status === CommentStatus.Review && (
          <ButtonGroup size='small' aria-label='Small button group'>
            <ButtonsWrapper>
              <ButtonWrapper
                variant={isBad ? 'outlined' : 'contained'}
                color='primary'
                size='small'
                id='new-comments-button'
                onClick={() => setIsBad(false)}
              >
                <Typography variant='text14M'>
                  {COMMENTS_MODERATION_CONTENT_CONSTS.isNew}({newCommentsCount})
                </Typography>
              </ButtonWrapper>
              <ButtonWrapper
                variant={isBad ? 'contained' : 'outlined'}
                color='primary'
                size='small'
                id='bad-comments-button'
                onClick={() => setIsBad(true)}
              >
                <Typography variant='text14M'>
                  {COMMENTS_MODERATION_CONTENT_CONSTS.isBad}({badCommentsCount})
                </Typography>
              </ButtonWrapper>
            </ButtonsWrapper>
          </ButtonGroup>
        )}
      </Box>
      {!loading && nothingFounded && (
        <Box marginTop={'36px'}>
          <Typography variant='text20R'>{COMMENTS_MODERATION_CONTENT_CONSTS.nothingWasFounded}</Typography>
        </Box>
      )}
      {loading && !commentEdges?.length && (
        <SpinnerWrapper>
          <Spinner title={COMMENTS_MODERATION_CONTENT_CONSTS.loading} />
        </SpinnerWrapper>
      )}
      {commentEdges?.length > 0 && (
        <InfiniteScrollWithScrollToTop
          dataLength={commentEdges?.length || 0}
          fetchMoreData={fetchMoreData}
          hasMore={!!pageInfo.hasNextPage}
          elementStyle={{
            height: tableHeight,
            marginTop: '8px'
          }}
        >
          <>
            {commentEdges.map((comment, ind) => (
              <CommentItem
                key={comment.node?.uid || 'comment-item'}
                ind={ind}
                comment={comment.node || defaultComment}
                moderateCommentsAction={moderateCommentsAction}
                refetchData={refetchData}
              />
            ))}
          </>
        </InfiniteScrollWithScrollToTop>
      )}
    </div>
  )
}

export default observer(CommentsModerationContent)
