import styled from 'styled-components'
import {DatePicker} from '@mui/x-date-pickers'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: flex-start;
`

export const DatePickerWrapper = styled(DatePicker)`
  display: flex;
  width: 274px;
  padding: 0;
  &input {
    padding: 0;
  }

  .MuiIconButton-root {
    padding: 0;
  }
`

export const WrapperBlock = styled.div`
  margin: 0 16px 30px 0;
`
