import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

export const AlignHorizontal: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M3.66667 18.6667H2V2H3.66667V18.6667ZM18.6667 6.16667H5.33333V8.66667H18.6667V6.16667ZM13.6667 12H5.33333V14.5H13.6667V12Z'
          fill='#222222'
        />
      </svg>
    </SvgIcon>
  )
}
