import Keycloak, {KeycloakInitOptions, KeycloakInstance} from 'keycloak-js'

export const initOptions: KeycloakInitOptions = {
  checkLoginIframe: true,
  checkLoginIframeInterval: 1,
  onLoad: 'login-required'
}

let keycloakInstance: KeycloakInstance | undefined

/**
 * Функция клиента для keycloack
 * @returns {KeycloakInstance} KeycloakInstance
 */
const keycloakClient = (): KeycloakInstance => {
  if (!keycloakInstance) {
    const keycloak = Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URI,
      realm: 'uranews',
      clientId: 'backend'
    })

    setInterval(() => {
      keycloak.updateToken(70).catch(() => {
        keycloak.logout()
      })
    }, 5000)

    keycloakInstance = keycloak
  }
  return keycloakInstance!
}

export const getKeycloakInstance = () => {
  if (!keycloakInstance) {
    throw new Error('Сначала инициализируйте Keycloak через ReactKeycloakProvider!')
  }
  return keycloakInstance
}

export default keycloakClient
