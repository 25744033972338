import React, {FC, useCallback, useEffect, useState} from 'react'
import {isEqual} from 'lodash'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {Tooltip, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import DragAndDropIcon from '@components/UI/Icons/DragAndDrop'
import {ConfirmationModal} from '@components/UI/ConfirmationModal/ConfirmationModal'
import {FigureVersion} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {GalleryItem} from '../../../../../types/GalleryItem'
import {swap} from '../../../../../libs/react-dnd/grid'
import {
  DragAndDropButtonWrapper,
  DropableAreaWrapper,
  TitleRowWrapper,
  TitleWrapper,
  Wrapper
} from './GalleryEditorGridDrop.styles'
import {GalleryEditorGridDropProps} from './GalleryEditorGridDropProps'
import GalleryBlockItem from './GalleryBlockItem/GalleryBlockItem'
import {GALLERY_EDITOR_GRID_DROP_CONSTS} from './GalleryEditorGridDrop.consts'

const GalleryEditorGridDrop: FC<GalleryEditorGridDropProps> = ({galleryBlocks, onSwapItems}) => {
  const {publicationStore} = useStore()
  const {galleryModalStore} = publicationStore
  const {showUploadFields, setGalleryDetailWithPrevState, deleteGalleryError} = galleryModalStore

  const [blockUidForDelete, setBlockUidForDelete] = useState('')
  const [curGalleryBlocks, setCurGalleryBlocks] = useState(galleryBlocks)

  useEffect(() => {
    setCurGalleryBlocks(galleryBlocks)
  }, [galleryBlocks, galleryBlocks.length])

  const onChange = (sourceId: any, sourceIndex: number, targetIndex: number): void => {
    const nextState = swap(galleryBlocks, sourceIndex, targetIndex)
    if (!isEqual(galleryBlocks, nextState)) {
      onSwapItems(true)
    }
    const updatedBlocks = nextState.map((block, index) => ({
      ...block,
      position: index + 1
    }))
    setGalleryDetailWithPrevState((prev: GalleryItem) => ({...prev, galleryImages: updatedBlocks}))
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const items = reorder(galleryBlocks, result.source.index, result.destination.index)

      setGalleryDetailWithPrevState((prev: GalleryItem) => ({...prev, galleryImages: items}))
    },
    [galleryBlocks, setGalleryDetailWithPrevState]
  )

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    display: 'block',
    opacity: isDragging ? 0.5 : 1,
    backgroundColor: isDragging ? '#EDEEF1' : 'inherit',
    ...draggableStyle
  })

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)

  const deleteBlockFromGallery = useCallback(() => {
    if (blockUidForDelete) {
      const items = galleryBlocks.filter(item => item.uid !== blockUidForDelete)
      // при удалении блока не забываем удалить ошибку по нему (если она была)
      deleteGalleryError(blockUidForDelete)

      setGalleryDetailWithPrevState((prev: GalleryItem) => ({...prev, galleryImages: items || []}))
    }
  }, [galleryBlocks, setGalleryDetailWithPrevState, deleteGalleryError, blockUidForDelete])

  const closeConfirmModal = useCallback(() => {
    setIsOpenConfirmDialog(false)
    deleteBlockFromGallery()
  }, [deleteBlockFromGallery])

  const toggleIsOpenConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(!isOpenConfirmDialog)
  }, [isOpenConfirmDialog])

  const handleDeleteBlock = useCallback((blockUid: string) => {
    setBlockUidForDelete(blockUid)
    setIsOpenConfirmDialog(true)
  }, [])

  const [showExtParams, setShowExtParams] = useState(showUploadFields)

  useEffect(() => {
    setShowExtParams(showUploadFields)
  }, [showUploadFields])

  return (
    <Wrapper showExtParams={showExtParams}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <DropableAreaWrapper ref={provided.innerRef}>
              {curGalleryBlocks?.length &&
                curGalleryBlocks.map((galleryBlock, ind) => (
                  <Draggable key={galleryBlock.id} draggableId={galleryBlock.id} index={ind}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <TitleRowWrapper>
                          <TitleWrapper>
                            <Typography variant='text16M'>
                              {GALLERY_EDITOR_GRID_DROP_CONSTS.photo} {ind + 1} {GALLERY_EDITOR_GRID_DROP_CONSTS.from}{' '}
                              {curGalleryBlocks.length}
                            </Typography>
                          </TitleWrapper>
                          <Tooltip title={GALLERY_EDITOR_GRID_DROP_CONSTS.changePhotoPosition}>
                            <DragAndDropButtonWrapper
                              id={`gallery-block-item-drag-and-drop-btn_${galleryBlock.id}`}
                              className='gallery-block-item-drag-and-drop-btn'
                              {...provided.dragHandleProps}
                            >
                              <DragAndDropIcon />
                            </DragAndDropButtonWrapper>
                          </Tooltip>
                        </TitleRowWrapper>
                        <GalleryBlockItem
                          galleryImage={galleryBlock}
                          curIndex={ind + 1}
                          handleDeleteBlock={handleDeleteBlock}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
            </DropableAreaWrapper>
          )}
        </Droppable>
      </DragDropContext>
      <ConfirmationModal
        id={'delete-photo-from-gallery'}
        confirmText={GALLERY_EDITOR_GRID_DROP_CONSTS.deletePhotoConfirmationText}
        isOpenConfirmDialog={isOpenConfirmDialog}
        closeConfirmModal={closeConfirmModal}
        okLabel={GALLERY_EDITOR_GRID_DROP_CONSTS.deletePhotoConfirmBtn}
        toggleIsOpenConfirmDialog={toggleIsOpenConfirmDialog}
      />
    </Wrapper>
  )
}

export default observer(GalleryEditorGridDrop)
