import {UiCommentsEdge} from 'entities/types/UiCommentsEdge'
import {UiCommentsEdgeWithLevel} from './UiCommentsEdgeWithLevel'

const commentEdgesTree = (edges: UiCommentsEdge[]): UiCommentsEdgeWithLevel[] => {
  const resEdges: UiCommentsEdgeWithLevel[] = []
  const childEdges: UiCommentsEdgeWithLevel[] = []

  edges.forEach(edge => {
    if (!edge.node?.parentComment?.id) {
      resEdges.push({...edge, level: 1})
    } else {
      childEdges.push({...edge, level: 2})
    }
  })

  childEdges.reverse().forEach(edge => {
    const parentCommentIndex = resEdges.findIndex(c => c.node?.id === edge.node?.parentComment?.id)
    if (parentCommentIndex !== -1) {
      const newLevel = resEdges[parentCommentIndex].level + 1

      resEdges.splice(parentCommentIndex + 1, 0, {
        ...edge,
        level: newLevel
      })
    }
  })

  return resEdges
}

export {commentEdgesTree}
