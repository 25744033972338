import React, {FC} from 'react'

const ShevronUpIcon: FC<any> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 17L12 7L2 17' stroke='#707070' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default ShevronUpIcon
