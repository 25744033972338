import {Button, Snackbar} from '@mui/material'
import styled from 'styled-components'

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: auto;
  right: 24px;
  bottom: 24px;
  gap: 10px;
  margin-bottom: 60px;
  @media (max-width: 840px) {
    margin-bottom: 130px;
  }
`

export const WrapperContent = styled.div`
  width: 416px;
  color: #eb3846;
  display: flex;
  align-items: flex-start;
  padding: 0;
  @media (max-width: 480px) {
    width: 316px;
  }
`

export const ButtonContent = styled.div`
  display: flex;
  gap: 8px;
`

export const LeftBlockWrapper = styled.div`
  align-items: flex-start;
  padding: 0 10px 0 0;
`

export const StyledUl = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 20.8px;
    margin-left: 10px;

    &::before {
      content: '•';
      color: #eb3846;
      font-size: 20px;
      display: inline-block;
      margin-right: 10px;
    }

    @media (max-width: 480px) {
      align-items: flex-start;
      font-size: 14px;
    }
  }
`
export const Title = styled.div`
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 20.8px;
  color: #eb3846;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const TitleButton = styled.div`
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 20.8px;
  margin-bottom: 12px;
  color: #eb3846;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const StyledSnackbar = styled(Snackbar)`
  position: static;

  .MuiPaper-root {
    background-color: #f2dddd;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    padding-right: 16px;
    gap: 15px;
  }
  .MuiSnackbarContent-message {
    padding: 0;
  }
  .MuiSnackbarContent-action {
    margin-left: 34px;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
  }

  @media (max-width: 840px) {
    left: auto;
    right: 24px;
    bottom: 24px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    right: 8px;
  }
`

export const StyledSubmitButton = styled(Button)`
  font-size: 14px;
  background-color: #eb3845;
  color: #ffffff;
  align-self: center;
  min-height: auto;
  padding: 5.5px 18px;
  &:hover {
    background-color: #e2202d;
  }
`

export const StyledCancelButton = styled(Button)`
  font-size: 14px;
  color: #e2202d;
  text-decoration: underline;
  align-self: center;
  min-height: auto;
  padding: 5.5px 18px;
`
