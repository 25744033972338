import React, {FC, useCallback, useEffect, useState} from 'react'
import {InputLabel, OutlinedInput} from '@mui/material'
import styled from 'styled-components'
import {generateGuid} from '@utils/generateGuid'
import {CustomHtmlElement} from '@graphql/types'
import SimpleModal, {SimpleModalProps} from '../SimpleModal/SimpleModal'
import {StyledFormControl} from '../styled'
import {HTMLBlot} from '../../TextEditor/Blots/HtmlBlot/Html'

const DetailsRowSelects = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

type HtmlEditorModalProps = {
  defaultHtml?: CustomHtmlElement
  onClose: () => void
  setCustomHtml: ({}: HTMLBlot) => void
} & SimpleModalProps

const HtmlEditorModal: FC<HtmlEditorModalProps> = ({
  defaultHtml,
  open,
  onClose,
  title,
  text,
  okLabel,
  noLabel,
  setCustomHtml
}) => {
  const htmlLinkText = 'HTML'
  const [id, setId] = useState(defaultHtml?.id)
  const [html, setHtml] = useState(defaultHtml?.html || '')

  useEffect(() => {
    setId(defaultHtml?.id)
    setHtml(defaultHtml?.html || '')
  }, [defaultHtml])

  const updateHtmlAction = useCallback(() => {
    setCustomHtml({
      id: id || defaultHtml?.id || generateGuid(),
      html: html || defaultHtml?.html || ''
    })
  }, [setHtml, id, html])

  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={onClose}
      title={title}
      text={text}
      onOk={() => {
        updateHtmlAction()
        onClose()
      }}
      onNo={() => {
        onClose()
      }}
      okButtonDisabled={!html}
      noButtonDisabled={false}
      okLabel={okLabel}
      noLabel={noLabel}
    >
      <>
        <DetailsRowSelects>
          <StyledFormControl width='100%'>
            <InputLabel>{htmlLinkText}</InputLabel>
            <OutlinedInput label={htmlLinkText} onChange={e => setHtml(e.target.value)} value={html} multiline />
          </StyledFormControl>
        </DetailsRowSelects>
      </>
    </SimpleModal>
  )
}

export default HtmlEditorModal
