import React, {FC, useCallback} from 'react'
import {CircularProgress, Typography} from '@mui/material'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'
import {SimpleModalProps} from '../SimpleModal'
import {ALERT_MODE, CONFIRMATION_MODE, DIALOG_MODE, MODAL_MODE, ModalMode} from '../../../../types/ModalMode'
import {ButtonWrapper, ShortButton, StyledDialogActions} from './ModalActions.styles'

const BUTTONS_MIN_WIDTH = 100

export const ModalActions: FC<SimpleModalProps> = ({
  id,
  mode,
  okLabel,
  noLabel,
  yesLabel,
  onOk,
  onNo,
  onYes,
  okButtonDisabled,
  yesButtonDisabled,
  noButtonDisabled,
  stylesOptions,
  okButtonDisabledTooltip,
  loading
}) => {
  const getButtonMinWidth = useCallback(
    (type: 'ok' | 'yes' | 'no') => {
      switch (type) {
        case 'ok':
          return stylesOptions?.okButtonMinWidth || stylesOptions?.buttonsMinWidth || BUTTONS_MIN_WIDTH
        case 'yes':
          return stylesOptions?.yesButtonMinWidth || stylesOptions?.buttonsMinWidth || BUTTONS_MIN_WIDTH
        case 'no':
          return stylesOptions?.noButtonMinWidth || stylesOptions?.buttonsMinWidth || BUTTONS_MIN_WIDTH
      }
    },
    [stylesOptions]
  )

  const renderButton = useCallback(
    (
      short: boolean,
      id: string,
      variant: 'contained' | 'outlined',
      label: string,
      disabled: boolean,
      minWidth: number,
      onClick: () => void,
      okButtonDisabledTooltip?: string,
      mode?: ModalMode | undefined,
      loading?: boolean
    ) => {
      const ButtonComponent = short ? ShortButton : ButtonWrapper
      return (
        <ControlTooltip
          title=''
          disabledTitle={okButtonDisabledTooltip || ''}
          disabled={disabled}
          color={'default'}
          withoutTooltipIfActive={true}
        >
          <ButtonComponent
            id={id}
            variant={variant}
            color='primary'
            size='large'
            disabled={disabled}
            onClick={onClick}
            style={{
              textTransform: 'none',
              padding: mode === CONFIRMATION_MODE ? '7px 21px' : '11px 16px',
              minHeight: mode === CONFIRMATION_MODE ? '32px' : '42px',
              maxHeight: mode === CONFIRMATION_MODE ? '32px' : '42px',
              minWidth: mode === CONFIRMATION_MODE ? '160px' : '174px'
            }}
          >
            {loading === true && <CircularProgress size='1rem' />}
            <Typography variant='text14M'>{label}</Typography>
          </ButtonComponent>
        </ControlTooltip>
      )
    },
    []
  )

  if (mode === MODAL_MODE) return null

  return (
    <StyledDialogActions style={{marginTop: mode === CONFIRMATION_MODE ? '8px' : '0px'}}>
      {noLabel != '' &&
        renderButton(
          false,
          `${id}${mode ? `_${mode}` : ''}_no_button`,
          'outlined',
          noLabel || 'Отменить',
          noButtonDisabled || false,
          getButtonMinWidth('no'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onNo ? onNo : () => {},
          okButtonDisabledTooltip || '',
          mode
        )}
      {mode === ALERT_MODE &&
        renderButton(
          !okLabel,
          `${id}${mode ? `_${mode}` : ''}_ok_button`,
          'contained',
          okLabel || 'Хорошо',
          okButtonDisabled || false,
          getButtonMinWidth('ok'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onOk ? onOk : () => {},
          okButtonDisabledTooltip || '',
          mode,
          loading
        )}
      {mode === CONFIRMATION_MODE &&
        renderButton(
          !okLabel,
          `${id}${mode ? `_${mode}` : ''}_ok_button`,
          'contained',
          okLabel || 'Хорошо',
          okButtonDisabled || false,
          getButtonMinWidth('ok'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onOk ? onOk : () => {},
          okButtonDisabledTooltip || '',
          mode,
          loading
        )}
      {mode === DIALOG_MODE &&
        renderButton(
          !yesLabel,
          `${id}${mode ? `_${mode}` : ''}_yes_button`,
          'contained',
          yesLabel || 'Хорошо',
          yesButtonDisabled || false,
          getButtonMinWidth('yes'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onYes ? onYes : () => {},
          '',
          mode,
          loading
        )}
    </StyledDialogActions>
  )
}
