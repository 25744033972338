import {format} from 'date-fns'
import {ReportsListFiltersProps} from '@pages/ReportsPage/ReportsListFilters/ReportsListFiltersProps'
import {MetricDataType} from '../../../../types/MetricDataType'
import {ReportsListTableDataProps} from '../ReportsListTableDataProps'
import {mapMetricDataResult} from './mapMetricDataResult'

export const getMetrics = async (
  publicationsMaps: any,
  publicationsList: ReportsListTableDataProps[],
  filters: ReportsListFiltersProps
): Promise<ReportsListTableDataProps[]> => {
  const publicationsCountInRequest = Object.keys(publicationsMaps).length

  if (publicationsCountInRequest > 0) {
    const response = await fetch(
      `${process.env.METRICS_SERVICE_URL}/?publications=${JSON.stringify(publicationsMaps)}&date1=${format(
        new Date(filters?.viewsDateFrom || ''),
        'yyyy-MM-dd'
      )}&date2=${format(new Date(filters?.viewsDateTo || ''), 'yyyy-MM-dd')}`
    )
    const data: MetricDataType = await response.json()

    const list: ReportsListTableDataProps[] = mapMetricDataResult(data, publicationsList)

    return list
  }
  return []
}
