import ky, {HTTPError} from 'ky'
import {toast} from 'react-toastify'

const fetchInstanse = ky.create({
  prefixUrl: process.env.REACT_APP_NN,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 100000
})

export const fetchInstanseWithoutError = ky.create({
  prefixUrl: 'https://a.uran.news/',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 100000
})

// TODO Типизировать с <T>
export const handleRequest = async (url: string, options: any) => {
  try {
    const response = await fetchInstanse(url, options)

    if (!response.ok) {
      const statusCode = response.status
      const statusText = response.statusText || 'Неизвестная ошибка'

      toast.error(`Ошибка: ${statusCode} - ${statusText}`, {
        containerId: 'error',
        autoClose: false,
        closeButton: true
      })

      return null
    }

    return await response.json()
  } catch (error) {
    if (error instanceof HTTPError && error.response) {
      // HTTP ошибки, которые возвращают статус ответа
      const statusCode = error.response.status
      const statusText = error.response.statusText || 'Неизвестная ошибка'

      toast.error(`Ошибка HTTP: ${statusCode} - ${statusText}`, {
        containerId: 'error',
        autoClose: false,
        closeButton: true
      })
    } else {
      // Сетевые ошибки, такие как 504 или сбой соединения
      const errorMessage = error.message.includes('Failed to fetch')
        ? 'Ошибка сети: сервер не отвечает (возможно, ошибка 504)'
        : `Сетевая ошибка: ${error.message}`

      toast.error(errorMessage, {
        containerId: 'error',
        autoClose: false,
        closeButton: true
      })
    }
    return null
  }
}
