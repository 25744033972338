import React, {FC, useCallback, useEffect, useState} from 'react'
import SimpleModal from '../../../UI/SimpleModal/SimpleModal'
import ImageCropper from '../../../UI/ImageEditor/ImageCropper/ImageCropper'
import {CropInput, Figure, OverviewBlock} from '@graphql/types'
import {Box} from '@mui/material'
import InputWithLength from '../../../UI/InputWithLength/InputWithLength'
import {useUpdateFigureMutation} from '../../../Publication/Form/gql/PublicationEditor.generated'
import {useSnackbar} from 'notistack'

import {useKeycloak} from '@react-keycloak/web'
import {OverviewBlockModalProps} from './OverviewBlockModalProps'
import {InputWrapper} from './OverviewBlockModal.styles'
import {OVERVIEW_BLOCK_MODAL_CONSTS} from './OverviewBlockModal.consts'
import {CropperType} from '../../../../types/CropperType'
import {useStore} from '@stores/rootStoreContext'

const OverviewBlockModal: FC<OverviewBlockModalProps> = ({open, onClose, handleUpdateBlock, dataModal}) => {
  const {cropperStore} = useStore()
  const {cropper, isCropperChanged, setIsCropperChanged, setCropper} = cropperStore

  const [title, setTitle] = useState('')
  const [errorTitle, setErrorTitle] = useState('')

  const [subtitle, setSubtitle] = useState('')
  const [errorSubtitle, setErrorSubtitle] = useState('')

  const [figure, setFigure] = useState({} as Figure)
  const [imageUrl, setImageUrl] = useState('')
  const [isFigureChanged, setIsFigureChanged] = useState(false)

  useEffect(() => {
    setIsFigureChanged(isCropperChanged)
  }, [isCropperChanged])

  const [updateFigure] = useUpdateFigureMutation()
  const snackbar = useSnackbar()

  useEffect(() => {
    setTitle(dataModal.data?.title || '')
    setSubtitle(dataModal.data?.subtitle || '')
    setFigure((dataModal.data?.figureVersion?.figure as Figure) || null)
    setCropper((dataModal.data?.figureVersion?.figure?.latestVersion?.crop as CropInput) || null)
    setImageUrl(dataModal.data?.figureVersion?.figure?.src || '')
  }, [
    dataModal.data?.title,
    dataModal.data?.subtitle,
    dataModal.data?.figureVersion?.figure,
    dataModal.data?.figureVersion?.figure?.latestVersion?.crop,
    open
  ])

  const clearTitle = () => {
    setTitle('')
    setErrorTitle('')
  }

  const clearSubtitle = () => {
    setSubtitle('')
    setErrorSubtitle('')
  }

  const handleSetFigure = useCallback(
    (figure: Figure) => {
      setFigure(figure)
      setIsCropperChanged(true)
    },
    [setIsCropperChanged]
  )

  const {keycloak} = useKeycloak()

  const onOk = useCallback(async () => {
    try {
      isFigureChanged &&
        (await updateFigure({
          variables: {
            data: {
              figureUid: figure?.uid,
              crop: cropper,
              author: figure?.author || (keycloak?.tokenParsed && keycloak?.tokenParsed['name'])
            }
          }
        }))

      handleUpdateBlock(
        {
          id: dataModal.data?.id,
          title,
          subtitle,
          figureVersion: {figure}
        } as OverviewBlock,
        dataModal.mode
      )
    } catch (e) {
      console.error(e)
    }
  }, [
    cropper,
    dataModal.data?.uid,
    dataModal.mode,
    figure,
    handleUpdateBlock,
    isFigureChanged,
    snackbar,
    subtitle,
    title,
    updateFigure
  ])

  return (
    <SimpleModal
      open={open}
      showCloseButton
      okLabel={OVERVIEW_BLOCK_MODAL_CONSTS.save}
      onClose={onClose}
      onOk={onOk}
      onNo={onClose}
      okButtonDisabled={!title}
      title={
        dataModal?.mode === 'create'
          ? OVERVIEW_BLOCK_MODAL_CONSTS.addingNewBlock
          : OVERVIEW_BLOCK_MODAL_CONSTS.editingOfBlock
      }
    >
      <Box>
        <ImageCropper
          hasDiagonalWatermark={figure?.hasDiagonalWatermark || false}
          hasWatermark={figure?.hasWatermark || false}
          figure={figure as Figure}
          setFigure={handleSetFigure}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          ratio={7 / 5}
          figureTypeSlug='main-page'
          cropperType={CropperType.isOverviewBlock}
        />
      </Box>
      <InputWrapper>
        <InputWithLength
          label={OVERVIEW_BLOCK_MODAL_CONSTS.title}
          title={title}
          setTitle={setTitle}
          errorTitle={errorTitle}
          clearTitle={clearTitle}
          maxLength={70}
        />
        <InputWithLength
          label={OVERVIEW_BLOCK_MODAL_CONSTS.subtitle}
          title={subtitle}
          setTitle={setSubtitle}
          errorTitle={errorSubtitle}
          clearTitle={clearSubtitle}
          maxLength={90}
        />
      </InputWrapper>
    </SimpleModal>
  )
}

export default OverviewBlockModal
