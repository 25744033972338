import {makeAutoObservable} from 'mobx'
import {Publication} from '@graphql/types'
import IPublicationStore from './IPublicationStore'
import {defaultPublication} from './publicationStore.defaultValues'
import ImageModalStore from './modals/imageModalStore/imageModalStore'
import AnnounceImageModalStore from './modals/announceImageModalStore/announceImageModalStore'
import OverviewBlockImageModalStore from './modals/overviewBlockImageModalStore/overviewBlockImageModalStore'
import RubricAndSubrubricStore from './rubricAndSubrubricStore/rubricAndSubrubricStore'
import GalleryModalStore from './modals/galleryModalStore/galleryModalStore'

class PublicationStore implements IPublicationStore {
  announceImageModalStore = new AnnounceImageModalStore(this)
  imageModalStore = new ImageModalStore(this)
  galleryModalStore = new GalleryModalStore(this)
  overviewBlockImageModalStore = new OverviewBlockImageModalStore(this)
  rubricAndSubrubricStore = new RubricAndSubrubricStore(this)

  publication: Publication = defaultPublication

  publicationLoading = false
  publicationContentLoading = false
  destinationsDirty = false
  checkYandexDzenTitleValidation = false
  checkingPhotoEditor = false
  checkingPhotoEditorCache = false
  hasTitlesError = false
  publicationFormDirty = false

  constructor() {
    makeAutoObservable(this)
  }

  setCheckingPhotoEditor = (value: boolean): void => {
    this.checkingPhotoEditor = value
  }

  setCheckingPhotoEditorCache = (value: boolean): void => {
    this.checkingPhotoEditorCache = value
  }

  setPublication = (value: Publication): void => {
    this.publication = value
  }

  setPublicationWithPrevState = (cb: (prev: Publication) => Publication): void => {
    this.publication = cb(this.publication)
  }

  setDefaultPublication = (): void => {
    this.publication = defaultPublication
  }

  setPublicationLoading = (value: boolean): void => {
    this.publicationLoading = value
  }

  setPublicationContentLoading = (value: boolean): void => {
    this.publicationContentLoading = value
  }

  setDestinationsDirty = (value: boolean): void => {
    this.destinationsDirty = value
  }

  setCheckYandexDzenTitleValidation = (value: boolean): void => {
    this.checkYandexDzenTitleValidation = value
  }

  setTitlesError = (value: boolean): void => {
    this.hasTitlesError = value
  }
  setPublicationFormDirty = (value: boolean): void => {
    this.publicationFormDirty = value
  }
}

export default PublicationStore
