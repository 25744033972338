import React, {FC, useCallback, useMemo, useState} from 'react'
import {PlayCircleOutline} from '@mui/icons-material'
import {YandexZenFilledIcon, YandexZenOutlinedIcon} from '@components/UI/Icons/YandexZen'
import {YandexNewsFilledIcon, YandexNewsOutlinedIcon} from '@components/UI/Icons/YandexNews'
import {CashIcon} from '@components/UI/Icons/Cash'
import {ImportantFilledIcon, ImportantOutlinedIcon} from '@components/UI/Icons/ImportantIcon'

import {CheckedByRedactorFilledIcon, CheckedByRedactorOutlinedIcon} from '@components/UI/Icons/CheckedByRedactorIcon'
import {VisibleIcon} from '@components/UI/Icons/VisibleIcon'
import {
  PublicationNodeFragment,
  useSetDestinationMutation,
  useSetImportantMutation
} from '../gql/PublicationList.generated'
import format from 'date-fns/format'
import {compareAsc} from 'date-fns'
import {
  AuthorsTitle,
  RedirectToSiteButton,
  Regions,
  Sources,
  StyledIconButton,
  StyledWatchLater,
  StyledTypography,
  FlexDiv,
  MainDiv,
  FlexTimeDiv,
  FlexTitle,
  FlexSettings,
  FlexAuthors,
  FlexPublicationInfo,
  FlexShortInfo
} from './StyledComponents'
import {TitleLink} from '@components/UI/styled'
import ConfirmationButton from '@components/UI/ConfirmationButton/ConfirmationButton'
import {exportSlugs} from '../../Form/PublicationDestinationPicker/PublicationDestinationPicker'
import * as _ from 'lodash'
import {Figure, PublicationStatus} from '@graphql/types'
import {useKeycloak} from '@react-keycloak/web'
import ModalAddPublicationToMainPage from '@components/UI/ModalAddPublicationToMainPage/ModalAddPublicationToMainPage'
import {PUBLICATION_ITEM_CONSTS} from './PublicationItem.consts'
import {toast} from 'react-toastify'
import ConfirmationPopover from '@components/UI/ConfirmatiomPopover/ConfirmationPopover'

type PublicationItemType = {
  publication: PublicationNodeFragment
  focusedElementId: string
  setFocusedElementId: (val: string) => void
  length?: number
}

const PublicationItem: FC<PublicationItemType> = ({publication, focusedElementId, setFocusedElementId, length}) => {
  const [setImportant] = useSetImportantMutation()
  const [setDestination] = useSetDestinationMutation()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const {keycloak} = useKeycloak()

  const toggleIsOpenModal = useCallback(() => {
    setIsOpenModal(!isOpenModal)
  }, [isOpenModal])

  const handleSetImportant = useCallback(
    async (action: boolean) => {
      try {
        await setImportant({
          variables: {
            publicationUid: publication.uid,
            isImportant: action
          }
        })
        toast.success(PUBLICATION_ITEM_CONSTS.updatedMsg, {
          autoClose: false,
          hideProgressBar: true,
          containerId: 'success'
        })
      } catch (e) {
        console.error(e)
      }
    },
    [publication.uid, setImportant]
  )

  const handleSetDestination = useCallback(
    (actionSlug: string) => {
      return async (action: boolean) => {
        try {
          const destinationSlugs = publication.destinations?.map(destination => destination?.slug) as string[]

          if (action) {
            !destinationSlugs.includes(actionSlug) && destinationSlugs?.push(actionSlug)
          } else {
            _.remove(destinationSlugs, slug => slug === actionSlug)
          }

          await setDestination({
            variables: {
              publicationUid: publication.uid,
              destinationSlugs: destinationSlugs
            }
          })
          toast.success(PUBLICATION_ITEM_CONSTS.updatedMsg, {
            autoClose: false,
            hideProgressBar: true,
            containerId: 'success'
          })
        } catch (e) {
          console.error(e)
        }
      }
    },
    [publication.destinations, publication.uid, setDestination]
  )

  const isPending = useMemo(
    () => compareAsc(new Date(publication.publishedAt), new Date()) > -1,
    [publication.publishedAt]
  )

  const handleOpenPublicationOnSite = () => {
    window.open(
      `${process.env.REACT_APP_URA_NEWS_URL + (publication.typeSlug as string)}/${publication.deprecatedId}`,
      '_blank'
    )
  }

  return (
    <>
      <FlexDiv>
        <FlexTimeDiv>
          {isPending && <StyledWatchLater fontSize={'small'} />}
          <StyledTypography id='publication-publisedOrCreated-time'>
            {format(new Date(publication.publishedAt || publication.createdAt), 'HH:mm')}
          </StyledTypography>
        </FlexTimeDiv>
        <MainDiv>
          <FlexPublicationInfo>
            <FlexTitle id='publication-title'>
              <TitleLink to={`/editor/${publication.uid}`} title={PUBLICATION_ITEM_CONSTS.goToNewsEditingMsg}>
                {publication.title || PUBLICATION_ITEM_CONSTS.withoutNameMsg}
              </TitleLink>
            </FlexTitle>
            <FlexShortInfo>
              {!!publication.regions?.length && (
                <Regions id='publication-regions-list'>
                  {publication.regions?.map((region, ind) => (
                    <span key={region?.uid}>
                      {ind > 0 && <span> • </span>}
                      <span>{region?.title}</span>
                    </span>
                  ))}
                </Regions>
              )}

              {publication.status === PublicationStatus.Published && (
                <Sources>
                  {publication.yandexNewsVideos.find(video => video.uri) && <PlayCircleOutline fontSize='small' />}

                  {publication.specProjectUrl && <CashIcon fontSize='small' />}
                </Sources>
              )}
            </FlexShortInfo>
          </FlexPublicationInfo>
          <FlexSettings>
            {publication.status === PublicationStatus.Published && (
              <>
                <ConfirmationPopover
                  id={`toggle-checked-${publication.id}-btn`}
                  focusedElementId={focusedElementId}
                  setFocusedElementId={setFocusedElementId}
                  textPopper={false ? PUBLICATION_ITEM_CONSTS.checkedByRedactor : PUBLICATION_ITEM_CONSTS.unChecked}
                  hasPopover={false}
                >
                  <StyledIconButton
                    onMouseEnter={() => {
                      setFocusedElementId(`toggle-checked-${publication.id}-btn`)
                    }}
                  >
                    {false ? <CheckedByRedactorFilledIcon /> : <CheckedByRedactorOutlinedIcon />}
                  </StyledIconButton>
                </ConfirmationPopover>
                {keycloak.hasResourceRole('toggle-important', 'publications') && (
                  <>
                    {publication.typeSlug !== 'articles' && (
                      <ConfirmationPopover
                        id={`toggle-important-${publication.id}-btn`}
                        focusedElementId={focusedElementId}
                        setFocusedElementId={setFocusedElementId}
                        textPopper={PUBLICATION_ITEM_CONSTS.importantMsg}
                        textPopperStatus={
                          !publication.isImportant
                            ? PUBLICATION_ITEM_CONSTS.isImportantMsg
                            : PUBLICATION_ITEM_CONSTS.notImportantMsg
                        }
                        actionPopper={handleSetImportant}
                        isActive={!!publication.isImportant}
                      >
                        <StyledIconButton
                          onMouseEnter={() => {
                            setFocusedElementId(`toggle-important-${publication.id}-btn`)
                          }}
                        >
                          {!publication.isImportant ? <ImportantOutlinedIcon /> : <ImportantFilledIcon />}
                        </StyledIconButton>
                      </ConfirmationPopover>
                    )}
                  </>
                )}

                <ConfirmationPopover
                  id={`yandex-news-${publication.id}-btn`}
                  focusedElementId={focusedElementId}
                  setFocusedElementId={setFocusedElementId}
                  actionPopper={handleSetDestination(exportSlugs.yandexNews)}
                  textPopper={PUBLICATION_ITEM_CONSTS.yandexNewsMsg}
                  isActive={
                    !!publication.destinations?.map(destination => destination?.slug).includes(exportSlugs.yandexNews)
                  }
                  textPopperStatus={
                    !publication.destinations?.map(destination => destination?.slug).includes(exportSlugs.yandexNews)
                      ? PUBLICATION_ITEM_CONSTS.toYandexNewsMsg
                      : PUBLICATION_ITEM_CONSTS.inYandexNewsMsg
                  }
                >
                  <StyledIconButton
                    onMouseEnter={() => {
                      setFocusedElementId(`yandex-news-${publication.id}-btn`)
                    }}
                  >
                    {publication.destinations
                      ?.map(destination => destination?.slug)
                      .includes(exportSlugs.yandexNews) ? (
                      <YandexNewsFilledIcon />
                    ) : (
                      <YandexNewsOutlinedIcon />
                    )}
                  </StyledIconButton>
                </ConfirmationPopover>

                <ConfirmationPopover
                  id={`yandex-zen-${publication.id}-btn`}
                  focusedElementId={focusedElementId}
                  setFocusedElementId={setFocusedElementId}
                  actionPopper={handleSetDestination(exportSlugs.yandexZen)}
                  textPopper={PUBLICATION_ITEM_CONSTS.yandexDzenMsg}
                  textPopperStatus={
                    !publication.destinations
                      ?.map(destination => destination?.slug as string)
                      .includes(exportSlugs.yandexZen)
                      ? PUBLICATION_ITEM_CONSTS.toYandexDzenMsg
                      : PUBLICATION_ITEM_CONSTS.inYandexDzenMsg
                  }
                  isActive={
                    !!publication.destinations
                      ?.map(destination => destination?.slug as string)
                      .includes(exportSlugs.yandexZen)
                  }
                >
                  <StyledIconButton
                    onMouseEnter={() => {
                      setFocusedElementId(`yandex-zen-${publication.id}-btn`)
                    }}
                  >
                    {publication.destinations
                      ?.map(destination => destination?.slug as string)
                      .includes(exportSlugs.yandexZen) ? (
                      <YandexZenFilledIcon />
                    ) : (
                      <YandexZenOutlinedIcon />
                    )}
                  </StyledIconButton>
                </ConfirmationPopover>
                <ConfirmationButton
                  id={`view-on-site-${publication.id}-btn`}
                  focusedElementId={focusedElementId}
                  setFocusedElementId={setFocusedElementId}
                  textPopper={PUBLICATION_ITEM_CONSTS.viewOnSiteMsg}
                >
                  <RedirectToSiteButton
                    onMouseEnter={() => {
                      setFocusedElementId(`view-on-site-${publication.id}-btn`)
                    }}
                    onClick={handleOpenPublicationOnSite}
                  >
                    <VisibleIcon />
                  </RedirectToSiteButton>
                </ConfirmationButton>
              </>
            )}
          </FlexSettings>
          <FlexAuthors>
            {publication.authors.map(author => (
              <AuthorsTitle key={author.uid}>
                {author?.firstName} {author?.lastName}
              </AuthorsTitle>
            ))}
          </FlexAuthors>
        </MainDiv>
      </FlexDiv>
      <ModalAddPublicationToMainPage
        open={isOpenModal}
        onClose={toggleIsOpenModal}
        figureTypeSlug='main-page'
        title={PUBLICATION_ITEM_CONSTS.addPublicationToMainMsg}
        okLabel={PUBLICATION_ITEM_CONSTS.addMsg}
        defaultFigure={{} as Figure}
        publicationTitle={publication?.title || ''}
        publicationId={publication?.id}
      />
    </>
  )
}

export default PublicationItem
