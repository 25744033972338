import React, {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import * as _ from 'lodash'
import {useKeycloak} from '@react-keycloak/web'
import InfiniteScroll from 'react-infinite-scroll-component'
import {NetworkStatus} from '@apollo/client'
import {Box, IconButton, InputAdornment, InputLabel, LinearProgress, OutlinedInput, Typography} from '@mui/material'
import {format, isValid, parseISO} from 'date-fns'
import {Clear} from '@mui/icons-material'
import ScrollToTopButton from '@components/UI/ScrollToTopButton/ScrollToTopButton'
import useDeviceDetect from '@utils/useDeviceDetect'
import {PublicationsSort, PublicationStatus} from '@graphql/types'
import {PublicationType} from '@pages/PublicationsList/PublicationsList'
import {usePublicationsQuery} from '../gql/PublicationList.generated'
import PublicationList from '../List/PublicationList'
import {TabPanelContentProps} from './TabPanelContentProps'
import {TAB_PANEL_CONTENT_CONSTS} from './TabPanelContent.consts'
import {
  AuthorPickerWrapper,
  KeyboardDatePickerWrapper,
  RegionPickerWrapper,
  StyledContainer,
  StyledFormControlWrapper
} from './TabPanelContent.styles'

const TabPanelContent: FC<TabPanelContentProps> = ({status, refetchDateTime}) => {
  const [isEmptyList, setIsEmptyList] = useState(false)
  const {isMobile} = useDeviceDetect()
  const typeSlug = useContext(PublicationType)
  const [searchDate, setSearchDate] = useState<any>(null)
  const [authorUid, setAuthorUid] = useState<any>(null)
  const [regionUid, setRegionUid] = useState<any>(null)
  const [titleSearch, setTitleSeacrh] = useState('')
  const [fieldWidth, setFieldWidth] = useState('315px')
  const [marginBottom, setMarginBottom] = useState('12px')
  const {keycloak} = useKeycloak()

  const variables = useMemo(
    () => ({
      status: status as PublicationStatus,
      typeSlug: typeSlug,
      userUid:
        status &&
        [PublicationStatus.Draft, PublicationStatus.Review].includes(status as PublicationStatus) &&
        !keycloak.hasResourceRole('manage-other-authors-publications', 'publications')
          ? keycloak.subject
          : authorUid,
      datePublished: searchDate && isValid(searchDate) ? format(searchDate, 'yyyy-MM-dd') : null,
      regionUid: regionUid,
      search: titleSearch,
      sort:
        status && [PublicationStatus.Draft, PublicationStatus.Review].includes(status as PublicationStatus)
          ? PublicationsSort.CreatedAtDesc
          : PublicationsSort.PublishedAtDesc
    }),
    [authorUid, keycloak, regionUid, searchDate, status, typeSlug, titleSearch]
  )

  const {data, fetchMore, refetch, networkStatus} = usePublicationsQuery({
    variables: variables,
    onCompleted: data => {
      setIsEmptyList(!data.publications?.edges.length)
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (!searchDate || (searchDate && isValid(searchDate))) {
      void refetch({
        status: status as PublicationStatus,
        typeSlug: typeSlug,
        authorUid: authorUid,
        datePublished: searchDate ? format(searchDate, 'yyyy-MM-dd') : null,
        regionUid: regionUid,
        search: titleSearch
      })
    }
  }, [keycloak.subject, refetch, status, typeSlug, authorUid, searchDate, regionUid, titleSearch, refetchDateTime])

  useEffect(() => {
    setFieldWidth(isMobile ? 'calc(100% - 20px)' : '315px')
    setMarginBottom(isMobile ? '12px' : '0px')
  }, [isMobile])

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        first: 10,
        after: data?.publications?.pageInfo.endCursor,
        sort: [PublicationStatus.Draft, PublicationStatus.Review].includes(status as PublicationStatus)
          ? PublicationsSort.CreatedAtDesc
          : PublicationsSort.PublishedAtDesc
      }
    })
  }, [data?.publications?.pageInfo.endCursor, fetchMore])

  const publicationsGroup = _.groupBy(
    data?.publications?.edges.map(item => {
      const res = JSON.parse(JSON.stringify(item))

      if (res?.node?.createdAt) {
        res.node.createdAtDate = format(parseISO(res?.node.createdAt), 'yyyy-MM-dd')
      }

      return res
    }),
    edge => (edge?.node?.publishedAtDate !== null ? edge?.node?.publishedAtDate : edge?.node?.createdAtDate)
  )

  const handleChangeAuthor = async (user: any) => {
    setAuthorUid(user?.uid || null)
  }
  const handleChangeRegion = async (region: any) => {
    setRegionUid(region?.uid || null)
  }

  const ref = useRef<any>(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  const onScroll = useCallback(val => {
    if (val) {
      setScrollPosition(val.scrollTop)
    }
  }, [])

  return (
    <>
      <StyledContainer>
        <StyledFormControlWrapper>
          <InputLabel htmlFor='header-search-input'>{TAB_PANEL_CONTENT_CONSTS.titleSearchLabel}</InputLabel>
          <OutlinedInput
            label={TAB_PANEL_CONTENT_CONSTS.titleSearchLabel}
            id='header-search-input'
            onChange={e => setTitleSeacrh(e.target.value)}
            value={titleSearch}
            endAdornment={
              <InputAdornment position='end'>
                {titleSearch && (
                  <IconButton edge='end' size='small' onClick={() => setTitleSeacrh('')}>
                    <Clear fontSize='small' />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </StyledFormControlWrapper>
        {(![PublicationStatus.Draft, PublicationStatus.Review].includes(status as PublicationStatus) ||
          keycloak.hasResourceRole('manage-other-authors-publications', 'publications')) && (
          <AuthorPickerWrapper
            handleChangeAuthor={handleChangeAuthor}
            width={fieldWidth}
            marginBottom={marginBottom}
            label={TAB_PANEL_CONTENT_CONSTS.searchByAuthor}
          />
        )}
        <RegionPickerWrapper
          handleChangeRegion={handleChangeRegion}
          label={TAB_PANEL_CONTENT_CONSTS.searchByRegion}
          marginBottom={marginBottom}
        />
        <KeyboardDatePickerWrapper
          disableToolbar
          variant='inline'
          format='yyyy-MM-dd'
          id='date-picker-inline'
          label={TAB_PANEL_CONTENT_CONSTS.searchByDate}
          value={searchDate}
          onChange={date => setSearchDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          slotProps={{
            openPickerButton: {color: 'primary'},
            field: {clearable: true}
          }}
        />
      </StyledContainer>

      {networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.refetch ? (
        <LinearProgress color='primary' />
      ) : (
        <>
          {!isEmptyList ? (
            <InfiniteScroll
              dataLength={data?.publications?.edges.length || 0}
              next={fetchMoreData}
              hasMore={!!data?.publications?.pageInfo.hasNextPage}
              loader={<LinearProgress color='primary' />}
              height='78vh'
              style={{overflowX: 'hidden', overflowY: 'auto', scrollBehavior: 'smooth'}}
              ref={ref}
              onScroll={e => onScroll(e.target)}
            >
              <PublicationList publicationsGroup={publicationsGroup} />
              <ScrollToTopButton refContainer={ref} pos={scrollPosition} />
            </InfiniteScroll>
          ) : (
            <Box marginTop='15px'>
              <Typography variant='h2'>{TAB_PANEL_CONTENT_CONSTS.publicationsNotFound}</Typography>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default TabPanelContent
function utcToZonedTime(searchDate: any): any {
  throw new Error('Function not implemented.')
}
