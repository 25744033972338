import {Quill} from 'react-quill'
import GalleryModalStore from '@stores/publicationStore/modals/galleryModalStore/galleryModalStore'
import rootStore from '@stores/rootStore'
import {GalleryBlotProps} from './GalleryBlotProps'
import {GALLERY_BLOT_CONSTS} from './GalleryBlot.consts'

const Embed = Quill.import('blots/block/embed')

/**
 * @class Класс для работы с галереей
 */
export default class GalleryBlot extends Embed {
  galleryModalStore: GalleryModalStore | null = null

  /**
   * Конструктор создания галереи
   * @constructor
   * @param node
   */
  constructor(node: HTMLElement) {
    super(node)
  }

  static create(gallery: GalleryBlotProps): HTMLElement {
    const node = super.create()
    const {publicationStore} = rootStore
    const {galleryModalStore} = publicationStore

    const {id, uid, galleryImages} = gallery

    node.setAttribute('draggable', 'false')
    node.setAttribute('id', id)
    node.setAttribute('uid', uid)
    node.setAttribute('galleryimages', JSON.stringify(galleryImages || []))
    node.setAttribute('contenteditable', 'false')

    const section = document.createElement('section')
    section.classList.add('carousel')
    section.setAttribute('aria-label', 'gallery')

    const deleteGallery = document.createElement('img')
    deleteGallery.setAttribute('src', '/deleteItem.jpg')
    deleteGallery.classList.add('delete-item')
    deleteGallery.setAttribute('draggable', 'false')
    deleteGallery.title = GALLERY_BLOT_CONSTS.galleryDeleting
    section.append(deleteGallery)

    const deleteGalleryEvent = new CustomEvent('deleteGalleryEvent', {
      detail: {id},
      bubbles: true
    })
    deleteGallery.addEventListener('click', () => {
      return window.dispatchEvent(deleteGalleryEvent)
    })

    const changeGallery = document.createElement('img')
    changeGallery.setAttribute('src', '/changeItem.jpg')
    changeGallery.classList.add('change-item')
    changeGallery.setAttribute('draggable', 'false')
    changeGallery.title = GALLERY_BLOT_CONSTS.galleryEditing
    section.append(changeGallery)

    changeGallery.addEventListener('click', () => {
      galleryModalStore.setGalleryDetail(gallery)
      galleryModalStore.setIsOpen(true)
    })

    const olElement = document.createElement('ol')
    olElement.classList.add('carousel_viewport')

    const goPrevBtn = document.createElement('button')
    goPrevBtn.setAttribute('onclick', `carouselHandleClick('${id}', 'prev')`)
    goPrevBtn.setAttribute('type', 'button')
    goPrevBtn.classList.add('carousel_prev')
    goPrevBtn.title = GALLERY_BLOT_CONSTS.prevTitle
    section.append(goPrevBtn)

    const goNextBtn = document.createElement('button')
    goNextBtn.setAttribute('onclick', `carouselHandleClick('${id}', 'next')`)
    goNextBtn.setAttribute('type', 'button')
    goNextBtn.classList.add('carousel_next')
    goNextBtn.title = GALLERY_BLOT_CONSTS.nextTitle
    section.append(goNextBtn)

    section.append(olElement)

    galleryImages.forEach((image, ind) => {
      const liItem = document.createElement('li')
      liItem.setAttribute('id', `carousel_slide_${ind}`)
      liItem.setAttribute('tabindex', '0')
      liItem.classList.add('carousel_slide')

      const divItem = document.createElement('div')
      divItem.classList.add('carousel_snapper')

      const imageDivItem: HTMLDivElement = document.createElement('div')
      imageDivItem.classList.add('carousel_snapper_image')

      imageDivItem.style.background = `url(${image.figureVersion?.primaryProcessedImage?.url || ''})`
      imageDivItem.style.backgroundPosition = 'center'
      imageDivItem.style.backgroundRepeat = 'no-repeat'
      imageDivItem.style.backgroundSize = 'contain'

      const indexInfoDivItem = document.createElement('div')
      indexInfoDivItem.classList.add('carousel_snapper_info')
      indexInfoDivItem.innerText = `${ind + 1} / ${galleryImages.length}`

      const descriptionDivItem = document.createElement('div')
      descriptionDivItem.classList.add('carousel_snapper_description')
      descriptionDivItem.innerText = image.description || ''

      const authorDivItem = document.createElement('div')
      authorDivItem.classList.add('carousel_snapper_author')
      authorDivItem.innerText = image.author || ''
      divItem.append(indexInfoDivItem)
      divItem.append(imageDivItem)
      divItem.append(descriptionDivItem)
      divItem.append(authorDivItem)
      liItem.append(divItem)
      olElement.append(liItem)
    })

    const caroselScript = document.createElement('script')
    caroselScript.setAttribute('src', '/carouselScript.js')

    section.append(caroselScript)
    node.append(section)
    return node
  }

  static value(node: HTMLElement): GalleryBlotProps {
    return {
      id: node.getAttribute('id') || '',
      uid: node.getAttribute('uid') || '',
      galleryImages: JSON.parse(node.getAttribute('galleryimages') || '') || []
    }
  }
}

GalleryBlot.tagName = 'div'
GalleryBlot.className = 'gallery'
GalleryBlot.blotName = 'custom-gallery'
