import React from 'react'
import {Typography} from '@mui/material'
import styled from 'styled-components'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import TopNewsContent from '@components/MainSelection/TopNews/TopNewsContent/TopNewsContent'
import TopNewsRegionTabs from '@components/MainSelection/TopNews/TopNewsRegionTabs/TopNewsRegionTabs'

const Title = styled(Typography)`
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #222222;
  margin-bottom: 10px;
`

const TopNewsPage: React.FC = () => {
  return (
    <PageContainer>
      <Title variant='h1'>Главное за день</Title>
      <TopNewsRegionTabs>
        <TopNewsContent />
      </TopNewsRegionTabs>
    </PageContainer>
  )
}

export default TopNewsPage
