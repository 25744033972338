import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Box, LinearProgress} from '@mui/material'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'
import {useKeycloak} from '@react-keycloak/web'
import {Publication} from '@graphql/types'
import PublicationForm from '@components/Publication/Form/PublicationForm/PublicationForm'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {useStore} from '@stores/rootStoreContext'
import ScrollToTopButton from '@components/UI/ScrollToTopButton/ScrollToTopButton'
import {deleteLockForNewAdminPanel, replaceLockForNewAdminPanel, updateLockForNewAdminPanel} from './locks.service'
import {PUBLICATION_CONSTS} from './Publication.consts'
import {usePublicationLazyQuery} from './gql/Publication.generated'

/**
 * Страница редактирования публикации
 */
const PublicationPage: FC = () => {
  const {publicationStore, editorStore} = useStore()
  const {
    publication,
    setPublication,
    setDefaultPublication,
    setCheckingPhotoEditor,
    setCheckingPhotoEditorCache,
    rubricAndSubrubricStore
  } = publicationStore
  const {toggleOnlineTranslation} = editorStore
  const {
    setRubricId,
    setRubricIdCache,
    setSubrubricId,
    setSubrubricIdCache,
    setRubricAvailableForRambler,
    setSubrubricAvailableForRambler
  } = rubricAndSubrubricStore
  const {uid} = useParams<{uid: string}>()
  const [canEditNew, setCanEditNew] = useState(true)
  const {keycloak} = useKeycloak()
  const [sessionIsDeletedNew, setSessionIsDeletedNew] = useState(true)
  const [publicationUid, setPublicationUid] = useState('')
  const [publicationType, setPublicationType] = useState<string | null | undefined>('')
  const [unlockedSessionIdForNew, setUnlockedSessionIdForNew] = useState<null | number>(null)
  const [blockerUser, setBlockerUser] = useState('')

  const history = useHistory()

  const [getPublication] = usePublicationLazyQuery({
    fetchPolicy: 'no-cache'
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getPublication({
      variables: {uid}
    }).then(res => {
      setPublicationUid(res.data?.publication?.uid)
      setPublicationType(res.data?.publication?.typeSlug)
      setPublication(res.data?.publication as any as Publication)
      setCheckingPhotoEditor(res.data?.publication?.checkingPhotoEditor || false)
      setCheckingPhotoEditorCache(res.data?.publication?.checkingPhotoEditor || false)
      setRubricId(res.data?.publication?.rubric?.id || '')
      setRubricIdCache(res.data?.publication?.rubric?.id || '')
      setRubricAvailableForRambler(res.data?.publication?.rubric?.availableForRambler || false)
      setSubrubricId(res.data?.publication?.subrubric?.id || '')
      setSubrubricIdCache(res.data?.publication?.subrubric?.id || '')
      setSubrubricAvailableForRambler(res.data?.publication?.subrubric?.availableForRambler || false)
      setLoading(false)
    })

    setDefaultPublication()
    toggleOnlineTranslation(false)
  }, [
    uid,
    getPublication,
    setPublication,
    setCheckingPhotoEditor,
    setCheckingPhotoEditorCache,
    setRubricId,
    setRubricIdCache,
    setRubricAvailableForRambler,
    setSubrubricId,
    setSubrubricIdCache,
    setSubrubricAvailableForRambler,
    setDefaultPublication,
    toggleOnlineTranslation
  ])

  const deleteLockFromNewAdminPanelEvent = useCallback(async (sessionIdForNewAdminPanel, uid, isEvenetListener?) => {
    if (!isEvenetListener) {
      setSessionIsDeletedNew(false)
    }
    await deleteLockForNewAdminPanel(sessionIdForNewAdminPanel, uid)
    if (!isEvenetListener) {
      setSessionIsDeletedNew(true)
    }
  }, [])

  useEffect(() => {
    let sessionIdForNewAdminPanel: number | null = unlockedSessionIdForNew
    let oldDeleteEvent = () => {
      return
    }
    let newDeleteEvent = () => {
      return
    }

    if (publicationUid && !loading && sessionIsDeletedNew) {
      const updateNewLocks = async (idForNew, deleteEvent?, oldEvent?) => {
        if (oldEvent) {
          window.removeEventListener('beforeunload', oldDeleteEvent)
        }
        oldDeleteEvent = deleteEvent
        if (idForNew) {
          window.addEventListener('beforeunload', deleteEvent)
          newDeleteEvent = deleteEvent
        }

        await updateLockForNewAdminPanel(
          idForNew,
          publicationUid,
          keycloak?.tokenParsed && keycloak?.tokenParsed['name']
        ).then(res => {
          if (res.success) {
            sessionIdForNewAdminPanel = res.lock.sessionId
          } else {
            setBlockerUser(res.lock.lockedBy?.username || 'Неизвестный')
          }
          setCanEditNew(res.success)
        })
      }
      updateNewLocks(sessionIdForNewAdminPanel)

      const interval = setInterval(() => {
        const deleteEvent = () => {
          deleteLockFromNewAdminPanelEvent(sessionIdForNewAdminPanel, publicationUid, true)
        }
        updateNewLocks(sessionIdForNewAdminPanel, deleteEvent, oldDeleteEvent)
      }, 6000)

      return () => {
        window.removeEventListener('beforeunload', newDeleteEvent)
        clearInterval(interval)

        setTimeout(() => {
          if (sessionIdForNewAdminPanel) {
            deleteLockFromNewAdminPanelEvent(sessionIdForNewAdminPanel, publicationUid)
          }
        }, 0)
      }
    }
  }, [
    publicationUid,
    // keycloak?.tokenParsed,
    loading,
    deleteLockFromNewAdminPanelEvent,
    sessionIsDeletedNew,
    unlockedSessionIdForNew,
    setBlockerUser
  ])

  const goBack = useCallback(() => {
    history.push(`/news`)
  }, [history])

  /**
   * Замена сессии блокировки
   * @returns void
   */
  const replaceLock = useCallback(async () => {
    replaceLockForNewAdminPanel(
      publicationUid,
      keycloak?.tokenParsed && keycloak?.tokenParsed['name'],
      keycloak.token
    ).then(res => {
      setUnlockedSessionIdForNew(res.lock.sessionId)
    })
  }, [publicationType, publication, keycloak, publicationUid])

  const canReplace = useMemo(() => {
    return keycloak.hasResourceRole('replace-lock', 'locks')
  }, [keycloak])

  const ref = useRef<HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  /**
   * Функция scroll-события
   * @param {any} само событие event
   * @returns void
   */
  const onScroll = useCallback(val => {
    if (val) {
      setScrollPosition(val.scrollTop)
    }
  }, [])

  return (
    <div
      id='scrollableDiv'
      style={{
        height: '92vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollBehavior: 'smooth'
      }}
      ref={ref}
      onScroll={e => onScroll(e.target)}
    >
      {canEditNew && (
        <Box position='relative'>
          {loading || !publication?.uid ? <LinearProgress color='primary' /> : <PublicationForm />}
          <ScrollToTopButton refContainer={ref} pos={scrollPosition} />
        </Box>
      )}
      {!canEditNew && !loading && (
        <SimpleModal
          open={true}
          mode='alert'
          onClose={goBack}
          title={PUBLICATION_CONSTS.anotherUserEditThisPublication + ' ' + blockerUser}
          okLabel={PUBLICATION_CONSTS.goBackToPublicationList}
          onOk={goBack}
          noLabel={canReplace ? PUBLICATION_CONSTS.edit : ''}
          onNo={replaceLock}
          style={{
            textAlign: 'center'
          }}
          text={canReplace ? PUBLICATION_CONSTS.clickOnEditButton : ''}
          maxWidth='sm'
        />
      )}
      {!canEditNew && loading && <LinearProgress color='primary' />}
    </div>
  )
}

export default observer(PublicationPage)
