import {Typography, Box, AccordionSummary} from '@mui/material'
import {ArrowDropDown} from '@mui/icons-material'
import React, {FC} from 'react'
import {StyledAccordion, StyledAccordionDetails, Link} from './ReferenceContent.styles'

type ReferenceContentProps = {
  references: any
}

const ReferenceContent: FC<ReferenceContentProps> = ({references}) => {
  const reference = 'СПРАВКА'
  const haveQuestion = 'Остались вопросы или предложения? '
  const sendUsToEmail = 'Напишите нам на почту'
  const forSearchUse = '(для поиска информации используйти кнопки CTRL + F)'
  return (
    <>
      <Box display='flex' justifyContent='space-between' marginBottom='40px' marginTop='30px'>
        <Typography variant='h1'>{reference}</Typography>
        <Typography>
          {haveQuestion}
          <a href='mailto:opros_it@ura.ru'>{sendUsToEmail}</a>
        </Typography>
      </Box>
      {references?.sections &&
        references.sections.map(section => (
          <StyledAccordion key={section.name}>
            <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls={section.name} id={section.name}>
              <Typography>{section.name}</Typography>
            </AccordionSummary>
            <StyledAccordionDetails>
              {section.links.map(link => (
                <Link
                  onClick={() => {
                    window.open(link.link)
                  }}
                  key={link.name}
                >
                  {link.name}
                </Link>
              ))}
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      {references.allInOneFile && (
        <Box display='flex' justifyContent='center' marginBottom='40px' marginTop='30px'>
          <Typography>
            <Link
              onClick={() => {
                window.open(references.allInOneFile.link)
              }}
              display='inline'
            >
              {references.allInOneFile.name}
            </Link>
            {forSearchUse}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default ReferenceContent
