import styled from 'styled-components'
import {Accordion, Typography, AccordionDetails} from '@mui/material'

export const StyledAccordion = styled(Accordion)`
  margin-bottom: 12px;
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 1px solid grey;
`

export const Link = styled(Typography)`
  color: blue;
  margin-bottom: 16px;
  width: 400px;
  cursor: pointer;
`
