import {SvgIconProps} from '@mui/material'
import React, {FC} from 'react'
import {grey20, mainTextColor} from '@theme/vars'

export const LikeIcon: FC<SvgIconProps> = props => {
  return (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.8146 4.13997L10.2826 6.8373C10.1706 7.38797 10.32 7.9573 10.6746 8.38664C11.0293 8.81597 11.552 9.06797 12.112 9.06797H17.2453V10.076L14.8466 15.6013H7.29598C7.12798 15.6013 6.97865 15.452 6.97865 15.284V7.96664L10.8146 4.13997ZM11.6453 0.667969L5.66265 6.65064C5.30798 7.0053 5.11198 7.4813 5.11198 7.97597V15.284C5.11198 16.488 6.09198 17.468 7.29598 17.468H14.856C15.5186 17.468 16.1253 17.1226 16.4613 16.5626L18.9533 10.8226C19.056 10.5893 19.112 10.3373 19.112 10.076V9.06797C19.112 8.0413 18.272 7.2013 17.2453 7.2013H12.112L12.9706 2.8613C13.0173 2.65597 12.9893 2.43197 12.896 2.2453C12.6813 1.8253 12.4106 1.44264 12.0746 1.10664L11.6453 0.667969ZM2.31198 7.2013H0.445312V17.468H2.31198C2.82531 17.468 3.24531 17.048 3.24531 16.5346V8.13464C3.24531 7.6213 2.82531 7.2013 2.31198 7.2013Z'
        fill={props.color === 'primary' ? mainTextColor : grey20}
      />
    </svg>
  )
}
