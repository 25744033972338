import styled from 'styled-components'
import {DateTimePicker} from '@mui/x-date-pickers'
import {blueColor, grayColor} from '@theme/vars'

export const DatePickerWrapper = styled(DateTimePicker)`
  width: 210px;
  min-width: 170px;
  color: ${grayColor};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 0px;
  margin-bottom: 8px;

  & input {
    padding: 0;
  }

  .MuiIconButton-root {
    padding: 0;
  }
`

export const DatePickerInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${blueColor};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: -6px;
  letter-spacing: 0px;
`
