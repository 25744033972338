import {FormHelperText, InputLabel, OutlinedInput} from '@mui/material'
import React, {FC, forwardRef, useCallback, useEffect, useState} from 'react'
import {redColor} from '../../../@theme/vars'
import {StyledFormControl} from '../styled'
import {INPUT_WITH_STRING_VALIDATION_CONSTS} from './InputStringWithValidation.consts'
import {FieldValueLength} from './InputStringWithValidation.styles'
import {InputStringWithValidationProps} from './InputStringWithValidationProps'

export const InputStringWithValidation = forwardRef<HTMLInputElement, InputStringWithValidationProps>((props, ref) => {
  const {
    id,
    title,
    defaultValue,
    clearFieldEventCounter = 0,
    setValue,
    setErrorText,
    showTitleLength,
    maxLength = 250,
    width = '250px',
    required = true
  } = props

  const [fieldValue, setFieldValue] = useState(defaultValue || '')
  const [fieldDirty, setFieldDirty] = useState(false)
  const [fieldErrorText, setFieldErrorText] = useState('')

  const checkValidation = useCallback(
    (val: string) => {
      if (val && val?.length > maxLength) {
        setFieldErrorText(INPUT_WITH_STRING_VALIDATION_CONSTS.characterLimitReachedMsg)
        setErrorText(INPUT_WITH_STRING_VALIDATION_CONSTS.characterLimitReachedMsg)
      } else {
        setFieldErrorText('')
        setErrorText('')
      }
      if (required && !val && fieldDirty) {
        setFieldErrorText(INPUT_WITH_STRING_VALIDATION_CONSTS.fieldIsRequiredMsg)
        setErrorText(INPUT_WITH_STRING_VALIDATION_CONSTS.fieldIsRequiredMsg)
      }
    },
    [maxLength, required, fieldDirty, setErrorText]
  )

  useEffect(() => {
    setFieldValue(defaultValue || '')
    checkValidation(defaultValue || '')
  }, [defaultValue, checkValidation])

  useEffect(() => {
    setFieldDirty(false)
  }, [clearFieldEventCounter])

  const handleChange = useCallback(
    val => {
      setFieldValue(val)
      setFieldDirty(true)
      setValue(val)
      checkValidation(val)
    },
    [setValue, checkValidation]
  )

  return (
    <StyledFormControl width={width} required={required} error={required && !fieldValue && fieldDirty} id={id}>
      <InputLabel>{title}</InputLabel>
      <OutlinedInput
        inputRef={ref}
        label={title}
        onChange={e => handleChange(e.target.value)}
        error={!!fieldErrorText}
        multiline
        value={fieldValue}
        required={required}
      />
      {!!fieldErrorText && (
        <FormHelperText
          style={{
            color: redColor
          }}
        >
          {fieldErrorText}
        </FormHelperText>
      )}
      {!!showTitleLength && (
        <FieldValueLength
          style={{
            color: !!fieldErrorText ? redColor : 'inherit'
          }}
        >
          {`${fieldValue?.length || 0} / ${maxLength}`}
        </FieldValueLength>
      )}
    </StyledFormControl>
  )
})

InputStringWithValidation.displayName = 'InputStringWithValidation'
