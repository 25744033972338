import * as Types from '../../../@graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetBildCheckListPublicationsQueryVariables = Types.Exact<{
  checkPhotoEditor?: Types.Maybe<Types.Scalars['Boolean']>;
  photoFromOutPhotobank?: Types.Maybe<Types.Scalars['Boolean']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  first?: Types.Maybe<Types.Scalars['Int']>;
  datePublished?: Types.Maybe<Types.Scalars['DateTime']>;
  status?: Types.Maybe<Types.PublicationStatus>;
  sort?: Types.Maybe<Types.PublicationsSort>;
}>;


export type GetBildCheckListPublicationsQuery = (
  { __typename?: 'Query' }
  & { publications?: Types.Maybe<(
    { __typename?: 'PublicationsConnection' }
    & { edges: Array<Types.Maybe<(
      { __typename?: 'PublicationsEdge' }
      & Pick<Types.PublicationsEdge, 'cursor'>
      & { node?: Types.Maybe<(
        { __typename?: 'Publication' }
        & BildCheckListPublicationNodeFragment
      )> }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type SetCheckingPhotoEditorMutationVariables = Types.Exact<{
  data: Types.SetCheckingPhotoEditorInput;
}>;


export type SetCheckingPhotoEditorMutation = (
  { __typename?: 'Mutation' }
  & { setCheckingPhotoEditor?: Types.Maybe<(
    { __typename?: 'Publication' }
    & Pick<Types.Publication, 'checkingPhotoEditor'>
  )> }
);

export type BildCheckListPublicationNodeFragment = (
  { __typename?: 'Publication' }
  & Pick<Types.Publication, 'id' | 'uid' | 'title' | 'publishedAt' | 'createdAt' | 'publishedAtDate' | 'typeSlug'>
  & { authors: Array<(
    { __typename?: 'Author' }
    & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>
  )> }
);

export const BildCheckListPublicationNodeFragmentDoc = gql`
    fragment BildCheckListPublicationNode on Publication {
  id
  uid
  title
  publishedAt
  createdAt
  publishedAtDate
  typeSlug
  authors {
    uid
    firstName
    lastName
  }
}
    `;
export const GetBildCheckListPublicationsDocument = gql`
    query getBildCheckListPublications($checkPhotoEditor: Boolean, $photoFromOutPhotobank: Boolean, $after: String, $first: Int, $datePublished: DateTime, $status: PublicationStatus, $sort: PublicationsSort) {
  publications(
    sort: $sort
    limit: 30
    filter: {checkPhotoEditor: $checkPhotoEditor, photoFromOutPhotobank: $photoFromOutPhotobank, datePublished: $datePublished, status: $status}
    first: $first
    after: $after
  ) {
    edges {
      cursor
      node {
        ...BildCheckListPublicationNode
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${BildCheckListPublicationNodeFragmentDoc}`;

/**
 * __useGetBildCheckListPublicationsQuery__
 *
 * To run a query within a React component, call `useGetBildCheckListPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBildCheckListPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBildCheckListPublicationsQuery({
 *   variables: {
 *      checkPhotoEditor: // value for 'checkPhotoEditor'
 *      photoFromOutPhotobank: // value for 'photoFromOutPhotobank'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      datePublished: // value for 'datePublished'
 *      status: // value for 'status'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetBildCheckListPublicationsQuery(baseOptions?: Apollo.QueryHookOptions<GetBildCheckListPublicationsQuery, GetBildCheckListPublicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBildCheckListPublicationsQuery, GetBildCheckListPublicationsQueryVariables>(GetBildCheckListPublicationsDocument, options);
      }
export function useGetBildCheckListPublicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBildCheckListPublicationsQuery, GetBildCheckListPublicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBildCheckListPublicationsQuery, GetBildCheckListPublicationsQueryVariables>(GetBildCheckListPublicationsDocument, options);
        }
export type GetBildCheckListPublicationsQueryHookResult = ReturnType<typeof useGetBildCheckListPublicationsQuery>;
export type GetBildCheckListPublicationsLazyQueryHookResult = ReturnType<typeof useGetBildCheckListPublicationsLazyQuery>;
export type GetBildCheckListPublicationsQueryResult = Apollo.QueryResult<GetBildCheckListPublicationsQuery, GetBildCheckListPublicationsQueryVariables>;
export const SetCheckingPhotoEditorDocument = gql`
    mutation setCheckingPhotoEditor($data: SetCheckingPhotoEditorInput!) {
  setCheckingPhotoEditor(data: $data) {
    checkingPhotoEditor
  }
}
    `;
export type SetCheckingPhotoEditorMutationFn = Apollo.MutationFunction<SetCheckingPhotoEditorMutation, SetCheckingPhotoEditorMutationVariables>;

/**
 * __useSetCheckingPhotoEditorMutation__
 *
 * To run a mutation, you first call `useSetCheckingPhotoEditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCheckingPhotoEditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCheckingPhotoEditorMutation, { data, loading, error }] = useSetCheckingPhotoEditorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetCheckingPhotoEditorMutation(baseOptions?: Apollo.MutationHookOptions<SetCheckingPhotoEditorMutation, SetCheckingPhotoEditorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCheckingPhotoEditorMutation, SetCheckingPhotoEditorMutationVariables>(SetCheckingPhotoEditorDocument, options);
      }
export type SetCheckingPhotoEditorMutationHookResult = ReturnType<typeof useSetCheckingPhotoEditorMutation>;
export type SetCheckingPhotoEditorMutationResult = Apollo.MutationResult<SetCheckingPhotoEditorMutation>;
export type SetCheckingPhotoEditorMutationOptions = Apollo.BaseMutationOptions<SetCheckingPhotoEditorMutation, SetCheckingPhotoEditorMutationVariables>;