import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type UpdateFigureSmmMutationVariables = Types.Exact<{
  data: Types.UpdateFigureInput
}>

export type UpdateFigureSmmMutation = {__typename?: 'Mutation'} & {
  updateFigure?: Types.Maybe<
    {__typename?: 'UpdateFigurePayload'} & {
      figure?: Types.Maybe<
        {__typename?: 'Figure'} & Pick<Types.Figure, 'uid'> & {
            cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
            latestVersion: {__typename?: 'FigureVersion'} & {
              primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url' | 'code'>>
            }
          }
      >
    }
  >
}

export const UpdateFigureSmmDocument = gql`
  mutation updateFigureSmm($data: UpdateFigureInput!) {
    updateFigure(data: $data) {
      figure {
        uid
        cropperPreview {
          url
        }
        latestVersion {
          primaryProcessedImage {
            url
            code
          }
        }
      }
    }
  }
`
export type UpdateFigureSmmMutationFn = Apollo.MutationFunction<
  UpdateFigureSmmMutation,
  UpdateFigureSmmMutationVariables
>

/**
 * __useUpdateFigureSmmMutation__
 *
 * To run a mutation, you first call `useUpdateFigureSmmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFigureSmmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFigureSmmMutation, { data, loading, error }] = useUpdateFigureSmmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFigureSmmMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFigureSmmMutation, UpdateFigureSmmMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateFigureSmmMutation, UpdateFigureSmmMutationVariables>(UpdateFigureSmmDocument, options)
}
export type UpdateFigureSmmMutationHookResult = ReturnType<typeof useUpdateFigureSmmMutation>
export type UpdateFigureSmmMutationResult = Apollo.MutationResult<UpdateFigureSmmMutation>
export type UpdateFigureSmmMutationOptions = Apollo.BaseMutationOptions<
  UpdateFigureSmmMutation,
  UpdateFigureSmmMutationVariables
>
