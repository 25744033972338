import {Author, PublicationsEdge} from '@graphql/types'
import {PublicationsDataPreferResult} from '../PublicationsDataPreferResult'
import {ReportsListTableDataProps} from '../ReportsListTableDataProps'
import {format} from 'date-fns'
import {PublicationForMetric} from 'types/PublicationForMetric'

/**
 * Функция подготовки данных для отрисовки отчета
 * @param data
 * @returns
 */
export const publicationsDataPrefer = (data: PublicationsEdge[]): PublicationsDataPreferResult => {
  const publications: PublicationForMetric = {}
  const publicationListData: ReportsListTableDataProps[] = []

  if (data.length) {
    data.forEach((p, ind) => {
      publications[`type${ind + 1}`] = {
        id:
          p.node?.typeSlug === 'news'
            ? (p.node?.canonicalUrl && p.node?.canonicalUrl.replace('https://ura.news/news/', '')) || ''
            : (p.node?.canonicalUrl && p.node?.canonicalUrl.replace('https://ura.news/articles/', '')) || '',
        type: p.node?.typeSlug || 'news'
      }

      const authors: string[] = []

      p?.node?.authors.forEach((a: Author) => {
        authors.push(`${a.firstName} ${a.lastName}`)
      })
      publicationListData.push({
        authors,
        canonicalUrl: p.node?.canonicalUrl,
        id: p.node?.id,
        date: format(new Date(p.node?.publishedAt), 'yyyy-MM-dd'),
        time: format(new Date(p.node?.publishedAt), 'HH:mm'),
        title: p.node?.title || '',
        seoTitle: p.node?.seoTitle || '',
        rubricTitle: p.node?.rubric?.title || '',
        subrubricTitle: p.node?.subrubric?.title || '',
        typeSlug: p.node?.typeSlug
      } as ReportsListTableDataProps)
    })
  }
  return {
    publications,
    publicationListData
  } as PublicationsDataPreferResult
}
