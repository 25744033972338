import React, {FC, useCallback, useState} from 'react'
import {FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'
import {Clear} from '@mui/icons-material'
import {StyledFormControl} from '../../../UI/styled'
import {useFormContext} from 'react-hook-form'

type linkPickerProps = {
  links?: string
}

const SpecialProjectInput: FC<linkPickerProps> = ({links}) => {
  const {setValue, getValues} = useFormContext()
  const [errorText, setErrorText] = useState('')
  const [link, setLink] = useState(links)

  const saveChanges = useCallback(() => {
    setValue('specProjectUrl', link)
  }, [link, setValue])

  const label = 'Ссылка на внешний спецпроект'

  const handleClearUrl = useCallback(() => {
    setLink('')
    setValue('specProjectUrl', link)
    setErrorText('')
  }, [link, setValue])

  return (
    <StyledFormControl width='296px' marginbottom='20px'>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        label={label}
        onChange={e => setLink(e.target.value)}
        onBlur={saveChanges}
        value={link}
        error={!!errorText}
        disabled={getValues('cantEditPublication')}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              edge='end'
              size='small'
              disabled={getValues('cantEditPublication')}
              onClick={() => handleClearUrl()}
            >
              <Clear fontSize='small' />
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{errorText}</FormHelperText>
    </StyledFormControl>
  )
}

export default SpecialProjectInput
