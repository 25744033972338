import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1270px;
  margin: 0 auto;
`

export const Header = styled.div`
  width: 100%;
  margin-bottom: 25px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
`
