import StoriesList from '@components/Stories/StoriesList'
import React, {createContext, FC} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'

const tabNames = [
  {name: 'ACTUAL', title: 'Актуальные сюжеты', isActive: true},
  {name: 'DELETED', title: 'Архивные сюжеты', isActive: false}
]

const TYPE_SLUG = 'stories'

export const StoriesType = createContext(TYPE_SLUG)

const StoriesPage: FC = () => {
  return (
    <StoriesType.Provider value={TYPE_SLUG}>
      <PageContainer>
        <StoriesList tabNames={tabNames} />
      </PageContainer>
    </StoriesType.Provider>
  )
}

export default StoriesPage
