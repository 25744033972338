import React, {FC} from 'react'
import {useParams} from 'react-router'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import CollectionForm from '@components/Collection/Form/CollectionForm'
import {useCollectionQuery} from './gql/Collections.generated'
import {Collection} from '@graphql/types'
import {Box, LinearProgress} from '@mui/material'

const CollectionEditorPage: FC = () => {
  const {uid} = useParams<{uid: string}>()
  const {data, loading} = useCollectionQuery({variables: {uid: uid}})

  return (
    <PageContainer>
      <Box position='relative'>
        {loading || !data?.collection ? (
          <LinearProgress color='primary' />
        ) : (
          <CollectionForm collection={data?.collection as Collection} />
        )}
      </Box>
    </PageContainer>
  )
}

export default CollectionEditorPage
