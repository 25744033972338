import {UiAutoModeratorResult} from '@entities/types/UiAutoModeratorResult'
import defaultPublication from '@entities/api/publication/publication.consts'
import {UiComment} from 'entities/types/UiComment'
import {UiCommentAuthor} from 'entities/types/UiCommentAuthor'
import {UiUser} from 'entities/types/UiUser'
import {UiCommentsConnection} from 'entities/types/UiCommentsConnection'
import {UiCommentsEdge} from 'entities/types/UiCommentsEdge'
import {CommentsConnection} from '@graphql/types'
import defaultComment from '../comments.consts'

const getCommentsListAdapterToClient = (response: CommentsConnection): UiCommentsConnection => {
  if (!response.edges?.length) {
    return {
      edges: [],
      pageInfo: {
        endCursor: '',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: ''
      },
      totalCount: 0
    }
  }

  const commentsEdges: UiCommentsEdge[] = []

  response.edges.forEach(item => {
    if (item?.node) {
      const {
        author,
        autoModeratorResult,
        createdAt,
        id,
        isBad,
        moderatedAt,
        parentComment,
        publication,
        status,
        text,
        uid,
        updatedAt,
        viewed
      } = item?.node

      let authorElement: UiCommentAuthor | null = null

      if (author) {
        const {ipAddress, name, sessionId, user} = author

        let userElement: UiUser | null = null

        if (user) {
          const {username, firstname, lastname, uid: userUid} = user

          userElement = {
            id: '',
            uid: userUid || '',
            username: username || '',
            firstname: firstname || '',
            lastname: lastname || ''
          }
        }
        authorElement = {
          ipAddress,
          name,
          sessionId,
          user: userElement ?? undefined
        }
      }

      let autoModeratorResultElement: UiAutoModeratorResult | null = null

      if (autoModeratorResult) {
        const {authorName, text} = autoModeratorResult

        autoModeratorResultElement = {
          authorName,
          text
        }
      }

      let parentCommentElement: UiComment | null = null

      if (parentComment) {
        const {id, uid, author} = parentComment

        const authorElement: UiCommentAuthor = {
          name: author?.name || '',
          ipAddress: '',
          sessionId: ''
        }

        parentCommentElement = {
          id: id || '',
          uid: uid || '',
          allComments: defaultComment.allComments,
          author: authorElement,
          autoModeratorResult: defaultComment.autoModeratorResult,
          createdAt: defaultComment.createdAt,
          moderatedAt: defaultComment.moderatedAt,
          newComments: defaultComment.newComments,
          parentComment: defaultComment.parentComment,
          publication: defaultComment.publication,
          status: defaultComment.status,
          text: defaultComment.text,
          updatedAt: defaultComment.updatedAt,
          viewed: defaultComment.viewed
        }
      }

      commentsEdges.push({
        cursor: item.cursor,
        node: {
          allComments: defaultComment.allComments,
          author: authorElement ?? defaultComment.author,
          autoModeratorResult: autoModeratorResultElement ?? defaultComment.autoModeratorResult,
          child: defaultComment.child,
          createdAt,
          id: id || '',
          isBad: isBad ?? undefined,
          moderatedAt: moderatedAt ?? defaultComment.moderatedAt,
          newComments: defaultComment.newComments,
          parentComment: parentCommentElement ?? defaultComment.parentComment,
          status,
          text: text || '',
          uid: uid || '',
          updatedAt: updatedAt ?? defaultComment.updatedAt,
          viewed: viewed ?? defaultComment.viewed,
          publication: {
            uid: publication.uid || '',
            title: publication.title || '',
            adParameters: defaultPublication.adParameters,
            ampUrl: defaultPublication.ampUrl,
            announceImage: defaultPublication.announceImage,
            authors: defaultPublication.authors,
            badwords: defaultPublication.badwords,
            canonicalUrl: defaultPublication.canonicalUrl,
            checkEditor: defaultPublication.checkEditor,
            checkingPhotoEditor: defaultPublication.checkingPhotoEditor,
            collections: defaultPublication.collections,
            comments: defaultPublication.comments,
            commentsCount: defaultPublication.commentsCount,
            content: defaultPublication.content,
            createdAt: defaultPublication.createdAt,
            creator: defaultPublication.creator,
            deprecatedId: defaultPublication.deprecatedId,
            destinations: defaultPublication.destinations,
            experiment: defaultPublication.experiment,
            hasOverviewBlock: defaultPublication.hasOverviewBlock,
            hasTopNewsBlock: defaultPublication.hasTopNewsBlock,
            id: defaultPublication.id,
            insidesCarousel: defaultPublication.insidesCarousel,
            isAutomaticCommentsDisabled:
              publication.isAutomaticCommentsDisabled ?? defaultPublication.isAutomaticCommentsDisabled,
            isCommentsEnabled: defaultPublication.isCommentsEnabled,
            isImportant: defaultPublication.isImportant,
            isInside: defaultPublication.isInside,
            isInvert: defaultPublication.isInvert,
            isOnlineBroadcast: defaultPublication.isOnlineBroadcast,
            keywords: defaultPublication.keywords,
            nationalProject: defaultPublication.nationalProject,
            ogDescription: defaultPublication.ogDescription,
            ogTitle: defaultPublication.ogTitle,
            oldAdminUrl: defaultPublication.oldAdminUrl,
            overviewDescription: defaultPublication.overviewDescription,
            overviewFigure: defaultPublication.overviewFigure,
            overviewFigureId: defaultPublication.overviewFigureId,
            overviewFigureVersionId: defaultPublication.overviewFigureVersionId,
            overviewTitle: defaultPublication.overviewTitle,
            pdaUrl: defaultPublication.pdaUrl,
            publishedAt: defaultPublication.publishedAt,
            publishedAtDate: defaultPublication.publishedAtDate,
            ramblerTitle: defaultPublication.ramblerTitle,
            redirectUrl: defaultPublication.redirectUrl,
            regions: defaultPublication.regions,
            relatedLinks: defaultPublication.relatedLinks,
            rubric: defaultPublication.rubric,
            sendToRambler: defaultPublication.sendToRambler,
            seoTitle: defaultPublication.seoTitle,
            socialMediaImage: defaultPublication.socialMediaImage,
            specProjectUrl: defaultPublication.specProjectUrl,
            status: defaultPublication.status,
            story: defaultPublication.story,
            subrubric: defaultPublication.subrubric,
            subscriptionBlock: defaultPublication.subscriptionBlock,
            subtitle: defaultPublication.subtitle,
            tags: defaultPublication.tags,
            type: defaultPublication.type,
            typeSlug: defaultPublication.typeSlug,
            updatedAt: defaultPublication.updatedAt,
            url: defaultPublication.url,
            yandexDzenTitle: defaultPublication.yandexDzenTitle,
            yandexNewsVideos: defaultPublication.yandexNewsVideos,
            yandexTitle: defaultPublication.yandexTitle,
            yandexZenCategories: defaultPublication.yandexZenCategories
          }
        }
      })
    }
  })

  const {endCursor, hasNextPage, hasPreviousPage} = response.pageInfo

  return {
    edges: commentsEdges,
    pageInfo: {
      endCursor: endCursor || '',
      hasNextPage: hasNextPage || false,
      hasPreviousPage: hasPreviousPage || false,
      startCursor: ''
    },
    totalCount: response.totalCount || 0
  }
}

export {getCommentsListAdapterToClient}
