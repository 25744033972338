import React, {FC} from 'react'
import {LinkWrapper, TBodyWrapper, TdDivWrapper, TdWrapper, TrWrapper} from './ReportListTableBodyFields.styles'
import {ReportListTableBodyFieldsProps} from './ReportListTableBodyFieldsProps'
import {Tooltip} from '@mui/material'

/**
 * Компонент отрисовки полей данных таблицы
 * @param param0
 * @returns
 */
export const ReportListTableBodyFields: FC<ReportListTableBodyFieldsProps> = ({fields, publicationList}) => {
  return (
    <TBodyWrapper>
      {publicationList?.length > 0 &&
        publicationList.map((publication, ind) => (
          <TrWrapper key={publication.id}>
            <TdWrapper style={{width: 34}}>
              <TdDivWrapper>{ind + 1}</TdDivWrapper>
            </TdWrapper>
            {fields.date && (
              <TdWrapper style={{width: 73}}>
                <TdDivWrapper>{publication.date}</TdDivWrapper>
              </TdWrapper>
            )}
            {fields.time && (
              <TdWrapper style={{width: 49}}>
                <TdDivWrapper>{publication.time}</TdDivWrapper>
              </TdWrapper>
            )}
            {fields.title && (
              <TdWrapper style={{width: 200}}>
                <LinkWrapper href={publication.canonicalUrl} target='_blank'>
                  <Tooltip title={publication.title}>
                    <TdDivWrapper>{publication.title}</TdDivWrapper>
                  </Tooltip>
                </LinkWrapper>
              </TdWrapper>
            )}
            {fields.seoTitle && (
              <TdWrapper style={{width: 200}}>
                <Tooltip title={publication.seoTitle}>
                  <TdDivWrapper>{publication.seoTitle}</TdDivWrapper>
                </Tooltip>
              </TdWrapper>
            )}
            {fields.rubricAndSubrubric && (
              <TdWrapper style={{width: 164}}>
                <TdDivWrapper>
                  <span>{publication.rubricTitle}</span>
                  {publication.subrubricTitle && <span>, {publication.subrubricTitle}</span>}
                </TdDivWrapper>
              </TdWrapper>
            )}
            {fields.authors && (
              <TdWrapper style={{width: 126}}>
                <TdDivWrapper>
                  {publication?.authors?.length &&
                    publication.authors.map((author: string, ind: number) => <div key={ind}>{author}</div>)}
                </TdDivWrapper>
              </TdWrapper>
            )}
            {fields.viewsCount && (
              <TdWrapper style={{width: 82}}>
                <TdDivWrapper>{publication.viewsCount || '-'}</TdDivWrapper>
              </TdWrapper>
            )}
            {fields.uniqueUsersCount && (
              <TdWrapper style={{width: 83}}>
                <TdDivWrapper>{publication.uniqueUsersCount || '-'}</TdDivWrapper>
              </TdWrapper>
            )}
            {fields.fromSocialNetworks && (
              <TdWrapper style={{width: 80}}>
                <TdDivWrapper>{publication?.socialVisits || '-'}</TdDivWrapper>
              </TdWrapper>
            )}
            {fields.fromSocialNetworksPercent && (
              <TdWrapper style={{width: 66}}>
                <TdDivWrapper>{publication?.percentSocial || '-'}</TdDivWrapper>
              </TdWrapper>
            )}
            {fields.withoutRecommendedSystems && (
              <TdWrapper style={{width: 96}}>
                <TdDivWrapper>{publication?.withoutRecommendedSystems || '-'}</TdDivWrapper>
              </TdWrapper>
            )}
          </TrWrapper>
        ))}
    </TBodyWrapper>
  )
}
