import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type SearchPublicationByDeprecatedIdQueryVariables = Types.Exact<{
  deprecatedId: Types.Scalars['Int']
}>

export type SearchPublicationByDeprecatedIdQuery = {__typename?: 'Query'} & {
  publicationByDeprecatedId?: Types.Maybe<
    {__typename?: 'Publication'} & Pick<Types.Publication, 'uid' | 'title' | 'subtitle' | 'url'> & {
        regions?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>>
        authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
        announceImage: {__typename?: 'AnnounceImage'} & Pick<
          Types.AnnounceImage,
          'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
        > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>}
      }
  >
}

export type FigureVersionFragmentFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'src' | 'uid'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
      }
  }

export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const SearchPublicationByDeprecatedIdDocument = gql`
  query searchPublicationByDeprecatedId($deprecatedId: Int!) {
    publicationByDeprecatedId(deprecatedId: $deprecatedId) {
      uid
      title
      subtitle
      url
      regions {
        uid
        title
      }
      authors {
        uid
        firstName
        lastName
      }
      announceImage {
        id
        isFullWidth
        description
        author
        alt
        figureVersion {
          ...FigureVersionFragment
        }
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`

/**
 * __useSearchPublicationByDeprecatedIdQuery__
 *
 * To run a query within a React component, call `useSearchPublicationByDeprecatedIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPublicationByDeprecatedIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPublicationByDeprecatedIdQuery({
 *   variables: {
 *      deprecatedId: // value for 'deprecatedId'
 *   },
 * });
 */
export function useSearchPublicationByDeprecatedIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPublicationByDeprecatedIdQuery,
    SearchPublicationByDeprecatedIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<SearchPublicationByDeprecatedIdQuery, SearchPublicationByDeprecatedIdQueryVariables>(
    SearchPublicationByDeprecatedIdDocument,
    options
  )
}
export function useSearchPublicationByDeprecatedIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPublicationByDeprecatedIdQuery,
    SearchPublicationByDeprecatedIdQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<SearchPublicationByDeprecatedIdQuery, SearchPublicationByDeprecatedIdQueryVariables>(
    SearchPublicationByDeprecatedIdDocument,
    options
  )
}
export type SearchPublicationByDeprecatedIdQueryHookResult = ReturnType<typeof useSearchPublicationByDeprecatedIdQuery>
export type SearchPublicationByDeprecatedIdLazyQueryHookResult = ReturnType<
  typeof useSearchPublicationByDeprecatedIdLazyQuery
>
export type SearchPublicationByDeprecatedIdQueryResult = Apollo.QueryResult<
  SearchPublicationByDeprecatedIdQuery,
  SearchPublicationByDeprecatedIdQueryVariables
>
