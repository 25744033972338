import {styled} from 'styled-components'
import {screenS} from '@theme/vars'
import SimpleModal from '../../SimpleModal/SimpleModal'

export const SimpleModalWrapper = styled(SimpleModal)`
  & .MuiDialog-paperWidthSm {
    max-width: 564px !important;
  }
`

export const GalleryImageWrapper = styled.div`
  width: 100%;
`

export const DeleteButtonWrapper = styled.div`
  display: block;
  position: absolute;
  top: 20px;
  left: calc(50% - 45px);

  @media screen and (max-width: ${screenS}) {
    left: calc(100% - 49px);
  }
`
