import React, {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import * as _ from 'lodash'
import {useKeycloak} from '@react-keycloak/web'
import InfiniteScroll from 'react-infinite-scroll-component'
import {NetworkStatus} from '@apollo/client'
import {format, isValid, parseISO} from 'date-fns'
import {
  FormControlLabelWrapper,
  KeyboardDatePickerWrapper,
  StyledContainer
} from './BildCheckListTabPanelContent.styles'
import ScrollToTopButton from '@components/UI/ScrollToTopButton/ScrollToTopButton'
import {PublicationStatus, PublicationsSort} from '@graphql/types'
import {useGetBildCheckListPublicationsQuery} from '../gql/BildCheckList.generated'
import {BILD_CHECK_LIST_TAB_PANEL_CONTENT} from './BildCheckListTabPanelContent.consts'
import {BildCheckType} from '../BildCheckList'
import BildCheckListTable from '../BildCheckListTable/BildCheckListTable'
import {Box, Checkbox, Typography, LinearProgress, FormControlLabel} from '@mui/material'
import {BildCheckListTabPanelContentProps} from './BildCheckListTabPanelContentProps'

/**
 * Компонент отрисовки содержимого таба в BildCheckList
 * @param {BildCheckListTabPanelContentProps}
 * @returns
 */
const BildCheckListTabPanelContent: FC<BildCheckListTabPanelContentProps> = ({status, refetchDateTime}) => {
  const [isEmptyList, setIsEmptyList] = useState(false)
  const bildCheckType = useContext(BildCheckType)
  const [searchDate, setSearchDate] = useState<any>(null)
  const [photoFromOutPhotobank, setPhotoFromOutPhotobank] = useState(false)
  const [checkPhotoEditor, setCheckPhotoEditor] = useState(status?.includes('CHECKED') ? true : false)

  const variables = useMemo(
    () => ({
      datePublished: searchDate && isValid(searchDate) ? format(searchDate, 'yyyy-MM-dd') : null,
      checkPhotoEditor: checkPhotoEditor,
      photoFromOutPhotobank: checkPhotoEditor ? undefined : photoFromOutPhotobank === true ? true : undefined,
      sort: PublicationsSort.PublishedAtDesc,
      status: PublicationStatus.Published
    }),
    [searchDate, checkPhotoEditor, photoFromOutPhotobank]
  )

  useEffect(() => {
    setCheckPhotoEditor(status == 'CHECKED' ? true : false)
  }, [status, setCheckPhotoEditor])

  const {data, fetchMore, refetch, networkStatus} = useGetBildCheckListPublicationsQuery({
    variables: variables,
    onCompleted: data => {
      setIsEmptyList(!data.publications?.edges.length)
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (!searchDate || (searchDate && isValid(searchDate))) {
      const filters = {
        datePublished: searchDate ? format(searchDate, 'yyyy-MM-dd') : null,
        checkPhotoEditor: checkPhotoEditor,
        status: PublicationStatus.Published,
        photoFromOutPhotobank: checkPhotoEditor ? undefined : photoFromOutPhotobank === true ? true : undefined
      }

      if (photoFromOutPhotobank) {
        filters['filters'] = photoFromOutPhotobank
      }

      void refetch(filters)
    }
  }, [refetch, searchDate, checkPhotoEditor, photoFromOutPhotobank, refetchDateTime])

  const fetchMoreData = useCallback(() => {
    const filters = {
      first: 10,
      datePublished: searchDate,
      checkPhotoEditor: checkPhotoEditor,
      status: PublicationStatus.Published,
      after: data?.publications?.pageInfo.endCursor,
      sort: PublicationsSort.PublishedAtDesc
    }

    if (!checkPhotoEditor && photoFromOutPhotobank) {
      filters['filters'] = photoFromOutPhotobank
    }

    void fetchMore({
      variables: filters
    })
  }, [data?.publications?.pageInfo.endCursor, fetchMore, searchDate, checkPhotoEditor, photoFromOutPhotobank])

  const publicationsGroup = _.groupBy(
    data?.publications?.edges.map(item => {
      const res = JSON.parse(JSON.stringify(item))

      if (res?.node?.createdAt) {
        res.node.createdAtDate = format(parseISO(res?.node.createdAt), 'yyyy-MM-dd')
      }

      return res
    }),
    edge => (edge?.node?.publishedAtDate !== null ? edge?.node?.publishedAtDate : edge?.node?.createdAtDate)
  )

  const ref = useRef<any>(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  const onScroll = useCallback(val => {
    if (val) {
      setScrollPosition(val.scrollTop)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('refetchBildCheckListEvent', () => refetch())

    return () => {
      window.removeEventListener('refetchBildCheckListEvent', () => refetch())
    }
  }, [refetch])

  return (
    <>
      <StyledContainer>
        <KeyboardDatePickerWrapper
          disableToolbar
          variant='inline'
          format='yyyy-MM-dd'
          id='date-picker-inline'
          label={BILD_CHECK_LIST_TAB_PANEL_CONTENT.searchByDateMsg}
          value={searchDate}
          onChange={date => setSearchDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          slotProps={{
            openPickerButton: {color: 'primary'},
            field: {clearable: true}
          }}
        />
        {!checkPhotoEditor && (
          <FormControlLabelWrapper
            control={
              <Checkbox
                checked={photoFromOutPhotobank}
                onChange={() => setPhotoFromOutPhotobank(prevState => !prevState)}
              />
            }
            label={BILD_CHECK_LIST_TAB_PANEL_CONTENT.photoFromOutPhotobankMsg}
          />
        )}
      </StyledContainer>

      {networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.refetch ? (
        <LinearProgress color='primary' />
      ) : (
        <>
          {!isEmptyList ? (
            <InfiniteScroll
              dataLength={data?.publications?.edges.length || 0}
              next={fetchMoreData}
              hasMore={!!data?.publications?.pageInfo.hasNextPage}
              loader={<LinearProgress color='primary' />}
              height='76vh'
              style={{overflowX: 'hidden', overflowY: 'auto', scrollBehavior: 'smooth'}}
              ref={ref}
              onScroll={e => onScroll(e.target)}
            >
              <BildCheckListTable status={status || 'UNCHECKED'} publicationsGroup={publicationsGroup} />
              <ScrollToTopButton refContainer={ref} pos={scrollPosition} />
            </InfiniteScroll>
          ) : (
            <Box marginTop='15px'>
              <Typography variant='h2'>{BILD_CHECK_LIST_TAB_PANEL_CONTENT.publicationsListIsEmptyMsg}</Typography>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default BildCheckListTabPanelContent
