import React, {FC, useCallback, useEffect, useState} from 'react'
import Editor from '../../Common/Editor/Editor'
import Header from '../../Common/Header/Header'
import {useHistory} from 'react-router-dom'
import {
  useAddPublicationsToTagMutation,
  usePublicationsByTagUidQuery,
  useRemovePublicationsFromTagMutation,
  useUpdateTagMutation
} from './gql/TagEditor.generated'
import {FormProvider, useForm} from 'react-hook-form'
import RelatedPublications from '@components/UI/RelatedPublications/RelatedPublications'
import {Figure, Publication, Tag} from '@graphql/types'
import {useSnackbar} from 'notistack'
import {Box, Divider, LinearProgress, Typography} from '@mui/material'
import RHFSearchPublication from '../../UI/RHFSearchPublication/RHFSearchPublication'
import InfiniteScroll from 'react-infinite-scroll-component'
import TagsSelect from './Selectors/TagsSelect/TagsSelect'
import TagsPriority from './Selectors/TagProiority/TagPriority'
import styled from 'styled-components'

const TagSettingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin: auto;
`

type TagFormProps = {
  tag: Tag
}

type TagEditorFormProps = {
  title: string
  description: string
  isActive: boolean
  publications: Publication[]
  figure: Figure
  relatedTags: any
  tagPriority: string
  onRemove: (publicationUid: string) => void
  onAdded: (publication: Publication) => void
}

const TagForm: FC<TagFormProps> = ({tag}) => {
  const [publicationsServer, setPublicationsServer] = useState<Publication[]>([])
  const [publicationsAdded, setPublicationsAdded] = useState<Publication[]>([])
  const [publicationsDeleted, setPublicationsDeleted] = useState<string[]>([])
  const history = useHistory()
  const [updateTagMutation] = useUpdateTagMutation()
  const [addPublication] = useAddPublicationsToTagMutation()
  const [removePublication] = useRemovePublicationsFromTagMutation()
  const snackbar = useSnackbar()

  const onAdded = useCallback(
    (publication: Publication) => {
      if ([...publicationsServer, ...publicationsAdded].map(v => v?.uid).includes(publication.uid)) {
        snackbar.enqueueSnackbar('Уже добавлена', {variant: 'error'})
      } else {
        setPublicationsAdded(prevState => [publication, ...prevState])
      }
      setPublicationsDeleted(prevState => prevState.filter(uid => uid !== publication.uid))
    },
    [publicationsAdded, publicationsServer, snackbar]
  )

  const onRemove = useCallback(
    (publicationUid: string) => {
      if (publicationsDeleted.includes(publicationUid)) {
        return
      }
      if (publicationsServer.map(publication => publication.uid).includes(publicationUid)) {
        setPublicationsDeleted(prevState => [publicationUid, ...prevState])
      }
      setPublicationsAdded(prevState => prevState.filter(publication => publication.uid !== publicationUid))
      setPublicationsServer(prevState => prevState.filter(publication => publication.uid !== publicationUid))
    },
    [publicationsDeleted, publicationsServer]
  )

  const defaultValues: TagEditorFormProps = {
    title: tag.title || '',
    description: tag.description,
    isActive: tag.isActive,
    publications: publicationsServer,
    figure: tag.figure as Figure,
    relatedTags: tag.relatedTags.map(tag => {
      return tag.uid
    }),
    tagPriority: tag.priority + '',
    onRemove: onRemove,
    onAdded: onAdded
  }
  const {control, setValue, handleSubmit, formState, ...methodsForm} = useForm<any>({
    defaultValues: defaultValues
  })

  useEffect(() => {
    setValue('publications', [...publicationsAdded, ...publicationsServer])
    setValue('onAdded', onAdded)
    setValue('onRemove', onRemove)
  }, [onAdded, onRemove, publicationsAdded, publicationsServer, setValue])

  const {data, fetchMore, refetch} = usePublicationsByTagUidQuery({
    variables: {tagUid: tag.uid},
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setPublicationsServer(data?.publications?.edges.map(edge => edge?.node as Publication) as Publication[])
      setValue('publications', data?.publications?.edges.map(edge => edge?.node as Publication) || [])
    }
  })

  const onBack = useCallback(() => {
    history.push(`/tags`)
  }, [history])

  const onSubmitTag = useCallback(
    async data => {
      try {
        await updateTagMutation({
          variables: {
            data: {
              description: data.description,
              figureUid: data.figure?.uid || null,
              tagUid: tag.uid,
              title: data.title,
              relatedTagUids: data.relatedTags,
              priority: +data.tagPriority
            }
          }
        })
        if (publicationsDeleted.length) {
          await removePublication({
            variables: {
              data: {
                tagUid: tag.uid,
                publicationUids: publicationsDeleted
              }
            }
          })
          setPublicationsDeleted([])
        }
        if (publicationsAdded.length) {
          await addPublication({
            variables: {
              data: {
                tagUid: tag.uid,
                publicationUids: publicationsAdded.map(publication => publication.uid)
              }
            }
          })
          setPublicationsAdded([])
        }

        setTimeout(
          () =>
            refetch({
              tagUid: tag.uid,
              limit: 16
            }),
          1000
        )
        snackbar.enqueueSnackbar('Сохранено', {variant: 'success'})
      } catch (e) {
        snackbar.enqueueSnackbar(`Ошибка ${e}`, {variant: 'error'})
      }
      onBack()
    },
    [
      onBack,
      addPublication,
      publicationsAdded,
      publicationsDeleted,
      refetch,
      removePublication,
      snackbar,
      tag.uid,
      updateTagMutation
    ]
  )

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        first: 10,
        after: data?.publications?.pageInfo.endCursor
      }
    })
  }, [data?.publications?.pageInfo.endCursor, fetchMore])

  return (
    <FormProvider
      {...{
        control,
        setValue,
        handleSubmit,
        formState,
        ...methodsForm
      }}
    >
      <form noValidate onSubmit={handleSubmit(onSubmitTag)}>
        <Header title='Редактирование тэга' onBack={onBack} hasPreview={false} />
        <Editor figureTypeSlug='tag' />
        <TagSettingsWrapper>
          <TagsPriority />
          <TagsSelect />
        </TagSettingsWrapper>
        <Box width='100%'>
          <RHFSearchPublication />
          <Box marginTop='30px'>
            <Divider />
            {tag.publications?.edges.length || [...publicationsAdded, ...publicationsServer].length ? (
              <InfiniteScroll
                dataLength={data?.publications?.edges.length || 0}
                next={fetchMoreData}
                hasMore={!!data?.publications?.pageInfo.hasNextPage}
                loader={<LinearProgress color='primary' />}
                height='64vh'
              >
                <RelatedPublications />
              </InfiniteScroll>
            ) : (
              <Box marginTop='15px'>
                <Typography variant='h2'>Публикации отсутствуют</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </FormProvider>
  )
}

export default TagForm
