import styled from 'styled-components'
import {Button} from '@mui/material'

export const TableHeaderCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 4px;
`

export const TableHeaderCellButtonsWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonWrapper = styled(Button)`
  border-radius: 50%;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  padding: 0;
`
