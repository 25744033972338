import {
  Packer,
  Document,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  HeadingLevel,
  OverlapType,
  RelativeHorizontalPosition,
  RelativeVerticalPosition,
  TableAnchorType,
  ExternalHyperlink,
  PageOrientation
} from 'docx'
import saveAs from 'file-saver'
import {lightGrayColor2} from '../../../../@theme/vars'
import {ReportsListTableDataProps} from '../ReportsListTableDataProps'
import {REPORTS_LIST_TABLE_CONSTS} from '../ReportsListTable.consts'

/**
 * Функция генерации word-файла
 * @param data данные
 * @param fieldsNames названия полей
 * @param fileName итоговое название файла
 */
export const generateWordFile = (data: ReportsListTableDataProps[], fieldsNames: string[], fileName: string): void => {
  const tableHeaderRowCells: TableCell[] = []

  fieldsNames.forEach(fieldName => {
    tableHeaderRowCells.push(
      new TableCell({
        children: [
          new Paragraph({
            text: REPORTS_LIST_TABLE_CONSTS[fieldName],
            heading: HeadingLevel.HEADING_4
          })
        ],
        verticalAlign: VerticalAlign.CENTER,
        borders: {
          bottom: {
            style: 'single',
            color: lightGrayColor2,
            size: 1
          },
          right: {
            style: 'single',
            color: lightGrayColor2,
            size: 1
          }
        }
      })
    )
  })

  const tableHeaderRow = new TableRow({
    children: tableHeaderRowCells
  })

  const tableRows: TableRow[] = []

  tableRows.push(tableHeaderRow)

  data.forEach((item: ReportsListTableDataProps) => {
    const tableBodyRow: TableRow = new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: item.date,
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: item.time,
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new ExternalHyperlink({
                  children: [new TextRun(item.title)],
                  link: item.canonicalUrl
                })
              ],
              wordWrap: true,
              heading: HeadingLevel.HEADING_3
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: item.seoTitle,
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: item.rubricTitle + `${item.subrubricTitle ? `, ${item.subrubricTitle}` : ''}`,
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: item.authors.join('\n'),
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: (item.viewsCount || 0).toString(),
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: (item.uniqueUsersCount || 0).toString(),
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: (item.socialVisits || 0).toString(),
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: (item.percentSocial || 0).toString(),
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: (item.withoutRecommendedSystems || 0).toString(),
              wordWrap: true,
              heading: HeadingLevel.HEADING_5
            })
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders: {
            bottom: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            },
            right: {
              style: 'single',
              color: lightGrayColor2,
              size: 1
            }
          }
        })
      ]
    })

    tableRows.push(tableBodyRow)
  })

  const table = new Table({
    rows: tableRows,
    columnWidths: [1000, 700, 2500, 2500, 2040, 1660, 820, 840, 800, 660, 960],
    float: {
      horizontalAnchor: TableAnchorType.MARGIN,
      verticalAnchor: TableAnchorType.MARGIN,
      relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
      relativeVerticalPosition: RelativeVerticalPosition.BOTTOM,
      overlap: OverlapType.NEVER,
      leftFromText: 100,
      rightFromText: 100,
      topFromText: 1500,
      bottomFromText: 30
    }
    // indent: {
    //   size: 0,
    //   type: WidthType.DXA
    // }
  })

  const doc = new Document({
    compatibility: {
      // layoutRawTableWidth: true,
      // doNotVerticallyAlignCellWithSp: false,
      // ignoreVerticalAlignmentInTextboxes: true,
      // suppressBottomSpacing: true,
      // suppressTopSpacing: true
      // suppressSpacingAtTopOfPage: true
      // suppressTopSpacingWP: true,
      // suppressSpBfAfterPgBrk: true
    },
    sections: [
      {
        properties: {
          page: {
            size: {
              orientation: PageOrientation.LANDSCAPE
            }
          }
        },
        children: [
          new Paragraph({
            children: [table]
          })
        ]
      }
    ],
    styles: {
      default: {
        heading3: {
          run: {
            size: 12,
            bold: false,
            italics: false,
            color: '1964e7'
          },
          paragraph: {
            spacing: {
              after: 40
            }
          }
        },
        heading4: {
          run: {
            size: 14,
            bold: true,
            italics: false,
            color: '222222'
          },
          paragraph: {
            spacing: {
              after: 40
            }
          }
        },
        heading5: {
          run: {
            size: 12,
            bold: false,
            italics: false,
            color: '222222'
          },
          paragraph: {
            spacing: {
              after: 40
            }
          }
        }
      }
    }
  })

  Packer.toBlob(doc).then(blob => {
    saveAs(blob, fileName)
  })
}
