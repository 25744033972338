import {SvgIconProps} from '@mui/material'
import React, {FC} from 'react'

const ForbiddenIcon: FC<SvgIconProps> = props => {
  const curWidth = props.width ?? 24
  const curHeight = props.height ?? 24
  return (
    <svg width={curWidth} height={curHeight} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Icons' clipPath='url(#clip0_8383_68484)'>
        <path
          id='Vector'
          d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 7.58 7.58 4 12 4C13.85 4 15.55 4.63 16.9 5.69L5.69 16.9C4.63 15.55 4 13.85 4 12ZM12 20C10.15 20 8.45 19.37 7.1 18.31L18.31 7.1C19.37 8.45 20 10.15 20 12C20 16.42 16.42 20 12 20Z'
          fill='#707070'
        />
      </g>
      <defs>
        <clipPath id='clip0_8383_68484'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ForbiddenIcon
