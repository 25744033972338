import React, {FC} from 'react'

const EditIcon: FC<any> = () => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle cx='12' cy='12' r='11.5' fill='white' stroke='#C4C4C4' />
            <g clipPath='url(#clip0_7215_80297)'>
                <path
                    d='M16.8538 15.1761L12.7251 11.0474C13.1334 10.0038 12.9066 8.77883 12.0445 7.9168C11.1371 7.00939 9.77601 6.82791 8.68712 7.32698L10.638 9.27791L9.27693 10.639L7.28064 8.68809C6.73619 9.77698 6.96304 11.1381 7.87045 12.0455C8.73249 12.9075 9.95749 13.1344 11.001 12.7261L15.1297 16.8548C15.3112 17.0362 15.5834 17.0362 15.7649 16.8548L16.8084 15.8112C17.0353 15.6298 17.0353 15.3122 16.8538 15.1761Z'
                    fill='#1964E7'
                />
            </g>
            <defs>
                <clipPath id='clip0_7215_80297'>
                    <rect
                        width='10.8889'
                        height='10.8889'
                        fill='white'
                        transform='translate(6.55469 6.55469)'
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EditIcon
