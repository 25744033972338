export const PUBLICATION_DATE_CONSTS = {
  closeMsg: 'Закрыть',
  currentTimeMsg: 'Текущее время определится автоматически',
  dateTimeMsg: 'Дата и время',
  deferredPublicationMsg: 'Отложенная публикация',
  hourForms: ['час', 'часа', 'часов'],
  nowMsg: 'Сейчас',
  publishAtNowMsg: 'Публикация выйдет, когда вы нажмёте на кнопку Опубликовать',
  selectMsg: 'Выбрать'
}
