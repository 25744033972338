import React, {FC, useCallback, useEffect, useState} from 'react'
import {Box, FormControlLabel, Radio, Switch} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useKeycloak} from '@react-keycloak/web'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {Figure} from '@graphql/types'
import {
  useAddToOverviewMutation,
  useAddToTopNewsMutation
} from '@components/Publication/PublicationList/gql/PublicationList.generated'
import {useStore} from '@stores/rootStoreContext'
import ImageCropper from '../ImageEditor/ImageCropper/ImageCropper'
import {useUpdateFigureMutation} from '../../Publication/Form/gql/PublicationEditor.generated'
import RegionPicker from '../RegionPicker/RegionPicker'
import PositionPicker from '../PositionPicker/PositionPicker'
import InputWithLength from '../InputWithLength/InputWithLength'
import {CropperType} from '../../../types/CropperType'
import {ModalAddPublicationToMainPageProps} from './ModalAddPublicationToMainPageProps'
import {MODAL_ADD_PUBLICATION_TO_MAIN_PAGE_CONSTS} from './ModalAddPublicationToMainPage.consts'
import {StyledRadioGroup} from './ModalAddPublicationToMainPage.styles'

const ModalAddPublicationToMainPage: FC<ModalAddPublicationToMainPageProps> = ({
  defaultFigure,
  open,
  onClose,
  title,
  text,
  okLabel,
  ratio = 16 / 9,
  figureTypeSlug,
  publicationTitle,
  publicationId
}) => {
  const [imageUrl, setImageUrl] = useState(defaultFigure?.src)
  const [figure, setFigure] = useState<Figure>(defaultFigure as Figure)
  const {cropperStore} = useStore()
  const {cropper, setCropper} = cropperStore
  const [radioValue, setRadioValue] = useState('top-news')
  const [region, setRegion] = useState(null)
  const [position, setPosition] = useState(null)
  const [description, setDescription] = useState(publicationTitle)
  const [subtitle, setSubtitle] = useState('')
  const [isExclusive, setIsExclusive] = useState(false)
  const [updateFigure] = useUpdateFigureMutation()
  const [addToTopNews] = useAddToTopNewsMutation()
  const [addToOverview] = useAddToOverviewMutation()
  const snackbar = useSnackbar()

  useEffect(() => {
    if (open) {
      setFigure(defaultFigure as Figure)
      setImageUrl(defaultFigure?.src)
      setRegion(null)
      setDescription(publicationTitle)
      setPosition(null)
    }
  }, [defaultFigure, publicationTitle, open])

  const handleChangeRadioValue = useCallback(event => {
    setRadioValue(event.target.value)
  }, [])

  const handleChangeRegion = useCallback(region => {
    setRegion(region?.uid || null)
  }, [])

  const handleChangePosition = useCallback(position => {
    setPosition(position ? position?.value || 0 : null)
  }, [])

  const {keycloak} = useKeycloak()

  const updateFigureAction = useCallback(async () => {
    try {
      const newFigure = await updateFigure({
        variables: {
          data: {
            crop: cropper,
            description: description,
            figureUid: figure?.uid,
            author: figure?.author || (keycloak?.tokenParsed && keycloak?.tokenParsed['name'])
          }
        }
      })
      if (radioValue === 'top-news') {
        await addToTopNews({
          variables: {
            data: {
              publicationId: publicationId,
              regionId: region,
              position: position || 1,
              figureVersionId: newFigure.data?.updateFigure?.figure?.uid,
              title: description,
              isExclusive: isExclusive
            }
          }
        })
      } else {
        await addToOverview({
          variables: {
            data: {
              publicationId: publicationId,
              regionId: region,
              position: position || 1,
              figureVersionId: newFigure.data?.updateFigure?.figure?.uid,
              title: description,
              subtitle: subtitle
            }
          }
        })
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }, [
    updateFigure,
    cropper,
    description,
    figure,
    radioValue,
    onClose,
    addToTopNews,
    publicationId,
    region,
    position,
    isExclusive,
    addToOverview,
    subtitle,
    snackbar
  ])

  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={onClose}
      title={title}
      text={text}
      onOk={updateFigureAction}
      okButtonDisabled={!region || position === null}
      okLabel={okLabel}
    >
      <>
        <StyledRadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          value={radioValue}
          onChange={handleChangeRadioValue}
          name='radio-buttons-group'
        >
          <FormControlLabel
            value='top-news'
            control={<Radio color='secondary' />}
            label={MODAL_ADD_PUBLICATION_TO_MAIN_PAGE_CONSTS.mainForTheDay}
          />
          <FormControlLabel
            value='overview'
            control={<Radio color='secondary' />}
            label={MODAL_ADD_PUBLICATION_TO_MAIN_PAGE_CONSTS.pictureOfTheDay}
          />
        </StyledRadioGroup>
        <Box display='flex' justifyContent='space-between' marginBottom='28px'>
          <RegionPicker handleChangeRegion={handleChangeRegion} />
          <PositionPicker handleChangePosition={handleChangePosition} />
        </Box>
        <ImageCropper
          hasDiagonalWatermark={figure?.hasDiagonalWatermark || false}
          hasWatermark={figure?.hasWatermark || false}
          figure={figure}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          setFigure={setFigure}
          ratio={ratio}
          figureTypeSlug={figureTypeSlug}
          cropperType={CropperType.isModalAddPublicationToMainPage}
        />
        <Box marginTop='20px'>
          <InputWithLength
            label={MODAL_ADD_PUBLICATION_TO_MAIN_PAGE_CONSTS.title}
            title={description}
            errorTitle={''}
            maxLength={radioValue === 'top-news' ? 54 : 70}
            clearTitle={() => {
              setDescription('')
            }}
            setTitle={setDescription}
            width='500px'
          />
        </Box>

        {radioValue === 'top-news' && (
          <Box display='flex' justifyContent='space-around' marginTop='10px'>
            <FormControlLabel
              label={MODAL_ADD_PUBLICATION_TO_MAIN_PAGE_CONSTS.exclusive}
              control={
                <Switch
                  checked={isExclusive}
                  value={isExclusive}
                  onChange={() => setIsExclusive(prevState => !prevState)}
                />
              }
            />
          </Box>
        )}
        {radioValue === 'overview' && (
          <Box marginTop='20px'>
            <InputWithLength
              label={MODAL_ADD_PUBLICATION_TO_MAIN_PAGE_CONSTS.subtitle}
              title={subtitle}
              errorTitle={''}
              maxLength={90}
              clearTitle={() => {
                setSubtitle('')
              }}
              setTitle={setSubtitle}
              width='500px'
            />
          </Box>
        )}
      </>
    </SimpleModal>
  )
}

export default ModalAddPublicationToMainPage
