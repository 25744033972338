import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useFormContext} from 'react-hook-form'
import {useStore} from '@stores/rootStoreContext'
import CustomAutocomplete from '@components/UI/CustomAutocomplete/CustomAutocomplete'
import useDeviceDetect from '@utils/useDeviceDetect'
import {useStoriesPublicationFormQuery} from '../../gql/PublicationEditor.generated'

const StorySelect: FC = () => {
  const {publicationStore} = useStore()
  const {publication} = publicationStore
  const {setValue, getValues} = useFormContext()

  const {data, fetchMore, refetch, loading} = useStoriesPublicationFormQuery()
  const {isMobile} = useDeviceDetect()

  const pageInfo = useMemo(() => data?.stories?.pageInfo, [data?.stories?.pageInfo])

  const [fieldWidth, setFieldWidth] = useState('620px')

  useEffect(() => {
    setFieldWidth(isMobile ? '100%' : '620px')
  }, [isMobile])

  const onRefetch = useCallback(
    (search: string | null) => {
      void refetch({search: search})
    },
    [refetch]
  )

  const onLoadMore = useCallback(() => {
    if (pageInfo?.hasNextPage && fetchMore) {
      void fetchMore({
        variables: {
          first: 15,
          after: pageInfo?.endCursor,
          search: null
        }
      })
    }
  }, [fetchMore, pageInfo])

  const handleSetValueForm = useCallback(
    (newStory: any) => {
      setValue('storyUid', newStory?.uid || null)
    },
    [setValue]
  )

  return (
    <CustomAutocomplete
      label='Сюжет'
      options={data?.stories?.edges.map(edge => edge?.node) || []}
      value={publication.story}
      refetch={onRefetch}
      fetchMore={onLoadMore}
      loading={loading}
      onChange={handleSetValueForm}
      width={fieldWidth}
      disabled={getValues('cantEditPublication')}
    />
  )
}

export default observer(StorySelect)
