import {Typography} from '@mui/material'
import styled from 'styled-components'

export const HeaderWithBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`

export const Title = styled(Typography)`
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #222222;
`
