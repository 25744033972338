import {useCallback, useEffect} from 'react'
import {CircularProgress, Typography} from '@mui/material'
import {Button} from '@mui/material'
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material'
import {Quill} from 'react-quill'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {redColor, warnColor} from '@theme/vars'
import {useStore} from '@stores/rootStoreContext'
import NnIcon from '@components/UI/Icons/NnIcon'
import PopoverInfoErrorButton from '@components/UI/PopoverInfoErrorButton/PopoverInfoErrorButton'
import {getQuillEditor} from '@utils/getQuillEditor'
import {ETitlesField} from '../PublicationFormTitles.types'
import {PUBLICATION_FORM_TITLES_CONSTS, TitleFieldEntries} from '../PublicationFormTitles.consts'
import {FormHelperErrorText, TitleLength} from './PublicationFormTitle.styles'
import type {FC} from 'react'
import type {ControllerRenderProps, UseFormReturn} from 'react-hook-form'
import type {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'

type PublicationFormTitleProps = {
  methodsForm: UseFormReturn<PublicationInputType>
  disabled: boolean
  field: ControllerRenderProps<PublicationInputType, ETitlesField>
  fullWidth?: boolean
  onChange: (value: string, fieldName: ETitlesField) => void
  newUpdateTitle?: () => void
  customInformationError?: string
  fillingRules?: React.ReactNode
}
/**
 * PublicationFormTitle is a functional component that renders a form control for publication titles.
 * It includes an input field, error handling, and a button to update the title.
 *
 * @param {PublicationFormTitleProps} props - The properties passed to the component.
 * @param {UseFormReturn<PublicationInputType>} props.methodsForm - The form methods from react-hook-form.
 * @param {boolean} props.disabled - A flag to disable the input field.
 * @param {ControllerRenderProps<PublicationInputType, ETitlesField>} props.field - The field properties from react-hook-form.
 * @param {boolean} [props.fullWidth=false] - A flag to set the input field to full width.
 * @param {(value: string, fieldName: ETitlesField) => void} props.onChange - The function to call when the input value changes.
 * @param {() => void} [props.newUpdateTitle] - An optional function to call for updating the title.
 * @param {string} [props.customInformationError] - An optional custom error message.
 * @param {React.ReactNode} [props.fillingRules] - Optional filling rules to display in a popover.
 *
 * @returns {JSX.Element} The rendered form control component.
 */
const PublicationFormTitle: FC<PublicationFormTitleProps> = ({
  methodsForm,
  disabled = false,
  field,
  fullWidth = false,
  onChange,
  newUpdateTitle,
  customInformationError,
  fillingRules
}) => {
  const {publicationStore, editorStore} = useStore()
  const {publication} = publicationStore
  const {editorRef} = editorStore
  const {getFieldState} = methodsForm

  const fieldState = getFieldState(field.name)
  const hasErrors = Boolean(fieldState?.invalid)

  const baseURL = process.env.REACT_APP_NN

  const errorText = fieldState?.error?.message
  const isSubtitle = field.name === ETitlesField.Subtitle

  const isArticlesTitle = publication.typeSlug === 'articles' && field.name === ETitlesField.Title
  const maxErrorLength = isArticlesTitle
    ? PUBLICATION_FORM_TITLES_CONSTS.articleTitleMaxLength
    : TitleFieldEntries[field.name].maxErrorLength

  const maxWarningLength = isArticlesTitle ? 0 : TitleFieldEntries[field.name].maxWarningLength || 0

  useEffect(() => {
    onChange(field.value, field.name)
  }, [field.name, field.value, onChange])

  const handleChange = useCallback(
    event => {
      const {value} = event.target
      onChange(value, field.name)
    },
    [field.name, onChange]
  )

  const snackbar = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const updateTitle = useCallback(() => {
    const publication_id = publicationStore.publication?.deprecatedId
    const publication_uid = publicationStore.publication?.id

    const quill = getQuillEditor(editorRef)

    if (quill) {
      const {ops} = quill.getContents()
      let text = ''
      ops?.forEach(op => {
        text += ' ' + op.insert
      })

      const data = JSON.stringify({publication_id, publication_uid, text})
      const params =
        field.name === ETitlesField.Title ? 'params' : field.name === ETitlesField.SeoTitle ? 'seo' : 'dzen'

      if (text.length <= 2) {
        snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
        setIsLoading(false)
      } else {
        setIsLoading(true)
        fetch(`${baseURL}title/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            [params]: data
          })
        })
          .then(res => {
            if (!res.ok) {
              throw new Error(res.statusText)
            }
            return res.text()
          })
          .then(response => {
            setIsLoading(false)
            field.onChange(response)
          })
          .catch(error => {
            setIsLoading(false)
            snackbar.enqueueSnackbar(`Ошибка при обновлении заголовка: ${error.message}`, {
              variant: 'error',
              autoHideDuration: 10000
            })
          })
      }
    }
  }, [editorRef, field, publicationStore.publication?.deprecatedId, publicationStore.publication?.id, snackbar])

  return (
    <FormControl
      fullWidth
      style={{
        marginTop: field.name === ETitlesField.Title ? '0' : '20px'
      }}
    >
      <div style={{flexDirection: 'row'}}>
        <InputLabel
          style={{
            width: `${fullWidth ? '90%' : 'calc(90% - 80px)'}`
          }}
        >
          <Typography variant='text12R'>{TitleFieldEntries[field.name].name}</Typography>
        </InputLabel>
        <OutlinedInput
          label={TitleFieldEntries[field.name].name}
          {...field}
          error={hasErrors || !!customInformationError}
          multiline
          style={{
            fontSize: `${isSubtitle ? '18px' : '16px'}`,
            fontStyle: `${isSubtitle ? 'italic' : 'inherit'}`,
            float: 'left',
            width: `${fullWidth ? '90%' : 'calc(90% - 80px)'}`,
            color: hasErrors ? redColor : 'inherit'
          }}
          disabled={disabled}
          onChange={handleChange}
        />
        <div>
          <TitleLength
            style={{
              display: `${fullWidth ? 'flex' : 'block'}`,
              float: 'right',
              color:
                field.value.length > maxErrorLength
                  ? redColor
                  : maxWarningLength > 0 && field.value.length > maxWarningLength
                  ? warnColor
                  : 'inherit'
            }}
          >
            {`${field.value.length || 0} / ${maxErrorLength}`}
          </TitleLength>
          {(hasErrors || !!customInformationError) && (
            <FormHelperErrorText>
              {errorText || customInformationError}
              {fillingRules && <PopoverInfoErrorButton>{fillingRules}</PopoverInfoErrorButton>}
            </FormHelperErrorText>
          )}
          {maxWarningLength > 0 && field.value.length > maxWarningLength && (
            <FormHelperText
              style={{
                float: 'left',
                color: warnColor
              }}
            >
              Слишком длинный заголовок
            </FormHelperText>
          )}
        </div>
        <Button
          color='secondary'
          variant='outlined'
          style={{
            padding: '0',
            marginLeft: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
            minWidth: '40px',
            minHeight: '40px'
          }}
          onClick={updateTitle}
        >
          {isLoading ? <CircularProgress disableShrink color='secondary' size='1rem' /> : <NnIcon />}
        </Button>
      </div>
    </FormControl>
  )
}

export default PublicationFormTitle
