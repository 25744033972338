import {UseFormReturn} from 'react-hook-form'
import {ChangeEvent} from 'react'
import {PublicationInputType} from '../PublicationForm/PublicationInputType'

export type IChangeEvent = ChangeEvent<HTMLInputElement> & {
  nativeEvent: InputEvent
}

export type IPublicationFormTitlesProps = {
  methodsForm: UseFormReturn<PublicationInputType>
  cantEditPublication: boolean
}

export enum ETitlesField {
  Title = 'title',
  Subtitle = 'subtitle',
  YandexTitle = 'yandexTitle',
  YandexDzenTitle = 'yandexDzenTitle',
  SeoTitle = 'seoTitle',
  OverviewTitle = 'overviewTitle'
}

export type ITitleFieldEntries = {
  name: string
  maxErrorLength: number
  maxWarningLength: number
  firstSentenceMaxLength?: number
  titleMaxWordsCount?: number
}
