import {Typography} from '@mui/material'
import styled from 'styled-components'
import {dividerColor, grayColor, mainTextColor, screenM, secondTextColor} from '../../../@theme/vars'

export const GridDiv = styled.div`
  min-height: 48px;
  font-size: 14px;
  line-height: 1.2;
  color: ${mainTextColor};
  margin-bottom: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${dividerColor};
  display: -ms-grid;
  display: grid;
  grid-template:
    'time title     title     settings authors'
    '.    shortInfo shortInfo settings authors';
  grid-template-columns: 0px 5fr 65px 3fr 3fr;
  grid-column-gap: 14px;
  align-items: center;
  @media only screen and (max-width: ${screenM}) {
    grid-row-gap: 14px;
    margin-left: 0;
  }
`

export const ShortInfo = styled.div`
  grid-area: shortInfo;
  text-align: left;
  display: flex;
  column-gap: 5px;
`

export const TimeDiv = styled.div`
  grid-area: time;
  font-size: 14px;
  color: ${grayColor};
  display: flex;
`

export const Title = styled.h3`
  grid-area: title;
  font-weight: 500;
  line-height: 1.2;
`

export const Settings = styled.div`
  grid-area: settings;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: ${screenM}) {
    text-align: left;
  }
`
export const Authors = styled.div`
  grid-area: authors;
  color: ${secondTextColor};
  font-size: 14px;
  line-height: 1.2;
`
export const AuthorsTitle = styled(Typography)`
  text-align: right;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`
