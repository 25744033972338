import {FetchResult} from '@apollo/client'
import {ModerateStatusCommentMutation} from '@components/Comments/gql/Comments.generated'

const moderateStatusCommentAdapterToClient = (response: FetchResult<ModerateStatusCommentMutation>): boolean => {
  if (!response.data?.moderateStatusComment) {
    return false
  }

  return response.data?.moderateStatusComment.status
}

export {moderateStatusCommentAdapterToClient}
