import {FC, useCallback} from 'react'
import {toast} from 'react-toastify'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {getQuillEditor} from '@utils/getQuillEditor'
import {fetchRephraseText, fetchRewriteText} from '@fetch'
import {addErrorsMessage} from '@utils/utilsNn/addErrorsMessage'
import {Button, MenuItem} from '../ToolbarNn.styles'
import {TOOLBAR_BUTTONS_TITLES, TOOLBAR_DIALOG_CONSTS} from '../ToolbarNn.consts'
import type {RangeStatic} from 'quill'

type Props = {
  editorRef: React.MutableRefObject<any>
  isRewriting: boolean
  handleClose: () => void
  authorId: string
}
/**
 * Кнопка для перезаписи или рерайта выбранного текста в редакторе.
 * В зависимости от состояния `isRewriting` отправляется запрос на сервер для рерайта или переписи выделенного текста.
 *
 * @param {React.MutableRefObject<any>} editorRef - Ссылка на редактор Quill, из которого берется текст для изменения.
 * @param {boolean} isRewriting - Определяет, следует ли переписать выделенный текст или сделать рерайт.
 * @param {Function} handleClose - Функция для закрытия диалогового окна.
 */

const RewriteTextButton: FC<Props> = ({editorRef, isRewriting, handleClose, authorId}) => {
  const {publicationStore, dialogStore} = useStore()

  const handleRewordText = useCallback(async () => {
    handleClose()

    const abortCtrl = new AbortController()
    dialogStore.setAbortController(abortCtrl)

    const quill = getQuillEditor(editorRef)
    let range: RangeStatic | undefined

    if (quill) {
      range = quill.getSelection(true)
    }

    if (quill && range) {
      const {publication} = publicationStore
      const publicationId = publication?.deprecatedId
      const publicationUid = publication?.id
      const {index, length} = range
      dialogStore.setSelectedText(index, length)
      const delta = quill.getContents(index, length)

      const selectingText = delta?.ops?.reduce((string, op) => string + op.insert, '')

      if (!selectingText) {
        toast.info(TOOLBAR_BUTTONS_TITLES.notSelectionText, {
          containerId: 'success',
          autoClose: 10000,
          hideProgressBar: true
        })
        return dialogStore.setIsLoadingNer(false)
      }

      dialogStore.setIsLoadingNer(true)

      try {
        dialogStore.openDialog()
        let rewriteTextJson
        if (isRewriting) {
          dialogStore.addMessage({
            isUser: true,
            isError: false,
            message: TOOLBAR_DIALOG_CONSTS.CONTENT.textForRewrite + '\n' + selectingText
          })
          const data = {params: {'1': selectingText}, publicationId, publicationUid, authorId}
          rewriteTextJson = await fetchRewriteText(data, abortCtrl.signal)
        } else {
          dialogStore.addMessage({
            isUser: true,
            isError: false,
            message: TOOLBAR_DIALOG_CONSTS.CONTENT.textForReword + '\n' + selectingText
          })
          const data = {params: {'1': selectingText}, publicationId, publicationUid, authorId}
          rewriteTextJson = await fetchRephraseText(data, abortCtrl.signal)
        }
        if (!rewriteTextJson) {
          console.error(TOOLBAR_BUTTONS_TITLES.fetchError)
          return
        }

        const regExp = /\n\s*\n+/g
        const text = JSON.parse(rewriteTextJson).text.replace(regExp, '\n')

        dialogStore.addMessage({isUser: false, isError: false, message: text})
      } catch (error) {
        addErrorsMessage(dialogStore, error)
      } finally {
        dialogStore.setIsLoadingNer(false)
        dialogStore.setAbortController(null)
      }
    }
  }, [editorRef, publicationStore, handleClose, isRewriting, dialogStore, authorId])

  return (
    <MenuItem>
      <Button onClick={handleRewordText}>
        {isRewriting ? TOOLBAR_BUTTONS_TITLES.rewriteText.rewrite : TOOLBAR_BUTTONS_TITLES.rewriteText.overwrite}
      </Button>
    </MenuItem>
  )
}

export default observer(RewriteTextButton)
