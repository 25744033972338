import React, {FC, useCallback, useEffect, useState} from 'react'
import {
  Box,
  LinearProgress,
  Typography,
  Button,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton
} from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import {NetworkStatus} from '@apollo/client'
import {
  useAddAutomoderatorDictionaryEntryMutation,
  useAutomoderatorDictionaryEntriesQuery,
  useRemoveAutomoderatorDictionaryEntryMutation
} from './gql/DictionariesTabContent.generated'
import {AutomoderatorDictionaryEntry} from '@graphql/types'
import DictionariesItem from '../DictionariesItem/DictionariesItem'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {StyledFormControl} from '@components/UI/styled'
import Clear from '@mui/icons-material/Clear'

type DictionariesTabContentProps = {
  status: any
}

const DictionariesTabContent: FC<DictionariesTabContentProps> = ({status}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [addingWord, setAddingWord] = useState('')

  const {data, refetch, fetchMore, networkStatus} = useAutomoderatorDictionaryEntriesQuery({
    variables: {
      filter: {
        dictionaryName: status
      }
    }
  })

  const [deleteWord] = useRemoveAutomoderatorDictionaryEntryMutation()
  const [addWord] = useAddAutomoderatorDictionaryEntryMutation()

  useEffect(() => {
    void refetch()
  }, [refetch, status])

  const fetchMoreWords = useCallback(async () => {
    await fetchMore({
      variables: {
        filter: null,
        sort: null,
        first: 20,
        after: data?.automoderatorDictionaryEntries?.pageInfo.endCursor
      }
    })
  }, [data?.automoderatorDictionaryEntries?.pageInfo.endCursor, fetchMore])

  const toggleModal = useCallback(() => {
    setAddingWord('')
    setIsOpenModal(!isOpenModal)
  }, [isOpenModal])

  const deleteWordAction = useCallback(
    async id => {
      try {
        await deleteWord({
          variables: {
            data: {
              id: id
            }
          }
        })
        refetch()
      } catch {}
    },
    [deleteWord, refetch]
  )

  const addWordAction = useCallback(async () => {
    try {
      await addWord({
        variables: {
          data: {
            dictionaryName: status,
            word: addingWord
          }
        }
      })
      toggleModal()
      refetch()
    } catch {}
  }, [addWord, addingWord, status, toggleModal, refetch])

  const word = 'Слово'
  const noWords = 'В словаре ещё нет слов'
  const add = 'Добавить'
  const addToDictionary = 'Добавить в ловарь'
  const create = 'Создать'

  if (networkStatus === NetworkStatus.loading) return <LinearProgress />

  return (
    <>
      <Box display='flex' justifyContent='flex-end' my={4}>
        <Button onClick={toggleModal} color='primary' variant='contained'>
          {add}
        </Button>
      </Box>
      {!data?.automoderatorDictionaryEntries?.edges?.length && <Typography variant='h4'>{noWords}</Typography>}
      <InfiniteScroll
        next={fetchMoreWords}
        hasMore={!!data?.automoderatorDictionaryEntries?.pageInfo.hasNextPage}
        loader={<LinearProgress />}
        dataLength={data?.automoderatorDictionaryEntries?.edges?.length || 0}
      >
        {data?.automoderatorDictionaryEntries?.edges?.map(edge => (
          <DictionariesItem
            key={edge?.node?.id}
            word={edge?.node as AutomoderatorDictionaryEntry}
            deleteWordAction={deleteWordAction}
          />
        ))}
      </InfiniteScroll>
      <SimpleModal
        open={isOpenModal}
        showCloseButton
        okLabel={create}
        title={addToDictionary}
        onClose={toggleModal}
        onOk={addWordAction}
        okButtonDisabled={!addingWord}
      >
        <StyledFormControl width='100%'>
          <InputLabel>{word}</InputLabel>
          <OutlinedInput
            label={word}
            onChange={e => setAddingWord(e.target.value)}
            value={addingWord}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton edge='end' size='small' onClick={() => setAddingWord('')}>
                  <Clear fontSize='small' />
                </IconButton>
              </InputAdornment>
            }
          />
        </StyledFormControl>
      </SimpleModal>
    </>
  )
}

export default DictionariesTabContent
