import styled from 'styled-components'
import {errorColor} from '../../../../@theme/vars'

export const PublicationHasBeenRemovedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${errorColor};
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 12px;
  /* align-self: flex-start; */
  margin-top: -30px;
  /* padding-left: 10px; */
  width: calc(100% - 20px);
  max-width: 1266px;
  /* position: absolute;
  top: 45px; */
`
