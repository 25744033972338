import styled from 'styled-components'
import {Tabs} from '@mui/material'

const TabsWithBorder = styled(Tabs)`
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  & .MuiTabScrollButton-root.Mui-disabled {
    width: 0;
  }
  & .MuiTab-root {
    min-width: 0;
    padding: 12px 20px;
  }
  & .MuiTab-wrapper {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    color: #222;
  }
`

export default TabsWithBorder
