import {handleRequest} from '../ky-client'
import {Twin, TwinRequest, TwinsResponse} from './SameNamesAPI.types'

export const fetchGetTwins = async (): Promise<TwinsResponse | null | undefined> => {
  return await handleRequest('twins/', {
    method: 'GET'
  })
}

export const fetchNextPage = async (nextPage: string): Promise<TwinsResponse | null | undefined> => {
  return await handleRequest(`twins/?page=${nextPage}`, {method: 'GET'})
}

export const fetchCreateTwin = async (data: TwinRequest): Promise<Twin | null | undefined> => {
  return await handleRequest('twins/create/', {
    method: 'POST',
    json: data
  })
}

export const fetchDeleteTwin = async (id: string): Promise<undefined | null> => {
  return await handleRequest(`twins/delete/${id}/`, {
    method: 'DELETE'
  })
}

export const fetchUpdateTwin = async (data: TwinRequest, id: string): Promise<Twin | null | undefined> => {
  return await handleRequest(`twins/update/${id}/`, {
    method: 'PUT',
    json: data
  })
}
