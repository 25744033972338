import React, {FC} from 'react'
import {Typography} from '@mui/material'
import SimpleModal from '../SimpleModal/SimpleModal'
import {CONFIRMATION_MODAL_CONSTS} from './ConfirmationModal.consts'
import {ConfirmationModalContentWrapper} from './ConfirmationModal.styles'

export const ConfirmationModal: FC<any> = ({
  id,
  confirmText,
  closeConfirmModal,
  isOpenConfirmDialog,
  okLabel,
  toggleIsOpenConfirmDialog,
  firstSentenceText = CONFIRMATION_MODAL_CONSTS.areYouSure
}) => {
  return (
    <SimpleModal
      id={id || 'modal'}
      open={isOpenConfirmDialog}
      mode='confirmation'
      variant='confirmation'
      title={CONFIRMATION_MODAL_CONSTS.warning}
      okLabel={okLabel}
      onOk={closeConfirmModal}
      onYes={closeConfirmModal}
      onClose={toggleIsOpenConfirmDialog}
      onAbort={toggleIsOpenConfirmDialog}
      onNo={toggleIsOpenConfirmDialog}
    >
      <ConfirmationModalContentWrapper>
        <Typography variant='text16R'>{firstSentenceText}</Typography>
        <Typography variant='text16M'>{confirmText}</Typography>
      </ConfirmationModalContentWrapper>
    </SimpleModal>
  )
}
