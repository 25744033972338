import {ValidationData, ValidationStatus} from '@utils/models/errorStatus'

/**
 * Функция проверки строки на запрещенные символы
 * @param text Текст
 * @returns
 */
export const validateStringBySymbols = (text: string): ValidationData => {
  const validationData: ValidationData = {
    status: ValidationStatus.Default,
    message: ''
  }

  if (text?.length) {
    const invalidCharacters = [...text]
      .filter(character => !/[A-ZА-Я0-9 «»%@№!&,;:—\-\+\*\(\)\/\|\?\.\+\$]/i.test(character))
      .join(', ')

    if (invalidCharacters.length > 0) {
      validationData.message = `Присутствуют запрещенные символы: ${invalidCharacters}`
      validationData.status = ValidationStatus.Error
    }
  }

  return validationData
}
