import {List, ListItem, ListItemText} from '@mui/material'
import styled from 'styled-components'

export const UIList = styled(List)``

export const UIListItem = styled(ListItem)``

export const ListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const UIListItemText = styled(ListItemText)``

export const UIListSecondText = styled(ListItemText)`
  font-size: 12px;
`
