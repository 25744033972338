import {Box, Divider} from '@mui/material'
import React, {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {YandexVideoItem} from '../YandexVideoItem/YandexVideoItem'

type YandexVideosListProps = {
  deleteYandexVideo: (uid: string) => void
  editYandexVideo: (uid: string) => void
}

export const YandexVideosList: FC<YandexVideosListProps> = observer(({deleteYandexVideo, editYandexVideo}) => {
  const {publicationStore} = useStore()
  const {publication} = publicationStore
  const [yandexVideosList, setYandexVideosList] = useState(publication?.yandexNewsVideos || [])

  useEffect(() => {
    setYandexVideosList(publication?.yandexNewsVideos || [])
  }, [publication?.yandexNewsVideos])
  return (
    <Box
      flexDirection={'column'}
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '4px'
      }}
    >
      {yandexVideosList?.length
        ? yandexVideosList.map((yandexVideosListItem, ind) => (
            <Box key={yandexVideosListItem.uid}>
              {ind > 0 && <Divider />}

              <YandexVideoItem
                uid={yandexVideosListItem?.uid}
                title={yandexVideosListItem?.title}
                thumbnail={yandexVideosListItem?.thumbnail}
                deleteYandexVideo={deleteYandexVideo}
                editYandexVideo={editYandexVideo}
              />
            </Box>
          ))
        : undefined}
    </Box>
  )
})
