import {styled} from 'styled-components'
import {Button} from '@mui/material'
import {grayColor, grey20, greyBG, lightblueClr, mainTextColor, screenS} from '@theme/vars'

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: ${screenS}) {
    flex-direction: column;
  }
`

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`

export const ButtonWrapper = styled(Button)`
  width: 202px;
  height: 40px;

  background-color: ${greyBG};
  border: 1px solid ${grey20};
  border-radius: 4px;
  color: ${grayColor};
  margin-top: 8px;

  &:hover {
    background-color: ${lightblueClr};
    color: ${mainTextColor};
  }
`
