export const uponSanitizeElementHook = (node, data) => {
  if (node?.innerHTML?.indexOf('<b style="font-weight:normal;"') >= 0) {
    node.innerHTML = node.innerHTML.replaceAll(
      /(\<b\sstyle\=\"font\-weight\:normal\;\")(.*)(\<\/b\>)/g,
      '<span$2</span>'
    )
  }

  if (node?.innerHTML?.length) {
    node.innerHTML = node.innerHTML.replaceAll(/&nbsp;/g, ' ')
    node.innerHTML = node.innerHTML.replaceAll(/[\u200B-\u200D\uFEFF]/g, '')
    node.innerHTML = node.innerHTML.replaceAll(/[\s]-[\s]/g, ' — ')
  }

  if (node?.nodeName === '#text') {
    if (
      node.textContent === '\n' ||
      node.textContent === '\r\n' ||
      node.textContent === '\n\n' ||
      node.textContent === '\r\n\r\n'
    ) {
      node.remove()
    }
  }
}
