export const SEO_TITLE_EXAMPLE_CONSTS = {
  exampleMsg: 'Например',
  hideMsg: 'Скрыть',
  mainTitleMsg: 'Основной заголовок',
  moreMsg: 'Подробнее',
  seoExampleMsg: `В seo-заголовке основные ключевики
  ставятся вначале. Пример: “Развод
  Веры Брежневой и Константина Меладзе
  26 июля 2022: правду раскрыла бывшая
  супруга композитора Яна Сумм -
  KP.RU”. Когда не используется
  seo-заголовок и он совпадает с
  основным, к нему добавляют дату,
  название СМИ и иногда рубрику.
  Пример: “... | Новости экономики |
  Известия | 06.07.2022”. Делается это
  для уникальности заголовка,
  соответственно нужна будет проверка
  на совпадение с другими заголовками,
  когда seo-заголовок пишется вручную`
}
