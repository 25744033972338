import Quill from 'quill'
import {OnlineTranslationDividerBlot} from '@components/TextEditor/Blots'

/**
 * Функция проверяет инвертирована онлайн-трансляция или нет
 *
 */

const checkIsOnlineTranslationReversed = (quill: Quill) => {
  const delta = quill.getContents()
  const blots: string[] = []

  if (!delta.ops) {
    return false
  }

  for (const element of delta.ops) {
    if (blots.length > 2) {
      break
    }

    if (element.insert && element.insert[OnlineTranslationDividerBlot.blotName]) {
      blots.push(element.insert[OnlineTranslationDividerBlot.blotName].createAt)
    }
  }

  if (!blots[1]) {
    return false
  }

  return blots[0] > blots[1]
}

export {checkIsOnlineTranslationReversed}
