import {makeAutoObservable} from 'mobx'
import {OverviewBlockCache} from 'types/OverviewBlockCache'
import IRegionsWithOverviewBlocksStore from './regionsWithOverviewBlocksStore.types'

class RegionsWithOverviewBlocksStore implements IRegionsWithOverviewBlocksStore {
  overviewBlocksCache: OverviewBlockCache[] = []
  overviewBlocksLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  setOverviewBlocksCache = (value: OverviewBlockCache[]): void => {
    this.overviewBlocksCache = value
  }

  setOverviewBlocksLoading = (value: boolean): void => {
    this.overviewBlocksLoading = value
  }
}

export default RegionsWithOverviewBlocksStore
