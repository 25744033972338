import {Quill} from 'react-quill'

const Inline = Quill.import('blots/inline')

type KeyValuePair = {
    key: string
    value: string
}

type TextRssBlockProps = {
    value: string
    attributes: KeyValuePair[]
}

export default class TextRssBlock extends Inline {
    static create(props: TextRssBlockProps) {
        const node = super.create()
        node.setAttribute('class', 'yandex-rss-hidden')
        return node
    }
}
TextRssBlock.blotName = 'textrssblock'
TextRssBlock.tagName = 'textrss'
