import React, {FC, ReactNode, useState, MouseEvent} from 'react'
import {Button, Menu, MenuItem} from '@mui/material'
import {useHistory} from 'react-router-dom'
import {NewPublicationItemType} from '../menu-items'
import styled from 'styled-components'

import {BLUE_COLOR} from '../../../../@theme/vars'
import {useCreatePublicationDraftMutation, useCreateStoryMutation} from './gql/NewPublication.generated'
import {useSnackbar} from 'notistack'
import {Add, ArrowDropDown} from '@mui/icons-material'

type NewPublicationType = {
  children?: ReactNode
  item: NewPublicationItemType
}

const GroupButton = styled.div`
  display: flex;
  align-items: center;
`

const PublicationButton = styled(Button)`
  height: 39px;
  color: ${BLUE_COLOR};
  border: 2px solid ${BLUE_COLOR};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 140px;
  min-width: 100px;
`

const MenuButton = styled(Button)`
  color: ${BLUE_COLOR};
  width: 36px;
  min-width: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  border: 2px solid ${BLUE_COLOR};
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`

const NewPublication: FC<NewPublicationType> = ({item}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [createPublicationDraft, {loading}] = useCreatePublicationDraftMutation()
  const [createStory] = useCreateStoryMutation()

  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCreateDraft = async (url: string, slug: string): Promise<void> => {
    if (slug === 'story') {
      try {
        const storyDraft = await createStory({
          variables: {
            data: {
              description: '',
              figureUid: null,
              title: ''
            }
          }
        })
        history.push(`${url}/${storyDraft.data?.createStory?.story?.uid}`)
      } catch (e) {
        snackbar.enqueueSnackbar(JSON.stringify(e))
      }
    } else {
      try {
        const publicationDraft = await createPublicationDraft({
          variables: {data: {typeSlug: slug}}
        })
        history.push(`${url}/${publicationDraft.data?.createPublicationDraft?.publication?.uid}`)
      } catch (e) {
        snackbar.enqueueSnackbar(JSON.stringify(e))
      }
    }
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <GroupButton>
      <PublicationButton
        disabled={loading}
        onClick={() => handleCreateDraft(item.mainButton.route, item.mainButton.slug)}
      >
        <Add />
        {item.mainButton.title}
      </PublicationButton>
      <MenuButton onClick={handleClick}>
        <ArrowDropDown />
      </MenuButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {item.secondButton.map(button => (
          <MenuItem
            disabled={loading || button.disabled}
            onClick={() => handleCreateDraft(button.route, button.slug)}
            key={button.title}
          >
            {button.title}
          </MenuItem>
        ))}
      </Menu>
    </GroupButton>
  )
}

export default NewPublication
