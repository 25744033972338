import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'

export const YandexNewsFilledIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <rect x='1' y='1' width='18' height='18' rx='1' stroke='#1964E7' strokeWidth='2' />
      <rect x='4.44531' y='4.44531' width='5.55556' height='11.1111' fill='#1964E7' />
      <rect x='11.1094' y='4.44531' width='4.44445' height='2.22222' fill='#1964E7' />
      <rect x='11.1094' y='7.78125' width='4.44445' height='2.22222' fill='#1964E7' />
      <rect x='11.1094' y='11.1094' width='3.33333' height='2.22222' fill='#1964E7' />
    </svg>
  )
}

export const YandexNewsOutlinedIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <rect x='1' y='1' width='18' height='18' rx='1' stroke='#A9A9A9' strokeWidth='2' />
      <rect x='4.44531' y='4.44531' width='5.55556' height='11.1111' fill='#A9A9A9' />
      <rect x='11.1094' y='4.44531' width='4.44445' height='2.22222' fill='#A9A9A9' />
      <rect x='11.1094' y='7.78125' width='4.44445' height='2.22222' fill='#A9A9A9' />
      <rect x='11.1094' y='11.1094' width='3.33333' height='2.22222' fill='#A9A9A9' />
    </svg>
  )
}
