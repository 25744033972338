import React, {FC} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import {LinearProgress, Typography} from '@mui/material'
// import InsiderCard from '@components/Insiders/InsiderСard/InsiderCard'
import styled from 'styled-components'
import {useInsideCardTemplatesQuery} from './gql/Insiders.generated'
// import {InsideCardTemplate} from '@graphql/types'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 77px;
`

const InsidersPage: FC = () => {
  const {data, loading} = useInsideCardTemplatesQuery()

  if (loading) return <LinearProgress />
  return (
    <PageContainer>
      <Typography variant='h1'>Карточки инсайдов</Typography>
      <Wrapper>
        {/* {data?.insideCardTemplates?.map(card => (
                    <InsiderCard
                        key={card.uid}
                        card={card as InsideCardTemplate}
                    />
                ))} */}
      </Wrapper>
    </PageContainer>
  )
}

export default InsidersPage
