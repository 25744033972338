import React, {FC, useEffect, useMemo, useRef} from 'react'
import MuiTreeItem from '@mui/lab/TreeItem'
import {Checkbox, FormControlLabel} from '@mui/material'

import {makeStyles} from '@mui/styles'
import {TreeItemWrapper} from './TreeItem.styles'

// import createTreeNode from './createTreeNode'

const useStyles = makeStyles(() => ({
  root: {
    [[
      '&:focus > $content $label',
      '&:hover > $content $label',
      '&$selected > $content $label',
      '&$selected > $content $label:hover',
      '&$selected:focus > $content $label'
    ].join(',')]: {
      backgroundColor: 'transparent'
    }
  },
  content: {},
  expanded: {},
  selected: {},
  label: {
    userSelect: 'none'
  }
}))

type TreeItemProps = {
  items: any[]
  selected: any
  onSelect: (val: any) => void
  disableMultiParentSelection: boolean
}

export const TreeItem = ({items, selected, onSelect, disableMultiParentSelection}: TreeItemProps): Element[] => {
  const classes = useStyles()
  const tree = useMemo(() => flattenTree({items} as FlattenTreeProps), [items])
  const marksUncheckedRef = useRef(createMarksUnchecked({tree, items, selected}))
  const activeParentRef = useRef('')

  type HandleChangeProps = {
    event: any
    parents: any[]
  }

  const handleChange = ({event, parents = []}: HandleChangeProps) => {
    const {
      target: {value, checked}
    } = event
    let newSelect = selected.slice()

    if (checked) {
      newSelect = [...parents, value].reverse().reduce(
        (prev, curr, index) => {
          const node = curr
          let newSelectSoFar = prev

          if (index === 0) {
            const childNodes = getTreeNodes({tree, node} as GetTreeNodesProps)
            const childNodesLength = childNodes.length

            if (childNodesLength > 0) {
              newSelectSoFar = [...newSelectSoFar.filter(select => !childNodes.includes(select))]

              marksUncheckedRef.current = marksUncheckedRef.current.filter(
                marksUnchecked => ![...childNodes, node].includes(marksUnchecked)
              )
            }
          } else {
            // const childNodes = getTreeNodes({ tree, node, depth: 1 });
            const childNodes = getTreeNodes({tree, node} as GetTreeNodesProps)
            const childNodesLength = childNodes.length

            if (childNodesLength > 0) {
              const isEveryChildrenExist = childNodes.every(childNode => newSelectSoFar.includes(childNode))

              if (isEveryChildrenExist) {
                newSelectSoFar = [...newSelectSoFar.filter(select => !childNodes.includes(select)), node]

                marksUncheckedRef.current = marksUncheckedRef.current.filter(
                  marksUnchecked => ![...childNodes, node].includes(marksUnchecked)
                )
              }
            }
          }

          marksUncheckedRef.current = marksUncheckedRef.current.filter(
            marksUnchecked => !newSelectSoFar.includes(marksUnchecked)
          )

          return newSelectSoFar
        },
        [...newSelect, value]
      )
    } else if (!checked && !newSelect.includes(value)) {
      let toExclude = value
      newSelect = parents
        .slice()
        .reverse()
        .reduce(
          (prev, curr, index) => {
            const node = curr
            let newSelectSoFar = prev
            let childNodes

            marksUncheckedRef.current = [...new Set([...marksUncheckedRef.current, toExclude])]

            if (index === 0) {
              childNodes = getTreeNodes({tree, node, depth: 1} as GetTreeNodesProps)
              newSelectSoFar = [...newSelectSoFar, ...childNodes.filter(childNode => childNode !== toExclude)]
            } else {
              childNodes = getTreeNodes({tree, node, depth: 1} as GetTreeNodesProps).filter(
                childNode => childNode !== toExclude
              )
              newSelectSoFar = [
                ...newSelectSoFar.filter(select => !childNodes.includes(select)),
                ...childNodes.filter(childNode => !marksUncheckedRef.current.includes(childNode))
              ]
            }

            toExclude = curr

            return newSelectSoFar
          },
          newSelect.filter(select => !parents.includes(select))
        )
    } else {
      ;[...parents, value]
        .slice()
        .reverse()
        .forEach(item => {
          const node = item
          const childNodes = getTreeNodes({tree, node, depth: 1} as GetTreeNodesProps)

          if (childNodes.length > 0) {
            marksUncheckedRef.current = [...new Set([...marksUncheckedRef.current, ...childNodes])]
          } else {
            marksUncheckedRef.current = [...new Set([...marksUncheckedRef.current, node])]
          }
        })
      newSelect = newSelect.filter(select => select !== value)
    }

    if (disableMultiParentSelection) {
      if (checked) {
        activeParentRef.current = parents.length > 0 ? parents[0] : value
      } else {
        const childNodes = getTreeNodes({
          tree,
          node: parents.length > 0 ? parents[0] : value
        } as GetTreeNodesProps)

        if (!childNodes.some(childNode => newSelect.includes(childNode))) {
          activeParentRef.current = ''
        }
      }
    }

    onSelect(newSelect)
  }

  type RenderTreeItemProps = {
    nodes: any[]
    parents: any[]
    level: number
  }

  const renderTreeItem = ({nodes, parents = [], level = 0}: RenderTreeItemProps) => {
    return nodes.map(node => {
      const {id: value, label, children} = node
      const checked = selected.includes(value) || parents.some(parent => selected.includes(parent))
      let disabled = activeParentRef.current ? !parents.includes(activeParentRef.current) : false

      if (activeParentRef.current === value) {
        disabled = false
      }

      if (children && children.length > 0) {
        const indeterminate = isIndeterminate({tree, selected, node: value})
        const treeItemLabel = createTreeItemLabel({
          formControlLabelProps: {label},
          checkboxProps: {
            value,
            checked,
            indeterminate,
            disabled,
            onChange: event => {
              handleChange({event, parents})
            }
          }
        })

        return (
          <TreeItemWrapper
            key={value}
            nodeId={value}
            label={treeItemLabel}
            classes={{
              root: classes.root,
              label: classes.label,
              content: classes.content,
              selected: classes.selected
            }}
          >
            {renderTreeItem({
              nodes: children,
              parents: [...parents, value],
              level: level + 1
            })}
          </TreeItemWrapper>
        )
      }

      const treeItemLabel = createTreeItemLabel({
        formControlLabelProps: {label},
        checkboxProps: {
          value,
          checked,
          disabled,
          onChange: event => {
            handleChange({event, parents})
          }
        }
      })

      return (
        <TreeItemWrapper
          key={value}
          nodeId={value}
          label={treeItemLabel}
          classes={{
            root: classes.root,
            label: classes.label,
            content: classes.content,
            selected: classes.selected
          }}
        />
      )
    })
  }

  return renderTreeItem({nodes: items} as RenderTreeItemProps)
}

export default TreeItem

type FlattenTreeProps = {
  items: any[]
  parent: any
  depth: number
}
const flattenTree = ({items, parent = 'root', depth = 0}: FlattenTreeProps) => {
  return items.reduce((prev, curr) => {
    Object.assign(prev, {[parent]: [...(prev[parent] || []), curr.id]})

    if (curr.children && curr.children.length > 0) {
      return {
        ...prev,
        ...flattenTree({
          items: curr.children,
          depth: depth + 1,
          parent: curr.id
        })
      }
    }

    return prev
  }, {})
}

function createMarksUnchecked({tree, items, selected}) {
  return items.reduce((prev, {id: node}) => {
    return [...prev, node, ...getTreeNodes({tree, node} as GetTreeNodesProps)]
  }, [])
}

function createTreeItemLabel({formControlLabelProps = {}, checkboxProps = {}}) {
  return (
    <FormControlLabel
      label={undefined}
      style={{width: '100%', marginLeft: 0}}
      onClick={event => {
        event.stopPropagation()
      }}
      control={<Checkbox {...checkboxProps} />}
      {...formControlLabelProps}
    />
  )
}

type GetTreeNodesProps = {
  tree: any
  node: any
  depth: any
  currentDepth: number
}

const getTreeNodes = ({tree, node = 'root', depth, currentDepth = 1}: GetTreeNodesProps) => {
  const branches = tree[node]

  if (!branches) {
    return []
  }

  return branches.reduce((prev, curr) => {
    let newPrev = [...prev, curr]

    if (tree[curr] && (typeof depth === 'undefined' || depth > currentDepth)) {
      newPrev = [
        ...newPrev,
        ...getTreeNodes({
          tree,
          node: curr,
          depth,
          currentDepth: currentDepth + 1
        })
      ]
    }

    return newPrev
  }, [])
}

function isIndeterminate({tree, node: value, selected}) {
  return getTreeNodes({tree, node: value} as GetTreeNodesProps).some(node => selected.includes(node))
}
