import React, {FC} from 'react'
import RegionTabs from '@components/UI/RegionTabs/RegionTabs'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import TopNewsSelectionHistory from '@components/MainSelection/TopNews/TopNewsSelectionHistory/TopNewsSelectionHistory'
import {Box, IconButton, Typography} from '@mui/material'
import {ArrowBack} from '@mui/icons-material'
import {useHistory} from 'react-router-dom'

const TopNewsSelectionHistoryPage: FC = () => {
  const history = useHistory()
  return (
    <PageContainer>
      <Box display='flex' alignItems='center' marginBottom='5px'>
        <IconButton size='small' title='Назад' color='secondary' onClick={history.goBack}>
          <ArrowBack />
        </IconButton>
        <Box marginLeft='25px'>
          <Typography variant='h1'>Главное за день (История)</Typography>
        </Box>
      </Box>
      <RegionTabs>
        <TopNewsSelectionHistory />
      </RegionTabs>
    </PageContainer>
  )
}

export default TopNewsSelectionHistoryPage
