import {DatePicker} from '@mui/x-date-pickers'
import styled from 'styled-components'

const screenSize = '770px'
export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: ${screenSize}) {
    flex-direction: column;
  }
`

export const KeyboardDatePickerWrapper = styled(DatePicker)`
  & input {
    padding: 0;
  }
  @media (max-width: ${screenSize}) {
    & input {
      padding: 0;
    }
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`

export const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;

  width: 100%;
  max-width: 1304px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 70vh;

  @media screen and (max-width: 768px) {
    height: 55vh;
  }
`
