import React, {FC} from 'react'

const ChartIcon: FC<any> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8.66536 9.5H5.33203V18.6667H8.66536V9.5Z' fill='#1964E7' />
      <path d='M18.6654 12.834H15.332V18.6673H18.6654V12.834Z' fill='#1964E7' />
      <path d='M13.6654 5.33398H10.332V18.6673H13.6654V5.33398Z' fill='#1964E7' />
    </svg>
  )
}

export default ChartIcon
