import ReactQuill from 'react-quill'
import {toast} from 'react-toastify'
import {publishPublicationAction} from '@service/agentCheckService/publicationFormService/publicationService'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {fetchCheckInoagents} from '@fetch'
import {UiPublication} from 'entities/types/UiPublication'
import {getKeycloakInstance} from '../../@auth/keyacloak-client'
import {AgentCheckServiceDependencies, AgentCheckServiceParams} from './agentCheckService.types'

class AgentCheckService implements AgentCheckServiceParams {
  private dependencies: AgentCheckServiceDependencies

  constructor(dependencies: AgentCheckServiceDependencies) {
    this.dependencies = dependencies
  }

  proceedAction(
    actionType: string,
    fields?: PublicationInputType,
    editorRef?: React.MutableRefObject<ReactQuill | null>,
    publication?: UiPublication
  ): void {
    switch (actionType) {
      case 'Опубликовать':
        this.dependencies.agentCheckStore.onConfirm()
        if (fields && editorRef && publication) {
          publishPublicationAction(
            fields,
            editorRef,
            publication,
            this.dependencies.publicationStore.rubricAndSubrubricStore,
            this.dependencies.publicationStore.announceImageModalStore,
            this.dependencies.setPublicationWithPrevState
          ).then(() => {
            this.dependencies.agentCheckStore.stopChecking()
            if (this.dependencies.agentCheckStore.isSameNames) {
              return
            }
            if (this.dependencies.agentCheckStore.inoagentMark !== undefined) {
              if (this.dependencies.agentCheckStore.inoagentMark) {
                toast.success('Все иноагенты отмечены', {
                  containerId: 'success'
                })
                return
              }
              toast.success('Иноагенты не найдены', {
                containerId: 'success'
              })
            }
          }) //для черновиков
        }
        break
      case 'Сохранить':
        this.dependencies.agentCheckStore.onConfirm()
        if (this.dependencies.publicationStore.submitHandler) {
          this.dependencies.publicationStore
            .submitHandler()
            .then(() => {
              this.dependencies.agentCheckStore.stopChecking()
              if (this.dependencies.agentCheckStore.isSameNames) {
                return
              }
              if (this.dependencies.agentCheckStore.inoagentMark !== undefined) {
                if (this.dependencies.agentCheckStore.inoagentMark) {
                  toast.success('Все иноагенты отмечены', {
                    containerId: 'success'
                  })
                  return
                }
                toast.success('Иноагенты не найдены', {
                  containerId: 'success'
                })
              }
            })
            .catch(error => {
              this.dependencies.agentCheckStore.stopChecking()
              console.error(error)
            })
        }
        break
      //   break
      // case 'Закончить':
      //   publicationStore.sendPublicationForReviewAction() // для авторов
      //   break
      default:
        break
    }
  }

  private async extractTextFromEditor(editorRef: React.MutableRefObject<ReactQuill | null>): Promise<string> {
    const quill = editorRef.current?.getEditor()
    if (!quill) {
      return ''
    }
    const delta = quill.getContents()
    let requestText = ''

    delta.forEach(item => {
      if (typeof item.insert === 'string') {
        requestText += item.insert
        requestText += ' '
      }
    })

    return requestText.replace(/\s+\*/g, '*').trim()
  }

  private async checkAgentsInText(text: string, publication: UiPublication): Promise<any> {
    const authorId = getKeycloakInstance().tokenParsed?.sub as string
    const data = {
      params: text,
      publicationId: publication.deprecatedId,
      publicationUid: publication.uid,
      authorId: authorId || null
    }
    return await fetchCheckInoagents(data)
  }

  private handleAgentCheckResponse(
    fields: PublicationInputType,
    editorRef: React.MutableRefObject<ReactQuill | null>,
    publication: UiPublication,
    response: any,
    actionType: string
  ): void {
    if (response && response['SAMENAMES'].length > 0) {
      this.dependencies.agentCheckStore.findedSameNames(response)
    }
    if (response && (response['INOAGENT'].length > 0 || response['EXTREMIST'].length > 0)) {
      this.dependencies.agentCheckStore.findedInoagent(response, actionType)
    } else {
      this.dependencies.agentCheckStore.notFoundInoagent(response, actionType)
      this.proceedAction(actionType, fields, editorRef, publication)
    }
  }

  async checkAgents(
    actionType: string,
    fields: PublicationInputType,
    editorRef: React.MutableRefObject<ReactQuill | null>,
    publication: UiPublication
  ): Promise<void> {
    try {
      this.dependencies.agentCheckStore.startChecking()

      const text = await this.extractTextFromEditor(editorRef)
      const response = await this.checkAgentsInText(text, publication)

      this.handleAgentCheckResponse(fields, editorRef, publication, response, actionType)
    } catch (error) {
      this.dependencies.agentCheckStore.setError(actionType)
    }
  }
}

export default AgentCheckService
