import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type PublicationQueryVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type PublicationQuery = {__typename?: 'Query'} & {
  publication?: Types.Maybe<{__typename?: 'Publication'} & PublicationFieldsFragment>
}

export type GetPublicationDeprecatedIdQueryVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type GetPublicationDeprecatedIdQuery = {__typename?: 'Query'} & {
  publication?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'deprecatedId'>>
}

export type GetPublicationTextOnlyByUidQueryVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type GetPublicationTextOnlyByUidQuery = {__typename?: 'Query'} & {
  publication?: Types.Maybe<
    {__typename?: 'Publication'} & Pick<
      Types.Publication,
      'isAutomaticCommentsDisabled' | 'publishedAt' | 'title' | 'uid'
    > & {
        content: {__typename?: 'PublicationContent'} & {
          elements: Array<
            | {__typename?: 'CustomHTMLElement'}
            | {__typename?: 'GalleryElement'}
            | {__typename?: 'IFrameElement'}
            | {__typename?: 'ImageElement'}
            | {__typename?: 'OnlineBroadcastElement'}
            | ({__typename?: 'TextElement'} & Pick<Types.TextElement, 'content'>)
          >
        }
      }
  >
}

export type VideoEmbedUrlQueryVariables = Types.Exact<{
  url: Types.Scalars['String']
}>

export type VideoEmbedUrlQuery = {__typename?: 'Query'} & Pick<Types.Query, 'videoEmbedUrl'>

export type RubricsQueryVariables = Types.Exact<{[key: string]: never}>

export type RubricsQuery = {__typename?: 'Query'} & {
  rubrics?: Types.Maybe<
    Array<
      {__typename?: 'Rubric'} & Pick<Types.Rubric, 'id' | 'title' | 'availableForRambler'> & {
          subrubrics?: Types.Maybe<
            Array<{__typename?: 'Subrubric'} & Pick<Types.Subrubric, 'id' | 'title' | 'availableForRambler'>>
          >
        }
    >
  >
}

export type GetGalleryPsUrlsQueryVariables = Types.Exact<{
  url: Types.Scalars['String']
}>

export type GetGalleryPsUrlsQuery = {__typename?: 'Query'} & {
  galleryPsUrls?: Types.Maybe<{__typename?: 'GalleryPhotoUrls'} & Pick<Types.GalleryPhotoUrls, 'galleryImgUrls'>>
}

export type GetGalleryImagesQueryVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type GetGalleryImagesQuery = {__typename?: 'Query'} & {
  galleryImages?: Types.Maybe<
    Array<
      Types.Maybe<
        {__typename?: 'GalleryImage'} & Pick<Types.GalleryImage, 'id' | 'uid' | 'description' | 'author'> & {
            figureVersion?: Types.Maybe<
              {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
                  primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
                  crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
                  figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'src' | 'id' | 'uid'> & {
                      sourceImage: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
                      cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url' | 'code'> & {
                          size: {__typename?: 'ImageSize'} & Pick<Types.ImageSize, 'width' | 'height'>
                        }
                    }
                }
            >
          }
      >
    >
  >
}

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<Types.FigureType, 'id' | 'uid' | 'slug'>
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
        crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
        primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        processedImages: Array<{__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>>
      }
  }

export type FigureVersionFragmentFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'src' | 'uid'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
      }
  }

export type PublicationFieldsFragment = {__typename?: 'Publication'} & Pick<
  Types.Publication,
  | 'id'
  | 'uid'
  | 'badwords'
  | 'checkingPhotoEditor'
  | 'title'
  | 'yandexTitle'
  | 'yandexDzenTitle'
  | 'seoTitle'
  | 'subtitle'
  | 'status'
  | 'canonicalUrl'
  | 'isCommentsEnabled'
  | 'isAutomaticCommentsDisabled'
  | 'isImportant'
  | 'ogTitle'
  | 'ogDescription'
  | 'createdAt'
  | 'redirectUrl'
  | 'typeSlug'
  | 'hasOverviewBlock'
  | 'overviewTitle'
  | 'overviewDescription'
  | 'overviewFigureId'
  | 'overviewFigureVersionId'
  | 'isInside'
  | 'specProjectUrl'
  | 'publishedAt'
  | 'deprecatedId'
  | 'url'
  | 'oldAdminUrl'
  | 'keywords'
  | 'sendToRambler'
> & {
    creator: {__typename?: 'User'} & Pick<Types.User, 'username'>
    announceImage: {__typename?: 'AnnounceImage'} & Pick<
      Types.AnnounceImage,
      'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
    > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>}
    socialMediaImage: {__typename?: 'SocialMediaImage'} & Pick<Types.SocialMediaImage, 'id'> & {
        socialMediaFigureVersion?: Types.Maybe<
          {__typename?: 'SocialMediaFigureVersion'} & Pick<Types.SocialMediaFigureVersion, 'title' | 'alignY'> & {
              announceFigureVersion: {__typename?: 'FigureVersion'} & FigureVersionFragmentFragment
              processedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
            }
        >
      }
    overviewFigure?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>
    subscriptionBlock?: Types.Maybe<{__typename?: 'SubscriptionBlock'} & Pick<Types.SubscriptionBlock, 'uid' | 'title'>>
    story?: Types.Maybe<{__typename?: 'Story'} & Pick<Types.Story, 'id' | 'uid' | 'title'>>
    regions?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'id' | 'uid' | 'title'>>>>
    tags?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Tag'} & Pick<Types.Tag, 'id' | 'uid' | 'title'>>>>
    collections?: Types.Maybe<
      Array<Types.Maybe<{__typename?: 'Collection'} & Pick<Types.Collection, 'id' | 'uid' | 'title'>>>
    >
    authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
    destinations?: Types.Maybe<
      Array<Types.Maybe<{__typename?: 'Destination'} & Pick<Types.Destination, 'id' | 'slug' | 'title'>>>
    >
    yandexZenCategories: Array<
      {__typename?: 'YandexZenCategory'} & Pick<Types.YandexZenCategory, 'id' | 'uid' | 'title'>
    >
    relatedLinks: Array<{__typename?: 'RelatedLink'} & Pick<Types.RelatedLink, 'id' | 'url' | 'text'>>
    yandexNewsVideos: Array<
      {__typename?: 'PublicationYandexNewsVideo'} & Pick<
        Types.PublicationYandexNewsVideo,
        'id' | 'uri' | 'title' | 'uid'
      > & {thumbnail: {__typename?: 'Figure'} & FigureFragment}
    >
    rubric?: Types.Maybe<{__typename?: 'Rubric'} & Pick<Types.Rubric, 'id' | 'title' | 'availableForRambler'>>
    subrubric?: Types.Maybe<{__typename?: 'Subrubric'} & Pick<Types.Subrubric, 'id' | 'title' | 'availableForRambler'>>
    content: {__typename?: 'PublicationContent'} & {
      elements: Array<
        | ({__typename: 'CustomHTMLElement'} & Pick<Types.CustomHtmlElement, 'id' | 'uid' | 'html'>)
        | ({__typename: 'GalleryElement'} & Pick<Types.GalleryElement, 'id' | 'uid'>)
        | ({__typename: 'IFrameElement'} & Pick<
            Types.IFrameElement,
            'src' | 'title' | 'width' | 'height' | 'allow' | 'allowFullscreen' | 'frameborder' | 'id' | 'uid'
          >)
        | ({__typename: 'ImageElement'} & Pick<
            Types.ImageElement,
            'isFullWidth' | 'author' | 'alt' | 'description' | 'id' | 'uid'
          > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>})
        | ({__typename: 'OnlineBroadcastElement'} & Pick<Types.OnlineBroadcastElement, 'text' | 'time' | 'id' | 'uid'>)
        | ({__typename: 'TextElement'} & Pick<Types.TextElement, 'id' | 'uid' | 'content'>)
      >
    }
  }

export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const PublicationFieldsFragmentDoc = gql`
  fragment PublicationFields on Publication {
    id
    uid
    badwords
    checkingPhotoEditor
    title
    yandexTitle
    yandexDzenTitle
    seoTitle
    subtitle
    status
    canonicalUrl
    isCommentsEnabled
    isAutomaticCommentsDisabled
    isImportant
    ogTitle
    ogDescription
    createdAt
    redirectUrl
    typeSlug
    creator {
      username
    }
    announceImage {
      id
      isFullWidth
      description
      author
      alt
      figureVersion {
        ...FigureVersionFragment
      }
    }
    socialMediaImage {
      id
      socialMediaFigureVersion {
        title
        alignY
        announceFigureVersion {
          ...FigureVersionFragment
        }
        processedImage {
          url
        }
      }
    }
    hasOverviewBlock
    overviewTitle
    overviewDescription
    overviewFigure {
      ...FigureVersionFragment
    }
    overviewFigureId
    overviewFigureVersionId
    isInside
    specProjectUrl
    publishedAt
    deprecatedId
    url
    oldAdminUrl
    keywords
    subscriptionBlock {
      uid
      title
    }
    story {
      id
      uid
      title
    }
    regions {
      id
      uid
      title
    }
    tags {
      id
      uid
      title
    }
    collections {
      id
      uid
      title
    }
    authors {
      uid
      firstName
      lastName
    }
    sendToRambler
    destinations {
      id
      slug
      title
    }
    yandexZenCategories {
      id
      uid
      title
    }
    relatedLinks {
      id
      url
      text
    }
    subscriptionBlock {
      uid
      title
    }
    yandexNewsVideos {
      id
      uri
      title
      uid
      thumbnail {
        ...Figure
      }
    }
    rubric {
      id
      title
      availableForRambler
    }
    subrubric {
      id
      title
      availableForRambler
    }
    content {
      elements {
        id
        uid
        __typename
        ... on GalleryElement {
          id
          uid
        }
        ... on ImageElement {
          isFullWidth
          author
          alt
          description
          figureVersion {
            ...FigureVersionFragment
          }
        }
        ... on TextElement {
          id
          uid
          content
        }
        ... on OnlineBroadcastElement {
          text
          time
        }
        ... on IFrameElement {
          src
          title
          width
          height
          allow
          allowFullscreen
          frameborder
        }
        ... on CustomHTMLElement {
          id
          uid
          html
        }
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
  ${FigureFragmentDoc}
`
export const PublicationDocument = gql`
  query publication($uid: UUID!) {
    publication(uid: $uid) {
      ...PublicationFields
    }
  }
  ${PublicationFieldsFragmentDoc}
`

/**
 * __usePublicationQuery__
 *
 * To run a query within a React component, call `usePublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function usePublicationQuery(baseOptions: Apollo.QueryHookOptions<PublicationQuery, PublicationQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationQuery, PublicationQueryVariables>(PublicationDocument, options)
}
export function usePublicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationQuery, PublicationQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationQuery, PublicationQueryVariables>(PublicationDocument, options)
}
export type PublicationQueryHookResult = ReturnType<typeof usePublicationQuery>
export type PublicationLazyQueryHookResult = ReturnType<typeof usePublicationLazyQuery>
export type PublicationQueryResult = Apollo.QueryResult<PublicationQuery, PublicationQueryVariables>
export const GetPublicationDeprecatedIdDocument = gql`
  query getPublicationDeprecatedId($uid: UUID!) {
    publication(uid: $uid) {
      deprecatedId
    }
  }
`

/**
 * __useGetPublicationDeprecatedIdQuery__
 *
 * To run a query within a React component, call `useGetPublicationDeprecatedIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationDeprecatedIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationDeprecatedIdQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPublicationDeprecatedIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPublicationDeprecatedIdQuery, GetPublicationDeprecatedIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetPublicationDeprecatedIdQuery, GetPublicationDeprecatedIdQueryVariables>(
    GetPublicationDeprecatedIdDocument,
    options
  )
}
export function useGetPublicationDeprecatedIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPublicationDeprecatedIdQuery, GetPublicationDeprecatedIdQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetPublicationDeprecatedIdQuery, GetPublicationDeprecatedIdQueryVariables>(
    GetPublicationDeprecatedIdDocument,
    options
  )
}
export type GetPublicationDeprecatedIdQueryHookResult = ReturnType<typeof useGetPublicationDeprecatedIdQuery>
export type GetPublicationDeprecatedIdLazyQueryHookResult = ReturnType<typeof useGetPublicationDeprecatedIdLazyQuery>
export type GetPublicationDeprecatedIdQueryResult = Apollo.QueryResult<
  GetPublicationDeprecatedIdQuery,
  GetPublicationDeprecatedIdQueryVariables
>
export const GetPublicationTextOnlyByUidDocument = gql`
  query getPublicationTextOnlyByUid($uid: UUID!) {
    publication(uid: $uid) {
      content {
        elements {
          ... on TextElement {
            content
          }
        }
      }
      isAutomaticCommentsDisabled
      publishedAt
      title
      uid
    }
  }
`

/**
 * __useGetPublicationTextOnlyByUidQuery__
 *
 * To run a query within a React component, call `useGetPublicationTextOnlyByUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationTextOnlyByUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationTextOnlyByUidQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPublicationTextOnlyByUidQuery(
  baseOptions: Apollo.QueryHookOptions<GetPublicationTextOnlyByUidQuery, GetPublicationTextOnlyByUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetPublicationTextOnlyByUidQuery, GetPublicationTextOnlyByUidQueryVariables>(
    GetPublicationTextOnlyByUidDocument,
    options
  )
}
export function useGetPublicationTextOnlyByUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPublicationTextOnlyByUidQuery, GetPublicationTextOnlyByUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetPublicationTextOnlyByUidQuery, GetPublicationTextOnlyByUidQueryVariables>(
    GetPublicationTextOnlyByUidDocument,
    options
  )
}
export type GetPublicationTextOnlyByUidQueryHookResult = ReturnType<typeof useGetPublicationTextOnlyByUidQuery>
export type GetPublicationTextOnlyByUidLazyQueryHookResult = ReturnType<typeof useGetPublicationTextOnlyByUidLazyQuery>
export type GetPublicationTextOnlyByUidQueryResult = Apollo.QueryResult<
  GetPublicationTextOnlyByUidQuery,
  GetPublicationTextOnlyByUidQueryVariables
>
export const VideoEmbedUrlDocument = gql`
  query videoEmbedUrl($url: String!) {
    videoEmbedUrl(url: $url)
  }
`

/**
 * __useVideoEmbedUrlQuery__
 *
 * To run a query within a React component, call `useVideoEmbedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoEmbedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoEmbedUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useVideoEmbedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<VideoEmbedUrlQuery, VideoEmbedUrlQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<VideoEmbedUrlQuery, VideoEmbedUrlQueryVariables>(VideoEmbedUrlDocument, options)
}
export function useVideoEmbedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VideoEmbedUrlQuery, VideoEmbedUrlQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<VideoEmbedUrlQuery, VideoEmbedUrlQueryVariables>(VideoEmbedUrlDocument, options)
}
export type VideoEmbedUrlQueryHookResult = ReturnType<typeof useVideoEmbedUrlQuery>
export type VideoEmbedUrlLazyQueryHookResult = ReturnType<typeof useVideoEmbedUrlLazyQuery>
export type VideoEmbedUrlQueryResult = Apollo.QueryResult<VideoEmbedUrlQuery, VideoEmbedUrlQueryVariables>
export const RubricsDocument = gql`
  query rubrics {
    rubrics {
      id
      title
      availableForRambler
      subrubrics {
        id
        title
        availableForRambler
      }
    }
  }
`

/**
 * __useRubricsQuery__
 *
 * To run a query within a React component, call `useRubricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRubricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRubricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRubricsQuery(baseOptions?: Apollo.QueryHookOptions<RubricsQuery, RubricsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<RubricsQuery, RubricsQueryVariables>(RubricsDocument, options)
}
export function useRubricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RubricsQuery, RubricsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<RubricsQuery, RubricsQueryVariables>(RubricsDocument, options)
}
export type RubricsQueryHookResult = ReturnType<typeof useRubricsQuery>
export type RubricsLazyQueryHookResult = ReturnType<typeof useRubricsLazyQuery>
export type RubricsQueryResult = Apollo.QueryResult<RubricsQuery, RubricsQueryVariables>
export const GetGalleryPsUrlsDocument = gql`
  query getGalleryPsUrls($url: String!) {
    galleryPsUrls(url: $url) {
      galleryImgUrls
    }
  }
`

/**
 * __useGetGalleryPsUrlsQuery__
 *
 * To run a query within a React component, call `useGetGalleryPsUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryPsUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryPsUrlsQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetGalleryPsUrlsQuery(
  baseOptions: Apollo.QueryHookOptions<GetGalleryPsUrlsQuery, GetGalleryPsUrlsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetGalleryPsUrlsQuery, GetGalleryPsUrlsQueryVariables>(GetGalleryPsUrlsDocument, options)
}
export function useGetGalleryPsUrlsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGalleryPsUrlsQuery, GetGalleryPsUrlsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetGalleryPsUrlsQuery, GetGalleryPsUrlsQueryVariables>(GetGalleryPsUrlsDocument, options)
}
export type GetGalleryPsUrlsQueryHookResult = ReturnType<typeof useGetGalleryPsUrlsQuery>
export type GetGalleryPsUrlsLazyQueryHookResult = ReturnType<typeof useGetGalleryPsUrlsLazyQuery>
export type GetGalleryPsUrlsQueryResult = Apollo.QueryResult<GetGalleryPsUrlsQuery, GetGalleryPsUrlsQueryVariables>
export const GetGalleryImagesDocument = gql`
  query getGalleryImages($uid: UUID!) {
    galleryImages(uid: $uid) {
      id
      uid
      figureVersion {
        id
        uid
        primaryProcessedImage {
          url
        }
        crop {
          left
          top
          width
          height
        }
        figure {
          sourceImage {
            url
          }
          cropperPreview {
            url
            size {
              width
              height
            }
            code
          }
          src
          id
          uid
        }
      }
      description
      author
    }
  }
`

/**
 * __useGetGalleryImagesQuery__
 *
 * To run a query within a React component, call `useGetGalleryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryImagesQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetGalleryImagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetGalleryImagesQuery, GetGalleryImagesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetGalleryImagesQuery, GetGalleryImagesQueryVariables>(GetGalleryImagesDocument, options)
}
export function useGetGalleryImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGalleryImagesQuery, GetGalleryImagesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetGalleryImagesQuery, GetGalleryImagesQueryVariables>(GetGalleryImagesDocument, options)
}
export type GetGalleryImagesQueryHookResult = ReturnType<typeof useGetGalleryImagesQuery>
export type GetGalleryImagesLazyQueryHookResult = ReturnType<typeof useGetGalleryImagesLazyQuery>
export type GetGalleryImagesQueryResult = Apollo.QueryResult<GetGalleryImagesQuery, GetGalleryImagesQueryVariables>
