import * as Types from '../../../../@graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TopNewsBlockByIdQueryVariables = Types.Exact<{
  blockId: Types.Scalars['UUID'];
}>;


export type TopNewsBlockByIdQuery = (
  { __typename?: 'Query' }
  & { topNewsBlockById?: Types.Maybe<(
    { __typename?: 'TopNewsBlock' }
    & TopNewsBlockByIdFragmentFragment
  )> }
);

export type SaveTopNewsBlockByIdMutationVariables = Types.Exact<{
  data: Types.SaveTopNewsBlockInput;
}>;


export type SaveTopNewsBlockByIdMutation = (
  { __typename?: 'Mutation' }
  & { saveTopNewsBlock?: Types.Maybe<(
    { __typename?: 'SaveTopNewsBlockPayload' }
    & { block?: Types.Maybe<(
      { __typename?: 'TopNewsBlock' }
      & TopNewsBlockByIdFragmentFragment
    )> }
  )> }
);

export type TopNewsBlockByIdFragmentFragment = (
  { __typename?: 'TopNewsBlock' }
  & Pick<Types.TopNewsBlock, 'uid' | 'position' | 'title' | 'isExclusive' | 'isAd' | 'url' | 'clickCount'>
  & { figureVersion?: Types.Maybe<(
    { __typename?: 'FigureVersion' }
    & Pick<Types.FigureVersion, 'id'>
    & { figure: (
      { __typename?: 'Figure' }
      & Pick<Types.Figure, 'id' | 'uid' | 'src'>
      & { cropperPreview: (
        { __typename?: 'Image' }
        & Pick<Types.Image, 'url'>
      ) }
    ), crop: (
      { __typename?: 'Crop' }
      & Pick<Types.Crop, 'height' | 'left' | 'top' | 'width'>
    ), primaryProcessedImage?: Types.Maybe<(
      { __typename?: 'Image' }
      & Pick<Types.Image, 'url'>
    )> }
  )> }
);

export const TopNewsBlockByIdFragmentFragmentDoc = gql`
    fragment TopNewsBlockByIdFragment on TopNewsBlock {
  uid
  position
  title
  isExclusive
  isAd
  url
  clickCount
  figureVersion {
    figure {
      id
      uid
      cropperPreview {
        url
      }
      src
    }
    id
    crop {
      height
      left
      top
      width
    }
    primaryProcessedImage {
      url
    }
  }
}
    `;
export const TopNewsBlockByIdDocument = gql`
    query topNewsBlockById($blockId: UUID!) {
  topNewsBlockById(blockId: $blockId) {
    ...TopNewsBlockByIdFragment
  }
}
    ${TopNewsBlockByIdFragmentFragmentDoc}`;

/**
 * __useTopNewsBlockByIdQuery__
 *
 * To run a query within a React component, call `useTopNewsBlockByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNewsBlockByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNewsBlockByIdQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useTopNewsBlockByIdQuery(baseOptions: Apollo.QueryHookOptions<TopNewsBlockByIdQuery, TopNewsBlockByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopNewsBlockByIdQuery, TopNewsBlockByIdQueryVariables>(TopNewsBlockByIdDocument, options);
      }
export function useTopNewsBlockByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopNewsBlockByIdQuery, TopNewsBlockByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopNewsBlockByIdQuery, TopNewsBlockByIdQueryVariables>(TopNewsBlockByIdDocument, options);
        }
export type TopNewsBlockByIdQueryHookResult = ReturnType<typeof useTopNewsBlockByIdQuery>;
export type TopNewsBlockByIdLazyQueryHookResult = ReturnType<typeof useTopNewsBlockByIdLazyQuery>;
export type TopNewsBlockByIdQueryResult = Apollo.QueryResult<TopNewsBlockByIdQuery, TopNewsBlockByIdQueryVariables>;
export const SaveTopNewsBlockByIdDocument = gql`
    mutation saveTopNewsBlockById($data: SaveTopNewsBlockInput!) {
  saveTopNewsBlock(data: $data) {
    block {
      ...TopNewsBlockByIdFragment
    }
  }
}
    ${TopNewsBlockByIdFragmentFragmentDoc}`;
export type SaveTopNewsBlockByIdMutationFn = Apollo.MutationFunction<SaveTopNewsBlockByIdMutation, SaveTopNewsBlockByIdMutationVariables>;

/**
 * __useSaveTopNewsBlockByIdMutation__
 *
 * To run a mutation, you first call `useSaveTopNewsBlockByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTopNewsBlockByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTopNewsBlockByIdMutation, { data, loading, error }] = useSaveTopNewsBlockByIdMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveTopNewsBlockByIdMutation(baseOptions?: Apollo.MutationHookOptions<SaveTopNewsBlockByIdMutation, SaveTopNewsBlockByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTopNewsBlockByIdMutation, SaveTopNewsBlockByIdMutationVariables>(SaveTopNewsBlockByIdDocument, options);
      }
export type SaveTopNewsBlockByIdMutationHookResult = ReturnType<typeof useSaveTopNewsBlockByIdMutation>;
export type SaveTopNewsBlockByIdMutationResult = Apollo.MutationResult<SaveTopNewsBlockByIdMutation>;
export type SaveTopNewsBlockByIdMutationOptions = Apollo.BaseMutationOptions<SaveTopNewsBlockByIdMutation, SaveTopNewsBlockByIdMutationVariables>;