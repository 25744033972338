import React, {FC} from 'react'
import CollectionList from '@components/Collection/List/CollectionList'
import PageContainer from '@components/UI/PageContainer/PageContainer'

const CollectionsPage: FC = () => {
  return (
    <PageContainer>
      <CollectionList />
    </PageContainer>
  )
}

export default CollectionsPage
