import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {
  ButtonsGroup,
  MainContainer,
  StyledAddBtn,
  StyledInvertBtn,
  StyledTextField,
  TextAreaWrapper
} from './OnlineTranslationFields.styles'
import {ONLINE_TRANSLATION_FIELDS_CONSTS} from './OnlineTranslationFields.consts'

const DEFAULT_FIELD_VALUE = ''

const OnlineTranslationFields: FC = () => {
  const {editorStore} = useStore()
  const {pushToOnlineTranslation, reverseOnlineTranslation} = editorStore
  const [value, setValue] = useState(DEFAULT_FIELD_VALUE)
  const fieldRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let textarea
    if (fieldRef.current) {
      textarea = fieldRef.current.querySelector('textarea')
    }
    if (textarea) {
      textarea.focus()
    }
  }, [fieldRef.current])

  const onChange = useCallback(
    event => {
      setValue(event.target.value)
    },
    [value]
  )

  const onClick = useCallback(() => {
    pushToOnlineTranslation(value)
    setValue(DEFAULT_FIELD_VALUE)
  }, [value])

  const onClickInverseButton = useCallback(() => {
    reverseOnlineTranslation()
  }, [value])

  return (
    <MainContainer>
      <TextAreaWrapper>
        <StyledTextField
          fullWidth
          ref={fieldRef}
          value={value}
          size='medium'
          multiline
          minRows={4}
          id='outlined-basic'
          label={ONLINE_TRANSLATION_FIELDS_CONSTS.textToTranslation}
          variant='outlined'
          onChange={onChange}
        />
      </TextAreaWrapper>
      <ButtonsGroup>
        <StyledAddBtn color='primary' variant='contained' type='button' disabled={!value.length} onClick={onClick}>
          {ONLINE_TRANSLATION_FIELDS_CONSTS.addToNews}
        </StyledAddBtn>

        <StyledInvertBtn
          color='primary'
          variant='outlined'
          type='button'
          disabled={false}
          onClick={onClickInverseButton}
        >
          {ONLINE_TRANSLATION_FIELDS_CONSTS.invertTime}
        </StyledInvertBtn>
      </ButtonsGroup>
    </MainContainer>
  )
}

export default observer(OnlineTranslationFields)
