import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type ReportPublicationsQueryVariables = Types.Exact<{
  status?: Types.Maybe<Types.PublicationStatus>
  authorUid?: Types.Maybe<Types.Scalars['UUID']>
  experimentUid?: Types.Maybe<Types.Scalars['UUID']>
  after?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
  datePublishedFrom?: Types.Maybe<Types.Scalars['DateTime']>
  datePublishedTo?: Types.Maybe<Types.Scalars['DateTime']>
  regionUid?: Types.Maybe<Types.Scalars['UUID']>
  rubric?: Types.Maybe<Array<Types.Maybe<Types.Scalars['UUID']>> | Types.Maybe<Types.Scalars['UUID']>>
  subrubric?: Types.Maybe<Array<Types.Maybe<Types.Scalars['UUID']>> | Types.Maybe<Types.Scalars['UUID']>>
  search?: Types.Maybe<Types.Scalars['String']>
  sort?: Types.Maybe<Types.PublicationsSort>
}>

export type ReportPublicationsQuery = {__typename?: 'Query'} & {
  publications?: Types.Maybe<
    {__typename?: 'PublicationsConnection'} & Pick<Types.PublicationsConnection, 'totalCount'> & {
        edges: Array<
          Types.Maybe<
            {__typename?: 'PublicationsEdge'} & Pick<Types.PublicationsEdge, 'cursor'> & {
                node?: Types.Maybe<
                  {__typename?: 'Publication'} & Pick<
                    Types.Publication,
                    | 'id'
                    | 'uid'
                    | 'title'
                    | 'seoTitle'
                    | 'publishedAt'
                    | 'publishedAtDate'
                    | 'status'
                    | 'canonicalUrl'
                    | 'url'
                    | 'typeSlug'
                  > & {
                      rubric?: Types.Maybe<{__typename?: 'Rubric'} & Pick<Types.Rubric, 'id' | 'title'>>
                      subrubric?: Types.Maybe<{__typename?: 'Subrubric'} & Pick<Types.Subrubric, 'id' | 'title'>>
                      authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
                    }
                >
              }
          >
        >
        pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
      }
  >
}

export const ReportPublicationsDocument = gql`
  query reportPublications(
    $status: PublicationStatus
    $authorUid: UUID
    $experimentUid: UUID
    $after: String
    $first: Int
    $datePublishedFrom: DateTime
    $datePublishedTo: DateTime
    $regionUid: UUID
    $rubric: [UUID]
    $subrubric: [UUID]
    $search: String
    $sort: PublicationsSort
  ) {
    publications(
      sort: $sort
      limit: 30
      filter: {
        status: $status
        authorUid: $authorUid
        experimentUid: $experimentUid
        datePublishedFrom: $datePublishedFrom
        datePublishedTo: $datePublishedTo
        regionUid: $regionUid
        rubric: $rubric
        subrubric: $subrubric
        search: $search
      }
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          id
          uid
          title
          seoTitle
          publishedAt
          publishedAtDate
          status
          canonicalUrl
          url
          typeSlug
          rubric {
            id
            title
          }
          subrubric {
            id
            title
          }
          authors {
            uid
            firstName
            lastName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`

/**
 * __useReportPublicationsQuery__
 *
 * To run a query within a React component, call `useReportPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPublicationsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      authorUid: // value for 'authorUid'
 *      experimentUid: // value for 'experimentUid'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      datePublishedFrom: // value for 'datePublishedFrom'
 *      datePublishedTo: // value for 'datePublishedTo'
 *      regionUid: // value for 'regionUid'
 *      rubric: // value for 'rubric'
 *      subrubric: // value for 'subrubric'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReportPublicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ReportPublicationsQuery, ReportPublicationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<ReportPublicationsQuery, ReportPublicationsQueryVariables>(ReportPublicationsDocument, options)
}
export function useReportPublicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportPublicationsQuery, ReportPublicationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<ReportPublicationsQuery, ReportPublicationsQueryVariables>(
    ReportPublicationsDocument,
    options
  )
}
export type ReportPublicationsQueryHookResult = ReturnType<typeof useReportPublicationsQuery>
export type ReportPublicationsLazyQueryHookResult = ReturnType<typeof useReportPublicationsLazyQuery>
export type ReportPublicationsQueryResult = Apollo.QueryResult<
  ReportPublicationsQuery,
  ReportPublicationsQueryVariables
>
