import { type OfficialWord, PublicationStatus } from '@graphql/types'
import { useStore } from '@stores/rootStoreContext'
import { FC, useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  useBadwordsListQuery,
  useOfficialWordsListLazyQuery,
  useRandomSeoTitleExampleQuery
} from './../gql/PublicationEditor.generated'
import PublicationFormTitle from './PublicationFormTitle/PublicationFormTitle'
import { replaceValue, validateTitle, validateWords } from './PublicationFormTitle/PublicationFormTitle.utils'
import {
  PUBLICATION_FORM_TITLES_CONSTS,
  SENTENCE_SEPARATOR_REG_EX,
  TitleFieldEntries
} from './PublicationFormTitles.consts'
import { PublicationFormTitlesWrapper, SeoTitleExampleWrapper } from './PublicationFormTitles.styles'
import { ETitlesField, IPublicationFormTitlesProps } from './PublicationFormTitles.types'
import { SeoTitleExample } from './SeoTitleExample/SeoTitleExample'
import { TitleFillingRules } from './TitleFillingRules'

export const PublicationFormTitles: FC<IPublicationFormTitlesProps> = ({methodsForm, cantEditPublication}) => {
  const {control, setValue, clearErrors, setError, getValues} = methodsForm

  const {publicationStore} = useStore()
  const {publication, setTitlesError} = publicationStore

  const [badWords, setBadWords] = useState<string[]>([])
  const [officialWords, setOfficialWords] = useState<OfficialWord[]>([])
  const isNews = publication?.typeSlug === 'news'
  const isArticles = publication.typeSlug === 'articles'
  const [titleCustomInformationError, setTitleCustomInformationError] = useState('')
  const [yandexTitleCustomInformationError, setYandexTitleCustomInformationError] = useState('')

  const {data: randomTitleExamleData} = useRandomSeoTitleExampleQuery({
    variables: {}
  })

  useBadwordsListQuery({
    variables: {},
    onCompleted: ({badwords}) => {
      if (badwords) {
        const data = badwords.reduce<string[]>((array, {verbal_expression}) => {
          if (verbal_expression) {
            array.push(verbal_expression)
          }

          return array
        }, [])

        setBadWords(data)
      }
    }
  })

  const [fetchOfficialWordsList] = useOfficialWordsListLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({officialWords}) => {
      if (officialWords) {
        setOfficialWords(officialWords)
      }
    }
  })

  useEffect(() => {
    fetchOfficialWordsList({
      variables: {}
    })
  }, [fetchOfficialWordsList])

  const handleChange = useCallback(
    (fieldValue: string, fieldName: ETitlesField) => {
      const value = replaceValue(fieldValue)
      setValue(fieldName, value)

      const isArticlesTitle = isArticles && fieldName === ETitlesField.Title
      const titleMaxLength = isArticlesTitle
        ? PUBLICATION_FORM_TITLES_CONSTS.articleTitleMaxLength
        : TitleFieldEntries[fieldName].maxErrorLength

      setTitlesError(false)
      clearErrors(fieldName)

      const {isInvalid, errorMessage} = validateTitle({
        fieldName,
        text: value,
        officialWords,
        maxErrorLength: titleMaxLength,
        titleMaxWordsCount: TitleFieldEntries[fieldName].titleMaxWordsCount || undefined,
        maxWarningLength: 0
      })

      if (isInvalid) {
        setError(fieldName, {
          message: errorMessage
        })
        setTitlesError(true)
        return
      }

      if (fieldName === ETitlesField.Title) {
        setTitleCustomInformationError(errorMessage)
      }
    },
    [officialWords, isArticles, setTitlesError]
  )

  const fieldYandexTitle = getValues(ETitlesField.YandexTitle)

  const handleYandexTitleChange = useCallback(
    (value, _fieldName) => {
      handleChange(value, ETitlesField.YandexTitle)

      const {isInvalid: yandexTitleHasBadWord, errorMessage: yandexTitleErrorMessage} = validateWords({
        text: value,
        invalidWords: badWords
      })

      setYandexTitleCustomInformationError(yandexTitleHasBadWord ? yandexTitleErrorMessage : '')
    },
    [badWords, handleChange]
  )

  // Дублирует значение Заголовка в Заголовок для Яндекс.Новостей

  const handleTitleChange = useCallback(
    (value, _fieldName) => {
      const sentencesArr = value.split(SENTENCE_SEPARATOR_REG_EX) || []
      const hasSentence = sentencesArr.length && sentencesArr[0]
      const condition =
        // Заголовок Яндекс пустой ИЛИ
        fieldYandexTitle === '' ||
        // Первое предложение начинается с заголовка Яндекс или пустой строки ИЛИ
        sentencesArr[0].includes(fieldYandexTitle || '', 0) ||
        // Первое предложение включает в себя заголовок Яндекс или пустую строку
        sentencesArr[0].includes(
          (fieldYandexTitle || '').slice(
            0,
            (fieldYandexTitle?.length || 0) > 0 ? (fieldYandexTitle?.length || 0) - 1 : 0
          ),
          0
        )

      if (hasSentence && condition) {
        const title = sentencesArr[0]
        handleChange(title, ETitlesField.YandexTitle)
      }
      if (value && ![PublicationStatus.Draft, PublicationStatus.Review].includes(publication.status) && publication.title !== getValues(ETitlesField.Title)) {
        handleChange(value, ETitlesField.YandexDzenTitle)
      }

      handleChange(value, ETitlesField.Title)
    },
    [fieldYandexTitle, handleChange]
  )

  const newUpdateTitle = () => {
    return
  }

  return (
    <PublicationFormTitlesWrapper>
      <Controller
        name={ETitlesField.Title}
        control={control}
        render={({field}) => (
          <PublicationFormTitle
            methodsForm={methodsForm}
            disabled={cantEditPublication}
            // @ts-ignore
            field={field}
            onChange={handleTitleChange}
            newUpdateTitle={newUpdateTitle}
            customInformationError={titleCustomInformationError}
            fillingRules={<TitleFillingRules/>}
          />
        )}
      />
      {isNews && (
        <Controller
          name={ETitlesField.YandexTitle}
          control={control}
          render={({field}) => (
            <PublicationFormTitle
              methodsForm={methodsForm}
              disabled={cantEditPublication}
              onChange={handleYandexTitleChange}
              customInformationError={yandexTitleCustomInformationError}
              // @ts-ignore
              field={field}
            />
          )}
        />
      )}
      <Controller
        name={ETitlesField.SeoTitle}
        control={control}
        render={({field}) => (
          <PublicationFormTitle
            methodsForm={methodsForm}
            disabled={cantEditPublication}
            onChange={handleChange}
            // @ts-ignore
            field={field}
          />
        )}
      />
      <SeoTitleExampleWrapper>
        <SeoTitleExample randomTitleExamleData={randomTitleExamleData} />
      </SeoTitleExampleWrapper>
      {isArticles && (
        <Controller
          name={ETitlesField.Subtitle}
          control={control}
          render={({field}) => (
            <PublicationFormTitle
              methodsForm={methodsForm}
              disabled={cantEditPublication}
              onChange={handleChange}
              // @ts-ignore
              field={field}
            />
          )}
        />
      )}
    </PublicationFormTitlesWrapper>
  )
}
