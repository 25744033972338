import styled from 'styled-components'
import {blueColor, lightGrayColor2, mainTextColor} from '../../../../@theme/vars'

export const TdWrapper = styled.div`
  border-right: 1px solid ${lightGrayColor2};
  border-bottom: 1px solid ${lightGrayColor2};
  color: ${mainTextColor};
  font-size: 12px;
  font-weight: 400;
  white-space: break-spaces;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const TBodyWrapper = styled.div`
  width: 1257px;
`

export const TrWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const TdDivWrapper = styled.div`
  width: inherit;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 12px 0px 12px 6px;
`

export const LinkWrapper = styled.a`
  width: inherit;
  margin-top: 6px;
  color: ${blueColor};
  text-decoration: none;
  cursor: pointer;
`
