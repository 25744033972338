export const MAIN_SELECTION_CARD_CONSTS = {
  advertisement: 'Реклама',
  deleteBlockConfirmation: 'Вы уверены что хотите удалить блок?',
  deletePublication: 'Удалить публикацию',
  errorTitleLength: 'Превышено количество символов в заголовке!',
  exclusive: 'Эксклюзив',
  maxTitleLength: 54,
  toChange: 'Изменить',
  toDelete: 'Удалить'
}
