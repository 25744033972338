import React, {FC, useState, useCallback, useEffect} from 'react'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {Box, Checkbox, FormControlLabel} from '@mui/material'
import ImageLoader from '../Loaders/ImageLoader/ImageLoader'
import VideoLoader from '../Loaders/VideoLoader/VideoLoader'
import {Figure, PublicationYandexNewsVideo} from '@graphql/types'
import {generateGuid} from '@utils/generateGuid'
import {UPLOAD_VIDEO_MODAL_CONSTS} from './UploadVideoModal.consts'
import {ImageLoaderWrapper, VideoLoaderWrapper} from './UploadVideoModal.styles'
import useWindowResize from '../../../hooks/useWindowResize'
import {UploadVideoModalProps} from './UploadVideoModalProps'

const UploadVideoModal: FC<UploadVideoModalProps> = ({
  open,
  onClose,
  okLabel,
  title,
  video,
  setVideos,
  setVideoForIncluding,
  setUpd,
  setCurrIncludeInNews
}) => {
  const [videoUid, setVideoUid] = useState<string>(video?.uid || generateGuid())
  const [videoTitle, setVideoTitle] = useState<string>(video?.title || '')
  const [videoUri, setVideoUri] = useState<string>(video?.uri || '')
  const [thumbnail, setThumbnail] = useState<Figure>(video?.thumbnail || ({} as Figure))
  const [includeInNews, setIncludeInNews] = useState(false)
  const [okBtnDisabled, setOkBtnDisabled] = useState(true)
  const [screenWidth] = useWindowResize()
  const [currentVideo, setCurrentVideo] = useState<PublicationYandexNewsVideo>({} as PublicationYandexNewsVideo)
  const [figureIsLoading, setFigureIsLoading] = useState(false)

  useEffect(() => {
    setOkBtnDisabled(!videoTitle || !videoUri || !thumbnail || figureIsLoading)
  }, [videoTitle, videoUri, thumbnail, figureIsLoading])

  useEffect(() => {
    setVideoUid(video?.uid || generateGuid())
    setVideoTitle(video?.title || '')
    setVideoUri(video?.uri || '')
    setThumbnail(video?.thumbnail || ({} as Figure))
  }, [video])

  useEffect(() => {
    setCurrIncludeInNews(includeInNews)
  }, [setCurrIncludeInNews, includeInNews])

  useEffect(() => {
    if (videoTitle && videoUri && thumbnail?.id && videoUid) {
      const newVideo: PublicationYandexNewsVideo = {
        id: videoUid,
        uid: videoUid,
        uri: videoUri,
        title: videoTitle,
        thumbnail: thumbnail
      } as PublicationYandexNewsVideo
      setVideoForIncluding(newVideo)
      setCurrentVideo(newVideo)
    }
  }, [videoUri, videoTitle, thumbnail, videoUid, setVideoForIncluding])

  const toggleIncludeInNews = useCallback(() => {
    setIncludeInNews(!includeInNews)
  }, [includeInNews])

  const setFigure = useCallback(
    (figure: Figure) => {
      setThumbnail(figure)
    },
    [setThumbnail]
  )

  const onOk = useCallback(() => {
    setVideos(prevVideos => {
      const updatedVideos = prevVideos.map(video => {
        if (video.uid === videoUid) {
          return currentVideo
        }
        return video
      })
      if (updatedVideos.some(video => video.uid === videoUid)) {
        return updatedVideos
      } else {
        return [...updatedVideos, currentVideo]
      }
    })
    setUpd(true)
    onClose()
  }, [currentVideo, onClose, setVideos, videoUid, setUpd])

  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={onClose}
      title={title}
      text={''}
      okLabel={okLabel}
      noLabel={''}
      onNo={onClose}
      onOk={onOk}
      onYes={onOk}
      okButtonDisabled={okBtnDisabled}
      okButtonDisabledTooltip={UPLOAD_VIDEO_MODAL_CONSTS.fillRequiredFields}
      loading={figureIsLoading}
    >
      <Box
        key={videoUid}
        position='relative'
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='start'
        width='100%'
      >
        <VideoLoaderWrapper>
          <VideoLoader video={video} setVideoTitle={setVideoTitle} setVideoUri={setVideoUri} />
        </VideoLoaderWrapper>
        <ImageLoaderWrapper>
          <ImageLoader setFigure={setFigure} defaultFigure={thumbnail} setFigureIsLoading={setFigureIsLoading} />
        </ImageLoaderWrapper>
        <Box style={{margin: screenWidth <= 768 ? '0 auto' : ''}}>
          <FormControlLabel
            control={<Checkbox checked={includeInNews} onChange={toggleIncludeInNews} color='primary' />}
            label={UPLOAD_VIDEO_MODAL_CONSTS.includeInNews}
          />
        </Box>
      </Box>
    </SimpleModal>
  )
}

export default UploadVideoModal
