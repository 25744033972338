import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import {ReportsListTableDataProps} from '../ReportsListTableDataProps'
import {REPORTS_LIST_TABLE_CONSTS} from '../ReportsListTable.consts'

/**
 * Функция генерации pdf-файла
 * @param data данные
 * @param fieldsNames названия полей
 * @param fileName итоговое название файла
 */
export const generatePdfFile = (data: ReportsListTableDataProps[], fieldsNames: any[], fileName: string): any => {
  const headers: any[] = []

  fieldsNames.forEach(fieldName => {
    headers.push({
      text: REPORTS_LIST_TABLE_CONSTS[fieldName],
      style: 'tableHeader',
      alignment: 'center'
    })
  })

  const dataItems = data.map(item => [
    {
      text: item.date,
      style: 'tableCell'
    },
    {
      text: item.time,
      style: 'tableCell'
    },
    {
      text: item.title,
      style: 'tableLink',
      link: item.canonicalUrl,
      decoration: 'underline'
    },
    {
      text: item.seoTitle,
      style: 'tableCell'
    },
    {
      text: item.rubricTitle + `${item.subrubricTitle ? `, ${item.subrubricTitle}` : ''}`,
      style: 'tableCell'
    },
    {
      text: item.authors.join('\n'),
      style: 'tableCell'
    },
    {
      text: (item.viewsCount || 0).toString(),
      style: 'tableCell'
    },
    {
      text: (item.uniqueUsersCount || 0).toString(),
      style: 'tableCell'
    },
    {
      text: (item.socialVisits || 0).toString(),
      style: 'tableCell'
    },
    {
      text: (item.percentSocial || 0).toString(),
      style: 'tableCell'
    },
    {
      text: (item.withoutRecommendedSystems || 0).toString(),
      style: 'tableCell'
    }
  ])

  const docDefinition = {
    pageSize: 'A3',
    pageOrientation: 'landscape',
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [15, 20, 15, 20],
    content: [
      {
        style: 'tableExample',
        table: {
          widths: [65, 40, 180, 180, 150, 120, 72, 72, 60, 60, 70],
          headerRows: 1,
          body: [headers, ...dataItems]
        }
      }
    ],
    styles: {
      tableExample: {
        margin: [0, 0, 0, 0]
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: '#222222'
      },
      tableCell: {
        bold: false,
        fontSize: 10,
        color: '#222222',
        margin: [2, 8, 2, 8]
      },
      tableLink: {
        bold: false,
        fontSize: 10,
        color: '#1964e7',
        margin: [2, 8, 2, 8]
      }
    }
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs
  pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`)
}
