import {createContext} from 'react'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import {CacheFigureContextType} from './CacheFigureContextType'

/**
 * Тип для работы с контекстом нескольких фигур
 */
export const FiguresCacheContext = createContext<CacheFigureContextType>({
  /**
   * Кеш по фигурам
   */
  figuresCache: [] as ImageBlotProps[],
  /**
   * Коллбек для установки значения кеша по фигурам
   */
  setFiguresCache: () => ({})
})
