import {Quill} from 'react-quill'
import ImageModalStore from '@stores/publicationStore/modals/imageModalStore/imageModalStore'
import rootStore from '@stores/rootStore'
import AnnounceImageModalStore from '@stores/publicationStore/modals/announceImageModalStore/announceImageModalStore'
import {ImageBlotProps} from './ImageBlotProps'

const Embed = Quill.import('blots/block/embed')

// type ImageBlotProps = {
//   image: ImageBlot
//   imageModalStore: ImageModalStore
// }

/**
 * @class Класс для работы с изображениями
 */
export default class ImageBlot extends Embed {
  imageModalStore: ImageModalStore | null = null
  announceImageModalStore: AnnounceImageModalStore | null = null
  /**
   * Конструктор создания изображения
   * @constructor
   * @param node
   */
  constructor(node: HTMLElement) {
    super(node)
  }

  static create(image: ImageBlotProps): HTMLElement {
    const node = super.create()
    const {publicationStore} = rootStore
    const {imageModalStore, announceImageModalStore} = publicationStore

    const {
      id,
      src,
      cropperPreviewUrl,
      crop,
      figureId,
      figureUid,
      figureVersionId,
      isFullWidth,
      // ogTitle,
      // ogDescription,
      author,
      description,
      alt,
      isoverviewblock,
      isannouncingphoto,
      hasWatermark,
      hasDiagonalWatermark
    } = image

    const figcaptionElement = document.createElement('figcaption')
    const imageElement = document.createElement('img')
    const descriptionElement = document.createElement('div')
    const authorElement = document.createElement('div')

    if (description) {
      descriptionElement.classList.add('image-description')
      descriptionElement.innerText = description
      figcaptionElement.append(descriptionElement)
    }
    if (author && !isoverviewblock && ((isannouncingphoto && src) || !isannouncingphoto)) {
      authorElement.classList.add('image-author')
      authorElement.innerText = `Фото: ${author}`
      figcaptionElement.append(authorElement)
    }
    if (src && !isannouncingphoto) {
      const deletePhoto = document.createElement('img')
      deletePhoto.setAttribute('src', '/deleteItem.jpg')
      deletePhoto.classList.add('delete-item')
      deletePhoto.setAttribute('draggable', 'false')
      node.append(deletePhoto)

      const deletePhotoEvent = new CustomEvent('deleteImageEvent', {
        detail: {id},
        bubbles: true
      })
      deletePhoto.addEventListener('click', () => {
        return window.dispatchEvent(deletePhotoEvent)
      })

      const changePhoto = document.createElement('img')
      changePhoto.setAttribute('src', '/changeItem.jpg')
      changePhoto.classList.add('change-item')
      changePhoto.setAttribute('draggable', 'false')
      node.append(changePhoto)

      changePhoto.addEventListener('click', () => {
        imageModalStore.setImageDetail(image)
        imageModalStore.setIsOpen(true)
      })
      imageElement.addEventListener('click', () => {
        imageModalStore.setImageDetail(image)
        imageModalStore.setIsOpen(true)
      })
    }
    if (isannouncingphoto) {
      if (image.src) {
        const changeAnnouncePhoto = document.createElement('img')
        changeAnnouncePhoto.setAttribute('src', '/changeItem.jpg')
        changeAnnouncePhoto.classList.add('change-item')
        changeAnnouncePhoto.setAttribute('draggable', 'false')
        node.append(changeAnnouncePhoto)

        changeAnnouncePhoto.addEventListener('click', () => {
          announceImageModalStore.setImageDetail(image)
          announceImageModalStore.setIsOpen(true)
        })
        imageElement.addEventListener('click', () => {
          announceImageModalStore.setImageDetail(image)
          announceImageModalStore.setIsOpen(true)
        })
      } else {
        imageElement.addEventListener('click', () => {
          announceImageModalStore.setImageDetail(image)
          announceImageModalStore.setIsOpen(true)
        })
      }
    }
    imageElement.setAttribute('src', src || '/noPhoto.jpg')
    imageElement.setAttribute('draggable', 'false')
    imageElement.alt = alt || ''
    imageElement.title = 'Нажмите для редактирования фото'
    node.setAttribute('id', id)
    node.setAttribute('cropperPreviewUrl', cropperPreviewUrl)
    node.setAttribute('cropLeft', crop?.left || 0)
    node.setAttribute('cropTop', crop?.top || 0)
    node.setAttribute('cropWidth', crop?.width || 0)
    node.setAttribute('cropHeight', crop?.height || 0)
    node.setAttribute('figureId', figureId)
    node.setAttribute('figureUid', figureUid)
    node.setAttribute('figureVersionId', figureVersionId)
    node.setAttribute('src', src || '/noPhoto.jpg')
    node.setAttribute('isfullwidth', isFullWidth)
    // node.setAttribute('ogTitle', ogTitle)
    // node.setAttribute('ogDescription', ogDescription)
    node.setAttribute('isannouncingphoto', isannouncingphoto)
    node.setAttribute('isoverviewblock', isoverviewblock)
    node.setAttribute('hasWatermark', hasWatermark || false)
    node.setAttribute('hasDiagonalWatermark', hasDiagonalWatermark || false)
    node.setAttribute('alt', alt || '')
    node.setAttribute('author', author || '')
    node.setAttribute('description', description || '')
    node.setAttribute('contenteditable', 'false')
    node.append(imageElement, figcaptionElement)

    return node
  }

  static value(node: HTMLElement): ImageBlotProps {
    return {
      alt: node.getAttribute('alt') || '',
      id: node.getAttribute('id') || '',
      cropperPreviewUrl: node.getAttribute('cropperPreviewUrl') || '',
      crop: {
        left: parseInt(node.getAttribute('cropLeft') || '0'),
        top: parseInt(node.getAttribute('cropTop') || '0'),
        width: parseInt(node.getAttribute('cropWidth') || '0'),
        height: parseInt(node.getAttribute('cropHeight') || '0')
      },
      figureId: node.getAttribute('figureId') || '',
      figureUid: node.getAttribute('figureUid') || '',
      figureVersionId: node.getAttribute('figureVersionId') || '',
      isFullWidth: node.getAttribute('isFullWidth') === 'true',
      // ogTitle: node.getAttribute('ogtitle') || '',
      // ogDescription: node.getAttribute('ogdescription') || '',
      src: node.getAttribute('src') || '',
      description: node.getAttribute('description') || '',
      author: node.getAttribute('author') || '',
      isannouncingphoto: node.getAttribute('isannouncingphoto') === 'true',
      isoverviewblock: node.getAttribute('isoverviewblock') === 'true',
      hasWatermark: node.getAttribute('hasWatermark') === 'true',
      hasDiagonalWatermark: node.getAttribute('hasDiagonalWatermark') === 'true'
    }
  }
}

ImageBlot.tagName = 'figure'
ImageBlot.className = 'image'
ImageBlot.blotName = 'custom-image'
