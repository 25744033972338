import {fetchInstanseWithoutError, handleRequest} from './ky-client'
import {
  BackRequest,
  GenerateRequest,
  InoagentsRequest,
  KeywordsRequest,
  PersonResponse,
  PersonsRequest,
  RewriteRequest
} from './NnAPI.types'
//TODO Типизация ответов
export const fetchAutoKeywords = async (data: KeywordsRequest): Promise<string> => {
  return await fetchInstanseWithoutError
    .post<string>('keywords/', {
      json: data,
      signal: data.signal
    })
    .text()
}

export const fetchPersons = async ({data, signal}: PersonsRequest): Promise<PersonResponse> => {
  return await fetchInstanseWithoutError
    .post('person/', {
      json: data,
      signal: signal
    })
    .json()
}

// Запрос для проверки агентов
export const fetchCheckInoagents = async (data: InoagentsRequest) => {
  return await handleRequest(`ner/`, {
    method: 'POST',
    json: data
  })
}
// Запрос на генерацию бека
export const fetchGenerateBack = async (data: BackRequest) => {
  return await handleRequest('back/', {
    method: 'POST',
    json: {params: JSON.stringify(data)}
  })
}
// Запрос на генерацию текста по теме
export const fetchGenerateText = async (data: GenerateRequest) => {
  return await handleRequest('generate/', {
    method: 'POST',
    json: {params: JSON.stringify(data)}
  })
}

// Запрос на переписывание текста
export const fetchRewriteText = async (data: RewriteRequest) => {
  return await handleRequest('rewrite/', {
    method: 'POST',
    json: {params: JSON.stringify(data)}
  })
}

// Запрос для рерайта текста
export const fetchRewordText = async (data: RewriteRequest) => {
  return await handleRequest('test/', {
    method: 'POST',
    json: {params: JSON.stringify(data)}
  })
}
