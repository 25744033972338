import AgentCheckStore from './agentCheckStore/agentCheckStore'
import CropperStore from './cropperStore/cropperStore'
import EditorStore from './editorStore/editorStore'
import PublicationStore from './publicationStore/publicationStore'
import RegionsWithOverviewBlocksStore from './regionsWithOverviewBlocksStore/regionsWithOverviewBlocksStore'
import ReportsListStore from './reportsListStore/reportsListStore'
import TopNewsStore from './topNewsStore/topNewsStore'

export class RootStore {
  cropperStore: CropperStore
  editorStore: EditorStore
  publicationStore: PublicationStore
  regionsWithOverviewBlocksStore: RegionsWithOverviewBlocksStore
  reportsListStore: ReportsListStore
  topNewsStore: TopNewsStore
  agentCheckStore: AgentCheckStore

  constructor() {
    this.cropperStore = new CropperStore()
    this.editorStore = new EditorStore()
    this.publicationStore = new PublicationStore(this)
    this.regionsWithOverviewBlocksStore = new RegionsWithOverviewBlocksStore()
    this.reportsListStore = new ReportsListStore()
    this.topNewsStore = new TopNewsStore()
    this.agentCheckStore = new AgentCheckStore(this)
  }
}

const rootStore = new RootStore()

export default rootStore
