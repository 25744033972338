import {observer} from 'mobx-react-lite'
import {SnackbarCloseReason} from '@mui/material'
import {useFormContext, useWatch} from 'react-hook-form'
import {WarningAmber} from '@mui/icons-material'
import {AgentCheckService} from '@service/index'
import {useStore} from '@stores/rootStoreContext'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {DoNotDistrubIcon} from '@components/UI/Icons/DoNotDistrub'
import ListInoagent from '@components/UI/ListInoagent/ListInoagent'
import {AGENT_CHECK_NOTIFICATION} from './AgentCheckNotification.const'
import {
  ButtonContent,
  LeftBlockWrapper,
  StyledCancelButton,
  StyledSnackbar,
  StyledSubmitButton,
  StyledUl,
  Title,
  TitleButton,
  WrapperContainer,
  WrapperContent
} from './AgentCheckNotification.styles'
/**
 * Компонент AgentCheckNotification
 * Предназначен для отображения уведомлений о проверке иноагентов и экстремистов и их тезок
 */
const AgentCheckNotification = observer(() => {
  const {agentCheckStore, publicationStore, editorStore} = useStore()
  const {listInoagent, isSameNames, isError, isOpen, isDisabledModalButton} = agentCheckStore
  const {setPublicationWithPrevState, publication} = publicationStore
  const {editorRef} = editorStore
  const {control} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})
  const keysInoagent = Object.keys(listInoagent).length

  const handleConfirm = () => {
    const agentCheckService = new AgentCheckService({
      publicationStore,
      agentCheckStore,
      setPublicationWithPrevState
    })
    agentCheckService.proceedAction(agentCheckStore.actionType, fields as PublicationInputType, editorRef, publication)
  }

  const handleCancel = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway' || reason === 'escapeKeyDown') {
      return
    }
    agentCheckStore.setSave(false)
    agentCheckStore.onCancel()
  }

  const handleCancelSameNames = () => {
    agentCheckStore.setSameNames(false)
  }

  if (keysInoagent === 0 && !isError) {
    return null
  }

  const componentWarningsWithError = (
    <WrapperContainer>
      <StyledSnackbar
        open={isError}
        onClose={handleCancel}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        message={
          <WrapperContent>
            <LeftBlockWrapper>
              <WarningAmber fontSize='medium' />
            </LeftBlockWrapper>
            <div>
              <Title>{AGENT_CHECK_NOTIFICATION.errorTitle}</Title>
            </div>
          </WrapperContent>
        }
        action={
          <>
            <ButtonContent>
              <StyledSubmitButton onClick={handleConfirm} disabled={!agentCheckStore.isError || isDisabledModalButton}>
                {agentCheckStore.actionType}
              </StyledSubmitButton>
              <StyledCancelButton onClick={handleCancel} disabled={!agentCheckStore.isError}>
                Отменить
              </StyledCancelButton>
            </ButtonContent>
          </>
        }
      />
    </WrapperContainer>
  )

  if (isError) {
    return componentWarningsWithError
  }

  const hasSameNames = (listInoagent.SAMENAMES?.length || 0) > 0
  const hasWarnings = listInoagent.INOAGENT.length > 0 || listInoagent.EXTREMIST.length > 0

  const componentSameNames =
    isSameNames && hasSameNames ? (
      <ListInoagent listInoagent={listInoagent} isWarning={true} handleCancel={handleCancelSameNames} />
    ) : (
      <></>
    )

  const componentWarnings = hasWarnings ? (
    <StyledSnackbar
      open={isOpen}
      onClose={handleCancel}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      message={
        <WrapperContent>
          <LeftBlockWrapper>
            <DoNotDistrubIcon fontSize='medium' />
          </LeftBlockWrapper>
          <div>
            <Title>
              {AGENT_CHECK_NOTIFICATION.snackbarTitle}{' '}
              {listInoagent.INOAGENT.length !== 0
                ? AGENT_CHECK_NOTIFICATION.titleInoagent
                : AGENT_CHECK_NOTIFICATION.titleExtremist}
            </Title>
            <StyledUl>
              {listInoagent.INOAGENT.length !== 0 && (
                <>
                  {listInoagent.INOAGENT.map((inoagent, index) => (
                    <li key={inoagent + index}>{inoagent}</li>
                  ))}
                </>
              )}
              {listInoagent.EXTREMIST.length !== 0 && (
                <>
                  {listInoagent.INOAGENT.length !== 0 && <Title>{AGENT_CHECK_NOTIFICATION.titleExtremist}</Title>}
                  {listInoagent.EXTREMIST.map((extremist, index) => (
                    <li key={extremist + index}>{extremist}</li>
                  ))}
                </>
              )}
            </StyledUl>
          </div>
        </WrapperContent>
      }
      action={
        <>
          <TitleButton>{AGENT_CHECK_NOTIFICATION.alertTitle(agentCheckStore.actionType)}</TitleButton>
          <ButtonContent>
            <StyledSubmitButton onClick={handleConfirm} disabled={!agentCheckStore.isOpen || isDisabledModalButton}>
              {agentCheckStore.actionType}
            </StyledSubmitButton>
            <StyledCancelButton onClick={handleCancel} disabled={!agentCheckStore.isOpen}>
              Отменить
            </StyledCancelButton>
          </ButtonContent>
        </>
      }
    />
  ) : (
    <></>
  )

  return (
    <WrapperContainer>
      {componentSameNames}
      {componentWarnings}
      {componentWarningsWithError}
    </WrapperContainer>
  )
})

export default AgentCheckNotification
