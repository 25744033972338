import React, {FC} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import StaffList from '@components/Staff/List/StaffList'

const StaffPage: FC = () => {
  return (
    <PageContainer>
      <StaffList />
    </PageContainer>
  )
}

export default StaffPage
