import React, {FC} from 'react'
import {Box, Grid, IconButton, Typography} from '@mui/material'
import DeleteForever from '@mui/icons-material/DeleteForever'
import styled from 'styled-components'
import {dividerColor} from '../../../../@theme/vars'
import {Publication} from '@graphql/types'
import TitleLink from '../../styled/TitleLink'

const ItemWrapper = styled.div`
  padding: 15px 5px 10px;
  border-bottom: 1px solid ${dividerColor};
`

const StyledRegions = styled(Typography)`
  padding-top: 10px;
`

type RelatedPublicationItemProps = {
  publication: Publication
  onRemove: (uid: string) => void
}

const RelatedPublicationItem: FC<RelatedPublicationItemProps> = ({publication, onRemove}) => {
  return (
    <ItemWrapper>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid container item direction='column' xs={8}>
          <TitleLink to={`/editor/${publication.uid}`}>
            <Typography variant='h2'>{publication.title || 'Без названия'}</Typography>
          </TitleLink>
          <StyledRegions variant='subtitle1'>
            {publication.regions?.map((region, ind) => (
              <span key={region?.uid}>
                {ind > 0 && <span> • </span>}
                <span>{region?.title}</span>
              </span>
            ))}
          </StyledRegions>
        </Grid>
        <Grid item xs={1}>
          <Box textAlign='center'>
            <IconButton size='small' onClick={() => onRemove(publication.uid)}>
              <DeleteForever color='disabled' />
            </IconButton>
          </Box>
        </Grid>
        <Grid container item direction='column' xs={2}>
          <Box textAlign='right'>
            {publication?.authors?.map(author => (
              <Typography key={publication.uid + author.uid}>
                {author.firstName} {author.lastName}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </ItemWrapper>
  )
}

export default RelatedPublicationItem
