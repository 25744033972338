import {makeAutoObservable} from 'mobx'
import {TopNewsBlock} from '@graphql/types'
import ITopNewsStore from './ITopNewsStore'

class TopNewsStore implements ITopNewsStore {
  addingBlocksCache: Map<string, TopNewsBlock> = new Map<string, TopNewsBlock>()
  deletingBlocksCache: Set<string> = new Set<string>()

  topNewsBlocks: TopNewsBlock[] = []
  topNewsBlocksUids: string[] = []
  confirmSaveModalOpen = false

  constructor() {
    makeAutoObservable(this)
  }

  setTopNewsBlocks = (val: TopNewsBlock[]): void => {
    this.topNewsBlocks = val
  }

  setTopNewsBlocksUids = (val: string[]): void => {
    this.topNewsBlocksUids = val
  }

  resetCache = (): void => {
    this.addingBlocksCache.clear()
    this.deletingBlocksCache.clear()
  }

  setConfirmSaveModalOpen = (val: boolean): void => {
    this.confirmSaveModalOpen = val
  }
}

export default TopNewsStore
