import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'

export const ImportantFilledIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        d='M25.673 15.2928L25.6533 15.5068L25.7949 15.6684L27.835 17.9952L25.7949 20.3221L25.6533 20.4837L25.673 20.6976L25.9573 23.7778L22.9387 24.4642L22.7293 24.5118L22.6196 24.6965L21.0396 27.3577L18.1975 26.136L17.9993 26.0508L17.8014 26.1365L14.9598 27.3663L13.3804 24.706L13.2707 24.5214L13.0613 24.4737L10.0426 23.7873L10.327 20.6975L10.3467 20.4836L10.2051 20.3221L8.16445 17.9946L10.2056 15.6582L10.3467 15.4967L10.327 15.2832L10.0428 12.2043L13.06 11.5265L13.2703 11.4793L13.3804 11.294L14.9604 8.63272L17.8025 9.85448L18 9.93937L18.1975 9.85448L21.0396 8.63272L22.6196 11.294L22.7293 11.4786L22.9387 11.5263L25.9573 12.2127L25.673 15.2928ZM24.5536 23.1651L24.9817 23.0682L24.941 22.6312L24.7128 20.1792L26.337 18.3247L26.6256 17.9952L26.337 17.6658L24.7128 15.8113L24.941 13.3593L24.9817 12.9223L24.5536 12.8253L22.1481 12.2804L20.8928 10.1626L20.6687 9.78447L20.2649 9.95836L17.9991 10.9342L15.7335 9.96727L15.3306 9.79527L15.1072 10.1722L13.8524 12.2891L11.4481 12.8249L11.018 12.9208L11.059 13.3595L11.2872 15.8025L9.66219 17.6667L9.37443 17.9968L9.66374 18.3255L11.2872 20.1704L11.059 22.6313L11.0184 23.0682L11.4464 23.1651L13.8519 23.71L15.1072 25.8278L15.3313 26.206L15.7351 26.0321L18 25.0566L20.2649 26.0321L20.6687 26.206L20.8928 25.8278L22.1481 23.71L24.5536 23.1651ZM18.4545 21.3619V22.2731H17.5455V21.3619H18.4545ZM18.4545 13.7174V18.4508H17.5455V13.7174H18.4545Z'
        fill='#1964E7'
        stroke='#1964E7'
      />
    </svg>
  )
}

export const ImportantOutlinedIcon: FC<SvgIconProps> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        d='M25.673 15.2928L25.6533 15.5068L25.7949 15.6684L27.835 17.9952L25.7949 20.3221L25.6533 20.4837L25.673 20.6976L25.9573 23.7778L22.9387 24.4642L22.7293 24.5118L22.6196 24.6965L21.0396 27.3577L18.1975 26.136L17.9993 26.0508L17.8014 26.1365L14.9598 27.3663L13.3804 24.706L13.2707 24.5214L13.0613 24.4737L10.0426 23.7873L10.327 20.6975L10.3467 20.4836L10.2051 20.3221L8.16445 17.9946L10.2056 15.6582L10.3467 15.4967L10.327 15.2832L10.0428 12.2043L13.06 11.5265L13.2703 11.4793L13.3804 11.294L14.9604 8.63272L17.8025 9.85448L18 9.93937L18.1975 9.85448L21.0396 8.63272L22.6196 11.294L22.7293 11.4786L22.9387 11.5263L25.9573 12.2127L25.673 15.2928ZM24.5536 23.1651L24.9817 23.0682L24.941 22.6312L24.7128 20.1792L26.337 18.3247L26.6256 17.9952L26.337 17.6658L24.7128 15.8113L24.941 13.3593L24.9817 12.9223L24.5536 12.8253L22.1481 12.2804L20.8928 10.1626L20.6687 9.78447L20.2649 9.95836L17.9991 10.9342L15.7335 9.96727L15.3306 9.79527L15.1072 10.1722L13.8524 12.2891L11.4481 12.8249L11.018 12.9208L11.059 13.3595L11.2872 15.8025L9.66219 17.6667L9.37443 17.9968L9.66374 18.3255L11.2872 20.1704L11.059 22.6313L11.0184 23.0682L11.4464 23.1651L13.8519 23.71L15.1072 25.8278L15.3313 26.206L15.7351 26.0321L18 25.0566L20.2649 26.0321L20.6687 26.206L20.8928 25.8278L22.1481 23.71L24.5536 23.1651ZM18.4545 21.3619V22.2731H17.5455V21.3619H18.4545ZM18.4545 13.7174V18.4508H17.5455V13.7174H18.4545Z'
        fill='#A9A9A9'
        stroke='#A9A9A9'
      />
    </svg>
  )
}
