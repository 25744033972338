import * as Types from '../../../@graphql/types'

import {PublicationEdgeCollectionFragment} from '../../Collections/gql/Collections.generated'
import {gql} from '@apollo/client'
import {PublicationEdgeCollectionFragmentDoc} from '../../Collections/gql/Collections.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type StoryQueryVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type StoryQuery = {__typename?: 'Query'} & {story?: Types.Maybe<{__typename?: 'Story'} & StoryFieldsFragment>}

export type StoryFieldsFragment = {__typename?: 'Story'} & Pick<
  Types.Story,
  'createdAt' | 'description' | 'id' | 'isActive' | 'title' | 'uid' | 'updatedAt'
> & {
    figure?: Types.Maybe<{__typename?: 'Figure'} & FigureFragment>
    publications?: Types.Maybe<
      {__typename?: 'PublicationsConnection'} & {
        edges: Array<Types.Maybe<{__typename?: 'PublicationsEdge'} & PublicationEdgeCollectionFragment>>
      }
    >
  }

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<Types.FigureType, 'id' | 'uid' | 'slug'>
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
        crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
        primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        processedImages: Array<{__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>>
      }
  }

export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const StoryFieldsFragmentDoc = gql`
  fragment StoryFields on Story {
    createdAt
    description
    id
    isActive
    title
    uid
    updatedAt
    figure {
      ...Figure
    }
    publications {
      edges {
        ...PublicationEdgeCollection
      }
    }
  }
  ${FigureFragmentDoc}
  ${PublicationEdgeCollectionFragmentDoc}
`
export const StoryDocument = gql`
  query Story($uid: UUID!) {
    story(uid: $uid) {
      ...StoryFields
    }
  }
  ${StoryFieldsFragmentDoc}
`

/**
 * __useStoryQuery__
 *
 * To run a query within a React component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useStoryQuery(baseOptions: Apollo.QueryHookOptions<StoryQuery, StoryQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<StoryQuery, StoryQueryVariables>(StoryDocument, options)
}
export function useStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryQuery, StoryQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<StoryQuery, StoryQueryVariables>(StoryDocument, options)
}
export type StoryQueryHookResult = ReturnType<typeof useStoryQuery>
export type StoryLazyQueryHookResult = ReturnType<typeof useStoryLazyQuery>
export type StoryQueryResult = Apollo.QueryResult<StoryQuery, StoryQueryVariables>
