import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {Box, IconButton} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {Clear} from '@mui/icons-material'
import AuthorPicker from '@components/UI/AuthorPicker/AuthorPicker'
import {useFormContext} from 'react-hook-form'
import {Author} from '@graphql/types'
import useDeviceDetect from '@utils/useDeviceDetect'
import {StyledBox} from './AuthorsSelect.styles'

const AuthorsSelect: FC = () => {
  const {publicationStore} = useStore()
  const {publication} = publicationStore
  const {isMobile} = useDeviceDetect()
  const {setValue, getValues} = useFormContext()
  const [fieldWidth, setFieldWidth] = useState('578px')
  const [fullFieldWidth, setFullFieldWidth] = useState('620px')

  const [numberAuthors, setNumberAuthors] = useState(publication?.authors?.length)

  const [firstAuthor, setFirstAuthor] = useState<Author | null>(
    publication?.authors?.length ? publication?.authors[0] : null
  )
  const [secondAuthor, setSecondAuthor] = useState<Author | null>(publication?.authors[1])

  const handleChangeFirstAuthor = useCallback((author: Author) => {
    setFirstAuthor(author)
  }, [])

  const handleChangeSecondAuthor = useCallback((author: Author) => {
    setSecondAuthor(author)
  }, [])

  useEffect(() => {
    const authorsUids: string[] = []

    if (firstAuthor?.uid) {
      authorsUids.push(firstAuthor?.uid)
    }
    if (secondAuthor?.uid) {
      authorsUids.push(secondAuthor?.uid)
    }

    setValue && setValue('authors', authorsUids)
  }, [firstAuthor, secondAuthor, setValue])

  useEffect(() => {
    setFieldWidth(isMobile ? 'calc(100% - 42px)' : '578px')
    setFullFieldWidth(isMobile ? '100%' : '620px')
  }, [isMobile])

  const handleRemoveSecondAuthor = useCallback(() => {
    setSecondAuthor(null)
    setNumberAuthors(1)
  }, [])

  const handleRemoveFirstAuthor = useCallback(() => {
    setFirstAuthor(Object.create(secondAuthor))
    setSecondAuthor(null)
    setNumberAuthors(1)
  }, [secondAuthor])

  return (
    <Box width={fullFieldWidth}>
      <StyledBox>
        <AuthorPicker
          author={firstAuthor}
          handleChangeAuthor={handleChangeFirstAuthor}
          selectedAuthorUid={secondAuthor?.uid}
          disabled={getValues('cantEditPublication')}
          width={fieldWidth}
        />

        <Box>
          {numberAuthors < 2 ? (
            <IconButton
              size='small'
              style={{
                padding: '3px',
                marginRight: '-6px'
              }}
              onClick={() => setNumberAuthors(2)}
              disabled={getValues('cantEditPublication')}
            >
              <AddCircleOutlineIcon
                style={{
                  transform: 'scale(0.8)',
                  color: '#1964E7'
                }}
              />
            </IconButton>
          ) : (
            <Box marginRight='5px'>
              <IconButton size='small' onClick={handleRemoveFirstAuthor} disabled={getValues('cantEditPublication')}>
                <Clear fontSize='small' />
              </IconButton>
            </Box>
          )}
        </Box>
      </StyledBox>
      {numberAuthors === 2 && (
        <StyledBox>
          <AuthorPicker
            author={secondAuthor}
            handleChangeAuthor={handleChangeSecondAuthor}
            selectedAuthorUid={firstAuthor?.uid}
            disabled={getValues('cantEditPublication')}
            width={fieldWidth}
          />
          <Box marginRight='5px'>
            <IconButton size='small' onClick={handleRemoveSecondAuthor} disabled={getValues('cantEditPublication')}>
              <Clear fontSize='small' />
            </IconButton>
          </Box>
        </StyledBox>
      )}
    </Box>
  )
}

export default observer(AuthorsSelect)
