import React, {FC, useCallback, useState} from 'react'
import styled from 'styled-components'
import {lightgrayColor} from '../../../../@theme/vars'
import {Box, IconButton} from '@mui/material'
import {useDayThemeQuery, useStoriesDayThemeQuery, useUpdateDayThemeMutation} from './gql/DayTheme.generated'
import {AssignmentOutlined, Edit} from '@mui/icons-material'
import {useHistory} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import CustomAutocomplete from '../../../UI/CustomAutocomplete/CustomAutocomplete'
import {Story} from '@graphql/types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 292px;
  height: 870px;
  border: 1px ${lightgrayColor} solid;
  border-radius: 4px;
  padding: 25px 15px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
`

const Image = styled.div<{imageUrl: string}>`
  width: 260px;
  height: 340px;
  border-radius: 4px;
  background-image: url('${({imageUrl}) => imageUrl}');
  background-color: ${lightgrayColor};
  margin-bottom: 8px;
  background-position: center;
`

const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`

const Plug = styled(Box)`
  width: 260px;
  height: 74px;
  background-color: ${lightgrayColor};
  border-radius: 4px;
  font-size: 14px;
  padding: 9px 0 0 13px;
`
type DayThemeProp = {
  regionId: string | undefined
}

const DayTheme: FC<DayThemeProp> = ({regionId}) => {
  const [selectedStory, setSelectedStory] = useState<Story>()
  const {data, refetch, loading, fetchMore} = useStoriesDayThemeQuery()

  const dayTheme = useDayThemeQuery({
    variables: {regionUid: regionId},
    onCompleted: data => setSelectedStory(data.dayTheme as Story)
  })

  const [updateDayTheme] = useUpdateDayThemeMutation()

  const history = useHistory()
  const snackbar = useSnackbar()

  const handleClickEdit = useCallback(() => {
    history.push(`/story/editor/${selectedStory?.uid}`)
  }, [history, selectedStory])

  const onLoadMore = useCallback(async () => {
    if (data?.stories?.pageInfo.hasNextPage)
      await fetchMore({
        variables: {
          first: 10,
          after: data?.stories?.pageInfo.endCursor
        }
      })
  }, [data?.stories?.pageInfo.endCursor, data?.stories?.pageInfo.hasNextPage, fetchMore])

  const handleChangeStory = useCallback(
    async (story: Story) => {
      try {
        await updateDayTheme({
          variables: {
            storyUid: story?.uid || null,
            regionUid: regionId
          },
          refetchQueries: ['dayTheme']
        })
        setSelectedStory(story)
      } catch (e) {
        console.error(e)
      }
    },
    [regionId, snackbar, updateDayTheme]
  )

  const onRefetch = useCallback(
    (search: string | null) => {
      void refetch({search: search})
    },
    [refetch]
  )

  return (
    <Wrapper>
      <CustomAutocomplete
        label='Сюжеты'
        options={data?.stories?.edges.map(edge => edge?.node) || []}
        value={selectedStory}
        refetch={onRefetch}
        fetchMore={onLoadMore}
        loading={loading}
        onChange={handleChangeStory}
        width='260px'
      />

      {dayTheme.data?.dayTheme ? (
        <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
          <Box marginTop='20px'>
            <Image imageUrl={dayTheme?.data?.dayTheme?.figure?.latestVersion.primaryProcessedImage?.url || ''} />
            <Title>{dayTheme?.data?.dayTheme?.title}</Title>

            <Plug marginTop='15px'>Новость из сюжета</Plug>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='end'>
            <Plug marginBottom='10px'>Другие сюжеты</Plug>
            <Plug marginBottom='10px'>Другие сюжеты</Plug>
            <IconButton onClick={handleClickEdit} size='small'>
              <Edit />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box margin='auto' textAlign='center' fontSize='24px' color={lightgrayColor}>
          <AssignmentOutlined fontSize='large' />
          <p>Выберите тему дня</p>
        </Box>
      )}
    </Wrapper>
  )
}

export default DayTheme
