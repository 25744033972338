export const STORY_FORM_CONSTS = {
  figure: {
    id: '',
    uid: '',
    src: '',
    alt: '',
    author: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    hasWatermark: false,
    hasDiagonalWatermark: false,
    type: {
      id: '',
      uid: '',
      slug: '',
      __typename: 'FigureType'
    },
    cropperPreview: {
      url: '',
      __typename: 'Image'
    },
    latestVersion: {
      id: '',
      uid: '',
      crop: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        __typename: 'Crop'
      },
      primaryProcessedImage: {
        url: '',
        __typename: 'Image'
      },
      processedImages: [
        {
          code: '',
          url: '',
          __typename: 'Image'
        }
      ],
      __typename: 'FigureVersion'
    },
    sourceImage: {
      url: '',
      __typename: 'Image'
    },
    __typename: 'Figure'
  }
}
