import {Quill} from 'react-quill'
import {ElementUnionInput, GalleryImageInput} from '@graphql/types'
import {GalleryBlot, Html, ImageBlot, Video} from '../../Blots'
import {PublicationContentElement} from '../../../../types/PublicationContentElement'
import ElementTypeNames from '../../../../types/ElementTypeNames'
import type {IAdaptQuillRequest, IAdaptResponseToDeltaOps} from './QuillEditor.types'
import type {DeltaOperation} from 'quill'

const Delta = Quill.import('delta')

const adaptQuillResponse: IAdaptResponseToDeltaOps = ({elements, quill}): DeltaOperation[] =>
  elements.reduce<DeltaOperation[]>((result, element: PublicationContentElement) => {
    if (element.__typename === ElementTypeNames.ImageElement) {
      result.push({
        insert: {
          [ImageBlot.blotName]: {
            id: element.figureVersion?.figure?.id,
            src: element.figureVersion?.primaryProcessedImage?.url || '',
            cropperPreviewUrl: element.figureVersion?.figure?.cropperPreview?.url || '',
            crop: {
              left: element.figureVersion?.crop?.left || 0,
              top: element.figureVersion?.crop?.top || 0,
              width: element.figureVersion?.crop?.width || 0,
              height: element.figureVersion?.crop?.height || 0
            },
            alt: element.alt,
            author: element.author,
            description: element.description,
            figureVersionId: element.figureVersion?.id,
            figureId: element.figureVersion?.figure.id,
            figureUid: element.figureVersion?.figure.uid,
            isFullWidth: element.isFullWidth,
            hasWatermark: element.figureVersion?.hasWatermark || false,
            hasDiagonalWatermark: element.figureVersion?.hasDiagonalWatermark || false
          }
        }
      })
    }

    if (element.__typename === ElementTypeNames.GalleryElement) {
      result.push({
        insert: {
          [GalleryBlot.blotName]: {
            id: element.id,
            uid: element.uid,
            galleryImages: element.galleryImages
          }
        }
      })
    }

    if (element.__typename === ElementTypeNames.IFrameElement) {
      result.push({
        insert: {
          [Video.blotName]: {
            ...element
          }
        }
      })
    }

    if (element.__typename === ElementTypeNames.CustomHTMLElement) {
      result.push({
        insert: {
          [Html.blotName]: {
            ...element
          }
        }
      })
    }

    if (!ElementTypeNames.OnlineBroadcastElement) {
      // TODO: Заготовка для онлайн-трансляции
    }

    if (element.__typename === ElementTypeNames.TextElement) {
      let textContent = element.content

      // замена необходима для корректного отображения скрытого текста в RSS
      if (textContent.includes('yandex-rss-hidden')) {
        textContent = textContent.replace(/\<p class=\"yandex\-rss\-hidden\"\>/, '<textrss class="yandex-rss-hidden">')
        textContent = textContent.replace(/\<\/p\>/, '</textrss></p>')
      }

      if (textContent.includes('ql-cursor')) {
        textContent = textContent.replace(/<span.*ql-cursor.*span>/, '')
        textContent = textContent.replace('class="ql-cursor"', '')
      }

      // если в тексте есть некорректные ссылки
      if (textContent.includes('href="true"')) {
        textContent = textContent.replace(/<a[^>]*href="true"[^>]*>.*<\/a>/, '')
      }

      // Желательно удалить
      // А textContent хорошо бы нормально парсить при помощи fast-xml-parser
      const {ops} = quill.clipboard.convert(textContent)

      if (ops === undefined) {
        return result
      }

      const lastOp = ops[ops.length - 1]

      const isParagraph = lastOp?.insert && typeof lastOp.insert === 'string' && textContent.startsWith('<p>')
      const isLineBreak = ops.length === 0

      if (isParagraph) {
        lastOp.insert += '\n'
      }

      if (isLineBreak) {
        result.push({insert: '\n'})
      }

      result.push(...ops)
    }

    return result
  }, [])

const adaptQuillRequest: IAdaptQuillRequest = ({quillChildren}) => {
  if (quillChildren === undefined) {
    return []
  }

  return quillChildren.map((element): ElementUnionInput => {
    if (element.classList.contains('image')) {
      return {
        image: {
          figureVersionId: element.getAttribute('figureVersionId') || null,
          alt: element.getAttribute('alt') || '',
          author: element.getAttribute('author') || '',
          description: element.getAttribute('description') || '',
          isFullWidth: element.getAttribute('isFullWidth') === 'true'
        }
      }
    }

    if (element.classList.contains('html')) {
      return {
        html: {
          html: element.getAttribute('html') ?? ''
        }
      }
    }

    if (element.classList.contains('ql-video')) {
      return {
        iframe: {
          src: element.getAttribute('src') ?? ''
        }
      }
    }
    if (element.classList.contains('gallery')) {
      const galleryImageArr: any[] = JSON.parse(element.getAttribute('galleryImages') || '') || []
      if (galleryImageArr.length > 0) {
        const galleryImage: GalleryImageInput[] = []

        galleryImageArr.forEach(image => {
          galleryImage.push({
            author: image.author,
            description: image.description,
            figureVersionId: image.figureVersion.uid
          })
        })

        return {
          gallery: galleryImage
          // igallery: {
          //   gallery: element.id
          // }
        }
      }
    }

    // TODO: Заготовка для онлайн-трансляции
    // if (!'element.__blot?.blot instanceof OnlineTranslationDividerBlot') {
    //   return {
    //     online: {
    //       text: '',
    //       time: ''
    //     }
    //   }
    // }

    let content = element.outerHTML

    if (element.classList.contains('sideBar')) {
      content = content
        .replace(`<img src="/changeItem.jpg" draggable="false" class="change-item">`, '')
        .replace('<img src="/deleteItem.jpg" draggable="false" class="delete-item">', '')
    }

    // замена необходима для корректного скрытия текста в RSS
    if (content.includes('class="yandex-rss-hidden"')) {
      content = content.replace('<p>', '<p class="yandex-rss-hidden">')
      content = content.replace('<textrss class="yandex-rss-hidden">', '')
      content = content.replace('</textrss>', '')
    }

    if (content.includes('class="ql-cursor"')) {
      content = content.replace(/<span.*ql-cursor.*span>/, '')
      content = content.replace('class="ql-cursor"', '')
    }

    if (content.includes('href="true"')) {
      content = content.replace(/<a[^>]*href="true"[^>]*>.*<\/a>/, '')
    }

    if (element.tagName === 'P' && !element.classList.contains('yandex-rss-hidden')) {
      element.removeAttribute('class')
      element.removeAttribute('style')
    }

    return {
      text: {
        content
      }
    }
  })
}

export {adaptQuillResponse, adaptQuillRequest}
