import {useCallback, useEffect, useState} from 'react'
import {UiCommentsConnection} from '@entities/types/UiCommentsConnection'
import {UiCommentsEdge} from '@entities/types/UiCommentsEdge'
import {UiPageInfo} from '@entities/types/UiPageInfo'
import {CommentStatus} from '@graphql/types'
import {COMMENTS_MODERATION_CONTENT_CONSTS} from './CommentsModerationContent.consts'

/**
 * хук для работы с комментариями
 */
const useCommentsData = (status: CommentStatus) => {
  const [commentEdges, setCommentEdges] = useState<UiCommentsEdge[]>([])
  const [pageInfo, setPageInfo] = useState<UiPageInfo>(COMMENTS_MODERATION_CONTENT_CONSTS.initialPageInfo)
  const [nothingFounded, setNothingFounded] = useState(false)

  const handleCommentsListData = useCallback((data: UiCommentsConnection, appendData = false) => {
    setPageInfo(data.pageInfo)
    setNothingFounded(data.edges.length === 0)

    const newCommentStates =
      data.edges?.map((edge, index) => ({
        index,
        commentUid: edge.node?.uid || '',
        isBad: edge.node?.autoModeratorResult ? edge.node.isBad : undefined,
        viewed: edge.node?.viewed || false
      })) || []

    if (appendData && data.pageInfo.hasPreviousPage) {
      setCommentEdges(prev => [...prev, ...data.edges])
      return newCommentStates
    }

    setCommentEdges(data.edges)
    return newCommentStates
  }, [])

  return {
    commentEdges,
    pageInfo,
    nothingFounded,
    setCommentEdges,
    setPageInfo,
    handleCommentsListData
  }
}

/**
 * хук для работы с фильтрами
 */
const useFilters = () => {
  const [isBad, setIsBad] = useState(false)
  const [findWord, setFindWord] = useState('')
  const [findWordValue, setFindWordValue] = useState('')
  const [selectedSortValue, setSelectedSortValue] = useState(COMMENTS_MODERATION_CONTENT_CONSTS.sortValues[0])

  const handleSortChange = useCallback(val => {
    setSelectedSortValue(val)
  }, [])

  return {
    isBad,
    setIsBad,
    findWord,
    setFindWord,
    findWordValue,
    setFindWordValue,
    selectedSortValue,
    handleSortChange
  }
}

/**
 * хук для работы с высотой таблицы
 */
const useTableHeight = (width: number, status: CommentStatus) => {
  const [height, setHeight] = useState(
    width > COMMENTS_MODERATION_CONTENT_CONSTS.screenSCustom
      ? `calc(100vh - ${status === CommentStatus.Review ? 280 : 230}px)`
      : `calc(100vh - ${status === CommentStatus.Review ? 340 : 290}px)`
  )

  useEffect(() => {
    setHeight(
      width > COMMENTS_MODERATION_CONTENT_CONSTS.screenSCustom
        ? `calc(100vh - ${status === CommentStatus.Review ? 280 : 230}px)`
        : `calc(100vh - ${status === CommentStatus.Review ? 340 : 290}px)`
    )
  }, [width, status])

  return height
}

export {useCommentsData, useFilters, useTableHeight}
