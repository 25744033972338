import styled from 'styled-components'
import {Button, DialogActions} from '@mui/material'

export const StyledDialogActions = styled(DialogActions)`
  //display: flex;
  justify-content: center;
  align-items: stretch;
  padding-top: 0px;

  & button:not(:first-of-type) {
    margin-left: 16px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ShortButton = styled(Button)`
  width: calc(32px * 2.4);
  padding-top: 11px;
  padding-bottom: 11px;

  &.large {
    width: calc(40px * 2.4);
  }

  &.small {
    width: calc(24px * 2.4);
  }

  @media screen and (max-width: 768px) {
    min-width: 164px !important;
  }
`

export const ButtonWrapper = styled(Button)`
  padding-top: 11px;
  padding-bottom: 11px;

  @media screen and (max-width: 768px) {
    min-width: 164px !important;
  }
`
