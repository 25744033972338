export const PUBLICATION_ITEM_CONSTS = {
  addMsg: 'Добавить',
  addPublicationToMainMsg: 'Добавление публикации на главную',
  goToNewsEditingMsg: 'Перейти в редактирование новости',
  isImportantMsg: 'Сделать важной',
  importantMsg: 'Важная',
  notImportantMsg: 'Новость отмечена важной',
  selectCategoriesMsg: 'Выберите категории',
  sendMsg: 'Отправить',
  toYandexNewsMsg: 'Добавить в Дзен Новости',
  inYandexNewsMsg: 'Новость добавлена в Дзен Новости',
  yandexNewsMsg: 'Дзен Новости',
  toYandexDzenMsg: 'Добавить в Дзен Блоги',
  inYandexDzenMsg: 'Новость добавлена в Дзен Блоги',
  yandexDzenMsg: 'Дзен Блоги',
  updatedMsg: 'Обновлено',
  viewOnSiteMsg: 'Посмотреть на сайте',
  withoutNameMsg: 'Без названия',
  checkedByRedactor: 'Проверена редактором',
  unChecked: 'Не проверена редактором'
}
