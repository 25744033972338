import * as Types from '../../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type CreatePublicationDraftMutationVariables = Types.Exact<{
  data: Types.CreatePublicationDraftInput
}>

export type CreatePublicationDraftMutation = {__typename?: 'Mutation'} & {
  createPublicationDraft?: Types.Maybe<
    {__typename?: 'CreatePublicationDraftPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'id' | 'uid'>>
    }
  >
}

export type CreateStoryMutationVariables = Types.Exact<{
  data: Types.CreateStoryInput
}>

export type CreateStoryMutation = {__typename?: 'Mutation'} & {
  createStory?: Types.Maybe<
    {__typename?: 'CreateStoryPayload'} & {
      story?: Types.Maybe<{__typename?: 'Story'} & Pick<Types.Story, 'id' | 'uid'>>
    }
  >
}

export const CreatePublicationDraftDocument = gql`
  mutation createPublicationDraft($data: CreatePublicationDraftInput!) {
    createPublicationDraft(data: $data) {
      publication {
        id
        uid
      }
    }
  }
`
export type CreatePublicationDraftMutationFn = Apollo.MutationFunction<
  CreatePublicationDraftMutation,
  CreatePublicationDraftMutationVariables
>

/**
 * __useCreatePublicationDraftMutation__
 *
 * To run a mutation, you first call `useCreatePublicationDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicationDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicationDraftMutation, { data, loading, error }] = useCreatePublicationDraftMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePublicationDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePublicationDraftMutation, CreatePublicationDraftMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreatePublicationDraftMutation, CreatePublicationDraftMutationVariables>(
    CreatePublicationDraftDocument,
    options
  )
}
export type CreatePublicationDraftMutationHookResult = ReturnType<typeof useCreatePublicationDraftMutation>
export type CreatePublicationDraftMutationResult = Apollo.MutationResult<CreatePublicationDraftMutation>
export type CreatePublicationDraftMutationOptions = Apollo.BaseMutationOptions<
  CreatePublicationDraftMutation,
  CreatePublicationDraftMutationVariables
>
export const CreateStoryDocument = gql`
  mutation createStory($data: CreateStoryInput!) {
    createStory(data: $data) {
      story {
        id
        uid
      }
    }
  }
`
export type CreateStoryMutationFn = Apollo.MutationFunction<CreateStoryMutation, CreateStoryMutationVariables>

/**
 * __useCreateStoryMutation__
 *
 * To run a mutation, you first call `useCreateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryMutation, { data, loading, error }] = useCreateStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStoryMutation, CreateStoryMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateStoryMutation, CreateStoryMutationVariables>(CreateStoryDocument, options)
}
export type CreateStoryMutationHookResult = ReturnType<typeof useCreateStoryMutation>
export type CreateStoryMutationResult = Apollo.MutationResult<CreateStoryMutation>
export type CreateStoryMutationOptions = Apollo.BaseMutationOptions<CreateStoryMutation, CreateStoryMutationVariables>
