import React, {FC} from 'react'
import {CircularProgress} from '@mui/material'
import {ProgressValueWrapper, SpinnerTitleWrapper, SpinnerWrapper} from './Spinner.styles'

export const Spinner: FC<any> = ({title, progress}) => {
  return (
    <SpinnerWrapper>
      <SpinnerTitleWrapper>{title}</SpinnerTitleWrapper>
      <CircularProgress size={68} />
      {progress && <ProgressValueWrapper>{progress}</ProgressValueWrapper>}
    </SpinnerWrapper>
  )
}
