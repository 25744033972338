import React from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import {Typography} from '@mui/material'
import styled from 'styled-components'
import RegionTabs from '@components/UI/RegionTabs/RegionTabs'
import HotNewsContent from '@components/MainSelection/HotNews/HotNewsContent'

const Title = styled(Typography)`
font-family: 'Roboto';
font-size: 20px;
font-weight: 500;
line-height: 24px;
color: #222222;
margin-bottom: 10px;
`

const HotNewsPage: React.FC = () => {
  return (
    <PageContainer>
      <Title variant='h1'>Горячая новость</Title>
      <RegionTabs>
        <HotNewsContent />
      </RegionTabs>
    </PageContainer>
  )
}

export default HotNewsPage
