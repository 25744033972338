import styled from 'styled-components'
import {blackColor} from '../../../@theme/vars'

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 68px;
  align-items: center;
  justify-content: center;
`

export const SpinnerTitleWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 21px;
`

export const ProgressValueWrapper = styled.div`
  position: absolute;
  /* margin-top: 53px; */
  font-size: 18px;
  line-height: 21px;
  color: ${blackColor};
`
