export const TOP_NEWS_CONTENT_CONSTS = {
  approveSaveChangesQuestion: 'Есть несохраненные изменения, сначала сохраните их',
  approveSaveChangesQuestion2: 'Вы действительно хотите уйти со страницы? Внесенные изменения не будут сохранены!',
  blockHistoryMsg: 'История изменений',
  blockWasSavedMsg: 'Блок создан',
  cancelChangesConfirmation: 'Отменить изменения',
  errorMsg: 'Ошибка',
  revertDeleteMsg: 'Отменить удаление',
  saveChangesMsg: 'Сохранить изменения',
  saveMsg: 'Сохранить',
  savedMsg: 'Сохранено',
  warning: 'Внимание!'
}
