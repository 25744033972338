import {observer} from 'mobx-react-lite'
import {FC, useCallback, useEffect, useRef} from 'react'
import {
  useCreateOverviewBlockDraftMutation,
  useCreateOverviewBlockMutation,
  useMoveOverviewBlockMutation,
  useOverviewBlocksByUrlOrNullLazyQuery,
  useOverviewBlocksDraftByPublicationOrNullLazyQuery,
  useRemoveOverviewBlockDraftMutation,
  useRemoveOverviewBlockMutation
} from '@components/MainSelection/Overview/OverviewContent/gql/OverviewContent.generated'
import {LinkType} from '@components/Publication/Form/Pickers/LinkPicker/LinkPicker'
import PublicationFormDetails from '@components/Publication/Form/PublicationForm/PublicationFormOverviewBlocks/PublicationFormDetails/PublicationFormDetails'
import {PublicationStatus} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {OverviewBlockCache} from 'types/OverviewBlockCache'
import {PublicationFormOverviewBlocksProps} from './PublicationFormOverviewBlocksProps'

export const PublicationFormOverviewBlocks: FC<PublicationFormOverviewBlocksProps> = observer(
  ({moveBlocks, setOverviewBlockFigureCache, setRelatedLinks}) => {
    const {publicationStore, regionsWithOverviewBlocksStore} = useStore()
    const {publication} = publicationStore
    const {overviewBlocksLoading, setOverviewBlocksCache, setOverviewBlocksLoading} = regionsWithOverviewBlocksStore
    const [overviewBlocksByUrlOrNull] = useOverviewBlocksByUrlOrNullLazyQuery()
    const [overviewBlocksDraftByPublicationOrNull] = useOverviewBlocksDraftByPublicationOrNullLazyQuery()
    const [createOverviewBlock, {loading: createOverviewBlockLoading}] = useCreateOverviewBlockMutation()
    const [createOverviewBlockDraft, {loading: createOverviewBlockDraftLoading}] = useCreateOverviewBlockDraftMutation()
    const [moveOverviewBlock, {loading: moveOverviewBlockLoading}] = useMoveOverviewBlockMutation()
    const [removeOverviewBlock, {loading: removeOverviewBlockLoading}] = useRemoveOverviewBlockMutation()
    const [removeOverviewBlockDraft, {loading: removeOverviewBlockDraftLoading}] = useRemoveOverviewBlockDraftMutation()

    const refetchOverviewBlocks = useRef<() => void>(null)

    const overviewBlocksByUrlOrNullAction = useCallback(
      async url => {
        await overviewBlocksByUrlOrNull({
          variables: {
            url: url
          }
        })
      },
      [overviewBlocksByUrlOrNull]
    )

    const overviewBlocksDraftByPublicationOrNullAction = useCallback(
      async publicationId => {
        await overviewBlocksDraftByPublicationOrNull({
          variables: {
            publicationId
          }
        })
      },
      [overviewBlocksDraftByPublicationOrNull]
    )

    useEffect(() => {
      if (publication.canonicalUrl) {
        overviewBlocksByUrlOrNullAction(publication.canonicalUrl)
      }
    }, [overviewBlocksByUrlOrNullAction, publication.canonicalUrl, publication.status])

    useEffect(() => {
      if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
        overviewBlocksDraftByPublicationOrNullAction(publication.id)
      }
    }, [publication.status, publication.id, overviewBlocksDraftByPublicationOrNullAction])

    const createOverviewBlockAction = useCallback(
      async (regionId, url, figureVersionId, title, subtitle) => {
        const createOverviewBlockData = await createOverviewBlock({
          variables: {
            data: {
              regionId,
              url,
              figureVersionId,
              title,
              subtitle
            }
          }
        })
        if (createOverviewBlockData?.data?.createOverviewBlock?.block?.id) {
          return createOverviewBlockData?.data?.createOverviewBlock.block?.id
        }
        return ''
      },
      [createOverviewBlock]
    )

    const createOverviewBlockDraftAction = useCallback(
      async (regionId, publicationId, position) => {
        const createOverviewBlockData = await createOverviewBlockDraft({
          variables: {
            data: {
              regionId,
              publicationId,
              position
            }
          }
        })
        if (createOverviewBlockData?.data?.createOverviewBlockDraft?.id) {
          return createOverviewBlockData?.data?.createOverviewBlockDraft?.id
        }
        return ''
      },
      [createOverviewBlockDraft]
    )

    const removeOverviewBlockAction = useCallback(
      async (overviewBlockId: string) => {
        const removeOverviewBlockData = await removeOverviewBlock({
          variables: {
            overviewBlockId
          }
        })
        return ''
      },
      [removeOverviewBlock]
    )

    const removeOverviewBlockDraftAction = useCallback(
      async (overviewBlockId: string) => {
        const removeOverviewBlockDraftData = await removeOverviewBlockDraft({
          variables: {
            overviewBlockId
          }
        })
        return ''
      },
      [removeOverviewBlockDraft]
    )

    const moveOverviewBlockAction = useCallback(
      async (overviewBlockId, position) => {
        const moveOverviewBlockData = await moveOverviewBlock({
          variables: {
            data: {
              overviewBlockId,
              position
            }
          }
        })
        if (moveOverviewBlockData?.data?.moveOverviewBlock?.status) {
          return moveOverviewBlockData?.data?.moveOverviewBlock?.status
        }
        return false
      },
      [moveOverviewBlock]
    )

    const createDeleteAndMoveBlocks = useCallback(
      async (blocks: OverviewBlockCache[]) => {
        const promises: Promise<any>[] = []

        blocks.forEach(async (item: OverviewBlockCache) => {
          let overviewBlockId = item?.id

          if (item.forDelete && item.id) {
            promises.push(
              new Promise(async resolve => {
                // Отправляем запрос на удаление блока
                await ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
                  ? removeOverviewBlockDraftAction(item.id)
                  : removeOverviewBlockAction(item.id))
                resolve(null)
              })
            )
          } else if (item.position != null) {
            /* 
              Создаем или перемещаем блок:
              1. Если мы находимся в черновиках, то создание и выставление блока на нужную позицию идет 
              только с помощью метода createOverviewBlockDraftAction
              2. Если публикация в статусе Published, то:
                - блок обязательно надо сначала создать createOverviewBlockAction
                - а потом его двигать moveOverviewBlockAction
                - когда снимаем галку с региона, то надо обязательно удалять overviewBlock
            */
            promises.push(
              new Promise(async resolve => {
                if (!overviewBlockId.length || publication.status == PublicationStatus.Draft) {
                  overviewBlockId = await ([PublicationStatus.Draft, PublicationStatus.Review].includes(
                    publication?.status
                  )
                    ? createOverviewBlockDraftAction(item.regionId, publication.id, item.position)
                    : createOverviewBlockAction(
                        item.regionId,
                        item.url,
                        item.figureVersionId || '',
                        item.title || '',
                        item.subtitle || ''
                      ))
                } else {
                  await moveOverviewBlockAction(overviewBlockId, item.position - 1)
                }
                resolve(null)
              })
            )
          }
        })
        return promises
      },
      [
        publication,
        removeOverviewBlockAction,
        removeOverviewBlockDraftAction,
        createOverviewBlockAction,
        createOverviewBlockDraftAction,
        moveOverviewBlockAction
      ]
    )

    const moveOverViewBlocks = useCallback(
      async (blocks: OverviewBlockCache[]) => {
        if (blocks.length) {
          const promises = createDeleteAndMoveBlocks(blocks)

          setOverviewBlocksLoading(true)
          await Promise.all(await promises).then(() => {
            refetchOverviewBlocks.current && refetchOverviewBlocks.current()
            setOverviewBlocksCache([])
          })
          setOverviewBlocksLoading(false)
        }
      },
      [createDeleteAndMoveBlocks, refetchOverviewBlocks, setOverviewBlocksCache]
    )

    useEffect(() => {
      moveBlocks.current = moveOverViewBlocks
    }, [moveBlocks, moveOverViewBlocks])

    const changeLinks = useCallback(
      (links: LinkType[]): void => {
        setRelatedLinks(links)
      },
      [setRelatedLinks]
    )

    useEffect(() => {
      setOverviewBlocksLoading(
        createOverviewBlockLoading ||
          moveOverviewBlockLoading ||
          removeOverviewBlockLoading ||
          overviewBlocksLoading ||
          createOverviewBlockDraftLoading ||
          removeOverviewBlockDraftLoading
      )
    }, [
      createOverviewBlockLoading,
      moveOverviewBlockLoading,
      removeOverviewBlockLoading,
      overviewBlocksLoading,
      createOverviewBlockDraftLoading,
      removeOverviewBlockDraftLoading,
      setOverviewBlocksLoading
    ])

    return (
      <PublicationFormDetails
        changeLinks={changeLinks}
        setOverviewBlockFigureCache={setOverviewBlockFigureCache}
        refetchOverviewBlocks={refetchOverviewBlocks}
      />
    )
  }
)
