import React, {FC} from 'react'

const DragAndDropIcon: FC<any> = () => {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_5378_33077)'>
        <rect width='36' height='36' rx='18' fill='#E5E5E5' />
        <path
          d='M12.8995 8.10051L16.6943 8.10051L16.6778 12.8523L19.0207 12.8523L19.0042 8.1005L22.799 8.10051L17.8492 3.15076L12.8995 8.10051ZM22.799 27.8995L19.0042 27.8995L19.0207 23.1477L16.6778 23.1477L16.6943 27.8995L12.8995 27.8995L17.8492 32.8492L22.799 27.8995Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_5378_33077'>
          <rect width='36' height='36' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DragAndDropIcon
