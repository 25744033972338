import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

const CloseIcon: FC<any> = (props: SvgIconProps) => {
  const maskUuid = '123'
  return (
    <SvgIcon {...props}>
      <mask id={maskUuid} width='14' height='14' x='5' y='6' maskUnits='userSpaceOnUse'>
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M17.364 6.211L12 11.575 6.636 6.211 5.222 7.626l5.364 5.364-5.364 5.364 1.414 1.414L12 14.404l5.364 5.364 1.414-1.414-5.364-5.364 5.364-5.364-1.414-1.415z'
          clipRule='evenodd'
        />
      </mask>
      <g mask={`url(#${maskUuid})`}>
        <path fill='#7F7F83' d='M0 0H40V40H0z' transform='translate(-8 -7.01)' />
      </g>
    </SvgIcon>
  )
}

export default CloseIcon
