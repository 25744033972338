import styled from 'styled-components'
import {mainTextColor, screenS} from '@theme/vars'

export const Wrapper = styled.div<{showExtParams: boolean}>`
  max-width: 1304px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${({showExtParams}) => (showExtParams ? 'calc(100vh - 260px)' : ' calc(100vh - 180px)')};

  @media screen and (max-width: ${screenS}) {
    max-height: ${({showExtParams}) => (showExtParams ? 'calc(100vh - 330px)' : 'calc(100vh - 190px)')};
    padding-right: 1px;
    padding-left: 10px;
    width: 100%;
    overflow-x: auto;
  }
`

export const DropableAreaWrapper = styled.div`
  width: calc(100% - 5px);
  padding: 0;
  margin-left: 0px;
`

export const TitleRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20.8px;
  width: 672px;

  @media screen and (max-width: ${screenS}) {
    width: 100%;
  }
`

export const TitleWrapper = styled.div`
  color: ${mainTextColor};
  margin-top: 5px;
`

export const DragAndDropButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: scale(0.7);
  height: 20.8px;

  &:hover,
  &:focus,
  &:focus:active {
    cursor: grab;
    background-color: inherit;
  }
  & svg {
    transform: scale(0.8255);
  }
`
