import React, {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {PublicationStatus} from '@graphql/types'
import {PublicationFormAnnounceImage} from '../PublicationFormAnnounceImage/PublicationFormAnnounceImage'
import {ExtAnnounceParams} from '../ExtAnnounceParams'
import {SENTENCE_SEPARATOR_REG_EX} from '../../PublicationFormTitles/PublicationFormTitles.consts'
import {
  useUpdatePublicationExtAnnounceParamsMutation,
  useUpdatePublicationSocialImageOnlyMutation
} from '../../gql/PublicationEditor.generated'
import {PublicationFormAnnounceImageWrapperProps} from './PublicationFormAnnounceImageWrapperProps'

const PublicationFormAnnounceImageWrapper: FC<PublicationFormAnnounceImageWrapperProps> = ({
  allFieldsAlygnY,
  allFieldsTitle,
  changeOgParams,
  defaultValues
}) => {
  const title = allFieldsTitle || ''
  const {publicationStore} = useStore()
  const {publication, announceImageModalStore, setPublicationWithPrevState} = publicationStore
  const {announceImageModalCacheStore, setUpdatePublicationExtAnnounceParamsLoading, setUpdateSocialImageOnlyLoading} =
    announceImageModalStore
  const {extAnnounceParamsCache, socialImageCache, setExtAnnounceParamsCache, setSocialImageCache} =
    announceImageModalCacheStore

  const [defaultExtAnnounceParams, setDefaultExtAnnounceParams] = useState<ExtAnnounceParams>({
    ogTitle:
      publication?.ogTitle ||
      (publication?.title?.length ? publication?.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''),
    ogDescription:
      publication?.ogDescription ||
      (publication?.title?.length && publication?.title?.length > 1
        ? publication?.title.split(SENTENCE_SEPARATOR_REG_EX)[1]
        : '')
  } as ExtAnnounceParams)

  const [updatePublicationExtAnnounceParams, {loading: updateExtAnnounceParamsLoading}] =
    useUpdatePublicationExtAnnounceParamsMutation()
  const [updateSocialImageOnly, {loading: updateSocialImageLoading}] = useUpdatePublicationSocialImageOnlyMutation()

  useEffect(() => {
    setUpdatePublicationExtAnnounceParamsLoading(updateExtAnnounceParamsLoading)
  }, [updateExtAnnounceParamsLoading, setUpdatePublicationExtAnnounceParamsLoading])

  useEffect(() => {
    setUpdateSocialImageOnlyLoading(updateSocialImageLoading)
  }, [updateSocialImageLoading, setUpdateSocialImageOnlyLoading])

  /**
   * Сохранение параметров ogTitle, ogDescription в черновиках
   */
  useEffect(() => {
    if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
      if (
        extAnnounceParamsCache &&
        (extAnnounceParamsCache?.ogTitle != defaultValues?.ogTitle ||
          extAnnounceParamsCache?.ogDescription != defaultValues?.ogDescription)
      ) {
        const timer = setTimeout(async () => {
          const variablesData = {
            publicationUid: publication.uid,
            ogTitle: extAnnounceParamsCache?.ogTitle || defaultValues?.ogTitle || '',
            ogDescription: extAnnounceParamsCache?.ogDescription || ''
          }

          const resultData = await updatePublicationExtAnnounceParams({
            variables: {
              data: variablesData
            }
          })

          if (!resultData?.errors?.length) {
            setExtAnnounceParamsCache(undefined)

            changeOgParams(
              resultData?.data?.updatePublication?.publication?.ogTitle || '',
              resultData?.data?.updatePublication?.publication?.ogDescription || ''
            )

            setPublicationWithPrevState(prev => ({
              ...prev,
              ogTitle: resultData?.data?.updatePublication?.publication?.ogTitle,
              ogDescription: resultData?.data?.updatePublication?.publication?.ogDescription
            }))

            setDefaultExtAnnounceParams({
              ogTitle: resultData?.data?.updatePublication?.publication?.ogTitle || '',
              ogDescription: resultData?.data?.updatePublication?.publication?.ogDescription || ''
            })
          }
        }, 1000)
        return () => clearTimeout(timer)
      }
    }
  }, [
    publication.uid,
    publication.status,
    defaultExtAnnounceParams,
    extAnnounceParamsCache,
    defaultValues?.ogTitle,
    defaultValues?.ogDescription,
    changeOgParams,
    setPublicationWithPrevState,
    updatePublicationExtAnnounceParams,
    setExtAnnounceParamsCache
  ])

  /**
   * Сохранение параметров картинки для соц-сетей в черновиках
   */
  useEffect(() => {
    if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
      if (
        socialImageCache &&
        (socialImageCache?.socialMediaFigureVersion?.alignY || 0) != (defaultValues.alygnY || 0)
      ) {
        const timer = setTimeout(async () => {
          const variablesData = {
            publicationUid: publication.uid,
            socialMediaImage: {
              alignY: socialImageCache?.socialMediaFigureVersion?.alignY || defaultValues.alygnY || 0
            }
          }

          const updateAnnounceImageData = await updateSocialImageOnly({
            variables: {
              data: variablesData
            }
          })

          if (!updateAnnounceImageData?.errors?.length) {
            setPublicationWithPrevState(prev => ({
              ...prev,
              socialMediaImage: {
                // @ts-ignore
                alignY:
                  updateAnnounceImageData?.data?.updatePublication?.publication?.socialMediaImage
                    ?.socialMediaFigureVersion?.alignY
              }
            }))
          }

          setSocialImageCache(undefined)
        }, 1000)
        return () => clearTimeout(timer)
      }
    }
  }, [
    updateSocialImageOnly,
    setPublicationWithPrevState,
    setSocialImageCache,
    publication.uid,
    publication.status,
    socialImageCache,
    allFieldsAlygnY,
    defaultValues.alygnY
  ])

  return <PublicationFormAnnounceImage publicationTitle={title} defaultExtAnnounceParams={defaultExtAnnounceParams} />
}
export default observer(PublicationFormAnnounceImageWrapper)
