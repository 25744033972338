import {IconButton} from '@mui/material'
import {styled} from 'styled-components'

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 16px;
`

export const IconWrapper = styled.div`
  display: inline-flex;
  margin: 8px 11px 7px 7px;

  & svg {
    width: 1.33em;
    height: 1.33em;
  }
`
