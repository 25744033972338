import React, {FC, useEffect, useState} from 'react'
import {Region} from '@graphql/types'
import {useRegionsLazyQuery} from '../../Publication/Form/gql/PublicationEditor.generated'
import {Autocomplete, TextField} from '@mui/material'
import useDeviceDetect from '@utils/useDeviceDetect'
import CloseIcon from '@mui/icons-material/Close'

type RegionAliasPickerProp = {
  region?: Region | null
  handleChangeRegion: (newRegion: any) => void
  selectedRegionUid?: string
  disabled?: boolean
  width?: string
  label?: string
  marginBottom?: string
}

const RegionPicker: FC<RegionAliasPickerProp> = ({
  region = null,
  handleChangeRegion,
  selectedRegionUid = '',
  disabled,
  width,
  label,
  marginBottom
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(
    region?.uid
      ? {
          uid: region?.uid,
          title: region.title,
          disabled: selectedRegionUid === region?.uid
        }
      : null
  )
  const [search, setSearch] = useState('')
  const {isMobile} = useDeviceDetect()
  const [getRegions, {data}] = useRegionsLazyQuery()
  const [fieldWidth, setFieldWidth] = useState(width || '315px')

  useEffect(() => {
    getRegions()
  }, [getRegions])

  useEffect(() => {
    if (width) {
      setFieldWidth(width)
      return
    }
    setFieldWidth(isMobile ? 'calc(100% - 20px)' : '315px')
  }, [width, isMobile])

  const handleOnChange = newValue => {
    setSelectedValue(newValue)
    handleChangeRegion(newValue)
  }

  return (
    <Autocomplete
      value={selectedValue}
      id={`regions-dropdown-tab`}
      inputValue={search}
      options={
        data?.regions?.map(region => ({
          uid: region?.uid,
          title: region.title,
          disabled: selectedRegionUid === region?.uid
        })) || []
      }
      getOptionDisabled={option => option?.disabled}
      isOptionEqualToValue={(option, value) => option?.uid === value?.uid}
      getOptionLabel={option => option?.title}
      style={{width: fieldWidth, marginBottom: marginBottom || '0px'}}
      onChange={(e, newValue) => handleOnChange(newValue)}
      onInputChange={(e, val) => setSearch(val)}
      renderInput={params => <TextField {...params} label={label || 'Регион'} variant='outlined' />}
      disabled={disabled}
      clearIcon={<CloseIcon fontSize='small' />}
      noOptionsText={'Значений не найдено'}
    />
  )
}

export default RegionPicker
