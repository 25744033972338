import {Publication} from '@graphql/types'
import {UiPublicationTextOnlyResult} from 'entities/types/UiPublication'
import {UiPublicationContent} from 'entities/types/UiPublicationContent'
import defaultPublication from '../publication.consts'
import UiTextElement from '../text-element/text-element.types'
import {UiElement} from '../publication.types'

const getPublicationTextOnlyByUidAdapterToClient = (response?: Publication): UiPublicationTextOnlyResult => {
  if (!response) {
    return {
      uid: '',
      content: defaultPublication.content
    }
  }

  const {isAutomaticCommentsDisabled, uid, title, publishedAt, content} = response

  let publicationContent: UiPublicationContent | null = null
  const contentElements: UiTextElement[] = []

  if (content?.elements) {
    content?.elements.forEach((element: UiElement) => {
      if (element.hasOwnProperty('content')) {
        const {id: elementId, content: elementContent} = element
        contentElements.push({
          id: elementId || '',
          content: elementContent || ''
        })
      }
    })

    publicationContent = {
      elements: contentElements
    }
  }

  return {
    uid: uid || '',
    title: title || '',
    isAutomaticCommentsDisabled: isAutomaticCommentsDisabled ?? defaultPublication.isAutomaticCommentsDisabled,
    publishedAt: publishedAt ?? defaultPublication.publishedAt,
    content: publicationContent ?? defaultPublication.content
  }
}

export {getPublicationTextOnlyByUidAdapterToClient}
