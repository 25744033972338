import * as Types from '../../../../@graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TopNewsBlockHistoryQueryVariables = Types.Exact<{
  topNewsBlockId: Types.Scalars['UUID'];
}>;


export type TopNewsBlockHistoryQuery = (
  { __typename?: 'Query' }
  & { topNewsBlockHistory?: Types.Maybe<Array<(
    { __typename?: 'TopNewsBlockChange' }
    & Pick<Types.TopNewsBlockChange, 'id' | 'uid' | 'isFigureVersionChanged' | 'isTitleChanged' | 'newTitle' | 'isUrlChanged' | 'newUrl' | 'isRegionChanged' | 'isPositionChanged' | 'newPosition' | 'isIsExclusiveChanged' | 'newIsExclusive' | 'createdAt'>
    & { block: (
      { __typename?: 'TopNewsBlock' }
      & Pick<Types.TopNewsBlock, 'uid' | 'position' | 'title' | 'url'>
      & { figureVersion?: Types.Maybe<(
        { __typename?: 'FigureVersion' }
        & Pick<Types.FigureVersion, 'uid'>
        & { primaryProcessedImage?: Types.Maybe<(
          { __typename?: 'Image' }
          & Pick<Types.Image, 'url'>
        )> }
      )> }
    ), user: (
      { __typename?: 'User' }
      & Pick<Types.User, 'firstname' | 'lastname'>
    ), newFigureVersion?: Types.Maybe<(
      { __typename?: 'FigureVersion' }
      & Pick<Types.FigureVersion, 'uid'>
      & { figure: (
        { __typename?: 'Figure' }
        & Pick<Types.Figure, 'uid'>
        & { latestVersion: (
          { __typename?: 'FigureVersion' }
          & { primaryProcessedImage?: Types.Maybe<(
            { __typename?: 'Image' }
            & Pick<Types.Image, 'url'>
          )> }
        ) }
      ), primaryProcessedImage?: Types.Maybe<(
        { __typename?: 'Image' }
        & Pick<Types.Image, 'url'>
      )> }
    )>, newRegion?: Types.Maybe<(
      { __typename?: 'Region' }
      & Pick<Types.Region, 'id' | 'title'>
    )> }
  )>> }
);


export const TopNewsBlockHistoryDocument = gql`
    query topNewsBlockHistory($topNewsBlockId: UUID!) {
  topNewsBlockHistory(topNewsBlockId: $topNewsBlockId) {
    id
    uid
    block {
      uid
      figureVersion {
        uid
        primaryProcessedImage {
          url
        }
      }
      position
      title
      url
    }
    user {
      firstname
      lastname
    }
    isFigureVersionChanged
    newFigureVersion {
      uid
      figure {
        uid
        latestVersion {
          primaryProcessedImage {
            url
          }
        }
      }
      primaryProcessedImage {
        url
      }
    }
    isTitleChanged
    newTitle
    isUrlChanged
    newUrl
    isRegionChanged
    newRegion {
      id
      title
    }
    isPositionChanged
    newPosition
    isIsExclusiveChanged
    newIsExclusive
    createdAt
  }
}
    `;

/**
 * __useTopNewsBlockHistoryQuery__
 *
 * To run a query within a React component, call `useTopNewsBlockHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNewsBlockHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNewsBlockHistoryQuery({
 *   variables: {
 *      topNewsBlockId: // value for 'topNewsBlockId'
 *   },
 * });
 */
export function useTopNewsBlockHistoryQuery(baseOptions: Apollo.QueryHookOptions<TopNewsBlockHistoryQuery, TopNewsBlockHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopNewsBlockHistoryQuery, TopNewsBlockHistoryQueryVariables>(TopNewsBlockHistoryDocument, options);
      }
export function useTopNewsBlockHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopNewsBlockHistoryQuery, TopNewsBlockHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopNewsBlockHistoryQuery, TopNewsBlockHistoryQueryVariables>(TopNewsBlockHistoryDocument, options);
        }
export type TopNewsBlockHistoryQueryHookResult = ReturnType<typeof useTopNewsBlockHistoryQuery>;
export type TopNewsBlockHistoryLazyQueryHookResult = ReturnType<typeof useTopNewsBlockHistoryLazyQuery>;
export type TopNewsBlockHistoryQueryResult = Apollo.QueryResult<TopNewsBlockHistoryQuery, TopNewsBlockHistoryQueryVariables>;