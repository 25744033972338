import React from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import TopNewsEdit from '../../components/MainSelection/TopNews/TopNewsEdit/TopNewsEdit'

const TopNewsBannersEditPage: React.FC = () => {
  return (
    <PageContainer>
      <TopNewsEdit />
    </PageContainer>
  )
}

export default TopNewsBannersEditPage
