import {useKeycloak} from '@react-keycloak/web'
import {debounce} from 'lodash'
import {observer} from 'mobx-react-lite'
import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import {toast} from 'react-toastify'
import {updatePublicationMainFieldsAdapterToClient} from '@entities/api/publication/adapters/updatePublicationMainFieldsAdapterToClient'
import {publishPublicationAdapterToClient} from '@entities/api/publication/adapters/publishPublicationAdapterToClient'
import {AgentCheckService} from '@service/index'
import {
  useCreateRelatedLinkMutation,
  useDeleteRelatedLinkMutation,
  usePublishPublicationMutation,
  useUpdatePublicationMainFieldsMutation,
  useUpdatePublicationMutation,
  useUpdatePublicationOverviewFigureMutation
} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {LinkType} from '@components/Publication/Form/Pickers/LinkPicker/LinkPicker'
import {PublicationFormTitles} from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitles'
import {SENTENCE_SEPARATOR_REG_EX} from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitles.consts'
import {PublicationInfo} from '@components/Publication/Form/PublicationInfo/PublicationInfo'
import {PublicationSendMenu} from '@components/Publication/Form/PublicationSendMenu/PublicationSendMenu'
import {UnpublishedNewsSignboard} from '@components/Publication/Form/UnpublishedNewsSignboard/UnpublishedNewsSignboard'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import Editor from '@components/TextEditor/Editor/Editor'
import {adaptQuillRequest} from '@components/TextEditor/Editor/QuillEditor/QuillEditor.adapters'
import {useUpdatePublicationContentMutation} from '@components/TextEditor/gql/TextEditor.generated'
import {
  ElementUnionInput,
  PublicationStatus,
  UpdatePublicationExportInput,
  UpdatePublicationInput
} from '@graphql/types'
import {TypographReplacer} from '@hooks/typographReplacer'
import {useSetCheckingPhotoEditorMutation} from '@pages/BildCheckList/gql/BildCheckList.generated'
import {useStore} from '@stores/rootStoreContext'
import {compareOfTwoArraysWithPrimitives} from '@utils/compareOfTwoArraysWithPrimitives'
import {OverviewBlockCache} from 'types/OverviewBlockCache'
import {getQuillEditor} from '@utils/getQuillEditor'
import {UiRegion} from 'entities/types/UiRegion'
import {UiAnnounceImage} from 'entities/types/UiAnnounceImage'
import {UiFigure} from 'entities/types/UiFigure'
import {isEqualDates} from '@utils/validation/isEqualDates'
import {isValidDate} from '@utils/validation/isValidDate'
import {FiguresCacheContext} from './FiguresCacheContext'
import {PublicationDebugInfo} from './PublicationDebugInfo/PublicationDebugInfo'
import {PUBLICATION_FORM_CONSTS} from './PublicationForm.consts'
import {
  PublicationFormContent,
  PublicationFormContentHeader,
  PublicationFormContentHeaderTitle,
  PublicationFormContentWrapper,
  PublicationFormWrapper
} from './PublicationForm.styles'
import {PublicationFormOverviewBlocks} from './PublicationFormOverviewBlocks/PublicationFormOverviewBlocks'
import PublicationFormAnnounceImageWrapper from './PublicationFormAnnounceImageWrapper/PublicationFormAnnounceImageWrapper'
import type {UseFormReturn} from 'react-hook-form'
import type {PublicationInputType} from './PublicationInputType'

/**
 * Общая форма для работы с публикацией(страница редактирования новости/статьи)
 * @returns
 */
const PublicationForm: FC = () => {
  const {publicationStore, editorStore, regionsWithOverviewBlocksStore, agentCheckStore} = useStore()
  const {
    publication,
    announceImageModalStore,
    checkingPhotoEditor,
    checkingPhotoEditorCache,
    rubricAndSubrubricStore,
    setPublicationLoading,
    setPublicationContentLoading,
    setPublicationWithPrevState,
    setCheckingPhotoEditor,
    setPublicationFormDirty
  } = publicationStore
  const {
    announceFigureCache,
    announceImageModalCacheStore,
    updatePublicationExtAnnounceParamsLoading,
    updateSocialImageOnlyLoading,
    updatePublicationAnnounceFieldsLoading,
    setAnnounceFigureCache,
    setImageModalLoading
  } = announceImageModalStore
  const {rubricId, rubricIdCache, subrubricId, subrubricIdCache, setRubricId, setSubrubricId} = rubricAndSubrubricStore
  const {editorRef, editorDirty, setEditorDirty} = editorStore
  const {socialImageCache, extAnnounceParamsCache, setExtAnnounceParamsCache} = announceImageModalCacheStore
  const {overviewBlocksCache} = regionsWithOverviewBlocksStore
  const {figuresCache} = useContext(FiguresCacheContext)

  const [overviewBlockFigureCache, setOverviewBlockFigureCache] = useState<ImageBlotProps | undefined>()
  const [updatePublication, {loading: updatePubliationLoading}] = useUpdatePublicationMutation()
  const [publishPublication, {loading: publishLoading}] = usePublishPublicationMutation()
  const [updatePublicationMainFields, {loading: updatePublicationMainFieldsLoading}] =
    useUpdatePublicationMainFieldsMutation()
  const [setCheckingPhotoEditorAction, {loading: setCheckingPhotoEditorLoading}] = useSetCheckingPhotoEditorMutation()
  const [updatePublicationOverviewFigure, {loading: updatePublicationOverviewFigureLoading}] =
    useUpdatePublicationOverviewFigureMutation()
  const [createRelatedLinkMutation, {loading: createRelatedLinkLoading}] = useCreateRelatedLinkMutation()
  const [deleteRelatedLinkMutation, {loading: deleteRelatedLinkLoading}] = useDeleteRelatedLinkMutation()
  const [updateContentElements, {loading: updateContentElementsLoading}] = useUpdatePublicationContentMutation()
  const {keycloak} = useKeycloak()
  const [mainFieldsEditorDirty, setMainFieldsEditorDirty] = useState(false)
  const publicationFormRef = useRef<HTMLFormElement>(null)

  const cantEditPublication = useMemo(
    () => publication?.status === 'PUBLISHED' && !keycloak.hasResourceRole('edit-published', 'publications'),

    [keycloak, publication?.status]
  )

  const [relatedLinks, setRelatedLinks] = useState<LinkType[]>()

  const defaultValues = useMemo<PublicationInputType>(
    () => ({
      uid: (publication?.uid as string) || '',
      title: (publication?.title as string) || '',
      subtitle: (publication?.subtitle as string) || '',
      yandexTitle: (publication?.yandexTitle as string) || '',
      yandexDzenTitle: publication?.yandexDzenTitle || '',
      seoTitle: (publication?.seoTitle as string) || '',
      subscriptionBlockUid: publication?.subscriptionBlock?.uid || '',
      storyUid: publication?.story?.uid || null,
      regionUids: publication?.regions?.map(v => v?.uid) || [],
      regions: publication?.regions || [],
      tagsUids: publication?.tags?.map(v => v?.uid) || [],
      collectionUids: publication?.collections?.map(v => v?.uid) || [],
      specProjectUrl: publication?.specProjectUrl || '',
      isInside: publication?.isInside || false,
      authors: publication.authors?.map(author => author.uid) || [],
      isCommentsDisabled: !(publication?.isCommentsEnabled as boolean),
      isAutomaticCommentsDisabled: publication?.isAutomaticCommentsDisabled as boolean,
      isImportant: publication?.typeSlug === 'articles' || publication?.isImportant === true ? true : false,
      sendToRambler: publication?.sendToRambler || false,
      publishedAt: publication.status === PublicationStatus.Published ? publication?.publishedAt || null : null,
      destinationExport: {
        destinationSlugs: (publication?.destinations?.map(v => v?.slug) as string[]) || [],
        yandexZenCategoryUids: (publication?.yandexZenCategories?.map(v => v?.id) as string[]) || []
      } as UpdatePublicationExportInput,
      imageUrl: '',
      relatedLinks: publication?.relatedLinks || [],
      yandexNewsVideos:
        publication?.yandexNewsVideos?.map(item => ({
          id: item.id,
          title: item.title,
          uri: item.uri,
          uid: item.uid,
          thumbnail: item.thumbnail as UiFigure
        })) || [],
      overviewDescription: publication?.overviewDescription || '',
      overviewTitle: publication?.overviewTitle || publication.title || '',
      overviewFigure: publication?.overviewFigure,
      overviewFigureId: publication?.overviewFigure?.figure?.id || '',
      overviewFigureVersionId: publication?.overviewFigure?.id || '',
      cantEditPublication: cantEditPublication,
      elements: publication?.content?.elements || [],
      destinationIds: (publication?.destinations?.map(v => v?.id) as string[]) || [],
      keywords: publication?.keywords,
      announceImage: publication?.announceImage,
      ogTitle:
        publication?.ogTitle ||
        (publication?.title?.length ? publication?.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''),
      ogDescription: publication?.ogDescription || '',
      announceFigureVersionId: publication?.announceImage?.figureVersion?.id || '',
      badwords: publication.badwords || false,
      socialMediaFigureVersion: publication?.socialMediaImage?.socialMediaFigureVersion,
      alygnY: publication?.socialMediaImage?.socialMediaFigureVersion?.alignY || 0
    }),
    [publication, cantEditPublication]
  )

  const methodsForm: UseFormReturn<PublicationInputType> = useForm({
    defaultValues
  })

  const {control, handleSubmit} = methodsForm

  useEffect(() => {
    if (
      !updateSocialImageOnlyLoading &&
      !updatePubliationLoading &&
      !publishLoading &&
      !updatePublicationMainFieldsLoading &&
      !updatePublicationAnnounceFieldsLoading &&
      !updatePublicationExtAnnounceParamsLoading &&
      // !updatePublicationRegionsLoading &&
      // !updatePubliationRubricAndSubrubricLoading &&
      !setCheckingPhotoEditorLoading &&
      !createRelatedLinkLoading &&
      !deleteRelatedLinkLoading &&
      !updatePublicationOverviewFigureLoading
    ) {
      debounce(() => {
        // reset(defaultValues)
        setPublicationFormDirty(false)
        setMainFieldsEditorDirty(false)
      }, 300)
    }
  }, [
    defaultValues,
    updateSocialImageOnlyLoading,
    updatePubliationLoading,
    publishLoading,
    updatePublicationMainFieldsLoading,
    updatePublicationAnnounceFieldsLoading,
    updatePublicationExtAnnounceParamsLoading,
    // updatePublicationRegionsLoading,
    // updatePubliationRubricAndSubrubricLoading,
    setCheckingPhotoEditorLoading,
    createRelatedLinkLoading,
    deleteRelatedLinkLoading,
    updatePublicationOverviewFigureLoading,
    setPublicationFormDirty
  ])

  const changeOgParams = useCallback(
    (ogTitleVal, ogDescriptionVal) => {
      defaultValues.ogTitle = ogTitleVal
      defaultValues.ogDescription = ogDescriptionVal
    },
    [defaultValues]
  )

  const allFields = useWatch({control})

  /**
   * Отслеживание изменений основных параметров публикации
   */
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (
        // allFields?.announceImage?.isFullWidth !==
        //     defaultValues?.announceImage?.isFullWidth ||
        allFields?.title != defaultValues?.title ||
        allFields?.yandexTitle != defaultValues?.yandexTitle ||
        allFields?.yandexDzenTitle != defaultValues?.yandexDzenTitle ||
        allFields?.seoTitle != defaultValues?.seoTitle ||
        allFields?.subtitle != defaultValues?.subtitle ||
        allFields?.isCommentsDisabled !== defaultValues?.isCommentsDisabled ||
        allFields?.isAutomaticCommentsDisabled !== defaultValues?.isAutomaticCommentsDisabled ||
        allFields?.isImportant !== defaultValues?.isImportant ||
        allFields?.sendToRambler !== publication?.sendToRambler ||
        allFields?.isInside !== defaultValues?.isInside ||
        allFields?.specProjectUrl != defaultValues?.specProjectUrl ||
        // allFields?.keywords != defaultValues?.keywords ||
        allFields.storyUid != defaultValues.storyUid ||
        // TODO надо будет вернуть эту проверку
        // allFields.publishedAt != defaultValues.publishedAt ||
        allFields.overviewDescription != defaultValues.overviewDescription ||
        allFields.overviewTitle != defaultValues.overviewTitle ||
        checkingPhotoEditor !== checkingPhotoEditorCache ||
        // !compareOfTwoArraysWithPrimitives(
        //     allFields?.destinationIds || [],
        //     defaultValues?.destinationIds
        // ) ||
        // !compareOfTwoArraysWithPrimitives(
        //     allFields?.destinationExport?.destinationSlugs || [],
        //     (defaultValues?.destinationExport
        //         ?.destinationSlugs as string[]) || []
        // ) ||
        (defaultValues?.authors?.length &&
          defaultValues?.authors[0] &&
          !compareOfTwoArraysWithPrimitives(allFields?.authors || [], defaultValues?.authors || [])) ||
        // !compareOfTwoArraysWithPrimitives(
        //   allFields?.yandexNewsVideos?.map(item => (item.title, item.uri, item.uid)) || [],
        //   defaultValues?.yandexNewsVideos?.map(item => (item.title, item.uri, item.uid)) || []
        // ) ||
        !compareOfTwoArraysWithPrimitives(
          allFields?.destinationExport?.yandexZenCategoryUids || [],
          defaultValues?.destinationExport?.yandexZenCategoryUids || []
        ) ||
        // !compareOfTwoArraysWithPrimitives(allFields?.tagsUids || [], defaultValues?.tagsUids) ||
        !compareOfTwoArraysWithPrimitives(allFields?.collectionUids || [], defaultValues?.collectionUids)
      ) {
        setMainFieldsEditorDirty(true)
      } else {
        setMainFieldsEditorDirty(false)
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [
    allFields.authors,
    allFields.yandexNewsVideos,
    allFields.destinationExport,
    allFields.collectionUids,
    allFields.title,
    allFields.yandexTitle,
    allFields.yandexDzenTitle,
    allFields.seoTitle,
    allFields.subtitle,
    allFields.isCommentsDisabled,
    allFields.isAutomaticCommentsDisabled,
    allFields.isImportant,
    allFields.sendToRambler,
    allFields.isInside,
    allFields.specProjectUrl,
    // allFields.keywords,
    allFields.storyUid,
    allFields.publishedAt,
    allFields.overviewDescription,
    allFields.overviewTitle,
    defaultValues.authors,
    defaultValues.yandexNewsVideos,
    defaultValues.destinationExport,
    defaultValues.collectionUids,
    defaultValues.title,
    defaultValues.yandexTitle,
    defaultValues.yandexDzenTitle,
    defaultValues.seoTitle,
    defaultValues.subtitle,
    defaultValues.isCommentsDisabled,
    defaultValues.isAutomaticCommentsDisabled,
    defaultValues.isImportant,
    defaultValues.sendToRambler,
    defaultValues.isInside,
    defaultValues.specProjectUrl,
    defaultValues.keywords,
    defaultValues.storyUid,
    defaultValues.publishedAt,
    defaultValues.overviewDescription,
    defaultValues.overviewTitle,
    defaultValues.ogTitle,
    defaultValues.ogDescription,
    checkingPhotoEditor,
    checkingPhotoEditorCache,
    publication.status
  ])

  useEffect(() => {
    if (
      mainFieldsEditorDirty ||
      announceFigureCache ||
      // allFields?.announceImage?.alt != defaultValues?.announceImage?.alt ||
      // allFields?.announceImage?.author != defaultValues?.announceImage?.author ||
      // allFields?.announceImage?.description != defaultValues?.announceImage?.description ||
      // allFields?.announceImage?.figureVersion?.id != defaultValues?.announceImage?.figureVersion?.id ||
      allFields.announceFigureVersionId != defaultValues.announceFigureVersionId ||
      rubricId != rubricIdCache ||
      subrubricId != subrubricIdCache ||
      // allFields?.ogTitle != defaultValues?.ogTitle ||
      // allFields?.ogDescription != defaultValues?.ogDescription ||
      (isValidDate(allFields.publishedAt?.toString() || '') &&
        !isEqualDates(allFields.publishedAt, defaultValues.publishedAt)) ||
      extAnnounceParamsCache ||
      allFields?.overviewFigureVersionId !== defaultValues?.overviewFigureVersionId ||
      allFields.keywords !== defaultValues.keywords ||
      !compareOfTwoArraysWithPrimitives(
        relatedLinks?.map(i => i.id) || [],
        defaultValues.relatedLinks?.map(i => i.id) || []
      ) ||
      !compareOfTwoArraysWithPrimitives(allFields?.regionUids || [], defaultValues?.regionUids) ||
      // (![PublicationStatus.Draft, PublicationStatus.Review].includes(publication.status) &&
      //   !compareTwoPublicationContent(defaultValues?.elements, allFields?.elements || [])) ||
      overviewBlocksCache.length > 0 ||
      figuresCache.length > 0
    ) {
      setPublicationFormDirty(true)
    } else {
      setPublicationFormDirty(false)
    }
  }, [
    mainFieldsEditorDirty,
    announceFigureCache,
    extAnnounceParamsCache,
    allFields?.announceImage,
    allFields.announceFigureVersionId,
    allFields.regionUids,
    allFields?.overviewFigureVersionId,
    allFields.publishedAt,
    allFields.keywords,
    defaultValues?.announceImage,
    defaultValues.announceFigureVersionId,
    defaultValues.regionUids,
    defaultValues?.ogTitle,
    defaultValues?.ogDescription,
    defaultValues?.overviewFigureVersionId,
    defaultValues.relatedLinks,
    defaultValues.publishedAt,
    defaultValues.keywords,
    overviewBlocksCache,
    rubricId,
    rubricIdCache,
    subrubricId,
    subrubricIdCache,
    relatedLinks,
    figuresCache?.length,
    setPublicationFormDirty
  ])

  const createRelatedLink = useCallback(
    (url, text) => {
      if (url) {
        createRelatedLinkMutation({
          variables: {
            data: {
              publicationId: publication?.id || '',
              url: url || '',
              text: text || ''
            }
          }
        }).then(data => {
          const newLinks = JSON.parse(JSON.stringify(defaultValues.relatedLinks))
          if (data.data?.createRelatedLink?.link) {
            newLinks.push(data.data?.createRelatedLink?.link)
            defaultValues.relatedLinks = newLinks

            setPublicationWithPrevState(prev => ({
              ...prev,
              relatedLinks: newLinks
            }))
          }
        })
      }
    },
    [publication?.id, defaultValues.relatedLinks, setPublicationWithPrevState]
  )

  const deleteRelatedLink = useCallback(
    linkId => {
      if (linkId) {
        deleteRelatedLinkMutation({
          variables: {
            data: {
              linkId: linkId || ''
            }
          }
        }).then(data => {
          const newLinks = JSON.parse(JSON.stringify(defaultValues.relatedLinks))

          if (data?.data?.deleteRelatedLink) {
            const resLinks = newLinks.filter(item => item.id !== linkId)
            defaultValues.relatedLinks = resLinks

            setPublicationWithPrevState(prev => ({
              ...prev,
              relatedLinks: resLinks
            }))
            setRelatedLinks(resLinks)
          }
        })
      }
    },
    [defaultValues?.relatedLinks, deleteRelatedLinkMutation, setPublicationWithPrevState]
  )

  const updateReleatedLinks = useCallback(() => {
    if (defaultValues?.relatedLinks?.length) {
      defaultValues?.relatedLinks?.forEach(ri => {
        if (!relatedLinks?.find(linkItem => linkItem.id === ri.id)) {
          deleteRelatedLink(ri.id || '')
        }
      })
    }
    if (relatedLinks?.length) {
      relatedLinks.forEach(linktItem => {
        if (!defaultValues.relatedLinks.find(ri => ri.id === linktItem.id)) {
          createRelatedLink(linktItem?.url || '', linktItem.text || '')
        }
      })
    }
  }, [relatedLinks, createRelatedLink, deleteRelatedLink, defaultValues.relatedLinks])

  /**
   * Сохранение основных параметров публикации в черновиках
   */
  useEffect(() => {
    const hasFormErrors = Object.keys(control._formState.errors).length > 0

    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      !hasFormErrors &&
      (allFields.title || allFields.yandexTitle || allFields.seoTitle || allFields.yandexDzenTitle) &&
      mainFieldsEditorDirty === true
    ) {
      const timer = setTimeout(async () => {
        const variablesData: UpdatePublicationInput = {
          publicationUid: publication.uid,
          title: allFields.title,
          yandexTitle: allFields.yandexTitle,
          yandexDzenTitle: allFields.yandexDzenTitle,
          subtitle: allFields.subtitle,
          isCommentsEnabled: !allFields.isCommentsDisabled,
          isAutomaticCommentsDisabled: allFields.isAutomaticCommentsDisabled,
          isImportant: allFields.isImportant,
          sendToRambler: allFields.sendToRambler,
          specProjectUrl: allFields.specProjectUrl || '',
          isInside: allFields.isInside,
          tagUids: allFields.tagsUids,
          storyUid: allFields.storyUid || null,
          collectionUids: allFields.collectionUids,
          subscriptionBlockUid: allFields.subscriptionBlockUid?.length ? allFields.subscriptionBlockUid : null,
          overviewTitle: allFields.overviewTitle,
          overviewDescription: allFields.overviewDescription,
          authors: allFields.authors || []
          // keywords: allFields.keywords
        }

        // if (defaultValues.yandexTitle !== allFields.yandexTitle) {
        //   variablesData['yandexTitle'] = allFields.yandexTitle
        // }

        if (allFields.seoTitle?.length) {
          variablesData.seoTitle = allFields.seoTitle
        }

        if (allFields.title !== defaultValues.title) {
          variablesData.overviewTitle = allFields.title || ''
          variablesData.yandexDzenTitle = allFields.title || ''
          // methodsForm.setValue('overviewTitle', allFields.title || '')
        }
        if (allFields.subtitle !== defaultValues.subtitle) {
          variablesData.overviewDescription = allFields.subtitle || ''
          // methodsForm.setValue('overviewDescription', allFields.subtitle || '')
        }

        // if (allFields.publishedAt) {
        //   variablesData['publishedAt'] = allFields.publishedAt
        // }

        /*
          Логика сохранения анонса для соц сетей:
          "Заголовок для соц. сетей" - текстовое поле с ограничением символов 100;
          Для новости: автоматически подставляет первое предложение из заголовка новости,
          Для статьи: автоматически подставляет все из заголовка статьи;
          Важно! Предзаполненное поле можно редактировать, что не влияет на основной заголовок публикации. 
          И если в основном заголовке публикации изменится текст, то он снова автоматически подтянется в "заголовок для соц. сетей", 
          заменив текущий текст.

          "Анонс для соц. сетей" - текстовое allFields.overviewTitleполе с ограничением символов 140;
          Для новости: автоматически подставляет второе предложение из заголовка новости (если второго предложения нет, то поле остается пустым),
          Для статей: поле автоматически заполняется из подзаголовка статьи;
          Важно! Предзаполненное поле можно редактировать, что не влияет на основной заголовок/подзаголовок публикации. 
          И если в основном заголовке/подзаголовке публикации изменится текст, то он снова автоматически подтянется в 
          "анонс для соц. сетей", заменив текущий текст.

          В опубликованной новости логика сохранения аналогичная.
        */

        let extAnnounceParamsChanged = false
        let newOgTitle = ''
        let newOgDescription = ''
        if (allFields.title !== defaultValues.title) {
          if (publication?.typeSlug === 'news') {
            newOgTitle = allFields.title?.length ? allFields.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''
            newOgDescription =
              (allFields?.title?.length || 0) > 1 ? allFields?.title?.split(SENTENCE_SEPARATOR_REG_EX)[1] || '' : ''
            extAnnounceParamsChanged = true
          } else {
            newOgTitle = allFields.title || ''
            newOgDescription = allFields.subtitle || ''
            extAnnounceParamsChanged = true
          }
        }

        if (allFields.subtitle !== defaultValues.subtitle && publication?.typeSlug === 'articles') {
          newOgDescription = allFields.subtitle || ''
          extAnnounceParamsChanged = true
        }

        if (extAnnounceParamsChanged) {
          setExtAnnounceParamsCache({
            ogTitle: newOgTitle,
            ogDescription: newOgDescription
          })
        }

        const updatePublicationRes = await updatePublicationMainFields({
          variables: {
            data: variablesData
          },
          fetchPolicy: 'no-cache'
        })

        const updatePublicationMainFieldsData = updatePublicationMainFieldsAdapterToClient(updatePublicationRes)

        if (updatePublicationMainFieldsData) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            authors: updatePublicationMainFieldsData?.authors,
            collections: updatePublicationMainFieldsData?.collections,
            isAutomaticCommentsDisabled: updatePublicationMainFieldsData?.isAutomaticCommentsDisabled,
            isCommentsEnabled: updatePublicationMainFieldsData?.isCommentsEnabled,
            isImportant: updatePublicationMainFieldsData?.isImportant,
            isInside: updatePublicationMainFieldsData?.isInside,
            overviewDescription: updatePublicationMainFieldsData?.overviewDescription,
            overviewTitle: updatePublicationMainFieldsData?.overviewTitle,
            sendToRambler: updatePublicationMainFieldsData?.sendToRambler,
            seoTitle: updatePublicationMainFieldsData?.seoTitle,
            specProjectUrl: updatePublicationMainFieldsData?.specProjectUrl,
            story: updatePublicationMainFieldsData?.story,
            subtitle: updatePublicationMainFieldsData?.subtitle,
            title: updatePublicationMainFieldsData?.title || '',
            yandexTitle: updatePublicationMainFieldsData?.yandexTitle,
            yandexDzenTitle: updatePublicationMainFieldsData?.yandexDzenTitle,
            deprecatedId: updatePublicationMainFieldsData?.deprecatedId
            // tagUids: updatePublicationData?.data?.updatePublication?.publication?.tagUids,
            // collectionUids: updatePublicationData?.data?.updatePublication?.publication?.collectionUids,
            // subscriptionBlockUid: updatePublicationData?.data?.updatePublication?.publication?.subscriptionBlockUid,
            // keywords: updatePublicationData?.data?.updatePublication?.publication?.keywords
          }))

          defaultValues.authors = updatePublicationMainFieldsData?.authors?.map(a => a.uid) || []
          defaultValues.isAutomaticCommentsDisabled =
            updatePublicationMainFieldsData?.isAutomaticCommentsDisabled || false
          defaultValues.isCommentsDisabled = !updatePublicationMainFieldsData?.isCommentsEnabled
          defaultValues.isImportant = updatePublicationMainFieldsData?.isImportant || false
          defaultValues.isInside = updatePublicationMainFieldsData?.isInside || false
          defaultValues.overviewDescription = updatePublicationMainFieldsData?.overviewDescription || ''
          defaultValues.overviewTitle = updatePublicationMainFieldsData?.overviewTitle || ''
          defaultValues.sendToRambler = updatePublicationMainFieldsData?.sendToRambler || false
          defaultValues.seoTitle = updatePublicationMainFieldsData?.seoTitle || ''
          defaultValues.subtitle = updatePublicationMainFieldsData?.subtitle || ''
          defaultValues.specProjectUrl = updatePublicationMainFieldsData?.specProjectUrl || ''
          defaultValues.storyUid = updatePublicationMainFieldsData?.story?.uid || null
          defaultValues.title = updatePublicationMainFieldsData?.title || ''
          defaultValues.yandexTitle = updatePublicationMainFieldsData?.yandexTitle || ''
          defaultValues.yandexDzenTitle = updatePublicationMainFieldsData?.yandexDzenTitle || ''
        }
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [
    control._formState.errors,
    mainFieldsEditorDirty,
    publication.uid,
    allFields.title,
    allFields.yandexTitle,
    allFields.yandexDzenTitle,
    allFields.seoTitle,
    allFields.subtitle,
    allFields.isCommentsDisabled,
    allFields.isAutomaticCommentsDisabled,
    allFields.isImportant,
    allFields.sendToRambler,
    allFields.specProjectUrl,
    allFields.overviewTitle,
    allFields.overviewDescription,
    allFields.overviewFigureId,
    allFields.overviewFigureVersionId,
    allFields.isInside,
    allFields.tagsUids,
    allFields.collectionUids,
    allFields.storyUid,
    allFields.subscriptionBlockUid,
    allFields.authors,
    updatePublicationMainFields,
    setExtAnnounceParamsCache,
    setPublicationWithPrevState,
    publication.status,
    publication.typeSlug,
    defaultValues.authors,
    defaultValues.title,
    defaultValues.subtitle
  ])

  /**
   * Сохранение параметров для ссылок по теме в черновиках
   */
  useEffect(() => {
    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      defaultValues?.relatedLinks?.length !== relatedLinks?.length
    ) {
      const timer = setTimeout(async () => {
        updateReleatedLinks()
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [relatedLinks, defaultValues?.relatedLinks, publication.status, updateReleatedLinks])

  /**
   * Сохранение параметров для фото выноса на главную в черновиках
   */
  useEffect(() => {
    if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) && overviewBlockFigureCache) {
      const timer = setTimeout(async () => {
        const overviewFigureVersionId = overviewBlockFigureCache?.figureVersionId || allFields.overviewFigureVersionId
        const overviewFigureId = overviewBlockFigureCache?.figureId || allFields.overviewFigureId
        const variablesData = {
          publicationUid: publication.uid,
          overviewFigureVersionId,
          overviewFigureId
        }

        const updatePublicationData = await updatePublicationOverviewFigure({
          variables: {
            data: variablesData
          }
        })

        if (!updatePublicationData?.errors?.length) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            // @ts-ignore
            overviewFigure: updatePublicationData?.data?.updatePublication?.publication?.overviewFigure,
            overviewFigureId:
              updatePublicationData?.data?.updatePublication?.publication?.overviewFigure?.figure?.id || '',
            overviewFigureVersionId:
              updatePublicationData?.data?.updatePublication?.publication?.overviewFigure?.id || ''
          }))
        }
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [
    publication.status,
    publication.uid,
    overviewBlockFigureCache,
    setPublicationWithPrevState,
    allFields.overviewFigureVersionId,
    allFields.overviewFigureId,
    updatePublicationOverviewFigure
  ])

  const moveBlocks = useRef<(val: OverviewBlockCache[]) => void>(null)

  /**
   * Сохранение параметров публикации при нажатии на кнопку "Сохранить" или "Опубликовать"
   */
  const handleOnSubmit = handleSubmit(async data => {
    try {
      if (!agentCheckStore.isSave) {
        const agentCheck = new AgentCheckService({
          publicationStore: publicationStore,
          agentCheckStore: agentCheckStore,
          setPublicationWithPrevState: setPublicationWithPrevState
        })
        agentCheck.checkAgents('Сохранить', data, editorRef, publication)
        agentCheckStore.setSave(true)
        return
      }
      const quill = getQuillEditor(editorRef)
      let elements: ElementUnionInput[] = []

      if (quill) {
        const quotesReplacer = new TypographReplacer()
        quotesReplacer.runTypograph(quill.container)
        elements = adaptQuillRequest({quillChildren: [...quill.root.children]})
      }

      const changeDestinationEvent = new CustomEvent('publicationDestinationChange', {detail: publication.uid})
      await window.dispatchEvent(changeDestinationEvent)
      const promises: Promise<any>[] = []

      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            const updateContentElementsResponse = await updateContentElements({
              variables: {
                data: {
                  publicationUid: publication.uid,
                  elements
                }
              }
            })
            resolve(updateContentElementsResponse)
          } catch (error) {
            reject(error)
          }
        })
      )

      updateReleatedLinks()

      const savePublicationEvent = new CustomEvent('savePublicationEvent', {
        detail: publication,
        bubbles: true
      })
      window.dispatchEvent(savePublicationEvent)

      const variablesData: UpdatePublicationInput = {
        publicationUid: publication.uid,
        title: data.title,
        yandexTitle: data.yandexTitle,
        yandexDzenTitle: data.yandexDzenTitle,
        seoTitle: data.seoTitle,
        subtitle: data.subtitle,
        isCommentsEnabled: !data.isCommentsDisabled,
        isAutomaticCommentsDisabled: data.isAutomaticCommentsDisabled,
        isImportant: data.isImportant,
        sendToRambler: data.sendToRambler,
        specProjectUrl: data.specProjectUrl || '',
        regionUids: data.regionUids,
        overviewTitle: data.overviewTitle || '',
        overviewDescription: data.overviewDescription || '',
        isInside: data.isInside,
        tagUids: data.tagsUids,
        collectionUids: data.collectionUids,
        subscriptionBlockUid: data.subscriptionBlockUid?.length ? allFields.subscriptionBlockUid : null,
        storyUid: allFields.storyUid?.length ? allFields.storyUid : null,
        rubricId: rubricIdCache?.length ? rubricIdCache : null,
        subrubricId: subrubricIdCache?.length ? subrubricIdCache : null,
        authors: data.authors?.filter(author => author),
        keywords: data.keywords,
        announceImage: {
          figureVersionId:
            announceFigureCache?.figureVersionId ||
            allFields.announceImage?.figureVersion?.id ||
            publication?.announceImage?.figureVersion?.id,
          isFullWidth:
            announceFigureCache?.isFullWidth ??
            allFields.announceImage?.isFullWidth ??
            publication?.announceImage?.isFullWidth ??
            false,
          description:
            announceFigureCache?.description ||
            allFields.announceImage?.description ||
            publication?.announceImage?.description ||
            '',
          author:
            announceFigureCache?.author || allFields.announceImage?.author || publication?.announceImage?.author || '',
          alt: announceFigureCache?.alt || allFields.announceImage?.alt || publication?.announceImage?.alt || ''
        },
        socialMediaImage: {
          alignY: socialImageCache?.socialMediaFigureVersion
            ? socialImageCache?.socialMediaFigureVersion.alignY
            : allFields.alygnY || 0
        },
        overviewFigureVersionId:
          overviewBlockFigureCache?.figureVersionId ||
          allFields.overviewFigureVersionId ||
          publication?.overviewFigure?.id,
        overviewFigureId:
          overviewBlockFigureCache?.figureId || allFields.overviewFigureId || publication?.overviewFigureId
      }

      /*
    Логика сохранения анонса для соц сетей указана в черновиках
    */
      if (data.title !== defaultValues.title) {
        if (publication?.typeSlug === 'news') {
          variablesData.ogTitle = data.title?.length ? data.title.split(SENTENCE_SEPARATOR_REG_EX)[0] : ''
          variablesData.ogDescription = data.title?.length > 1 ? data.title.split(SENTENCE_SEPARATOR_REG_EX)[1] : ''
        } else {
          variablesData.ogTitle = data.title
          variablesData.ogDescription = data.subtitle
        }
      } else if (extAnnounceParamsCache) {
        variablesData.ogTitle = extAnnounceParamsCache?.ogTitle || allFields.ogTitle || publication?.ogTitle
        variablesData.ogDescription =
          extAnnounceParamsCache?.ogDescription || allFields.ogDescription || publication?.ogDescription
      }

      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            const updatePublicationResponse = await updatePublication({
              variables: {
                data: variablesData
              }
            })

            resolve(updatePublicationResponse)
          } catch (error) {
            reject(error)
          }
        })
      )

      await Promise.all(promises)

      setRubricId(rubricIdCache)
      setSubrubricId(subrubricIdCache)

      if (publication.status !== PublicationStatus.Unpublished) {
        const publishPublicationRes = await publishPublication({
          variables: {
            data: {
              publicationUid: publication?.uid,
              publishedAt: allFields.publishedAt
            }
          }
        })

        const publishPublicationData = publishPublicationAdapterToClient(publishPublicationRes)

        if (publishPublicationData) {
          methodsForm.setValue(
            'announceFigureVersionId',
            publishPublicationData?.announceImage?.figureVersion?.id || ''
          )
          methodsForm.setValue('publishedAt', publishPublicationData.publishedAt || null)
          methodsForm.setValue('overviewTitle', publishPublicationData.overviewTitle || '')
          methodsForm.setValue('yandexDzenTitle', publishPublicationData.yandexDzenTitle || '')
          methodsForm.setValue('ogTitle', publishPublicationData.ogTitle || '')
          methodsForm.setValue('ogDescription', publishPublicationData.ogDescription || '')
          figuresCache.length = 0
          setEditorDirty(false)
          setMainFieldsEditorDirty(false)
          setPublicationFormDirty(false)
          setAnnounceFigureCache(undefined)
          setExtAnnounceParamsCache(undefined)

          setPublicationWithPrevState(prev => ({
            ...prev,
            announceFigureVersionId: publishPublicationData?.announceImage?.figureVersion?.id || '',
            announceImage: publishPublicationData?.announceImage as UiAnnounceImage,
            alygnY: publishPublicationData?.socialMediaImage?.socialMediaFigureVersion?.alignY || 0,
            authors: publishPublicationData?.authors || [],
            badwords: publishPublicationData?.badwords || false,
            canonicalUrl: publishPublicationData.canonicalUrl || '',
            cantEditPublication: cantEditPublication,
            collectionUids: publishPublicationData?.collections?.map(v => v?.uid) || [],
            destinationExport: {
              destinationSlugs: (publishPublicationData?.destinations?.map(v => v?.slug) as string[]) || [],
              yandexZenCategoryUids: (publishPublicationData?.yandexZenCategories?.map(v => v?.id) as string[]) || []
            } as UpdatePublicationExportInput,
            destinationIds: (publishPublicationData?.destinations?.map(v => v?.id) as string[]) || [],
            yandexTitle: publishPublicationData?.yandexTitle,
            yandexDzenTitle: publishPublicationData?.yandexDzenTitle,
            imageUrl: '',
            isCommentsEnabled: publishPublicationData?.isCommentsEnabled,
            isImportant: publishPublicationData?.isImportant,
            isInside: publishPublicationData?.isInside,
            isCommentsDisabled: !(publishPublicationData?.isCommentsEnabled as boolean),
            keywords: publishPublicationData?.keywords,
            ogDescription: publishPublicationData?.ogDescription || '',
            ogTitle:
              publishPublicationData?.ogTitle ||
              (publishPublicationData?.title?.length
                ? publishPublicationData?.title.split(SENTENCE_SEPARATOR_REG_EX)[0]
                : ''),
            overviewDescription: publishPublicationData?.overviewDescription,
            overviewFigureId: publishPublicationData?.overviewFigureId,
            overviewFigureVersionId: publishPublicationData?.overviewFigureVersionId,
            overviewTitle: publishPublicationData?.overviewTitle,
            publishedAt:
              publishPublicationData?.status === PublicationStatus.Published
                ? publishPublicationData?.publishedAt
                : null,
            regions: (publishPublicationData?.regions as UiRegion[]) || [],
            regionUids: publishPublicationData?.regions?.map(v => v?.uid) || [],
            relatedLinks: publishPublicationData?.relatedLinks || [],
            sendToRambler: publishPublicationData?.sendToRambler,
            socialMediaFigureVersion: publishPublicationData?.socialMediaImage?.socialMediaFigureVersion,
            specProjectUrl: publishPublicationData?.specProjectUrl,
            subscriptionBlockUid: publishPublicationData?.subscriptionBlock?.uid || '',
            subtitle: publishPublicationData?.subtitle,
            seoTitle: publishPublicationData?.seoTitle,
            title: publishPublicationData?.title,
            story: publishPublicationData?.story,
            storyUid: publishPublicationData?.story?.uid || null,
            tagsUids: publishPublicationData?.tags?.map(v => v?.uid) || []
          }))

          defaultValues.title = publishPublicationData?.title || ''
          defaultValues.yandexTitle = publishPublicationData?.yandexTitle || ''
          defaultValues.yandexDzenTitle = publishPublicationData?.yandexDzenTitle || ''
          defaultValues.subtitle = publishPublicationData?.subtitle || ''
          defaultValues.seoTitle = publishPublicationData?.seoTitle || ''
          defaultValues.ogTitle = publishPublicationData?.ogTitle || ''
          defaultValues.ogDescription = publishPublicationData?.ogDescription || ''
          defaultValues.announceFigureVersionId = publishPublicationData?.announceImage?.figureVersion?.id || ''
          defaultValues.isCommentsDisabled = !publishPublicationData?.isCommentsEnabled
          defaultValues.isImportant = publishPublicationData?.isImportant || false
          defaultValues.sendToRambler = publishPublicationData?.sendToRambler || false
          defaultValues.specProjectUrl = publishPublicationData?.specProjectUrl || ''
          defaultValues.isInside = publishPublicationData?.isInside || false
          defaultValues.storyUid = publishPublicationData?.story?.uid || null
          defaultValues.overviewTitle = publishPublicationData?.overviewTitle || ''
          defaultValues.overviewDescription = publishPublicationData?.overviewDescription || ''
          defaultValues.authors = publishPublicationData?.authors?.map(a => a.uid) || []
          defaultValues.keywords = publishPublicationData?.keywords || ''
          defaultValues.overviewFigureId = publishPublicationData?.overviewFigureId
          defaultValues.overviewFigureVersionId = publishPublicationData?.overviewFigureVersionId
          defaultValues.announceImage = publishPublicationData?.announceImage
          defaultValues.yandexNewsVideos = publishPublicationData?.yandexNewsVideos || []

          defaultValues.subscriptionBlockUid = publishPublicationData?.subscriptionBlock?.uid || ''
          defaultValues.regionUids = publishPublicationData?.regions?.map(v => v?.uid) || []
          defaultValues.tagsUids = publishPublicationData?.tags?.map(v => v?.uid) || []
          defaultValues.collectionUids = publishPublicationData?.collections?.map(v => v?.uid) || []
          defaultValues.publishedAt = publishPublicationData?.publishedAt || new Date()
          defaultValues.destinationExport = {
            destinationSlugs: (publication?.destinations?.map(v => v?.slug) as string[]) || [],
            yandexZenCategoryUids: (publication?.yandexZenCategories?.map(v => v?.id) as string[]) || []
          } as UpdatePublicationExportInput
          defaultValues.relatedLinks = publishPublicationData?.relatedLinks || []
          defaultValues.destinationIds = (publishPublicationData?.destinations?.map(v => v?.id) as string[]) || []
          defaultValues.socialMediaFigureVersion = publishPublicationData?.socialMediaImage?.socialMediaFigureVersion
          defaultValues.alygnY = publishPublicationData?.socialMediaImage?.socialMediaFigureVersion?.alignY || 0
        }
      }

      if (overviewBlocksCache.length) {
        moveBlocks?.current && moveBlocks?.current(overviewBlocksCache)
      }
      if (
        keycloak.hasResourceRole('check-bildeditor', 'publications') &&
        checkingPhotoEditor !== checkingPhotoEditorCache
      ) {
        const checkingPhotoEditorData = {
          publicationUid: publication.uid,
          status: checkingPhotoEditorCache || false
        }

        const checkingPhotoEditorResult = await setCheckingPhotoEditorAction({
          variables: {
            data: checkingPhotoEditorData
          }
        })

        if (!checkingPhotoEditorResult?.errors?.length) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            checkingPhotoEditor: checkingPhotoEditorData.status
          }))

          setCheckingPhotoEditor(checkingPhotoEditorData.status || false)
        }
      }
      toast.success(PUBLICATION_FORM_CONSTS.savedMsg, {
        autoClose: false,
        hideProgressBar: true,
        containerId: 'success'
      })
    } catch (error) {
      agentCheckStore.stopChecking()
      console.error(error)
    }
  })

  useEffect(() => {
    publicationStore.registerSubmitHandler(handleOnSubmit)
    return () => {
      publicationStore.unregisterSubmitHandler()
    }
  }, [handleOnSubmit, publicationStore])

  useEffect(() => {
    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      overviewBlocksCache.length &&
      (allFields.title || allFields.yandexTitle || allFields.seoTitle || allFields.yandexDzenTitle)
    ) {
      const timer = setTimeout(async () => {
        moveBlocks?.current && moveBlocks?.current(overviewBlocksCache)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [
    overviewBlocksCache,
    publication.status,
    allFields.title,
    allFields.yandexTitle,
    allFields.yandexDzenTitle,
    allFields.seoTitle,
    editorDirty
  ])

  useEffect(() => {
    setImageModalLoading(
      updatePublicationMainFieldsLoading ||
        updatePublicationAnnounceFieldsLoading ||
        updatePublicationExtAnnounceParamsLoading ||
        updateSocialImageOnlyLoading
    )
  }, [
    updatePublicationMainFieldsLoading,
    updatePublicationAnnounceFieldsLoading,
    updatePublicationExtAnnounceParamsLoading,
    updateSocialImageOnlyLoading,
    setImageModalLoading
  ])

  useEffect(() => {
    setPublicationLoading(
      updateContentElementsLoading ||
        updateSocialImageOnlyLoading ||
        updatePubliationLoading ||
        publishLoading ||
        createRelatedLinkLoading ||
        deleteRelatedLinkLoading ||
        updatePublicationMainFieldsLoading ||
        updatePublicationAnnounceFieldsLoading ||
        // updatePubliationRubricAndSubrubricLoading ||
        setCheckingPhotoEditorLoading ||
        updatePublicationOverviewFigureLoading
    )
    setPublicationContentLoading(updateContentElementsLoading)
  }, [
    updateContentElementsLoading,
    updateSocialImageOnlyLoading,
    updatePubliationLoading,
    publishLoading,
    createRelatedLinkLoading,
    deleteRelatedLinkLoading,
    updatePublicationMainFieldsLoading,
    updatePublicationAnnounceFieldsLoading,
    // updatePubliationRubricAndSubrubricLoading ||
    setCheckingPhotoEditorLoading,
    updatePublicationOverviewFigureLoading,
    setPublicationLoading,
    setPublicationContentLoading
  ])

  const curDeprecatedId = publication?.deprecatedId || 0

  return (
    <FormProvider {...methodsForm}>
      <PublicationFormWrapper noValidate onSubmit={handleOnSubmit}>
        <PublicationFormContent>
          <PublicationFormContentHeader>
            <PublicationFormContentHeaderTitle>
              <span>{PUBLICATION_FORM_CONSTS.editMsg}&nbsp;</span>
              {publication?.typeSlug === 'news' ? (
                <span>{PUBLICATION_FORM_CONSTS.newsMsg}</span>
              ) : (
                <span>{PUBLICATION_FORM_CONSTS.articleMsg}</span>
              )}
            </PublicationFormContentHeaderTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <PublicationSendMenu />
              <PublicationInfo
                publicationId={publication?.id}
                publicationAuthorUid={(publication?.authors?.length && publication?.authors[0].uid) || ''}
                publicationCreatedAt={publication?.createdAt || ''}
              />
              <PublicationDebugInfo
                id={publication?.id || ''}
                oldAdminUrl={publication?.oldAdminUrl || ''}
                url={publication?.url || ''}
                deprecatedId={curDeprecatedId}
              />
            </div>
          </PublicationFormContentHeader>
          {publication.status === 'UNPUBLISHED' && <UnpublishedNewsSignboard redirectUrl={publication?.redirectUrl} />}
          <PublicationFormContentWrapper ref={publicationFormRef}>
            <PublicationFormAnnounceImageWrapper
              allFieldsAlygnY={allFields?.alygnY || 0}
              allFieldsTitle={allFields?.title || ''}
              changeOgParams={changeOgParams}
              defaultValues={defaultValues}
            />
            <PublicationFormTitles methodsForm={methodsForm} cantEditPublication={cantEditPublication} />
          </PublicationFormContentWrapper>
          <Editor methodsForm={methodsForm} publicationHeaderHeight={publicationFormRef?.current?.offsetHeight || 0} />
        </PublicationFormContent>
        <PublicationFormOverviewBlocks
          moveBlocks={moveBlocks}
          setRelatedLinks={setRelatedLinks}
          setOverviewBlockFigureCache={setOverviewBlockFigureCache}
        />
      </PublicationFormWrapper>
    </FormProvider>
  )
}

export default observer(PublicationForm)
