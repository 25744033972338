export const TEXT_EDITOR_SIDEBAR_MODAL_CONSTS = {
    addMsg: 'Добавить',
    incorrectLinkMsg: 'Некорректная ссылка',
    linkOnNewsMsg: 'Ссылка на новость',
    newsByThemeMsg: 'НОВОСТЬ ПО ТЕМЕ',
    publicationNotFound: 'Публикация не найдена',
    publicationTitleMsg: 'Заголовок публикации',
    saveMsg: 'Сохранить',
    sidebarCreationMsg: 'Создание врезки'
}
