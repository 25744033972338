import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type HotNewsBlockQueryVariables = Types.Exact<{
  regionUid: Types.Scalars['UUID']
}>

export type HotNewsBlockQuery = {__typename?: 'Query'} & {
  hotNewsBlock?: Types.Maybe<{__typename?: 'HotNewsBlock'} & HotNewsFragmentFragment>
}

export type UpdateHotNewsBlockMutationVariables = Types.Exact<{
  data: Types.UpdateHotNewsBlockInput
}>

export type UpdateHotNewsBlockMutation = {__typename?: 'Mutation'} & {
  updateHotNewsBlock?: Types.Maybe<
    {__typename?: 'UpdateHotNewsBlockPayload'} & Pick<Types.UpdateHotNewsBlockPayload, 'status'>
  >
}

export type UpdateHotNewsBlockForAllRegionsMutationVariables = Types.Exact<{
  data: Types.UpdateHotNewsBlockForAllRegionsInput
}>

export type UpdateHotNewsBlockForAllRegionsMutation = {__typename?: 'Mutation'} & {
  updateHotNewsBlockForAllRegions?: Types.Maybe<
    {__typename?: 'UpdateHotNewsBlockForAllRegionsPayload'} & Pick<
      Types.UpdateHotNewsBlockForAllRegionsPayload,
      'status'
    >
  >
}

export type DeleteHotNewsBlockMutationVariables = Types.Exact<{
  data: Types.DeleteHotNewsBlockInput
}>

export type DeleteHotNewsBlockMutation = {__typename?: 'Mutation'} & {
  deleteHotNewsBlock?: Types.Maybe<
    {__typename?: 'DeleteHotNewsBlockPayload'} & Pick<Types.DeleteHotNewsBlockPayload, 'status'>
  >
}

export type HotNewsFragmentFragment = {__typename?: 'HotNewsBlock'} & Pick<
  Types.HotNewsBlock,
  'clickCount' | 'createdAt' | 'id' | 'subtitle' | 'title' | 'uid' | 'updatedAt' | 'url'
>

export const HotNewsFragmentFragmentDoc = gql`
  fragment HotNewsFragment on HotNewsBlock {
    clickCount
    createdAt
    id
    subtitle
    title
    uid
    updatedAt
    url
  }
`
export const HotNewsBlockDocument = gql`
  query hotNewsBlock($regionUid: UUID!) {
    hotNewsBlock(regionUid: $regionUid) {
      ...HotNewsFragment
    }
  }
  ${HotNewsFragmentFragmentDoc}
`

/**
 * __useHotNewsBlockQuery__
 *
 * To run a query within a React component, call `useHotNewsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotNewsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotNewsBlockQuery({
 *   variables: {
 *      regionUid: // value for 'regionUid'
 *   },
 * });
 */
export function useHotNewsBlockQuery(
  baseOptions: Apollo.QueryHookOptions<HotNewsBlockQuery, HotNewsBlockQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<HotNewsBlockQuery, HotNewsBlockQueryVariables>(HotNewsBlockDocument, options)
}
export function useHotNewsBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HotNewsBlockQuery, HotNewsBlockQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<HotNewsBlockQuery, HotNewsBlockQueryVariables>(HotNewsBlockDocument, options)
}
export type HotNewsBlockQueryHookResult = ReturnType<typeof useHotNewsBlockQuery>
export type HotNewsBlockLazyQueryHookResult = ReturnType<typeof useHotNewsBlockLazyQuery>
export type HotNewsBlockQueryResult = Apollo.QueryResult<HotNewsBlockQuery, HotNewsBlockQueryVariables>
export const UpdateHotNewsBlockDocument = gql`
  mutation updateHotNewsBlock($data: UpdateHotNewsBlockInput!) {
    updateHotNewsBlock(data: $data) {
      status
    }
  }
`
export type UpdateHotNewsBlockMutationFn = Apollo.MutationFunction<
  UpdateHotNewsBlockMutation,
  UpdateHotNewsBlockMutationVariables
>

/**
 * __useUpdateHotNewsBlockMutation__
 *
 * To run a mutation, you first call `useUpdateHotNewsBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHotNewsBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHotNewsBlockMutation, { data, loading, error }] = useUpdateHotNewsBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateHotNewsBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHotNewsBlockMutation, UpdateHotNewsBlockMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateHotNewsBlockMutation, UpdateHotNewsBlockMutationVariables>(
    UpdateHotNewsBlockDocument,
    options
  )
}
export type UpdateHotNewsBlockMutationHookResult = ReturnType<typeof useUpdateHotNewsBlockMutation>
export type UpdateHotNewsBlockMutationResult = Apollo.MutationResult<UpdateHotNewsBlockMutation>
export type UpdateHotNewsBlockMutationOptions = Apollo.BaseMutationOptions<
  UpdateHotNewsBlockMutation,
  UpdateHotNewsBlockMutationVariables
>
export const UpdateHotNewsBlockForAllRegionsDocument = gql`
  mutation updateHotNewsBlockForAllRegions($data: UpdateHotNewsBlockForAllRegionsInput!) {
    updateHotNewsBlockForAllRegions(data: $data) {
      status
    }
  }
`
export type UpdateHotNewsBlockForAllRegionsMutationFn = Apollo.MutationFunction<
  UpdateHotNewsBlockForAllRegionsMutation,
  UpdateHotNewsBlockForAllRegionsMutationVariables
>

/**
 * __useUpdateHotNewsBlockForAllRegionsMutation__
 *
 * To run a mutation, you first call `useUpdateHotNewsBlockForAllRegionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHotNewsBlockForAllRegionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHotNewsBlockForAllRegionsMutation, { data, loading, error }] = useUpdateHotNewsBlockForAllRegionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateHotNewsBlockForAllRegionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHotNewsBlockForAllRegionsMutation,
    UpdateHotNewsBlockForAllRegionsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateHotNewsBlockForAllRegionsMutation, UpdateHotNewsBlockForAllRegionsMutationVariables>(
    UpdateHotNewsBlockForAllRegionsDocument,
    options
  )
}
export type UpdateHotNewsBlockForAllRegionsMutationHookResult = ReturnType<
  typeof useUpdateHotNewsBlockForAllRegionsMutation
>
export type UpdateHotNewsBlockForAllRegionsMutationResult =
  Apollo.MutationResult<UpdateHotNewsBlockForAllRegionsMutation>
export type UpdateHotNewsBlockForAllRegionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateHotNewsBlockForAllRegionsMutation,
  UpdateHotNewsBlockForAllRegionsMutationVariables
>
export const DeleteHotNewsBlockDocument = gql`
  mutation deleteHotNewsBlock($data: DeleteHotNewsBlockInput!) {
    deleteHotNewsBlock(data: $data) {
      status
    }
  }
`
export type DeleteHotNewsBlockMutationFn = Apollo.MutationFunction<
  DeleteHotNewsBlockMutation,
  DeleteHotNewsBlockMutationVariables
>

/**
 * __useDeleteHotNewsBlockMutation__
 *
 * To run a mutation, you first call `useDeleteHotNewsBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHotNewsBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHotNewsBlockMutation, { data, loading, error }] = useDeleteHotNewsBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteHotNewsBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteHotNewsBlockMutation, DeleteHotNewsBlockMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<DeleteHotNewsBlockMutation, DeleteHotNewsBlockMutationVariables>(
    DeleteHotNewsBlockDocument,
    options
  )
}
export type DeleteHotNewsBlockMutationHookResult = ReturnType<typeof useDeleteHotNewsBlockMutation>
export type DeleteHotNewsBlockMutationResult = Apollo.MutationResult<DeleteHotNewsBlockMutation>
export type DeleteHotNewsBlockMutationOptions = Apollo.BaseMutationOptions<
  DeleteHotNewsBlockMutation,
  DeleteHotNewsBlockMutationVariables
>
