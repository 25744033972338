import {Button, FormControlLabel, TextField, Typography} from '@mui/material'
import styled from 'styled-components'
import {blueColor, grayColor, screenM, whiteColor} from '../../../../@theme/vars'
import {StyledFormControl} from '../../../../components/UI/styled'

export const HeaderWithBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
`
export const Title = styled(Typography)`
  flex: 1;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #222222;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 22px;
`
export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${screenM}) {
    flex-direction: column;
    align-items: center;
  }

  & #top-news-edit-image {
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    & figure {
      width: 240px;

      @media screen and (max-width: 750px) {
        width: 100%;
      }

      & .change-item {
        position: absolute;
        opacity: 0.8;
        border-radius: 80%;
        height: 26px;
        width: 26px;
        margin: 16px 6px 6px 0px;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }

      & .change-item {
        margin-left: 200px;

        @media screen and (max-width: 750px) {
          margin-left: calc(100% - 110px);
        }
      }
    }
  }
`

export const ImageFlex = styled.div<{url: string}>`
  width: 240px;
  height: 171px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url('${({url}) => url}');
  @media (max-width: ${screenM}) {
    order: 2;
    margin-right: 0;
    width: 340px;
    height: 242px;
    margin: 24px 0;
  }
`
export const StyledDeleteButton = styled(Button)`
  width: 158px;
  height: 42px;
  margin-left: 137px;
  color: ${blueColor};
  border-radius: 4px;
  gap: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  @media (max-width: ${screenM}) {
    order: 1;
    margin-left: 0;
    width: 340px;
  }
`

export const InfoContainersFlex = styled.div`
  width: 417px;
  height: 42px;
  gap: 12px;
  margin-left: 20px;
  @media (max-width: ${screenM}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 3;
    gap: 8px;
  }
`

export const PriorityFormControl = styled(StyledFormControl)`
  width: 95px;
  height: 42px;
  margin-right: 12px;
  @media (max-width: ${screenM}) {
    margin-right: 0;
    align-self: flex-start;
    margin-left: 38px;
  }
`
export const UrlFormControl = styled(StyledFormControl)`
  width: 417px;
  height: 42px;
  @media (max-width: ${screenM}) {
    width: 340px;
  }
`

export const TitleFormControl = styled(StyledFormControl)`
  width: 359px;
  height: 42px;
  margin-top: 12px;
  margin-bottom: 12px;
  @media (max-width: ${screenM}) {
    margin-bottom: 0;
    margin-top: 0;
  }
`

export const StyledSaveButton = styled(Button)`
  width: 417px;
  height: 42px;
  color: ${whiteColor};
  border-radius: 4px;
  border: 1px;
  padding: 11px 26px;
  gap: 4px;
  margin-top: 20px;
  @media (max-width: ${screenM}) {
    margin-top: 0;
    width: 340px;
  }
`

export const TitleLength = styled.div`
  width: 38px;
  height: 17px;
  margin-left: 20px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  color: ${grayColor};
  @media (max-width: ${screenM}) {
    font-size: 9px;
    height: auto;
  }
`
export const TitleWithLengthContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  @media (max-width: ${screenM}) {
    width: 340px;
  }
`
export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 12px;
  @media (max-width: ${screenM}) {
    align-self: flex-start;
    margin-left: 26px;
  }
`

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 5px;
`

export const TextFieldWrapper = styled(TextField)`
  margin-top: 18px;
  width: 100%;

  > div {
    padding: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    > input {
      padding: 8.5px 14px;
    }
  }
`
