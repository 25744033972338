import styled from 'styled-components'
import {secondTextColor} from '../../../../@theme/vars'

export const ToastErrorCollapserWrapper = styled.div`
  position: absolute;
  padding: 11px 18px;
  vertical-align: middle;
  text-align: center;
  bottom: 80px;
  right: 15px;
  z-index: 999999;
  width: 300px;
  height: 32px;
  background: #edeef1;
  border-radius: 4px;
  color: ${secondTextColor};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  display: none;

  @media screen and (max-width: 840px) {
    bottom: 145px;
  }
`
