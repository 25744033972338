import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {Paper} from '@mui/material'
import {
  LeftBlockWrapper,
  RetryButtonWrapper,
  ShowDetailButtonWrapper,
  TitleErrorWrapper,
  ToastErrorWrapper
} from './ToastError.styles'
import {TOAST_ERROR_CONSTS} from './ToastError.consts'
import {DoNotDistrubIcon} from '../../Icons/DoNotDistrub'
import ReactJson from 'react-json-view'
import {useMutation, useQuery} from '@apollo/client'
import {toast} from 'react-toastify'
import {useOptionalMutation, useOptionalQuery} from '../../../../hooks/optionalQueryAndMutation'

type ToastErrorProps = {
  text: string
  detailInfo: any
  id: string
  canRetry: boolean
  operation: any
}

export const ToastError: FC<ToastErrorProps> = ({text, detailInfo, id, canRetry, operation}) => {
  const toastId: any = useRef(null)

  const [showDetail, setShowDetail] = useState(false)

  const toggleDetail = useCallback(
    prev => {
      setShowDetail(!prev)
    },
    [setShowDetail]
  )

  const isQuery =
    operation?.query?.definitions?.length && operation?.query?.definitions[0].operation === 'query' ? true : false

  const [skipQuery, setSkipQuery] = useState(true)

  const [operationMutation] = useOptionalMutation(operation.query, {
    variables: operation.variables,
    skip: isQuery ? true : false
  })

  const operationQuery = useOptionalQuery(operation.query || '', {
    variables: operation.variables,
    skip: skipQuery
  })

  const retry = useCallback(() => {
    if (toastId?.current) {
      toast.dismiss(toastId?.current?.id || undefined)
      if (operation?.query?.definitions?.length && operation?.query?.definitions[0].operation === 'query') {
        setSkipQuery(false)
      } else {
        operationMutation(operation.variables)
      }
    }
  }, [toastId, operation])

  return (
    <ToastErrorWrapper ref={toastId} id={id}>
      <LeftBlockWrapper>
        <DoNotDistrubIcon />
      </LeftBlockWrapper>
      <div
        style={{
          padding: '0px 10px 8px 0px'
        }}
      >
        <TitleErrorWrapper>
          {TOAST_ERROR_CONSTS.errorMsg}: {text}
        </TitleErrorWrapper>
        <div>
          <ShowDetailButtonWrapper variant='contained' onClick={() => toggleDetail(showDetail)}>
            {showDetail ? TOAST_ERROR_CONSTS.hideMsg : TOAST_ERROR_CONSTS.moreInfoMsg}
          </ShowDetailButtonWrapper>
          {canRetry && (
            <RetryButtonWrapper variant='outlined' onClick={retry} aria-label='close'>
              {TOAST_ERROR_CONSTS.retryMsg}
            </RetryButtonWrapper>
          )}
        </div>
        {showDetail && (
          <Paper
            style={{
              marginTop: '10px',
              padding: '5px'
            }}
          >
            <ReactJson src={detailInfo} />
          </Paper>
        )}
      </div>
    </ToastErrorWrapper>
  )
}
