import {Clear} from '@mui/icons-material'
import {Box, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'
import {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {getCommentsCountGroupByPublicationUidAdapterToClient} from '@entities/api/comments/adapters/getCommentsCountGroupByPublicationUidAdapterToClient'
import TableHeaderCell from '@components/UI/Table/TableHeaderCell/TableHeaderCell'
import {NothingFounded} from '@components/UI/NothingFounded/NothingFounded'
import {CommentsSort} from '@graphql/types'
import {UiPageInfo} from 'entities/types/UiPageInfo'
import InfiniteScrollWithScrollToTop from '@components/InfiniteScrollWithScrollToTop/InfiniteScrollWithScrollToTop'
import {Spinner} from '@components/UI/Spinner/Spinner'
import {grayBorder} from '@theme/vars'
import {UiCommentsWithCommentsCountEdge} from '@entities/types/UiCommentsEdge'
import {UiCommentsConnectionWithCommentsCount} from '@entities/types/UiCommentsConnection'
import {useStore} from '@stores/rootStoreContext'
import LastPublicationCommentItem from '../LastPublicationCommentItem/LastPublicationCommentItem'
import {useGetCommentsCountGroupByPublicationUidLazyQuery} from '../gql/Comments.generated'
import {LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS} from './LastPublicationCommentsContent.consts'
import {
  FiltersWrapper,
  SpinnerWrapper,
  StyledFormControlWrapper,
  TableHeadRow,
  TableHeadWrapper,
  TableWrapper
} from './LastPublicationCommentsContent.styles'
import {getCommentsGroupByPublicationUidSortByFieldName} from './LastPublicationCommentsContent.hooks'

const LastPublicationCommentsContent: FC = () => {
  const [searchTitle, setSearchTitle] = useState('')
  const [searchTitleValue, setSearchTitleValue] = useState('')
  const [tableData, setTableData] = useState<UiCommentsWithCommentsCountEdge[]>([])
  const [tableDataFiltered, setTableDataFiltered] = useState<UiCommentsWithCommentsCountEdge[]>([])
  const [nothingFounded, setNothingFounded] = useState(false)
  const [pageInfo, setPageInfo] = useState<UiPageInfo>({
    endCursor: '',
    hasNextPage: true,
    hasPreviousPage: false,
    startCursor: ''
  })

  const [sortField, setSortField] = useState<CommentsSort>(CommentsSort.GroupByDescRc)
  const {tableStore} = useStore()
  const {sortFieldName, sortDirection} = tableStore

  useEffect(() => {
    const newSortField = getCommentsGroupByPublicationUidSortByFieldName(sortFieldName, sortDirection)
    setSortField(newSortField)
  }, [sortFieldName, sortDirection])

  useEffect(() => {
    setSearchTitleValue(searchTitle.trim())
  }, [searchTitle])

  useEffect(() => {
    const filteredData = tableData.filter(item =>
      item.node?.publication?.title?.toLowerCase().includes(searchTitleValue.toLowerCase())
    )
    setTableDataFiltered(filteredData)
    setNothingFounded(filteredData?.length > 0 ? false : true)
  }, [tableData, searchTitleValue])

  const [getCommentsCountGroupByPublicationUidLazyQuery, {loading, fetchMore, refetch}] =
    useGetCommentsCountGroupByPublicationUidLazyQuery()

  const getPublicationsWithCommentsCountLazyQueryAction = useCallback(
    async (sort: CommentsSort) => {
      const publicationsWithCommentsCountRes = await getCommentsCountGroupByPublicationUidLazyQuery({
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
        variables: {
          sort
        },
        notifyOnNetworkStatusChange: true
      })

      if (publicationsWithCommentsCountRes.data?.comments) {
        const publicationsWithCommentsCountData: UiCommentsConnectionWithCommentsCount =
          getCommentsCountGroupByPublicationUidAdapterToClient(publicationsWithCommentsCountRes?.data?.comments)

        setPageInfo(publicationsWithCommentsCountData.pageInfo)

        if (publicationsWithCommentsCountData.pageInfo.hasPreviousPage) {
          setTableData(prev => [...prev, ...publicationsWithCommentsCountData.edges])
          return
        }

        setTableData(publicationsWithCommentsCountData.edges)
        return
      }
      setTableData([])
    },
    [getCommentsCountGroupByPublicationUidLazyQuery]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setTableData([])
      getPublicationsWithCommentsCountLazyQueryAction(sortField)
    }, 500)
    return () => clearTimeout(timer)
  }, [sortField, getPublicationsWithCommentsCountLazyQueryAction])

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first: 10,
        sort: sortField
        // status: PublicationStatus.Published
      }
    }).then(data => {
      if (data?.data?.comments) {
        const publicationsWithCommentsCountData: UiCommentsConnectionWithCommentsCount =
          getCommentsCountGroupByPublicationUidAdapterToClient(data.data.comments)

        setPageInfo(publicationsWithCommentsCountData.pageInfo)

        if (publicationsWithCommentsCountData.pageInfo.hasPreviousPage) {
          setTableData(prev => [...prev, ...publicationsWithCommentsCountData.edges])
          return
        }

        setTableData(publicationsWithCommentsCountData.edges)
      }
    })
  }, [pageInfo.endCursor, sortField, fetchMore])

  return (
    <div>
      <FiltersWrapper>
        <StyledFormControlWrapper>
          <InputLabel htmlFor='search-by-title-input'>
            {LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.searchByTitle}
          </InputLabel>
          <OutlinedInput
            label={LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.searchByTitle}
            id='search-by-title-input'
            onChange={e => setSearchTitle(e.target.value)}
            value={searchTitle}
            endAdornment={
              <InputAdornment position='end'>
                {searchTitle && (
                  <IconButton edge='end' size='small' onClick={() => setSearchTitle('')}>
                    <Clear fontSize='small' />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </StyledFormControlWrapper>
      </FiltersWrapper>
      {loading && !tableDataFiltered?.length && (
        <SpinnerWrapper>
          <Spinner title={LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.loading} />
        </SpinnerWrapper>
      )}
      {!loading && nothingFounded && (
        <NothingFounded
          title={LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.searchResult}
          texts={[LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.nothingWasFounded]}
        />
      )}
      {tableDataFiltered.length > 0 && (
        <TableWrapper>
          <InfiniteScrollWithScrollToTop
            dataLength={tableDataFiltered?.length || 0}
            fetchMoreData={fetchMoreData}
            hasMore={!!pageInfo.hasNextPage}
            elementStyle={{
              height: 'calc(100vh - 250px)'
            }}
          >
            <div
              style={{
                width: 'fit-content'
              }}
            >
              <TableHeadWrapper>
                <TableHeadRow>
                  <Box width={'121px'} style={{borderLeft: `1px solid ${grayBorder}`}}>
                    <TableHeaderCell title={LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.type} height={'41px'} />
                  </Box>
                  <Box width={'880px'} style={{borderLeft: `1px solid ${grayBorder}`}}>
                    <TableHeaderCell title={LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.title} height={'41px'} />
                  </Box>
                  <Box width={'143px'} style={{borderLeft: `1px solid ${grayBorder}`}}>
                    <TableHeaderCell
                      fieldName='newComments'
                      title={LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.newComments}
                      height={'41px'}
                    />
                  </Box>
                  <Box width={'118px'} style={{borderLeft: `1px solid ${grayBorder}`}}>
                    <TableHeaderCell
                      fieldName='allComments'
                      title={LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS.allComments}
                      height={'41px'}
                    />
                  </Box>
                </TableHeadRow>
              </TableHeadWrapper>
              {tableDataFiltered.map((item, ind) => (
                <LastPublicationCommentItem
                  key={item.node?.publication?.uid || `publication-comments-item-${ind}`}
                  item={item.node}
                />
              ))}
            </div>
          </InfiniteScrollWithScrollToTop>
        </TableWrapper>
      )}
    </div>
  )
}

export default observer(LastPublicationCommentsContent)
