import {FC, useCallback, useEffect, useState} from 'react'
import {Accordion, IconButton, InputBase, InputLabel} from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import {makeStyles} from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'
import {useRubricsQuery} from '@pages/Publication/gql/Publication.generated'
import CustomTreeView from '../Tree/TreeView'
import {RUBRIC_AND_SUBRUBRIC_PICKER_CONSTS} from './RubricAndSubrubricPicker.consts'
import {
  AccordionDetailsWrapper,
  AccordionSummaryWrapper,
  AccordionWrapper,
  SelectedInfoWrapper,
  StyledFormControlWrapper
} from './RubricAndSubrubricPicker.styles'
import {RubricAndSubrubricPickerProps} from './RubricAndSubrubricPickerProps'

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400
  }
})

export const RubricAndSubrubricPicker: FC<RubricAndSubrubricPickerProps> = ({changeSelectedRubricsAndSubrubrics}) => {
  const [textValue, setTextValue] = useState('')
  const {data, loading} = useRubricsQuery()
  const [rubricList, setRubricList] = useState<any[]>([])
  const [values, setValues] = useState<any[]>([])
  const [selectedRubricAndSubrubric, setSelectedRubricAndSubrubric] = useState<any>()

  useEffect(() => {
    const newTree = {}

    if (rubricList.length) {
      rubricList.forEach(r => {
        const rubricId = values.find(i => i === r.id)

        if (rubricId) {
          newTree[rubricId] = {
            title: r.label,
            children: []
          }
        } else if (r.children?.length) {
          r.children.forEach(s => {
            const subrubricId = values.find(i => i === s.id)

            if (subrubricId) {
              if (!newTree[r.id]) {
                newTree[r.id] = {
                  title: r.label,
                  children: []
                }
              }
              newTree[r.id].children.push({
                id: s.id,
                title: s.label
              })
            }
          })
        }
      })
    }
    setSelectedRubricAndSubrubric(newTree)
    changeSelectedRubricsAndSubrubrics(newTree)
  }, [values, rubricList])

  useEffect(() => {
    let resStr = ''
    if (selectedRubricAndSubrubric) {
      Object.keys(selectedRubricAndSubrubric).forEach(i => {
        resStr += selectedRubricAndSubrubric[i].title

        if (selectedRubricAndSubrubric[i].children?.length) {
          resStr += '('
          selectedRubricAndSubrubric[i].children.forEach((c, ind) => {
            resStr += c.title
            if (ind < selectedRubricAndSubrubric[i].children.length - 1) {
              resStr += ', '
            }
          })
          resStr += ')'
        }
        resStr += '; '
      })
    }
    setTextValue(resStr)
  }, [selectedRubricAndSubrubric])

  useEffect(() => {
    if (!loading && data) {
      sessionStorage.setItem('rubrics', JSON.stringify(data))

      if (data?.rubrics?.length) {
        const dataRubricList: any[] = []
        data.rubrics.forEach(rubric => {
          const item = {
            id: rubric.id,
            label: rubric.title
          }

          if (rubric.subrubrics?.length) {
            const dataSubrubricList: any[] = []

            rubric.subrubrics.forEach(subrubric => {
              const subrubricItem = {
                id: subrubric.id,
                label: subrubric.title
              }
              dataSubrubricList.push(subrubricItem)
            })

            item['children'] = dataSubrubricList
          }
          dataRubricList.push(item)
        })
        setRubricList(dataRubricList)
      }
    }
  }, [loading, data, setRubricList])

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const handleClickAway = () => {
    if (open) {
      setOpen(false)
    }
  }

  const handleClearClick = useCallback(() => {
    setValues([])
  }, [])
  // const renderTree = useCallback(
  //   nodes => (
  //     <TreeItem
  //       key={nodes.id}
  //       nodeId={nodes.id}
  //       label={
  //         <div>
  //           <CheckBox color='primary' checked={false}></CheckBox>
  //           {nodes.title}
  //         </div>
  //       }
  //     >
  //       {nodes.subrubrics?.length ? nodes.subrubrics.map(node => renderTree(node)) : null}
  //     </TreeItem>
  //   ),
  //   [values]
  // )

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AccordionWrapper>
        <Accordion
          expanded={open}
          onChange={(e, expanded) => {
            setOpen(expanded)
          }}
        >
          <AccordionSummaryWrapper
            expandIcon={<ArrowDropDownIcon />}
            aria-controls='rubrics-and-subrubrics-content'
            id='rubrics-and-subrubrics-header'
          >
            <StyledFormControlWrapper>
              <InputLabel
                htmlFor='rubrics-and-subrubrics-value'
                style={{
                  zIndex: 1,
                  backgroundColor: '#fff',
                  marginTop: '-2px'
                }}
              >
                {RUBRIC_AND_SUBRUBRIC_PICKER_CONSTS.rubricAndSubrubricMsg}
              </InputLabel>
              <InputBase
                id='rubrics-and-subrubrics-value'
                sx={{ml: 1, flex: 1}}
                label={RUBRIC_AND_SUBRUBRIC_PICKER_CONSTS.rubricAndSubrubricMsg}
                value={textValue}
                readOnly
                fullWidth
                endAdornment={
                  <IconButton
                    sx={{visibility: textValue ? 'visible' : 'hidden'}}
                    onClick={handleClearClick}
                    size='small'
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                }
                onClick={() => {
                  setOpen(prev => !prev)
                }}
              />
            </StyledFormControlWrapper>
          </AccordionSummaryWrapper>
          <AccordionDetailsWrapper>
            {data?.rubrics && (
              // <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
              //   {data?.rubrics.map(item => renderTree(item))}
              //   {/* {renderTree(data?.rubrics)} */}
              // </TreeView>
              <CustomTreeView
                selected={values}
                onSelect={setValues}
                items={rubricList}
                disableMultiParentSelection={false}
              />
            )}
          </AccordionDetailsWrapper>
        </Accordion>
        {values?.length > 0 && (
          <SelectedInfoWrapper>
            {RUBRIC_AND_SUBRUBRIC_PICKER_CONSTS.selectedMsg}: {values.length}
          </SelectedInfoWrapper>
        )}
      </AccordionWrapper>
    </ClickAwayListener>
  )
}
