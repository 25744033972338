import React, {FC} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import {Typography} from '@mui/material'

/**
 * Заглушка для страниц когда нет доступа
 * @returns
 */
const AccessDenied: FC = () => {
  return (
    <PageContainer>
      <Typography variant='h4'>Доступ запрещен</Typography>
    </PageContainer>
  )
}

export default AccessDenied
