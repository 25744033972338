import React, {FC} from 'react'

const ArrowRightIcon: FC<any> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Icons'>
        <path
          id='Vector'
          d='M5.33464 12.8307L15.4763 12.8307L10.818 17.4891L12.0013 18.6641L18.668 11.9974L12.0013 5.33073L10.8263 6.50573L15.4763 11.1641L5.33464 11.1641L5.33464 12.8307Z'
          fill='#222222'
        />
      </g>
    </svg>
  )
}

export default ArrowRightIcon
