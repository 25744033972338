export const PUBLICATION_INFO_CONSTS = {
  changeHistoryMsg: 'История изменений',
  createdDateMsg: 'Дата создания',
  commentsMsg: 'Комментарии',
  errorMsg: 'Ошибка',
  makeAnArticleMsg: 'Преобразовать в статью',
  publicationCheckedByBildEditorMsg: 'Публикация проверена бильдредактором',
  publicationNotCheckedByBildEditorMsg: 'Публикация не проверена бильдредактором',
  savedMsg: 'Сохранено'
}
