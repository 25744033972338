import {Button, IconButton} from '@mui/material'
import styled, {css} from 'styled-components'
import {secondTextColor} from '../../../../@theme/vars'

export const ToastErrorWrapper = styled.div`
  width: 300px;
  background-color: #f2dddd;
  border-radius: 4px;
  color: #eb3846;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: flex-start;
  margin: -14px;
  padding-right: 20px;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
`

export const LeftBlockWrapper = styled.div`
  align-items: flex-start;
  padding: 10px;
`
export const TitleErrorWrapper = styled.div`
  padding: 10px 0px;
`

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

export const ShowDetailButtonWrapper = styled(Button)`
  padding: 3px 8.5px;
  background-color: #eb3846;
  color: #ffffff;
  gap: 4px;
  width: 100px;
  height: 28px;
  font-size: 12px;
  font-weight: 400;
  &:hover,
  &:focus,
  &:active {
    background-color: #eb3846;
    color: #ffffff;
  }
`

export const RetryButtonWrapper = styled(Button)`
  padding: 3px 8.5px;
  gap: 4px;
  width: 100px;
  height: 28px;
  border-color: ${secondTextColor};
  color: ${secondTextColor};
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
  &:hover,
  &:focus,
  &:active {
    border-color: ${secondTextColor};
    color: ${secondTextColor};
  }
`
