import {makeAutoObservable} from 'mobx'
import PublicationStore from '@stores/publicationStore/publicationStore'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import IModals from '../IModals'
import IImageModalStore from '../IImageModalStore'
import {ANNOUNCE_IMGAGE_MODAL_STORE_CONSTS} from './announceImageModalStore.consts'

class AnnounceImageModalStore implements IModals, IImageModalStore {
  publicationStore: PublicationStore

  imageDetail: ImageBlotProps = ANNOUNCE_IMGAGE_MODAL_STORE_CONSTS.defaultImageDetail
  isModalDirty = false
  isOpen = false
  imageModalLoading = false

  constructor(publicationStore: PublicationStore) {
    this.publicationStore = publicationStore
    makeAutoObservable(this)
  }

  setIsModalDirty = (value: boolean): void => {
    this.isModalDirty = value
  }
  setIsOpen = (value: boolean): void => {
    this.isOpen = value
  }

  setImageDetail = (value: ImageBlotProps): void => {
    this.imageDetail = value
  }

  setImageModalLoading = (value: boolean): void => {
    this.imageModalLoading = value
  }

  reset = (): void => {
    this.imageDetail = ANNOUNCE_IMGAGE_MODAL_STORE_CONSTS.defaultImageDetail
    this.isModalDirty = false
  }
}

export default AnnounceImageModalStore
