import {FormControl, FormControlProps} from '@mui/material'
import styled, {css} from 'styled-components'

type FormControlType = {
  width?: string
  margintop?: string
  marginbottom?: string
  marginleft?: string
  marginright?: string
} & FormControlProps

const StyledFormControl = styled(FormControl)<FormControlType>`
  &.MuiFormControl-root {
    & input {
      padding: 0;
    }

    ${({width}) =>
      width &&
      css`
        width: ${width};
      `}
    ${({marginbottom}) =>
      marginbottom &&
      css`
        margin-bottom: ${marginbottom};
      `}
    ${({margintop}) =>
      margintop &&
      css`
        margin-top: ${margintop};
      `}
    ${({marginleft}) =>
      marginleft &&
      css`
        margin-left: ${marginleft};
      `}
    ${({marginright}) =>
      marginright &&
      css`
        margin-right: ${marginright};
      `}
      @media screen and (max-width: 360px) {
      width: calc(100%-20px);
    }
  }
`
export default StyledFormControl
