import React, {FC} from 'react'
import {DialogContentText} from '@mui/material'
import {SimpleModalProps} from '../SimpleModal'
import {StyledDialogContent} from './ModalContent.styles'

export const ModalContent: FC<SimpleModalProps> = ({id, text, secondText, html, children, mode, loading}) => {
  if (html) {
    return <StyledDialogContent id={`${id}_content`} dangerouslySetInnerHTML={{__html: html}} loading={loading} />
  }
  return (
    <StyledDialogContent id={`${id}_content`} loading={loading} mode={mode}>
      {text && <DialogContentText>{text}</DialogContentText>}
      {secondText && <DialogContentText>{secondText}</DialogContentText>}
      {children}
    </StyledDialogContent>
  )
}
