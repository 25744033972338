import styled from 'styled-components'
import {mainTextColor} from '../../../../@theme/vars'
import {IconButton} from '@mui/material'

export const PublicationSendMenuWrapper = styled.div`
  display: flex;
`

export const PublicationSendMenuBtn = styled(IconButton)`
  display: flex;
  color: ${mainTextColor};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  &:hover,
  :focus {
    background: inherit;
    border: none;
  }
`
