import {Quill} from 'react-quill'

const Block = Quill.import('blots/block')

export default class BlockquoteBlot extends Block {
  constructor(node: HTMLElement) {
    super(node)
  }

  static create(): HTMLElement {
    const node: HTMLElement = super.create()
    node.setAttribute(
      'style',
      `
        padding-left: 8px;
        border-left: 4px solid #1964e7;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Segoe UI',sans-serif;
        white-space: normal;
        clear: both;
    `
    )
    return node
  }
}

// В будущем pre нужно заменить на blockquote
BlockquoteBlot.tagName = 'pre'
BlockquoteBlot.className = 'ql-syntax'
BlockquoteBlot.blotName = 'blockquote-blot'
