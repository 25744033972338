import React, {FC, useState} from 'react'
import {makeStyles} from '@mui/styles'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {TreeView} from '@mui/x-tree-view'
import ShevronRightIcon from '../Icons/ShevronRightIcon'
import ShevronUpIcon from '../Icons/ShevronUpIcon'
import TreeItem from './TreeItem'

const useStyles = makeStyles({
  root: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 496
  }
})

type TreeViewProps = {
  items: any[]
  selected: any[]
  onSelect: (val: any) => void
  disableMultiParentSelection?: boolean
}

const CustomTreeView: FC<TreeViewProps> = ({
  items,
  selected: selectedProps = [],
  onSelect: onSelectProps,
  disableMultiParentSelection = true
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState([])

  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds)
  }

  const handleSelect = value => {
    onSelectProps(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(prevSelect => prevSelect !== value)
      }

      return [...prevSelected, value]
    })
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ShevronUpIcon />}
      defaultExpandIcon={<ShevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
    >
      {items?.length && (
        <TreeItem
          items={items}
          selected={selectedProps}
          onSelect={onSelectProps}
          disableMultiParentSelection={disableMultiParentSelection}
        />
      )}
    </TreeView>
  )
}

export default CustomTreeView
