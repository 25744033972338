import {gql, useMutation, useQuery} from '@apollo/client'

const mockQuery = gql`
    query {
        mock
    }
`

export const useOptionalQuery = (query, options) => {
    const result = useQuery(options.skip ? mockQuery : query, options)
    return options.skip ? undefined : result
}

const mockMutation = gql`
    mutation {
        mock
    }
`

export const useOptionalMutation = (mutation, options) => {
    const result = useMutation(options.skip ? mockMutation : mutation, options)

    const onExecute = () => {
        throw new Error(
            "This mutation was marked with `skip`, it's not possible to call it."
        )
    }

    return options.skip ? [onExecute] : result
}
