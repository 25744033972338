import React, {FC, useCallback, useState} from 'react'
import {Box, IconButton, Typography} from '@mui/material'
import {useHistory} from 'react-router-dom'
import Tooltip from '@components/UI/Tooltip/Tooltip'
import {redColor} from '@theme/vars'
import SimpleModal from '../../UI/SimpleModal/SimpleModal'
import DeleteIcon from '../../../components/UI/Icons/DeleteIcon'
import EditIcon from '../../../components/UI/Icons/EditIcon'
import ChartIcon from '../../../components/UI/Icons/ChartIcon'
import {
  BoxBreakWord,
  BoxBreakWordWithError,
  ClickCounter,
  FooterWrapper,
  IconsWrapper,
  ImageBox,
  ImageBoxDataWrapper,
  LabelsCard,
  ModalDescription,
  NumberClicks,
  SerialNumber,
  StyledBoxForImg,
  TooltipWrapper,
  Wrapper
} from './MainSelectionCard.styles'
import {CardSize, MainSelectionCardProps} from './MainSelectionCardProps'
import {MAIN_SELECTION_CARD_CONSTS} from './MainSelectionCard.consts'

const MainSelectionCard: FC<MainSelectionCardProps> = ({
  index,
  data: data,
  handleDeleteBlock,
  overview,
  read,
  regionId,
  size
}) => {
  const [open, setOpen] = useState(false)

  const history = useHistory()

  const onYes = useCallback(() => {
    handleDeleteBlock && handleDeleteBlock(data?.uid)
    setOpen(false)
  }, [data?.block?.uid, handleDeleteBlock])

  const handleOpenHistory = () => {
    history.push(`/main-page/top-news/history/block/${data?.uid}`)
  }

  const handleEditBlockModal = useCallback(
    (uid: string) => {
      history.push(`/main-page/top-news/${regionId}/edit/${uid}`)
    },
    [history, regionId]
  )

  const viewBlockHistory = useCallback(
    (uid: string) => {
      history.push(`/main-page/top-news/history/${regionId}/${uid}`)
    },
    [history, regionId]
  )

  return (
    <Wrapper size={size ? CardSize[size] : overview ? CardSize.overview : CardSize.topNews}>
      <StyledBoxForImg>
        <ImageBox
          url={data?.figureVersion?.primaryProcessedImage?.url || ''}
          onClick={() => read && viewBlockHistory(data?.uid)}
        >
          {!read ? (
            <>
              <IconsWrapper>
                <TooltipWrapper>
                  <Tooltip title={MAIN_SELECTION_CARD_CONSTS.deletePublication}>
                    <IconButton edge='end' size='small' id='main-day-top-news-delete' onClick={() => setOpen(true)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={MAIN_SELECTION_CARD_CONSTS.toChange}>
                    <IconButton
                      edge='end'
                      id='main-day-top-news-edit'
                      onClick={() => handleEditBlockModal(data?.uid)}
                      size='small'
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TooltipWrapper>
                <FooterWrapper>
                  <SerialNumber>{1 + index}</SerialNumber>
                  {!overview && (data?.isExclusive || data?.isAd) && (
                    <LabelsCard>
                      {data?.isExclusive && <Typography>{MAIN_SELECTION_CARD_CONSTS.exclusive}</Typography>}
                      {data?.isAd && (
                        <Typography variant='subtitle1'>{MAIN_SELECTION_CARD_CONSTS.advertisement}</Typography>
                      )}
                    </LabelsCard>
                  )}
                </FooterWrapper>
              </IconsWrapper>
            </>
          ) : (
            <ImageBoxDataWrapper>
              <IconsWrapper>
                <TooltipWrapper></TooltipWrapper>
                <FooterWrapper>
                  {!overview && (data?.isExclusive || data?.isAd) && (
                    <LabelsCard>
                      {data?.isExclusive && <Typography>{MAIN_SELECTION_CARD_CONSTS.exclusive}</Typography>}
                      {data?.isAd && (
                        <Typography variant='subtitle1'>{MAIN_SELECTION_CARD_CONSTS.advertisement}</Typography>
                      )}
                    </LabelsCard>
                  )}
                </FooterWrapper>
              </IconsWrapper>
            </ImageBoxDataWrapper>
          )}
        </ImageBox>

        {data?.title?.length > MAIN_SELECTION_CARD_CONSTS.maxTitleLength && (
          <BoxBreakWordWithError>{MAIN_SELECTION_CARD_CONSTS.errorTitleLength}</BoxBreakWordWithError>
        )}
        <BoxBreakWord>
          <Typography>{data?.title}</Typography>
        </BoxBreakWord>
        {overview && (
          <BoxBreakWord>
            <Typography variant='subtitle2'>{data?.subtitle}</Typography>
          </BoxBreakWord>
        )}
        <ClickCounter>
          <ChartIcon />
          <NumberClicks>{data?.clickCount || 0}</NumberClicks>
        </ClickCounter>
      </StyledBoxForImg>
      {/* <ActionButtons>
          {!overview && (
            <IconButton onClick={handleOpenHistory} size='small'>
              <History />
            </IconButton>
          )}
        </ActionButtons> */}
      {!read && (
        <SimpleModal
          mode='dialog'
          open={open}
          title={MAIN_SELECTION_CARD_CONSTS.deletePublication}
          onClose={() => setOpen(false)}
          onNo={() => setOpen(false)}
          onYes={onYes}
          yesLabel={MAIN_SELECTION_CARD_CONSTS.toDelete}
        >
          <Box textAlign='center'>
            <ModalDescription variant='h2'>{MAIN_SELECTION_CARD_CONSTS.deleteBlockConfirmation}</ModalDescription>
          </Box>
        </SimpleModal>
      )}
    </Wrapper>
  )
}
export default MainSelectionCard
