import {createTheme, ThemeProvider} from '@mui/material'
import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import Tooltip, {TooltipProps} from './Tooltip'

const StyledDiv = styled.div`
  display: inline-flex;
`

export interface ControlTooltipProps extends TooltipProps {
  // Содержимое в заблокированном состоянии
  disabledTitle?: React.ReactNode
  // Не показывать тултип, если разблокирован
  withoutTooltipIfActive?: boolean
  // Элемент заблокирован или нет
  disabled?: boolean
}

/**
 * Компонент тултипа для заблокированных элементов
 */
const ControlTooltip = ({
  children,
  disabled,
  disabledTitle,
  title,
  color,
  withoutTooltipIfActive,
  ...props
}: ControlTooltipProps): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const [isHoverOn, setHoverOn] = useState(false)

  const openWithDelay = useCallback(() => {
    setHoverOn(true)
    if (props?.enterDelay && props?.enterDelay > 0) {
      setTimeout(() => setOpen(true), props.enterDelay)
    } else {
      setOpen(true)
    }
  }, [props])

  if (withoutTooltipIfActive && !disabled) {
    return children
  }

  if (disabled && !disabledTitle) return children
  if (!disabled && !title) return children

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: color === 'default' ? '#222222' : '#ffffff',
            backgroundColor: color === 'default' ? '#ffffff' : '#f48188'
          }
        }
      }
    }
  })

  return disabled ? (
    <ThemeProvider theme={theme}>
      <Tooltip open={open && isHoverOn} title={disabledTitle || ''} {...props}>
        <StyledDiv
          onMouseEnter={() => openWithDelay()}
          onMouseLeave={() => {
            setOpen(false)
            setHoverOn(false)
          }}
        >
          {children}
        </StyledDiv>
      </Tooltip>
    </ThemeProvider>
  ) : (
    <Tooltip open={open} title={title} {...props}>
      {React.cloneElement(children, {
        ...children.props,
        onMouseEnter: () => openWithDelay(),
        onMouseLeave: () => setOpen(false),
        onMouseDown: () => setOpen(false)
      })}
    </Tooltip>
  )
}
ControlTooltip.displayName = 'ControlTooltip'

export default ControlTooltip
