import styled from 'styled-components'
import {Typography} from '@mui/material'
import {grayColor} from '@theme/vars'

export const DebugInfoWrapper = styled.div`
  margin-left: 32px;
  float: right;
`

export const EditorFooterWrapper = styled.div`
  /* width: calc(100% - 167px); */
  margin: auto;
  width: calc(100% - 20px);
  max-width: 1266px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 10px;

  @media (max-width: 440px) {
    flex-direction: column;
  }
`
export const NnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`
export const TextNn = styled(Typography)`
  color: ${grayColor};
`
