import React, {FC, useCallback, useState} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import ImageCropper from '@components/UI/ImageEditor/ImageCropper/ImageCropper'
import {CropInput, Figure} from '@graphql/types'
import {Button, FormControlLabel, Switch, Typography} from '@mui/material'
import styled from 'styled-components'
import {useUpdateFigureSmmMutation} from './gql/SmmTools.generated'
import * as _ from 'lodash'
import {useSnackbar} from 'notistack'
import {CropperType} from '../../types/CropperType'
import {useStore} from '@stores/rootStoreContext'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  row-gap: 15px;
`

const SmmToolsPage: FC = () => {
  const [updateFigure] = useUpdateFigureSmmMutation()

  const [figure, setFigure] = useState({} as Figure)
  const {cropperStore} = useStore()
  const {cropper, setCropper} = cropperStore
  const [imageUrl, setImageUrl] = useState('')

  const snackbar = useSnackbar()

  const [hasDiagonalWatermark, setHasDiagonalWatermark] = useState(false)

  const onDownloadImage = useCallback(async () => {
    try {
      if (!_.isEmpty(figure)) {
        const newFigureResult = await updateFigure({
          variables: {
            data: {
              author: '',
              figureUid: figure.uid,
              crop: cropper,
              hasDiagonalWatermark: hasDiagonalWatermark
            }
          }
        })

        setFigure(newFigureResult.data?.updateFigure?.figure as Figure)

        fetch(newFigureResult.data?.updateFigure?.figure?.latestVersion.primaryProcessedImage?.url || '')
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'smm-tools.jpeg'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
          })
          .catch(e => console.error(e))
      }
    } catch (e) {
      console.error(e)
    }
  }, [cropper, figure, hasDiagonalWatermark, snackbar, updateFigure])

  return (
    <PageContainer>
      <Typography variant='h1'>Инструменты SMM</Typography>
      <Wrapper>
        <ImageCropper
          hasDiagonalWatermark={figure?.hasDiagonalWatermark || false}
          hasWatermark={figure?.hasWatermark || false}
          figure={figure}
          figureTypeSlug='smm'
          imageUrl={imageUrl}
          setFigure={setFigure}
          setImageUrl={setImageUrl}
          cropperType={CropperType.isSmmToolsPage}
          isCustomRatio
        />
        <FormControlLabel
          label='Ватермарка на всё фото'
          control={
            <Switch
              checked={hasDiagonalWatermark}
              value={hasDiagonalWatermark}
              onChange={() => setHasDiagonalWatermark(prevState => !prevState)}
            />
          }
        />
        <Button variant='contained' onClick={onDownloadImage} disabled={_.isEmpty(figure)}>
          Скачать фото
        </Button>
      </Wrapper>
    </PageContainer>
  )
}

export default SmmToolsPage
