export const TOP_NEWS_BLOCK_MODAL_CONSTS = {
  titleMsg: 'Вставка фото',
  okLabelMsg: 'Сохранить',
  addPhotoMsg: 'Вставить фото',
  cancelMsg: 'Отмена',
  setAnotherPhotoLabelMsg: 'Установить другое фото',
  warningMsg: 'Внимание!',
  closeConfirmBtn: 'Да, выйти',
  backConfirmationText: 'Внесенные измененения не будут сохранены. Вы уверены, что хотите выйти?'
}
