import React, {FC, useCallback, useEffect, useState} from 'react'
import {
  Box,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ClearIcon from '@mui/icons-material/Clear'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {observer} from 'mobx-react-lite'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import {InputStringWithValidation} from '@components/UI/InputStringWithValidation/InputStringWithValidation'
import {useStore} from '@stores/rootStoreContext'
import SimpleModal from '../../../../components/UI/SimpleModal/SimpleModal'
import {redColor} from '../../../../@theme/vars'
import TopNewsBlockModal from '../TopNewsBlockModal/TopNewsBlockModal'
import {
  useSaveTopNewsBlockMutation,
  useUpdateTopNewsSelectionMutation
} from '../TopNewsContent/gql/TopNewsContent.generated'
import {FigureVersion, SaveTopNewsBlockInput, TopNewsBlock} from '../../../../@graphql/types'
import {TopNewsEditProps} from './TopNewsEditProps'
import {useTopNewsBlockByIdQuery} from './TopNewsEdit.generated'
import {TOP_NEWS_EDIT_CONSTS} from './TopNewsEdit.consts'
import {
  HeaderWithBorder,
  InfoContainersFlex,
  MainContainer,
  StyledDeleteButton,
  StyledFormControlLabel,
  StyledSaveButton,
  TextFieldWrapper,
  Title,
  TitleLength,
  TitleWithLengthContainer,
  UrlFormControl
} from './TopNewsEdit.styles'

/**
 *
 * @returns
 */
const TopNewsEdit: FC<TopNewsEditProps> = () => {
  const {cropperStore, topNewsStore} = useStore()
  const {cropper, setCropper} = cropperStore
  const {addingBlocksCache} = topNewsStore
  const [title, setTitle] = useState('')
  const [url, setUrl] = useState('')
  const [figureVersion, setFigureVersion] = useState({} as FigureVersion)
  const [priority, setPriority] = useState<number>(1)
  const [invalidLink, setInvalidLink] = useState('')
  const [isExclusive, setIsExclusive] = useState(false)
  const [titleErrorText, setTitleErrorText] = useState('')
  const [disableFormState, setDisableFormState] = useState(false)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const history = useHistory()

  const {regionId, uid} = useParams<{regionId: string; uid: string}>()
  const [saveTopNewsBlock] = useSaveTopNewsBlockMutation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  // const [imageFlexUrl, setImageFlexUrl] = useState('')
  const [updateTopNewsSelection] = useUpdateTopNewsSelectionMutation()
  const [topNewsBlocks, setTopNewsBlocks] = useState<TopNewsBlock[]>([])

  const [blockImage, setBlockImage] = useState<ImageBlotProps>(TOP_NEWS_EDIT_CONSTS.defaultImage)

  const openModalImage = useCallback(() => {
    // if (blockImage?.figureUid) {
    //   setCurrentFigure({
    //     alt: '',
    //     author: '',
    //     cropperPreview: {
    //       url: blockImage.cropperPreviewUrl || '',
    //       size: {
    //         width: blockImage.crop?.width || 0,
    //         height: blockImage.crop?.height || 0
    //       }
    //     },
    //     description: '',
    //     hasWatermark: blockImage.hasWatermark || false,
    //     hasDiagonalWatermark: blockImage.hasDiagonalWatermark || false,
    //     id: blockImage.figureId,
    //     latestVersion: {
    //       id: blockImage.figureVersionId,
    //       crop: {
    //         width: blockImage.crop?.width || 0,
    //         height: blockImage.crop?.height || 0,
    //         top: blockImage.crop?.top || 0,
    //         left: blockImage.crop?.left || 0
    //       }
    //     },
    //     src: blockImage.src || '',
    //     uid: blockImage.figureUid || ''
    //   } as Figure)
    // }
    setIsModalOpen(true)
  }, [])

  useTopNewsBlockByIdQuery({
    variables: {blockId: uid},
    onCompleted: data => {
      setTitle(data?.topNewsBlockById?.title ?? '')
      setIsExclusive(data?.topNewsBlockById?.isExclusive ?? false)
      setUrl(data?.topNewsBlockById?.url ?? '')
      setPriority((data?.topNewsBlockById?.position as any) + 1 ?? null)
      setNewFigureVersion((data?.topNewsBlockById?.figureVersion as FigureVersion) || undefined)
      setCropper({
        height: data?.topNewsBlockById?.figureVersion?.crop.height || 0,
        top: data?.topNewsBlockById?.figureVersion?.crop.top || 0,
        left: data?.topNewsBlockById?.figureVersion?.crop.left || 0,
        width: data?.topNewsBlockById?.figureVersion?.crop.width || 0
      })
    }
  })

  const setNewFigureVersion = useCallback(
    (value: FigureVersion | undefined) => {
      if (value) {
        setFigureVersion({
          crop: {
            width: cropper.width,
            height: cropper.height,
            top: cropper.top,
            left: cropper.left
          },
          figure: value?.figure,
          hasDiagonalWatermark: false,
          hasWatermark: false,
          primaryProcessedImage: value?.primaryProcessedImage,
          uid: value?.uid,
          id: value?.id
        } as FigureVersion)
        // setImageFlexUrl(value?.primaryProcessedImage?.url || '')
        setBlockImage({
          id: 'topNewsEdit' + value?.figure?.id || '',
          src: value?.figure?.latestVersion?.primaryProcessedImage?.url || value?.primaryProcessedImage?.url || '',
          cropperPreviewUrl: value?.figure?.cropperPreview?.url || '',
          crop: {
            width: cropper.width,
            height: cropper.height,
            top: cropper.top,
            left: cropper.left
            // height: value?.crop.height || 0,
            // top: value?.crop.top || 0,
            // left: value?.crop.left || 0,
            // width: value?.crop.width || 0
          },
          figureId: value?.figure?.id || '',
          figureUid: value?.figure?.uid || '',
          figureVersionId: value?.id || '',
          isFullWidth: true,
          isannouncingphoto: false,
          isoverviewblock: false,
          hasWatermark: false,
          hasDiagonalWatermark: false
        })
      }
    },
    [cropper]
  )

  useEffect(() => {
    const regex = /^http(s)?\:\/\/(.{0,10})ura\.news\/((story)|(articles)|(news))\/\d{1,10}$/g
    const fullMatch = url.slice().replace(regex, '')
    setInvalidLink(fullMatch.length > 0 ? TOP_NEWS_EDIT_CONSTS.wrongLinkMsg : '')
  }, [url])

  // useEffect(() => {
  //   setDisableFormState(!url || !title || !!titleErrorText || !!invalidLink || !priority)
  // }, [url, title, titleErrorText, invalidLink, priority])

  useEffect(() => {
    setDisableFormState(!url || !title || !!titleErrorText || !!invalidLink)
  }, [url, title, titleErrorText, invalidLink])

  // todo функция удаления
  const clearBanner = useCallback(async () => {
    // addingBlocksCache.delete(uid)

    const updateTopNewsSelectionData = await updateTopNewsSelection({
      variables: {
        data: {
          regionId: regionId,
          blockIds: topNewsBlocks.map(block => block.uid).filter(b => b !== uid)
        }
      }
    })

    if (!updateTopNewsSelectionData?.errors?.length) {
      history.push(`/main-page/top-news/${regionId}`)
    }
  }, [history, regionId, uid, topNewsBlocks, updateTopNewsSelection])

  const handleUpdateBlock = useCallback(async () => {
    try {
      const res = await saveTopNewsBlock({
        variables: {
          data: {
            topNewsBlockId: uid,
            title: title,
            isExclusive: isExclusive,
            figureVersionId: figureVersion.id,
            url: url
          } as SaveTopNewsBlockInput
        }
      })

      if (!res.errors?.length && addingBlocksCache.get(uid)) {
        addingBlocksCache.set(uid, res.data?.saveTopNewsBlock?.block as TopNewsBlock)
      }

      history.push(`/main-page/top-news/${regionId}`)
      toast.success(TOP_NEWS_EDIT_CONSTS.successTopNewsMsg, {
        autoClose: 1000,
        hideProgressBar: true,
        containerId: 'success'
      })
    } catch (e) {
      console.error(e)
    }
  }, [saveTopNewsBlock, addingBlocksCache, url, isExclusive, title, uid, figureVersion, history, regionId])

  const goBack = useCallback(() => {
    history.push(`/main-page/top-news/${regionId}`)
  }, [history, regionId])

  return (
    <>
      <HeaderWithBorder>
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Title>{TOP_NEWS_EDIT_CONSTS.titleMsg}</Title>
      </HeaderWithBorder>
      <MainContainer>
        <div id='top-news-edit-image' onClick={openModalImage}>
          {blockImage && (
            <figure {...blockImage}>
              {blockImage.src && (
                <img className={'change-item'} draggable='false' src={'/changeItem.jpg'} onClick={openModalImage} />
              )}
              <img src={blockImage.src || '/noPhoto.jpg'} draggable='false' />
            </figure>
          )}
        </div>
        <InfoContainersFlex>
          <UrlFormControl>
            <InputLabel>{TOP_NEWS_EDIT_CONSTS.urlMsg}</InputLabel>
            <OutlinedInput
              label={TOP_NEWS_EDIT_CONSTS.urlMsg}
              onChange={e => setUrl(e.target.value)}
              value={url}
              error={!!invalidLink}
              endAdornment={
                <InputAdornment position='end'>
                  {url && (
                    <IconButton edge='end' size='small' onClick={() => setUrl('')}>
                      <ClearIcon fontSize='small' />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
            {invalidLink && (
              <FormHelperText
                style={{
                  color: redColor
                }}
              >
                {invalidLink}
              </FormHelperText>
            )}
          </UrlFormControl>
          <TitleWithLengthContainer>
            <InputStringWithValidation
              id={'top-news-edit-title'}
              title={TOP_NEWS_EDIT_CONSTS.titleNameMsg}
              defaultValue={title}
              setValue={setTitle}
              setErrorText={setTitleErrorText}
              showTitleLength={false}
              maxLength={TOP_NEWS_EDIT_CONSTS.maxTitleLength}
              width={'85%'}
              required={true}
            />
            <TitleLength
              style={{
                color: !!titleErrorText ? redColor : 'inherit'
              }}
            >
              {`${title?.length || 0} / ${TOP_NEWS_EDIT_CONSTS.maxTitleLength}`}
            </TitleLength>
          </TitleWithLengthContainer>
          {/* <div>
            <TextFieldWrapper
              type='number'
              inputProps={{min: 1, max: 8, pattern: '[0-9]*'}}
              label={TOP_NEWS_EDIT_CONSTS.priorityMsg}
              onChange={e => setPriority(+e.target.value)}
              value={priority}
              style={{
                backgroundColor: !priority || priority < 1 ? redColor : 'inherit'
              }}
            />
          </div> */}
          <StyledFormControlLabel
            label={TOP_NEWS_EDIT_CONSTS.exclusiveMsg}
            control={
              <Checkbox
                checked={isExclusive}
                value={isExclusive}
                onChange={() => setIsExclusive(prevState => !prevState)}
              />
            }
          />
          <StyledSaveButton color='primary' disabled={disableFormState} variant='contained' onClick={handleUpdateBlock}>
            {TOP_NEWS_EDIT_CONSTS.saveMsg}
          </StyledSaveButton>
        </InfoContainersFlex>
        <StyledDeleteButton color='primary' variant='outlined' onClick={() => setIsOpenConfirmDialog(true)}>
          {TOP_NEWS_EDIT_CONSTS.deleteBannerMsg}
        </StyledDeleteButton>
      </MainContainer>
      <SimpleModal
        open={isOpenConfirmDialog}
        mode='alert'
        variant='error'
        title={TOP_NEWS_EDIT_CONSTS.warningMsg}
        okLabel={TOP_NEWS_EDIT_CONSTS.closeConfirmBtn}
        yesLabel={TOP_NEWS_EDIT_CONSTS.closeConfirmBtn}
        onOk={clearBanner}
        onYes={clearBanner}
        onClose={() => setIsOpenConfirmDialog(false)}
        onAbort={() => setIsOpenConfirmDialog(false)}
        onNo={() => setIsOpenConfirmDialog(false)}
      >
        <Box textAlign='center' style={{marginBottom: '10px'}}>
          <Typography variant='h2'>{TOP_NEWS_EDIT_CONSTS.closeConfirmationText}</Typography>
        </Box>
      </SimpleModal>
      <TopNewsBlockModal
        figureVersion={figureVersion}
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setFigureVersion={setNewFigureVersion}
      />
    </>
  )
}
export default observer(TopNewsEdit)
