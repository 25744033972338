import styled from 'styled-components'
import {screenS} from '../../@theme/vars'
import PageContainer from '@components/UI/PageContainer/PageContainer'

export const PageName = styled.div`
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #222;
  @media (max-width: ${screenS}) {
    font-size: 16px;
  }
`

const screenLargeSize = '1135px'
const screenMiddleSize = '979px'
const screenSize = '770px'

export const PageContainerWrapper = styled(PageContainer)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 850px;
`

export const ReportsListWrapper = styled.div`
  margin-top: 24px;
  height: auto;

  @media (max-width: ${screenLargeSize}) {
    height: 900px;
  }

  @media (max-width: ${screenMiddleSize}) {
    height: 1000px;
  }

  @media (max-width: ${screenSize}) {
    height: 1100px;
  }
`
