import styled from 'styled-components'

export const PublicationDestinationPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxWithInfo = styled.div`
  display: flex;
  align-items: center;
`

export const DetailsRowSelects = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

export const YandexDzenTitleWrapper = styled.div`
  width: 100%;
  max-width: 690px;
`
