import React from 'react'
import {Typography} from '@mui/material'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import LastPublicationCommentsContent from '@components/Comments/LastPublicationCommentsContent/LastPublicationCommentsContent'

const LastPublicationCommentsPage: React.FC = () => {
  return (
    <PageContainer>
      <Typography variant='text20M'>Последние комментируемые публикации</Typography>
      <LastPublicationCommentsContent />
    </PageContainer>
  )
}

export default LastPublicationCommentsPage
