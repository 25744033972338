export const PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS = {
  articleMsg: 'Статья',
  cancelMsg: 'Отмена',
  changeMsg: 'Изменить',
  defaultOverviewImage: {
    id: 'overviewBlockFigureId',
    src: '',
    cropperPreviewUrl: '',
    crop: {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    },
    figureId: '',
    figureUid: '',
    figureVersionId: '',
    isFullWidth: true,
    isannouncingphoto: false,
    isoverviewblock: true,
    hasWatermark: false,
    hasDiagonalWatermark: false
  },
  deleteMsg: 'Удалить',
  insertingImageMsg: 'Вставка фото',
  newsMsg: 'Новость',
  onMainPageMsg: ' на главной',
  pasteMsg: 'Вставить фото',
  photoForMainPageMsg: 'Картинка для главной',
  unknownMsg: 'Неизвестен'
}
