import React, {FC} from 'react'

const PencilIcon: FC<any> = () => {
  return (
    <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 7.91696V10H2.08304L8.22664 3.85641L6.14359 1.77336L0 7.91696ZM9.83752 2.24552C10.0542 2.02888 10.0542 1.67893 9.83752 1.4623L8.5377 0.162477C8.32107 -0.0541591 7.97112 -0.0541591 7.75448 0.162477L6.73795 1.179L8.821 3.26205L9.83752 2.24552Z'
        fill='#A9A9A9'
      />
    </svg>
  )
}

export const PencilIcon24: FC<any> = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 36 36' fill='none'>
      <path
        d='M9 23.2505V27H12.7495L23.8079 15.9415L20.0585 12.1921L9 23.2505ZM26.7075 13.0419C27.0975 12.652 27.0975 12.0221 26.7075 11.6321L24.3679 9.29246C23.9779 8.90251 23.348 8.90251 22.9581 9.29246L21.1283 11.1222L24.8778 14.8717L26.7075 13.0419Z'
        fill='#A9A9A9'
      />
    </svg>
  )
}

export default PencilIcon
