import React, {FC} from 'react'

const BookOpenIcon: FC<any> = () => {
    return (
        <svg
            width='21'
            height='20'
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.5 8.66634V16.6663M10.5 8.66634C10.5 6.7995 10.5 5.86608 10.1367 5.15304C9.81711 4.52583 9.30717 4.0159 8.67997 3.69632C7.96693 3.33301 7.03351 3.33301 5.16667 3.33301H4.33333C3.86662 3.33301 3.63327 3.33301 3.45501 3.42384C3.29821 3.50373 3.17072 3.63121 3.09083 3.78802C3 3.96628 3 4.19963 3 4.66634V13.6663C3 14.1331 3 14.3664 3.09083 14.5447C3.17072 14.7015 3.29821 14.829 3.45501 14.9088C3.63327 14.9997 3.86662 14.9997 4.33333 14.9997H6.7889C7.23906 14.9997 7.46414 14.9997 7.68178 15.0385C7.87496 15.0729 8.06342 15.13 8.24326 15.2085C8.44587 15.2969 8.63314 15.4218 9.0077 15.6715L10.5 16.6663M10.5 8.66634C10.5 6.7995 10.5 5.86608 10.8633 5.15304C11.1829 4.52583 11.6928 4.0159 12.32 3.69632C13.0331 3.33301 13.9665 3.33301 15.8333 3.33301H16.6667C17.1334 3.33301 17.3667 3.33301 17.545 3.42384C17.7018 3.50373 17.8293 3.63121 17.9092 3.78802C18 3.96628 18 4.19963 18 4.66634V13.6663C18 14.1331 18 14.3664 17.9092 14.5447C17.8293 14.7015 17.7018 14.829 17.545 14.9088C17.3667 14.9997 17.1334 14.9997 16.6667 14.9997H14.2111C13.7609 14.9997 13.5359 14.9997 13.3182 15.0385C13.125 15.0729 12.9366 15.13 12.7567 15.2085C12.5541 15.2969 12.3669 15.4218 11.9923 15.6715L10.5 16.6663'
                stroke='#222222'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export default BookOpenIcon
