import {isNil} from 'lodash'
import {Quill} from 'react-quill'
import './Video.styles.scss'

const Embed = Quill.import('blots/block/embed')

export type VideoBlot = {
  id: string
  src: string
  title: string
  width: number | null
  height: number | null
  allow: string
  allowFullscreen: boolean | null
  frameborder: boolean | null
}

export default class Video extends Embed {
  constructor(node: HTMLElement) {
    super(node)
  }

  static create(videoBlot: VideoBlot): HTMLElement {
    const node = super.create()
    const childNode = document.createElement('iframe')
    const videoOptionsNode = document.createElement('div')

    const {id, src, title, width, height, allow, allowFullscreen, frameborder} = videoBlot

    node.setAttribute('id', id)
    node.setAttribute('src', src)
    childNode.setAttribute('src', src)
    childNode.setAttribute('style', 'width: 100%; min-height: 420px; height: auto;')
    node.setAttribute('contenteditable', 'false')
    videoOptionsNode.classList.add('video-options')
    childNode.innerText = src

    const deleteVideo = document.createElement('img')
    deleteVideo.setAttribute('src', '/deleteItem.jpg')
    deleteVideo.setAttribute('draggable', 'false')
    deleteVideo.classList.add('delete-item')
    videoOptionsNode.prepend(deleteVideo)

    const deleteVideoEvent = new CustomEvent('deleteVideoEvent', {
      detail: {id},
      bubbles: true
    })
    deleteVideo.addEventListener('click', () => {
      return window.dispatchEvent(deleteVideoEvent)
    })

    const changeVideo = document.createElement('img')
    changeVideo.setAttribute('src', '/changeItem.jpg')
    changeVideo.setAttribute('draggable', 'false')
    changeVideo.classList.add('change-item')
    videoOptionsNode.prepend(changeVideo)

    const changeVideoEvent = new CustomEvent('openModalVideo', {
      detail: {videoBlot},
      bubbles: true
    })
    changeVideo.addEventListener('click', () => {
      return window.dispatchEvent(changeVideoEvent)
    })

    node.appendChild(childNode)
    node.appendChild(videoOptionsNode)
    return node
  }

  static value(node: HTMLElement): VideoBlot {
    return {
      id: node.getAttribute('id') || '',
      src: node.getAttribute('src') || '',
      title: node.getAttribute('title') || '',
      width: isNil(node.getAttribute('width')) ? null : +(node.getAttribute('width') || '0'),
      height: isNil(node.getAttribute('height')) ? null : +(node.getAttribute('height') || '0'),
      allow: node.getAttribute('allow') || '',
      allowFullscreen: isNil(node.getAttribute('allowFullscreen'))
        ? null
        : node.getAttribute('allowFullscreen') == 'true',
      frameborder: isNil(node.getAttribute('frameborder')) ? null : node.getAttribute('frameborder') == 'true'
    }
  }
}

Video.tagName = 'div'
Video.className = 'ql-video'
Video.blotName = 'custom-video'
