import React, {FC, useCallback, useEffect, useState} from 'react'
import {Box, IconButton, Tooltip, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {CreateFigureVersionInput, CropInput, Figure, FigureVersion, Image} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {useCreateFigureVersionMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import SimpleModal from '../../../UI/SimpleModal/SimpleModal'
import ImageCropper from '../../../UI/ImageEditor/ImageCropper/ImageCropper'
import DeleteIcon from '../../../../components/UI/Icons/DeleteIcon'
import {CropperType} from '../../../../types/CropperType'
import {TopNewsBlockModalProps} from './TopNewsBlockModalProps'
import {TOP_NEWS_BLOCK_MODAL_CONSTS} from './TopNewsBlockModal.consts'
import {ConfirmationTextWrapper, DeleteButtonWrapper} from './TopNewsBlockModal.styles'

const TopNewsBlockModal: FC<TopNewsBlockModalProps> = ({figureVersion, open, setIsModalOpen, setFigureVersion}) => {
  const [imageUrl, setImageUrl] = useState<string>('')
  const [currFigure, setCurrFigure] = useState<Figure>({} as Figure)
  const {cropperStore} = useStore()
  const {cropper, isCropperChanged, setCropper} = cropperStore
  const [isModalDirty, setIsModalDirty] = useState<boolean>(true)
  const [disabledFormState, setDisabledFormState] = useState<boolean>(true)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false)

  const [createFigureVersion, {loading: createFigureVersionLoading}] = useCreateFigureVersionMutation()

  const createFigureVersionAction = useCallback(
    (createFigureVersionInputData: CreateFigureVersionInput) => {
      const newFigure = createFigureVersion({
        variables: {
          data: createFigureVersionInputData
        }
      })

      return newFigure
    },
    [createFigureVersion]
  )

  useEffect(() => {
    const newFigure: Figure = {
      hasDiagonalWatermark: figureVersion?.hasDiagonalWatermark || false,
      hasWatermark: figureVersion?.hasWatermark || false,
      sourceImage: figureVersion?.figure?.sourceImage || ({} as Image),
      cropperPreview: figureVersion?.figure?.cropperPreview || ({} as Image),
      id: figureVersion?.figure?.id || '',
      latestVersion: figureVersion?.figure?.latestVersion || ({} as FigureVersion),
      src: figureVersion?.figure?.src || '',
      type: figureVersion?.figure?.type || 'main-page',
      uid: figureVersion?.figure?.uid || ''
    } as Figure
    setCurrFigure(newFigure)
    setImageUrl(figureVersion?.figure?.latestVersion?.primaryProcessedImage?.url || '')
    setCropper((figureVersion?.crop as CropInput) || null)
  }, [figureVersion, setCropper])

  useEffect(() => {
    const newDirtyVal =
      isCropperChanged ||
      cropper?.width !== figureVersion?.crop?.width ||
      cropper?.height !== figureVersion?.crop?.height ||
      cropper?.left !== figureVersion?.crop?.left ||
      cropper?.top !== figureVersion?.crop?.top ||
      imageUrl !== figureVersion?.primaryProcessedImage?.url

    setIsModalDirty(newDirtyVal)
  }, [cropper, isCropperChanged, figureVersion, imageUrl])

  useEffect(() => {
    setDisabledFormState(!currFigure?.uid || !isModalDirty)
    if (currFigure?.latestVersion?.primaryProcessedImage?.url) {
      setImageUrl(currFigure?.latestVersion?.primaryProcessedImage?.url || '')
    }
  }, [currFigure, isModalDirty])

  const clearFigure = useCallback(() => {
    setCurrFigure({} as Figure)
    setDisabledFormState(true)
    setImageUrl('')
  }, [])

  const resetForm = useCallback(() => {
    setCurrFigure(figureVersion.figure || ({} as Figure))
    setImageUrl(figureVersion?.figure?.latestVersion?.primaryProcessedImage?.url || '')
    setCropper((figureVersion?.crop as CropInput) || null)
    setDisabledFormState(true)
  }, [figureVersion, setCropper])

  const closeModalHandler = useCallback(() => {
    !isModalDirty ? setIsModalOpen(false) : setIsOpenConfirmDialog(!isOpenConfirmDialog)
  }, [isModalDirty, setIsModalOpen, isOpenConfirmDialog])

  const closeModal = useCallback(() => {
    if (isOpenConfirmDialog) {
      resetForm()
    }
    setIsOpenConfirmDialog(false)
    setIsModalDirty(false)
    setIsModalOpen(false)
  }, [isOpenConfirmDialog, setIsModalOpen, resetForm])

  const handleOk = useCallback(async () => {
    const newFigureVersion = await createFigureVersionAction({
      figureId: currFigure?.id,
      hasWatermark: currFigure.hasWatermark,
      hasDiagonalWatermark: currFigure.hasDiagonalWatermark,
      crop: cropper
    } as CreateFigureVersionInput)

    if (!newFigureVersion?.errors?.length) {
      setFigureVersion(newFigureVersion.data?.createFigureVersion?.figureVersion as FigureVersion)
      closeModal()
    }
  }, [currFigure, cropper, setFigureVersion, closeModal, createFigureVersionAction])

  return (
    <SimpleModal
      open={open}
      onClose={closeModalHandler}
      onOk={handleOk}
      title={TOP_NEWS_BLOCK_MODAL_CONSTS.titleMsg}
      okLabel={TOP_NEWS_BLOCK_MODAL_CONSTS.addPhotoMsg}
      okButtonDisabled={disabledFormState || createFigureVersionLoading}
      loading={createFigureVersionLoading}
      onNo={closeModalHandler}
    >
      <Box>
        <ImageCropper
          hasDiagonalWatermark={currFigure?.latestVersion?.figure?.hasDiagonalWatermark || false}
          hasWatermark={currFigure?.latestVersion?.figure?.hasWatermark || false}
          figure={currFigure}
          setFigure={setCurrFigure}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          ratio={16 / 9}
          isCustomRatio={true}
          figureTypeSlug='main-page'
          cropperType={CropperType.isTopNewsBlock}
        />
        {currFigure?.src && (
          <DeleteButtonWrapper>
            <Tooltip title={TOP_NEWS_BLOCK_MODAL_CONSTS.setAnotherPhotoLabelMsg}>
              <IconButton edge='end' size='small' id='top-news-image-modal-delete-photo' onClick={clearFigure}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </DeleteButtonWrapper>
        )}
      </Box>

      <SimpleModal
        open={isOpenConfirmDialog}
        mode='alert'
        variant='error'
        title={TOP_NEWS_BLOCK_MODAL_CONSTS.warningMsg}
        okLabel={TOP_NEWS_BLOCK_MODAL_CONSTS.closeConfirmBtn}
        yesLabel={TOP_NEWS_BLOCK_MODAL_CONSTS.closeConfirmBtn}
        onOk={closeModal}
        onYes={closeModal}
        onClose={closeModalHandler}
        onNo={closeModalHandler}
      >
        <ConfirmationTextWrapper>
          <Typography variant='h2'>{TOP_NEWS_BLOCK_MODAL_CONSTS.backConfirmationText}</Typography>
        </ConfirmationTextWrapper>
      </SimpleModal>
    </SimpleModal>
  )
}

export default observer(TopNewsBlockModal)
