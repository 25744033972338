import React, {FC, useState} from 'react'
import {Autocomplete, TextField} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type PositionPickerProp = {
  handleChangePosition: (newPosition: any) => void
  disabled?: boolean
}

const PositionPicker: FC<PositionPickerProp> = ({handleChangePosition, disabled}) => {
  const [selectedValue, setSelectedValue] = useState<any>(null)
  const [search, setSearch] = useState('')

  const handleOnChange = newValue => {
    setSelectedValue(newValue)
    handleChangePosition(newValue)
  }

  const positions = [
    {
      title: '1',
      value: 0
    },
    {
      title: '2',
      value: 1
    },
    {
      title: '3',
      value: 2
    },
    {
      title: '4',
      value: 3
    },
    {
      title: '5',
      value: 4
    },
    {
      title: '6',
      value: 5
    },
    {
      title: '7',
      value: 6
    },
    {
      title: '8',
      value: 7
    }
  ]

  return (
    <Autocomplete
      value={selectedValue}
      inputValue={search}
      options={positions}
      getOptionDisabled={option => option?.disabled}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      getOptionLabel={option => option?.title}
      clearIcon={<CloseIcon fontSize='small' />}
      noOptionsText={'Значений не найдено'}
      style={{width: 250}}
      onChange={(e, newValue) => handleOnChange(newValue)}
      onInputChange={(e, val) => setSearch(val)}
      renderInput={params => <TextField {...params} label='Порядок' variant='outlined' />}
      disabled={disabled}
    />
  )
}

export default PositionPicker
