import React, {FC, useEffect, useCallback} from 'react'
import {Figure} from '@graphql/types'
import styled from 'styled-components'
import {
  useBannerQuery,
  useUpdateBannerForAllRegionsMutation,
  useUpdateBannerMutation
} from './gql/BannerContent.generated'
import {FormProvider, useForm, useWatch} from 'react-hook-form'
import BannerEditor from './BannerEditor/BannerEditor'
import BannerPreview from './BannerPreview/BannerPreview'
import {useSnackbar} from 'notistack'

type TabPanelContentProps = {
  regionUid?: string
}

export type BannerType = {
  description: string
  figure: Figure
  title: string
  uid: string
  url: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 48px;
`

const BannerContent: FC<TabPanelContentProps> = ({regionUid}) => {
  const banner = useBannerQuery({
    variables: {
      regionUid: regionUid
    },
    fetchPolicy: 'cache-and-network'
  })
  const snackbar = useSnackbar()
  const [updateBanner] = useUpdateBannerMutation()
  const [updateBannerForAllRegions] = useUpdateBannerForAllRegionsMutation()

  const defaultValue: BannerType = {
    description: banner.data?.banner?.description || '',
    figure: banner.data?.banner?.figure as Figure,
    title: banner.data?.banner?.title || '',
    uid: banner.data?.banner?.uid,
    url: banner.data?.banner?.url || ''
  }

  const {control, setValue, handleSubmit, formState, ...methodsForm} = useForm<any>({
    defaultValues: defaultValue
  })
  const fields = useWatch({control})

  const updateBannerAction = useCallback(async () => {
    try {
      await updateBanner({
        variables: {
          data: {
            description: fields.description,
            figureUid: fields.figure.uid,
            regionUid: regionUid,
            title: fields.title,
            url: fields.url
          }
        }
      })
      snackbar.enqueueSnackbar('Сохранено', {variant: 'success'})
    } catch {}
  }, [fields, regionUid, updateBanner, snackbar])

  const updateBannerForAllRegionsAction = useCallback(async () => {
    try {
      await updateBannerForAllRegions({
        variables: {
          data: {
            description: fields.description,
            figureUid: fields.figure.uid,
            title: fields.title,
            url: fields.url
          }
        }
      })
      snackbar.enqueueSnackbar('Сохранено', {variant: 'success'})
    } catch {}
  }, [fields, updateBannerForAllRegions, snackbar])

  useEffect(() => {
    setValue('description', banner.data?.banner?.description)
    setValue('figure', banner.data?.banner?.figure)
    setValue('url', banner.data?.banner?.url)
    setValue('title', banner.data?.banner?.title)
    setValue('uid', banner.data?.banner?.uid)
  }, [banner, setValue])

  return (
    <FormProvider
      {...{
        control,
        setValue,
        handleSubmit,
        formState,
        ...methodsForm
      }}
    >
      <Wrapper>
        <BannerEditor updateBanner={updateBannerAction} updateAllBanners={updateBannerForAllRegionsAction} />
        <BannerPreview />
      </Wrapper>
    </FormProvider>
  )
}

export default BannerContent
