import React, {FC} from 'react'
import styled from 'styled-components'
import {dividerColor} from '../../../../@theme/vars'
import {Box, Grid, Typography} from '@mui/material'
import format from 'date-fns/format'
import {TopNewsBlockChange} from '@graphql/types'

type BlockChangeProps = {
  index: number
  change: TopNewsBlockChange
}

const Wrapper = styled.div`
  padding: 13px 5px;
  border-bottom: 1px ${dividerColor} solid;
`

const Image = styled.div<{url: string}>`
  height: 132px;
  width: 185px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('${({url}) => url}');
`

const ChangeInfo = styled(Grid)`
  > div:not(:first-child) {
    margin-top: 10px;
  }
`

const BlockChange: FC<BlockChangeProps> = ({change, index}) => {
  return (
    <Wrapper>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item md={1}>
          <Typography variant='h2'>{1 + index}</Typography>

          <Typography>{format(new Date(change.createdAt), 'dd.MM.yyyy HH:mm')}</Typography>
        </Grid>
        <Grid container item md={8}>
          {change.isFigureVersionChanged && (
            <Grid container item direction='column' md={4}>
              <Typography variant='subtitle2'>Фотография</Typography>
              <Image url={change.newFigureVersion?.primaryProcessedImage?.url || ''} />
            </Grid>
          )}

          <ChangeInfo container item direction='column' md={8}>
            {change.isTitleChanged && (
              <Grid item>
                <Typography variant='subtitle2'>Заголовок</Typography>
                <Typography variant='h2'>{change.newTitle}</Typography>
              </Grid>
            )}
            <Grid container spacing={2} alignItems='center'>
              {change.isRegionChanged && (
                <Grid item>
                  <Typography variant='subtitle2'>Регион</Typography>
                  <Typography variant='h2'>{change.newRegion?.title || 'Удалена'}</Typography>
                </Grid>
              )}

              {change.isPositionChanged && (
                <Grid item>
                  <Typography variant='subtitle2'>Порядок</Typography>
                  <Typography variant='h2'>
                    {change?.newPosition !== null ? (change?.newPosition as number) + 1 : 'Нет'}
                  </Typography>
                </Grid>
              )}
              {change.isIsExclusiveChanged && (
                <Grid item>
                  <Typography variant='subtitle2'>Эксклюзив</Typography>
                  <Typography variant='h2'>{change.newIsExclusive ? 'Да' : 'Нет'}</Typography>
                </Grid>
              )}
            </Grid>
          </ChangeInfo>
        </Grid>

        <Grid item md={2}>
          <Box textAlign='end'>
            <Typography>
              {change.user.firstname} {change.user.lastname}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default BlockChange
