import React, {useCallback, useRef, useState} from 'react'
import {Id, toast} from 'react-toastify'
import {fetchPersons} from '@fetch/NnAPI'
import {Person} from '@fetch/NnAPI.types'
import {getQuillEditor} from '@utils/getQuillEditor'
import {addPersonsUrls, addRemovedPerson, getPersonsUrls, getRemovedPersons} from '@utils/removedPersonsStorage'
import {Button, ContentWrapper, Link, StyledCloseIcon, StyledUl, Title} from './useSnackbarPersons.styles'
import {IGetPersonInfo, IToastContenet} from './useSnackbarPersons.types'

export const useSnackbarPersons = (editorRef, isAutoSave, publicationId) => {
  const [personsList, setPersonsList] = useState([])
  const toastIdRef = useRef<Id | null>(null)

  const editorTextLengthRef = useRef(null)

  const abortControllerRef = useRef<AbortController | null>(null)

  const findAllOccurrences = (text, subText) => {
    const indices = []
    let index = text.indexOf(subText)
    while (index !== -1) {
      indices.push(index)
      index = text.indexOf(subText, index + subText.length)
    }
    return indices
  }

  const handleRemoveLink = useCallback(
    person => {
      const quill = getQuillEditor(editorRef)
      if (quill) {
        const text = quill.getText()
        const indices = findAllOccurrences(text, person.name)
        if (indices.length > 0) {
          indices.forEach(index => {
            quill.formatText(index, person.name.length, {link: false}, 'user')
          })
        }

        setPersonsList(prevPersonsList => {
          const updatedPersonsList = prevPersonsList.filter(personState => personState.name !== person.name)

          if (updatedPersonsList.length === 0 && toastIdRef.current !== null) {
            toast.dismiss(toastIdRef.current)
            toastIdRef.current = null
          } else {
            updateToastContent(updatedPersonsList)
          }

          return updatedPersonsList
        })

        toast.info(<div>Ссылка на персону удалена</div>, {
          autoClose: 5000,
          containerId: 'NnToast'
        })
      }
    },
    [toastIdRef, editorRef]
  )

  const ToastContent = ({persons, onRemoveLink}: IToastContenet) => (
    <div>
      <Title>Автоматически добавлены ссылки на персон:</Title>
      <StyledUl>
        {persons.map(person => (
          <li key={person.normalize_name}>
            <ContentWrapper>
              <Link href={person.url} target='_blank'>
                {person.normalize_name}
              </Link>
              <Button variant='text' onClick={() => onRemoveLink(person)}>
                <StyledCloseIcon />
                Удалить ссылку
              </Button>
            </ContentWrapper>
          </li>
        ))}
      </StyledUl>
    </div>
  )

  const updateToastContent = (updatedPersonsList: Person[]) => {
    if (toastIdRef.current !== null) {
      toast.update(toastIdRef.current, {
        render: <ToastContent persons={updatedPersonsList} onRemoveLink={handleRemoveLink} />,
        containerId: 'NnToast'
      })
    }
  }

  const handleToastClose = () => {
    setPersonsList([])
    toastIdRef.current = null
  }

  const getPersonsInfo = useCallback(
    async ({data, setIsLoading, editorTextLength}: IGetPersonInfo) => {
      try {
        if (editorTextLengthRef.current !== editorTextLength) {
          editorTextLengthRef.current = null
          setIsLoading(true)
          if (abortControllerRef.current) {
            abortControllerRef.current.abort()
          }
          const controller = new AbortController()
          abortControllerRef.current = controller
          const signal = controller.signal

          const quill = getQuillEditor(editorRef)

          const responseJson = await fetchPersons({data, signal})

          if (responseJson && quill) {
            let persons = responseJson['person']

            if (typeof persons === 'string') {
              setIsLoading(false)
              return
            }

            const currentSelection = quill.getSelection()
            const repeatPersonsUrl = getPersonsUrls(publicationId)
            const personsForToast: Person[] = []

            persons = persons.filter(person => !repeatPersonsUrl.includes(person.url))

            persons.forEach(person => {
              const indices = findAllOccurrences(quill.getText(), person.name)
              const firstIndex = indices[0]
              if (firstIndex !== undefined) {
                quill.formatText(firstIndex, person.name.length, {link: person.url}, 'user')
                addPersonsUrls(publicationId, person.url)
                personsForToast.push(person)
              }
            })

            if (currentSelection) {
              quill.setSelection(currentSelection)
            }

            setPersonsList(personsForToast)

            if (isAutoSave && personsForToast.length > 0) {
              editorTextLengthRef.current = editorTextLength

              const content = <ToastContent persons={personsForToast} onRemoveLink={handleRemoveLink} />

              if (toastIdRef.current === null) {
                const id = toast.success(content, {
                  autoClose: 10000,
                  containerId: 'NnToast',
                  closeOnClick: false,
                  onClose: handleToastClose
                })
                toastIdRef.current = id
              } else {
                toast.update(toastIdRef.current, {
                  render: content,
                  containerId: 'NnToast'
                })
              }
            }
          }
          setIsLoading(false)
        }
      } catch (e) {
        if (e.name === 'AbortError') {
          return
        }
        console.error(e)
        setIsLoading(false)
      }
    },
    [editorRef, isAutoSave, handleRemoveLink, publicationId]
  )

  return {
    getPersonsInfo
  }
}
