import React, {FC} from 'react'
import {NothinFoundedWrapper} from './NothingFounded.styles'
import {NothingFoundedProps} from './NothingFoundedProps'

export const NothingFounded: FC<NothingFoundedProps> = ({texts}) => {
  return (
    <NothinFoundedWrapper>
      {texts.map((text, ind) => (
        <div key={ind}>{text}</div>
      ))}
    </NothinFoundedWrapper>
  )
}
