import React, {cloneElement, FC, ReactElement, useCallback, useEffect, useState} from 'react'
import {Tab} from '@mui/material'
import {useHistory, useParams} from 'react-router'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import TabsWithBorder from '@components/UI/styled/TabsWithBorder'
import TabPanel from '@components/UI/TabPanel/TabPanel'
import useWindowResize from '@hooks/useWindowResize'
import {useRegionsMainQuery} from './gql/TopNewsRegionTabs.generated'

type RegionTabsProps = {
  children: ReactElement
}

const TopNewsRegionTabs: FC<RegionTabsProps> = ({children}) => {
  const {data, loading} = useRegionsMainQuery()
  const {regionId} = useParams<{regionId: string}>()
  const [value, setValue] = useState<number>(
    data?.regions?.length && data?.regions?.findIndex(item => item.uid === regionId) > 0
      ? data?.regions?.findIndex(item => item.uid === regionId)
      : 0
  )
  const [width] = useWindowResize()
  const history = useHistory()

  const {topNewsStore} = useStore()
  const {addingBlocksCache, deletingBlocksCache, resetCache, setConfirmSaveModalOpen} = topNewsStore

  const handleChange = useCallback(
    (regionUid: string, newValue: number) => {
      if (addingBlocksCache.size > 0 || deletingBlocksCache.size > 0) {
        setConfirmSaveModalOpen(true)
        return
      }
      setValue(newValue)
      history.push(`/main-page/top-news/${history.location.pathname.includes('history') ? 'history/' : ''}${regionUid}`)
    },
    [addingBlocksCache, deletingBlocksCache, history, setConfirmSaveModalOpen]
  )

  useEffect(() => {
    if (!regionId && data?.regions?.length) {
      resetCache()
      handleChange(data.regions[0].uid, 0)
    }
  }, [regionId, data?.regions, resetCache])

  return (
    <>
      {width && !loading && data?.regions?.length && (
        <TabsWithBorder
          value={value}
          indicatorColor='primary'
          textColor='inherit'
          scrollButtons={true}
          variant='scrollable'
        >
          {data?.regions?.map((region, ind) => (
            <Tab label={region.title} key={region.uid} onClick={() => handleChange(region.uid, ind)} />
          ))}
        </TabsWithBorder>
      )}

      {data?.regions?.map((region, i) => (
        <TabPanel value={value} index={i} key={region.uid}>
          {cloneElement(children, {
            regionId: region.uid || ''
          })}
        </TabPanel>
      ))}
    </>
  )
}

export default observer(TopNewsRegionTabs)
