import React from 'react'
import PageContainer from '../../components/UI/PageContainer/PageContainer'
import {TopNewsBannerHistoryContent} from '../../components/MainSelection/TopNews/TopNewsBannerHistoryContent/TopNewsBannerHistoryContent'

const TopNewsBannerHistoryPage: React.FC = () => {
  return (
    <PageContainer>
      <TopNewsBannerHistoryContent />
    </PageContainer>
  )
}

export default TopNewsBannerHistoryPage
