import styled from 'styled-components'
import {IconButton, Typography} from '@mui/material'
import WatchLater from '@mui/icons-material/WatchLater'
import {
  dividerColor,
  grayColor,
  lightgrayColor,
  mainTextColor,
  robotoFont,
  screenM,
  screenS,
  secondTextColor
} from '../../../../@theme/vars'

export const GridDiv = styled.div`
  min-height: 48px;
  font-size: 14px;
  line-height: 1.2;
  color: ${mainTextColor};
  padding: 8px 10px;
  border-bottom: 1px solid ${dividerColor};
  display: -ms-grid;
  display: grid;
  grid-template:
    'time title     title     settings authors'
    '.    shortInfo shortInfo settings authors';
  grid-template-columns: 55px 5fr 65px 3fr 3fr;
  grid-column-gap: 14px;
  align-items: center;
  @media only screen and (max-width: ${screenM}) {
    grid-row-gap: 14px;
    margin-left: 0;
  }
`

export const ShortInfo = styled.div`
  grid-area: shortInfo;
  text-align: left;
  display: flex;
  column-gap: 10px;
`

export const StyledTypography = styled(Typography)`
  grid-area: title;
  font-weight: 400;
  line-height: 1.2;
  font-size: 14px;
  font-style: normal;
  font-family: ${robotoFont};
  color: ${mainTextColor};
`

export const TimeDiv = styled.div`
  grid-area: time;
  color: ${grayColor};
  display: flex;
`

export const Title = styled.h3`
  grid-area: title;
  font-weight: 500;
  line-height: 1.3;
  font-size: 16px;
  font-style: normal;
  font-family: ${robotoFont};
`

export const Settings = styled.div`
  grid-area: settings;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: ${screenM}) {
    text-align: left;
  }

  @media (max-width: ${screenM}) {
    justify-content: flex-start;
  }
`

export const Authors = styled.div`
  grid-area: authors;
  color: ${secondTextColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

export const AuthorsTitle = styled(Typography)`
  text-align: right;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
`
export const Sources = styled.div`
  color: ${lightgrayColor};
  grid-area: sources;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 5px;
`

export const StyledWatchLater = styled(WatchLater)`
  color: ${grayColor};
  margin-right: 5px;
  margin-bottom: 2px;
`

export const StyledIconButton = styled(IconButton)`
  color: inherit;
`
export const RedirectToSiteButton = styled(IconButton)`
  color: inherit;
  margin-left: 15px;
`

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 1266px;
  justify-content: space-between;
  border-bottom: 1px solid ${dividerColor};
`
export const FlexTimeDiv = styled.div`
  color: ${grayColor};
  flex-grow: 0;
  margin-right: 15px;
  margin-bottom: auto;
  margin-top: 12px;
`
export const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  /* margin: 12px 0 12px; */
  @media only screen and (max-width: ${screenS}) {
    flex-direction: column;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`
export const FlexPublicationInfo = styled.div`
  width: calc(50% - 20px);
  margin: 12px 10px;
  @media only screen and (max-width: ${screenS}) {
    width: calc(85% - 20px);
    margin: 0;
  }
`
export const FlexTitle = styled.div`
  font-weight: 500;
  line-height: 1.3;
  font-size: 16px;
  font-style: normal;
  font-family: ${robotoFont};
`
export const FlexShortInfo = styled.div`
  text-align: left;
  display: flex;
  column-gap: 10px;
`
export const Regions = styled.div`
  font-size: 12px;
  font-family: ${robotoFont};
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${grayColor};
`
export const FlexSettings = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: ${screenS}) {
    justify-content: flex-start;
    text-align: left;
  }
`
export const FlexAuthors = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: ${secondTextColor};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 21%;
  min-width: 160px;
  position: relative;
  @media only screen and (max-width: ${screenS}) {
    align-items: flex-start;
    padding-bottom: 12px;
    width: 100%;
    min-width: 250px;
  }
`
