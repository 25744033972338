import styled from 'styled-components'
import {mainTextColor, blueColor, screenS} from '../../../../@theme/vars'
import {StyledFormControl} from '@components/UI/styled'

export const StyledUploadWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;
  display: flex;
  width: 212px;
  height: 120px;
  color: ${blueColor};
  border: 2px dashed ${blueColor};
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${screenS}) {
    width: 320px;
    height: 182px;
  }
`

export const VideoLoaderTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: ${mainTextColor};
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: ${screenS}) {
    margin-bottom: 8px;
  }
`

export const StyledDeleteVideoWrapper = styled.div`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 12px;
`

export const StyledDeleteVideoButton = styled.div`
  background: #c4c4c4;
  font-weight: 500;
  padding: 4px;
`

export const StyledFormWrapper = styled(StyledFormControl)`
  width: 428px;
  margin-left: 12px;
  margin-top: 32px;
  @media (max-width: ${screenS}) {
    width: 320px;
    margin-top: 20px;
    margin-left: 0;
  }
`
export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;

  @media (max-width: ${screenS}) {
    flex-direction: column;
    align-items: center;
  }
`

export const StyledVideoIframe = styled.iframe`
  width: 212px;
  height: 120px;

  @media (max-width: ${screenS}) {
    width: 320px;
    height: 182px;
  }
`
export const StyledDeleteBtn = styled.div`
  display: block;
  position: absolute;
  top: 30px;
  left: 185px;
  @media (max-width: ${screenS}) {
    left: 462px;
  }
`
export const VideoBoxText = styled.div`
  width: 170px;
  height: 42px;
  text-align: center;
  @media (max-width: ${screenS}) {
    width: 145px;
  }
`
