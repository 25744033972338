import {Button} from '@mui/material'
import styled from 'styled-components'
import {StyledFormControl} from '@components/UI/styled'
import {whiteColor} from '@theme/vars'

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`

export const ButtonWrapper = styled(Button)`
  width: 100px;
  height: 32px !important;
  min-height: 32px !important;
  border-radius: 50px;

  &:not(:first-child) {
    margin-left: 12px;
  }

  @media (max-width: 450px) {
    width: calc(100% - 20px);
  }
`

export const StyledFormControlWrapper = styled(StyledFormControl)`
  width: 360px;
  margin-left: 16px;

  @media (max-width: 580px) {
    margin-left: 0px;
    margin-top: 20px;
    width: 100%;
  }
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  position: absolute;
  /* opacity: 0.5; */
  z-index: 1;
  background-color: ${whiteColor};

  & > div.spinner-wrapper {
    height: calc(100vh - 300px);
  }
`
