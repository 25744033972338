import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type GetCommentsListQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>
  findWord?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
  isBad?: Types.Maybe<Types.Scalars['Boolean']>
  moderatedAtDate?: Types.Maybe<Types.Scalars['Date']>
  publicationUid?: Types.Maybe<Types.Scalars['UUID']>
  sort?: Types.Maybe<Types.CommentsSort>
  status?: Types.Maybe<Types.CommentStatus>
}>

export type GetCommentsListQuery = {__typename?: 'Query'} & {
  comments?: Types.Maybe<
    {__typename?: 'CommentsConnection'} & Pick<Types.CommentsConnection, 'totalCount'> & {
        edges: Array<
          Types.Maybe<
            {__typename?: 'CommentsEdge'} & Pick<Types.CommentsEdge, 'cursor'> & {
                node?: Types.Maybe<{__typename?: 'Comment'} & CommentsNodeFragment>
              }
          >
        >
        pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      }
  >
}

export type GetCommentsCountGroupByPublicationUidQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
  sort?: Types.Maybe<Types.CommentsSort>
}>

export type GetCommentsCountGroupByPublicationUidQuery = {__typename?: 'Query'} & {
  comments?: Types.Maybe<
    {__typename?: 'CommentsConnection'} & Pick<Types.CommentsConnection, 'totalCount'> & {
        edges: Array<
          Types.Maybe<
            {__typename?: 'CommentsEdge'} & Pick<Types.CommentsEdge, 'cursor'> & {
                node?: Types.Maybe<
                  {__typename?: 'Comment'} & Pick<Types.Comment, 'allComments' | 'newComments'> & {
                      publication: {__typename?: 'Publication'} & Pick<
                        Types.Publication,
                        'isAutomaticCommentsDisabled' | 'title' | 'typeSlug' | 'uid'
                      >
                    }
                >
              }
          >
        >
        pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      }
  >
}

export type GetPublicationCommentsListQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
  publicationUid?: Types.Maybe<Types.Scalars['UUID']>
}>

export type GetPublicationCommentsListQuery = {__typename?: 'Query'} & {
  comments?: Types.Maybe<
    {__typename?: 'CommentsConnection'} & Pick<Types.CommentsConnection, 'totalCount'> & {
        edges: Array<
          Types.Maybe<
            {__typename?: 'CommentsEdge'} & Pick<Types.CommentsEdge, 'cursor'> & {
                node?: Types.Maybe<
                  {__typename?: 'Comment'} & {
                    child?: Types.Maybe<
                      Array<
                        Types.Maybe<
                          {__typename?: 'Comment'} & {
                            child?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Comment'} & CommentsNodeFragment>>>
                          } & CommentsNodeFragment
                        >
                      >
                    >
                  } & CommentsNodeFragment
                >
              }
          >
        >
        pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      }
  >
}

export type GetCommentsTotalCountByStatusQueryVariables = Types.Exact<{
  status: Types.CommentStatus
}>

export type GetCommentsTotalCountByStatusQuery = {__typename?: 'Query'} & {
  comments?: Types.Maybe<{__typename?: 'CommentsConnection'} & Pick<Types.CommentsConnection, 'totalCount'>>
}

export type ModerateCommentsMutationVariables = Types.Exact<{
  data: Types.ModerateCommentsInput
}>

export type ModerateCommentsMutation = {__typename?: 'Mutation'} & {
  moderateComments?: Types.Maybe<
    {__typename?: 'ModerateCommentsPayload'} & Pick<Types.ModerateCommentsPayload, 'status'>
  >
}

export type GetCountBadAndNewQueryVariables = Types.Exact<{[key: string]: never}>

export type GetCountBadAndNewQuery = {__typename?: 'Query'} & Pick<Types.Query, 'countBadComments' | 'countNewComments'>

export type ModerateStatusCommentMutationVariables = Types.Exact<{
  data: Types.ModerateStatusCommentInput
}>

export type ModerateStatusCommentMutation = {__typename?: 'Mutation'} & {
  moderateStatusComment?: Types.Maybe<
    {__typename?: 'ModerateStatusCommentPayload'} & Pick<Types.ModerateStatusCommentPayload, 'status'>
  >
}

export type CommentsNodeFragment = {__typename?: 'Comment'} & Pick<
  Types.Comment,
  'createdAt' | 'id' | 'isBad' | 'moderatedAt' | 'status' | 'text' | 'uid' | 'updatedAt' | 'viewed'
> & {
    author: {__typename?: 'CommentAuthor'} & Pick<Types.CommentAuthor, 'ipAddress' | 'name' | 'sessionId'> & {
        user?: Types.Maybe<{__typename?: 'User'} & Pick<Types.User, 'firstname' | 'lastname' | 'uid' | 'username'>>
      }
    autoModeratorResult?: Types.Maybe<
      {__typename?: 'AutoModeratorResult'} & Pick<Types.AutoModeratorResult, 'authorName' | 'text'>
    >
    parentComment?: Types.Maybe<
      {__typename?: 'Comment'} & Pick<Types.Comment, 'id' | 'uid'> & {
          author: {__typename?: 'CommentAuthor'} & Pick<Types.CommentAuthor, 'name'>
        }
    >
    publication: {__typename?: 'Publication'} & Pick<Types.Publication, 'isAutomaticCommentsDisabled' | 'title' | 'uid'>
  }

export const CommentsNodeFragmentDoc = gql`
  fragment CommentsNode on Comment {
    author {
      ipAddress
      name
      sessionId
      user {
        firstname
        lastname
        uid
        username
      }
    }
    autoModeratorResult {
      authorName
      text
    }
    createdAt
    id
    isBad
    moderatedAt
    parentComment {
      id
      uid
      author {
        name
      }
    }
    publication {
      isAutomaticCommentsDisabled
      title
      uid
    }
    status
    text
    uid
    updatedAt
    viewed
  }
`
export const GetCommentsListDocument = gql`
  query getCommentsList(
    $after: String
    $findWord: String
    $first: Int
    $isBad: Boolean
    $moderatedAtDate: Date
    $publicationUid: UUID
    $sort: CommentsSort
    $status: CommentStatus
  ) {
    comments(
      after: $after
      filter: {
        findWord: $findWord
        moderatedAtDate: $moderatedAtDate
        isBad: $isBad
        publicationUid: $publicationUid
        status: $status
      }
      first: $first
      limit: 30
      sort: $sort
    ) {
      edges {
        cursor
        node {
          ...CommentsNode
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${CommentsNodeFragmentDoc}
`

/**
 * __useGetCommentsListQuery__
 *
 * To run a query within a React component, call `useGetCommentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      findWord: // value for 'findWord'
 *      first: // value for 'first'
 *      isBad: // value for 'isBad'
 *      moderatedAtDate: // value for 'moderatedAtDate'
 *      publicationUid: // value for 'publicationUid'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCommentsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCommentsListQuery, GetCommentsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetCommentsListQuery, GetCommentsListQueryVariables>(GetCommentsListDocument, options)
}
export function useGetCommentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsListQuery, GetCommentsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetCommentsListQuery, GetCommentsListQueryVariables>(GetCommentsListDocument, options)
}
export type GetCommentsListQueryHookResult = ReturnType<typeof useGetCommentsListQuery>
export type GetCommentsListLazyQueryHookResult = ReturnType<typeof useGetCommentsListLazyQuery>
export type GetCommentsListQueryResult = Apollo.QueryResult<GetCommentsListQuery, GetCommentsListQueryVariables>
export const GetCommentsCountGroupByPublicationUidDocument = gql`
  query getCommentsCountGroupByPublicationUid($after: String, $first: Int, $sort: CommentsSort) {
    comments(after: $after, first: $first, limit: 100, sort: $sort) {
      edges {
        cursor
        node {
          allComments
          newComments
          publication {
            isAutomaticCommentsDisabled
            title
            typeSlug
            uid
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

/**
 * __useGetCommentsCountGroupByPublicationUidQuery__
 *
 * To run a query within a React component, call `useGetCommentsCountGroupByPublicationUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsCountGroupByPublicationUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsCountGroupByPublicationUidQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCommentsCountGroupByPublicationUidQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommentsCountGroupByPublicationUidQuery,
    GetCommentsCountGroupByPublicationUidQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<
    GetCommentsCountGroupByPublicationUidQuery,
    GetCommentsCountGroupByPublicationUidQueryVariables
  >(GetCommentsCountGroupByPublicationUidDocument, options)
}
export function useGetCommentsCountGroupByPublicationUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentsCountGroupByPublicationUidQuery,
    GetCommentsCountGroupByPublicationUidQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<
    GetCommentsCountGroupByPublicationUidQuery,
    GetCommentsCountGroupByPublicationUidQueryVariables
  >(GetCommentsCountGroupByPublicationUidDocument, options)
}
export type GetCommentsCountGroupByPublicationUidQueryHookResult = ReturnType<
  typeof useGetCommentsCountGroupByPublicationUidQuery
>
export type GetCommentsCountGroupByPublicationUidLazyQueryHookResult = ReturnType<
  typeof useGetCommentsCountGroupByPublicationUidLazyQuery
>
export type GetCommentsCountGroupByPublicationUidQueryResult = Apollo.QueryResult<
  GetCommentsCountGroupByPublicationUidQuery,
  GetCommentsCountGroupByPublicationUidQueryVariables
>
export const GetPublicationCommentsListDocument = gql`
  query getPublicationCommentsList($after: String, $first: Int, $publicationUid: UUID) {
    comments(after: $after, filter: {publicationUid: $publicationUid}, first: $first, limit: 10) {
      edges {
        cursor
        node {
          ...CommentsNode
          child {
            ...CommentsNode
            child {
              ...CommentsNode
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
  ${CommentsNodeFragmentDoc}
`

/**
 * __useGetPublicationCommentsListQuery__
 *
 * To run a query within a React component, call `useGetPublicationCommentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationCommentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationCommentsListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      publicationUid: // value for 'publicationUid'
 *   },
 * });
 */
export function useGetPublicationCommentsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPublicationCommentsListQuery, GetPublicationCommentsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetPublicationCommentsListQuery, GetPublicationCommentsListQueryVariables>(
    GetPublicationCommentsListDocument,
    options
  )
}
export function useGetPublicationCommentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPublicationCommentsListQuery, GetPublicationCommentsListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetPublicationCommentsListQuery, GetPublicationCommentsListQueryVariables>(
    GetPublicationCommentsListDocument,
    options
  )
}
export type GetPublicationCommentsListQueryHookResult = ReturnType<typeof useGetPublicationCommentsListQuery>
export type GetPublicationCommentsListLazyQueryHookResult = ReturnType<typeof useGetPublicationCommentsListLazyQuery>
export type GetPublicationCommentsListQueryResult = Apollo.QueryResult<
  GetPublicationCommentsListQuery,
  GetPublicationCommentsListQueryVariables
>
export const GetCommentsTotalCountByStatusDocument = gql`
  query getCommentsTotalCountByStatus($status: CommentStatus!) {
    comments(filter: {status: $status}) {
      totalCount
    }
  }
`

/**
 * __useGetCommentsTotalCountByStatusQuery__
 *
 * To run a query within a React component, call `useGetCommentsTotalCountByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsTotalCountByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsTotalCountByStatusQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetCommentsTotalCountByStatusQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommentsTotalCountByStatusQuery, GetCommentsTotalCountByStatusQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetCommentsTotalCountByStatusQuery, GetCommentsTotalCountByStatusQueryVariables>(
    GetCommentsTotalCountByStatusDocument,
    options
  )
}
export function useGetCommentsTotalCountByStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentsTotalCountByStatusQuery,
    GetCommentsTotalCountByStatusQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetCommentsTotalCountByStatusQuery, GetCommentsTotalCountByStatusQueryVariables>(
    GetCommentsTotalCountByStatusDocument,
    options
  )
}
export type GetCommentsTotalCountByStatusQueryHookResult = ReturnType<typeof useGetCommentsTotalCountByStatusQuery>
export type GetCommentsTotalCountByStatusLazyQueryHookResult = ReturnType<
  typeof useGetCommentsTotalCountByStatusLazyQuery
>
export type GetCommentsTotalCountByStatusQueryResult = Apollo.QueryResult<
  GetCommentsTotalCountByStatusQuery,
  GetCommentsTotalCountByStatusQueryVariables
>
export const ModerateCommentsDocument = gql`
  mutation moderateComments($data: ModerateCommentsInput!) {
    moderateComments(data: $data) {
      status
    }
  }
`
export type ModerateCommentsMutationFn = Apollo.MutationFunction<
  ModerateCommentsMutation,
  ModerateCommentsMutationVariables
>

/**
 * __useModerateCommentsMutation__
 *
 * To run a mutation, you first call `useModerateCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModerateCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moderateCommentsMutation, { data, loading, error }] = useModerateCommentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModerateCommentsMutation(
  baseOptions?: Apollo.MutationHookOptions<ModerateCommentsMutation, ModerateCommentsMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ModerateCommentsMutation, ModerateCommentsMutationVariables>(
    ModerateCommentsDocument,
    options
  )
}
export type ModerateCommentsMutationHookResult = ReturnType<typeof useModerateCommentsMutation>
export type ModerateCommentsMutationResult = Apollo.MutationResult<ModerateCommentsMutation>
export type ModerateCommentsMutationOptions = Apollo.BaseMutationOptions<
  ModerateCommentsMutation,
  ModerateCommentsMutationVariables
>
export const GetCountBadAndNewDocument = gql`
  query getCountBadAndNew {
    countBadComments
    countNewComments
  }
`

/**
 * __useGetCountBadAndNewQuery__
 *
 * To run a query within a React component, call `useGetCountBadAndNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountBadAndNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountBadAndNewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountBadAndNewQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCountBadAndNewQuery, GetCountBadAndNewQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetCountBadAndNewQuery, GetCountBadAndNewQueryVariables>(GetCountBadAndNewDocument, options)
}
export function useGetCountBadAndNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCountBadAndNewQuery, GetCountBadAndNewQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetCountBadAndNewQuery, GetCountBadAndNewQueryVariables>(
    GetCountBadAndNewDocument,
    options
  )
}
export type GetCountBadAndNewQueryHookResult = ReturnType<typeof useGetCountBadAndNewQuery>
export type GetCountBadAndNewLazyQueryHookResult = ReturnType<typeof useGetCountBadAndNewLazyQuery>
export type GetCountBadAndNewQueryResult = Apollo.QueryResult<GetCountBadAndNewQuery, GetCountBadAndNewQueryVariables>
export const ModerateStatusCommentDocument = gql`
  mutation moderateStatusComment($data: ModerateStatusCommentInput!) {
    moderateStatusComment(data: $data) {
      status
    }
  }
`
export type ModerateStatusCommentMutationFn = Apollo.MutationFunction<
  ModerateStatusCommentMutation,
  ModerateStatusCommentMutationVariables
>

/**
 * __useModerateStatusCommentMutation__
 *
 * To run a mutation, you first call `useModerateStatusCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModerateStatusCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moderateStatusCommentMutation, { data, loading, error }] = useModerateStatusCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModerateStatusCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<ModerateStatusCommentMutation, ModerateStatusCommentMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ModerateStatusCommentMutation, ModerateStatusCommentMutationVariables>(
    ModerateStatusCommentDocument,
    options
  )
}
export type ModerateStatusCommentMutationHookResult = ReturnType<typeof useModerateStatusCommentMutation>
export type ModerateStatusCommentMutationResult = Apollo.MutationResult<ModerateStatusCommentMutation>
export type ModerateStatusCommentMutationOptions = Apollo.BaseMutationOptions<
  ModerateStatusCommentMutation,
  ModerateStatusCommentMutationVariables
>
