import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

export const ArrowForward: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.05 9C13.5083 7.65833 11.5083 6.83333 9.3 6.83333C5.425 6.83333 2.15 9.35833 1 12.85L2.96667 13.5C3.84167 10.8417 6.34167 8.91667 9.3 8.91667C10.925 8.91667 12.4083 9.51667 13.5667 10.4833L10.55 13.5H18.05V6L15.05 9Z'
          fill='#222222'
        />
      </svg>
    </SvgIcon>
  )
}
