import {Quill as ReactQuill} from 'react-quill'
import {DeltaOperation, Quill} from 'quill'
import {OnlineTranslationDividerBlot} from '@components/TextEditor/Blots'
import {SortOrder} from '../../../types/SortOrder'

const Delta = ReactQuill.import('delta')

type PublicationChunks = {
  publicationBody: DeltaOperation[]
  onlineTranslationChunks: DeltaOperation[][]
}

const comparator = (sortOrder: SortOrder) => (a: DeltaOperation[], b: DeltaOperation[]) => {
  const firstElement = a[0].insert[OnlineTranslationDividerBlot.blotName].createAt
  const secondElement = b[0].insert[OnlineTranslationDividerBlot.blotName].createAt

  if (firstElement > secondElement) {
    return sortOrder === SortOrder.Asc ? -1 : 1
  }
  if (firstElement < secondElement) {
    return sortOrder === SortOrder.Desc ? -1 : 1
  }
  return 0
}

const reverseOnlineTranslation = (quill: Quill, isOnlineTranslationReversed: boolean, chunks: PublicationChunks) => {
  const {publicationBody, onlineTranslationChunks} = chunks

  if (isOnlineTranslationReversed) {
    onlineTranslationChunks.sort(comparator(SortOrder.Asc))
  } else {
    onlineTranslationChunks.sort(comparator(SortOrder.Desc))
  }

  // Иногда нарушается порядок рендеринга онлайн-трансляции - первый элемент улетает в конец
  // или последний элемент улетает в начало для этого заведен костыль с forEach и queueMicrotask

  const ops = [...publicationBody, ...onlineTranslationChunks.flat()]
  quill.setContents(new Delta({ops}), 'user')
}

export {reverseOnlineTranslation}
