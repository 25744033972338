import React, {cloneElement, FC, useCallback, useEffect, useState} from 'react'
import {Fade, Typography} from '@mui/material'
import {Arrow, StylePopper, Wrapper} from './ConfirmationButton.styles'
import {ConfirmationButtonProps} from './ConfirmationButtonProps'

const ConfirmationButton: FC<ConfirmationButtonProps> = ({
  id,
  focusedElementId,
  textPopper,
  actionPopper,
  setFocusedElementId,
  children
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  const onMouseEnter = (event: React.MouseEvent<HTMLButtonElement>, id) => {
    setCurFocusedElementId(id)
    setFocusedElementId(id)
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  const [curFocusedElementId, setCurFocusedElementId] = useState('')

  useEffect(() => {
    setCurFocusedElementId(focusedElementId)

    if (focusedElementId !== id) {
      setOpen(false)
      setCurFocusedElementId('')
    }
  }, [id, focusedElementId, setCurFocusedElementId, setOpen])

  const onMouseLeave = useCallback(
    (elId: string) => {
      setTimeout(() => {
        if (elId === curFocusedElementId) {
          setOpen(true)
        } else {
          setOpen(false)
          setCurFocusedElementId('')
        }
        setTimeout(() => {
          setOpen(false)
        }, 1500)
      }, 50)
    },
    [curFocusedElementId, setOpen]
  )

  const handleAction = (action: boolean) => {
    actionPopper && actionPopper(action)
    setOpen(false)
  }

  return (
    <>
      {cloneElement(children, {
        onMouseEnter: event => {
          onMouseEnter(event, id)
        },
        onMouseOut: () => {
          onMouseLeave(id)
        },
        onBlur: () => {
          setOpen(false)
          if (curFocusedElementId === id) {
            setFocusedElementId('')
          }
        }
      })}
      {id === curFocusedElementId && (
        <StylePopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement='top'
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef
              }
            }
          ]}
          style={{
            zIndex: 2
          }}
          onMouseLeave={() => {
            setOpen(false)
          }}
        >
          {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={100}>
              <div ref={arrowRef}>
                <Wrapper>
                  <Typography onClick={() => handleAction(true)}>{textPopper}</Typography>
                  {/* {actionPopper && (
                    <Box>
                      <Button onClick={() => handleAction(true)}>Да</Button>
                      <Button onClick={() => handleAction(false)}>Нет</Button>
                    </Box>
                  )} */}
                </Wrapper>
                <Arrow ref={setArrowRef} />
              </div>
            </Fade>
          )}
        </StylePopper>
      )}
    </>
  )
}

export default ConfirmationButton
