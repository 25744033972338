import React, {FC, useCallback} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {Checkbox, FormControlLabel, FormControlProps} from '@mui/material'

type RHFCheckboxLabelType = {
  name: string
  label: string
  handleClick?: (name: string) => void
} & FormControlProps

const RHFCheckboxLabel: FC<RHFCheckboxLabelType> = ({name, label, style, disabled, handleClick}) => {
  const {control} = useFormContext()

  return (
    <FormControlLabel
      label={label}
      style={style}
      control={
        <Controller
          name={name}
          control={control}
          render={({field}) => (
            <Checkbox
              {...field}
              color='primary'
              checked={field.value}
              disabled={disabled}
              onClick={
                handleClick
                  ? () => {
                      handleClick(name)
                    }
                  : undefined
              }
            />
          )}
        />
      }
    />
  )
}

export default RHFCheckboxLabel
