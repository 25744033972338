export const REPORTS_LIST_FILTERS_CONSTS = {
  authorMsg: 'Автор',
  exportMsg: 'Экспорт',
  invalidUrlError: 'Некорректная ссылка',
  nameOfExperimentMsg: 'Название эксперимента',
  periodDateError: 'Выбран слишком большой период поиска',
  publicationLinkMsg: 'Ссылка на публикацию',
  publishDateFromMsg: 'Начало периода публикации',
  publishDateToMsg: 'Конец периода публикации',
  requiredFieldsError: 'Заполните обязательные поля',
  regionMsg: 'Регион',
  reportsListIsEmptyMsg: 'Список пуст',
  searchMsg: 'Найти',
  startDateMustBeLessEndDateError: 'Дата начала периода должна быть больше даты конца периода',
  viewsDateFromMsg: 'Начало периода просмотров',
  viewsDateToMsg: 'Конец периода просмотров'
}
