import {QuotePairs, quotesRegExp} from '../../../../types/QuotePairs'
import {dashReplacerHook} from './dashReplacerHook'
import {quotesReplacer} from './quotesReplacer'

/**
 * Запуск типографа для строки
 * @param value
 * @returns
 */
export const stringTypographRunner = (value: string): string => {
  const hasQuotationMark = quotesRegExp.test(value)
  const hasDash = / - /.test(value)

  if (hasQuotationMark) {
    value = quotesReplacer(value, QuotePairs, quotesRegExp)
  }

  if (hasDash) {
    value = dashReplacerHook(value)
  }

  return value
}
