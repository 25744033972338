import React, {FC} from 'react'
import {isEmpty} from 'lodash'
import {DoNotDistrubIcon} from '../Icons/DoNotDistrub'
import CloseIcon from '../Icons/CloseIcon'
import {ListInoAgentProps} from './ListInoagentProp'
import {LeftBlockWrapper, StyledUl, Title, WrapperContent} from './ListInoagent.styles'
import {TITLE_EXTREMIST, TITLE_INOAGENT, TITLE_TEXT, TITLE_TEXT_WARNING} from './ListInoagent.consts'

/**
 * Компонент ListInoagent
 * Предназначен для отображения списка иноагентов и экстремистов, найденных в тексте.
 * Принимает объект со списками иноагентов и экстремистов и отображает их в виде заголовков и элементов списка.
 * @param {Object} listInoagent - Объект со списками иноагентов и экстремистов
 * @param {boolean} isWarning - Флаг для определения Warning плашки и Error плашки
 * @param {function} handleCancel - Функция для закрытия плашки
 * @returns {JSX.Element} Возвращает JSX элемент со списком иноагентов и экстремистов
 */
const ListInoagent: FC<ListInoAgentProps> = ({listInoagent, isWarning, handleCancel}) => {
  return (
    <WrapperContent isWarning={isWarning}>
      <LeftBlockWrapper>
        <DoNotDistrubIcon fontSize='medium' />
      </LeftBlockWrapper>
      <div>
        <Title>
          {isWarning ? TITLE_TEXT_WARNING : TITLE_TEXT}{' '}
          {!isWarning && (listInoagent.INOAGENT.length !== 0 ? TITLE_INOAGENT : TITLE_EXTREMIST)}
        </Title>
        {isWarning ? (
          <StyledUl>
            {listInoagent.SAMENAMES.length !== 0 && (
              <>
                {listInoagent.SAMENAMES.map((samenames, index) => (
                  <li key={samenames + index}>{samenames}</li>
                ))}
              </>
            )}
          </StyledUl>
        ) : (
          <StyledUl>
            {listInoagent.INOAGENT.length !== 0 && (
              <>
                {listInoagent.INOAGENT.map((inoagent, index) => (
                  <li key={inoagent + index}>{inoagent}</li>
                ))}
              </>
            )}
            {listInoagent.EXTREMIST.length !== 0 && (
              <>
                {listInoagent.INOAGENT.length !== 0 && <Title>{TITLE_EXTREMIST}</Title>}
                {listInoagent.EXTREMIST.map((extremist, index) => (
                  <li key={extremist + index}>{extremist}</li>
                ))}
              </>
            )}
          </StyledUl>
        )}
      </div>
      {isWarning && <CloseIcon onClick={handleCancel} style={{cursor: 'pointer'}} />}
    </WrapperContent>
  )
}

export default ListInoagent
