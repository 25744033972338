import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Tooltip, Typography} from '@mui/material'
import CloseIcon from '@components/UI/Icons/CloseIcon'
import SortIcon from '@components/UI/Icons/SortIcon'
import {useStore} from '@stores/rootStoreContext'
import ActiveSortIcon from '@components/UI/Icons/ActiveSortIcon'
import {SortOrder} from '../../../../types/SortOrder'
import {ButtonWrapper, TableHeaderCellButtonsWrapper, TableHeaderCellWrapper} from './TableHeaderCell.styles'
import {TableHeaderCellProps} from './TableHeaderCellProps'
import {TABLE_HEADER_CELL_CONSTS} from './TableHeaderCell.consts'

const TableHeaderCell: FC<TableHeaderCellProps> = ({fieldName, title, height = '80px', onRemove}) => {
  const {tableStore} = useStore()
  const {sortFieldName, sortDirection, setSortFieldName, setSortDirection} = tableStore

  const [sortIconVisible, setSortIconVisible] = useState(false)
  const [direction, setDirection] = useState<SortOrder>(sortDirection)
  const [transformStyle, setTransformStyle] = useState(
    sortDirection === SortOrder.Asc ? 'rotate(180deg)' : 'rotate(0deg)'
  )

  const onChangeSort = useCallback(
    (name: string) => {
      setSortFieldName(name)
      setDirection(prev => (prev === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc))
    },
    [setSortFieldName]
  )

  useEffect(() => {
    setSortDirection(direction)
    setTransformStyle(direction === SortOrder.Asc ? 'rotate(180deg)' : 'rotate(0deg)')
  }, [direction, setSortDirection])

  return (
    <TableHeaderCellWrapper
      onMouseEnter={() => {
        fieldName && setSortIconVisible(true)
      }}
      onMouseLeave={() => {
        fieldName && setSortIconVisible(false)
      }}
      style={{height: height}}
    >
      {sortFieldName && (
        <TableHeaderCellButtonsWrapper>
          {fieldName && (
            <Tooltip
              title={
                sortFieldName === fieldName
                  ? TABLE_HEADER_CELL_CONSTS.changeSortOrder
                  : TABLE_HEADER_CELL_CONSTS.sortByFieldMsg
              }
            >
              <ButtonWrapper
                onClick={() => {
                  fieldName && onChangeSort(fieldName)
                }}
                style={{
                  transform: transformStyle
                }}
              >
                {sortFieldName === fieldName ? <ActiveSortIcon /> : sortIconVisible && <SortIcon />}
              </ButtonWrapper>
            </Tooltip>
          )}
          {onRemove && (
            <Tooltip title={TABLE_HEADER_CELL_CONSTS.deleteColumnMsg}>
              <ButtonWrapper
                onClick={() => {
                  fieldName && onRemove(fieldName)
                }}
              >
                <CloseIcon />
              </ButtonWrapper>
            </Tooltip>
          )}
        </TableHeaderCellButtonsWrapper>
      )}
      <Typography variant='text12M' paddingLeft={'4px'}>
        {title}
      </Typography>
    </TableHeaderCellWrapper>
  )
}

export default observer(TableHeaderCell)
