import React, {FC, useEffect, useState} from 'react'
import {FirstColumnWrapper, THeadWrapper, ThWrapper} from './ReportListTableHead.styles'
import TableHeaderCell from '@components/UI/Table/TableHeaderCell/TableHeaderCell'
import {REPORTS_LIST_TABLE_CONSTS} from '../ReportsListTable.consts'
import {ReportListTableHeadProps} from './ReportListTableHeadProps'

export const ReportListTableHead: FC<ReportListTableHeadProps> = ({fields, setFields}) => {
  const [curFields, setCurFields] = useState(fields)

  useEffect(() => {
    setCurFields(fields)
  }, [fields])

  return (
    <THeadWrapper>
      <ThWrapper style={{width: 34, verticalAlign: 'bottom', paddingBottom: '4px'}}>
        <FirstColumnWrapper>№</FirstColumnWrapper>
      </ThWrapper>
      {curFields.date && (
        <ThWrapper style={{width: 73}}>
          <TableHeaderCell
            fieldName={'publishedAtDate'}
            title={REPORTS_LIST_TABLE_CONSTS.date}
            onRemove={() => setFields(prev => ({...prev, date: false}))}
          />
        </ThWrapper>
      )}
      {curFields.time && (
        <ThWrapper style={{width: 49}}>
          <TableHeaderCell
            fieldName={'publishedAt'}
            title={REPORTS_LIST_TABLE_CONSTS.time}
            onRemove={() => setFields(prev => ({...prev, time: false}))}
          />
        </ThWrapper>
      )}
      {curFields.title && (
        <ThWrapper style={{width: 200}}>
          <TableHeaderCell
            fieldName={'title'}
            title={REPORTS_LIST_TABLE_CONSTS.title}
            onRemove={() => setFields(prev => ({...prev, title: false}))}
          />
        </ThWrapper>
      )}
      {curFields.seoTitle && (
        <ThWrapper style={{width: 200}}>
          <TableHeaderCell
            fieldName={'seoTitle'}
            title={REPORTS_LIST_TABLE_CONSTS.seoTitle}
            onRemove={() => setFields(prev => ({...prev, seoTitle: false}))}
          />
        </ThWrapper>
      )}
      {curFields.rubricAndSubrubric && (
        <ThWrapper style={{width: 164}}>
          <TableHeaderCell
            fieldName={'rubricTitle'}
            title={REPORTS_LIST_TABLE_CONSTS.rubricAndSubrubric}
            onRemove={() => setFields(prev => ({...prev, rubricAndSubrubric: false}))}
          />
        </ThWrapper>
      )}
      {curFields.authors && (
        <ThWrapper style={{width: 126}}>
          <TableHeaderCell
            fieldName={'authors'}
            title={REPORTS_LIST_TABLE_CONSTS.authors}
            onRemove={() => setFields(prev => ({...prev, authors: false}))}
          />
        </ThWrapper>
      )}
      {curFields.viewsCount && (
        <ThWrapper style={{width: 82}}>
          <TableHeaderCell
            fieldName={'viewsCount'}
            title={REPORTS_LIST_TABLE_CONSTS.viewsCount}
            onRemove={() => setFields(prev => ({...prev, viewsCount: false}))}
          />
        </ThWrapper>
      )}
      {curFields.uniqueUsersCount && (
        <ThWrapper style={{width: 84}}>
          <TableHeaderCell
            fieldName={'uniqueUsersCount'}
            title={REPORTS_LIST_TABLE_CONSTS.uniqueUsersCount}
            onRemove={() => setFields(prev => ({...prev, uniqueUsersCount: false}))}
          />
        </ThWrapper>
      )}
      {curFields.fromSocialNetworks && (
        <ThWrapper style={{width: 80}}>
          <TableHeaderCell
            fieldName={'fromSocialNetworkCount'}
            title={REPORTS_LIST_TABLE_CONSTS.fromSocialNetworks}
            onRemove={() => setFields(prev => ({...prev, fromSocialNetworks: false}))}
          />
        </ThWrapper>
      )}
      {curFields.fromSocialNetworksPercent && (
        <ThWrapper style={{width: 66}}>
          <TableHeaderCell
            fieldName={'fromSocialNetworksPercent'}
            title={REPORTS_LIST_TABLE_CONSTS.fromSocialNetworksPercent}
            onRemove={() => setFields(prev => ({...prev, fromSocialNetworksPercent: false}))}
          />
        </ThWrapper>
      )}
      {curFields.withoutRecommendedSystems && (
        <ThWrapper style={{width: 96}}>
          <TableHeaderCell
            fieldName={'withoutRecommendedSystems'}
            title={REPORTS_LIST_TABLE_CONSTS.withoutRecommendedSystems}
            onRemove={() => setFields(prev => ({...prev, withoutRecommendedSystems: false}))}
          />
        </ThWrapper>
      )}
    </THeadWrapper>
  )
}
