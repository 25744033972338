import PageContainer from '@components/UI/PageContainer/PageContainer'
import ListSameNames from '@components/ListSameNames/ListSameNames'

const SameNames: React.FC = () => {
  return (
    <PageContainer>
      <ListSameNames />
    </PageContainer>
  )
}

export default SameNames
