import React, {FC, useCallback, useEffect, useState} from 'react'
import {Quill} from 'quill'
import {Quill as ReactQuill} from 'react-quill'
import {IFrameElement} from '@graphql/types'
import {getQuillEditor} from '@utils/getQuillEditor'
import {VideoBlot} from '../../Blots/VideoBlot/Video'
import {Video} from '../../Blots'
import VideoEditorModal from '../../../UI/Video/VideoEditorModal'
import {TOOLBAR_VIDEO_CONSTS} from './ToolbarVideo.consts'
import {ToolbarVideoProps} from './ToolbarVideoProps'

const Delta = ReactQuill.import('delta')

const ToolbarVideo: FC<ToolbarVideoProps> = ({editorRef, isOpenVideoModal, closeDialogVideo}) => {
  const [isOpenVideoModalVal, setIsOpenVideoModalVal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState({} as IFrameElement)

  const toggleVideoModal = useCallback(() => {
    closeDialogVideo()
    setIsOpenVideoModalVal(prev => {
      setCurrentVideo({} as IFrameElement)
      return !prev
    })
  }, [closeDialogVideo])

  useEffect(() => {
    setIsOpenVideoModalVal(isOpenVideoModal)
    setCurrentVideo({} as IFrameElement)
  }, [isOpenVideoModal])

  const openModalVideo = e => {
    if (e?.detail?.videoBlot) {
      setCurrentVideo(e?.detail?.videoBlot as IFrameElement)
    }
    setIsOpenVideoModalVal(true)
  }

  useEffect(() => {
    window.addEventListener('openModalVideo', e => openModalVideo(e))

    return () => {
      window.removeEventListener('openModalVideo', e => openModalVideo(e))
    }
  }, [])

  const insertVideoInEditor = e => {
    if (e?.detail?.yandexNewsVideo) {
      setVideo(e?.detail?.yandexNewsVideo as VideoBlot)
    }
    setIsOpenVideoModalVal(false)
  }

  useEffect(() => {
    window.addEventListener('insertVideoInEditor', e => insertVideoInEditor(e))

    return () => {
      window.removeEventListener('insertVideoInEditor', e => insertVideoInEditor(e))
    }
  }, [])

  const setVideo = useCallback(
    (videoBlot: VideoBlot) => {
      const quill = getQuillEditor(editorRef)

      if (quill) {
        let isNew = true
        const contents = quill.getContents()

        const ops = contents.map(operation => {
          if (
            operation.insert[Video.blotName] &&
            operation.insert[Video.blotName].id &&
            operation.insert[Video.blotName].id === Video.id
          ) {
            isNew = false

            return {
              insert: {
                [Video.blotName]: videoBlot
              }
            }
          }

          return operation
        })

        if (isNew) {
          const length = quill.getLength()
          const {index} = quill.getSelection(true)

          quill.insertEmbed(index, Video.blotName, videoBlot, 'user')

          if (length < 1 + index) {
            quill.insertText(index + 1, '\n', 'user')
          }

          return
        }

        quill.setContents(
          new Delta({
            ops
          }),
          'user'
        )
      }
    },
    [editorRef]
  )

  return (
    <>
      <VideoEditorModal
        defaultVideo={currentVideo}
        open={isOpenVideoModalVal}
        setVideo={setVideo}
        onClose={toggleVideoModal}
        okLabel={currentVideo?.uid ? TOOLBAR_VIDEO_CONSTS.changeMsg : TOOLBAR_VIDEO_CONSTS.pasteMsg}
        onNo={toggleVideoModal}
        title={TOOLBAR_VIDEO_CONSTS.insertingVideoMsg}
        text=''
      />
    </>
  )
}
export default ToolbarVideo
