import React, {createContext, FC, useCallback, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import TabsList from '@components/Publication/PublicationList/TabsList/TabsList'
import {items} from '@components/UI/Navbar/menu-items'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import BreakingNewsModal from '@components/UI/BreakingNewsModal/BreakingNewsModal'
import {tabNamesType} from '../../models/models'
import {screenS} from '../../@theme/vars'
import {PUBLICATION_LIST} from './PublicationsList.consts'

const tabNames: tabNamesType = [
  {name: 'PUBLISHED', title: PUBLICATION_LIST.publishedMsg},
  {name: 'REVIEW', title: PUBLICATION_LIST.reviewMsg},
  {name: 'DRAFT', title: PUBLICATION_LIST.draftMsg},
  {name: 'UNPUBLISHED', title: PUBLICATION_LIST.unpublishedMsg}
]
export const PageName = styled.div`
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #222;
  @media (max-width: ${screenS}) {
    font-size: 16px;
  }
`
export const PublicationType = createContext('')

const PublicationsListPage: FC = () => {
  const location = useLocation()

  const [isOpenBreakingNewsModal, setIsOpenDialogImageVal] = useState(false)

  useEffect(() => {
    if (location.search?.includes('?fast-news=true')) {
      setIsOpenDialogImageVal(true)
    }
  }, [location.search])

  const closeBreakingNewsModal = useCallback(() => {
    setIsOpenDialogImageVal(false)
    location.search = ''
    window.history.pushState('', '', '/news')
  }, [location, setIsOpenDialogImageVal])

  const [refetchDateTime, setRefetchDateTime] = useState<Date>()

  return (
    <PublicationType.Provider value={location.pathname.replace('/', '')}>
      <PageContainer>
        <PageName id='page-name'>{items?.find(item => item.route === location.pathname)?.title}</PageName>
        <TabsList tabNames={tabNames} refetchDateTime={refetchDateTime} />
      </PageContainer>
      <BreakingNewsModal
        open={isOpenBreakingNewsModal}
        onClose={closeBreakingNewsModal}
        setRefetchDateTime={setRefetchDateTime}
        modalId={'breaking-news-modal'}
        okLabel={PUBLICATION_LIST.publishMsg}
        title={PUBLICATION_LIST.breakingNewsMsg}
        figureTypeSlug={''}
        publicationTitle={''}
      />
    </PublicationType.Provider>
  )
}

export default PublicationsListPage
