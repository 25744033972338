import React, {FC} from 'react'
import {Redirect, Router, Switch} from 'react-router'
import Navbar from '@components/UI/Navbar/Navbar'
import PrivateRoute from './PrivateRoute/PrivateRoute'

import {routes} from './routes'
import {createBrowserHistory} from 'history'

const history = createBrowserHistory()

const AppRouter: FC = () => {
  return (
    <Router history={history}>
      <Navbar />
      <Switch>
        <Redirect from='/' to='/news' exact />
        {routes.map(route => (
          <PrivateRoute
            key={route.name}
            component={route.component}
            permission={route.permission}
            path={route.path}
            exact={route.exact}
            resource={route.resource}
          />
        ))}
      </Switch>
    </Router>
  )
}

export default AppRouter
