import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'

export const AcceptLinkOnNewsIcon: FC<SvgIconProps> = props => {
  return (
    <svg width='18' height='18' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.5 2.885L6.9425 11.45L3.7625 8.27L4.82 7.2125L6.9425 9.335L14.4425 1.835L15.5 2.885ZM13.8425 6.665C13.94 7.0925 14 7.5425 14 8C14 11.315 11.315 14 8 14C4.685 14 2 11.315 2 8C2 4.685 4.685 2 8 2C9.185 2 10.28 2.345 11.21 2.9375L12.29 1.8575C11.075 1.0025 9.5975 0.5 8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 7.1075 15.335 6.2525 15.05 5.4575L13.8425 6.665Z'
        fill={`${props.color === 'disabled' ? '#C4C4C4' : '#222222'}`}
      />
    </svg>
  )
}
