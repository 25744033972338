import React, {cloneElement, FC, useCallback, useEffect, useState} from 'react'
import {Tab} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useHistory, useParams} from 'react-router'
import TabsWithBorder from '@components/UI/styled/TabsWithBorder'
import TabPanel from '@components/UI/TabPanel/TabPanel'
import useWindowResize from '@hooks/useWindowResize'
import {CommentStatus} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'

const tabNames = [
  {name: CommentStatus.Review, title: 'На рассмотрении', isActive: true},
  {name: CommentStatus.Published, title: 'Опубликованные', isActive: false},
  {name: CommentStatus.Rejected, title: 'Заблокированные', isActive: false}
]

const CommentsModerationTabs: FC = ({children}) => {
  const {status} = useParams<{status: string}>()
  const [value, setValue] = useState<number>(status ? tabNames.findIndex(t => t.name === status) : 0)
  const [width] = useWindowResize()
  const history = useHistory()
  const {commentsStore} = useStore()
  const {totalCount} = commentsStore

  const [totalCountValue, setTotalCountValue] = useState(0)

  useEffect(() => {
    setTotalCountValue(totalCount)
  }, [totalCount])

  const handleChange = useCallback(
    (status: string, newValue: number) => {
      setValue(newValue)
      history.push(`/comments/comments-moderation/${status}`)
    },
    [history]
  )

  useEffect(() => {
    if (!status) {
      handleChange(tabNames[0].name, 0)
    }
  }, [status, handleChange])

  return (
    <>
      {width && (
        <TabsWithBorder
          value={value}
          indicatorColor='primary'
          textColor='inherit'
          scrollButtons={true}
          variant='scrollable'
        >
          {tabNames?.map((tab, ind) => (
            <Tab
              label={`${tab.title}${
                tab.name === CommentStatus.Review && totalCountValue ? `(${totalCountValue})` : ''
              }`}
              key={tab.name}
              id={tab.name}
              onClick={() => handleChange(tab.name, ind)}
            />
          ))}
        </TabsWithBorder>
      )}

      {tabNames?.map((tab, i) => (
        <TabPanel value={value} index={i} key={tab.name}>
          {cloneElement(children, {
            status: tab.name || ''
          })}
        </TabPanel>
      ))}
    </>
  )
}

export default observer(CommentsModerationTabs)
