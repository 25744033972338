import React from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import {Typography} from '@mui/material'
import styled from 'styled-components'
import RegionTabs from '@components/UI/RegionTabs/RegionTabs'
import BannerContent from '@components/MainSelection/Banner/BannerContent/BannerContent'

const Title = styled(Typography)`
  margin-bottom: 10px;
`

const BannerPage: React.FC = () => {
  return (
    <PageContainer>
      <Title variant='h1'>Баннеры</Title>
      <RegionTabs>
        <BannerContent />
      </RegionTabs>
    </PageContainer>
  )
}

export default BannerPage
