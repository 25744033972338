import * as Types from '../../../../@graphql/types'

import {StoryFieldsFragment} from '../../../../pages/StoriesEditor/gql/StoriesEditor.generated'
import {gql} from '@apollo/client'
import {StoryFieldsFragmentDoc} from '../../../../pages/StoriesEditor/gql/StoriesEditor.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type UpdateStoryMutationVariables = Types.Exact<{
  data: Types.UpdateStoryInput
}>

export type UpdateStoryMutation = {__typename?: 'Mutation'} & {
  updateStory?: Types.Maybe<
    {__typename?: 'UpdateStoryPayload'} & {story?: Types.Maybe<{__typename?: 'Story'} & StoryFieldsFragment>}
  >
}

export type RemovePublicationsFromStoryMutationVariables = Types.Exact<{
  data: Types.RemovePublicationsFromStoryInput
}>

export type RemovePublicationsFromStoryMutation = {__typename?: 'Mutation'} & {
  removePublicationsFromStory?: Types.Maybe<
    {__typename?: 'RemovePublicationsFromStoryPayload'} & {
      story?: Types.Maybe<
        {__typename?: 'Story'} & Pick<Types.Story, 'uid'> & {
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<
                  Types.Maybe<
                    {__typename?: 'PublicationsEdge'} & {
                      node?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'uid'>>
                    }
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type AddPublicationsToStoryMutationVariables = Types.Exact<{
  data: Types.AddPublicationsToStoryInput
}>

export type AddPublicationsToStoryMutation = {__typename?: 'Mutation'} & {
  addPublicationsToStory?: Types.Maybe<
    {__typename?: 'AddPublicationsToStoryPayload'} & {
      story?: Types.Maybe<
        {__typename?: 'Story'} & Pick<Types.Story, 'uid'> & {
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<
                  Types.Maybe<
                    {__typename?: 'PublicationsEdge'} & {
                      node?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'uid'>>
                    }
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type PublicationsByStoryUidQueryVariables = Types.Exact<{
  storyUid?: Types.Maybe<Types.Scalars['UUID']>
  limit?: Types.Maybe<Types.Scalars['Int']>
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
}>

export type PublicationsByStoryUidQuery = {__typename?: 'Query'} & {
  publications?: Types.Maybe<
    {__typename?: 'PublicationsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'PublicationsEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'Publication'} & Pick<Types.Publication, 'uid' | 'title'> & {
                  regions?: Types.Maybe<
                    Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>
                  >
                  authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
                }
            >
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    }
  >
}

export const UpdateStoryDocument = gql`
  mutation updateStory($data: UpdateStoryInput!) {
    updateStory(data: $data) {
      story {
        ...StoryFields
      }
    }
  }
  ${StoryFieldsFragmentDoc}
`
export type UpdateStoryMutationFn = Apollo.MutationFunction<UpdateStoryMutation, UpdateStoryMutationVariables>

/**
 * __useUpdateStoryMutation__
 *
 * To run a mutation, you first call `useUpdateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryMutation, { data, loading, error }] = useUpdateStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStoryMutation, UpdateStoryMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateStoryMutation, UpdateStoryMutationVariables>(UpdateStoryDocument, options)
}
export type UpdateStoryMutationHookResult = ReturnType<typeof useUpdateStoryMutation>
export type UpdateStoryMutationResult = Apollo.MutationResult<UpdateStoryMutation>
export type UpdateStoryMutationOptions = Apollo.BaseMutationOptions<UpdateStoryMutation, UpdateStoryMutationVariables>
export const RemovePublicationsFromStoryDocument = gql`
  mutation removePublicationsFromStory($data: RemovePublicationsFromStoryInput!) {
    removePublicationsFromStory(data: $data) {
      story {
        uid
        publications {
          edges {
            node {
              uid
            }
          }
        }
      }
    }
  }
`
export type RemovePublicationsFromStoryMutationFn = Apollo.MutationFunction<
  RemovePublicationsFromStoryMutation,
  RemovePublicationsFromStoryMutationVariables
>

/**
 * __useRemovePublicationsFromStoryMutation__
 *
 * To run a mutation, you first call `useRemovePublicationsFromStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePublicationsFromStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePublicationsFromStoryMutation, { data, loading, error }] = useRemovePublicationsFromStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemovePublicationsFromStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePublicationsFromStoryMutation,
    RemovePublicationsFromStoryMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<RemovePublicationsFromStoryMutation, RemovePublicationsFromStoryMutationVariables>(
    RemovePublicationsFromStoryDocument,
    options
  )
}
export type RemovePublicationsFromStoryMutationHookResult = ReturnType<typeof useRemovePublicationsFromStoryMutation>
export type RemovePublicationsFromStoryMutationResult = Apollo.MutationResult<RemovePublicationsFromStoryMutation>
export type RemovePublicationsFromStoryMutationOptions = Apollo.BaseMutationOptions<
  RemovePublicationsFromStoryMutation,
  RemovePublicationsFromStoryMutationVariables
>
export const AddPublicationsToStoryDocument = gql`
  mutation addPublicationsToStory($data: AddPublicationsToStoryInput!) {
    addPublicationsToStory(data: $data) {
      story {
        uid
        publications {
          edges {
            node {
              uid
            }
          }
        }
      }
    }
  }
`
export type AddPublicationsToStoryMutationFn = Apollo.MutationFunction<
  AddPublicationsToStoryMutation,
  AddPublicationsToStoryMutationVariables
>

/**
 * __useAddPublicationsToStoryMutation__
 *
 * To run a mutation, you first call `useAddPublicationsToStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPublicationsToStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPublicationsToStoryMutation, { data, loading, error }] = useAddPublicationsToStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddPublicationsToStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPublicationsToStoryMutation, AddPublicationsToStoryMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<AddPublicationsToStoryMutation, AddPublicationsToStoryMutationVariables>(
    AddPublicationsToStoryDocument,
    options
  )
}
export type AddPublicationsToStoryMutationHookResult = ReturnType<typeof useAddPublicationsToStoryMutation>
export type AddPublicationsToStoryMutationResult = Apollo.MutationResult<AddPublicationsToStoryMutation>
export type AddPublicationsToStoryMutationOptions = Apollo.BaseMutationOptions<
  AddPublicationsToStoryMutation,
  AddPublicationsToStoryMutationVariables
>
export const PublicationsByStoryUidDocument = gql`
  query publicationsByStoryUid($storyUid: UUID, $limit: Int = 15, $first: Int, $after: String) {
    publications(limit: $limit, filter: {storyUid: $storyUid}, sort: PUBLISHED_AT_DESC, first: $first, after: $after) {
      edges {
        node {
          uid
          title
          regions {
            uid
            title
          }
          authors {
            uid
            firstName
            lastName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

/**
 * __usePublicationsByStoryUidQuery__
 *
 * To run a query within a React component, call `usePublicationsByStoryUidQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationsByStoryUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationsByStoryUidQuery({
 *   variables: {
 *      storyUid: // value for 'storyUid'
 *      limit: // value for 'limit'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePublicationsByStoryUidQuery(
  baseOptions?: Apollo.QueryHookOptions<PublicationsByStoryUidQuery, PublicationsByStoryUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationsByStoryUidQuery, PublicationsByStoryUidQueryVariables>(
    PublicationsByStoryUidDocument,
    options
  )
}
export function usePublicationsByStoryUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationsByStoryUidQuery, PublicationsByStoryUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationsByStoryUidQuery, PublicationsByStoryUidQueryVariables>(
    PublicationsByStoryUidDocument,
    options
  )
}
export type PublicationsByStoryUidQueryHookResult = ReturnType<typeof usePublicationsByStoryUidQuery>
export type PublicationsByStoryUidLazyQueryHookResult = ReturnType<typeof usePublicationsByStoryUidLazyQuery>
export type PublicationsByStoryUidQueryResult = Apollo.QueryResult<
  PublicationsByStoryUidQuery,
  PublicationsByStoryUidQueryVariables
>
