export const VIDEO_LOADER_CONSTS = {
  addVideoMsg: 'Нажмите сюда для выбора видео',
  addYandexVideoMsg: 'Добавить Яндекс видео',
  characterLimitReachedMsg: 'Достигнут лимит по кол-ву символов',
  deleteMsg: 'Удалить',
  fieldIsRequiredMsg: 'Поле обязательно к заполнению',
  fillRequiredFields: 'Заполните обязательные поля',
  maxLength: 200,
  videoTitleMsg: 'Заголовок для видео',
  watchVideoMsg: 'Смотреть видео',
  setAnotherPhotoLabelMsg: 'Установить другое фото'
}
