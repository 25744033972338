import * as Types from '../../../@graphql/types'

import {PublicationEdgeCollectionFragment} from '../../Collections/gql/Collections.generated'
import {gql} from '@apollo/client'
import {PublicationEdgeCollectionFragmentDoc} from '../../Collections/gql/Collections.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type TagsListQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
  search?: Types.Maybe<Types.Scalars['String']>
}>

export type TagsListQuery = {__typename?: 'Query'} & {
  tags: {__typename?: 'TagsConnection'} & {
    edges: Array<
      Types.Maybe<
        {__typename?: 'TagsEdge'} & {
          node?: Types.Maybe<
            {__typename?: 'Tag'} & Pick<Types.Tag, 'id' | 'uid' | 'title' | 'isActive' | 'description'>
          >
        }
      >
    >
    pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
  }
}

export type TagQueryVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type TagQuery = {__typename?: 'Query'} & {
  tag?: Types.Maybe<
    {__typename?: 'Tag'} & Pick<Types.Tag, 'uid' | 'title' | 'description' | 'priority'> & {
        figure?: Types.Maybe<{__typename?: 'Figure'} & FigureFragment>
        relatedTags: Array<{__typename?: 'Tag'} & Pick<Types.Tag, 'title' | 'uid'>>
        publications?: Types.Maybe<
          {__typename?: 'PublicationsConnection'} & {
            edges: Array<Types.Maybe<{__typename?: 'PublicationsEdge'} & PublicationEdgeCollectionFragment>>
            pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
          }
        >
      }
  >
}

export type CreateTagMutationVariables = Types.Exact<{
  title: Types.Scalars['String']
  description: Types.Scalars['String']
}>

export type CreateTagMutation = {__typename?: 'Mutation'} & {
  createTag?: Types.Maybe<
    {__typename?: 'CreateTagPayload'} & {
      tag?: Types.Maybe<
        {__typename?: 'Tag'} & Pick<Types.Tag, 'uid' | 'title' | 'description' | 'priority'> & {
            figure?: Types.Maybe<{__typename?: 'Figure'} & FigureFragment>
            relatedTags: Array<{__typename?: 'Tag'} & Pick<Types.Tag, 'title' | 'uid'>>
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<Types.Maybe<{__typename?: 'PublicationsEdge'} & PublicationEdgeCollectionFragment>>
                pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
              }
            >
          }
      >
    }
  >
}

export type DeactivateTagMutationVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type DeactivateTagMutation = {__typename?: 'Mutation'} & {
  deactivateTag?: Types.Maybe<
    {__typename?: 'DeactivateTagPayload'} & Pick<Types.DeactivateTagPayload, 'status'> & {
        tag?: Types.Maybe<{__typename?: 'Tag'} & Pick<Types.Tag, 'uid'>>
      }
  >
}

export type ActivateTagMutationVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type ActivateTagMutation = {__typename?: 'Mutation'} & {
  activateTag?: Types.Maybe<
    {__typename?: 'ActivateTagPayload'} & Pick<Types.ActivateTagPayload, 'status'> & {
        tag?: Types.Maybe<{__typename?: 'Tag'} & Pick<Types.Tag, 'uid'>>
      }
  >
}

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<Types.FigureType, 'id' | 'uid' | 'slug'>
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
        crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
        primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        processedImages: Array<{__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>>
      }
  }

export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const TagsListDocument = gql`
  query tagsList($first: Int, $after: String, $search: String) {
    tags(limit: 10, filter: {search: $search, isActive: true}, first: $first, after: $after) {
      edges {
        node {
          id
          uid
          title
          isActive
          description
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

/**
 * __useTagsListQuery__
 *
 * To run a query within a React component, call `useTagsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsListQuery(baseOptions?: Apollo.QueryHookOptions<TagsListQuery, TagsListQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TagsListQuery, TagsListQueryVariables>(TagsListDocument, options)
}
export function useTagsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsListQuery, TagsListQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TagsListQuery, TagsListQueryVariables>(TagsListDocument, options)
}
export type TagsListQueryHookResult = ReturnType<typeof useTagsListQuery>
export type TagsListLazyQueryHookResult = ReturnType<typeof useTagsListLazyQuery>
export type TagsListQueryResult = Apollo.QueryResult<TagsListQuery, TagsListQueryVariables>
export const TagDocument = gql`
  query tag($uid: UUID!) {
    tag(uid: $uid) {
      uid
      title
      description
      figure {
        ...Figure
      }
      priority
      relatedTags {
        title
        uid
      }
      publications {
        edges {
          ...PublicationEdgeCollection
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${FigureFragmentDoc}
  ${PublicationEdgeCollectionFragmentDoc}
`

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options)
}
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options)
}
export type TagQueryHookResult = ReturnType<typeof useTagQuery>
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>
export const CreateTagDocument = gql`
  mutation createTag($title: String!, $description: String!) {
    createTag(data: {title: $title, description: $description}) {
      tag {
        uid
        title
        description
        figure {
          ...Figure
        }
        priority
        relatedTags {
          title
          uid
        }
        publications {
          edges {
            ...PublicationEdgeCollection
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${FigureFragmentDoc}
  ${PublicationEdgeCollectionFragmentDoc}
`
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options)
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>
export const DeactivateTagDocument = gql`
  mutation deactivateTag($uid: UUID!) {
    deactivateTag(data: {tagUid: $uid}) {
      tag {
        uid
      }
      status
    }
  }
`
export type DeactivateTagMutationFn = Apollo.MutationFunction<DeactivateTagMutation, DeactivateTagMutationVariables>

/**
 * __useDeactivateTagMutation__
 *
 * To run a mutation, you first call `useDeactivateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateTagMutation, { data, loading, error }] = useDeactivateTagMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeactivateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateTagMutation, DeactivateTagMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<DeactivateTagMutation, DeactivateTagMutationVariables>(DeactivateTagDocument, options)
}
export type DeactivateTagMutationHookResult = ReturnType<typeof useDeactivateTagMutation>
export type DeactivateTagMutationResult = Apollo.MutationResult<DeactivateTagMutation>
export type DeactivateTagMutationOptions = Apollo.BaseMutationOptions<
  DeactivateTagMutation,
  DeactivateTagMutationVariables
>
export const ActivateTagDocument = gql`
  mutation activateTag($uid: UUID!) {
    activateTag(data: {tagUid: $uid}) {
      tag {
        uid
      }
      status
    }
  }
`
export type ActivateTagMutationFn = Apollo.MutationFunction<ActivateTagMutation, ActivateTagMutationVariables>

/**
 * __useActivateTagMutation__
 *
 * To run a mutation, you first call `useActivateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTagMutation, { data, loading, error }] = useActivateTagMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useActivateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateTagMutation, ActivateTagMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ActivateTagMutation, ActivateTagMutationVariables>(ActivateTagDocument, options)
}
export type ActivateTagMutationHookResult = ReturnType<typeof useActivateTagMutation>
export type ActivateTagMutationResult = Apollo.MutationResult<ActivateTagMutation>
export type ActivateTagMutationOptions = Apollo.BaseMutationOptions<ActivateTagMutation, ActivateTagMutationVariables>
