import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import {Button as ButtonUI} from '@mui/material'
import {grayColor} from '@theme/vars'

export const StyledUl = styled.ul`
  list-style: none; /* Убираем стандартные маркеры списка */
  padding: 0;
  li {
    display: flex;
    align-items: center; /* Выравниваем по вертикали */
    margin-bottom: 8px;

    &::before {
      content: '•'; /* Добавляем маркер вручную */
      color: green; /* Цвет маркера */
      font-size: 20px;
      display: inline-block;
      margin-right: 10px; /* Отступ от маркера до текста */
    }

    @media (max-width: 480px) {
      align-items: flex-start;
    }
  }
`

export const Title = styled.div`
  margin-bottom: 5px;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 5px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`

export const Link = styled.a`
  color: #048e11;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const Button = styled(ButtonUI)`
  color: ${grayColor};
  padding: 0;
  min-height: 21px;
`

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${grayColor};
  font-size: 16px;
  margin-right: 5px; /* Добавляем небольшой отступ для иконки */
`
