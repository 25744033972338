import {ValidationData, ValidationStatus} from '@utils/models/errorStatus'

/**
 * Функция проверки заголовка на количество слов
 * @param text Текст заголовка
 * @param maxWordsCount Максимальное количество слов в заголовке
 * @returns
 */
export const validateTitleByWordsCount = (text: string, maxWordsCount: number): ValidationData => {
  const validationData: ValidationData = {
    status: ValidationStatus.Default,
    message: ''
  }

  const words = text.match(/[А-ЯA-Z0-9]+/gi) ?? []
  const wordsLimitIsExceeded = maxWordsCount === undefined ? false : words.length > maxWordsCount

  if (maxWordsCount > 0 && wordsLimitIsExceeded) {
    validationData.message = `В заголовке не может быть более ${maxWordsCount} слов`
    validationData.status = ValidationStatus.Error
  }

  return validationData
}
