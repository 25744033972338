import styled from 'styled-components'
import {blueColor, grayBorder, hoverColor, lightRedColor2, mainTextColor} from '@theme/vars'

export const TableRowWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${grayBorder};
  &:hover {
    background-color: ${hoverColor};
    cursor: pointer;
  }
`

export const TableCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 38px;
  padding: 10px 6px 9px 6px;
  border-left: 1px solid ${grayBorder};

  &.comment-count {
    align-items: center;
  }
  & span {
    color: ${mainTextColor};

    &.new-comments {
      color: ${blueColor};
    }

    &.is-automatic-comments-disabled {
      background-color: ${lightRedColor2};
      width: fit-content;
    }
  }
`
