import React, {FC, useState, useEffect} from 'react'
import {InputLabel, OutlinedInput, FormHelperText} from '@mui/material'
import {DetailsRowSelects, BottomText} from './TextEditorInsertModal.styles'
import SimpleModal, {SimpleModalProps} from '../SimpleModal/SimpleModal'
import {StyledFormControl} from '../styled'
import {textEditorInsertModalConsts} from './TextEditorInsertModal.consts'

export type LinkElement = {
  linkText: string
  description: string
}

export type TextEditorInsertModallProps = {
  toggleModal: () => void
  bottomText?: string
  link: LinkElement
  descriptionLabel: string
  okAction: (description: string, linkText: string) => void
} & SimpleModalProps

const TextEditorInsertModal: FC<TextEditorInsertModallProps> = ({
  toggleModal,
  bottomText,
  descriptionLabel,
  open,
  onClose,
  title,
  text,
  okLabel,
  okAction,
  link
}) => {
  const [description, setDescription] = useState(link?.description || '')
  const [linkText, setLinkText] = useState(link?.linkText || '')
  const error = ''
  useEffect(() => {
    setDescription(link.description)
    setLinkText(link.linkText)
  }, [link])
  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={onClose}
      title={title}
      text={text}
      onOk={() => {
        okAction(description, linkText || Date.now().toString())
        setDescription('')
        setLinkText('')
        toggleModal()
      }}
      onNo={() => {
        toggleModal()
      }}
      okLabel={okLabel}
      okButtonDisabled={!description || !linkText}
      maxWidth='sm'
    >
      <>
        <DetailsRowSelects>
          <StyledFormControl width='100%'>
            <InputLabel>{descriptionLabel}</InputLabel>
            <OutlinedInput
              label={descriptionLabel}
              onChange={e => setDescription(e.target.value)}
              value={description}
              multiline
              required
            />
            <FormHelperText>{error}</FormHelperText>
          </StyledFormControl>
          {descriptionLabel === textEditorInsertModalConsts.linkMsg && (
            <StyledFormControl width='100%' margintop='20px'>
              <InputLabel>{textEditorInsertModalConsts.linkTextMsg}</InputLabel>
              <OutlinedInput
                label={textEditorInsertModalConsts.linkTextMsg}
                onChange={e => setLinkText(e.target.value)}
                value={linkText}
                multiline
                required
              />
              <FormHelperText>{error}</FormHelperText>
            </StyledFormControl>
          )}
        </DetailsRowSelects>
        {bottomText && <BottomText>{bottomText}</BottomText>}
      </>
    </SimpleModal>
  )
}

export default TextEditorInsertModal
