import styled from 'styled-components'
import {Box, Button, Tooltip} from '@mui/material'
import {StyledFormControl} from '@components/UI/styled'
import {blueColor, screenS} from '../../../../@theme/vars'

export const SimpleFigureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #c4c4c4;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }
`

export const LabelWrapper = styled.label`
  width: 100%;
`

export const AnnounceImageTitle = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #4e4e4e;
  width: 100%;
  margin-top: 12px;
`

export const DetailsRowSelects = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;

  @media screen and (max-width: ${screenS}) {
    flex-direction: column;
  }
`
export const ImageTooltipWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: ${blueColor};
  width: 145px;
  height: 42px;
`

export const TooltipWrapper = styled(Tooltip)`
  position: absolute;
  display: flex;
  margin-left: 170px;
  margin-top: 10px;
  background-color: #fff;
`

export const StyledFormWrapper = styled(StyledFormControl)`
  /* width: 428px; */
`

export const ImageUrlBoxWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const UploadFromFileButtonWrapper = styled(Button)`
  padding: 11px 26px 11px 26px;
  font-size: 14px;
  font-weight: 500;
  height: 42px;
`
