import {IconButton} from '@mui/material'
import styled from 'styled-components'
import {mainTextColor} from '../../../../../@theme/vars'

export const YandexVideoItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 12px;
`
export const YandexVideoItemTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${mainTextColor};
`

export const StyledDeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`
