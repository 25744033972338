import {ValidationData, ValidationStatus} from '@utils/models/errorStatus'

/**
 * Функция проверки строки по длине
 * @param text Текст
 * @param maxErrorLength Максимальная длина текста при которой будет ошибка
 * @param maxWarningLength Максимальная длина текста при которой будет предупреждение
 * @param errorMessage Текст ошибки, по-умолчанию "Достигнут лимит по количеству символов"
 * @param warningMessage Текст предупреждения, по-умолчанию "Слишком длинный заголовок"
 * @returns
 */
export const validateStringByMaxLength = (
  text: string,
  maxErrorLength: number,
  maxWarningLength = 0,
  errorMessage = '',
  warningMessage = ''
): ValidationData => {
  const validationData: ValidationData = {
    status: ValidationStatus.Default,
    message: ''
  }

  if (text?.length > maxErrorLength) {
    validationData.message = errorMessage || 'Достигнут лимит по количеству символов'
    validationData.status = ValidationStatus.Error
    return validationData
  }
  if (maxWarningLength > 0 && text.length > maxWarningLength) {
    validationData.message = warningMessage || 'Слишком длинный заголовок'
    validationData.status = ValidationStatus.Warning
    return validationData
  }

  return validationData
}
