import {FormHelperText, InputLabel, OutlinedInput, Typography} from '@mui/material'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {StyledFormControl} from '@components/UI/styled'
import {redColor} from '@theme/vars'
import {IMAGE_EDITOR_MODAL_CONSTS} from '@components/UI/ImageEditor/ImageEditorModal/ImageEditorModal.consts'
import {useStore} from '@stores/rootStoreContext'
import {stringTypographRunner} from '@components/TextEditor/Editor/utils/stringTypographRunner'
import {GalleryBlockItemAuthorProps} from './GalleryBlockItemAuthorProps'

const GalleryBlockItemAuthor: FC<GalleryBlockItemAuthorProps> = ({ind, authorVal, setError}) => {
  const {publicationStore} = useStore()
  const {galleryModalStore} = publicationStore
  const {galleryDetail, setGalleryImageById} = galleryModalStore
  const [author, setAuthor] = useState(authorVal || '')
  const [authorDirty, setAuthorDirty] = useState(false)
  const [authorErrorText, setAuthorErrorText] = useState('')

  useEffect(() => {
    setAuthor(authorVal)
  }, [authorVal])

  useEffect(() => {
    if (!author) {
      setError(IMAGE_EDITOR_MODAL_CONSTS.isEmpty)
      if (authorDirty) {
        setAuthorErrorText(IMAGE_EDITOR_MODAL_CONSTS.fieldIsRequiredMsg)
      }
      return
    }
    if (author?.length > IMAGE_EDITOR_MODAL_CONSTS.maxAuthorLength) {
      setAuthorErrorText(IMAGE_EDITOR_MODAL_CONSTS.characterLimitReachedMsg)
      setError(IMAGE_EDITOR_MODAL_CONSTS.characterLimitReachedMsg)
      return
    }
    setAuthorErrorText('')
    setError('')
  }, [author, authorDirty, setAuthorErrorText, setError])

  const setAuthorValue = useCallback(
    (value: string) => {
      const fieldValue = stringTypographRunner(value)
      setAuthor(fieldValue)
      setAuthorDirty(true)
      const curImage = galleryDetail.galleryImages[ind]

      if (curImage) {
        curImage.author = fieldValue
        setGalleryImageById(curImage)
      }
    },
    [ind, galleryDetail, setGalleryImageById]
  )

  return (
    <StyledFormControl width='100%' required error={!author && authorDirty} margintop='16px'>
      <div style={{flexDirection: 'row'}}>
        <InputLabel
          style={{
            width: '70%'
          }}
        >
          <Typography variant='text12R'>{IMAGE_EDITOR_MODAL_CONSTS.authorLabelMsg}</Typography>
        </InputLabel>
        <OutlinedInput
          id={`gallery-block-item-author_${ind}`}
          label={IMAGE_EDITOR_MODAL_CONSTS.authorLabelMsg}
          error={!!authorErrorText}
          multiline
          style={{
            float: 'left',
            width: '100%',
            minHeight: '42px'
          }}
          onChange={e => {
            setAuthorValue(e.target.value)
            setAuthorDirty(true)
          }}
          value={author}
          required
        />
      </div>
      {!!authorErrorText && (
        <FormHelperText
          style={{
            color: redColor
          }}
        >
          {authorErrorText}
        </FormHelperText>
      )}
    </StyledFormControl>
  )
}

export default observer(GalleryBlockItemAuthor)
