import React, {FC} from 'react'
import {useKeycloak} from '@react-keycloak/web'
import {Route} from 'react-router-dom'
import {RouteProps} from 'react-router'
import AccessDenied from '@pages/AccessDenied/AccessDenied'

type PrivateRoute = {
  component: FC
  permission: string
  resource?: string
} & RouteProps

const PrivateRoute: FC<PrivateRoute> = ({component: Component, permission, resource, ...rest}) => {
  const {keycloak} = useKeycloak()
  const isAllowed = (permission: string): boolean => {
    if (!permission) {
      return true
    }
    if (keycloak && permission) {
      return keycloak.hasRealmRole(permission) || keycloak.hasResourceRole(permission, resource)
    }

    return false
  }

  return (
    <Route
      {...rest}
      render={props => {
        return isAllowed(permission) ? <Component {...props} /> : <AccessDenied />
      }}
    />
  )
}

export default PrivateRoute
