import {SvgIconProps} from '@mui/material'
import React, {FC} from 'react'
import {grey20, mainTextColor} from '@theme/vars'

export const DislikeIcon: FC<SvgIconProps> = props => {
  return (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.9653 13.5507L9.4973 10.8533C9.6093 10.3027 9.45997 9.73332 9.1053 9.30399C8.75064 8.87466 8.22797 8.62266 7.66797 8.62266H2.53464V7.61466L4.9333 2.08932H12.484C12.652 2.08932 12.8013 2.23866 12.8013 2.40666V9.72399L8.9653 13.5507ZM8.13464 17.0227L14.1173 11.04C14.472 10.6853 14.668 10.2093 14.668 9.71466V2.40666C14.668 1.20266 13.688 0.222656 12.484 0.222656H4.92397C4.2613 0.222656 3.65464 0.567989 3.31864 1.12799L0.826635 6.86799C0.723969 7.10132 0.667969 7.35332 0.667969 7.61466V8.62266C0.667969 9.64932 1.50797 10.4893 2.53464 10.4893H7.66797L6.8093 14.8293C6.76264 15.0347 6.79064 15.2587 6.88397 15.4453C7.09864 15.8653 7.3693 16.248 7.7053 16.584L8.13464 17.0227ZM17.468 10.4893H19.3346V0.222656H17.468C16.9546 0.222656 16.5346 0.642656 16.5346 1.15599V9.55599C16.5346 10.0693 16.9546 10.4893 17.468 10.4893Z'
        fill={props.color === 'primary' ? mainTextColor : grey20}
      />
    </svg>
  )
}
