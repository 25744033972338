import {FC, useCallback} from 'react'
import {toast} from 'react-toastify'
import {useStore} from '@stores/rootStoreContext'
import {getQuillEditor} from '@utils/getQuillEditor'
import {fetchGenerateText} from '@fetch'
import {addErrorsMessage} from '@utils/utilsNn/addErrorsMessage'
import {Button, MenuItem} from '../ToolbarNn.styles'
import {TOOLBAR_BUTTONS_TITLES, TOOLBAR_DIALOG_CONSTS} from '../ToolbarNn.consts'

type Props = {
  editorRef: React.MutableRefObject<any>
  handleClose: () => void
  authorId: string
}
/**
 * Кнопка для генерации текста на основе введенного в редакторе текста.
 * При нажатии отправляется запрос на сервер для генерации текста и отображается результат в диалоге.
 *
 *
 * @param {React.MutableRefObject<any>} editorRef - Ссылка на редактор Quill, содержащий текст для генерации.
 * @param {Function} handleClose - Функция для закрытия диалогового окна.
 */

const GenerateTextButton: FC<Props> = ({editorRef, handleClose, authorId}) => {
  const {publicationStore, dialogStore} = useStore()

  const handleGenerateText = useCallback(async () => {
    handleClose()

    const abortCtrl = new AbortController()
    dialogStore.setAbortController(abortCtrl)

    const quill = getQuillEditor(editorRef)

    const {publication} = publicationStore
    const publicationId = publication?.deprecatedId
    const publicationUid = publication?.id

    if (quill) {
      let requestText = ''
      const delta = quill.getContents()

      delta.forEach(item => {
        if (typeof item.insert === 'string') requestText += item.insert + ' '
      })

      if (requestText.length <= 2) {
        toast.info(TOOLBAR_BUTTONS_TITLES.notFoundText, {
          containerId: 'success',
          autoClose: 10000,
          hideProgressBar: true
        })
        return dialogStore.setIsLoadingNer(false)
      }

      dialogStore.setIsLoadingNer(true)

      try {
        dialogStore.openDialog()
        const data = {params: {'1': requestText}, publicationId, publicationUid, authorId}
        dialogStore.addMessage({
          isUser: true,
          isError: false,
          message: TOOLBAR_DIALOG_CONSTS.CONTENT.textForGenerate + '\n' + requestText
        })
        const generateTextJson = await fetchGenerateText(data, abortCtrl.signal)
        if (!generateTextJson) {
          console.error(TOOLBAR_BUTTONS_TITLES.fetchError)

          return
        }
        const regExp = /\n\s*\n+/g
        const text = JSON.parse(generateTextJson).text.replace(regExp, '\n')
        dialogStore.addMessage({isUser: false, isError: false, message: text})
      } catch (error) {
        addErrorsMessage(dialogStore, error)
      } finally {
        dialogStore.setIsLoadingNer(false)
      }
    }
  }, [editorRef, publicationStore, dialogStore, handleClose, authorId])

  return (
    <MenuItem>
      <Button onClick={handleGenerateText}>{TOOLBAR_BUTTONS_TITLES.generateTextTopic}</Button>
    </MenuItem>
  )
}

export default GenerateTextButton
