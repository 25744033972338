import React, {FC, useCallback, useEffect, useState} from 'react'
import {debounce} from 'lodash'
import {useKeycloak} from '@react-keycloak/web'
import {IconButton, Tooltip} from '@mui/material'
import {useUpdateFigureMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import ImageCropper from '@components/UI/ImageEditor/ImageCropper/ImageCropper'
import {CropInput, Figure} from '@graphql/types'
import DeleteIcon from '@components/UI/Icons/DeleteIcon'
import {useStore} from '@stores/rootStoreContext'
import {CropperType} from '../../../../types/CropperType'
import {IMAGE_LOADER_CONSTS} from './ImageLoader.consts'
import {ImageLoaderTitle, MainContainer, StyledDeleteBtn} from './ImageLoader.styles'
import {ImageLoaderProps} from './ImageLoaderProps'

const ImageLoader: FC<ImageLoaderProps> = ({defaultFigure, setFigure, setFigureIsLoading}) => {
  const ratio = 16 / 9
  const {keycloak} = useKeycloak()
  const {cropperStore} = useStore()
  const {cropper, setCropper} = cropperStore
  const [imageUrl, setImageUrl] = useState(defaultFigure?.src)
  const [updateFigure, {loading: updateFigureLoading}] = useUpdateFigureMutation()
  const [currFigureIsLoading, setCurrFigureIsLoading] = useState(false)
  const [curCropper, setCurCropper] = useState({
    width: cropper.width,
    height: cropper.height,
    top: cropper.top,
    left: cropper.left
  })

  const createNewFigureVersion = useCallback(
    async (cropper: CropInput) => {
      setCurrFigureIsLoading(true)
      const newFigure = await updateFigure({
        variables: {
          data: {
            crop: {
              height: cropper.height || 0,
              left: cropper.left || 0,
              top: cropper.top || 0,
              width: cropper.width || 0
            },
            alt: defaultFigure?.alt || '',
            author: defaultFigure?.author || (keycloak?.tokenParsed && keycloak?.tokenParsed['name']),
            description: defaultFigure?.description || '',
            figureUid: defaultFigure?.uid,
            hasDiagonalWatermark: false,
            hasWatermark: defaultFigure?.hasWatermark
          }
        }
      })
      if (newFigure?.data?.updateFigure?.figure) {
        setFigure(newFigure?.data?.updateFigure?.figure as Figure)
        setCurrFigureIsLoading(false)
      }
    },
    [defaultFigure, setFigure, updateFigure]
  )
  useEffect(() => {
    setFigureIsLoading(currFigureIsLoading)
  }, [currFigureIsLoading, setFigureIsLoading])

  const changeCropper = debounce(value => {
    const newCropper = {
      width: value.width || 0,
      height: value.height || 0,
      top: value.top || 0,
      left: value.left || 0
    } as CropInput
    createNewFigureVersion(newCropper)
  }, 300)

  useEffect(() => {
    if (
      cropper.width !== curCropper.width ||
      cropper.height !== curCropper.height ||
      cropper.top !== curCropper.top ||
      cropper.left !== curCropper.left
    ) {
      setCurCropper(cropper)
      changeCropper(cropper)
    }
  }, [cropper, curCropper, changeCropper])

  const handleDeleteImg = useCallback(() => {
    setFigure({} as Figure)
    setImageUrl('')
    setCropper({} as CropInput)
  }, [setFigure])

  return (
    <MainContainer>
      <div>
        <ImageLoaderTitle>{IMAGE_LOADER_CONSTS.addCoverImageMsg}</ImageLoaderTitle>
        <ImageCropper
          figure={defaultFigure as Figure}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          setFigure={e => {
            setFigure(e)
          }}
          ratio={ratio}
          figureTypeSlug={'publication'}
          cropperType={CropperType.isYandexVideo}
          isCustomRatio={false}
          forImageLoader={true}
          hasWatermark={false}
          hasDiagonalWatermark={false}
          figureIsLoading={updateFigureLoading}
        />
        {defaultFigure?.src && (
          <StyledDeleteBtn>
            <Tooltip title={IMAGE_LOADER_CONSTS.setAnotherPhotoLabelMsg}>
              <IconButton
                edge='end'
                size='small'
                id='yandex-video-modal-delete-image'
                onClick={() => handleDeleteImg()}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </StyledDeleteBtn>
        )}
      </div>
    </MainContainer>
  )
}

export default ImageLoader
