import styled from 'styled-components'

export const EditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 12px;
`

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
`
