import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type AdPublicationsQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>
  adParametersCreatorId?: Types.Maybe<Types.Scalars['UUID']>
  adParametersCreatedAtDate?: Types.Maybe<Types.Scalars['Date']>
}>

export type AdPublicationsQuery = {__typename?: 'Query'} & {
  adPublications?: Types.Maybe<
    Array<
      {__typename?: 'Publication'} & Pick<Types.Publication, 'id' | 'uid' | 'title'> & {
          adParameters?: Types.Maybe<
            {__typename?: 'AdPublicationParameters'} & Pick<
              Types.AdPublicationParameters,
              'weight' | 'showsAt' | 'hidesAt' | 'isActive' | 'createdAt'
            > & {
                creator: {__typename?: 'User'} & Pick<Types.User, 'id' | 'uid' | 'username' | 'firstname' | 'lastname'>
              }
          >
        }
    >
  >
}

export type SetAdPublicationParametersMutationVariables = Types.Exact<{
  publicationId: Types.Scalars['UUID']
  weight: Types.Scalars['Int']
  showsAt: Types.Scalars['Time']
  hidesAt: Types.Scalars['Time']
}>

export type SetAdPublicationParametersMutation = {__typename?: 'Mutation'} & {
  setAdPublicationParameters?: Types.Maybe<
    {__typename?: 'SetAdPublicationParametersPayload'} & Pick<Types.SetAdPublicationParametersPayload, 'status'>
  >
}

export type ResetAdPublicationParametersMutationVariables = Types.Exact<{
  publicationId: Types.Scalars['UUID']
}>

export type ResetAdPublicationParametersMutation = {__typename?: 'Mutation'} & {
  resetAdPublicationParameters?: Types.Maybe<
    {__typename?: 'ResetAdPublicationParametersPayload'} & Pick<Types.ResetAdPublicationParametersPayload, 'status'>
  >
}

export type ActivateAdPublicationMutationVariables = Types.Exact<{
  publicationId: Types.Scalars['UUID']
}>

export type ActivateAdPublicationMutation = {__typename?: 'Mutation'} & {
  activateAdPublication?: Types.Maybe<
    {__typename?: 'ActivateAdPublicationPayload'} & Pick<Types.ActivateAdPublicationPayload, 'status'>
  >
}

export type DeactivateAdPublicationMutationVariables = Types.Exact<{
  publicationId: Types.Scalars['UUID']
}>

export type DeactivateAdPublicationMutation = {__typename?: 'Mutation'} & {
  deactivateAdPublication?: Types.Maybe<
    {__typename?: 'DeactivateAdPublicationPayload'} & Pick<Types.DeactivateAdPublicationPayload, 'status'>
  >
}

export const AdPublicationsDocument = gql`
  query adPublications($search: String, $adParametersCreatorId: UUID, $adParametersCreatedAtDate: Date) {
    adPublications(
      filter: {
        search: $search
        adParametersCreatorId: $adParametersCreatorId
        adParametersCreatedAtDate: $adParametersCreatedAtDate
      }
    ) {
      id
      uid
      title
      adParameters {
        weight
        showsAt
        hidesAt
        isActive
        creator {
          id
          uid
          username
          firstname
          lastname
        }
        createdAt
      }
    }
  }
`

/**
 * __useAdPublicationsQuery__
 *
 * To run a query within a React component, call `useAdPublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdPublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdPublicationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      adParametersCreatorId: // value for 'adParametersCreatorId'
 *      adParametersCreatedAtDate: // value for 'adParametersCreatedAtDate'
 *   },
 * });
 */
export function useAdPublicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdPublicationsQuery, AdPublicationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<AdPublicationsQuery, AdPublicationsQueryVariables>(AdPublicationsDocument, options)
}
export function useAdPublicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdPublicationsQuery, AdPublicationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<AdPublicationsQuery, AdPublicationsQueryVariables>(AdPublicationsDocument, options)
}
export type AdPublicationsQueryHookResult = ReturnType<typeof useAdPublicationsQuery>
export type AdPublicationsLazyQueryHookResult = ReturnType<typeof useAdPublicationsLazyQuery>
export type AdPublicationsQueryResult = Apollo.QueryResult<AdPublicationsQuery, AdPublicationsQueryVariables>
export const SetAdPublicationParametersDocument = gql`
  mutation setAdPublicationParameters($publicationId: UUID!, $weight: Int!, $showsAt: Time!, $hidesAt: Time!) {
    setAdPublicationParameters(
      data: {publicationId: $publicationId, weight: $weight, showsAt: $showsAt, hidesAt: $hidesAt}
    ) {
      status
    }
  }
`
export type SetAdPublicationParametersMutationFn = Apollo.MutationFunction<
  SetAdPublicationParametersMutation,
  SetAdPublicationParametersMutationVariables
>

/**
 * __useSetAdPublicationParametersMutation__
 *
 * To run a mutation, you first call `useSetAdPublicationParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAdPublicationParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAdPublicationParametersMutation, { data, loading, error }] = useSetAdPublicationParametersMutation({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *      weight: // value for 'weight'
 *      showsAt: // value for 'showsAt'
 *      hidesAt: // value for 'hidesAt'
 *   },
 * });
 */
export function useSetAdPublicationParametersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAdPublicationParametersMutation,
    SetAdPublicationParametersMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SetAdPublicationParametersMutation, SetAdPublicationParametersMutationVariables>(
    SetAdPublicationParametersDocument,
    options
  )
}
export type SetAdPublicationParametersMutationHookResult = ReturnType<typeof useSetAdPublicationParametersMutation>
export type SetAdPublicationParametersMutationResult = Apollo.MutationResult<SetAdPublicationParametersMutation>
export type SetAdPublicationParametersMutationOptions = Apollo.BaseMutationOptions<
  SetAdPublicationParametersMutation,
  SetAdPublicationParametersMutationVariables
>
export const ResetAdPublicationParametersDocument = gql`
  mutation resetAdPublicationParameters($publicationId: UUID!) {
    resetAdPublicationParameters(data: {publicationId: $publicationId}) {
      status
    }
  }
`
export type ResetAdPublicationParametersMutationFn = Apollo.MutationFunction<
  ResetAdPublicationParametersMutation,
  ResetAdPublicationParametersMutationVariables
>

/**
 * __useResetAdPublicationParametersMutation__
 *
 * To run a mutation, you first call `useResetAdPublicationParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAdPublicationParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAdPublicationParametersMutation, { data, loading, error }] = useResetAdPublicationParametersMutation({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useResetAdPublicationParametersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetAdPublicationParametersMutation,
    ResetAdPublicationParametersMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ResetAdPublicationParametersMutation, ResetAdPublicationParametersMutationVariables>(
    ResetAdPublicationParametersDocument,
    options
  )
}
export type ResetAdPublicationParametersMutationHookResult = ReturnType<typeof useResetAdPublicationParametersMutation>
export type ResetAdPublicationParametersMutationResult = Apollo.MutationResult<ResetAdPublicationParametersMutation>
export type ResetAdPublicationParametersMutationOptions = Apollo.BaseMutationOptions<
  ResetAdPublicationParametersMutation,
  ResetAdPublicationParametersMutationVariables
>
export const ActivateAdPublicationDocument = gql`
  mutation activateAdPublication($publicationId: UUID!) {
    activateAdPublication(data: {publicationId: $publicationId}) {
      status
    }
  }
`
export type ActivateAdPublicationMutationFn = Apollo.MutationFunction<
  ActivateAdPublicationMutation,
  ActivateAdPublicationMutationVariables
>

/**
 * __useActivateAdPublicationMutation__
 *
 * To run a mutation, you first call `useActivateAdPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAdPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAdPublicationMutation, { data, loading, error }] = useActivateAdPublicationMutation({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useActivateAdPublicationMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateAdPublicationMutation, ActivateAdPublicationMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ActivateAdPublicationMutation, ActivateAdPublicationMutationVariables>(
    ActivateAdPublicationDocument,
    options
  )
}
export type ActivateAdPublicationMutationHookResult = ReturnType<typeof useActivateAdPublicationMutation>
export type ActivateAdPublicationMutationResult = Apollo.MutationResult<ActivateAdPublicationMutation>
export type ActivateAdPublicationMutationOptions = Apollo.BaseMutationOptions<
  ActivateAdPublicationMutation,
  ActivateAdPublicationMutationVariables
>
export const DeactivateAdPublicationDocument = gql`
  mutation deactivateAdPublication($publicationId: UUID!) {
    deactivateAdPublication(data: {publicationId: $publicationId}) {
      status
    }
  }
`
export type DeactivateAdPublicationMutationFn = Apollo.MutationFunction<
  DeactivateAdPublicationMutation,
  DeactivateAdPublicationMutationVariables
>

/**
 * __useDeactivateAdPublicationMutation__
 *
 * To run a mutation, you first call `useDeactivateAdPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateAdPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateAdPublicationMutation, { data, loading, error }] = useDeactivateAdPublicationMutation({
 *   variables: {
 *      publicationId: // value for 'publicationId'
 *   },
 * });
 */
export function useDeactivateAdPublicationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateAdPublicationMutation, DeactivateAdPublicationMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<DeactivateAdPublicationMutation, DeactivateAdPublicationMutationVariables>(
    DeactivateAdPublicationDocument,
    options
  )
}
export type DeactivateAdPublicationMutationHookResult = ReturnType<typeof useDeactivateAdPublicationMutation>
export type DeactivateAdPublicationMutationResult = Apollo.MutationResult<DeactivateAdPublicationMutation>
export type DeactivateAdPublicationMutationOptions = Apollo.BaseMutationOptions<
  DeactivateAdPublicationMutation,
  DeactivateAdPublicationMutationVariables
>
