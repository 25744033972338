import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {debounce} from 'lodash'
import {observer} from 'mobx-react-lite'
import {UseFormReturn} from 'react-hook-form'
import {Switch} from '@mui/material'
import {Quill} from 'react-quill'
import {useKeycloak} from '@react-keycloak/web'
import {fetchAutoKeywords} from '@fetch'
import {useStore} from '@stores/rootStoreContext'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {useUpdatePublicationKeywordsMutation} from '@components/TextEditor/gql/TextEditor.generated'
import {PublicationStatus} from '@graphql/types'
import PopoverPopupState from '@components/UI/PopoverInfoButton/PopoverInfoButton'
import {HELP_INFO_NN_KEYWORDS} from '@components/UI/PopoverInfoButton/TextInfo'
import {FocusEvent} from '@utils/focusEvent'
import KeyWords from '../../KeyWords/KeyWords'
import {EditorFooterWrapper, NnWrapper, TextNn} from './TextEditorFooter.styles'

type EditorFooterProps = {
  methodsForm: UseFormReturn<PublicationInputType>
  keywords: string
}

/**
 * Футер текстового редактора, тоесть ключевые слова и ИИ для автогенерации Ключевых слова
 * @param methodsForm Общие методы формы
 * @param keywords Ключевые слова хранящиеся в publicationStore
 * @returns
 */
const EditorFooter: FC<EditorFooterProps> = ({methodsForm, keywords}) => {
  const autoText = 'Авто'
  const [words, setWords] = useState(keywords)
  const [isAutoKeywords, setIsAutoKeywords] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const {keycloak} = useKeycloak()

  const [authorId, setAuthorId] = useState<string>('')

  useEffect(() => {
    if (keycloak.tokenParsed?.sub) {
      setAuthorId(keycloak.tokenParsed?.sub)
    }
  }, [keycloak.tokenParsed?.sub])

  const {publicationStore, editorStore} = useStore()
  const {publication, setPublicationWithPrevState, setPublicationLoading} = publicationStore
  const {editorTextLength, editorRef, editorDirty} = editorStore

  const abortControllerRef = useRef<AbortController | null>(null)

  const [updatePublicationKeywords] = useUpdatePublicationKeywordsMutation()
  const {setValue} = methodsForm

  // Автосохранение ключевых слов в черновиках
  const debouncedUpdatePublication = useMemo(() => {
    return debounce(async (value: string) => {
      try {
        if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication.status)) {
          setPublicationLoading(true)
          const variablesData = {
            publicationUid: publication.uid,
            keywords: value
          }

          const updatePublicationData = await updatePublicationKeywords({
            variables: {
              data: variablesData
            }
          })

          if (!updatePublicationData?.errors?.length) {
            setPublicationWithPrevState(prev => ({
              ...prev,
              keywords: updatePublicationData.data?.updatePublication?.publication?.keywords ?? ''
            }))
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        setPublicationLoading(false)
      }
    }, 3000)
  }, [
    publication.status,
    publication.uid,
    setPublicationLoading,
    updatePublicationKeywords,
    setPublicationWithPrevState
  ])

  // Функция автогенерации слова
  const generateKeywords = useCallback(async () => {
    setIsLoading(true)
    // Отмена предыдущего запроса
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    const controller = new AbortController()
    abortControllerRef.current = controller

    try {
      if (isAutoKeywords && editorDirty && editorTextLength > 2) {
        let quill: Quill | undefined

        if (editorRef.current) {
          quill = editorRef.current.getEditor()
        }

        if (quill) {
          const delta = quill.getContents()
          let requestText = ''

          delta.forEach(item => {
            if (typeof item.insert === 'string') {
              requestText += item.insert
              requestText += ' '
            }
          })

          const generateKeywords = await fetchAutoKeywords(
            {
              params: requestText,
              publicationId: publication.deprecatedId,
              publicationUid: publication.uid,
              authorId
            },
            controller.signal
          )

          const keywords = generateKeywords.keywords

          if (keywords) {
            setWords(keywords)
            setValue('keywords', keywords)
            debouncedUpdatePublication(keywords)
          }
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [isAutoKeywords, editorDirty, editorTextLength, editorRef, publication.uid, setValue, debouncedUpdatePublication])

  //handler для onChange в <Keywords/>
  const changeKeyWords = useCallback(
    (value: string) => {
      if (!value.includes('<html>')) {
        setValue('keywords', value)
        setIsAutoKeywords(false)
        setWords(value)
        debouncedUpdatePublication(value)
      } else {
        console.error('Обнаружены некорректные данные:', value)
      }
    },
    [debouncedUpdatePublication, setValue]
  )

  // Дебаунс-функция с задержкой 7 секунд для изменений текста
  const debounceGenerateKeywords7Sec = useMemo(() => {
    return debounce(generateKeywords, 7000)
  }, [generateKeywords])

  // Дебаунс-функция с задержкой 2 секунды для переключения тумблера
  const debounceGenerateKeywords2Sec = useMemo(() => {
    return debounce(generateKeywords, 2000)
  }, [generateKeywords])

  // useEffect для вызова функции генерации ключевых слов при изменении текста
  useEffect(() => {
    if (isAutoKeywords) {
      debounceGenerateKeywords7Sec()
    }
  }, [editorTextLength, editorDirty])

  // useEffect для вызова функции генерации ключевых слов при переключении тумблера
  useEffect(() => {
    if (isAutoKeywords) {
      debounceGenerateKeywords2Sec()
    }
  }, [isAutoKeywords])

  // useEffect для размонтирования
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      debouncedUpdatePublication.cancel()
      debounceGenerateKeywords7Sec.cancel()
      debounceGenerateKeywords2Sec.cancel()
    }
  }, [debouncedUpdatePublication, debounceGenerateKeywords7Sec, debounceGenerateKeywords2Sec])

  //useEffect для отмены fetch запроса
  useEffect(() => {
    if (!isAutoKeywords && abortControllerRef.current) {
      abortControllerRef.current.abort()
      abortControllerRef.current = null
    }
  }, [isAutoKeywords])

  useEffect(() => {
    // Регистрируем поле
    if (ref.current) {
      FocusEvent.register('keywords', () => {
        ref.current?.focus({preventScroll: true})
      })
    }

    // Очистка при размонтировании
    return () => {
      FocusEvent.unregister('keywords')
    }
  }, [])

  return (
    <EditorFooterWrapper>
      <KeyWords words={words} changeKeyWords={changeKeyWords} isLoading={isLoading} ref={ref} />
      <NnWrapper>
        <Switch
          checked={isAutoKeywords}
          onChange={() => {
            setIsAutoKeywords(prev => !prev)
          }}
        />
        <TextNn variant='subtitle1'>{autoText}</TextNn>
        <PopoverPopupState info={HELP_INFO_NN_KEYWORDS} isWarning={false} />
      </NnWrapper>
    </EditorFooterWrapper>
  )
}

export default observer(EditorFooter)
