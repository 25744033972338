import {Box} from '@mui/material'
import {screenS} from '../../../@theme/vars'
import styled from 'styled-components'
import {StyledFormControl} from '../../UI/styled'

export const MainBox = styled(Box)`
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${screenS}) {
    flex-direction: column;
    width: calc(100% - 40px);
    min-width: 340px;
    max-width: 450px;
    gap: 12px;
    margin: 0 auto;
    margin-top: 12px;
  }
`
export const FormControlFlex = styled(StyledFormControl)`
  width: 222px;
  @media screen and (max-width: ${screenS}) {
    width: calc(100% - 22px);
    min-width: 340px;
    max-width: 450px;
    margin: 0 auto;
    margin-left: 22px;
  }
`
