import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

export const Logout: FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox='-2 -2 22 22' {...props}>
      <path
        d='M14.5 5L13.09 6.41L14.67 8H6.5V10H14.67L13.09 11.58L14.5 13L18.5 9L14.5 5ZM2.5 2H9.5V0H2.5C1.4 0 0.5 0.9 0.5 2V16C0.5 17.1 1.4 18 2.5 18H9.5V16H2.5V2Z'
        fill='#707070'
      />
    </SvgIcon>
  )
}
