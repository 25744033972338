import React, {FC, ReactNode} from 'react'
import styled, {css} from 'styled-components'

import {Build, PhotoSizeSelectActualOutlined} from '@mui/icons-material'
import {Button, ButtonProps} from '@mui/material'

type ImagePreviewType = {
  children?: ReactNode
  url?: string
  handleOpenDialog?: () => void
  hasOpenModal?: boolean
} & ButtonProps

const StyledButton = styled(Button)<Omit<ImagePreviewType, 'handleOpenDialog'>>`
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px dashed #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${({url}) =>
    url &&
    css`
      background-image: url('${url}');

      .MuiButton-label {
        background: white;
        opacity: 0.8;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        top: 5px;
        right: 5px;
        position: absolute;
      }
    `}
`

const StyledBuild = styled(Build)``

const ImagePreview: FC<ImagePreviewType> = ({handleOpenDialog, hasOpenModal = true, ...props}) => {
  const handleClick = () => {
    if (hasOpenModal && handleOpenDialog) {
      return handleOpenDialog()
    } else {
      return
    }
  }

  return (
    <StyledButton {...props} onClick={() => handleClick()}>
      {props.url ? <StyledBuild /> : <PhotoSizeSelectActualOutlined color='disabled' />}
    </StyledButton>
  )
}

export default ImagePreview
