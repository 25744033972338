import {format} from 'date-fns'
import {getSortFieldForMetric} from './getSortFieldForMetric'
import {MetricDataType} from '../../../../types/MetricDataType'

/**
 * Функция получения данных яндекс-метрики
 * @param viewsDateFrom начала периода просмотров
 * @param viewsDateTo конец периода просмотров
 * @param sortField поле, по которому идет сортировка
 * @returns
 */
export const getFullMetrics = async (
  viewsDateFrom: string,
  viewsDateTo: string,
  sortField: string
): Promise<MetricDataType[]> => {
  const sortFieldForMetric = getSortFieldForMetric(sortField)
  const response = await fetch(
    `${process.env.METRICS_SERVICE_URL}/metrics/?date1=${format(new Date(viewsDateFrom), 'yyyy-MM-dd')}&date2=${format(
      new Date(viewsDateTo),
      'yyyy-MM-dd'
    )}&sort=${sortFieldForMetric}`
  )
  const data = await response.json()

  return data
}
