import {styled} from 'styled-components'
import {Button} from '@mui/material'
import {
  errorColor,
  greenColor2,
  grey20,
  greyBG,
  lightGrayColor2,
  lightGreenColor,
  lightRedColor2,
  mainTextColor
} from '@theme/vars'

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${grey20};
  border-radius: 4px;
`

export const ButtonWrapper = styled(Button)`
  height: 40px;
  border-radius: 0;
  background-color: ${greyBG};
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  &.comment-button__like {
    border-radius: 4px 0px 0px 4px;
    width: 51px;

    &:hover,
    &__active:hover {
      background-color: ${lightGreenColor};

      & svg path {
        fill: ${greenColor2};
      }
    }

    &__active {
      background-color: ${lightGreenColor};
      border-radius: 4px 0px 0px 4px;
      width: 51px;
    }
  }

  &.comment-button__dislike {
    border-radius: 0px 4px 4px 0px;
    border-left: 1px solid ${grey20};
    width: 50px;

    &:hover,
    &__active:hover {
      background-color: ${lightRedColor2};

      & svg path {
        fill: ${errorColor};
      }
    }

    &__active {
      background-color: ${lightRedColor2};
      border-left: 1px solid ${grey20};
      border-radius: 0px 4px 4px 0px;
      width: 50px;
    }
  }

  &.comment-button__dontknow {
    background-color: ${greyBG};
    border-left: 1px solid ${grey20};
    color: ${grey20};
    width: 100px;
  }
  &.comment-button__dontknow:hover {
    color: ${mainTextColor};
  }
  &.comment-button__dontknow__active {
    background-color: ${lightGrayColor2};
    border-left: 1px solid ${grey20};
    color: ${mainTextColor};
    width: 100px;
  }

  @media (max-width: 450px) {
    width: calc(100% - 20px);
  }
`
