import React, {FC} from 'react'

const ExpandCircleDown: FC<any> = () => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.156 5.313L7 7.469L4.844 5.313L3.85 6.3L7 9.45L10.15 6.3L9.156 5.313ZM7 -6.11959e-07C3.136 -2.74158e-07 2.74158e-07 3.136 6.11959e-07 7C9.49761e-07 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 -9.49761e-07 7 -6.11959e-07ZM7 12.6C3.906 12.6 1.4 10.094 1.4 7C1.4 3.906 3.906 1.4 7 1.4C10.094 1.4 12.6 3.906 12.6 7C12.6 10.094 10.094 12.6 7 12.6Z'
                fill='#1964E7'
            />
        </svg>
    )
}

export default ExpandCircleDown
