import React, {FC, useCallback, useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {observer} from 'mobx-react-lite'
import {CircularProgress} from '@mui/material'
import {useStore} from '@stores/rootStoreContext'
import {IdTitle} from '../../../../../types/IdTitle'
import {SubRubric} from '../../../../../types/SubRubric'
import {SubRubricPickerButton, SubRubricPickerButtonGroup, SubRubricTitleWrapper} from './SubRubricPicker.styles'
import {SUBRUBRIC_PICKER_CONSTS} from './SubRubricPicker.consts'
import {SubRubricPickerProps} from './SubRubricPickerProps'

const SubRubricPicker: FC<SubRubricPickerProps> = ({loading, rubricList}) => {
  const {publicationStore} = useStore()
  const {publication, rubricAndSubrubricStore} = publicationStore
  const {rubricIdCache, subrubricIdCache, setSubrubricIdCache} = rubricAndSubrubricStore
  const {getValues} = useFormContext()
  const [rubricsWithDefault, setRubricsWithDefault] = useState<IdTitle[]>()
  const [rubrics, setRubrics] = useState<SubRubric[]>(rubricList || [])
  useEffect(() => {
    setRubrics(rubricList)
  }, [rubricList])

  const setNewValue = useCallback(
    (newValue: SubRubric | null) => {
      setSubrubricIdCache(newValue?.id || '')
    },
    [setSubrubricIdCache]
  )

  useEffect(() => {
    if (rubricIdCache && rubrics?.length) {
      const rubric = rubrics.find(rubric => rubric.id === rubricIdCache)
      const subRubrics = rubric?.subrubrics || []
      const defaultSubRubric: IdTitle = {id: null, title: SUBRUBRIC_PICKER_CONSTS.withoutSubricMsg}
      if (subRubrics.length === 0) {
        setRubricsWithDefault([defaultSubRubric])
        setNewValue(defaultSubRubric)
      } else {
        if (rubricIdCache !== publication?.rubric?.id) {
          setNewValue(defaultSubRubric)
        }
        if (rubricIdCache == publication?.rubric?.id) {
          const curSubrubricId = publication?.subrubric?.id
          const curSubrubricTitle = publication?.subrubric?.title

          if (curSubrubricId !== undefined && curSubrubricTitle !== undefined) {
            setNewValue({id: curSubrubricId, title: curSubrubricTitle})
          }
        }
        setRubricsWithDefault([defaultSubRubric, ...subRubrics])
      }
    }
  }, [
    rubrics,
    rubricIdCache,
    publication?.rubric?.id,
    publication?.subrubric?.id,
    publication?.subrubric?.title,
    setNewValue
  ])

  return (
    <div>
      <SubRubricTitleWrapper>
        {SUBRUBRIC_PICKER_CONSTS.subrubricMsg} {loading && <CircularProgress size='1rem' />}
      </SubRubricTitleWrapper>
      {rubricIdCache ? (
        rubricsWithDefault && (
          <SubRubricPickerButtonGroup
            variant='contained'
            aria-label='outlined primary button group'
            disabled={getValues('cantEditPublication') || loading}
          >
            {rubricsWithDefault &&
              rubricsWithDefault?.map((subrubric: IdTitle) => (
                <SubRubricPickerButton
                  className={`${subrubricIdCache == (subrubric.id || '') ? 'active' : ''}`}
                  variant='contained'
                  key={subrubric.id}
                  id={`subrubric-item-${subrubric.id}`}
                  onClick={() => {
                    setNewValue(subrubric)
                  }}
                >
                  {subrubric.title}
                </SubRubricPickerButton>
              ))}
          </SubRubricPickerButtonGroup>
        )
      ) : (
        <SubRubricPickerButton disabled>{SUBRUBRIC_PICKER_CONSTS.withoutSubricMsg}</SubRubricPickerButton>
      )}
    </div>
  )
}

export default observer(SubRubricPicker)
