import React, {FC, ReactNode, useCallback} from 'react'
import {Box, DialogTitle, Typography} from '@mui/material'
import InfoWarningIcon from '@components/UI/Icons/InfoWarningIcon'
import {errorColor} from '@theme/vars'
import {SimpleModalProps} from '../SimpleModal'
import CloseIcon from '../../Icons/CloseIcon'
import WarningIcon from '../../Icons/WarningIcon'
import {CHECK_STYLE, CONFIRMATION_STYLE, ERROR_STYLE, WARNING_STYLE} from '../../../../types/ModalStyle'
import {IconWrapper, StyledCloseButton} from './ModalTitle.styles'

export const ModalTitle: FC<SimpleModalProps> = ({
  id,
  mode,
  title,
  variant,
  showCloseButton = true,
  onClose,
  headerEndIcon
}) => {
  const onCloseClick = useCallback(() => onClose && onClose({}, 'escapeKeyDown'), [onClose])

  if (!title) return null
  let icon: ReactNode = null
  let textColor = 'rgba(0, 0, 0, 0.87)'
  switch (variant) {
    case CONFIRMATION_STYLE:
      icon = <InfoWarningIcon />
      textColor = errorColor
      break
    case WARNING_STYLE:
      icon = <WarningIcon color='error' />
      textColor = '#FFC64C'
      break
    case ERROR_STYLE:
      icon = <WarningIcon color='error' />
      textColor = '#FF6268'
      break
    case CHECK_STYLE:
      textColor = '#00DA71'
      break
  }

  return (
    <DialogTitle
      id={`${id}_title`}
      style={{
        padding: '16px 24px 0px 24px'
      }}
    >
      <Box color={textColor} display='flex' alignItems='center' justifyContent='center'>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <Typography variant='h6' data-test='title_text' style={{fontSize: '18px', lineHeight: '23.4px'}}>
          {title}
        </Typography>
        {headerEndIcon && headerEndIcon}
        {showCloseButton && onClose && (
          <StyledCloseButton onClick={onCloseClick}>
            <CloseIcon color='secondary' />
          </StyledCloseButton>
        )}
      </Box>
    </DialogTitle>
  )
}
