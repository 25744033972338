import React, {FC, useCallback} from 'react'
import {observer} from 'mobx-react-lite'
import {Checkbox, FormControlLabel} from '@mui/material'
import {useStore} from '@stores/rootStoreContext'
import {PUBLICATION_FORM_DETAILS_CONSTS} from '../PublicationFormDetails.consts'

type IPublicationFormOnlineTranslationCheckbox = {
  disabled: boolean
}

const PublicationFormOnlineTranslationCheckbox: FC<IPublicationFormOnlineTranslationCheckbox> = ({disabled}) => {
  const {editorStore} = useStore()
  const {hasOnlineTranslation, toggleOnlineTranslation} = editorStore

  const handleChange = useCallback(() => {
    toggleOnlineTranslation()
  }, [toggleOnlineTranslation])

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={hasOnlineTranslation}
          color='primary'
          disabled={disabled}
          value={hasOnlineTranslation}
          onChange={handleChange}
        />
      }
      label={PUBLICATION_FORM_DETAILS_CONSTS.onlineTranslation}
    />
  )
}

export default observer(PublicationFormOnlineTranslationCheckbox)
