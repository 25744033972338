import RHFSelect from '@components/Publication/Form/RHFSelect/RHFSelect'
import React, {FC, useCallback, useMemo} from 'react'
import {useTagsListQuery} from '@pages/Tags/gql/Tags.generated'

const TagsSelect: FC = () => {
  const {data, fetchMore} = useTagsListQuery()

  const pageInfo = useMemo(() => data?.tags.pageInfo, [data?.tags.pageInfo])

  const onLoadMore = useCallback(() => {
    if (pageInfo?.hasNextPage && fetchMore) {
      void fetchMore({
        variables: {
          first: 15,
          after: pageInfo?.endCursor
        }
      })
    }
  }, [fetchMore, pageInfo])
  return (
    <RHFSelect
      width='260px'
      name='relatedTags'
      label='Похожие тэги'
      items={data?.tags.edges.map(edge => edge?.node) || []}
      onLoadMore={onLoadMore}
      multiply
    />
  )
}

export default TagsSelect
