import {Box, IconButton, Typography} from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import React, {FC, useState, useCallback} from 'react'
import styled from 'styled-components'
import {useFormContext, useWatch} from 'react-hook-form'
import RHFInput from '@components/Collection/Form/RHFInput/RHFInput'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {lightgrayColor} from '../../../../@theme/vars'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

const HotNewsItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 24px;
`

const ClickedIcon = styled(RemoveRedEyeIcon)`
  color: #1964e7;
  margin-right: 8px;
`

const IconWrapper = styled(IconButton)`
  margin-left: 24px;
`

const Counter = styled(Typography)`
  font-size: 18px;
  line-height: 18px;
  margin-right: 24px;
`

const StyledLink = styled.a`
  color: ${lightgrayColor};
  display: flex;
  align-items: center;
`

type HotNewsItemProps = {
  deleteHotNews: () => void
}

const HotNewsItem: FC<HotNewsItemProps> = ({deleteHotNews}) => {
  const [isOpenDeleteModal, setIsOpenDeleteMpdal] = useState(false)
  const {control} = useFormContext()
  const fields = useWatch({control})
  const titleLabel = 'Заголовок'
  const descriptionLabel = 'Подзаголовок'

  const toggleIsOpenModal = useCallback(() => {
    setIsOpenDeleteMpdal(!isOpenDeleteModal)
  }, [isOpenDeleteModal])

  return (
    <>
      <HotNewsItemWrapper>
        <RHFInput name='title' label={titleLabel} width='100%' />
        <RHFInput name='description' label={descriptionLabel} width='100%' />
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <ClickedIcon />
            <Counter color='primary' variant='h1'>
              {fields.clickCount || 0}
            </Counter>
          </Box>
          <Box display='flex' alignItems='center'>
            <StyledLink href={fields.url}>
              <VisibilityOutlinedIcon />
            </StyledLink>
            <IconWrapper aria-label='Перейти к истории изменений' onClick={toggleIsOpenModal}>
              <DeleteForeverOutlinedIcon className='icon' color='disabled' />
            </IconWrapper>
          </Box>
        </Box>
      </HotNewsItemWrapper>
      <SimpleModal
        mode='dialog'
        variant='warning'
        open={isOpenDeleteModal}
        title='Удаление горячей новости'
        onClose={toggleIsOpenModal}
        onNo={toggleIsOpenModal}
        onYes={deleteHotNews}
        yesLabel='Удалить'
      >
        <Box textAlign='center'>
          <Typography variant='h2'>Вы уверены что хотите удалить горячую новость?</Typography>
        </Box>
      </SimpleModal>
    </>
  )
}

export default HotNewsItem
