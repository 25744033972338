import {DeltaOperation} from 'quill'
import {Quill} from 'react-quill'
import {OnlineTranslationDividerBlot} from '@components/TextEditor/Blots'

/**
 * Разделяет тело новости от онлайн-трансляции. Звенья онлайн-трансляции сохраняются в виде массива для дальнейшей сортировки
 * @param {Quill} quill - Экземпляр редактора Quill
 */
const generateOnlineTranslationChunks = (quill: Quill) => {
  const delta = quill.getContents()

  let onlineTranslationHasBegan = false

  const publicationBody: DeltaOperation[] = []
  const onlineTranslationChunks: DeltaOperation[][] = []

  delta.forEach(element => {
    const isOnlineTranslationBlot = Boolean(element.insert[OnlineTranslationDividerBlot.blotName])

    // Ставит флаг что онлайн-трансляция началась и добавляет этот элемент в операции Дельты
    if (isOnlineTranslationBlot) {
      onlineTranslationHasBegan = true
      onlineTranslationChunks.push([element])
    }

    // Сохраняет порядок элементов (фотографий, видео и html),
    // вставленных между элементами онлайн трансляции
    if (!isOnlineTranslationBlot && onlineTranslationHasBegan) {
      const lastIndex = onlineTranslationChunks.length - 1
      onlineTranslationChunks[lastIndex].push(element)
    }

    // Сохраняет тело новости для ее последующей вставкой перед онлайн-трансляцией
    if (!onlineTranslationHasBegan) {
      publicationBody.push(element)
    }
  })

  return {publicationBody, onlineTranslationChunks}
}

export {generateOnlineTranslationChunks}
