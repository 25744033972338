import styled from 'styled-components'

export const PublicationFormContent = styled.div`
  position: relative;
  /* flex: 1 1; */
  padding-top: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

export const PublicationFormContentHeader = styled.div`
  width: calc(100% - 20px);
  max-width: 1266px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #222;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 28px;
`

export const PublicationFormContentHeaderTitle = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const PublicationFormContentWrapper = styled.form`
  display: flex;
  align-items: left;
  flex-direction: row;
  width: calc(100% - 20px);
  max-width: 1266px;
  margin: auto;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const PublicationFormWrapper = styled.form`
  /* height: calc(100vh - 64px); */
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 60px;

  @media only screen and (max-width: 840px) {
    margin-bottom: 120px;
  }
  @media only screen and (max-width: 540px) {
    margin-bottom: 160px;
  }
`

export const FooterEditor = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
`
