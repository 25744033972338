import React, {FC, useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {FormProvider, useForm} from 'react-hook-form'
import {useSnackbar} from 'notistack'
import {Box, Divider, LinearProgress, Typography} from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import {Figure, Publication, Story} from '@graphql/types'
import RelatedPublications from '@components/UI/RelatedPublications/RelatedPublications'
import RHFSearchPublication from '../../UI/RHFSearchPublication/RHFSearchPublication'
import Header from '../../Common/Header/Header'
import Editor from '../../Common/Editor/Editor'
import {
  useAddPublicationsToStoryMutation,
  usePublicationsByStoryUidQuery,
  useRemovePublicationsFromStoryMutation,
  useUpdateStoryMutation
} from './gql/StoryEditor.generated'
import {STORY_FORM_CONSTS} from './StoryForm.consts'

const StoryFormWrapper = styled.div`
  margin: 25px 5%;
  padding: 0 20px;
`

type StoryFormProps = {
  story: Story
}

type StoryEditorFormProps = {
  title: string
  description: string
  isActive: boolean
  createdAt: string
  publications: Publication[]
  updatedAt: string
  figure: Figure
  uid: string
  onRemove: (publicationUid: string) => void
  onAdded: (publication: Publication) => void
}

const StoryForm: FC<StoryFormProps> = ({story}) => {
  const [publicationsServer, setPublicationsServer] = useState<Publication[]>([])
  const [publicationsAdded, setPublicationsAdded] = useState<Publication[]>([])
  const [publicationsDeleted, setPublicationsDeleted] = useState<string[]>([])
  const history = useHistory()
  const [updateStoryMutation] = useUpdateStoryMutation()
  const [addPublication] = useAddPublicationsToStoryMutation()
  const [removePublication] = useRemovePublicationsFromStoryMutation()
  const snackbar = useSnackbar()

  const onAdded = useCallback(
    (publication: Publication) => {
      if ([...publicationsServer, ...publicationsAdded].map(v => v?.uid).includes(publication.uid)) {
        snackbar.enqueueSnackbar('Уже добавлена', {variant: 'error'})
      } else {
        setPublicationsAdded(prevState => [publication, ...prevState])
      }
      setPublicationsDeleted(prevState => prevState.filter(uid => uid !== publication.uid))
    },
    [publicationsAdded, publicationsServer, snackbar]
  )

  const onRemove = useCallback(
    (publicationUid: string) => {
      if (publicationsDeleted.includes(publicationUid)) {
        return
      }
      if (publicationsServer.map(publication => publication.uid).includes(publicationUid)) {
        setPublicationsDeleted(prevState => [publicationUid, ...prevState])
      }
      setPublicationsAdded(prevState => prevState.filter(publication => publication.uid !== publicationUid))
      setPublicationsServer(prevState => prevState.filter(publication => publication.uid !== publicationUid))
    },
    [publicationsDeleted, publicationsServer]
  )

  const defaultValues: StoryEditorFormProps = {
    title: story?.title || '',
    createdAt: story?.createdAt || '',
    description: story?.description || '',
    isActive: story?.isActive || true,
    uid: story?.uid || '',
    updatedAt: story?.updatedAt || '',
    publications: publicationsServer || [],
    figure: (story?.figure || STORY_FORM_CONSTS.figure) as Figure,
    onRemove: onRemove,
    onAdded: onAdded
  }

  const {control, setValue, handleSubmit, formState, ...methodsForm} = useForm<any>({
    defaultValues
  })

  useEffect(() => {
    setValue('publications', [...publicationsAdded, ...publicationsServer])
    setValue('onAdded', onAdded)
    setValue('onRemove', onRemove)
  }, [onAdded, onRemove, publicationsAdded, publicationsServer, setValue])

  const {data, fetchMore, refetch} = usePublicationsByStoryUidQuery({
    variables: {storyUid: story.uid},
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setPublicationsServer(data?.publications?.edges.map(edge => edge?.node as Publication) as Publication[])
      setValue('publications', data?.publications?.edges.map(edge => edge?.node as Publication) || [])
    }
  })

  const onBack = useCallback(() => {
    history.goBack()
  }, [history])

  const onSubmitStory = useCallback(
    async data => {
      try {
        await updateStoryMutation({
          variables: {
            data: {
              description: data.description,
              figureUid: data.figure?.uid || null,
              storyUid: story.uid,
              title: data.title
            }
          }
        })
        if (publicationsDeleted.length) {
          await removePublication({
            variables: {
              data: {
                storyUid: story.uid,
                publicationUids: publicationsDeleted
              }
            }
          })
          setPublicationsDeleted([])
        }
        if (publicationsAdded.length) {
          await addPublication({
            variables: {
              data: {
                storyUid: story.uid,
                publicationUids: publicationsAdded.map(publication => publication.uid)
              }
            }
          })
          setPublicationsAdded([])
        }

        setTimeout(
          () =>
            refetch({
              storyUid: story.uid,
              limit: 16
            }),
          1000
        )
        snackbar.enqueueSnackbar('Сохранено', {variant: 'success'})
      } catch (e) {
        console.error(e)
      }
      onBack()
    },
    [
      onBack,
      addPublication,
      publicationsAdded,
      publicationsDeleted,
      refetch,
      removePublication,
      snackbar,
      story.uid,
      updateStoryMutation
    ]
  )

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        first: 10,
        after: data?.publications?.pageInfo.endCursor
      }
    })
  }, [data?.publications?.pageInfo.endCursor, fetchMore])

  return (
    <FormProvider
      {...{
        control,
        setValue,
        handleSubmit,
        formState,
        ...methodsForm
      }}
    >
      <StoryFormWrapper>
        <form noValidate onSubmit={handleSubmit(onSubmitStory)}>
          <Header title='Редактирование сюжета' onBack={onBack} />
          <Editor figureTypeSlug='story' />
          <Box width='100%'>
            <RHFSearchPublication />
            <Box marginTop='30px'>
              <Divider />
              {story.publications?.edges.length || [...publicationsAdded, ...publicationsServer].length ? (
                <InfiniteScroll
                  dataLength={data?.publications?.edges.length || 0}
                  next={fetchMoreData}
                  hasMore={!!data?.publications?.pageInfo.hasNextPage}
                  loader={<LinearProgress color='primary' />}
                  height='64vh'
                >
                  <RelatedPublications />
                </InfiniteScroll>
              ) : (
                <Box marginTop='15px'>
                  <Typography variant='h2'>Публикации отсутствуют</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </form>
      </StoryFormWrapper>
    </FormProvider>
  )
}

export default StoryForm
