import React, {FC, useCallback, useEffect, useState} from 'react'
import {IconButton, Tooltip, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import EditIcon from '@components/UI/Icons/EditIcon'
import TrashFilledIcon from '@components/UI/Icons/TrashFilled'
import {useStore} from '@stores/rootStoreContext'
import {Figure, GalleryImage} from '@graphql/types'
import {GALLERY_BLOCK_ITEM_CONSTS} from './GalleryBlockItem.consts'
import {
  AuthorWrapper,
  DeleteBlockWrapper,
  DeleteFromGalleryButton,
  DeleteFromGalleryText,
  DeleteIconWrapper,
  DescriptionWrapper,
  GalleryBlockImageWrapper,
  GalleryBlockInfoFieldsWrapper,
  GalleryBlockInfoWrapper,
  GalleryBlockItemWrapper,
  IconsWrapper,
  ImageBox,
  TooltipWrapper,
  ViewSourceImageWrapper
} from './GalleryBlockItem.styles'
import GalleryBlockItemDescription from './GalleryBlockItemDescription/GalleryBlockItemDescription'
import GalleryBlockItemAuthor from './GalleryBlockItemAuthor/GalleryBlockItemAuthor'
import {GalleryBlockItemProps} from './GalleryBlockItemProps'

const GalleryBlockItem: FC<GalleryBlockItemProps> = ({galleryImage, curIndex, handleDeleteBlock}) => {
  const {publicationStore} = useStore()
  const {galleryModalStore} = publicationStore
  const {setGalleryError, deleteGalleryError, setIsImageEditorOpen, setGalleryImageForImageEditor} = galleryModalStore

  const [errorAuthor, setErrorAuthor] = useState('')
  const [errorDescription, setErrorDescription] = useState('')

  const handleEditBlockModal = useCallback(
    (galleryImage: GalleryImage) => {
      setIsImageEditorOpen(true)
      setGalleryImageForImageEditor(galleryImage)
    },
    [setIsImageEditorOpen, setGalleryImageForImageEditor]
  )

  useEffect(() => {
    if (errorDescription.length > 0) {
      setGalleryError(galleryImage.uid, errorDescription)
      return
    }
    if (errorAuthor.length > 0) {
      setGalleryError(galleryImage.uid, errorAuthor)
      return
    }
    deleteGalleryError(galleryImage.uid)
  }, [galleryImage.uid, errorDescription, errorAuthor, setGalleryError, deleteGalleryError])

  return (
    <GalleryBlockItemWrapper>
      <GalleryBlockInfoWrapper>
        <GalleryBlockImageWrapper>
          <ImageBox url={galleryImage?.figureVersion?.primaryProcessedImage?.url || ''}>
            <IconsWrapper>
              <TooltipWrapper>
                <Tooltip title={GALLERY_BLOCK_ITEM_CONSTS.toChange}>
                  <IconButton
                    edge='end'
                    id={`gallery-block-item-edit-btn-${galleryImage.id}`}
                    onClick={() => handleEditBlockModal(galleryImage)}
                    size='small'
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TooltipWrapper>
            </IconsWrapper>
          </ImageBox>
          <ViewSourceImageWrapper
            href={
              galleryImage?.figureVersion?.figure?.sourceImage?.url ||
              galleryImage?.figureVersion?.primaryProcessedImage?.url
            }
            target='_blank'
            rel='noreferrer'
            id={`gallery-block-item-view-source_${galleryImage.id}`}
          >
            <Typography variant='text12R'>{GALLERY_BLOCK_ITEM_CONSTS.viewSourceImage}</Typography>
          </ViewSourceImageWrapper>
        </GalleryBlockImageWrapper>
        <GalleryBlockInfoFieldsWrapper>
          <DescriptionWrapper>
            <GalleryBlockItemDescription
              ind={curIndex - 1}
              descriptionVal={galleryImage.description}
              setError={setErrorDescription}
            ></GalleryBlockItemDescription>
          </DescriptionWrapper>
          <AuthorWrapper>
            <GalleryBlockItemAuthor
              ind={curIndex - 1}
              authorVal={galleryImage.author}
              setError={setErrorAuthor}
            ></GalleryBlockItemAuthor>
          </AuthorWrapper>
          <DeleteBlockWrapper>
            <Tooltip title={GALLERY_BLOCK_ITEM_CONSTS.removeFromGalleryTooltip} placement='left'>
              <DeleteFromGalleryButton
                id={`delete-from-gallery-btn-${galleryImage.id}`}
                onClick={() => handleDeleteBlock(galleryImage.uid)}
              >
                <DeleteIconWrapper>
                  <TrashFilledIcon style={{height: '20px'}} />
                </DeleteIconWrapper>
                <DeleteFromGalleryText>
                  <Typography variant='text14R'>{GALLERY_BLOCK_ITEM_CONSTS.removeFromGallery}</Typography>
                </DeleteFromGalleryText>
              </DeleteFromGalleryButton>
            </Tooltip>
          </DeleteBlockWrapper>
        </GalleryBlockInfoFieldsWrapper>
      </GalleryBlockInfoWrapper>
    </GalleryBlockItemWrapper>
  )
}

export default observer(GalleryBlockItem)
