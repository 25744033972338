const baseUrl = `${process.env.REACT_APP_LOCKS}`

type newAdminPanelResponse = {
    success: boolean
    lock: {
        sessionId: number
        lockedBy: {
            username: string
        }
        entity: {
            id: string
        }
    }
}

type deleteLockResponse = {
    success: boolean
}

/**
 *
 * @param sessionId - id текущей блокировки
 * @param id - id публикации
 * @param username - имя пользователя
 * @returns
 */
export const updateLockForNewAdminPanel = async (
    sessionId: number | null,
    id: string,
    username: string
): Promise<newAdminPanelResponse> => {
    return await fetch(`${baseUrl}/update`, {
        method: 'POST',
        body: JSON.stringify({
            sessionId,
            id,
            username
        })
    }).then(result => result.json())
}
/**
 *
 * @param sessionId - id текущей блокировки
 * @param id - id публикации
 * @returns
 */
export const deleteLockForNewAdminPanel = async (
    sessionId: number | null,
    id: string
): Promise<deleteLockResponse> => {
    return await fetch(`${baseUrl}/delete`, {
        method: 'POST',
        body: JSON.stringify({
            sessionId,
            id
        })
    }).then(result => result.json())
}

/**
 *
 * @param id - Id публикации
 * @param userName - имя пользователя
 * @param token - token из кейклока
 * @returns
 */
export const replaceLockForNewAdminPanel = async (
    id: string,
    username: string,
    token: string | undefined
): Promise<newAdminPanelResponse> => {
    return await fetch(`${baseUrl}/replace`, {
        method: 'POST',
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        },
        body: JSON.stringify({
            id,
            username
        })
    }).then(result => result.json())
}
