import {ButtonGroup, CircularProgress} from '@mui/material'
import {FC, useCallback, useEffect, useState} from 'react'
import {LikeIcon} from '@components/UI/Icons/LikeIcon'
import {DislikeIcon} from '@components/UI/Icons/DislikeIcon'
import {ButtonsWrapper, ButtonWrapper} from './CommentItemButtons.styles'
import {COMMENT_ITEM_BUTTONS_CONSTS} from './CommentItemButtons.consts'
import {CommentItemButtonsProps} from './CommentItemButtonsProps'

/**
 * Кнопки для модерации комментария
 * @param param0
 * @returns
 */
export const CommentItemButtons: FC<CommentItemButtonsProps> = ({
  commentUid,
  viewed,
  isBad,
  moderateComment,
  loading
}) => {
  const [isBadValue, setIsBadValue] = useState(viewed === true ? undefined : isBad)
  const [isViewed, setIsViewed] = useState(viewed)

  useEffect(() => {
    setIsViewed(viewed)
  }, [viewed])

  const handleModerateComment = useCallback(
    (viewedValue: boolean, val?: boolean) => {
      setIsBadValue(val === false ? true : val === true ? false : undefined)
      setIsViewed(viewedValue)
      moderateComment(commentUid, viewedValue, val)
    },
    [commentUid, moderateComment]
  )

  return (
    <ButtonGroup size='small' aria-label='Small button group'>
      <ButtonsWrapper>
        <ButtonWrapper
          variant='contained'
          color='secondary'
          className={isBadValue === false ? 'comment-button__like__active' : 'comment-button__like'}
          size='small'
          id={`comment-like-button-${commentUid}`}
          onClick={() => handleModerateComment(false, true)}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <LikeIcon color={isBadValue === false ? 'primary' : 'secondary'} />
          )}
        </ButtonWrapper>
        <ButtonWrapper
          variant='contained'
          color='secondary'
          className={isViewed === true ? 'comment-button__dontknow__active' : 'comment-button__dontknow'}
          size='small'
          id={`comment-dont-know-button-${commentUid}`}
          onClick={() => handleModerateComment(true, undefined)}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} /> : COMMENT_ITEM_BUTTONS_CONSTS.dontKnow}
        </ButtonWrapper>
        <ButtonWrapper
          variant='contained'
          color='secondary'
          className={isBadValue === true ? 'comment-button__dislike__active' : 'comment-button__dislike'}
          size='small'
          id={`comment-dislike-button-${commentUid}`}
          onClick={() => handleModerateComment(false, false)}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <DislikeIcon color={isBadValue === true ? 'primary' : 'secondary'} />
          )}
        </ButtonWrapper>
      </ButtonsWrapper>
    </ButtonGroup>
  )
}
