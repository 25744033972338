import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useSetCheckingPhotoEditorMutation} from '../gql/BildCheckList.generated'
import format from 'date-fns/format'
import {compareAsc} from 'date-fns'
import {
  AuthorsTitle,
  StyledWatchLater,
  StyledTypography,
  FlexDiv,
  MainDiv,
  FlexTimeDiv,
  FlexTitle,
  FlexAuthors,
  FlexPublicationInfo,
  FlexSettings,
  StyledIconButton
} from './BildCheckListTableItem.styles'
import * as _ from 'lodash'
import {BILD_CHECK_LIST_TABLE_ITEM_CONSTS} from './BildCheckListTableItem.consts'
import TitleLink from '@components/UI/styled/TitleLink'
import {PencilIcon24} from '@components/UI/Icons/Pencil'
import ConfirmationPopover from '@components/UI/ConfirmatiomPopover/ConfirmationPopover'
import {CheckedByRedactorOutlinedIcon} from '@components/UI/Icons/CheckedByRedactorIcon'
import {CircularProgress} from '@mui/material'
import {BildCheckListTableItemProps} from './BildCheckListTableItemProps'

/**
 * Компонент отрисовки строки таблицы проверки бильдредакторов
 * @param param0
 * @returns
 */
const BildCheckListTableItem: FC<BildCheckListTableItemProps> = ({
  publication,
  focusedElementId,
  setFocusedElementId,
  status
}) => {
  const isPending = useMemo(
    () => compareAsc(new Date(publication.publishedAt), new Date()) > -1,
    [publication.publishedAt]
  )

  const [setCheckingPhotoEditor, {loading: loading}] = useSetCheckingPhotoEditorMutation()
  const [publicationStatus, setPublicationStatus] = useState(status)

  useEffect(() => {
    setPublicationStatus(status)
  }, [status])

  const setChecked = useCallback(() => {
    const variablesData = {
      publicationUid: publication.uid,
      status: true
    }
    const setCheckingPhotoEditorData = setCheckingPhotoEditor({
      variables: {
        data: variablesData
      }
    }).then(() => {
      const refetchBildCheckListEvent = new CustomEvent('refetchBildCheckListEvent')
      window.dispatchEvent(refetchBildCheckListEvent)
    })
  }, [setCheckingPhotoEditor, publication.uid])

  return (
    <>
      <FlexDiv>
        <FlexTimeDiv>
          {isPending && <StyledWatchLater fontSize={'small'} />}
          <StyledTypography
            id={`publication-publised-or-created-time-${publication.id}`}
            style={{
              marginLeft: '10px',
              marginTop: '2px'
            }}
          >
            {format(new Date(publication.publishedAt || publication.createdAt), 'HH:mm')}
          </StyledTypography>
        </FlexTimeDiv>
        <MainDiv>
          <FlexPublicationInfo>
            <FlexTitle id={`publication-title-${publication.id}`}>
              <TitleLink to={`/editor/${publication.uid}`} title={BILD_CHECK_LIST_TABLE_ITEM_CONSTS.goToNewsEditingMsg}>
                {publication.title || BILD_CHECK_LIST_TABLE_ITEM_CONSTS.withoutNameMsg}
              </TitleLink>
            </FlexTitle>
          </FlexPublicationInfo>
          <FlexSettings>
            <>
              {publicationStatus && publicationStatus.includes('UNCHECKED') && !loading && (
                <ConfirmationPopover
                  id={`toggle-checked-${publication.id}-btn`}
                  focusedElementId={focusedElementId}
                  setFocusedElementId={setFocusedElementId}
                  textPopper={BILD_CHECK_LIST_TABLE_ITEM_CONSTS.approveMsg}
                  hasPopover={true}
                  actionPopper={setChecked}
                  textPopperStatus={BILD_CHECK_LIST_TABLE_ITEM_CONSTS.setCheckedMsg}
                >
                  <StyledIconButton
                    style={{padding: '6px'}}
                    onMouseEnter={() => {
                      setFocusedElementId(`toggle-checked-${publication.id}-btn`)
                    }}
                  >
                    <CheckedByRedactorOutlinedIcon />
                  </StyledIconButton>
                </ConfirmationPopover>
              )}
              {loading && <CircularProgress size={'24px'} />}
              <ConfirmationPopover
                id={`edit-${publication.id}-btn`}
                focusedElementId={focusedElementId}
                setFocusedElementId={setFocusedElementId}
                textPopper={BILD_CHECK_LIST_TABLE_ITEM_CONSTS.editMsg}
                hasPopover={false}
              >
                <TitleLink to={`/editor/${publication.uid}`}>
                  <StyledIconButton
                    style={{padding: '0px', marginLeft: '8px'}}
                    onMouseEnter={() => {
                      setFocusedElementId(`toggle-checked-${publication.id}-btn`)
                    }}
                  >
                    <PencilIcon24 />
                  </StyledIconButton>
                </TitleLink>
              </ConfirmationPopover>
            </>
          </FlexSettings>
          <FlexAuthors>
            {publication.authors.map(author => (
              <AuthorsTitle key={author.uid}>
                {author?.firstName} {author?.lastName}
              </AuthorsTitle>
            ))}
          </FlexAuthors>
        </MainDiv>
      </FlexDiv>
    </>
  )
}

export default BildCheckListTableItem
