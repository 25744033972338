import * as Types from '../../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type StoriesDayThemeQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
  search?: Types.Maybe<Types.Scalars['String']>
}>

export type StoriesDayThemeQuery = {__typename?: 'Query'} & {
  stories?: Types.Maybe<
    {__typename?: 'StoriesConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'StoriesEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'Story'} & Pick<Types.Story, 'uid' | 'title'> & {
                  figure?: Types.Maybe<
                    {__typename?: 'Figure'} & Pick<Types.Figure, 'uid'> & {
                        latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'uid'> & {
                            primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
                          }
                      }
                  >
                }
            >
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    }
  >
}

export type UpdateDayThemeMutationVariables = Types.Exact<{
  regionUid: Types.Scalars['UUID']
  storyUid?: Types.Maybe<Types.Scalars['UUID']>
}>

export type UpdateDayThemeMutation = {__typename?: 'Mutation'} & {
  updateDayTheme?: Types.Maybe<{__typename?: 'UpdateDayThemePayload'} & Pick<Types.UpdateDayThemePayload, 'status'>>
}

export type DayThemeQueryVariables = Types.Exact<{
  regionUid: Types.Scalars['UUID']
}>

export type DayThemeQuery = {__typename?: 'Query'} & {
  dayTheme?: Types.Maybe<
    {__typename?: 'DayTheme'} & Pick<Types.DayTheme, 'uid' | 'title'> & {
        figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'uid'> & {
            latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'uid'> & {
                primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
              }
          }
      }
  >
}

export const StoriesDayThemeDocument = gql`
  query storiesDayTheme($first: Int, $after: String, $search: String) {
    stories(filter: {isActive: true, search: $search}, sort: UPDATED_AT_DESC, limit: 15, first: $first, after: $after) {
      edges {
        node {
          uid
          title
          figure {
            uid
            latestVersion {
              uid
              primaryProcessedImage {
                url
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

/**
 * __useStoriesDayThemeQuery__
 *
 * To run a query within a React component, call `useStoriesDayThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesDayThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesDayThemeQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useStoriesDayThemeQuery(
  baseOptions?: Apollo.QueryHookOptions<StoriesDayThemeQuery, StoriesDayThemeQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<StoriesDayThemeQuery, StoriesDayThemeQueryVariables>(StoriesDayThemeDocument, options)
}
export function useStoriesDayThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoriesDayThemeQuery, StoriesDayThemeQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<StoriesDayThemeQuery, StoriesDayThemeQueryVariables>(StoriesDayThemeDocument, options)
}
export type StoriesDayThemeQueryHookResult = ReturnType<typeof useStoriesDayThemeQuery>
export type StoriesDayThemeLazyQueryHookResult = ReturnType<typeof useStoriesDayThemeLazyQuery>
export type StoriesDayThemeQueryResult = Apollo.QueryResult<StoriesDayThemeQuery, StoriesDayThemeQueryVariables>
export const UpdateDayThemeDocument = gql`
  mutation updateDayTheme($regionUid: UUID!, $storyUid: UUID) {
    updateDayTheme(data: {regionUid: $regionUid, storyUid: $storyUid}) {
      status
    }
  }
`
export type UpdateDayThemeMutationFn = Apollo.MutationFunction<UpdateDayThemeMutation, UpdateDayThemeMutationVariables>

/**
 * __useUpdateDayThemeMutation__
 *
 * To run a mutation, you first call `useUpdateDayThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDayThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDayThemeMutation, { data, loading, error }] = useUpdateDayThemeMutation({
 *   variables: {
 *      regionUid: // value for 'regionUid'
 *      storyUid: // value for 'storyUid'
 *   },
 * });
 */
export function useUpdateDayThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDayThemeMutation, UpdateDayThemeMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateDayThemeMutation, UpdateDayThemeMutationVariables>(UpdateDayThemeDocument, options)
}
export type UpdateDayThemeMutationHookResult = ReturnType<typeof useUpdateDayThemeMutation>
export type UpdateDayThemeMutationResult = Apollo.MutationResult<UpdateDayThemeMutation>
export type UpdateDayThemeMutationOptions = Apollo.BaseMutationOptions<
  UpdateDayThemeMutation,
  UpdateDayThemeMutationVariables
>
export const DayThemeDocument = gql`
  query dayTheme($regionUid: UUID!) {
    dayTheme(regionUid: $regionUid) {
      uid
      title
      figure {
        uid
        latestVersion {
          uid
          primaryProcessedImage {
            url
          }
        }
      }
    }
  }
`

/**
 * __useDayThemeQuery__
 *
 * To run a query within a React component, call `useDayThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayThemeQuery({
 *   variables: {
 *      regionUid: // value for 'regionUid'
 *   },
 * });
 */
export function useDayThemeQuery(baseOptions: Apollo.QueryHookOptions<DayThemeQuery, DayThemeQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<DayThemeQuery, DayThemeQueryVariables>(DayThemeDocument, options)
}
export function useDayThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DayThemeQuery, DayThemeQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<DayThemeQuery, DayThemeQueryVariables>(DayThemeDocument, options)
}
export type DayThemeQueryHookResult = ReturnType<typeof useDayThemeQuery>
export type DayThemeLazyQueryHookResult = ReturnType<typeof useDayThemeLazyQuery>
export type DayThemeQueryResult = Apollo.QueryResult<DayThemeQuery, DayThemeQueryVariables>
