import CropperStore from './cropperStore/cropperStore'
import EditorStore from './editorStore/editorStore'
import PublicationStore from './publicationStore/publicationStore'
import RegionsWithOverviewBlocksStore from './regionsWithOverviewBlocksStore/regionsWithOverviewBlocksStore'
import ReportsListStore from './reportsListStore/reportsListStore'
import TopNewsStore from './topNewsStore/topNewsStore'

class RootStore {
  cropperStore = new CropperStore()
  editorStore = new EditorStore()
  publicationStore = new PublicationStore()
  regionsWithOverviewBlocksStore = new RegionsWithOverviewBlocksStore()
  reportsListStore = new ReportsListStore()
  topNewsStore = new TopNewsStore()
}

export default new RootStore()
