import React, {FC, useCallback, useEffect, useState} from 'react'
import {format, parseISO} from 'date-fns'
import {Typography} from '@mui/material'
import {useFormContext, useWatch} from 'react-hook-form'
import {PublicationStatus} from '@graphql/types'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {DatePickerInfoWrapper, DatePickerWrapper} from './PublicationDate.styles'
import {PUBLICATION_DATE_CONSTS} from './PublicationDate.consts'

export type PublicationDateProps = {
  publication: any
}

export const PublicationDate: FC<PublicationDateProps> = ({publication}) => {
  const {control, setValue, getValues} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})
  const [timeRest, setTimeRest] = useState(0)
  const [hourForm, setHourForm] = useState(PUBLICATION_DATE_CONSTS.hourForms[0])
  const [dateTime, setDateTime] = useState<any>(new Date(parseISO(publication.publishedAt) || new Date()))

  const [isEditDate, setIsEditDate] = useState(!!fields.publishedAt)

  const getHourForm = useCallback(hour => {
    hour = Math.abs(hour) % 100
    const hour1 = hour % 10
    if (hour > 10 && hour < 20) {
      return setHourForm(PUBLICATION_DATE_CONSTS.hourForms[2])
    }
    if (hour1 > 1 && hour1 < 5) {
      return setHourForm(PUBLICATION_DATE_CONSTS.hourForms[1])
    }
    if (hour1 == 1) {
      return setHourForm(PUBLICATION_DATE_CONSTS.hourForms[0])
    }
    return setHourForm(PUBLICATION_DATE_CONSTS.hourForms[2])
  }, [])

  const saveDateToServer = useCallback(
    date => {
      setValue('publishedAt', date)
    },
    [setValue]
  )

  const [needSetData, setNeedSetData] = useState(false)

  useEffect(() => {
    const newTimeRest = Math.ceil((+new Date(dateTime) - +new Date()) / 1000)
    setNeedSetData(!isEditDate && [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status))

    setTimeRest(newTimeRest)

    if (new Date(dateTime) > new Date()) {
      // setTimeRest(newTimeRest)
      getHourForm(newTimeRest)
    }
    const interval = setInterval(() => {
      if (new Date(dateTime) > new Date()) {
        setTimeRest(newTimeRest)
        getHourForm(newTimeRest)
      }
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [dateTime, getHourForm, isEditDate, publication.status])

  useEffect(() => {
    if (needSetData) {
      setDateTime(new Date())
    }
    const interval = setInterval(() => {
      if (needSetData) {
        setDateTime(new Date())
      }
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [needSetData])

  return (
    <div>
      <DatePickerWrapper
        disableToolbar
        variant='inline'
        inputFormat='dd.MM.yyyy HH:mm'
        margin='normal'
        id='date-picker-inline'
        label={PUBLICATION_DATE_CONSTS.dateTimeMsg}
        value={dateTime}
        onChange={date => {
          setIsEditDate(true)
          setDateTime(date)
          saveDateToServer(date)
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        disabled={getValues('cantEditPublication')}
        slotProps={{
          actionBar: {
            actions: ['today', 'cancel', 'accept'],
            sx: {
              paddingLeft: '15px',
              display: 'inline-flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }
          }
        }}
        localeText={{
          todayButtonLabel: PUBLICATION_DATE_CONSTS.nowMsg,
          cancelButtonLabel: PUBLICATION_DATE_CONSTS.closeMsg,
          okButtonLabel: PUBLICATION_DATE_CONSTS.selectMsg
        }}
      />
      <Typography color='primary' component='div'>
        <DatePickerInfoWrapper>{PUBLICATION_DATE_CONSTS.currentTimeMsg}</DatePickerInfoWrapper>
      </Typography>
      {timeRest > 0 && (
        <Typography color='secondary' component='span' style={{margin: '8px 0 0 12px'}}>
          {PUBLICATION_DATE_CONSTS.deferredPublicationMsg}
        </Typography>
      )}
      {/* {!isEditDate && publication.status !== PublicationStatus.Published && (
        <Typography color='secondary' component='span' style={{margin: '8px 0 0 12px'}}>
          {PUBLICATION_DATE_CONSTS.publishAtNowMsg}
        </Typography>
      )} */}
    </div>
  )
}
