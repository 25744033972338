import React, {FC, useCallback, useEffect, useState} from 'react'
import {FormHelperText, IconButton, InputAdornment, Typography} from '@mui/material'
import {Clear} from '@mui/icons-material'
import {debounce} from 'lodash'
import {observer} from 'mobx-react-lite'
import {redColor} from '@theme/vars'
import {Crop, Figure, FigureVersion, GalleryImage, Image} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {generateGuid} from '@utils/generateGuid'
import {useGetGalleryPsUrlsLazyQuery} from '@pages/Publication/gql/Publication.generated'
import {useCreateFigureMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {Spinner} from '@components/UI/Spinner/Spinner'
import {resolveAllSettledPromises} from '@hooks/resolveAllSettledPromises'
import GalleryImageEditor from '../../ImageEditor/GalleryImageEditor/GalleryImageEditor'
import {GALLERY_UPLOADER_CONSTS} from './GalleryUploader.consts'
import {
  ColumnContainerWrapper,
  GalleryUploaderWrapper,
  InputLabelWrapper,
  OutlinedInputWrapper,
  SpinnerWrapper,
  StyledFormControlWrapper,
  UploadUrlWrapper
} from './GalleryUploader.styles'
import {AddImageToGalleryButton} from './AddImageToGalleryButton/AddImageToGalleryButton'
import {UploadGalleryButton} from './UploadGalleryButton/UploadGalleryButton'

const GalleryUploader: FC = () => {
  const {publicationStore} = useStore()
  const {galleryModalStore} = publicationStore
  const {
    isImageEditorOpen,
    galleryDetail,
    setIsImageEditorOpen,
    setGalleryImageForImageEditor,
    setGalleryImageById,
    setGalleryModalLoading
  } = galleryModalStore
  const [errorText, setErrorText] = useState('')

  const [galleryUrl, setGalleryUrl] = useState('')
  const [galleryImagesLoading, setGalleryImagesLoading] = useState(false)
  const [imagesCount, setImagesCount] = useState(galleryDetail.galleryImages.length)

  const [getGalleryPsUrls, {loading: uploadGetGalleryPsUrlsLoading}] = useGetGalleryPsUrlsLazyQuery()
  const [createFigure, {loading: createFigureLoading}] = useCreateFigureMutation()

  const [imagesForDownloadingCount, setImagesForDownloadingCount] = useState(0)
  const [loadingProgressCount, setLoadingProgressCount] = useState(0)
  const [progressPercent, setProgressPercent] = useState(0)

  useEffect(() => {
    if (loadingProgressCount && imagesForDownloadingCount) {
      if (loadingProgressCount === imagesForDownloadingCount) {
        setProgressPercent(0)
        return
      }
      const val = Math.floor((loadingProgressCount * 100) / imagesForDownloadingCount)
      setProgressPercent(val)
      return
    }
    setProgressPercent(0)
  }, [loadingProgressCount, imagesForDownloadingCount])

  useEffect(() => {
    setImagesCount(galleryDetail.galleryImages.length)
  }, [galleryDetail.galleryImages.length])

  const clearLinkUrl = useCallback(() => {
    setGalleryUrl('')
    setErrorText('')
  }, [])

  const autoCreateGallery = useCallback(
    debounce(url => {
      if (
        url &&
        url.indexOf(GALLERY_UPLOADER_CONSTS.photoStoreHttp, 0) === -1 &&
        url.indexOf(GALLERY_UPLOADER_CONSTS.photoStoreHttps, 0) === -1
      ) {
        setErrorText(GALLERY_UPLOADER_CONSTS.incorrectLinkMsg)
      } else {
        setErrorText('')
      }
    }, 300),
    []
  )

  const openImageDialog = useCallback(() => {
    setIsImageEditorOpen(true)
    setGalleryImageForImageEditor({} as GalleryImage)
  }, [setIsImageEditorOpen, setGalleryImageForImageEditor])

  const closeImageDialog = useCallback(() => {
    setIsImageEditorOpen(false)
  }, [setIsImageEditorOpen])

  const prepareAllData = useCallback(
    async (promises: Promise<any>[]) => {
      try {
        await resolveAllSettledPromises(promises).then(() => {
          setGalleryImagesLoading(false)
          setImagesForDownloadingCount(0)
          setLoadingProgressCount(0)
          setProgressPercent(0)
          clearLinkUrl()
        })
      } catch (err) {
        console.log('err', err)
      }
    },
    [clearLinkUrl]
  )

  const prepareFigureData = useCallback(
    async (src: string): Promise<Figure | null> => {
      const figureData = await createFigure({
        variables: {
          data: {
            src,
            figureTypeSlug: 'publication'
          }
        }
      })

      const promiseRes = await Promise.resolve(figureData)

      if (promiseRes.data?.createFigure?.figure) {
        const galleryUid = galleryDetail?.uid || generateGuid()

        const figureVersion = {
          id: promiseRes.data?.createFigure?.figure?.latestVersion?.id || '',
          uid: promiseRes.data?.createFigure?.figure?.latestVersion?.uid || '',
          crop: (promiseRes.data?.createFigure?.figure?.latestVersion?.crop || {}) as Crop,
          primaryProcessedImage: (promiseRes.data?.createFigure?.figure?.latestVersion?.primaryProcessedImage ||
            {}) as Image,
          processedImages: promiseRes.data?.createFigure?.figure?.latestVersion?.processedImages || [],
          figure: (promiseRes.data?.createFigure?.figure || {}) as Figure
        } as FigureVersion

        const newGalleryBlockItem: GalleryImage = {
          author: promiseRes.data?.createFigure?.figure.author,
          description: promiseRes.data?.createFigure?.figure.description,
          figureVersion: figureVersion,
          gallery: galleryUid,
          id: promiseRes.data?.createFigure?.figure.id,
          uid: promiseRes.data?.createFigure?.figure.uid
        } as GalleryImage

        setGalleryImageById(newGalleryBlockItem)

        setLoadingProgressCount(prev => {
          return prev + 1
        })
        return promiseRes.data?.createFigure?.figure as Figure
      }
      return null
    },
    [galleryDetail?.uid, createFigure, setGalleryImageById]
  )

  const getGalleryPsUrlsAction = useCallback(() => {
    getGalleryPsUrls({
      variables: {
        url: galleryUrl
      }
    }).then(data => {
      const promises: Promise<any>[] = []

      if (data?.data?.galleryPsUrls?.galleryImgUrls?.length) {
        for (const item of data?.data?.galleryPsUrls?.galleryImgUrls) {
          if (item) {
            promises.push(Promise.resolve(prepareFigureData(item)))
            if (promises.length + imagesCount >= GALLERY_UPLOADER_CONSTS.imagesCountLimit) {
              break
            }
          }
        }
      }

      if (promises.length > 0) {
        setImagesForDownloadingCount(promises.length)
        setGalleryImagesLoading(true)
        prepareAllData(promises)
        return
      }
    })
  }, [galleryUrl, imagesCount, getGalleryPsUrls, prepareFigureData, prepareAllData])

  useEffect(() => {
    setGalleryModalLoading(uploadGetGalleryPsUrlsLoading || galleryImagesLoading)
  }, [uploadGetGalleryPsUrlsLoading, galleryImagesLoading, setGalleryModalLoading])

  return (
    <GalleryUploaderWrapper>
      <UploadUrlWrapper>
        <StyledFormControlWrapper>
          <InputLabelWrapper>
            <Typography variant='text12R'>{GALLERY_UPLOADER_CONSTS.galleryUrl}</Typography>
          </InputLabelWrapper>
          <OutlinedInputWrapper
            label={GALLERY_UPLOADER_CONSTS.galleryUrl}
            error={!!errorText}
            autoFocus
            onChange={e => {
              setGalleryUrl(e?.target?.value)
              autoCreateGallery(e?.target?.value)
            }}
            value={galleryUrl}
            disabled={uploadGetGalleryPsUrlsLoading || galleryImagesLoading}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton edge='end' size='small' onClick={() => clearLinkUrl()}>
                  <Clear fontSize='small' />
                </IconButton>
              </InputAdornment>
            }
          />
          {!!errorText && (
            <FormHelperText
              style={{
                color: redColor
              }}
            >
              {errorText}
            </FormHelperText>
          )}
        </StyledFormControlWrapper>
        <UploadGalleryButton
          imagesCount={imagesCount}
          imagesCountLimit={GALLERY_UPLOADER_CONSTS.imagesCountLimit}
          loading={uploadGetGalleryPsUrlsLoading || galleryImagesLoading || createFigureLoading}
          isInvalid={!!errorText || !galleryUrl}
          getGalleryPsUrlsAction={getGalleryPsUrlsAction}
        />
      </UploadUrlWrapper>
      <ColumnContainerWrapper>
        <AddImageToGalleryButton
          imagesCount={imagesCount}
          imagesCountLimit={GALLERY_UPLOADER_CONSTS.imagesCountLimit}
          loading={uploadGetGalleryPsUrlsLoading || galleryImagesLoading || createFigureLoading}
          openImageDialog={openImageDialog}
        />
      </ColumnContainerWrapper>
      <GalleryImageEditor
        modalId={'gallery-image-editor-modal'}
        open={isImageEditorOpen}
        onClose={closeImageDialog}
        figureIsLoading={false}
      />
      {progressPercent > 0 && (
        <SpinnerWrapper>
          <Spinner progress={progressPercent} title={''} />
        </SpinnerWrapper>
      )}
    </GalleryUploaderWrapper>
  )
}

export default observer(GalleryUploader)
