import {Figure, OverviewBlock, TopNewsBlock} from '../../../@graphql/types'

export const CardSize: {[key in 'topNews' | 'topNewsHistory' | 'overview']: CardSizeType} = {
  topNews: {height: 288, width: 278},
  topNewsHistory: {height: 270, width: 278},
  overview: {height: 362, width: 302}
}

export type CardSizeType = {width: number; height: number}

export type MainSelectionCardProps = {
  index: number
  data?: TopNewsBlock | OverviewBlock | any
  read?: boolean
  overview?: boolean
  handleDeleteBlock?: (blockUid: string) => void
  regionId: string | undefined
  size?: 'topNews' | 'topNewsHistory' | 'overview'
}
