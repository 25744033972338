import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type InsideCardTemplatesQueryVariables = Types.Exact<{[key: string]: never}>

export type InsideCardTemplatesQuery = {__typename?: 'Query'} & {
  insideCardTemplates?: Types.Maybe<Array<{__typename?: 'InsideCardTemplate'} & InsideCardTemplateFragmentFragment>>
}

export type InsideCardTemplateFragmentFragment = {__typename?: 'InsideCardTemplate'} & Pick<
  Types.InsideCardTemplate,
  'id' | 'uid' | 'titleExample' | 'subtitleExample' | 'gradientColor'
> & {
    figure?: Types.Maybe<
      {__typename?: 'Figure'} & Pick<Types.Figure, 'uid' | 'src'> & {
          cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
          latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'uid'> & {
              crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'width' | 'height' | 'left' | 'top'>
              primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
            }
        }
    >
  }

export const InsideCardTemplateFragmentFragmentDoc = gql`
  fragment InsideCardTemplateFragment on InsideCardTemplate {
    id
    uid
    titleExample
    subtitleExample
    gradientColor
    figure {
      uid
      cropperPreview {
        url
      }
      src
      latestVersion {
        uid
        crop {
          width
          height
          left
          top
        }
        primaryProcessedImage {
          url
        }
      }
    }
  }
`
export const InsideCardTemplatesDocument = gql`
  query insideCardTemplates {
    insideCardTemplates {
      ...InsideCardTemplateFragment
    }
  }
  ${InsideCardTemplateFragmentFragmentDoc}
`

/**
 * __useInsideCardTemplatesQuery__
 *
 * To run a query within a React component, call `useInsideCardTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsideCardTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsideCardTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsideCardTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<InsideCardTemplatesQuery, InsideCardTemplatesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<InsideCardTemplatesQuery, InsideCardTemplatesQueryVariables>(
    InsideCardTemplatesDocument,
    options
  )
}
export function useInsideCardTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsideCardTemplatesQuery, InsideCardTemplatesQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<InsideCardTemplatesQuery, InsideCardTemplatesQueryVariables>(
    InsideCardTemplatesDocument,
    options
  )
}
export type InsideCardTemplatesQueryHookResult = ReturnType<typeof useInsideCardTemplatesQuery>
export type InsideCardTemplatesLazyQueryHookResult = ReturnType<typeof useInsideCardTemplatesLazyQuery>
export type InsideCardTemplatesQueryResult = Apollo.QueryResult<
  InsideCardTemplatesQuery,
  InsideCardTemplatesQueryVariables
>
