import {isNaN, isNull} from 'lodash'
import {format} from 'date-fns'
import ReactQuill from 'react-quill'
import {toast} from 'react-toastify'
import {updatePublicationAdapterToClient} from '@entities/api/publication/adapters/updatePublicationAdapterToClient'
import serviceClient from '@service/apollo-service-client'
import {fetchCheckText} from '@fetch'
import {PublicationStatus, UpdatePublicationExportInput} from '@graphql/types'
import {UiRegion} from 'entities/types/UiRegion'
import {getQuillEditor} from '@utils/getQuillEditor'
import {TypographReplacer} from '@hooks/typographReplacer'
import {UiPublication} from 'entities/types/UiPublication'
import {UpdatePublicationInput} from '@graphql/types'
import RubricAndSubrubricStore from '@stores/publicationStore/rubricAndSubrubricStore/rubricAndSubrubricStore'
import AnnounceImageModalStore from '@stores/publicationStore/modals/announceImageModalStore/announceImageModalStore'
import {UiFigure} from 'entities/types/UiFigure'
import {UiStory} from 'entities/types/UiStory'
import {clearRemovedUrlsPersons} from '@utils/removedPersonsStorage'
import {
  PublishPublicationDocument,
  UpdatePublicationDocument
} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {PublishOverviewBlocksDraftDocument} from '@components/MainSelection/Overview/OverviewContent/gql/OverviewContent.generated'
import {getKeycloakInstance} from '../../../@auth/keyacloak-client'
import {PublicationInputType} from '../../../components/Publication/Form/PublicationForm/PublicationInputType'
import {PUBLICATION_ACTION_BUTTON_CONSTS} from '../../../components/Publication/Form/PublicationForm/PublicationFormOverviewBlocks/PublicationFormDetails/PublicationActionButton/PublicationActionButton.consts'

export const publishPublicationAction = async (
  fields: PublicationInputType,
  editorRef: React.MutableRefObject<ReactQuill | null>,
  publication: UiPublication,
  rubricAndSubrubricStore: RubricAndSubrubricStore,
  announceImageModalStore: AnnounceImageModalStore,
  setPublicationWithPrevState: (cb: (prev: UiPublication) => UiPublication) => void
): Promise<any> => {
  let authorId = ''
  if (getKeycloakInstance().tokenParsed?.sub) {
    authorId = getKeycloakInstance().tokenParsed?.sub as string
  }

  const updatePublication = (variables: any) =>
    serviceClient.mutate({
      mutation: UpdatePublicationDocument,
      variables: {
        data: variables
      }
    })

  const publishPublication = (variables: any) =>
    serviceClient.mutate({
      mutation: PublishPublicationDocument,
      variables: {
        data: variables
      }
    })

  const publishOverviewBlocksDraft = (variables: any) =>
    serviceClient.mutate({
      mutation: PublishOverviewBlocksDraftDocument,
      variables: {
        data: variables
      }
    })

  const quill = getQuillEditor(editorRef)

  if (quill) {
    const quotesReplacer = new TypographReplacer()
    quotesReplacer.runTypograph(quill.container)
  }

  const messageForToast =
    isNaN(fields.publishedAt) || isNull(fields.publishedAt) || fields.publishedAt < new Date()
      ? PUBLICATION_ACTION_BUTTON_CONSTS.publishedMsg
      : PUBLICATION_ACTION_BUTTON_CONSTS.willPublishMsg + format(new Date(fields.publishedAt), 'dd.MM.yyyy HH:mm')

  try {
    let isFullWidthValue = true
    if (!fields.announceImage?.figureVersion?.id) {
      isFullWidthValue = publication.typeSlug === 'articles' ? true : false
    } else {
      isFullWidthValue =
        fields.announceImage?.figureVersion?.isFullWidth || publication.announceImage?.isFullWidth || false
    }

    // Формируем данные для мутации
    const variablesData: UpdatePublicationInput = {
      publicationUid: publication.uid,
      title: fields.title,
      yandexTitle: fields.yandexTitle,
      yandexDzenTitle: fields.yandexDzenTitle || '',
      subtitle: fields.subtitle,
      isCommentsEnabled: fields.isCommentsDisabled == true ? false : true,
      isAutomaticCommentsDisabled: fields.isAutomaticCommentsDisabled == true ? true : false,
      isImportant: fields.isImportant === true ? true : false,
      specProjectUrl: fields.specProjectUrl || '',
      regionUids: fields.regionUids,
      overviewTitle: fields.overviewTitle || '',
      overviewDescription: fields.overviewDescription || '',
      isInside: fields.isInside,
      tagUids: fields.tagsUids,
      collectionUids: fields.collectionUids,
      storyUid: fields.storyUid?.length ? fields.storyUid : null,
      rubricId: rubricAndSubrubricStore.rubricIdCache?.length ? rubricAndSubrubricStore.rubricIdCache : null,
      subrubricId: rubricAndSubrubricStore.subrubricIdCache?.length ? rubricAndSubrubricStore.subrubricIdCache : null,
      subscriptionBlockUid: fields.subscriptionBlockUid?.length ? fields.subscriptionBlockUid : null,
      authors: fields.authors?.filter(author => author),
      keywords: fields.keywords,
      announceImage: {
        figureVersionId:
          announceImageModalStore.announceFigureCache?.figureVersionId ||
          publication?.announceImage?.figureVersion?.id ||
          fields.announceImage?.figureVersion?.id,
        isFullWidth:
          announceImageModalStore.announceFigureCache?.isFullWidth ||
          publication?.announceImage?.isFullWidth !== undefined
            ? publication?.announceImage?.isFullWidth || false
            : fields.announceImage?.isFullWidth || false,
        description:
          announceImageModalStore.announceFigureCache?.description ||
          publication?.announceImage?.description ||
          fields.announceImage?.description ||
          '',
        author:
          announceImageModalStore.announceFigureCache?.author ||
          publication?.announceImage?.author ||
          fields.announceImage?.author ||
          '',
        alt:
          announceImageModalStore.announceFigureCache?.description ||
          publication?.announceImage?.description ||
          fields.announceImage?.description ||
          ''
      }
    }

    if (fields.seoTitle?.length) {
      variablesData.seoTitle = fields.seoTitle
    }
    if (fields.overviewFigureVersionId?.length) {
      variablesData.overviewFigureVersionId = fields.overviewFigureVersionId
    }
    if (fields.overviewFigureId?.length) {
      variablesData.overviewFigureId = fields.overviewFigureId
    }
    if (fields.ogTitle?.length) {
      variablesData.ogTitle = publication?.ogTitle || fields.ogTitle || ''
    }
    if (fields.ogDescription?.length) {
      variablesData.ogDescription = publication?.ogDescription || fields.ogDescription || ''
    }

    const updatePublicationRes = await updatePublication(variablesData)

    const updatePublicationData = updatePublicationAdapterToClient(updatePublicationRes)

    if (updatePublicationData) {
      const newPublicationSchema = await publishPublication({
        publicationUid: publication?.uid,
        publishedAt: fields.publishedAt
      })

      setPublicationWithPrevState(prev => ({
        ...prev,
        title: updatePublicationData?.title,
        yandexTitle: updatePublicationData?.yandexTitle,
        yandexDzenTitle: updatePublicationData?.yandexDzenTitle,
        subtitle: updatePublicationData?.subtitle,
        seoTitle: updatePublicationData?.seoTitle,
        isCommentsEnabled: updatePublicationData?.isCommentsEnabled,
        isImportant: updatePublicationData?.isImportant,
        sendToRambler: updatePublicationData?.sendToRambler,
        specProjectUrl: updatePublicationData?.specProjectUrl,
        isInside: updatePublicationData?.isInside,
        story: updatePublicationData?.story ? ({uid: updatePublicationData?.story?.uid || ''} as UiStory) : null,
        overviewTitle: updatePublicationData?.overviewTitle,
        overviewDescription: updatePublicationData?.overviewDescription,
        authors: updatePublicationData?.authors,
        keywords: updatePublicationData?.keywords || '',
        yandexNewsVideos:
          updatePublicationData?.yandexNewsVideos?.map(item => ({
            id: item.id,
            title: item.title,
            uri: item.uri,
            uid: item.uid,
            thumbnail: item.thumbnail as UiFigure
          })) || [],
        subscriptionBlockUid: updatePublicationData?.subscriptionBlock?.uid || '',
        storyUid: updatePublicationData?.story?.uid || null,
        regionUids: updatePublicationData?.regions?.map(v => v?.uid) || [],
        regions: (updatePublicationData?.regions as UiRegion[]) || [],
        tagsUids: updatePublicationData?.tags?.map(v => v?.uid) || [],
        collectionUids: updatePublicationData?.collections?.map(v => v?.uid) || [],
        isCommentsDisabled: !(updatePublicationData?.isCommentsEnabled as boolean),
        publishedAt:
          updatePublicationData?.status === PublicationStatus.Published ? updatePublicationData?.publishedAt : null,
        destinationExport: {
          destinationSlugs: (updatePublicationData?.destinations?.map(v => v?.slug) as string[]) || [],
          yandexZenCategoryUids: (updatePublicationData?.yandexZenCategories?.map(v => v?.id) as string[]) || []
        } as UpdatePublicationExportInput,
        imageUrl: '',
        relatedLinks: updatePublicationData?.relatedLinks || [],
        overviewFigureId: updatePublicationData?.overviewFigureId,
        overviewFigureVersionId: updatePublicationData?.overviewFigureVersionId,
        destinationIds: (updatePublicationData?.destinations?.map(v => v?.id) as string[]) || [],
        // announceImage: updatePublicationData?.announceImage as UiAnnounceImage,
        ogTitle: updatePublicationData?.ogTitle || '',
        ogDescription: updatePublicationData?.ogDescription || '',
        // announceFigureVersionId:
        //   updatePublicationData?.announceImage?.figureVersion?.id || '',
        badwords: updatePublicationData?.badwords || false
      }))

      announceImageModalStore.setAnnounceFigureCache(undefined)

      if (!newPublicationSchema?.errors?.length) {
        if (newPublicationSchema.data?.publishPublication?.publication?.status) {
          const publishOverviewBlocksDraftData = await publishOverviewBlocksDraft({
            publicationId: publication?.id,
            url:
              publication.canonicalUrl ||
              newPublicationSchema.data?.publishPublication?.publication?.canonicalUrl ||
              publication.url ||
              '',
            figureVersionId: publication?.overviewFigureVersionId || publication?.announceImage?.figureVersion?.id,
            title: publication?.overviewTitle || publication?.title || '',
            subtitle: publication?.overviewDescription || publication?.subtitle || ''
          })

          if (quill) {
            const delta = quill.getContents()
            const deprecatedId = newPublicationSchema.data.publishPublication.publication.deprecatedId
            let requestText = ''

            delta.forEach(item => {
              if (typeof item.insert === 'string') {
                requestText += item.insert
                requestText += ' '
              }
            })

            fetchCheckText({
              publicationId: deprecatedId,
              publicationUid: publication.uid,
              params: requestText,
              authorId
            })
          }

          setPublicationWithPrevState(prev => ({
            ...prev,
            status: newPublicationSchema.data?.publishPublication?.publication?.status || PublicationStatus.Published,
            canonicalUrl: newPublicationSchema.data?.publishPublication?.publication?.canonicalUrl || ''
          }))

          toast.success(messageForToast, {
            autoClose: false,
            hideProgressBar: true,
            containerId: 'success'
          })
          clearRemovedUrlsPersons(publication.uid)
        }
      }
    }
  } catch (e) {
    console.error(e)
  }
}
