import React, {ChangeEvent, FC, useContext, useState} from 'react'
import {Tab} from '@mui/material'
import TabPanelContent from '../TabPanelContent/TabPanelContent'
import {TabsListProps} from '../../../../models/models'
import {TabsWithBorder} from '@components/UI/styled'
import TabPanel from '@components/UI/TabPanel/TabPanel'
import {LengthContext} from '../List/PublicationList'

const TabsList: FC<TabsListProps> = ({tabNames, refetchDateTime}) => {
  const [value, setValue] = useState(0)
  const length = useContext(LengthContext)
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      <TabsWithBorder value={value} onChange={handleChange} scrollButtons={true} variant='scrollable'>
        {tabNames.map(tab => (
          <Tab label={tab.title} key={tab.name} id={'publication-status-tab'} />
        ))}
      </TabsWithBorder>
      {tabNames.map((tab, i) => (
        <TabPanel value={value} index={i} key={tab.name}>
          <TabPanelContent status={tab.name} refetchDateTime={refetchDateTime} />
        </TabPanel>
      ))}
    </>
  )
}

export default TabsList
