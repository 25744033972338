import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {Controller} from 'react-hook-form'
import {OfficialWord, PublicationStatus} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {FocusEvent} from '@utils/focusEvent'
import {
  useBadwordsListQuery,
  useOfficialWordsListLazyQuery,
  useRandomSeoTitleExampleQuery
} from './../gql/PublicationEditor.generated'
import PublicationFormTitle from './PublicationFormTitle/PublicationFormTitle'
import {replaceValue, validateTitle, validateWords} from './PublicationFormTitle/PublicationFormTitle.utils'
import {
  PUBLICATION_FORM_TITLES_CONSTS,
  SENTENCE_SEPARATOR_REG_EX,
  TitleFieldEntries
} from './PublicationFormTitles.consts'
import {PublicationFormTitlesWrapper, SeoTitleExampleWrapper} from './PublicationFormTitles.styles'
import {ETitlesField, IPublicationFormTitlesProps} from './PublicationFormTitles.types'
import {SeoTitleExample} from './SeoTitleExample/SeoTitleExample'
import {TitleFillingRules} from './TitleFillingRules'

export const PublicationFormTitles: FC<IPublicationFormTitlesProps> = ({methodsForm, cantEditPublication}) => {
  const {control, setValue, clearErrors, setError, getValues, setFocus} = methodsForm

  const titleRef = useRef<HTMLInputElement>(null)
  const yandexTitleRef = useRef<HTMLInputElement>(null)
  const seoTitleRef = useRef<HTMLInputElement>(null)
  const subtitleRef = useRef<HTMLInputElement>(null)

  const {publicationStore, editorStore} = useStore()
  const {publication, setTitlesError} = publicationStore
  const {editorRef} = editorStore

  const [badWords, setBadWords] = useState<string[]>([])
  const [officialWords, setOfficialWords] = useState<OfficialWord[]>([])
  const isNews = publication?.typeSlug === 'news'
  const isArticles = publication.typeSlug === 'articles'
  const [titleCustomInformationError, setTitleCustomInformationError] = useState('')
  const [yandexTitleCustomInformationError, setYandexTitleCustomInformationError] = useState('')

  const {data: randomTitleExamleData} = useRandomSeoTitleExampleQuery({
    variables: {}
  })

  useBadwordsListQuery({
    variables: {},
    onCompleted: ({badwords}) => {
      if (badwords) {
        const data = badwords.reduce<string[]>((array, {verbal_expression}) => {
          if (verbal_expression) {
            array.push(verbal_expression)
          }

          return array
        }, [])

        setBadWords(data)
      }
    }
  })

  const [fetchOfficialWordsList] = useOfficialWordsListLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({officialWords}) => {
      if (officialWords) {
        setOfficialWords(officialWords)
      }
    }
  })

  useEffect(() => {
    fetchOfficialWordsList({
      variables: {}
    })
  }, [fetchOfficialWordsList])

  const [curEventTarget, setCurEventTarget] = useState<HTMLInputElement | undefined>()
  const [curEventTargetSelectionStart, setCurEventTargetSelectionStart] = useState(0)
  const [curFieldName, setCurFieldName] = useState<ETitlesField>()
  const [curFieldValue, setCurFieldValue] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (curEventTarget && curFieldName) {
        const value = replaceValue(curFieldValue)
        const titleIndexPosition = curEventTargetSelectionStart || 0
        setValue(curFieldName, value)
        if (curEventTarget === document.activeElement && titleIndexPosition < curFieldValue.length) {
          curEventTarget.focus()
          curEventTarget.setSelectionRange(titleIndexPosition, titleIndexPosition)
        }
      }
    }, 1500)
    return () => clearTimeout(timer)
  }, [curEventTarget, curEventTargetSelectionStart, curFieldName, curFieldValue, setValue])

  const handleChange = useCallback(
    (fieldValue: string, fieldName: ETitlesField, eventTarget?: HTMLInputElement) => {
      const value = fieldValue
      setCurFieldName(fieldName)
      setCurFieldValue(fieldValue)

      setValue(fieldName, value)

      if (eventTarget) {
        setCurEventTarget(eventTarget)
        setCurEventTargetSelectionStart(eventTarget.selectionStart || 0)
      }

      const isArticlesTitle = isArticles && fieldName === ETitlesField.Title
      const titleMaxLength = isArticlesTitle
        ? PUBLICATION_FORM_TITLES_CONSTS.articleTitleMaxLength
        : TitleFieldEntries[fieldName].maxErrorLength

      setTitlesError(false)
      clearErrors(fieldName)

      const {isInvalid, errorMessage} = validateTitle({
        fieldName,
        text: value,
        officialWords,
        maxErrorLength: titleMaxLength,
        titleMaxWordsCount: TitleFieldEntries[fieldName].titleMaxWordsCount || undefined,
        maxWarningLength: 0
      })

      if (isInvalid) {
        setError(fieldName, {
          message: errorMessage
        })
        setTitlesError(true)
        return
      }

      if (fieldName === ETitlesField.Title) {
        setTitleCustomInformationError(errorMessage)
      }
    },
    [officialWords, isArticles, setTitlesError, setError, clearErrors, setValue]
  )

  const fieldYandexTitle = getValues(ETitlesField.YandexTitle)

  const handleYandexTitleChange = useCallback(
    (value, _fieldName, eventTarget) => {
      handleChange(value, ETitlesField.YandexTitle)

      const {isInvalid: yandexTitleHasBadWord, errorMessage: yandexTitleErrorMessage} = validateWords({
        text: value,
        invalidWords: badWords
      })

      setYandexTitleCustomInformationError(yandexTitleHasBadWord ? yandexTitleErrorMessage : '')

      if (eventTarget) {
        setCurEventTarget(eventTarget)
        setCurEventTargetSelectionStart(eventTarget.selectionStart || 0)
      }
    },
    [badWords, handleChange]
  )

  // Дублирует значение Заголовка в Заголовок для Яндекс.Новостей

  const handleTitleChange = useCallback(
    (value, _fieldName, eventTarget) => {
      const sentencesArr = value?.split(SENTENCE_SEPARATOR_REG_EX) || []
      const hasSentence = sentencesArr.length && sentencesArr[0]
      const condition =
        // Заголовок Яндекс пустой ИЛИ
        fieldYandexTitle === '' ||
        // Первое предложение начинается с заголовка Яндекс или пустой строки ИЛИ
        (hasSentence && sentencesArr[0].includes(fieldYandexTitle || '', 0)) ||
        // Первое предложение включает в себя заголовок Яндекс или пустую строку
        (hasSentence &&
          sentencesArr[0].includes(
            (fieldYandexTitle || '').slice(
              0,
              (fieldYandexTitle?.length || 0) > 0 ? (fieldYandexTitle?.length || 0) - 1 : 0
            ),
            0
          ))

      if ((hasSentence && condition) || publication.typeSlug === 'articles') {
        const title = sentencesArr[0]
        handleChange(title, ETitlesField.YandexTitle, eventTarget)
      }
      if (value && publication.title !== getValues(ETitlesField.Title)) {
        handleChange(value, ETitlesField.YandexDzenTitle, eventTarget)
        handleChange(value, ETitlesField.OverviewTitle, eventTarget)
      }

      handleChange(value, ETitlesField.Title, eventTarget)
    },
    [fieldYandexTitle, handleChange]
  )

  const newUpdateTitle = () => {
    return
  }

  const fieldsRef = [
    {id: 'title', ref: titleRef},
    {id: 'yandexTitle', ref: yandexTitleRef},
    {id: 'seoTitle', ref: seoTitleRef},
    {id: 'subtitle', ref: subtitleRef}
  ]

  useEffect(() => {
    // Регистрируем все поля
    fieldsRef.forEach(field => {
      if (field.ref.current) {
        FocusEvent.register(field.id, () => {
          field.ref.current?.focus({preventScroll: true})
        })
      }
    })

    // Запускаем автофокус, который будет следить за появлением lt-toolbar
    const cleanup = FocusEvent.startAutoFocus()

    // Очистка при размонтировании
    return () => {
      fieldsRef.forEach(field => {
        FocusEvent.unregister(field.id)
      })
      cleanup?.()
    }
  }, [])

  return (
    <PublicationFormTitlesWrapper>
      <Controller
        name={ETitlesField.Title}
        control={control}
        render={({field}) => (
          <PublicationFormTitle
            methodsForm={methodsForm}
            disabled={cantEditPublication}
            // @ts-ignore
            ref={titleRef}
            field={field}
            onChange={handleTitleChange}
            customInformationError={titleCustomInformationError}
            fillingRules={<TitleFillingRules />}
          />
        )}
      />
      {isNews && (
        <Controller
          name={ETitlesField.YandexTitle}
          control={control}
          render={({field}) => (
            <PublicationFormTitle
              methodsForm={methodsForm}
              disabled={cantEditPublication}
              onChange={handleYandexTitleChange}
              customInformationError={yandexTitleCustomInformationError}
              // @ts-ignore
              ref={yandexTitleRef}
              field={field}
            />
          )}
        />
      )}
      <Controller
        name={ETitlesField.SeoTitle}
        control={control}
        render={({field}) => (
          <PublicationFormTitle
            methodsForm={methodsForm}
            disabled={cantEditPublication}
            onChange={handleChange}
            // @ts-ignore
            ref={seoTitleRef}
            field={field}
          />
        )}
      />
      <SeoTitleExampleWrapper>
        <SeoTitleExample randomTitleExamleData={randomTitleExamleData} />
      </SeoTitleExampleWrapper>
      {isArticles && (
        <Controller
          name={ETitlesField.Subtitle}
          control={control}
          render={({field}) => (
            <PublicationFormTitle
              methodsForm={methodsForm}
              disabled={cantEditPublication}
              onChange={handleChange}
              // @ts-ignore
              ref={subtitleRef}
              field={field}
            />
          )}
        />
      )}
    </PublicationFormTitlesWrapper>
  )
}
