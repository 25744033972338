import React, {FC, useCallback, useContext, useEffect, useState} from 'react'
import {Box, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography} from '@mui/material'
import {Clear, HighlightOff} from '@mui/icons-material'
import {useFormContext} from 'react-hook-form'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {usePublicationByUrlFromEditorLazyQuery} from '@components/UI/TextEditorSidebarModal/gql/TextEditorSidebarModal.generated'
import {StyledFormControl} from '@components/UI/styled'
import {AcceptLinkOnNewsIcon} from '@components/UI/Icons/AcceptLinkOnNews'
import {LINK_PICKER_CONSTS} from './LinkPicker.consts'

export type LinkType = {
  id?: string
  url: string
  text: string
}

const LinkPicker: FC<any> = ({changeLinks}) => {
  const {publicationStore} = useStore()
  const {publication} = publicationStore
  const {setValue, getValues} = useFormContext()
  const [urlInput, setUrlInput] = useState('')
  const [errorText, setErrorText] = useState('')
  const [links, setLinks] = useState((publication.relatedLinks as LinkType[]) || [])

  useEffect(
    () =>
      setValue(
        'relatedLinks',
        links.map(link => link)
      ),
    [links, setValue]
  )

  const [getPublicationByUrl] = usePublicationByUrlFromEditorLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const linkExist = links.map(l => l.text).includes(data.publicationByUrl?.title || '')
      if (linkExist) {
        setErrorText(LINK_PICKER_CONSTS.linkHasAlreadyBeenAddedMsg)
        setUrlInput('')
      } else {
        const newLinks = links.slice()
        const publicationByUrlId = data.publicationByUrl ? data.publicationByUrl['uid'] || null : null
        const relatedTextTitle = data.publicationByUrl?.title || ''
        newLinks.push({
          id: publicationByUrlId,
          text: relatedTextTitle,
          url: urlInput
        } as LinkType)
        setLinks(newLinks)

        changeLinks(newLinks)
        setUrlInput('')
      }
    }
  })

  const label = LINK_PICKER_CONSTS.relatedLinkMsg

  const handleClearUrlErrors = useCallback((url: string) => {
    if (url && url.search(new RegExp(`${process.env.REACT_APP_NEWS_REGEX}`)) !== 0) {
      setErrorText(LINK_PICKER_CONSTS.invalidLinkMsg)
    } else {
      setErrorText('')
    }
    setUrlInput(url)
  }, [])

  const handleRemoveLink = useCallback(
    (index: number, linkId: string) => {
      const newLinks = links.filter((_, i) => i !== index)
      setLinks(newLinks)
      changeLinks(newLinks)
    },
    [links]
  )

  const getPublicationByOldUrl = useCallback(() => {
    if (!urlInput) {
      setErrorText(LINK_PICKER_CONSTS.invalidLinkMsg)
      setUrlInput('')
      return
    }
    if (links.length === 5) {
      setErrorText(LINK_PICKER_CONSTS.maximumLinksMsg)
      setUrlInput('')
      return
    }
    getPublicationByUrl({
      variables: {url: urlInput}
    })
  }, [getPublicationByUrl, links.length, urlInput])

  return (
    <Box
      position='relative'
      style={{
        width: '100%',
        maxWidth: '1266px'
      }}
    >
      <StyledFormControl
        style={{
          width: 'calc(100% - 42px)',
          maxWidth: '1266px'
        }}
      >
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          label={label}
          onChange={e => handleClearUrlErrors(e.target.value)}
          value={urlInput}
          error={!!errorText}
          disabled={getValues('cantEditPublication')}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                edge='end'
                size='small'
                onClick={() => handleClearUrlErrors('')}
                disabled={getValues('cantEditPublication')}
              >
                <Clear fontSize='small' />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{errorText}</FormHelperText>
      </StyledFormControl>
      <IconButton
        style={{
          float: 'right',
          padding: '3px',
          marginTop: '6px'
        }}
        disabled={!urlInput || !!errorText || getValues('cantEditPublication')}
        onClick={() => getPublicationByOldUrl()}
      >
        <AcceptLinkOnNewsIcon
          color={!urlInput || !!errorText || getValues('cantEditPublication') ? 'disabled' : 'primary'}
          fontSize='small'
        />
      </IconButton>
      {links.map((link, index) => (
        <Box key={link.url} display='flex' alignItems='center'>
          <IconButton
            style={{marginLeft: '-11px'}}
            disabled={getValues('cantEditPublication')}
            onClick={() => handleRemoveLink(index, link.id || '')}
          >
            <HighlightOff />
          </IconButton>
          <Typography color='secondary'>{link.text}</Typography>
        </Box>
      ))}
    </Box>
  )
}

export default observer(LinkPicker)
