import React, {FC, useCallback, useState} from 'react'
import CollectionItem from './Item/CollectionItem'
import {useHistory} from 'react-router-dom'
import CreateCollectionModal from '../NewCollectionModal/CreateCollectionModal'
import {useCollectionListQuery, useCreateCollectionMutation} from '@pages/Collections/gql/Collections.generated'
import {useSnackbar} from 'notistack'
import {Collection} from '@graphql/types'
import {Box, Button, Divider, LinearProgress} from '@mui/material'
import styled from 'styled-components'

const ListWrapper = styled.div`
  padding-right: 5px;
  overflow: auto;
  max-height: calc(100vh - 159px);
`

const CollectionList: FC = () => {
  const {data, loading} = useCollectionListQuery({
    fetchPolicy: 'cache-and-network'
  })
  const history = useHistory()
  const [createCollection] = useCreateCollectionMutation()
  const snackbar = useSnackbar()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCreateCollection = useCallback(
    async data => {
      try {
        const result = await createCollection({
          variables: {
            title: data.title,
            description: data.description
          },
          refetchQueries: ['collectionList'],
          awaitRefetchQueries: true
        })
        history.push(`/collections/editor/${result.data?.createCollection?.collection?.uid}`)
      } catch {
        snackbar.enqueueSnackbar('Ошибка')
      }
      setIsOpenModal(false)
    },
    [createCollection, history, snackbar]
  )

  if (loading) return <LinearProgress color='primary' />

  return (
    <>
      <Box display='flex' justifyContent='flex-end' marginBottom='33px'>
        <Button color='primary' variant='contained' onClick={() => setIsOpenModal(true)}>
          Добавить
        </Button>
      </Box>
      <Divider />
      <ListWrapper>
        {data?.collections?.edges.map(edge => (
          <CollectionItem key={edge?.node?.uid} collection={edge?.node as Collection} />
        ))}
      </ListWrapper>
      <CreateCollectionModal open={isOpenModal} onClose={() => setIsOpenModal(false)} onOk={handleCreateCollection} />
    </>
  )
}

export default CollectionList
