import {ETitlesField} from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitles.types'
import {OfficialWord} from '@graphql/types'
import {ValidationData, ValidationStatus} from '@utils/models/errorStatus'

const SENTENCE_SEPARATOR_REG_EX = /[\.\!\?]\s/gi

/**
 * Функция проверки заголовка на количество предложений
 * @param fieldName Тип поля
 * @param text Текст заголовка
 * @param officialWords Список разрешенных слов (которые разрешены в заголовке после первого предложения)
 * @returns
 */
export const validateTitleBySentenceCount = (
  fieldName: string,
  text: string,
  officialWords: OfficialWord[]
): ValidationData => {
  const validationData: ValidationData = {
    status: ValidationStatus.Default,
    message: ''
  }

  const isTitle = fieldName === ETitlesField.Title
  const isYandexTitle = fieldName === ETitlesField.YandexTitle

  const enableSentenceCount = isTitle || isYandexTitle
  const sentences = text.split(SENTENCE_SEPARATOR_REG_EX)
  const sentenceIsOnce = sentences.length === 1
  const sentenceAreTwo = sentences.length === 2 && officialWords?.some(word => word.officialWord === sentences[1])
  const tooManySentences = enableSentenceCount && !(sentenceIsOnce || sentenceAreTwo)

  if (tooManySentences) {
    validationData.message = 'В заголовке не может быть более одного предложения'
    validationData.status = ValidationStatus.Error
  }

  return validationData
}
