import {Button} from '@mui/material'
import {mainTextColor, blueColor, screenS} from '../../../../@theme/vars'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  margin: 5px 0 0 7px;
  padding: 0;
  width: 24px;
  height: 24px;
  min-width: initial;
  background: #c4c4c4;
  font-size: 12px;
`

export const ChooseImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;

  position: absolute;
  width: 276px;
  height: 120px;
  left: 0px;
  top: 204px;
  border: 2px dashed ${blueColor};
  border-radius: 4px;
`

export const ImageLoaderTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: ${mainTextColor};
  width: 100%;
  margin-bottom: 10px;
`

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: start;
  width: 100%;
  margin-bottom: 24px;
`
export const StyledDeleteBtn = styled.div`
  display: block;
  position: absolute;
  top: 204px;
  left: 185px;
  @media (max-width: ${screenS}) {
    top: 323px;
    left: 433px;
  }
`
