import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import ReactQuill, {Quill} from 'react-quill'
import 'quill-paste-smart'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {getQuillEditor} from '@utils/getQuillEditor'
import {FocusEvent} from '@utils/focusEvent'
import Toolbar from '../Toolbar/Toolbar'
import {TypographReplacer} from '../../../hooks/typographReplacer'
import {BlockquoteBlot} from '../Blots'
import EditorFooter from './TextEditorFooter/TextEditorFooter'
import QuillEditor from './QuillEditor/QuillEditor'
import {uponSanitizeElementHook} from './utils/uponSanitizeElementHook'
import OnlineTranslation from './OnlineTraslation/OnlineTranslation'
import {EDITOR_REF_ID, TOOLBAR_ID} from './Editor.consts'
import type {EditorProps} from './Editor.props'

const Delta = Quill.import('delta')
const Keyboard = Quill.import('modules/keyboard')
const Size = Quill.import('attributors/style/size')
const QuillHistory = Quill.import('modules/history')
Size.whitelist = ['30px', '20px', '18px', 'textrssblock']

Quill.register(Keyboard, true)
Quill.register(Size, true)
Quill.register(QuillHistory, true)

const Editor: FC<EditorProps> = ({methodsForm, publicationHeaderHeight}) => {
  const {publicationStore, editorStore} = useStore()
  const {publication} = publicationStore
  const {hasOnlineTranslation} = editorStore
  const [editorLength, setEditorLength] = useState(0)
  const [isEditorFullScreen, setIsEditorFullScreen] = useState(false)

  const changeEditorTextLength = useCallback((val: number) => {
    setEditorLength(val)
  }, [])

  const editorRef = useRef<ReactQuill | null>(null)

  const Parchment = Quill.import('parchment')
  const OnlineAttribute = new Parchment.Attributor.Attribute('online-class', 'class', {
    scope: Parchment.Scope.INLINE
  })
  Quill.register(OnlineAttribute, true)

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${TOOLBAR_ID}`
      },
      keyboard: {
        bindings: {
          strike: {
            key: 'B',
            ctrlKey: true,
            shiftKey: false,
            handler: (range, context) => {
              const quill = (editorRef.current as any).getEditor()
              const format = quill.getFormat(range)
              quill.format('bold', !format.bold)
            }
          },
          italic: {
            key: 'I',
            ctrlKey: true,
            shiftKey: false,
            handler: (range, context) => {
              const quill = (editorRef.current as any).getEditor()
              const format = quill.getFormat(range)
              quill.format('italic', !format.italic)
            }
          },
          dash: {
            key: 32,
            ctrlKey: false,
            shiftKey: false,
            prefix: / -$/,
            handler: (range, context) => {
              const quill = (editorRef.current as any).getEditor()
              quill.deleteText(range.index - 2, 2, 'api')
              quill.insertText(range.index - 2, ' —\u00A0', 'user')
            }
          },
          resetFormatAfterPressingEnter: {
            key: 13,
            collapsed: true,
            format: [BlockquoteBlot.blotName],
            suffix: /^$/,
            handler: (range, context) => {
              const quill = getQuillEditor(editorRef)

              if (quill) {
                const [line, offset] = quill.getLine(range.index)
                const delta = new Delta()
                  .retain(range.index)
                  .insert('\n', context.format)
                  .retain(line.length() - offset - 1)
                  .retain(1, {[BlockquoteBlot.blotName]: null})
                quill.updateContents(delta, 'user')
                quill.setSelection(range.index + 1, 'silent')
                // @ts-ignore
                quill.scrollIntoView()
              }
            }
          }
        }
      },
      clipboard: {
        matchVisual: false,
        allowed: {
          tags: ['a', 'b', 'strong', 's', 'i', 'p', 'ul', 'ol', 'li', 'span'],
          attributes: ['href', 'rel', 'target', 'class', 'color', 'bold', 'font', 'size', 'strike', 'italic', 'link']
        },
        keepSelection: true,
        magicPasteLinks: true,
        hooks: {
          uponSanitizeElement(node, data) {
            uponSanitizeElementHook(node, data)
          }
        }
      },
      history: {
        delay: 1000,
        maxStack: 50,
        userOnly: true
      }
    }),
    []
  )

  const handleFocus = useCallback(() => {
    const quill = getQuillEditor(editorRef)

    if (quill) {
      quill.focus()
    }
  }, [])

  const runTypograph = useCallback(() => {
    const quill = (editorRef.current as any).getEditor()
    const quotesReplacer = new TypographReplacer()
    quotesReplacer.runTypograph(quill.container)
  }, [])

  const toggleFullScreen = useCallback(() => {
    const editor = (editorRef.current as any).getEditor()

    if (editor) {
      const editorElement = document.getElementById(EDITOR_REF_ID)
      const toolbarElement = document.getElementById(TOOLBAR_ID)
      const listElement = document.querySelector('.ql-editor')
      const buttonElement = document.getElementById('toolbar-typograph-fullscreen-btn')
      // buttonElement?.blur()
      buttonElement?.addEventListener('click', e => {
        const target = e.currentTarget as HTMLElement
        target.blur()
      })

      if (!isEditorFullScreen) {
        editorElement?.classList.add('fullscreen-editor')
        toolbarElement?.classList.add('fullscreen')
        listElement?.classList.add('fullscreen')

        setIsEditorFullScreen(true)
      } else {
        editorElement?.classList.remove('fullscreen-editor')
        toolbarElement?.classList.remove('fullscreen')
        listElement?.classList.remove('fullscreen')

        setIsEditorFullScreen(false)
      }
    }
  }, [isEditorFullScreen, editorRef])

  useEffect(() => {
    if (editorRef.current) {
      FocusEvent.register('quill-editor', () => {
        const editor = (editorRef.current as any).getEditor()
        if (editor) {
          editor.focus({preventScroll: true})
          editor.setSelection(editor.getLength(), 0)
        }
      })
    }

    return () => {
      FocusEvent.unregister('quill-editor')
    }
  }, [])

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <Toolbar
        toolbarId={TOOLBAR_ID}
        editorLength={editorLength}
        editorRef={editorRef}
        handleFocus={handleFocus}
        runTypograph={runTypograph}
        toggleFullScreen={toggleFullScreen}
        isEditorFullScreen={isEditorFullScreen}
      />
      {publication?.id && (
        <QuillEditor
          editorRef={editorRef}
          changeEditorTextLength={changeEditorTextLength}
          modules={modules}
          handleFocus={handleFocus}
          publicationHeaderHeight={publicationHeaderHeight}
        />
      )}
      {/* TODO: Нужно завести два отдельных компонента и вынести их в PublicationForm */}
      {hasOnlineTranslation && <OnlineTranslation />}
      <EditorFooter methodsForm={methodsForm} keywords={publication.keywords} />
    </div>
  )
}

export default observer(Editor)
