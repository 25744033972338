/**
 * Функция получения имени поля для сортировки в самой метрике
 * @param sortFieldName
 * @returns
 */
export const getSortFieldForMetric = (sortFieldName: string): string => {
  switch (sortFieldName) {
    case 'viewsCount':
      return 'pageviews'
    case 'uniqueUsersCount':
      return 'users'
    case 'fromSocialNetworkCount':
      return 'pageviews'
    case 'fromSocialNetworksPercent':
      return 'pageviews'
    case 'withoutRecommendedSystems':
      return 'pageviews'
    default:
      return '-ym:s:publisherviews'
  }
}
