import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type PublicationByUrlFromEditorQueryVariables = Types.Exact<{
  url: Types.Scalars['String']
}>

export type PublicationByUrlFromEditorQuery = {__typename?: 'Query'} & {
  publicationByUrl?: Types.Maybe<
    | ({__typename: 'ExternalPublication'} & Pick<
        Types.ExternalPublication,
        'url' | 'title' | 'subtitle' | 'announcingImageUrl'
      >)
    | ({__typename: 'Publication'} & Pick<Types.Publication, 'uid' | 'title' | 'subtitle'> & {
          announceImage: {__typename?: 'AnnounceImage'} & Pick<
            Types.AnnounceImage,
            'id' | 'isFullWidth' | 'description' | 'author' | 'alt'
          > & {figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>}
        })
  >
}

export type FigureVersionFragmentFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'src' | 'uid'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
      }
  }

export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const PublicationByUrlFromEditorDocument = gql`
  query publicationByUrlFromEditor($url: String!) {
    publicationByUrl(url: $url) {
      __typename
      ... on Publication {
        uid
        title
        subtitle
        announceImage {
          id
          isFullWidth
          description
          author
          alt
          figureVersion {
            ...FigureVersionFragment
          }
        }
      }
      ... on ExternalPublication {
        url
        title
        subtitle
        announcingImageUrl
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`

/**
 * __usePublicationByUrlFromEditorQuery__
 *
 * To run a query within a React component, call `usePublicationByUrlFromEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationByUrlFromEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationByUrlFromEditorQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function usePublicationByUrlFromEditorQuery(
  baseOptions: Apollo.QueryHookOptions<PublicationByUrlFromEditorQuery, PublicationByUrlFromEditorQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationByUrlFromEditorQuery, PublicationByUrlFromEditorQueryVariables>(
    PublicationByUrlFromEditorDocument,
    options
  )
}
export function usePublicationByUrlFromEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationByUrlFromEditorQuery, PublicationByUrlFromEditorQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationByUrlFromEditorQuery, PublicationByUrlFromEditorQueryVariables>(
    PublicationByUrlFromEditorDocument,
    options
  )
}
export type PublicationByUrlFromEditorQueryHookResult = ReturnType<typeof usePublicationByUrlFromEditorQuery>
export type PublicationByUrlFromEditorLazyQueryHookResult = ReturnType<typeof usePublicationByUrlFromEditorLazyQuery>
export type PublicationByUrlFromEditorQueryResult = Apollo.QueryResult<
  PublicationByUrlFromEditorQuery,
  PublicationByUrlFromEditorQueryVariables
>
