import {styled} from 'styled-components'
import {Button} from '@mui/material'
import {blueColor, screenS} from '@theme/vars'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'

export const UploadPhotoButtonWrapper = styled(Button)`
  line-height: 20.8px;
  padding-bottom: 0px;
  min-height: 20.8px;
  width: 224px;
  text-decoration: underline;
  color: ${blueColor};
  margin-top: 12px;
  margin-bottom: -1px;

  @media screen and (max-width: ${screenS}) {
    width: 100%;
  }
`

export const ControlTooltipWrapper = styled(ControlTooltip)`
  & button {
    text-transform: none !important;
    letter-spacing: normal !important;

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
    }
  }
`
