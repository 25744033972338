import React, {FC} from 'react'

const ArrowUpIcon: FC<any> = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_8327_91915)'>
        <path
          d='M22.9596 32.3307L22.9596 19.6536L17.1367 25.4766L15.668 23.9974L24.0013 15.6641L32.3346 23.9974L30.8659 25.4661L25.043 19.6536L25.043 32.3307L22.9596 32.3307Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_8327_91915'>
          <rect width='30' height='30' fill='white' transform='matrix(-4.37114e-08 1 1 4.37114e-08 9 9)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowUpIcon
