import styled from 'styled-components'
import {GalleryBlot, Html, ImageBlot, OnlineTranslationDividerBlot, SideBar, Video} from '@components/TextEditor/Blots'
import {
  blueColor,
  grayColor,
  lightgrayColor,
  lightGrayColor2,
  lightRedColor,
  mainTextColor,
  secondTextColor,
  whiteColor
} from '../../../../@theme/vars'

export const QuillEditorWrapper = styled.div`
  max-width: 1266px;
  width: calc(100% - 20px);
  margin: auto;
  max-height: ${props => props.style?.maxHeight};
  overflow-y: auto;
  border: 1px solid #c4c4c4;
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 26px;
`

export const EditorWrapper = styled.div`
  position: relative;
  margin: auto;
  max-width: 780px;
  background-color: ${whiteColor};
  padding: 0px 10px;

  & .quill {
    display: flex;
    justify-content: center;
    font-size: 18px;

    &.fullscreen-editor {
      position: fixed;
      top: 43px;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1200;
      background-color: #edeef1;
      width: 100%;
      border-radius: 0;
      border: none;
      overflow-y: auto;
      overflow-x: auto;

      @media screen and (max-width: 850px) {
        top: 65px;
      }

      @media screen and (max-width: 763px) {
        top: 85px;
      }

      @media screen and (max-width: 436px) {
        top: 105px;
      }

      @media screen and (max-width: 415px) {
        top: 120px;
      }
    }
  }

  & .ql-editor {
    min-height: calc(100vh - 215px);
    padding: 24px 0;
    font-family: 'Segoe UI', sans-serif !important;
    outline: none;
    background-color: ${whiteColor};

    &.fullscreen {
      padding: 16px 24px;
      min-height: calc(100% - 43px);
      height: auto;
      width: 808px;

      @media screen and (max-width: 768px) {
        max-width: 808px;
        width: 100%;
      }
    }
    a {
      color: ${blueColor};
    }

    .yandex-rss-hidden {
      color: ${lightRedColor} !important;
    }
    iframe {
      width: 760px;
      height: 428px;
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;

      @media screen and (max-width: 768px) {
        max-width: 760px;
        width: 100%;
        height: auto;
      }
    }

    .${SideBar.className} {
      position: relative;

      @media screen and (max-width: 768px) {
        max-width: 760px !important;
        width: 100% !important;
        height: auto !important;
      }

      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
    }

    & .subHeader {
      min-width: 270px;
      width: 270px;
      height: 256px;
      float: left;
      & p {
        outline: none;
      }
      & span {
        display: flex;
        & .imageWrapper {
          margin-right: 20px;
          width: 250px;
          min-width: 250px;
        }
      }
    }
    & .html {
      max-height: 70px;
      contain: content;
      overflow-y: hidden;
      display: block;
      justify-content: flex-start;
      -webkit-box-pack: start;
      padding: 16px;
      border: 2px dashed ${lightgrayColor};
      width: 760px;
      font-size: 18px;
      color: ${grayColor};
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;

      @media screen and (max-width: 850px) {
        max-width: 760px;
        width: 100%;
        height: auto;
      }

      & .htmlButtons {
        display: flex;
        flex-direction: row-reverse;

        & .delete-item {
          margin: 6px 0px;
        }
      }
    }
  }

  & .ql-tooltip {
    position: absolute;
    border-radius: 3px;
    padding: 3px 8px;
    background: white;
    min-width: auto;
    display: inline-block;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', sans-serif;
    text-decoration: underline;
    color: ${blueColor};
    & input {
      display: none;
    }
  }

  & .ql-container {
    flex-direction: column;
    border-bottom-color: transparent;
    position: relative;
    max-width: 760px;
    width: 100%;
    /* white-space: pre-wrap;
        word-wrap: break-word; */
  }

  & .ql-hidden {
    visibility: hidden;
  }

  & .ql-clipboard {
    visibility: hidden;
  }

  & .ql-syntax {
    white-space: pre-wrap;
  }

  .${ImageBlot.className} {
    float: left;
    clear: left;

    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    margin-bottom: 1em;
    width: 250px;
    user-select: none;

    @media screen and (max-width: 768px) {
      float: none;
      max-width: 760px;
      width: 100%;
      height: auto;
    }
  }

  .${ImageBlot.className}[isFullWidth='true'] {
    width: 100%;
    float: none;

    @media screen and (max-width: 815px) {
      max-width: 760px;
      width: 100%;
      height: auto;
    }
  }

  .${ImageBlot.className}-description {
    margin-top: 6px;
    line-height: 16px;
    color: ${mainTextColor};
  }

  .${ImageBlot.className}-author {
    font-size: 12px;
    margin-top: 6px;
    line-height: 14px;
    color: ${secondTextColor};
  }

  .${OnlineTranslationDividerBlot.className} {
    display: block;
    height: 1px;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid ${lightGrayColor2};
  }

  .${GalleryBlot.className} {
    float: none;
    display: flex;
    flex-direction: column;
    max-width: 760px;
    width: 100%;
    height: auto;
  }

  & .change-item,
  & .delete-item {
    cursor: pointer;
    opacity: 0.8;
    border-radius: 80%;
    height: 26px;
    width: 26px;
    margin: 6px 6px 6px 0px;

    &:not(.video-options > .change-item, .video-options > .delete-item) {
      position: absolute;
      top: 0;
    }
  }

  & .change-item {
    right: 0px;
  }

  & .delete-item {
    right: 32px;
  }

  & .change-item:hover,
  & .delete-item:hover {
    opacity: 1;
  }

  & img:hover {
    cursor: pointer;
    opacity: 1;
  }

  .online_time {
    color: ${grayColor};
    margin-right: 16px;
  }

  * + p,
  * + .${Html.className}, * + .${Video.className}, * + .${ImageBlot.className}, * + .${GalleryBlot.className} {
    margin-top: 1em;
  }
  * + .${SideBar.className} {
    margin-top: 1em !important;
  }

  .${OnlineTranslationDividerBlot.className} + p {
    margin-top: 12px;
    font-size: 16px;
    line-height: 21px;
  }
`
