import styled from 'styled-components'

export const DeleteButtonWrapper = styled.div`
  display: block;
  position: absolute;
  left: 642px;
  top: 13px;
`

export const ConfirmationTextWrapper = styled.div`
  text-align: center;
  margin-bottom: 10px;
`
