import React, {FC} from 'react'
import {Box, Typography} from '@mui/material'
import {PUBLICATION_FORM_TITLES_CONSTS} from './PublicationFormTitles.consts'

export const TitleFillingRules: FC = () => {
  return (
    <div>
      <Typography>Правила заполнения заголовка:</Typography>
      <Typography>
        1. В заголовке может быть максимум {PUBLICATION_FORM_TITLES_CONSTS.titleMaxLength} символов для новости и{' '}
        {PUBLICATION_FORM_TITLES_CONSTS.articleTitleMaxLength} символов для статьи, включая служебные слова
      </Typography>
      <Typography>2. После точки могут указываться только служебные слова</Typography>
      <Box marginTop={'10px'}>Рекомендации:</Box>
      <Typography>
        1. В заголовке должно быть не более {PUBLICATION_FORM_TITLES_CONSTS.titleMaxWordsCount} слов, спец. слова и
        служебные слова не входят в это число
      </Typography>
    </div>
  )
}
