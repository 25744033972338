import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {blueColor, mainTextColor} from '../../../@theme/vars'

const TitleLink = styled(Link)`
  color: ${mainTextColor};

  &:hover {
    background-color: inherit;
    cursor: pointer;
    color: ${blueColor};
    text-decoration: underline;
  }
`
export default TitleLink
