import React, {FC, useEffect, useState} from 'react'
import ForbiddenIcon from '@components/UI/Icons/ForbiddenIcon'
import {useKeycloak} from '@react-keycloak/web'
import {PUBLICATION_LOADING_CONSTS} from './PublicationLoading.consts'
import {PublicationLoadingProps} from './PublicationLoadingProps'
import {
  PublicationLoadingBlockText,
  PublicationLoadingBlockWrapper,
  PublicationLoadingWrapper,
  RefreshIconWrapper
} from './PublicationLoading.styles'
import RefreshIcon from '@components/UI/Icons/RefreshIcon'

export const PublicationLoading: FC<PublicationLoadingProps> = ({publicationStatus, publicationLoading}) => {
  const {keycloak} = useKeycloak()

  const [status, setStatus] = useState(publicationStatus || '')
  const [loading, setLoading] = useState(publicationLoading || false)

  useEffect(() => {
    setStatus(publicationStatus)
  }, [publicationStatus])

  useEffect(() => {
    setLoading(publicationLoading)
  }, [publicationLoading])

  return (
    <PublicationLoadingWrapper>
      {(status === 'PUBLISHED' || status === 'UNPUBLISHED') && !loading && (
        <PublicationLoadingBlockWrapper>
          <ForbiddenIcon />
          <PublicationLoadingBlockText>{PUBLICATION_LOADING_CONSTS.autosaveIsDisabledMsg}</PublicationLoadingBlockText>
        </PublicationLoadingBlockWrapper>
      )}
      {loading && (
        <PublicationLoadingBlockWrapper>
          <RefreshIconWrapper>
            <RefreshIcon />
          </RefreshIconWrapper>
          <PublicationLoadingBlockText>{PUBLICATION_LOADING_CONSTS.savingMsg}</PublicationLoadingBlockText>
        </PublicationLoadingBlockWrapper>
      )}
    </PublicationLoadingWrapper>
  )
}
