import styled from 'styled-components'
import {mainTextColor, secondTextColor} from '../../../../../@theme/vars'

export const AnnounceImageWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    margin: auto;
  }

  & #announcePhoto {
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    & figure {
      width: 245px;

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 500px;
        margin-bottom: 24px;
      }

      & figcaption {
        & .image-description {
          margin-top: 6px;
          line-height: 16px;
          color: ${mainTextColor};
        }
        & .image-author {
          font-size: 12px;
          margin-top: 6px;
          line-height: 14px;
          color: ${secondTextColor};
        }
      }

      & .change-item {
        &:not(.video-options > .change-item, .video-options > .delete-item) {
          position: absolute;
        }
        opacity: 0.8;
        border-radius: 80%;
        height: 26px;
        width: 26px;
        margin: 6px 6px 6px 0px;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }

      & .change-item {
        margin-left: 214px;

        @media screen and (max-width: 768px) {
          margin-left: 455px;
        }

        @media screen and (max-width: 520px) {
          margin-left: calc(100% - 65px);
        }
      }
    }
  }
`
