const defaultFilters = {
  datePublishedFrom: '',
  datePublishedTo: '',
  viewsDateFrom: '',
  viewsDateTo: '',
  publicationUrl: '',
  authorUid: '',
  regionUid: '',
  rubricUids: [],
  subrubricUids: []
}

export {defaultFilters}
