import {AccordionSummary, AccordionDetails, InputBase} from '@mui/material'
import {lightgrayColor, grayColor, whiteColor} from '../../../@theme/vars'
import {styled} from 'styled-components'
import {StyledFormControl} from '@components/UI/styled'

export const AccordionWrapper = styled.div`
  width: 565px;
  margin-bottom: 12px;
  box-shadow: none;

  & .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
    min-height: 40px;
  }

  & .css-1086bdv-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
  }

  & .css-1c35hjw-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;

    & .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
      height: 40px;
      min-height: 40px;
      transition: none !important;
    }

    & .jgJJeu {
      transition: none !important;
    }
  }

  @media (max-width: 770px) {
    width: 100%;
  }
`

export const AccordionSummaryWrapper = styled(AccordionSummary)`
  border: 1px solid ${lightgrayColor};
  border-radius: 4px;
  padding: 0;
  color: ${grayColor};
  transition: none;
  background-color: transparent !important;

  & .css-o4b71y-MuiAccordionSummary-content {
    margin: 0;
  }
  & .css-eqpfi5-MuiAccordionSummary-content {
    margin: 0;
  }
`

export const AccordionDetailsWrapper = styled(AccordionDetails)`
  max-height: 400px;
  width: 496px;
  overflow-y: auto;
  border: 1px solid ${lightgrayColor};
  border-radius: 4px;
  position: absolute;
  z-index: 20;
  margin-top: 4px;
  background-color: ${whiteColor};
`

export const StyledFormControlWrapper = styled(StyledFormControl)`
  width: calc(100% - 10px);
  height: 40px;
  border: none;
  background-color: transparent !important;

  & .css-1rsuc8g-MuiInputBase-root {
    text-overflow: ellipsis;
  }
  & #rubrics-and-subrubrics-value {
    text-overflow: ellipsis;

    & ::before {
      border: none !important;
    }
  }

  @media (max-width: 770px) {
    display: flex;
    /* margin-bottom: 12px; */
  }
`

export const AccordionSummaryWrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 399px;

  & div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
`

export const OutlinedInputWrapper = styled(InputBase)`
  text-overflow: ellipsis;
  width: 100%;

  & .MuiOutlinedInput-root {
    border: none !important;
  }
`

export const SelectedInfoWrapper = styled.div`
  margin-top: 2px;
  color: ${grayColor};
  font-size: 12px;
  line-height: 14.4px;
`
