export const GALLERY_BLOCK_ITEM_CONSTS = {
  author: 'Автор',
  changePhotoPosition: 'Нажмите и перетащите блок вверх/вниз для изменения позиции фото в галерее',
  description: 'Описание',
  from: 'из',
  photo: 'Фото',
  removeFromGallery: 'Удалить из галереи',
  removeFromGalleryTooltip: 'Удалить фото из галереи',
  toChange: 'Изменить',
  viewSourceImage: 'Посмотреть исходник'
}
