import {mainTextColor, lightRedColor} from '../../../../@theme/vars'
import {SizeItem} from './SizeItem'

export const TOOLBAR_SIZE_CONSTS = {
    sizeItems: [
        {
            title: 'Большой',
            color: mainTextColor,
            size: '30px'
        } as SizeItem,
        {
            title: 'Крупный',
            color: mainTextColor,
            size: '20px'
        } as SizeItem,
        {
            title: 'Обычный',
            color: mainTextColor,
            size: '18px'
        } as SizeItem,
        {
            title: 'Скрыть в RSS',
            color: lightRedColor,
            size: 'textrssblock'
        } as SizeItem
    ],
    textSize: 'Размер текста'
}
