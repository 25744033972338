import {Button, TextField} from '@mui/material'
import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 12px;
  width: 100%;
`
export const TextAreaWrapper = styled.div``

export const StyledTextField = styled(TextField)`
  textarea:first-child {
    resize: vertical;
  }
`

export const ButtonsGroup = styled.div`
  display: flex;
  gap: 12px;
  max-width: 1266px;
`

export const StyledAddBtn = styled(Button)`
  width: 180px;
  height: 32px;
  padding: 11px, 18px, 11px, 18px;
  border-radius: 4px;
  border: 1px;
  gap: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`
export const StyledInvertBtn = styled(Button)`
  width: 180px;
  height: 32px;
  padding: 11px, 18px, 11px, 18px;
  border-radius: 4px;
  gap: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`
