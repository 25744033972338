import React, {FC, useCallback, useMemo, useState} from 'react'
import {useDepartmentsQuery, useStaffQuery} from '../gql/Staff.generated'
import InfiniteScroll from 'react-infinite-scroll-component'
import StaffItem from './Item/StaffItem'
import {User} from '@graphql/types'
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material'
import {StyledFormControl} from '../../UI/styled'
import {Search} from '@mui/icons-material'
import styled from 'styled-components'

const StyledTitle = styled(Typography)`
  margin-right: 24px;
  margin-top: 12px;
`

const StaffList: FC = () => {
  const [search, setSearch] = useState('')
  const [departmentUid, setDepartmentUid] = useState('')
  const [isShowFired, setIsShowFired] = useState(true)
  const [isEmptyList, setIsEmptyList] = useState(false)

  const {data, fetchMore, loading} = useStaffQuery({
    variables: {
      search: search,
      departmentUid: departmentUid || null,
      isFired: isShowFired ? null : false
    },
    onCompleted: data => setIsEmptyList(!data.staff?.edges.length),
    fetchPolicy: 'cache-and-network'
  })
  const departments = useDepartmentsQuery()

  const pageInfo = useMemo(() => data?.staff?.pageInfo, [data?.staff?.pageInfo])

  const onLoadMore = useCallback(() => {
    if (pageInfo?.hasNextPage && fetchMore) {
      void fetchMore({
        variables: {
          first: 25,
          after: pageInfo?.endCursor
        }
      })
    }
  }, [fetchMore, pageInfo])

  const staff = 'Сотрудники'

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='25px' flexWrap='wrap'>
        <Box display='flex' alignItems='center' flexWrap='wrap'>
          <StyledTitle variant='h1'>{staff}</StyledTitle>
          <StyledFormControl width='250px' marginright='24px' margintop='12px'>
            <InputLabel>Поиск по ФИО...</InputLabel>
            <OutlinedInput
              label='Поиск по ФИО...'
              value={search}
              onChange={event => setSearch(event.target.value as string)}
              endAdornment={<Search color='action' />}
            />
          </StyledFormControl>
          <StyledFormControl width='250px' margintop='12px'>
            <InputLabel>Отдел</InputLabel>
            <Select
              value={departmentUid}
              onChange={event => setDepartmentUid(event.target.value as string)}
              label='Отдел'
            >
              {departments.data?.departments?.map(department => (
                <MenuItem key={department?.uid} value={department?.uid}>
                  {department?.name}
                </MenuItem>
              ))}

              <MenuItem value=''>
                <em>Не выбрано</em>
              </MenuItem>
            </Select>
          </StyledFormControl>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={isShowFired}
                onChange={() => setIsShowFired(prevState => !prevState)}
              />
            }
            label='Показать уволенных сотрудников'
          />
        </Box>
      </Box>
      {loading && <LinearProgress />}
      {isEmptyList && <Typography variant='h1'>Не найдено</Typography>}
      <InfiniteScroll
        next={onLoadMore}
        hasMore={!!pageInfo?.hasNextPage}
        dataLength={data?.staff?.edges.length || 0}
        loader={<LinearProgress />}
      >
        <>
          {data?.staff?.edges.map(edge => (
            <StaffItem key={edge?.node?.uid} user={edge?.node as User} />
          ))}
        </>
      </InfiniteScroll>
    </Box>
  )
}

export default StaffList
