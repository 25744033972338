import {Query} from '@graphql/types'
import {UiCountBadAndNew} from 'entities/types/UiCountBadAndNew'

const getCountBadAndNewAdapterToClient = (response?: Query): UiCountBadAndNew => {
  if (!response) {
    return {
      countBadComments: 0,
      countNewComments: 0
    }
  }

  const {countBadComments, countNewComments} = response

  return {
    countBadComments: countBadComments || 0,
    countNewComments: countNewComments || 0
  }
}

export {getCountBadAndNewAdapterToClient}
