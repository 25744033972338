import styled from 'styled-components'

export const TimeLabelWrapper = styled.div`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 7px;
    width: 292px;
`
export const WeightInfoLabelWrapper = styled.div`
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    width: 292px;
`
export const TimeWrapper = styled.div`
    input[type='time']::-webkit-calendar-picker-indicator {
        background: none;
        display: none;
    }
`

export const WeightWrapper = styled.div`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`
