import styled, {css} from 'styled-components'
import {Button} from '@mui/material'
import {ButtonCheckedType} from './ButtonCheckedType'
import {mainTextColor} from '../../../../@theme/vars'

export const StyledButton = styled(Button)<ButtonCheckedType>`
  ${({$active}) =>
    $active
      ? css`
          color: #1964e7;
          font-weight: 400;
          text-transform: none;
        `
      : css`
          color: #707070;
          text-transform: none;
          font-weight: 400;
        `}
`

export const LinkWrapper = styled.a`
  color: ${mainTextColor};
`
