import React, {FC} from 'react'
import {PublicationSendMenuBtn, PublicationSendMenuWrapper} from './PublicationSendMenu.styles'
import ArrowRightIcon from '@components/UI/Icons/ArrowRightIcon'
import {PUBLICATION_SEND_MENU_CONSTS} from './PublicationSendMenu.consts'
import {Menu, MenuItem, Paper} from '@mui/material'

export const PublicationSendMenu: FC<any> = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <PublicationSendMenuWrapper>
      <Paper elevation={0}>
        <PublicationSendMenuBtn
          aria-controls={open ? 'publication-send-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={false}
          id='publication-send-menu-btn'
        >
          {PUBLICATION_SEND_MENU_CONSTS.sendMsg}&nbsp;
          <ArrowRightIcon />
        </PublicationSendMenuBtn>
        <Menu
          id='publication-send-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'publication-send-button'
          }}
        >
          <MenuItem disabled>{PUBLICATION_SEND_MENU_CONSTS.menu1}</MenuItem>
          <MenuItem disabled>{PUBLICATION_SEND_MENU_CONSTS.menu2}</MenuItem>
          <MenuItem disabled>{PUBLICATION_SEND_MENU_CONSTS.menu3}</MenuItem>
        </Menu>
      </Paper>
    </PublicationSendMenuWrapper>
  )
}
