import {Box, Typography} from '@mui/material'
import React, {FC} from 'react'
import styled from 'styled-components'
import {useFormContext, useWatch} from 'react-hook-form'
import {BannerType} from '../BannerContent'

const StyledImage = styled.img`
  min-width: 275px;
  height: 275px;
  margin-bottom: 8px;
`

const StyledTitle = styled(Typography)`
  z-index: 2;
  position: absolute;
  top: -11px;
  background: white;
  padding-right: 16px;
`

const BannerPreview: FC = () => {
  const {control} = useFormContext<BannerType>()
  const fields = useWatch({control})

  return (
    <Box ml={10} width='275px'>
      <Box display='flex' position='relative' borderTop='1px solid #c4c4c4' zIndex={1} height='16px'>
        <StyledTitle variant='subtitle1' color='textSecondary'>
          {fields.title}
        </StyledTitle>
      </Box>
      <StyledImage src={fields.figure?.latestVersion?.primaryProcessedImage?.url} />
      <Typography variant='h1' color='inherit'>
        {fields.description}
      </Typography>
    </Box>
  )
}

export default BannerPreview
