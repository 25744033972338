import {Popper, PopperPlacementType, Tooltip} from '@mui/material'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useStore} from '@stores/rootStoreContext'
import {PublicationStatus, Region} from '@graphql/types'
import {
  OverviewBlocksByUrlOrNullQuery,
  OverviewBlocksDraftByPublicationOrNullQuery
} from '@components/MainSelection/Overview/OverviewContent/gql/OverviewContent.generated'
import {MainPageModal} from '../MainPageModal/MainPageModal'
import {PlaceSelectBtn, PlaceSelectBtnSpan} from './RegionOverviewBlockPlace.styles'
import {REGIONS_OVERVIEW_BLOCKS_PLACE_CONSTS} from './RegionOverviewBlockPlace.consts'
import {RegionOverviewBlockPlaceProps} from './RegionOverviewBlockPlaceProps'

export const RegionOverviewBlockPlace: FC<RegionOverviewBlockPlaceProps> = observer(
  ({
    cantEdit,
    isOpenModal,
    loading,
    overviewBlocks,
    overviewBlocksDraft,
    region,
    setMainPlacePage,
    setSelectedOverviewBlockId,
    setSelectedRegionId,
    showPlaceBlockDraftIndex,
    showPlaceBlockIndex
  }) => {
    const {publicationStore} = useStore()
    const {publication} = publicationStore
    const [placement, setPlacement] = useState<PopperPlacementType | undefined>('bottom')
    const [anchorEl, setAnchorEl] = useState(null)
    const [isOpenMainPageModal, setIsOpenMainPageModal] = useState(false)
    const [selectedPosition, setSelectedPosition] = useState('')
    const [cantEditPublication, setCantEditPublication] = useState(false)
    const [curRegion, setCurRegion] = useState<Region>({} as Region)
    const [curOverviewBlocks, setCurOverviewBlocks] = useState<OverviewBlocksByUrlOrNullQuery>()
    const [curOverviewBlocksDraft, setCurOverviewBlocksDraft] = useState<OverviewBlocksDraftByPublicationOrNullQuery>()

    const toggleMainPageModal = useCallback(
      eventTarget => {
        setIsOpenMainPageModal(!isOpenMainPageModal)
        setAnchorEl(anchorEl ? null : eventTarget)
      },
      [isOpenMainPageModal, anchorEl]
    )

    const setMainPagePlace = useCallback(
      (eventTarget: any, region: Region, position: string) => {
        setSelectedPosition(position)
        setSelectedRegionId(region?.id)
        let overviewBlockId = ''

        if ([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)) {
          if (curOverviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull?.length) {
            overviewBlockId =
              curOverviewBlocksDraft?.overviewBlocksDraftByPublicationOrNull.find(item => item?.region.id === region.id)
                ?.id || ''
          }
        } else {
          if (curOverviewBlocks?.overviewBlocksByUrlOrNull?.length) {
            overviewBlockId =
              curOverviewBlocks?.overviewBlocksByUrlOrNull.find(item => item?.region.id === region.id)?.id || ''
          }
        }

        if (eventTarget?.id) {
          const eventTargetElement = document.getElementById(eventTarget?.id)
          const elementRect = eventTargetElement?.getBoundingClientRect()

          if (elementRect) {
            setPlacement(window.innerHeight - elementRect.top > 241 ? 'bottom-end' : 'top-end')
          }
        }
        setSelectedOverviewBlockId(overviewBlockId)
        toggleMainPageModal(eventTarget)
      },
      [
        curOverviewBlocks,
        curOverviewBlocksDraft,
        publication?.status,
        setSelectedRegionId,
        setSelectedOverviewBlockId,
        setSelectedPosition,
        toggleMainPageModal,
        setPlacement
      ]
    )

    useEffect(() => {
      setCurRegion(region)
    }, [region])

    useEffect(() => {
      setCantEditPublication(cantEdit || loading)
    }, [cantEdit, loading])

    useEffect(() => {
      setIsOpenMainPageModal(isOpenModal)
      if (!isOpenModal) {
        setAnchorEl(null)
      }
    }, [isOpenModal])

    useEffect(() => {
      setCurOverviewBlocks(overviewBlocks)
    }, [overviewBlocks])

    useEffect(() => {
      setCurOverviewBlocksDraft(overviewBlocksDraft)
    }, [overviewBlocksDraft])

    return (
      <>
        <Tooltip title={REGIONS_OVERVIEW_BLOCKS_PLACE_CONSTS.selectPositionMsg}>
          <PlaceSelectBtn
            variant='outlined'
            id={`region-with-overview-blocks-btn-${curRegion.id}`}
            color='secondary'
            disabled={cantEditPublication}
            onClick={event => {
              !cantEditPublication &&
                setMainPagePlace(event.currentTarget, curRegion as Region, curRegion.position.toString())
            }}
            style={{
              borderColor: [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
                ? '#C4C4C4'
                : showPlaceBlockIndex(region.id)?.isDirty
                ? '#C4C4C4'
                : '#5B97FF'
            }}
          >
            <PlaceSelectBtnSpan
              style={{
                color: [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
                  ? '#222'
                  : showPlaceBlockIndex(region.id)?.isDirty
                  ? '#222'
                  : '#5B97FF'
              }}
            >
              {[PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) ? (
                <span>{showPlaceBlockDraftIndex(region.id)}</span>
              ) : (
                <span>{showPlaceBlockIndex(region.id)?.position}</span>
              )}
            </PlaceSelectBtnSpan>
          </PlaceSelectBtn>
        </Tooltip>
        <Popper
          id={`region-select-popper-${region.id}`}
          open={isOpenMainPageModal}
          anchorEl={anchorEl}
          onBlur={toggleMainPageModal}
          onMouseLeave={toggleMainPageModal}
          disablePortal={false}
          placement={placement}
          style={{
            zIndex: '90'
          }}
          modifiers={[
            {
              name: 'flip',
              enabled: true,
              options: {
                fallbackPlacements: ['top', 'bottom']
              }
            }
          ]}
        >
          {isOpenMainPageModal && (
            <MainPageModal
              selectedPosition={selectedPosition}
              setMainPlacePage={setMainPlacePage}
              onClose={toggleMainPageModal}
            />
          )}
        </Popper>
      </>
    )
  }
)
