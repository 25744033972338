import {FormHelperText, InputLabel, OutlinedInput, Typography} from '@mui/material'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {StyledFormControl} from '@components/UI/styled'
import {redColor} from '@theme/vars'
import {IMAGE_EDITOR_MODAL_CONSTS} from '@components/UI/ImageEditor/ImageEditorModal/ImageEditorModal.consts'
import {useStore} from '@stores/rootStoreContext'
import {stringTypographRunner} from '@components/TextEditor/Editor/utils/stringTypographRunner'
import {GalleryBlockItemDescriptionProps} from './GalleryBlockItemDescriptionProps'

const GalleryBlockItemDescription: FC<GalleryBlockItemDescriptionProps> = ({ind, descriptionVal, setError}) => {
  const {publicationStore} = useStore()
  const {galleryModalStore} = publicationStore
  const {galleryDetail, setGalleryImageById} = galleryModalStore
  const [description, setDescription] = useState(descriptionVal || '')
  const [descriptionDirty, setDescriptionDirty] = useState(false)
  const [descriptionErrorText, setDescriptionErrorText] = useState('')

  useEffect(() => {
    setDescription(descriptionVal)
  }, [descriptionVal])

  useEffect(() => {
    if (!description) {
      setError(IMAGE_EDITOR_MODAL_CONSTS.isEmpty)
      if (descriptionDirty) {
        setDescriptionErrorText(IMAGE_EDITOR_MODAL_CONSTS.fieldIsRequiredMsg)
      }
      return
    }
    if (description?.length > IMAGE_EDITOR_MODAL_CONSTS.maxDescriptionLength) {
      setDescriptionErrorText(IMAGE_EDITOR_MODAL_CONSTS.characterLimitReachedMsg)
      setError(IMAGE_EDITOR_MODAL_CONSTS.characterLimitReachedMsg)
      return
    }
    setDescriptionErrorText('')
    setError('')
  }, [description, descriptionDirty, setDescriptionErrorText, setError])

  const setDescriptionValue = useCallback(
    (value: string) => {
      const fieldValue = stringTypographRunner(value)
      setDescription(fieldValue)
      setDescriptionDirty(true)
      const curImage = galleryDetail.galleryImages[ind]

      if (curImage) {
        curImage.description = fieldValue
        setGalleryImageById(curImage)
      }
    },
    [ind, galleryDetail, setGalleryImageById]
  )

  return (
    <StyledFormControl width='100%' required error={!description && descriptionDirty} margintop='8px'>
      <div style={{flexDirection: 'row'}}>
        <InputLabel
          style={{
            width: '70%'
          }}
        >
          <Typography variant='text12R'>{IMAGE_EDITOR_MODAL_CONSTS.descriptionLabelMsg}</Typography>
        </InputLabel>
        <OutlinedInput
          id={`gallery-block-item-description_${ind}`}
          label={IMAGE_EDITOR_MODAL_CONSTS.descriptionLabelMsg}
          error={!!descriptionErrorText}
          multiline
          style={{
            float: 'left',
            width: '100%',
            minHeight: '42px'
          }}
          onChange={e => {
            setDescriptionValue(e.target.value)
            setDescriptionDirty(true)
          }}
          value={description}
          required
        />
      </div>
      {!!descriptionErrorText && (
        <FormHelperText
          style={{
            color: redColor
          }}
        >
          {descriptionErrorText}
        </FormHelperText>
      )}
    </StyledFormControl>
  )
}

export default observer(GalleryBlockItemDescription)
