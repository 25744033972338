import ky, {HTTPError} from 'ky'
import {toast} from 'react-toastify'
import {NerDialogRequest} from './NnApi/NnAPI.types'

let lastRequestData = null

const handleBeforeRequest = (request: Request, options: any) => {
  lastRequestData = {
    url: request.url,
    options: {
      method: request.method,
      headers: request.headers,
      body: options.body,
      credentials: request.credentials,
      signal: request.signal,
      timeout: options.timeout
      // Сохраните другие необходимые параметры
    }
  }
}

export const fetchInstanse = ky.create({
  prefixUrl: process.env.REACT_APP_NN,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 100000
})

export const fetchInstanseForDialogNer = ky.create({
  prefixUrl: process.env.REACT_APP_NN,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 200000,
  hooks: {
    beforeRequest: [handleBeforeRequest]
  }
})

export const handleRequest = async <T,>(url: string, options: any): Promise<T | null | undefined> => {
  try {
    const response = await fetchInstanse(url, options)

    if (!response.ok) {
      const statusCode = response.status
      const statusText = response.statusText || 'Неизвестная ошибка'

      toast.error(`Ошибка: ${statusCode} - ${statusText}`, {
        containerId: 'error',
        autoClose: false,
        closeButton: true
      })

      return null
    }
    if (response.status === 204) {
      return undefined
    }
    return await response.json()
  } catch (error) {
    if (error instanceof HTTPError && error.response) {
      // HTTP ошибки, которые возвращают статус ответа
      const statusCode = error.response.status
      const statusText = error.response.statusText || 'Неизвестная ошибка'

      toast.error(`Ошибка HTTP: ${statusCode} - ${statusText}`, {
        containerId: 'error',
        autoClose: false,
        closeButton: true
      })
    } else {
      // Сетевые ошибки, такие как 504 или сбой соединения
      const errorMessage = error.message.includes('Failed to fetch')
        ? 'Ошибка сети: сервер не отвечает (возможно, ошибка 504)'
        : `Сетевая ошибка: ${error.message}`

      toast.error(errorMessage, {
        containerId: 'error',
        autoClose: false,
        closeButton: true
      })
    }
    return null
  }
}

// Функция для повторения последнего запроса
export const retryLastRequest = async (
  signal: AbortSignal,
  data?: NerDialogRequest | undefined
): Promise<string | null> => {
  if (!lastRequestData) {
    console.log('Нет запроса')
    return null
  }

  const {url, options} = lastRequestData
  let response

  if (data) {
    response = await ky(url, {
      ...options,
      json: data,
      signal,
      hooks: {
        beforeRequest: [handleBeforeRequest]
      }
    })
  } else {
    response = await ky(url, {
      ...options,
      signal,
      hooks: {
        beforeRequest: [handleBeforeRequest]
      }
    })
  }

  if (!response.ok) {
    console.log(response.status)
    return null
  }

  return response.text()
}
