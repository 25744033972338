export enum ValidationStatus {
  Error = 'error',
  Warning = 'warning',
  Default = 'default'
}

export type ValidationData = {
  status: ValidationStatus
  message: string
}
