import {PublicationsSort} from '@graphql/types'
import {SortOrder} from '../../../../types/SortOrder'

export const getPublicationsSortByFieldName = (fieldName: string, sortDirection: SortOrder): PublicationsSort => {
  switch (fieldName) {
    case 'publishedAtDate': {
      return sortDirection === SortOrder.Asc ? PublicationsSort.PublishedAtAsc : PublicationsSort.PublishedAtDesc
    }
    case 'publishedAt': {
      return sortDirection === SortOrder.Asc ? PublicationsSort.PublishedAtAsc : PublicationsSort.PublishedAtDesc
    }
    case 'title': {
      return sortDirection === SortOrder.Asc ? PublicationsSort.TitleAtAsc : PublicationsSort.TitleAtDesc
    }
    case 'seoTitle': {
      return sortDirection === SortOrder.Asc ? PublicationsSort.SeoTitleAtAsc : PublicationsSort.SeoTitleAtDesc
    }
    case 'rubricTitle': {
      return sortDirection === SortOrder.Asc ? PublicationsSort.RubricAtAsc : PublicationsSort.RubricAtDesc
    }
    case 'authors': {
      return sortDirection === SortOrder.Asc ? PublicationsSort.AuthorAtAsc : PublicationsSort.AuthorAtDesc
    }
    default: {
      return PublicationsSort.PublishedAtDesc
    }
  }
}
