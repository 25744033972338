export type IBreakingNewsModalFieldEntries = {
  name: string
  maxLength: number
  firstSentenceMaxLength?: number
  titleMaxWordsCount?: number
}

export enum EBreakingNewsModalField {
  Title = 'title'
}

export const BreakingNewsModalFieldEntries: Record<EBreakingNewsModalField, IBreakingNewsModalFieldEntries> = {
  [EBreakingNewsModalField.Title]: {
    name: 'Заголовок',
    maxLength: 110,
    firstSentenceMaxLength: 65,
    titleMaxWordsCount: 15
  }
}
