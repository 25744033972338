import React, {FC} from 'react'

const UraLogoIcon: FC<any> = () => {
    return (
        <svg
            width='36'
            height='36'
            viewBox='0 0 36 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='36' height='36' rx='18' fill='white' />
            <path
                d='M7.72325 16.9198C5.49077 16.9198 3.81641 16.0619 3.81641 13.848V8.42383H6.31466V13.765C6.31466 14.4845 6.84621 14.9273 7.77642 14.9273C8.65346 14.9273 9.23815 14.4568 9.23815 13.765V8.42383H11.7364V14.0417C11.7098 15.9789 10.0355 16.9198 7.72325 16.9198Z'
                fill='#1964E7'
            />
            <path
                d='M18.1052 10.7792C17.9741 10.4988 17.7381 10.4146 17.3972 10.4146H15.3255V12.1531H17.4496C17.7643 12.1531 18.0003 12.069 18.1052 11.8727C18.1577 11.7325 18.1839 11.5082 18.1839 11.3119C18.1839 11.1156 18.1577 10.8913 18.1052 10.7792ZM20.2293 12.7139C19.9671 13.1625 19.6262 13.4429 19.2328 13.527L21.0947 16.9198H18.3675L16.9514 14.0598H15.2993V16.9198H12.8867V8.42383H17.9479C19.023 8.42383 19.7048 8.7603 20.1244 9.32109C20.4391 9.74169 20.5965 10.3305 20.5965 11.1156C20.5702 11.7886 20.4391 12.3213 20.2293 12.7139Z'
                fill='#1964E7'
            />
            <path
                d='M25.6439 11.3119L25.8044 10.5829L25.9649 11.2839L26.6334 13.3027H24.9219L25.6439 11.3119ZM24.2801 8.42383L21.0977 16.9198H23.6917L24.3335 15.1253H27.2485L27.8368 16.9198H30.4577L27.4625 8.42383H24.2801Z'
                fill='#1964E7'
            />
            <path
                d='M33.6953 14.1841H31.0312V16.9201H33.6953V14.1841Z'
                fill='#1964E7'
            />
            <path
                d='M9.02518 21.5072C8.89362 21.2268 8.65696 21.1427 8.31487 21.1427H6.23681V22.8811H8.36742C8.6834 22.8811 8.92006 22.797 9.02518 22.6007C9.07773 22.4605 9.10417 22.2362 9.10417 22.0399C9.10417 21.8437 9.10417 21.6193 9.02518 21.5072ZM11.1561 23.4419C10.893 23.8906 10.5512 24.1709 10.1566 24.2551L12.0244 27.6479H9.26216L7.84154 24.7878H6.18394V27.6479H3.81641V19.1519H8.89363C9.97247 19.1519 10.6563 19.4883 11.0771 20.0491C11.3928 20.4697 11.5508 21.0585 11.5508 21.8437C11.5243 22.5166 11.3667 23.0494 11.1561 23.4419Z'
                fill='#1964E7'
            />
            <path
                d='M16.7581 27.6479C14.546 27.6479 12.8867 26.79 12.8867 24.576V19.1519H15.3623V24.493C15.3623 25.2125 15.8891 25.6553 16.8109 25.6553C17.6799 25.6553 18.2592 25.1848 18.2592 24.493V19.1519H20.7347V24.7697C20.7085 26.7069 19.0492 27.6479 16.7581 27.6479Z'
                fill='#1964E7'
            />
        </svg>
    )
}

export default UraLogoIcon
