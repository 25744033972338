import {createContext} from 'react'
import {GridSettings} from './grid-types'
import {StateType} from '../responder'

export interface GridItemContextType {
    top: number;
    disableDrag: boolean;
    endTraverse: () => void;
    mountWithTraverseTarget?: [number, number];
    left: number;
    i: number;
    onMove: (state: StateType, x: number, y: number) => void;
    onEnd: (state: StateType, x: number, y: number) => void;
    onStart: () => void;
    grid: GridSettings;
    dragging: boolean;
}

export const GridItemContext = createContext<GridItemContextType | null>(
    null
)
