import {Quill} from 'react-quill'

const BlockEmbed = Quill.import('blots/block/embed')

interface IOnlineTranslationBlot {
  createAt: string
}

export default class OnlineTranslationDividerBlot extends BlockEmbed {
  constructor(node: HTMLElement) {
    super(node)
  }

  static create({createAt}: IOnlineTranslationBlot): HTMLElement {
    const node: HTMLElement = super.create()
    node.setAttribute('create-at', createAt)
    return node
  }

  static value(node: HTMLElement): IOnlineTranslationBlot {
    return {
      createAt: node.getAttribute('create-at') || ''
    }
  }
}

OnlineTranslationDividerBlot.tagName = 'hr'
OnlineTranslationDividerBlot.className = 'online-divider'
OnlineTranslationDividerBlot.blotName = 'online-divider-blot'
