import styled from 'styled-components'
import {lightGrayColor2, mainTextColor} from '../../../../@theme/vars'

export const THeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* position: fixed; */
  /* border-top: 1px solid ${lightGrayColor2};
  border-left: 1px solid ${lightGrayColor2}; */
`

export const ThWrapper = styled.div`
  background-color: #f1f6ff;
  border-right: 1px solid ${lightGrayColor2};
  border-bottom: 1px solid ${lightGrayColor2};
  color: ${mainTextColor};
  font-size: 12px;
  font-weight: 500;
`

export const FirstColumnWrapper = styled.div`
  height: 100%;
  padding-left: 4px;
  display: flex;
  align-items: flex-end;
`
