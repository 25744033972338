export const TOOLBAR_CONSTS = {
  boldfaceMsg: 'Полужирный (Ctrl + B)',
  bulletedListMsg: 'Маркированный список',
  cancelFormattingMsg: 'Очистка форматирования',
  cancelMsg: 'Отмена (Ctrl + Z)',
  italicMsg: 'Курсив (Ctrl + I)',
  linkMsg: 'Ссылка',
  numberedListMsg: 'Нумерованный список',
  pasteHtmlMsg: 'Вставка HTML',
  pasteImageMsg: 'Вставка фото',
  pasteVideoMsg: 'Вставка видео',
  pasteSidebarMsg: 'Вставить врезку',
  previewMsg: 'Предпросмотр',
  removeLinkMsg: 'Удалить ссылку',
  repeatMsg: 'Повторить (Ctrl + Shift + Z)',
  takeoutForTextMsg: 'Вынос текста',
  textMsg: 'текст',
  typographMsg: 'Сделать, чтобы все было красиво (Ctrl + K)',
  fullScreenMsg: 'Открыть на весь экран',
  exitFullScreenMsg: 'Нажмите для выхода из полноэкранного режима'
}
