import {FC, memo} from 'react'
import {Button, Divider, IconButton, ListItemButton} from '@mui/material'

import DeleteIcon from '../../UI/Icons/DeleteIcon'
import {LIST_LABELS} from '../ListSameNames.consts'
import {ListItemsContainer, UIList, UIListItem, UIListItemText, UIListSecondText} from './List.styles'
import ListProps from './ListProps'

const List: FC<ListProps> = ({listItems, isLoading, nextPage, addNewUsers, onClickListItem, handleDelete}) => {
  if (!listItems) {
    return <div>{LIST_LABELS.noTwins}</div>
  }
  return (
    <>
      <UIList>
        {listItems.map(item => (
          <div key={item.id} data-testid='list-item'>
            <ListItemButton
              data-testid='list-item-button'
              onClick={() => onClickListItem(item.id)}
              disabled={isLoading}
            >
              <UIListItem
                secondaryAction={
                  <IconButton onClick={e => handleDelete(e, item.id)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemsContainer>
                  <UIListItemText>
                    {item.first_name ?? ''} {item.last_name}
                  </UIListItemText>
                  <UIListSecondText>{item.description}</UIListSecondText>
                </ListItemsContainer>
              </UIListItem>
            </ListItemButton>
            <Divider />
          </div>
        ))}
      </UIList>
      {nextPage && <Button onClick={() => addNewUsers(nextPage)}>{LIST_LABELS.loadTwins}</Button>}
    </>
  )
}

export default memo(List)
