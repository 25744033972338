import AgentCheckStore from './agentCheckStore/agentCheckStore'
import CommentsStore from './commentsStore/commentsStore'
import CropperStore from './cropperStore/cropperStore'
import EditorStore from './editorStore/editorStore'
import DialogStore from './nnStore/nnStore'
import PublicationStore from './publicationStore/publicationStore'
import RegionsWithOverviewBlocksStore from './regionsWithOverviewBlocksStore/regionsWithOverviewBlocksStore'
import ReportsListStore from './reportsListStore/reportsListStore'
import TableStore from './tableStore/tableStore'
import TopNewsStore from './topNewsStore/topNewsStore'

export class RootStore {
  commentsStore: CommentsStore
  cropperStore: CropperStore
  editorStore: EditorStore
  publicationStore: PublicationStore
  regionsWithOverviewBlocksStore: RegionsWithOverviewBlocksStore
  reportsListStore: ReportsListStore
  topNewsStore: TopNewsStore
  agentCheckStore: AgentCheckStore
  dialogStore: DialogStore
  tableStore: TableStore

  constructor() {
    this.commentsStore = new CommentsStore()
    this.cropperStore = new CropperStore()
    this.editorStore = new EditorStore()
    this.publicationStore = new PublicationStore()
    this.regionsWithOverviewBlocksStore = new RegionsWithOverviewBlocksStore()
    this.reportsListStore = new ReportsListStore()
    this.topNewsStore = new TopNewsStore()
    this.agentCheckStore = new AgentCheckStore()
    this.dialogStore = new DialogStore()
    this.tableStore = new TableStore()
  }
}

const rootStore = new RootStore()

export default rootStore
