import React, {FC, cloneElement, forwardRef, useEffect, useState} from 'react'
import {Box, Button, Fade, Typography, styled} from '@mui/material'
import {Arrow, StylePopper, Wrapper} from '../ConfirmationButton/ConfirmationButton.styles'
import {HoverWrapper} from './ConfirmationPopover.styles'
import {ConfirmationPopoverProps} from './ConfirmationPopoverProps'

type PopperPlacementType = 'top'

/**
 * Компонент popper-a с подтверждением действий
 * @param param0
 * @returns
 */
const ConfirmationPopover: FC<ConfirmationPopoverProps> = ({
  id,
  focusedElementId,
  textPopper,
  textPopperStatus,
  isActive,
  actionPopper,
  setFocusedElementId,
  children,
  hasPopover = true
}) => {
  const [clickPopperOpen, setClickPopperOpen] = React.useState(false)
  const [hoverPopperOpen, setHoverPopperOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null)
  const [placement, setPlacement] = React.useState<PopperPlacementType>()

  const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setClickPopperOpen(!clickPopperOpen)
    setHoverPopperOpen(false)
  }

  const handleHoverPopperOpen = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLElement>) => {
    setCurFocusedElementId(id)
    setFocusedElementId(id)
    setHoverPopperOpen(true)
    setAnchorEl(event.currentTarget)
    setPlacement(newPlacement)
  }

  const handleHoverPopperClose = () => {
    setHoverPopperOpen(false)
    setCurFocusedElementId('')
    setTimeout(() => {
      setClickPopperOpen(false)
    }, 300)
  }
  const handleAction = (action: boolean) => {
    actionPopper && actionPopper(action)
    setClickPopperOpen(false)
  }

  const handlePopoverClose = () => {
    setClickPopperOpen(false)
    setCurFocusedElementId('')
  }

  const [curFocusedElementId, setCurFocusedElementId] = useState('')

  useEffect(() => {
    setCurFocusedElementId(focusedElementId)

    if (focusedElementId !== id) {
      setHoverPopperOpen(false)
      setCurFocusedElementId('')
    }
  }, [id, focusedElementId, setCurFocusedElementId, setHoverPopperOpen])
  const updatedChildren = cloneElement(children, {
    onClick: handleClickPopper,
    onMouseEnter: handleHoverPopperOpen('top'),
    onMouseLeave: handleHoverPopperClose,
    onBlur: handlePopoverClose,
    'aria-describedby': clickPopperOpen ? `transition-popper-${id}` : undefined,
    type: 'button'
  })

  return (
    <>
      {updatedChildren}
      {hasPopover && (
        <>
          <StylePopper
            id={`transition-popper-${id}`}
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef
                }
              }
            ]}
            open={clickPopperOpen}
            anchorEl={anchorEl}
            placement={'top'}
            sx={{zIndex: 1200}}
            transition
          >
            {({TransitionProps}) => (
              <Fade {...TransitionProps} timeout={350}>
                <div ref={arrowRef}>
                  <Wrapper>
                    <Typography style={{display: 'flex', flexDirection: 'column'}}>
                      {!isActive ? (
                        <>
                          <Button onClick={() => handleAction(true)}> {textPopperStatus}</Button>
                        </>
                      ) : (
                        <>
                          {textPopperStatus}
                          <Button onClick={() => handleAction(false)}>Отменить</Button>
                        </>
                      )}
                    </Typography>
                  </Wrapper>
                  <Arrow ref={setArrowRef} />
                </div>
              </Fade>
            )}
          </StylePopper>
        </>
      )}
      <StylePopper id={`hover-popper-${id}`} open={hoverPopperOpen} anchorEl={anchorEl} placement={placement}>
        <HoverWrapper>{textPopper}</HoverWrapper>
      </StylePopper>
    </>
  )
}

export default ConfirmationPopover
