import React, {FC} from 'react'

const SortIcon: FC<any> = () => {
  const maskUuid = '123'
  return (
    <svg width='16' height='22' viewBox='0 0 16 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2 11.7773L8 17.7773L14 11.7773H2Z' fill='#707070' />
      <path d='M14 10.2227L8 4.22266L2 10.2227L14 10.2227Z' fill='#707070' />
    </svg>
  )
}

export default SortIcon
