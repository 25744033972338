export const REPORTS_LIST_TABLE_CONSTS = {
  authors: 'авторы',
  date: 'дата',
  fromSocialNetworks: 'из соцсетей кол-во',
  fromSocialNetworksPercent: 'из соцсетей %',
  restoreAll: 'Вернуть все',
  restoreAllColumns: 'Вернуть все колонки',
  restoreColumn: 'Вернуть колонку',
  rubricAndSubrubric: 'рубрика, подрубрика',
  seoTitle: 'SEO-заголовок',
  time: 'время',
  title: 'название',
  uniqueUsersCount: 'кол-во уникальных посетителей',
  viewsCount: 'кол-во просмотров',
  withoutRecommendedSystems: 'без рекоменд. систем и Дзен Новостей'
}
