import React, {FC, useCallback, useEffect, useState} from 'react'
import {format, parseISO} from 'date-fns'
import {observer} from 'mobx-react-lite'
import {CircularProgress, IconButton, Menu, Paper} from '@mui/material'
import {InfoOutlined} from '@mui/icons-material'
import {toast} from 'react-toastify'
import {useStore} from '@stores/rootStoreContext'
import CloseIcon from '@components/UI/Icons/CloseIcon'
import {useConvertPublicationToArticleMutation} from '../gql/PublicationEditor.generated'
import {PUBLICATION_INFO_CONSTS} from './PublicationInfo.consts'
import {
  PublicationCheckedByBildEditor,
  PublicationInfoDevider,
  PublicationInfoLink,
  PublicationInfoMakeArticleBtn,
  PublicationInfoPaper,
  PublicationInfoPaperClose,
  PublicationInfoPaperRow,
  PublicationInfoWrapper,
  PublicationNotCheckedByBildEditor
} from './PublicationInfo.styles'

export const PublicationInfo: FC<any> = observer(() => {
  const {publicationStore} = useStore()
  const {publication, setPublication} = publicationStore
  const [id, setId] = useState(publication?.id || '')
  const [creatorName, setCreatorName] = useState(publication?.creator?.username || '')
  const [createdAt, setCreatedAt] = useState(format(parseISO(publication?.createdAt), 'dd.MM.yyyy HH:mm:ss') || '')
  const [convertPublicationToArticle, {loading: convertPublicationToArticleLoading}] =
    useConvertPublicationToArticleMutation()

  useEffect(() => {
    setId(publication?.id)
    setCreatorName(publication?.creator?.username || '')
    setCreatedAt(format(parseISO(publication?.createdAt), 'dd.MM.yyyy HH:mm:ss') || '')
  }, [publication])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const convertPublicationToArticleAction = useCallback(async () => {
    try {
      const newPublicationShema = await convertPublicationToArticle({
        variables: {
          data: {
            publicationUid: publication?.uid
          }
        }
      })

      if (newPublicationShema.data?.convertPublicationToArticle?.publication?.status) {
        const newPublication = publication
        newPublication.status = newPublicationShema.data?.convertPublicationToArticle?.publication?.status
        setPublication(newPublication)
      }

      toast.success(PUBLICATION_INFO_CONSTS.savedMsg, {
        autoClose: false,
        hideProgressBar: true,
        containerId: 'success'
      })
    } catch {
      console.error(PUBLICATION_INFO_CONSTS.errorMsg)
    }
  }, [publication, convertPublicationToArticle, setPublication])

  return (
    <PublicationInfoWrapper>
      <Paper elevation={0}>
        <IconButton
          id='publication-info-button'
          aria-controls={open ? 'publication-info-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            float: 'right',
            padding: '3px'
          }}
          disabled={false}
        >
          <InfoOutlined
            style={{
              color: '#1964e7',
              transform: 'scale(0.8)'
            }}
          />
        </IconButton>
        <Menu
          id='publication-info-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'publication-info-button'
          }}
        >
          <PublicationInfoPaper>
            <PublicationInfoPaperClose onClick={handleClose}>
              <CloseIcon />
            </PublicationInfoPaperClose>
            <PublicationInfoPaperRow>
              <b>uid:</b>
              <span>&nbsp;{creatorName}</span>
            </PublicationInfoPaperRow>
            <PublicationInfoPaperRow>
              <b>{PUBLICATION_INFO_CONSTS.createdDateMsg}:</b>
              <span>&nbsp;{createdAt}</span>
            </PublicationInfoPaperRow>
            {publication.checkingPhotoEditor ? (
              <PublicationCheckedByBildEditor>
                {PUBLICATION_INFO_CONSTS.publicationCheckedByBildEditorMsg}
              </PublicationCheckedByBildEditor>
            ) : (
              <PublicationNotCheckedByBildEditor>
                {PUBLICATION_INFO_CONSTS.publicationNotCheckedByBildEditorMsg}
              </PublicationNotCheckedByBildEditor>
            )}
            <PublicationInfoDevider />
            <PublicationInfoLink
              aria-disabled
              style={{
                color: '#cecece'
              }}
            >
              {PUBLICATION_INFO_CONSTS.changeHistoryMsg}
            </PublicationInfoLink>
            <PublicationInfoLink
              aria-disabled
              style={{
                color: '#cecece'
              }}
            >
              {PUBLICATION_INFO_CONSTS.commentsMsg}
            </PublicationInfoLink>
            {publication?.typeSlug === 'news' && (
              <PublicationInfoMakeArticleBtn
                onClick={convertPublicationToArticleAction}
                disabled={convertPublicationToArticleLoading}
              >
                {convertPublicationToArticleLoading ? (
                  <CircularProgress size='1rem' />
                ) : (
                  <span>{PUBLICATION_INFO_CONSTS.makeAnArticleMsg}</span>
                )}
              </PublicationInfoMakeArticleBtn>
            )}
          </PublicationInfoPaper>
        </Menu>
      </Paper>
    </PublicationInfoWrapper>
  )
})
