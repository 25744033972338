import {FC, useCallback} from 'react'
import {toast} from 'react-toastify'
import {useStore} from '@stores/rootStoreContext'
import {getQuillEditor} from '@utils/getQuillEditor'
import {fetchGenerateBack} from '@fetch/NnAPI'
import {Button, MenuItem} from '../ToolbarNn.styles'
import {TOOLBAR_BUTTONS_TITLES} from '../ToolbarNn.consts'

type Props = {
  editorRef: React.MutableRefObject<any>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
}

const WriteBackButton: FC<Props> = ({editorRef, setIsLoading, handleClose}) => {
  const {publicationStore} = useStore()

  const responseForBack = useCallback(
    (data: any) => {
      const quill = getQuillEditor(editorRef)

      if (quill) {
        // Получаем значение по ключу text
        const text = data.text

        if (text === TOOLBAR_BUTTONS_TITLES.back.backNotFound) {
          toast.success(TOOLBAR_BUTTONS_TITLES.back.backNotFound, {
            containerId: 'success',
            autoClose: 10000,
            hideProgressBar: true
          })
        } else if (text === TOOLBAR_BUTTONS_TITLES.back.publicationNotFound) {
          toast.success(TOOLBAR_BUTTONS_TITLES.back.backNotFound, {
            containerId: 'success',
            autoClose: 10000,
            hideProgressBar: true
          })
        } else {
          // Получаем текущую длину контента
          const currentLength = quill.getLength()

          // Получаем последний символ
          const lastChar = quill.getText(currentLength)

          // Определяем позицию вставки текста
          let insertPosition
          if (lastChar === '\n') {
            insertPosition = currentLength - 4
          } else {
            insertPosition = currentLength + 1
          }

          // Вставляем текст в указанную позицию
          quill.insertText(insertPosition, text.toString(), 'user')

          // Устанавливаем курсор в конец вставленного текста
          const insertedLength = text.length
          quill.setSelection(insertPosition + insertedLength, 0)

          // Вставка слова с ссылкой
          const word = data.word
          const url = data.url
          const index = text.indexOf(word) - 1

          if (index !== -1) {
            // Вставляем слово с ссылкой в нужную позицию
            quill.insertText(insertPosition + index, word, {link: url}, 'user')
            // Удаляем старое слово (после вставки, чтобы не сдвигать текст)
            quill.deleteText(insertPosition + index + word.length, word.length, 'user')
          }
        }

        setIsLoading(false)
      }
    },
    [editorRef, setIsLoading]
  )

  const handleWriteBack = useCallback(async () => {
    handleClose()
    const quill = getQuillEditor(editorRef)
    const {publication} = publicationStore
    const publication_id = publication?.deprecatedId
    const publication_uid = publication?.id
    const regions = publication?.regions?.map(v => v?.uid)
    const rubric = publication?.rubric?.title
    const subrubric = publication?.subrubric?.title

    if (quill) {
      let requestText = ''
      const delta = quill.getContents()

      delta.forEach(item => {
        if (typeof item.insert === 'string') requestText += item.insert + ' '
      })

      if (requestText.length <= 1) {
        toast.info(TOOLBAR_BUTTONS_TITLES.notFoundText, {
          containerId: 'success',
          autoClose: 10000,
          hideProgressBar: true
        })
        return setIsLoading(false)
      }

      if (!rubric) {
        toast.info(TOOLBAR_BUTTONS_TITLES.back.notFoundRubric, {
          containerId: 'success',
          autoClose: 10000,
          hideProgressBar: true
        })
        return setIsLoading(false)
      }

      const data = {text: requestText, regions, rubric, subrubric, publication_id, publication_uid}
      setIsLoading(true)

      try {
        const response = await fetchGenerateBack(data)
        if (!response) {
          console.error('Не удалось получить данные от сервера.')
          return
        }
        responseForBack(response)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  }, [editorRef, responseForBack, publicationStore, setIsLoading, handleClose])

  return (
    <MenuItem>
      <Button onClick={handleWriteBack}>{TOOLBAR_BUTTONS_TITLES.back.backTitle}</Button>
    </MenuItem>
  )
}

export default WriteBackButton
