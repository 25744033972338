import {Typography} from '@mui/material'
import styled from 'styled-components'

export const HeaderWithBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
`

export const TitleWrapper = styled(Typography)`
  flex: 1;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #222222;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 22px;
`

export const CreatedInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
  flex-flow: wrap;
`
