import * as Types from '../../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type RegionsMainQueryVariables = Types.Exact<{[key: string]: never}>

export type RegionsMainQuery = {__typename?: 'Query'} & {
  regions?: Types.Maybe<Array<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>
}

export const RegionsMainDocument = gql`
  query regionsMain {
    regions {
      uid
      title
    }
  }
`

/**
 * __useRegionsMainQuery__
 *
 * To run a query within a React component, call `useRegionsMainQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsMainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsMainQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsMainQuery(
  baseOptions?: Apollo.QueryHookOptions<RegionsMainQuery, RegionsMainQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<RegionsMainQuery, RegionsMainQueryVariables>(RegionsMainDocument, options)
}
export function useRegionsMainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionsMainQuery, RegionsMainQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<RegionsMainQuery, RegionsMainQueryVariables>(RegionsMainDocument, options)
}
export type RegionsMainQueryHookResult = ReturnType<typeof useRegionsMainQuery>
export type RegionsMainLazyQueryHookResult = ReturnType<typeof useRegionsMainLazyQuery>
export type RegionsMainQueryResult = Apollo.QueryResult<RegionsMainQuery, RegionsMainQueryVariables>
