import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'

export const DebugInfoIcon: FC<SvgIconProps> = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.0231 14.2498L10.1981 7.42483C10.8731 5.69983 10.4981 3.67483 9.07312 2.24983C7.57312 0.749826 5.32312 0.449826 3.52312 1.27483L6.74812 4.49983L4.49812 6.74983L1.19812 3.52483C0.298123 5.32483 0.673124 7.57483 2.17312 9.07483C3.59812 10.4998 5.62312 10.8748 7.34812 10.1998L14.1731 17.0248C14.4731 17.3248 14.9231 17.3248 15.2231 17.0248L16.9481 15.2998C17.3231 14.9998 17.3231 14.4748 17.0231 14.2498Z'
        fill='#C4C4C4'
      />
    </svg>
  )
}
