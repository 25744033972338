import {FC} from 'react'
import {format} from 'date-fns'
import {ru} from 'date-fns/locale'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {grey100, grey35} from '@theme/vars'
import {
  AnswerFlagWrapper,
  HtmlWrapper,
  TableCellRow,
  TableRowLeft,
  TitleLink,
  TitleLinkWrapper
} from './CommentItemInfo.styles'
import {COMMENT_ITEM_INFO_CONSTS} from './CommentItemInfo.consts'
import {CommentItemInfoProps} from './CommentItemInfoProps'

/**
 * Компонент для отображения информации о комментарии
 * @param param0
 * @returns
 */
const CommentItemInfo: FC<CommentItemInfoProps> = ({comment, publicationUid, level}) => {
  const renderPublicationTitle = () => {
    if (!comment.publication.uid || !comment.publication.title) {
      return COMMENT_ITEM_INFO_CONSTS.publicationNotFound
    }

    return (
      <TitleLink
        to={`/comments/last-publication-comments/${comment.publication.uid}`}
        title={COMMENT_ITEM_INFO_CONSTS.goToNewsEditingMsg}
        id={`title-publication-link-${comment.id}`}
        className={comment.publication?.isAutomaticCommentsDisabled ? 'is-automatic-comments-disabled' : ''}
      >
        <Typography variant='text14R'>{comment.publication.title}</Typography>
      </TitleLink>
    )
  }

  const paddingLeft = !level ? '0px' : level === 2 ? '4%' : level > 2 ? '8%' : '0px'

  return (
    <TableRowLeft style={{paddingLeft}}>
      {!publicationUid && (
        <TitleLinkWrapper>
          {renderPublicationTitle()}
          <AnswerFlagWrapper>
            {comment.parentComment?.id && (
              <Typography variant='text14R'>{COMMENT_ITEM_INFO_CONSTS.itsAnswer}</Typography>
            )}
          </AnswerFlagWrapper>
        </TitleLinkWrapper>
      )}
      <TableCellRow>
        <Typography variant='text14R' color={grey100}>
          {comment.author?.name}:
        </Typography>
        <Typography variant='text14R' color={grey100}>
          {format(new Date(comment.createdAt), 'dd.MM.yyyy в HH:mm:ss', {
            locale: ru
          })}
        </Typography>
      </TableCellRow>
      <Box marginTop={'8px'}>
        {publicationUid && comment.parentComment?.id && (
          <Typography variant='text16M'>{comment.parentComment?.author?.name}, </Typography>
        )}
        <HtmlWrapper
          dangerouslySetInnerHTML={{__html: comment.autoModeratorResult?.text || comment.text}}
        ></HtmlWrapper>
      </Box>
      <TableCellRow>
        <Typography variant='text14R' color={grey35}>
          IP: {comment.author.ipAddress || COMMENT_ITEM_INFO_CONSTS.noData}
        </Typography>
        <Typography variant='text14R' color={grey35}>
          ID: {comment.author.sessionId || COMMENT_ITEM_INFO_CONSTS.noData}
        </Typography>
      </TableCellRow>
    </TableRowLeft>
  )
}

export default CommentItemInfo
