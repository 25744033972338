/**
 * Функция удаления элемента из разметки по идентификатору
 * @param id идентификатор
 * @returns void
 */
export const deleteElementById = (id: string): void => {
  if (id) {
    const currentElement = document.getElementById(id) as HTMLElement

    if (currentElement) {
      currentElement?.remove()
    }
  }
}
