import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {CircularProgress} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import {useKeycloak} from '@react-keycloak/web'
import {generateGuid} from '@utils/generateGuid'
import {Figure} from '@graphql/types'
import OverviewBlockImageModal from '@components/UI/ImageEditor/OverviewBlockImageModal/OverviewBlockImageModal'
import {useStore} from '@stores/rootStoreContext'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import {PublicationOverviewBlockFields} from '../PublicationForm/PublicationOverviewBlockFields/PublicationOverviewBlockFields'
import {PublicationInputType} from '../PublicationForm/PublicationInputType'
import {PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS} from './PublicationFormOverviewBlockImage.consts'
import {
  OverviewBlockImageWrapper,
  PublicationFormOverviewBlockImageHeaderWrapper,
  PublicationFormOverviewBlockImageInfoWrapper,
  PublicationFormOverviewBlockImagePhotoInfoWrapper,
  PublicationFormOverviewBlockImageWrapper
} from './PublicationFormOverviewBlockImage.styles'
import {PublicationFormOverviewBlockImageProps} from './PublicationFormOverviewBlockImageProps'

const PublicationFormOverviewBlockImage: FC<PublicationFormOverviewBlockImageProps> = ({
  defaultOverviewBlockImage,
  announceImage,
  overviewBlockFigureCache,
  publicationTypeSlug,
  setOverviewBlockFigureCache
}) => {
  const {publicationStore} = useStore()
  const {overviewBlockImageModalStore} = publicationStore
  const {isOpen, imageModalLoading, setIsOpen} = overviewBlockImageModalStore
  const [currentFigure, setCurrentFigure] = useState({} as Figure)
  const [currentOverviewBlockFigureCache, setCurrentOverviewBlockFigureCache] = useState<ImageBlotProps | undefined>()
  const {keycloak} = useKeycloak()
  const canEditPublication = useMemo(
    () => keycloak.hasResourceRole('edit-published', 'publications'),

    [keycloak]
  )

  const [overviewImage, setOverviewImage] = useState<ImageBlotProps>(
    PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS.defaultOverviewImage
  )

  const {setValue} = useFormContext<PublicationInputType>()

  useEffect(() => {
    if (overviewBlockFigureCache) {
      setCurrentOverviewBlockFigureCache(overviewBlockFigureCache)
      setValue('overviewFigureVersionId', overviewBlockFigureCache?.id || '')
      setValue('overviewFigureId', overviewBlockFigureCache?.figureId || '')
      setCurrentFigure({} as Figure)
      setIsOpen(false)
    }
  }, [overviewBlockFigureCache, setValue, setIsOpen])

  const closeDialogImage = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const openOverviewBlockModalImage = useCallback(() => {
    if (overviewImage?.figureUid) {
      if (currentOverviewBlockFigureCache) {
        setCurrentFigure({
          alt: currentOverviewBlockFigureCache.alt || '',
          author: currentOverviewBlockFigureCache.author || '',
          cropperPreview: {
            url: currentOverviewBlockFigureCache.cropperPreviewUrl || ''
          },
          description: currentOverviewBlockFigureCache.description || '',
          hasWatermark: currentOverviewBlockFigureCache.hasWatermark || false,
          hasDiagonalWatermark: currentOverviewBlockFigureCache.hasDiagonalWatermark || false,
          id: currentOverviewBlockFigureCache.figureId,
          latestVersion: {
            id: overviewImage.id,
            crop: currentOverviewBlockFigureCache.crop
          },
          src: currentOverviewBlockFigureCache.src || '',
          uid: currentOverviewBlockFigureCache.figureUid || ''
        } as Figure)
      } else {
        setCurrentFigure({
          alt: '',
          author: '',
          cropperPreview: {
            url: overviewImage.cropperPreviewUrl || '',
            size: {
              width: overviewImage.crop?.width || 0,
              height: overviewImage.crop?.height || 0
            }
          },
          description: '',
          hasWatermark: overviewImage.hasWatermark || false,
          hasDiagonalWatermark: overviewImage.hasDiagonalWatermark || false,
          id: overviewImage.figureId,
          latestVersion: {
            id: overviewImage.figureVersionId,
            crop: {
              width: overviewImage.crop?.width || 0,
              height: overviewImage.crop?.height || 0,
              top: overviewImage.crop?.top || 0,
              left: overviewImage.crop?.left || 0
            }
          },
          src: overviewImage.src || '',
          uid: overviewImage.figureUid || ''
        } as Figure)
      }
    }
    setIsOpen(true)
  }, [currentOverviewBlockFigureCache, setIsOpen, overviewImage])

  const setOverviewBlockImage = useCallback(
    (overviewBlockFigureCacheItem?: ImageBlotProps, overviewBlockImage?: any) => {
      const overviewBlockPhoto = document.getElementById('overviewBlockPhoto')

      if (overviewBlockPhoto && (overviewBlockFigureCacheItem || overviewBlockImage)) {
        setCurrentOverviewBlockFigureCache(overviewBlockFigureCacheItem)
        setOverviewBlockFigureCache(overviewBlockFigureCacheItem)

        const newOverviewImage = {
          id: overviewBlockFigureCacheItem?.id || overviewBlockImage?.figure?.id || 'overviewBlockFigureId',
          src: overviewBlockFigureCacheItem?.src || overviewBlockImage?.primaryProcessedImage?.url || '',
          cropperPreviewUrl:
            overviewBlockFigureCacheItem?.cropperPreviewUrl || overviewBlockImage?.figure?.cropperPreview?.url || '',
          crop: {
            left: overviewBlockFigureCacheItem?.crop?.left || overviewBlockImage?.crop?.left || 0,
            top: overviewBlockFigureCacheItem?.crop?.top || overviewBlockImage?.crop?.top || 0,
            width: overviewBlockFigureCacheItem?.crop?.width || overviewBlockImage?.crop?.width || 0,
            height: overviewBlockFigureCacheItem?.crop?.height || overviewBlockImage?.crop?.height || 0
          },
          figureId: overviewBlockFigureCacheItem?.id || overviewBlockImage?.figure?.id || '',
          figureUid: overviewBlockFigureCacheItem?.figureUid || overviewBlockImage?.figure?.uid || '',
          figureVersionId: overviewBlockFigureCacheItem?.figureVersionId || overviewBlockImage?.id || '',
          isFullWidth: true,
          isannouncingphoto: false,
          isoverviewblock: true,
          hasWatermark: false,
          hasDiagonalWatermark: false
        }

        setOverviewImage(newOverviewImage)

        setValue(
          'overviewFigureVersionId',
          overviewBlockFigureCacheItem?.figureVersionId || overviewBlockImage?.id || ''
        )
        setValue('overviewFigureId', overviewBlockFigureCacheItem?.figureId || overviewBlockImage?.figure?.id || '')
      }
    },
    [setCurrentOverviewBlockFigureCache, setOverviewBlockFigureCache, setValue]
  )

  useEffect(() => {
    setCurrentOverviewBlockFigureCache(undefined)
    setOverviewBlockImage(undefined, defaultOverviewBlockImage || announceImage?.figureVersion || ({} as Figure))
  }, [defaultOverviewBlockImage, announceImage, setOverviewBlockImage])

  return (
    <PublicationFormOverviewBlockImageWrapper>
      <PublicationFormOverviewBlockImageHeaderWrapper>
        {publicationTypeSlug === 'articles'
          ? PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS.articleMsg
          : PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS.newsMsg}
        {PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS.onMainPageMsg}
      </PublicationFormOverviewBlockImageHeaderWrapper>
      <PublicationFormOverviewBlockImageInfoWrapper>
        <OverviewBlockImageWrapper>
          <div
            id='overviewBlockPhoto'
            onClick={() => {
              canEditPublication && openOverviewBlockModalImage()
            }}
            style={{
              opacity: `${defaultOverviewBlockImage?.id && imageModalLoading ? '0.5' : '1'}`
            }}
          >
            {overviewImage && (
              <figure {...overviewImage}>
                <img
                  className={'change-item'}
                  draggable='false'
                  src={'/changeItem.jpg'}
                  onClick={() => {
                    canEditPublication && openOverviewBlockModalImage()
                  }}
                />
                <img src={overviewImage.src} draggable='false' />
              </figure>
            )}
          </div>
          {defaultOverviewBlockImage?.id && imageModalLoading ? (
            <CircularProgress
              size='3rem'
              style={{
                position: 'absolute',
                margin: '55px 97px'
              }}
            />
          ) : undefined}
          <OverviewBlockImageModal
            defaultFigure={currentFigure}
            modalId={defaultOverviewBlockImage?.id || generateGuid()}
            open={isOpen}
            onClose={closeDialogImage}
            title={PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS.insertingImageMsg}
            okLabel={PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS.changeMsg}
            figureTypeSlug='main-page'
            fullWidth={
              !defaultOverviewBlockImage?.figureVersion?.id
                ? publicationTypeSlug === 'articles'
                  ? true
                  : false
                : defaultOverviewBlockImage.isFullWidth
            }
            setImage={setOverviewBlockImage}
            figureIsLoading={false}
          />
          <PublicationFormOverviewBlockImagePhotoInfoWrapper>
            {PUBLICATION_FORM_OVERVIEW_BLOCK_IMAGE_CONSTS.photoForMainPageMsg}
          </PublicationFormOverviewBlockImagePhotoInfoWrapper>
        </OverviewBlockImageWrapper>
        {publicationTypeSlug !== 'articles' && <PublicationOverviewBlockFields />}
      </PublicationFormOverviewBlockImageInfoWrapper>
    </PublicationFormOverviewBlockImageWrapper>
  )
}

export default observer(PublicationFormOverviewBlockImage)
