interface FocusableElement {
  id: string
  focus: () => void
}

/**
 * Синглтон для управления фокусом на элементах
 *
 * @type {IFocusEvent}
 * @property {Object} registeredElements - Хранилище зарегистрированных элементов
 * @property {number} currentIndex - Текущий индекс в последовательности фокуса
 * @property {boolean} isAutoFocusing - Флаг активности автофокуса
 * @property {boolean} userInteracted - Флаг пользовательского взаимодействия
 * @property {number} timeout - Таймаут для blur
 * @property {Function} register - Метод для регистрации элемента
 * @property {Function} unregister - Метод для удаления элемента
 * @property {Function} unregisterAll - Метод для удаления всех элементов
 * @property {Function} focusNext - Метод для перехода к следующему элементу
 * @property {Function} startAutoFocus - Метод для запуска автофокуса
 */

interface IFocusEvent {
  // Хранилище зарегистрированных элементов
  registeredElements: {[id: string]: FocusableElement}
  // Текущий индекс в последовательности фокуса
  currentIndex: number
  // Флаг активности автофокуса
  isAutoFocusing: boolean
  // Флаг пользовательского взаимодействия
  userInteracted: boolean
  // Таймаут для blur
  timeout: number

  // Методы
  register: (id: string, focus: () => void) => void
  unregister: (id: string) => void
  unregisterAll: () => void
  focusNext: () => void
  startAutoFocus: () => (() => void) | undefined
}

export const FocusEvent: IFocusEvent = {
  // Глобальное хранилище для зарегистрированных полей
  registeredElements: {} as {[id: string]: FocusableElement},
  currentIndex: 0,
  isAutoFocusing: false,
  userInteracted: false,
  timeout: 0,

  // Функция для регистрации поля
  register(id: string, focus: () => void) {
    if (FocusEvent.registeredElements[id]) {
      return
    }
    FocusEvent.registeredElements[id] = {id, focus}
  },

  // Функция для отмены регистрации поля
  unregister(id: string) {
    delete FocusEvent.registeredElements[id]
  },

  unregisterAll() {
    FocusEvent.registeredElements = {} as {[id: string]: FocusableElement}
    this.currentIndex = 0
    this.isAutoFocusing = false
    this.userInteracted = false
    this.timeout = 1000
  },

  focusNext() {
    if (this.userInteracted || !this.isAutoFocusing) return

    const keys = Object.keys(this.registeredElements)

    this.currentIndex = (this.currentIndex + 1) % keys.length

    if (this.currentIndex === 0) {
      this.isAutoFocusing = false
      setTimeout(() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }
      }, this.timeout)
      return
    }

    const currentElement = this.registeredElements[keys[this.currentIndex]]

    if (currentElement) {
      try {
        currentElement.focus()
      } catch (e) {
        console.error('Focus error:', e)
      }
    }
  },

  startAutoFocus() {
    this.isAutoFocusing = true
    this.currentIndex = 0
    this.userInteracted = false
    const fallbackTimer = setTimeout(() => {
      if (this.currentIndex === 0 && this.isAutoFocusing) {
        document.activeElement?.blur() // Вызовет blur через существующую логику
      }
    }, 1000)
    // Блокируем ввод текста при автофокусировке
    const keyHandler = (e: Event) => e.preventDefault()
    const inputHandler = (e: Event) => {
      if (!this.userInteracted) e.preventDefault()
    }
    // Вешаем обработчики блокировки
    document.addEventListener('keydown', keyHandler)
    document.addEventListener('input', inputHandler)

    const observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        const addedNodes = Array.from(mutation.addedNodes)
        if (addedNodes.some(node => node instanceof Element && node.matches('lt-toolbar[contenteditable="false"]'))) {
          this.focusNext()
        }
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })

    // Отслеживаем клик пользователя
    const clickHandler = (e: PointerEvent) => {
      if (e.target instanceof Element && e.target.tagName === 'IMG') {
        return
      }
      this.userInteracted = true

      document.removeEventListener('keydown', keyHandler)
      document.removeEventListener('input', inputHandler)
      document.removeEventListener('click', clickHandler as EventListener)
    }
    document.addEventListener('click', clickHandler as EventListener)
    // Фокусируем первое поле
    const keys = Object.keys(this.registeredElements)
    if (keys.length > 0) {
      this.registeredElements[keys[this.currentIndex]].focus()
    }

    return () => {
      // Очищаем все обработчики
      observer.disconnect()
      document.removeEventListener('click', clickHandler as EventListener)
      document.removeEventListener('keydown', keyHandler)
      document.removeEventListener('input', inputHandler)
      clearTimeout(fallbackTimer)
    }
  }
}
