import React, {FC, useEffect, useMemo, useState} from 'react'
import {TopNewsBannerHistoryItemProps} from './TopNewsBannerHistoryItemProps'
import {
  AccordionSummaryWrapper,
  AccordionWrapper,
  ArrowDropDownIconWrapper,
  DetailNewValue,
  DetailOldValue,
  DetailsImageWrapper,
  DetailsInfoTitleWrapper,
  DetailsInfoWrapper,
  DetailsWrapper,
  ImagePlaceContainer,
  NewUrl,
  OldUrl,
  PublicationTitleWrapper,
  TitleWrapper,
  TopNewsBannerHistoryItemWrapper,
  UrlWrapper
} from './TopNewsBannerHistoryItem.styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {AccordionDetails, Tooltip} from '@mui/material'
import {format} from 'date-fns'
import {TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS} from './TopNewsBannerHistoryItem.consts'
import CopyToClipboard from '../../../../../components/UI/Buttons/CopyToClipboard/CopyToClipboard'
import {TopNewsBlock} from '../../../../../@graphql/types'

/**
 * Компонент отрисовки одного из значений истории изменений баннера
 * @param param0
 * @returns
 */
export const TopNewsBannerHistoryItem: FC<TopNewsBannerHistoryItemProps> = ({
  id,
  block,
  user,
  isFigureVersionChanged,
  newFigureVersion,
  isTitleChanged,
  newTitle,
  isUrlChanged,
  newUrl,
  isRegionChanged,
  newRegion,
  isPositionChanged,
  newPosition,
  isIsExclusiveChanged,
  newIsExclusive,
  createdAt
}) => {
  const [curBlock, setCurBlock] = useState<TopNewsBlock | null>(null)
  const [expanded, setExpanded] = useState()
  const [changesCount, setChangesCount] = useState(0)

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    setCurBlock(block || null)
  }, [block])

  useEffect(() => {
    let curChangesCount = 0

    if (isFigureVersionChanged) curChangesCount += 1
    if (isTitleChanged) curChangesCount += 1
    if (isUrlChanged) curChangesCount += 1
    if (isRegionChanged) curChangesCount += 1
    if (isPositionChanged) curChangesCount += 1
    if (isIsExclusiveChanged) curChangesCount += 1
    setChangesCount(curChangesCount)
  }, [isFigureVersionChanged, isTitleChanged, isUrlChanged, isRegionChanged, isPositionChanged, isIsExclusiveChanged])

  return (
    <TopNewsBannerHistoryItemWrapper>
      <AccordionWrapper
        square
        expanded={expanded === `top-news-banner-history-item-${id}-accordion`}
        onChange={handleChange(`top-news-banner-history-item-${id}-accordion`)}
      >
        <AccordionSummaryWrapper
          aria-controls={`top-news-banner-history-item-${id}-accordion-content`}
          id={`top-news-banner-history-item-${id}-accordion-header`}
        >
          <TitleWrapper>
            <div>{format(new Date(createdAt), 'dd.MM.yyyy HH:mm')}</div>
            <div>{`${user.firstname} ${user.lastname} ${
              curBlock
                ? TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.makeChangesMsg
                : TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.createBannerMsg
            }`}</div>
          </TitleWrapper>
          <ArrowDropDownIconWrapper>
            <span>{changesCount}</span>
            <ArrowDropDownIcon
              fontSize='medium'
              style={{
                transform: `${expanded ? 'rotate(180deg)' : 'rotate(0deg)'}`
              }}
            ></ArrowDropDownIcon>
          </ArrowDropDownIconWrapper>
        </AccordionSummaryWrapper>
        <AccordionDetails
          style={{
            padding: '0'
          }}
        >
          <DetailsWrapper>
            <DetailsImageWrapper>
              {/* <img src={curBlock.figureVersion?.primaryProcessedImage?.url} width={250}></img> */}
              <ImagePlaceContainer>
                {isFigureVersionChanged && (
                  <div>
                    <DetailsInfoTitleWrapper>URL</DetailsInfoTitleWrapper>
                    {curBlock && (
                      <UrlWrapper>
                        <Tooltip title={curBlock.figureVersion?.primaryProcessedImage?.url || ''}>
                          <OldUrl>{curBlock.figureVersion?.primaryProcessedImage?.url}</OldUrl>
                        </Tooltip>
                        <Tooltip title={TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.copyToClipboardMsg}>
                          <CopyToClipboard data={curBlock.figureVersion?.primaryProcessedImage?.url || ''} />
                        </Tooltip>
                      </UrlWrapper>
                    )}
                    <UrlWrapper>
                      <Tooltip title={newFigureVersion.primaryProcessedImage?.url || ''}>
                        <NewUrl>{newFigureVersion.primaryProcessedImage?.url}</NewUrl>
                      </Tooltip>
                      <Tooltip title={TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.copyToClipboardMsg}>
                        <CopyToClipboard data={newFigureVersion.primaryProcessedImage?.url || ''} />
                      </Tooltip>
                    </UrlWrapper>
                  </div>
                )}
              </ImagePlaceContainer>
              <PublicationTitleWrapper>
                {isTitleChanged && curBlock ? (
                  <div>
                    <DetailOldValue>{curBlock.title}</DetailOldValue>
                    <DetailNewValue>{newTitle}</DetailNewValue>
                  </div>
                ) : (
                  <div>{newTitle}</div>
                )}
              </PublicationTitleWrapper>
            </DetailsImageWrapper>
            <DetailsInfoWrapper>
              {isPositionChanged && (
                <div>
                  <DetailsInfoTitleWrapper>{TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.priorityMsg}</DetailsInfoTitleWrapper>
                  {curBlock && <DetailOldValue>{(curBlock.position || 0) + 1}</DetailOldValue>}
                  <DetailNewValue>{(newPosition || 0) + 1}</DetailNewValue>
                </div>
              )}
              {isIsExclusiveChanged && (
                <div>
                  <DetailsInfoTitleWrapper>{TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.exclusiveMsg}</DetailsInfoTitleWrapper>
                  {curBlock && (
                    <DetailOldValue>
                      {curBlock.isExclusive
                        ? TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.yes
                        : TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.no}
                    </DetailOldValue>
                  )}
                  <DetailNewValue>
                    {newIsExclusive ? TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.yes : TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.no}
                  </DetailNewValue>
                </div>
              )}
              {isUrlChanged && (
                <div>
                  <DetailsInfoTitleWrapper>
                    {TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS.publicationLinkMsg}
                  </DetailsInfoTitleWrapper>
                  {curBlock && <DetailOldValue>{curBlock.url}</DetailOldValue>}
                  <DetailNewValue>{newUrl}</DetailNewValue>
                </div>
              )}
            </DetailsInfoWrapper>
          </DetailsWrapper>
        </AccordionDetails>
      </AccordionWrapper>
    </TopNewsBannerHistoryItemWrapper>
  )
}
