import React, {FC} from 'react'
import {useFormContext, useWatch} from 'react-hook-form'
import RelatedPublicationItem from './RelatedPublicationItem/RelatedPublicationItem'
import {Publication} from '@graphql/types'

const RelatedPublications: FC = () => {
  const {control} = useFormContext()
  const fields = useWatch({control})

  return (
    <>
      {fields.publications.map(publication => (
        <RelatedPublicationItem
          key={publication?.uid}
          publication={publication as Publication}
          onRemove={fields.onRemove}
        />
      ))}
    </>
  )
}

export default RelatedPublications
