import {DatePicker} from '@mui/x-date-pickers'
import AuthorPicker from '@components/UI/AuthorPicker/AuthorPicker'
import RegionPicker from '@components/UI/RegionPicker/RegionPicker'
import {StyledFormControl} from '@components/UI/styled'
import styled from 'styled-components'

const screenSize = '979px'
export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;

  @media (max-width: ${screenSize}) {
    flex-direction: column;
  }
`
export const StyledFormControlWrapper = styled(StyledFormControl)`
  width: 350px;
  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`
export const AuthorPickerWrapper = styled(AuthorPicker)`
  width: 315px;
  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`

export const RegionPickerWrapper = styled(RegionPicker)`
  width: 250px;
  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`
export const KeyboardDatePickerWrapper = styled(DatePicker)`
  & input {
    padding: 0;
  }
  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`
