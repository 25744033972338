import styled from 'styled-components'
import {Accordion, AccordionDetails, AccordionSummary, Divider} from '@mui/material'
import {blueColor, grey35, mainTextColor, screenS, whiteColor} from '@theme/vars'
import SimpleModal from '../../SimpleModal/SimpleModal'

export const SimpleModalWrapper = styled(SimpleModal)`
  & .MuiDialog-paperWidthSm {
    max-height: 100%;
    padding-bottom: 0;
  }
  & #modal_content {
    padding: 16px 0px 24px 24px;
    line-height: 1.2;
    width: 100%;

    @media screen and (max-width: ${screenS}) {
      padding: 16px 0px 24px 0px;
    }
  }
`

export const GalleryEditorGridDropWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: ${screenS}) {
    margin-left: 0px;
  }
`

export const DividerWrapper = styled(Divider)`
  margin-top: 0px;
  width: calc(100% - 24px);

  @media screen and (max-width: ${screenS}) {
    margin-left: 10px;
    width: calc(100% - 20px);
  }
`

export const AccordionWrapper = styled(Accordion)`
  border: none;
  box-shadow: none;
  background-color: inherit;
  width: 100%;

  &.MuiPaper-root {
    &::before {
      height: 0px !important;
    }
  }

  @media screen and (max-width: ${screenS}) {
    width: calc(100% - 20px);
  }
`

export const AccordionSummaryWrapper = styled(AccordionSummary)`
  color: ${blueColor};
  display: flex;
  flex-direction: row;
  opacity: 1;

  &.MuiAccordionSummary-root {
    padding: 0;
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 17px !important;
    margin: 0px !important;

    & div {
      margin: 0px;
    }
  }

  &.Mui-disabled {
    opacity: 1;
  }
`

export const AccordionSummaryTitleWrapper = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  height: 24px;
  color: ${mainTextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ArrowDropDownIconWrapper = styled.div`
  height: 24px;
  color: ${mainTextColor};
`

export const AccordionDetailsWrapper = styled(AccordionDetails)`
  background-color: ${whiteColor};
  display: flex;
  flex-direction: column;
  padding: 0;
`
