import React, {FC} from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import styled from 'styled-components'
import {AutomoderatorDictionaryEntry} from '@graphql/types'
import IconButton from '@mui/material/IconButton'

const ItemWrapper = styled.div`
  font-size: 14px;
  padding-left: 12px;
  line-height: 1.2;
  color: #222;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
`

const ItemContentWrapper = styled.div``

const ItemInfo = styled.div`
  display: flex;
`
const Title = styled.h3`
  grid-area: title;
  font-weight: 500;
  line-height: 1.2;
`

type WordItemType = {
  word: AutomoderatorDictionaryEntry
  deleteWordAction: (id: string) => void
}

const DictionariesItem: FC<WordItemType> = ({word, deleteWordAction}) => {
  return (
    <ItemWrapper>
      <ItemInfo>
        <ItemContentWrapper>
          <Title>{word.word || 'Без названия'}</Title>
        </ItemContentWrapper>
      </ItemInfo>
      <IconButton onClick={() => deleteWordAction(word.id)}>
        <DeleteForeverIcon className='icon' color='disabled' />
      </IconButton>
    </ItemWrapper>
  )
}

export default DictionariesItem
