import {FetchResult} from '@apollo/client'
import {ModerateCommentsMutation} from '@components/Comments/gql/Comments.generated'

const moderateCommentsAdapterToClient = (response: FetchResult<ModerateCommentsMutation>): boolean => {
  if (!response.data?.moderateComments) {
    return false
  }

  return response.data?.moderateComments.status
}

export {moderateCommentsAdapterToClient}
