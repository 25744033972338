import React, {FC, useCallback, useEffect, useState} from 'react'
import {Box, LinearProgress, Typography} from '@mui/material'
import StoriesItem from '../StoriesItem/StoriesItem'
import {useActivateStoryMutation, useDeactivateStoryMutation, useStoriesListQuery} from '../gql/stories.generated'
import InfiniteScroll from 'react-infinite-scroll-component'
import {Story} from '@graphql/types'
import {format, isValid} from 'date-fns'
import {StoriesListContentProps} from './StoriesListContentProps'

const StoriesListContent: FC<StoriesListContentProps> = ({isActive, search, dateUpdatedFrom}) => {
  const [isEmptyList, setIsEmptyList] = useState(false)
  const {data, fetchMore, refetch} = useStoriesListQuery({
    variables: {
      isActive: isActive,
      search: search || null,
      dateUpdatedFrom: dateUpdatedFrom && isValid(dateUpdatedFrom) ? format(dateUpdatedFrom, 'yyyy-MM-dd') : null
    },
    onCompleted: data => {
      setIsEmptyList(!data.stories?.edges.length)
    },
    notifyOnNetworkStatusChange: true
  })
  useEffect(() => {
    void refetch({
      isActive: isActive,
      search: search || null,
      dateUpdatedFrom: dateUpdatedFrom && isValid(dateUpdatedFrom) ? format(dateUpdatedFrom, 'yyyy-MM-dd') : null
    })
  }, [dateUpdatedFrom, isActive, refetch, search])

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        first: 10,
        after: data?.stories?.pageInfo.endCursor,
        search: search || null
      }
    })
  }, [data?.stories?.pageInfo.endCursor, fetchMore, search])
  const [deactivateStory] = useDeactivateStoryMutation()
  const [activateStory] = useActivateStoryMutation()
  const deactivateStoryAction = useCallback(
    async uid => {
      try {
        await deactivateStory({
          variables: {
            data: {
              storyUid: uid
            }
          }
        })
        void refetch({
          isActive: isActive,
          search: search,
          dateUpdatedFrom: dateUpdatedFrom && isValid(dateUpdatedFrom) ? format(dateUpdatedFrom, 'yyyy-MM-dd') : null
        })
      } catch {}
    },
    [dateUpdatedFrom, deactivateStory, isActive, refetch, search]
  )
  const activateStoryAction = useCallback(
    async uid => {
      try {
        await activateStory({
          variables: {
            data: {
              storyUid: uid
            }
          }
        })
        void refetch({
          isActive: isActive,
          search: search,
          dateUpdatedFrom: dateUpdatedFrom && isValid(dateUpdatedFrom) ? format(dateUpdatedFrom, 'yyyy-MM-dd') : null
        })
      } catch {}
    },
    [activateStory, dateUpdatedFrom, isActive, refetch, search]
  )

  return (
    <>
      {!isEmptyList ? (
        <InfiniteScroll
          dataLength={data?.stories?.edges.length || 0}
          next={fetchMoreData}
          hasMore={!!data?.stories?.pageInfo.hasNextPage}
          loader={<LinearProgress color='primary' />}
          height='78vh'
        >
          <Box paddingRight='15px'>
            {data?.stories?.edges.map(story => (
              <StoriesItem
                story={story?.node as Story}
                key={story?.node?.uid}
                deactivateStoryAction={deactivateStoryAction}
                activateStoryAction={activateStoryAction}
              />
            ))}
          </Box>
        </InfiniteScroll>
      ) : (
        <Box marginTop='15px'>
          <Typography variant='h2'>Сюжеты отсутствуют</Typography>
        </Box>
      )}
    </>
  )
}

export default StoriesListContent
