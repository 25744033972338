import React, {HTMLAttributes, ReactNode, useContext, useEffect, useRef} from 'react'
import {animated, to, useSpring} from 'react-spring'
import {GridItemContext} from './GridItemContext'
import {FC} from 'react'
import {StateType, useGestureResponder} from '../responder'

interface GridItemProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    withoutZoom?: boolean
}

export const GridItem: FC<any> = ({
                                      children,
                                      style,
                                      className,
                                      withoutZoom = false,
                                      ...other
                                  }: GridItemProps) => {
    const context = useContext(GridItemContext)

    if (!context) {
        throw Error(
            'Unable to find GridItem context. Please ensure that GridItem is used as a child of GridDropZone'
        )
    }

    const {
        top,
        disableDrag,
        endTraverse,
        onStart,
        mountWithTraverseTarget,
        left,
        i,
        onMove,
        onEnd,
        grid,
        dragging: isDragging
    } = context

    const {columnWidth, rowHeight} = grid
    const dragging = useRef(false)
    const startCoords = useRef([left, top])

    const [styles, set] = useSpring(() => {
        if (mountWithTraverseTarget) {
            // this feels really brittle. unsure of a better
            // solution for now.

            const mountXY = mountWithTraverseTarget

            endTraverse()

            return {
                xy: mountXY,
                immediate: true,
                zIndex: '1',
                scale: withoutZoom === true ? 1 : 1.1,
                opacity: 0.8,
                backgroundColor: withoutZoom === true ? '#EDEEF1' : 'inherit'
            }
        }

        return {
            xy: [left, top],
            immediate: true,
            zIndex: '0',
            scale: 1,
            opacity: 1,
            backgroundColor: 'inherit'
        }
    })

    // handle move updates imperatively
    function handleMove(state: StateType) {
        const x = startCoords.current[0] + state.delta[0]
        const y = startCoords.current[1] + state.delta[1]
        set({
            xy: [x, y],
            zIndex: '1',
            immediate: true,
            opacity: 0.8,
            scale: 1.1,
            backgroundColor: withoutZoom === true ? '#EDEEF1' : 'inherit'
        })

        onMove(state, x, y)
    }

    // handle end of drag
    function handleEnd(state: StateType) {
        const x = startCoords.current[0] + state.delta[0]
        const y = startCoords.current[1] + state.delta[1]
        dragging.current = false
        onEnd(state, x, y)
    }

    const {bind} = useGestureResponder(
        {
            onMoveShouldSet: () => {
                if (disableDrag) {
                    return false
                }

                onStart()

                startCoords.current = [left, top]
                dragging.current = true
                return true
            },
            onMove: handleMove,
            onTerminationRequest: () => {
                return !dragging.current;


            },
            onTerminate: handleEnd,
            onRelease: handleEnd
        },
        {
            enableMouse: true
        }
    )

    /**
     * Update our position when left or top
     * values change
     */

    useEffect(() => {
        if (!dragging.current) {
            set({
                xy: [left, top],
                zIndex: '0',
                opacity: 1,
                scale: 1,
                immediate: false,
                backgroundColor: 'inherit'
            })
        }
    }, [dragging.current, left, top])

    const props = {
        className:
            'GridItem' +
            (isDragging ? ' dragging' : '') +
            (disableDrag ? ' disabled' : '') +
            className
                ? ` ${className}`
                : '',
        ...bind,
        style: {
            cursor: disableDrag ? 'grab' : undefined,
            zIndex: styles.zIndex,
            position: 'absolute',
            width: columnWidth + 'px',
            opacity: styles.opacity,
            height: rowHeight + 'px',
            boxSizing: 'border-box',
            backgroundColor: styles.backgroundColor,
            transform: to(
                [styles.xy, styles.scale],
                (xy: any, s: any) =>
                    `translate3d(${xy[0]}px, ${xy[1]}px, 0) scale(${withoutZoom === true ? 1 : s})`
            ),
            ...style
        },
        ...other
    }

    return typeof children === 'function' ? (
        children(animated.div, props, {
            dragging: isDragging,
            disabled: disableDrag,
            i,
            grid
        })
    ) : (
        // @ts-ignore
        <animated.div {...props}>{children}</animated.div>
    )
}
