import styled from 'styled-components'
import {blueColor} from '../../../@theme/vars'
import {IconButton} from '@mui/material'

export const ScrollToTopBtn = styled(IconButton)`
  position: fixed;
  bottom: 10px;
  right: 25px;
  padding: 0px;
  width: 48px;
  height: 48px;
  border-radius: 9.6px;
  background-color: ${blueColor};
  border: none;
  border-radius: 50%;
  box-shadow: 0px 2.25px 3.75px -0.75px rgba(0, 0, 0, 0.2), 0px 4.5px 7.5px 0px rgba(0, 0, 0, 0.14),
    0px 0.75px 13.5px 0px rgba(0, 0, 0, 0.12);
  z-index: 100;

  &:hover {
    cursor: pointer;
    background-color: ${blueColor};
  }

  @media only screen and (min-width: 841px) and (max-width: 1426px) {
    bottom: 70px;
  }
`
