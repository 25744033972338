import {FC, useEffect, useRef, useState} from 'react'
import {CircularProgress} from '@mui/material'
import {observer} from 'mobx-react-lite'
import ReactQuill from 'react-quill'
import {StyledDialog} from '@components/UI/SimpleModal/SimpleModal.styles'
import CloseIcon from '@components/UI/Icons/CloseIcon'
import {useStore} from '@stores/rootStoreContext'
import {retryLastRequest} from '@fetch/ky-client'
import {getQuillEditor} from '@utils/getQuillEditor'
import {NerDialogRequest} from '@fetch/NnApi/NnAPI.types'
import {addErrorsMessage} from '@utils/utilsNn/addErrorsMessage'
import {insertSelectedText} from '@utils/insertSelectedText'
import {insertText} from '@utils/insertText'
import {errorColor} from '@theme/vars'
import FullScreenNerIcon from '@components/UI/Icons/FullScreenNerIcon'
import {TOOLBAR_DIALOG_CONSTS} from '../ToolbarNn.consts'
import {
  DialogButton,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  HorizontalLine,
  Modal,
  RollUp,
  StyledPaper,
  TextRollUp,
  TextRollUpTitle,
  WrapperRollUp
} from './DialogNn.styles'
import DialogItemNn from './DialogItemNn/DialogItemNn'
import DialogInputNn from './DialogInputNn/DialogInputNn'
import type {RangeStatic} from 'quill'

type DialogNnProps = {
  editorRef: React.MutableRefObject<ReactQuill | null>
  authorId: string
}
/**
 * Диалоговое окно для взаимодействия с Ner.
 * Показывает сообщения от пользователя и  ИИ, а также позволяет отправлять новые сообщения и повторять запросы.
 *
 * @param {React.MutableRefObject<any>} editorRef - Ссылка на редактор Quill, которая используется для вставки текста.
 *
 * @methods
 * - handleRetry: Функция для повторного отправления запроса при ошибке.
 * - handleInsertText: Функция для вставки текста в редактор.
 * - handleSendMessage: Функция для отправки нового сообщения в диалог.
 * - handleCloseDialog: Функция для закрытия диалога.
 */

const DialogNn: FC<DialogNnProps> = ({editorRef, authorId}) => {
  const {dialogStore, publicationStore} = useStore()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {messages, isDialogOpen, isLoadingNer, indexSelectedText, lengthSelectedText, isRollUp} = dialogStore
  const {publication} = publicationStore
  const publicationUid = publication.id
  const publicationId = publication.deprecatedId

  const isError = messages.some(message => message.isError)
  const lastMessageIndex = messages.map((_, index) => index).pop() // Получаем индекс последнего ИИ-сообщения

  const dialogContentRef = useRef<HTMLDivElement | null>(null)

  //Функция повторного запроса
  const handleRetry = async () => {
    const abortCtrl = new AbortController()
    dialogStore.setAbortController(abortCtrl)

    dialogStore.deleteLastMessage()
    dialogStore.setIsLoadingNer(true)
    try {
      const retryResponse = await retryLastRequest(abortCtrl.signal)
      if (retryResponse) {
        dialogStore.setIsLoadingNer(false)
        const regExp = /\n\s*\n+/g
        const text = JSON.parse(retryResponse).text.replace(regExp, '\n')
        dialogStore.addMessage({isUser: false, isError: false, message: text})
      }
    } catch (error) {
      addErrorsMessage(dialogStore, error)
    } finally {
      dialogStore.setIsLoadingNer(false)
      dialogStore.setAbortController(null)
    }
  }

  //Функция вставки текста в редактор
  const handleInsertText = (message: string) => {
    const quill = getQuillEditor(editorRef)

    if (quill) {
      dialogStore.closeDialog()
      if (typeof lengthSelectedText === 'number' && typeof indexSelectedText === 'number') {
        setTimeout(() => {
          insertSelectedText({quill, index: indexSelectedText, length: lengthSelectedText, text: message})
        }, 0)

        return
      }
      setTimeout(() => {
        insertText({quill, text: message})
      }, 0)
    }
  }

  //Функция отправки нового сообщения в диалоге
  const handleSendMessage = async (message: string) => {
    if (messages.some(message => message.isError)) {
      dialogStore.deleteErrorMessage()
    }

    dialogStore.addMessage({isUser: true, isError: false, message})
    let data: NerDialogRequest = {params: {}, publicationId, publicationUid, authorId}

    data = dialogStore.messages.reduce((acc, msg) => {
      return {
        ...acc,
        params: {...acc.params, [String(msg.id)]: msg.message}
      }
    }, data)
    const abortCtrl = new AbortController()
    dialogStore.setAbortController(abortCtrl)

    dialogStore.setIsLoadingNer(true)
    try {
      const response = await retryLastRequest(abortCtrl.signal, data)
      if (response) {
        const regExp = /\n\s*\n+/g
        const text = JSON.parse(response).text.replace(regExp, '\n')
        dialogStore.addMessage({isUser: false, isError: false, message: text})
      }
    } catch (error) {
      addErrorsMessage(dialogStore, error)
    } finally {
      dialogStore.setIsLoadingNer(false)
    }
  }

  //Функция при попытке закрыть диалог с ИИ
  const handleCloseDialog = () => {
    if (messages.length > 2 || (messages.length === 2 && !messages.some(message => message.isError))) {
      setIsModalOpen(true)
      return
    }
    dialogStore.closeDialog()
  }

  //
  const handleRoleUp = () => {
    dialogStore.roleUpDialog()
  }

  //sideEffect для прокрутки вниз к актуальному сообщению
  useEffect(() => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTop = dialogContentRef.current.scrollHeight
    }
  }, [messages.length])

  return (
    <>
      <StyledDialog
        open={isDialogOpen}
        PaperComponent={StyledPaper}
        onClose={() => {
          handleCloseDialog()
        }}
      >
        <Modal
          open={isModalOpen}
          variant='confirmation'
          showCloseButton={true}
          onClose={() => {
            setIsModalOpen(false)
          }}
          onOk={() => {
            setIsModalOpen(false)
            const lastMessage = messages.findLast(message => !message.isUser && !message.isError)
            if (lastMessage?.message) {
              handleInsertText(lastMessage.message)
            }
          }}
          onNo={() => {
            setIsModalOpen(false)
            dialogStore.closeDialog()
          }}
          title={TOOLBAR_DIALOG_CONSTS.MODAL.titleModal}
          text={TOOLBAR_DIALOG_CONSTS.MODAL.textModal}
          okLabel={TOOLBAR_DIALOG_CONSTS.MODAL.okButtonModal}
          noLabel={TOOLBAR_DIALOG_CONSTS.MODAL.noButtonModal}
        />
        <DialogHeader>
          <DialogTitle>{TOOLBAR_DIALOG_CONSTS.HEADER.helpNn}</DialogTitle>
          <DialogButton
            onClick={() => {
              handleRoleUp()
            }}
          >
            <HorizontalLine fontSize='medium' />
          </DialogButton>
          <DialogButton
            onClick={() => {
              handleCloseDialog()
            }}
          >
            <CloseIcon />
          </DialogButton>
        </DialogHeader>

        <DialogContent ref={dialogContentRef}>
          {messages.map((message, index) => (
            <DialogItemNn
              key={message.id}
              isUser={message.isUser}
              isError={message.isError}
              handleRetry={handleRetry}
              handleInsertText={handleInsertText}
              isLastMessage={index === lastMessageIndex && !message.isUser}
            >
              {message.message}
            </DialogItemNn>
          ))}
          {isLoadingNer ? (
            <DialogItemNn isLastMessage={false} isUser={false} isError={false} isLoading={isLoadingNer}>
              <CircularProgress size='24px' />
            </DialogItemNn>
          ) : (
            <></>
          )}
        </DialogContent>

        <DialogFooter>
          <DialogInputNn
            isLoading={isLoadingNer}
            isError={isError && messages.length <= 2}
            handleCancel={() => {
              dialogStore.handleCancel()
            }}
            handleSendMessage={handleSendMessage}
          />
        </DialogFooter>
      </StyledDialog>
      {isRollUp && (
        <WrapperRollUp>
          <RollUp onClick={handleRoleUp}>
            <TextRollUpTitle>{TOOLBAR_DIALOG_CONSTS.HEADER.helpNn}:</TextRollUpTitle>
            {isLoadingNer ? (
              <TextRollUp>{TOOLBAR_DIALOG_CONSTS.ROLLUP.rollUpLoading}</TextRollUp>
            ) : isError ? (
              <TextRollUp color={errorColor}>{TOOLBAR_DIALOG_CONSTS.ROLLUP.rollUpError}</TextRollUp>
            ) : (
              <TextRollUp color={'#048E11'}>{TOOLBAR_DIALOG_CONSTS.ROLLUP.rollUpSuccess}</TextRollUp>
            )}
            <DialogButton style={{marginLeft: 'auto', marginRight: 0}}>
              <FullScreenNerIcon />
            </DialogButton>
          </RollUp>
        </WrapperRollUp>
      )}
    </>
  )
}

export default observer(DialogNn)
