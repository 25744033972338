import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Controller, useFormContext} from 'react-hook-form'
import {InfoOutlined} from '@mui/icons-material'
import {Typography} from '@mui/material'
import PublicationOverviewBlockFieldsTitle from '@components/Publication/Form/PublicationForm/PublicationOverviewBlockFieldsTitle/PublicationOverviewBlockFieldsTitle'
import {replaceValue} from '../../PublicationFormTitles/PublicationFormTitle/PublicationFormTitle.utils'
import {PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS} from './PublicationOverviewBlockFields.consts'
import {
  PublicationOverviewBlockFieldsWrapper,
  PublicationOverviewBlockInfo
} from './PublicationOverviewBlockFields.styles'

export const PublicationOverviewBlockFields: FC = observer(() => {
  const [titleErrorText, setTitleErrorText] = useState('')
  const [subtitleErrorText, setSubtitleErrorText] = useState('')
  const {control, setValue} = useFormContext()
  const [curEventTarget, setCurEventTarget] = useState<HTMLInputElement | undefined>()
  const [curEventTargetSelectionStart, setCurEventTargetSelectionStart] = useState(0)
  const [curFieldName, setCurFieldName] = useState<string>()
  const [curFieldValue, setCurFieldValue] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (curEventTarget && curFieldName) {
        const value = replaceValue(curFieldValue)
        const titleIndexPosition = curEventTargetSelectionStart || 0
        setValue(curFieldName, value)
        if (curEventTarget === document.activeElement && titleIndexPosition < curFieldValue.length) {
          curEventTarget.focus()
          curEventTarget.setSelectionRange(titleIndexPosition, titleIndexPosition)
        }
      }
    }, 1500)
    return () => clearTimeout(timer)
  }, [curEventTarget, curEventTargetSelectionStart, curFieldName, curFieldValue, setValue])

  const handleChange = useCallback(
    (fieldValue: string, fieldName: string, eventTarget?: HTMLInputElement) => {
      const value = fieldValue

      setCurFieldName(fieldName)
      setCurFieldValue(fieldValue)

      setValue(fieldName, value)

      if (eventTarget) {
        setCurEventTarget(eventTarget)
        setCurEventTargetSelectionStart(eventTarget.selectionStart || 0)
      }
    },
    [setValue]
  )

  return (
    <PublicationOverviewBlockFieldsWrapper>
      <Controller
        name='overviewTitle'
        control={control}
        render={({field}) => (
          <div style={{width: '100%', marginBottom: '20px'}}>
            <PublicationOverviewBlockFieldsTitle
              titleLabel={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.titleMsg}
              titleMaxLength={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.titleMaxLength}
              field={field}
              onChange={handleChange}
              textErrorChange={setTitleErrorText}
              required={true}
            />
            {!titleErrorText?.length && (
              <Typography color='primary' component='div'>
                <PublicationOverviewBlockInfo>
                  <InfoOutlined
                    style={{
                      color: '#1964e7',
                      transform: 'scale(0.6)',
                      marginLeft: '-6px'
                    }}
                  />
                  {PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.autocompleteMsg}
                </PublicationOverviewBlockInfo>
              </Typography>
            )}
          </div>
        )}
      />
      <Controller
        name='overviewDescription'
        control={control}
        render={({field}) => (
          <div style={{width: '100%', marginBottom: '20px'}}>
            <PublicationOverviewBlockFieldsTitle
              titleLabel={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.subtitleMsg}
              titleMaxLength={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.subtitleMaxLength}
              field={field}
              onChange={handleChange}
              textErrorChange={setSubtitleErrorText}
              required={false}
            />
          </div>
        )}
      />
    </PublicationOverviewBlockFieldsWrapper>
  )
})
