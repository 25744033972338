import styled from 'styled-components'

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; // Задаем высоту контейнера на 100% высоты окна
`

export const InputsContainer = styled.div`
  // Стили для контейнера инпутов
  margin-bottom: 16px; // Отступ между инпутами и списком
`

export const ScrollableListContainer = styled.div`
  flex: 1; // Занимает оставшееся пространство
  max-height: calc(100vh - 350px); // Замените 200px на высоту ваших инпутов
  overflow-y: auto; // Вертикальный скролл
`
