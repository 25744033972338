import React from 'react'
import {Typography} from '@mui/material'
import styled from 'styled-components'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import Persons from '@components/Persons/Persons'

const Title = styled(Typography)`
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #222222;
  margin-bottom: 10px;
`

const PersonsPage: React.FC = () => {
  return (
    <PageContainer>
      <Title variant='h1'>Персоны</Title>
      <Persons />
    </PageContainer>
  )
}

export default PersonsPage
