import {DatePicker} from '@mui/x-date-pickers'
import {StyledFormControl} from '@components/UI/styled'

import styled from 'styled-components'
import {FormControlLabel} from '@mui/material'
import {mainTextColor} from '../../../@theme/vars'

const screenSize = '770px'
export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: ${screenSize}) {
    flex-direction: column;
  }
`

export const FormControlLabelWrapper = styled(FormControlLabel)`
  margin-left: 40px;
  color: ${mainTextColor};

  @media (max-width: ${screenSize}) {
    margin-left: -12px;
  }
`

export const StyledFormControlWrapper = styled(StyledFormControl)`
  width: 350px;
  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`

export const KeyboardDatePickerWrapper = styled(DatePicker)`
  & input {
    padding: 0;
  }
  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`

export const BoxWithInfo = styled.div`
  display: flex;
  align-items: center;
`
