import {Box, Button, FormHelperText, Typography} from '@mui/material'
import React, {FC, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useFormContext, useWatch} from 'react-hook-form'
import RHFInput from '@components/Collection/Form/RHFInput/RHFInput'
import {useSnackbar} from 'notistack'
import {usePublicationByUrlFromEditorLazyQuery} from '@components/UI/TextEditorSidebarModal/gql/TextEditorSidebarModal.generated'
import {HOT_NEWS_HEADER_CONSTS} from './HotNewsHeader.consts'

const AddButton = styled(Button)`
  margin: 0 12px;
`

type HotNewsHeaderProps = {
  updateHotNews: () => void
  updateHotNewsForAllRegions: () => void
}

const HotNewsHeader: FC<HotNewsHeaderProps> = ({updateHotNews, updateHotNewsForAllRegions}) => {
  const {control, setValue} = useFormContext()
  const fields = useWatch({control})
  const snackbar = useSnackbar()
  const [invalidLink, setInvalidLink] = useState('')

  const [getPublicationByUrl] = usePublicationByUrlFromEditorLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setValue('description', data?.publicationByUrl?.subtitle || '')
      setValue('title', data?.publicationByUrl?.title || '')
      setValue(
        'url',
        data?.publicationByUrl?.__typename === 'ExternalPublication' ? data?.publicationByUrl?.url || '' : ''
      )
      setValue('uid', data?.publicationByUrl?.__typename === 'Publication' ? data?.publicationByUrl?.uid || '' : '')
    }
  })

  useEffect(() => {
    const regex = /^http(s)?\:\/\/(.{0,10})ura\.news\/((story)|(articles)|(news))\/\d{1,10}$/g
    const fullMatch = fields.searchUrl.slice().replace(regex, '')
    setInvalidLink(fullMatch.length > 0 ? HOT_NEWS_HEADER_CONSTS.wrongLink : '')
  }, [fields.searchUrl])

  const getOldId = useCallback(async () => {
    try {
      await getPublicationByUrl({
        variables: {url: fields.searchUrl}
      })
      setValue('isHaveNews', true)
    } catch {
      snackbar.enqueueSnackbar(HOT_NEWS_HEADER_CONSTS.newsNotFoundMsg)
    }
  }, [fields.searchUrl, getPublicationByUrl, setValue, snackbar])

  return (
    <Box display='flex' justifyContent='space-between' width='100%' flexWrap='wrap'>
      <Box marginBottom='12px'>
        <RHFInput
          name='searchUrl'
          label={HOT_NEWS_HEADER_CONSTS.urlLabelMsg}
          width='300px'
          disabled={fields.isHaveNews}
          errorText={invalidLink}
        />
        <AddButton
          variant='outlined'
          color='primary'
          onClick={getOldId}
          disabled={!fields.searchUrl || fields.isHaveNews || invalidLink}
        >
          {HOT_NEWS_HEADER_CONSTS.addMsg}
        </AddButton>
        {fields.isHaveNews && <Typography component='span'>{HOT_NEWS_HEADER_CONSTS.haveNews}</Typography>}
      </Box>
      <Box marginBottom='12px'>
        <Button variant='outlined' style={{marginRight: '24px'}} onClick={updateHotNewsForAllRegions}>
          {HOT_NEWS_HEADER_CONSTS.saveForAllRegionsMsg}
        </Button>
        <Button variant='contained' color='primary' onClick={updateHotNews}>
          {HOT_NEWS_HEADER_CONSTS.saveMsg}
        </Button>
      </Box>
    </Box>
  )
}

export default HotNewsHeader
