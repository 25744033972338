import React, {FC, useCallback, useEffect, useState} from 'react'
import SimpleModal from '../../UI/SimpleModal/SimpleModal'
import {StyledFormControl} from '../../UI/styled'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

type NewCollectionModalProps = {
  open: boolean
  onClose: () => void
  onOk: (data: CreateCollectionItem) => void
}

export type CreateCollectionItem = {
  title: string
  description?: string
}

const CreateCollectionModal: FC<NewCollectionModalProps> = ({open, onClose, onOk}) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
  }

  useEffect(() => {
    setTitle('')
    setDescription('')
  }, [open])

  const handleOnOk = useCallback(() => onOk({title, description}), [onOk, description, title])

  return (
    <SimpleModal
      open={open}
      showCloseButton
      okLabel='Создать'
      title='Создание коллекции'
      onClose={onClose}
      onOk={handleOnOk}
      okButtonDisabled={!title || !description}
    >
      <Box display='flex' flexDirection='column' alignItems='center'>
        <StyledFormControl width='400px' marginbottom='23px'>
          <TextField label='Название' value={title} onChange={handleChangeTitle} />
        </StyledFormControl>
        <StyledFormControl width='400px' marginbottom='23px'>
          <TextField label='Описание' value={description} onChange={handleChangeDescription} />
        </StyledFormControl>
      </Box>
    </SimpleModal>
  )
}

export default CreateCollectionModal
