import React, {FC, useState} from 'react'
import {useParams} from 'react-router'
import {LinearProgress} from '@mui/material'
import StoryForm from '@components/Stories/StoriesEditor/StoryForm'
import {useStoryQuery} from './gql/StoriesEditor.generated'
import {Story} from '@graphql/types'
import PageContainer from '@components/UI/PageContainer/PageContainer'

const StoryEditorPage: FC = () => {
  const [story, setStory] = useState<Story>()
  const {uid} = useParams<{uid: string}>()
  const {loading} = useStoryQuery({
    variables: {uid: uid},
    onCompleted: data => {
      setStory(data?.story as Story)
    }
  })
  return (
    <PageContainer>
      {loading || !story ? <LinearProgress color='primary' /> : <StoryForm story={story} />}
    </PageContainer>
  )
}

export default StoryEditorPage
