import React, {FC} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography
} from '@mui/material'
import {items} from '../menu-items'
import {StyledButton, StyledNavLink, WrapperButtons} from '../Navbar'
import styled from 'styled-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {grayColor} from '../../../../@theme/vars'
import {Close, HelpOutline} from '@mui/icons-material'
import {Logout} from '../../Icons/Logout'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 320px;
  max-width: 600px;
`

const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  &::before {
    height: 0;
  }
`

const TitleAccordion = styled(Typography)`
  color: ${grayColor};
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 8px;
  display: inline-flex;
  height: 36px;

  &.MuiAccordionSummary-root {
    min-height: 36px;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

type CustomDrawerProps = {
  open: boolean
  setOpen: (open: boolean) => void
  handleLogout: () => void
}

const CustomDrawer: FC<CustomDrawerProps> = ({open, setOpen, handleLogout}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor='right'>
      <Wrapper>
        <Box alignSelf='end'>
          <IconButton size='small' onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        {items.map((item, ind) => {
          if (item.hasOwnProperty('type')) {
            return (
              <StyledAccordion
                key={'custom-drawer-' + item.title + ind}
                expanded={expanded === item.title}
                onChange={handleChange(item.title)}
              >
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <TitleAccordion>{item.title}</TitleAccordion>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  {item.menuList?.map((menuItem, ind2) => (
                    <StyledNavLink exact to={menuItem.route} key={'custom-drawer-' + menuItem.title + ind2}>
                      <StyledButton>{menuItem.title}</StyledButton>
                    </StyledNavLink>
                  ))}
                </StyledAccordionDetails>
              </StyledAccordion>
            )
          }
          return (
            <StyledNavLink
              exact
              to={item.route}
              key={'custom-drawer-' + item.title}
              style={{
                marginLeft: item.class === 'tags' ? '-10px' : undefined
              }}
            >
              <StyledButton>{item.title}</StyledButton>
            </StyledNavLink>
          )
        })}
        <Divider />
        <Box margin='10px auto'>
          <WrapperButtons>
            <IconButton size='large'>
              <HelpOutline />
            </IconButton>
            <IconButton onClick={handleLogout}>
              <Logout />
            </IconButton>
          </WrapperButtons>
        </Box>
      </Wrapper>
    </Drawer>
  )
}

export default CustomDrawer
