import React, {FC, useCallback, useEffect, useState} from 'react'
import {IconButton, Tooltip} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import SimpleModal from '@components/UI/SimpleModal/SimpleModal'
import {CropInput, Figure} from '@graphql/types'
import {generateGuid} from '@utils/generateGuid'
import DeleteIcon from '@components/UI/Icons/DeleteIcon'
import {useStore} from '@stores/rootStoreContext'
import ImageCropper from '../ImageCropper/ImageCropper'
import {useCreateFigureVersionMutation} from '../../../Publication/Form/gql/PublicationEditor.generated'
import {ImageBlotProps} from '../../../TextEditor/Blots/ImageBlot/ImageBlotProps'
import {CropperType} from '../../../../types/CropperType'
import {OVERVIEW_BLOCK_IMAGE_MODAL_CONSTS} from './OverviewBlockImageModal.consts'
import {OverviewBlockImageModalProps} from './OverviewBlockImageModalProps'

const OverviewBlockImageModal: FC<OverviewBlockImageModalProps> = ({
  defaultFigure,
  modalId,
  open,
  onClose,
  title,
  text,
  okLabel,
  noLabel,
  ratio = 7 / 5,
  isCustomRatio = false,
  // addFigure,
  setValueFormContext,
  figureTypeSlug,
  deleteFigure,
  setImage,
  figureIsLoading
}) => {
  const [imageUrl, setImageUrl] = useState(defaultFigure?.src)
  const [id, setId] = useState(modalId || generateGuid())
  const [figure, setFigure] = useState<Figure>(defaultFigure as Figure)
  const {cropperStore} = useStore()
  const {cropper, cropperIsValid, setCropper} = cropperStore
  const [currentHasWatermark, setCurrentHasWatermark] = useState(!!defaultFigure?.hasWatermark)
  const [figureVersionId, setFigureVersionId] = useState(defaultFigure?.latestVersion?.id)
  const [createFigureVersion, {loading: createFigureVersionLoading}] = useCreateFigureVersionMutation()
  const {setValue} = useFormContext()
  const [clearFieldEventCounter, setClearFieldEventCounter] = useState(0)

  useEffect(() => {
    setId(modalId || generateGuid())
    setFigure(defaultFigure as Figure)
    setImageUrl(defaultFigure?.src || '')
  }, [defaultFigure, modalId])

  const createFigureVersionAction = useCallback(async () => {
    // if (addFigure) {
    const newFigure = await createFigureVersion({
      variables: {
        data: {
          figureId: figure?.id,
          hasWatermark: currentHasWatermark || false,
          hasDiagonalWatermark: false,
          crop: cropper
        }
      }
    })

    const imageBlot: ImageBlotProps = {
      id: id || generateGuid(),
      crop: cropper,
      figureId: newFigure.data?.createFigureVersion?.figureVersion?.figure?.id || figure?.id || '',
      figureUid: newFigure.data?.createFigureVersion?.figureVersion?.figure?.uid || figure?.uid || '',
      figureVersionId: newFigure.data?.createFigureVersion?.figureVersion?.id || figureVersionId || '',
      // hasWatermark:
      //     newFigure.data?.createFigureVersion?.figureVersion
      //         ?.hasWatermark || false,
      // hasDiagonalWatermark:
      //     newFigure.data?.createFigureVersion?.figureVersion
      //         ?.hasDiagonalWatermark || false,
      src: newFigure.data?.createFigureVersion?.figureVersion?.figure?.latestVersion.primaryProcessedImage?.url || '',
      cropperPreviewUrl:
        newFigure.data?.createFigureVersion?.figureVersion?.figure?.cropperPreview?.url ||
        figure?.cropperPreview?.url ||
        '',
      isFullWidth: true,
      author: '',
      description: '',
      alt: '',
      isannouncingphoto: false,
      hasWatermark: false,
      hasDiagonalWatermark: false
    }

    setFigureVersionId(newFigure.data?.createFigureVersion?.figureVersion?.id || '')
    setImage && setImage(imageBlot)
    // addFigure(imageBlot)
    // }

    onClose()
    setId('')
  }, [
    createFigureVersion,
    currentHasWatermark,
    cropper,
    figure?.uid,
    setFigureVersionId,
    // addFigure,
    setValueFormContext,
    onClose,
    setValue,
    id,
    setImage,
    figureVersionId,
    imageUrl
  ])

  const closeModal = useCallback(() => {
    onClose()
  }, [onClose])

  const deleteFigureAction = useCallback(async () => {
    if (deleteFigure && id) {
      deleteFigure(id)
    }
    closeModal()
  }, [deleteFigure, closeModal, id])

  const clearFigure = useCallback(() => {
    setFigure({} as Figure)
    setImageUrl('')
    setClearFieldEventCounter(prev => prev + 1)
  }, [])

  return (
    <SimpleModal
      id='overview-block-modal'
      open={open}
      mode='alert'
      onClose={closeModal}
      title={title}
      text={text}
      onOk={createFigureVersionAction}
      onNo={deleteFigureAction}
      okButtonDisabled={!figure?.uid || !cropperIsValid || createFigureVersionLoading === true}
      okButtonDisabledTooltip={OVERVIEW_BLOCK_IMAGE_MODAL_CONSTS.fillRequiredFields}
      noButtonDisabled={false}
      okLabel={okLabel}
      noLabel={noLabel}
      loading={figureIsLoading || createFigureVersionLoading}
    >
      <>
        <ImageCropper
          figure={figure}
          imageUrl={imageUrl}
          description={''}
          hasWatermark={currentHasWatermark}
          hasDiagonalWatermark={false}
          setImageUrl={setImageUrl}
          setFigure={setFigure}
          ratio={ratio}
          figureTypeSlug={figureTypeSlug}
          cropperType={CropperType.isOverviewBlockImageModal}
          isCustomRatio={isCustomRatio}
          figureIsLoading={figureIsLoading}
        />
        {figure.src && (
          <div
            style={{
              display: 'block',
              position: 'absolute',
              top: '8px',
              right: '28px'
            }}
          >
            <Tooltip title={OVERVIEW_BLOCK_IMAGE_MODAL_CONSTS.setAnotherPhotoLabelMsg}>
              <IconButton
                edge='end'
                size='small'
                id='overview-block-image-modal-delete-photo'
                onClick={() => clearFigure()}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </>
    </SimpleModal>
  )
}

export default OverviewBlockImageModal
