import styled, {css} from 'styled-components'
import {DialogContent} from '@mui/material'
import {CONFIRMATION_MODE, ModalMode} from '../../../../types/ModalMode'

export const StyledDialogContent = styled(DialogContent)<{
  loading?: boolean
  mode?: ModalMode
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => (props.mode === CONFIRMATION_MODE ? css`8px 24px` : css`24px`)};
  ${props =>
    props.loading &&
    css`
      pointer-events: none;
      user-select: none;
    `}
`
