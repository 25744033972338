import React, {useCallback, FC, useState, useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Box
} from '@mui/material'
import {Clear, Videocam} from '@mui/icons-material'
import {useKeycloak} from '@react-keycloak/web'
import {VIDEO_LOADER_CONSTS} from './VideoLoader.consts'
import {
  MainContainer,
  StyledDeleteBtn,
  StyledFormWrapper,
  StyledUploadWrapper,
  StyledVideoIframe,
  VideoBoxText,
  VideoLoaderTitle
} from './VideoLoader.styles'
import {redColor} from '../../../../@theme/vars'
import DeleteIcon from '@components/UI/Icons/DeleteIcon'
import {VideoLoaderProps} from './VideoLoaderProps'

const VideoLoader: FC<VideoLoaderProps> = ({video, setVideoTitle, setVideoUri}) => {
  const {keycloak, initialized} = useKeycloak()
  const [title, setTitle] = useState('')
  const [titleErrorText, setTitleErrorText] = useState('')
  const [titleDirty, setTitleDirty] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [currentVideoUri, setCurrentVideoUri] = useState('')

  useEffect(() => {
    if (video?.uid) {
      setTitle(video?.title || '')
      setCurrentVideoUri(video?.uri || '')
      setTitleDirty(false)
      setTitleErrorText('')
    }
  }, [video])

  const uploadFile = useCallback(
    e => {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      const requestOptions = {
        method: 'POST',
        headers: {
          authorization: initialized ? `Bearer ${keycloak.token}` : ''
        },
        body: formData
      }
      setVideoLoading(true)
      fetch(`${process.env.REACT_APP_UPLOADS_VIDEOS_URI}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          setVideoLoading(false)
          setCurrentVideoUri(data.data?.url)
          setVideoUri(data?.data?.url)
        })
      e.target.value = null
    },
    [initialized, keycloak.token, setVideoUri, setVideoLoading, setCurrentVideoUri]
  )

  useEffect(() => {
    if (title && title?.length > VIDEO_LOADER_CONSTS.maxLength) {
      setTitleErrorText(VIDEO_LOADER_CONSTS.characterLimitReachedMsg)
      setVideoTitle('')
    } else {
      setTitleErrorText('')
      setVideoTitle(title)
    }
    if (!title && titleDirty) {
      setTitleErrorText(VIDEO_LOADER_CONSTS.fieldIsRequiredMsg)
    }
  }, [title, titleDirty, setVideoTitle, setTitleErrorText])

  const handleChangeTitle = useCallback(
    e => {
      if (e?.target?.value) {
        setTitle(e.target.value)
      } else {
        setTitle('')
      }
      setTitleDirty(true)
    },
    [setTitle, setTitleDirty]
  )

  const handleDeleteVideo = useCallback(() => {
    setCurrentVideoUri('')
    setVideoUri('')
  }, [setCurrentVideoUri, setVideoUri])

  return (
    <MainContainer>
      <div>
        <VideoLoaderTitle>{VIDEO_LOADER_CONSTS.addYandexVideoMsg}</VideoLoaderTitle>
        <Box>
          <input
            id='contained-button-video'
            style={{display: 'none'}}
            type='file'
            accept='video/*'
            onChange={uploadFile}
            disabled={videoLoading}
          />
          <label htmlFor='contained-button-video'>
            <StyledUploadWrapper>
              {videoLoading === true ? (
                <CircularProgress size='3rem' style={{position: 'absolute'}} />
              ) : currentVideoUri ? (
                <StyledVideoIframe src={currentVideoUri} />
              ) : (
                <>
                  <Videocam />
                  <VideoBoxText>{VIDEO_LOADER_CONSTS.addVideoMsg}</VideoBoxText>
                </>
              )}
            </StyledUploadWrapper>
          </label>
        </Box>
      </div>
      <div>
        <Controller
          name='videoUrl'
          render={({field}) => (
            <StyledFormWrapper>
              <InputLabel>{VIDEO_LOADER_CONSTS.videoTitleMsg}</InputLabel>
              <OutlinedInput
                label={VIDEO_LOADER_CONSTS.videoTitleMsg}
                {...field}
                onChange={handleChangeTitle}
                value={title}
                multiline
                error={!!titleErrorText}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton edge='end' size='small'>
                      <Clear fontSize='small' onClick={handleChangeTitle} />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!!titleErrorText && (
                <FormHelperText
                  style={{
                    color: redColor
                  }}
                >
                  {titleErrorText}
                </FormHelperText>
              )}
            </StyledFormWrapper>
          )}
        />
        {currentVideoUri && (
          <StyledDeleteBtn>
            <Tooltip title={VIDEO_LOADER_CONSTS.setAnotherPhotoLabelMsg}>
              <IconButton edge='start' size='small' id='yandex-video-modal-delete-video' onClick={handleDeleteVideo}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </StyledDeleteBtn>
        )}
      </div>
    </MainContainer>
  )
}

export default VideoLoader
