import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {ExportType} from 'export-from-json'
import {IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'
import Clear from '@mui/icons-material/Clear'
import {
  KeyboardDatePickerWrapper1,
  KeyboardDatePickerWrapper2,
  FiltersRowContainer,
  AuthorPickerWrapper,
  RegionPickerWrapper,
  StyledFormControlWrapper,
  FilterFieldsWrapper,
  ButtonWrapper,
  ButtonsWrapper,
  FiltersWrapper,
  FiltersColumnContainer,
  SecondColumnContainer,
  MainRowContainer,
  ErrorContainer,
  ExportToFileButtonWrapper,
  RubricAndPublicationUrlContainer
} from './ReportsListFilters.styles'
import {useStore} from '@stores/rootStoreContext'
import {REPORTS_LIST_FILTERS_CONSTS} from './ReportsListFilters.consts'
import {ExportToFileButton} from '@components/UI/Buttons/ExportToFile/ExportToFile'
import {RubricAndSubrubricPicker} from '@components/UI/RubricAndSubrubricPicker/RubricAndSubrubricPicker'
import {ReportsListFiltersProps} from './ReportsListFiltersProps'
import {add, format} from 'date-fns'
import useDeviceDetect from '@utils/useDeviceDetect'

const ReportsListFilters: FC<any> = () => {
  const {reportsListStore} = useStore()
  const {increaseExportBtnCounter, increaseSearchBtnCounter, setFilters, setFileFormat} = reportsListStore

  const [datePublishedFrom, setDatePublishedFrom] = useState<any>(add(new Date(), {days: -1}))
  const [datePublishedTo, setDatePublishedTo] = useState<any>(new Date())
  const [viewsDateFrom, setViewsDateFrom] = useState<any>(add(new Date(), {days: -1}))
  const [viewsDateTo, setViewsDateTo] = useState<any>(new Date())
  const [authorUid, setAuthorUid] = useState<any>(null)
  const [regionUid, setRegionUid] = useState<any>(null)
  const [rubricUids, setRubricUids] = useState<string[]>([])
  const [subrubricUids, setSubrubricUids] = useState<string[]>([])
  const [publicationUrl, setPublicationUrl] = useState('')
  const [error, setError] = useState('')
  const [publicationUrlError, setPublicationUrlError] = useState('')

  const {windowWidth} = useDeviceDetect()
  const [fieldWidth, setFieldWidth] = useState('315px')

  useEffect(() => {
    if (!windowWidth) {
      setFieldWidth('315px')
      return
    }
    if (windowWidth > 650 && windowWidth < 980) {
      setFieldWidth('565px')
      return
    }
    if (windowWidth < 651) {
      setFieldWidth('100%')
    }
    if (windowWidth > 979) {
      setFieldWidth('315px')
    }
  }, [windowWidth])

  const handleChangeAuthor = async (user: any) => {
    setAuthorUid(user?.uid || null)
  }
  const handleChangeRegion = async (region: any) => {
    setRegionUid(region?.uid || null)
  }

  const changeSelectedRubricsAndSubrubrics = useCallback((val: any) => {
    if (val) {
      const rubrics: string[] = []
      const subrubrics: string[] = []

      Object.keys(val).forEach(i => {
        rubrics.push(i)

        if (val[i].children?.length) {
          val[i].children.forEach(c => {
            subrubrics.push(c.id)
          })
        }
      })

      setRubricUids(rubrics)
      setSubrubricUids(subrubrics)
      return
    }
    setRubricUids([])
    setSubrubricUids([])
  }, [])

  useEffect(() => {
    if (!datePublishedFrom || !datePublishedTo || !viewsDateFrom || !viewsDateTo) {
      setError(REPORTS_LIST_FILTERS_CONSTS.requiredFieldsError)
      return
    }
    if (datePublishedFrom <= add(datePublishedTo, {months: -1})) {
      setError(REPORTS_LIST_FILTERS_CONSTS.periodDateError)
      return
    }
    if (datePublishedFrom > datePublishedTo) {
      setError(REPORTS_LIST_FILTERS_CONSTS.startDateMustBeLessEndDateError)
      return
    }
    if (viewsDateFrom > viewsDateTo) {
      setError(REPORTS_LIST_FILTERS_CONSTS.startDateMustBeLessEndDateError)
      return
    }
    if (viewsDateFrom <= add(viewsDateTo, {months: -1})) {
      setError(REPORTS_LIST_FILTERS_CONSTS.periodDateError)
      return
    }
    setError('')
  }, [datePublishedFrom, datePublishedTo, viewsDateFrom, viewsDateTo, publicationUrl])

  useEffect(() => {
    if (publicationUrl && !(publicationUrl.startsWith('https://') || publicationUrl.startsWith('http://'))) {
      setPublicationUrlError(REPORTS_LIST_FILTERS_CONSTS.invalidUrlError)
      return
    }
    setPublicationUrlError('')
  })

  const search = useCallback(() => {
    setFilters({
      datePublishedFrom,
      datePublishedTo,
      viewsDateFrom,
      viewsDateTo,
      publicationUrl,
      authorUid,
      regionUid,
      rubricUids,
      subrubricUids
    } as ReportsListFiltersProps)
    increaseSearchBtnCounter()
  }, [
    datePublishedFrom,
    datePublishedTo,
    viewsDateFrom,
    viewsDateTo,
    publicationUrl,
    authorUid,
    regionUid,
    rubricUids,
    subrubricUids,
    increaseSearchBtnCounter,
    setFilters
  ])

  const exportToFile = useCallback(
    (fileFormat: ExportType) => {
      setFilters({
        datePublishedFrom,
        datePublishedTo,
        viewsDateFrom,
        viewsDateTo,
        publicationUrl,
        authorUid,
        regionUid,
        rubricUids,
        subrubricUids
      } as ReportsListFiltersProps)
      setFileFormat(fileFormat)
      increaseExportBtnCounter()
    },
    [
      datePublishedFrom,
      datePublishedTo,
      viewsDateFrom,
      viewsDateTo,
      publicationUrl,
      authorUid,
      regionUid,
      rubricUids,
      subrubricUids,
      setFileFormat,
      setFilters,
      increaseExportBtnCounter
    ]
  )

  return (
    <FiltersWrapper>
      <FilterFieldsWrapper>
        <MainRowContainer>
          <FiltersColumnContainer>
            <FiltersRowContainer>
              {/* <DateRangeEditor dateRange={dateRange} onChange={range => setDateRange(range)} /> */}
              <KeyboardDatePickerWrapper1
                disableToolbar
                variant='inline'
                inputFormat='yyyy-MM-dd'
                id='filters-publish-date-from'
                label={REPORTS_LIST_FILTERS_CONSTS.publishDateFromMsg}
                value={datePublishedFrom}
                maxDate={datePublishedTo}
                onChange={date => setDatePublishedFrom(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                slotProps={{
                  openPickerButton: {color: 'primary'},
                  field: {clearable: true, required: true, error: !datePublishedFrom}
                }}
              />
              <KeyboardDatePickerWrapper2
                disableToolbar
                variant='inline'
                inputFormat='yyyy-MM-dd'
                id='filters-publish-date-to'
                label={REPORTS_LIST_FILTERS_CONSTS.publishDateToMsg}
                value={datePublishedTo}
                minDate={datePublishedFrom}
                onChange={date => setDatePublishedTo(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                slotProps={{
                  openPickerButton: {color: 'primary'},
                  field: {clearable: true, required: true, error: !datePublishedTo}
                }}
              />
            </FiltersRowContainer>
            <FiltersRowContainer>
              <KeyboardDatePickerWrapper1
                disableToolbar
                variant='inline'
                inputFormat='yyyy-MM-dd'
                id='filters-views-date-from'
                label={REPORTS_LIST_FILTERS_CONSTS.viewsDateFromMsg}
                value={viewsDateFrom}
                maxDate={viewsDateTo}
                onChange={date => setViewsDateFrom(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                slotProps={{
                  openPickerButton: {color: 'primary'},
                  field: {clearable: true, required: true, error: !viewsDateFrom}
                }}
              />
              <KeyboardDatePickerWrapper2
                disableToolbar
                variant='inline'
                inputFormat='yyyy-MM-dd'
                id='filters-views-date-to'
                label={REPORTS_LIST_FILTERS_CONSTS.viewsDateToMsg}
                value={viewsDateTo}
                minDate={viewsDateFrom}
                onChange={date => setViewsDateTo(date)}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                slotProps={{
                  openPickerButton: {color: 'primary'},
                  field: {clearable: true, required: true, error: !viewsDateTo}
                }}
              />
            </FiltersRowContainer>
          </FiltersColumnContainer>
          <SecondColumnContainer>
            <AuthorPickerWrapper
              handleChangeAuthor={handleChangeAuthor}
              label={REPORTS_LIST_FILTERS_CONSTS.authorMsg}
              marginBottom='12px'
              width={fieldWidth}
            />
            <RegionPickerWrapper
              handleChangeRegion={handleChangeRegion}
              label={REPORTS_LIST_FILTERS_CONSTS.regionMsg}
              width={fieldWidth}
            />
          </SecondColumnContainer>
        </MainRowContainer>
        <FiltersColumnContainer>
          <RubricAndPublicationUrlContainer>
            <RubricAndSubrubricPicker changeSelectedRubricsAndSubrubrics={changeSelectedRubricsAndSubrubrics} />
            <StyledFormControlWrapper error={!!publicationUrlError}>
              <InputLabel htmlFor='filters-publication-link'>
                {REPORTS_LIST_FILTERS_CONSTS.publicationLinkMsg}
              </InputLabel>
              <OutlinedInput
                id='filters-publication-link'
                label={REPORTS_LIST_FILTERS_CONSTS.publicationLinkMsg}
                onChange={e => setPublicationUrl(e.target.value)}
                value={publicationUrl}
                endAdornment={
                  <InputAdornment position='end'>
                    {publicationUrl && (
                      <IconButton edge='end' size='small' onClick={() => setPublicationUrl('')}>
                        <Clear fontSize='small' />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </StyledFormControlWrapper>
          </RubricAndPublicationUrlContainer>
          {error && (
            <div>
              <ErrorContainer>{error}</ErrorContainer>
            </div>
          )}
          {publicationUrlError && (
            <div>
              <ErrorContainer>{publicationUrlError}</ErrorContainer>
            </div>
          )}
        </FiltersColumnContainer>
      </FilterFieldsWrapper>
      <ButtonsWrapper>
        <ButtonWrapper
          variant='contained'
          color='primary'
          size='small'
          onClick={search}
          disabled={!!error || !!publicationUrlError}
        >
          {REPORTS_LIST_FILTERS_CONSTS.searchMsg}
        </ButtonWrapper>
        <ExportToFileButtonWrapper>
          <ExportToFileButton setFileFormat={val => exportToFile(val)} disabled={!!error || !!publicationUrlError} />
        </ExportToFileButtonWrapper>
      </ButtonsWrapper>
    </FiltersWrapper>
  )
}

export default observer(ReportsListFilters)
