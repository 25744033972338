import React, {FC} from 'react'
import {IconButton} from '@mui/material'
import {CopyToClipboardIcon} from '../../Icons/CopyToClipboard'

type CopyToClipboardProps = {
  data: string
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({data}) => {
  const handleClick = data => {
    navigator.clipboard.writeText(data)
  }

  return (
    <div>
      <IconButton
        style={{
          padding: '3px',
          marginLeft: '10px'
        }}
        onClick={() => {
          handleClick(data)
        }}
        disabled={!data}
      >
        <CopyToClipboardIcon />
      </IconButton>
    </div>
  )
}

export default CopyToClipboard
