import React, {FC, useState} from 'react'
import {useTopNewsSelectionHistoryQuery} from './gql/TopNewsSelectionHistory.generated'
import {Box} from '@mui/material'
import {TopNewsBlock} from '@graphql/types'
import MainSelectionCard from '../../MainSelectionCard/MainSelectionCard'
import {format, isValid} from 'date-fns'
import {Wrapper, DatePickerWrapper, WrapperBlock} from './TopNewsSelectionHistory.styles'

type TopNewsSelectionHistoryProps = {
  regionId?: string
}

const TopNewsSelectionHistory: FC<TopNewsSelectionHistoryProps> = ({regionId}) => {
  const [searchDate, setSearchDate] = useState<any>(new Date())

  const {data} = useTopNewsSelectionHistoryQuery({
    variables: {regionId, date: searchDate && isValid(searchDate) ? format(searchDate, 'yyyy-MM-dd') : null}
  })
  return (
    <Wrapper>
      <DatePickerWrapper
        variant='inline'
        id='top-news-selection-history-date-filter'
        inputFormat='yyyy-MM-dd'
        label='Дата'
        value={searchDate}
        onChange={(e, date) => setSearchDate(date)}
        error={false}
        slotProps={{
          openPickerButton: {color: 'primary'}
        }}
      />
      <Box display='flex' flexWrap='wrap' justifyContent='flex-start' maxWidth='1310px' marginTop='20px'>
        {data?.topNewsSelectionHistory?.map((block, i) => (
          <WrapperBlock key={block?.uid}>
            <MainSelectionCard index={i} data={block as TopNewsBlock} read />
          </WrapperBlock>
        ))}
      </Box>
    </Wrapper>
  )
}

export default TopNewsSelectionHistory
