import React, {FC, useEffect, useState} from 'react'
import {DebugInfoWrapper} from './PublicationDebugInfo.styles'
import DebugInfo from '@components/TextEditor/DebugInfo/DebugInfo'

export type PublicationDebugInfoProps = {
  id: string
  oldAdminUrl: string
  url: string
}

export const PublicationDebugInfo: FC<PublicationDebugInfoProps> = props => {
  const [id, setId] = useState('')
  const [oldAdminUrl, setOldAdminUrl] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    setId(props.id)
    setOldAdminUrl(props.oldAdminUrl)
    setUrl(props.url)
  }, [props])

  return (
    <DebugInfoWrapper>
      <DebugInfo id={id} oldAdminUrl={oldAdminUrl} url={url} />
    </DebugInfoWrapper>
  )
}
