import styled from 'styled-components'
import {Accordion, AccordionSummary} from '@mui/material'
import {
  blueColor,
  greenColor,
  grey20,
  grey35,
  lightGrayColor2,
  mainTextColor,
  redColor,
  whiteColor
} from '../../../../../@theme/vars'

export const TopNewsBannerHistoryItemWrapper = styled.div`
  margin-bottom: 12px;
`

export const AccordionWrapper = styled(Accordion)`
  border: 1px solid ${grey35};
  border-radius: 6px;
  box-shadow: none;
  background-color: ${whiteColor};
  width: 100%;
  margin: 0px 0px;
`

export const AccordionSummaryWrapper = styled(AccordionSummary)`
  border-bottom: 1px solid ${grey35};
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  padding-right: 10px;

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px !important;
  }

  &.Mui-disabled {
    opacity: 1;
  }

  & div.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0px !important;
  }
`

export const ArrowDropDownIconWrapper = styled.div`
  color: ${mainTextColor};
  display: flex;
  flex-direction: row;
`

export const TitleWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  color: ${mainTextColor};
  padding-left: 4px;
  width: 100%;

  &.disabled {
    color: ${mainTextColor};
    opacity: 1;
  }
`

export const SelectedTitlesWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${mainTextColor};
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  width: 100%;

  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
  }
`

export const DetailsImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 282px;
  padding: 16px;
  height: 100%;
  border-right: 1px solid ${grey20};

  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
    border-right: none;
    border-top: 1px solid ${grey20};
  }
`

export const ImagePlaceContainer = styled.div`
  width: 250px;
  padding: 12px;
  background-color: ${lightGrayColor2};
  height: 179px;

  text-overflow: ellipsis;
`

export const DetailsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`

export const DetailsInfoTitleWrapper = styled.div`
  font-weight: 500;
  color: ${mainTextColor};
`

export const PublicationTitleWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`

export const DetailOldValue = styled.div`
  color: ${redColor};
  text-decoration: line-through;
`

export const DetailNewValue = styled.div`
  color: ${greenColor};
  text-decoration: none;
`

export const UrlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const OldUrl = styled.div`
  color: ${redColor};
  text-decoration: line-through;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
`

export const NewUrl = styled.div`
  color: ${greenColor};
  text-decoration: none;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
`
