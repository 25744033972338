import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1304px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 70vh;

  @media screen and (max-width: 768px) {
    height: 55vh;
  }
`
