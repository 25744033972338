import {FC, memo} from 'react'
import {Checkbox, InputLabel, OutlinedInput} from '@mui/material'
import {BUTTON_LABELS, CHECKBOX_LABEL, INPUT_LABELS} from '../ListSameNames.consts'
import SameNamesInputsProps from './SameNamesInputsProps'
import {Button, ContainerInputs, FooterContainer, FormControlLabel, StyledFormControl} from './SameNamesInputs.styles'

const SameNamesInputs: FC<SameNamesInputsProps> = ({
  isOrganization,
  isUpdate,
  isLoading,
  setIsOrganization,
  firstNameValue,
  lastNameValue,
  descriptionValue,
  handleChange,
  onClickCreate,
  onClickUpdate,
  onCancelUpdate,
  isEmpty,
  hasChanges
}) => {
  return (
    <ContainerInputs>
      {!isOrganization && (
        <StyledFormControl disabled={isLoading}>
          <InputLabel>{INPUT_LABELS.firstName}</InputLabel>
          <OutlinedInput
            label={INPUT_LABELS.firstName}
            inputProps={{'data-testid': 'first-name-input'}}
            value={firstNameValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'firstName')}
          />
        </StyledFormControl>
      )}
      <StyledFormControl disabled={isLoading}>
        <InputLabel>{isOrganization ? INPUT_LABELS.organization : INPUT_LABELS.lastName}</InputLabel>
        <OutlinedInput
          label={isOrganization ? INPUT_LABELS.organization : INPUT_LABELS.lastName}
          inputProps={{'data-testid': 'last-name-input'}}
          value={lastNameValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'lastName')}
        />
      </StyledFormControl>
      <StyledFormControl disabled={isLoading}>
        <InputLabel>{INPUT_LABELS.description}</InputLabel>
        <OutlinedInput
          label={INPUT_LABELS.description}
          inputProps={{'data-testid': 'description-input'}}
          value={descriptionValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, 'description')}
        />
      </StyledFormControl>
      <FooterContainer>
        {isUpdate ? (
          <>
            <Button
              onClick={onClickUpdate}
              variant='contained'
              color='primary'
              disabled={!hasChanges || isLoading || isEmpty}
            >
              {BUTTON_LABELS.update}
            </Button>
            <Button onClick={onCancelUpdate} variant='outlined' color='primary'>
              {BUTTON_LABELS.cancel}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClickCreate} variant='contained' color='primary' disabled={isEmpty || isLoading}>
              {BUTTON_LABELS.create}
            </Button>
            <FormControlLabel
              label={CHECKBOX_LABEL}
              control={<Checkbox checked={isOrganization} onChange={setIsOrganization} />}
            />
          </>
        )}
      </FooterContainer>
    </ContainerInputs>
  )
}

export default memo(SameNamesInputs)
