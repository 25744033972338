import React, {FC, useEffect, useState} from 'react'
import {MAIN_PAGE_MODAL} from './MainPageModal.consts'
import {CellButton, MainPageCell} from './MainPageModal.styles'
import {whiteColor} from '../../../../../../@theme/vars'
import {MainPageModalProps} from './MainPageModalProps'

export const MainPageModal: FC<MainPageModalProps> = ({onClose, setMainPlacePage, selectedPosition}) => {
  const places = Array.from(Array(15 + 1).keys()).slice(1)
  const [checkedPos, setCheckedPos] = useState<number | null>(null)

  useEffect(() => {
    if (+selectedPosition !== null) {
      setCheckedPos(+selectedPosition)
    } else {
      setCheckedPos(null)
    }
  }, [selectedPosition])

  return (
    <MainPageCell>
      {places &&
        places.map((item, ind) => (
          <CellButton
            key={item}
            onClick={() => {
              setMainPlacePage(item)
              onClose()
            }}
            style={{
              backgroundColor: `${checkedPos === ind + 1 ? '#F1F6FF' : whiteColor}`
            }}
          >
            {item}
          </CellButton>
        ))}
      <CellButton
        onClick={() => {
          setMainPlacePage(null)
          onClose()
        }}
        style={{
          width: '192px',
          backgroundColor: `${!checkedPos ? '#F1F6FF' : whiteColor}`
        }}
      >
        {MAIN_PAGE_MODAL.dontShowMsg}
      </CellButton>
    </MainPageCell>
  )
}
