import {styled} from 'styled-components'
import {Button} from '@mui/material'
import {black80, blueColor, grayColor, screenS, secondTextColor} from '@theme/vars'

export const GalleryBlockItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  &:hover {
    cursor: auto;
  }
`

export const GalleryBlockInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  @media screen and (max-width: ${screenS}) {
    flex-direction: column;
  }
`

export const GalleryBlockImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${screenS}) {
    margin: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  & #announcePhoto {
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    & figure {
      width: 245px;

      @media screen and (max-width: ${screenS}) {
        width: 100%;
        max-width: 500px;
        margin-bottom: 24px;
      }

      & .change-item {
        &:not(.video-options > .change-item, .video-options > .delete-item) {
          position: absolute;
        }
        opacity: 0.8;
        border-radius: 80%;
        height: 26px;
        width: 26px;
        margin: 6px 6px 6px 0px;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }

      & .change-item {
        margin-left: 214px;

        @media screen and (max-width: ${screenS}) {
          margin-left: 455px;
        }

        @media screen and (max-width: 520px) {
          margin-left: calc(100% - 65px);
        }
      }
    }
  }
`

export const ImageBox = styled.div<{url: string}>`
  height: 144px;
  width: 200px;
  border-radius: 4px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${black80};
  background-image: url('${({url}) => url}');

  @media screen and (max-width: ${screenS}) {
    width: 100%;
    max-width: 200px;
    height: auto;
    aspect-ratio: 1.3888;
  }
`

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 5px;
`

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`

export const ViewSourceImageWrapper = styled.a`
  color: ${blueColor};
  margin-top: 0px;
  text-decoration: underline;
`

export const GalleryBlockInfoFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 460px;

  @media screen and (max-width: ${screenS}) {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
`

export const AuthorWrapper = styled.div`
  display: flex;
`

export const DeleteBlockWrapper = styled.div`
  align-self: flex-end;
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const DeleteFromGalleryButton = styled(Button)`
  padding: 0;

  &:hover {
    background-color: inherit;

    & div {
      color: ${secondTextColor};
    }
  }
`

export const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 8px 10px;
`

export const DeleteFromGalleryText = styled.div`
  display: flex;
  margin-left: 4px;
  color: ${grayColor};
`
