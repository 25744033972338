import styled from 'styled-components'

export const StyledTitleAdPublicationList = styled.h2`
    margin: 12px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #222;
`

export const AdTitleWrapper = styled.div`
    margin-bottom: 8px;
    margin-top: 25px;
    display: row;
`
