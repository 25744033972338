import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {IconButton} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers'
import {Clear} from '@mui/icons-material'
import {CustomDatePickerProps} from './CustomDatePickerProps'
import {CUSTOM_DATE_PICKER_CONSTS} from './CustomDatePicker.consts'

const KeyboardDatePickerWrapper = styled(DatePicker)`
  display: flex;
  width: 274px;
  & input {
    padding: 0;
  }

  @media (max-width: 650px) {
    margin-bottom: 12px;
    width: calc(100% - 20px);
  }
`

const CustomDatePicker: FC<CustomDatePickerProps> = ({name, id, value, setInvalidDate, setDate, maxDate, minDate}) => {
  const [curMaxDate, setCurMaxDate] = useState<Date | string>(new Date('2100-01-01'))
  const [curMinDate, setCurMinDate] = useState<Date | string>(new Date('1900-01-01'))
  const [curId, setCurId] = useState('')
  const [curName, setCurName] = useState('')
  const [curValue, setCurValue] = useState<any>(null)
  const curSetInvalidDate = setInvalidDate
  const setCurDate = setDate

  const datePickerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setCurName(name || CUSTOM_DATE_PICKER_CONSTS.dateMsg)
  }, [name])

  useEffect(() => {
    setCurMaxDate(maxDate || new Date('2100-01-01'))
  }, [maxDate])

  useEffect(() => {
    setCurMinDate(minDate || new Date('1900-01-01'))
  }, [minDate])

  useEffect(() => {
    setCurId(id || 'custom-date-picker')
  }, [id])

  useEffect(() => {
    setCurValue(value)
  }, [value])

  const changeDate = useCallback(
    date => {
      if (date !== null && date?.toString()?.toLowerCase()?.includes('invalid')) {
        curSetInvalidDate(CUSTOM_DATE_PICKER_CONSTS.invalidDateMsg)
      } else if (date && date < curMinDate) {
        curSetInvalidDate(CUSTOM_DATE_PICKER_CONSTS.minDateMsg)
      } else if (date && date > curMaxDate) {
        curSetInvalidDate(CUSTOM_DATE_PICKER_CONSTS.maxDateMsg)
      } else {
        curSetInvalidDate('')
      }
      setCurValue(date)
      setCurDate(date)
    },
    [setCurValue, setCurDate, curSetInvalidDate]
  )

  return (
    <>
      <div ref={datePickerRef}>
        <KeyboardDatePickerWrapper
          autoOk={true}
          disableToolbar
          variant='inline'
          inputFormat='yyyy-MM-dd'
          maxDate={curMaxDate}
          maxDateMessage={CUSTOM_DATE_PICKER_CONSTS.maxDateMsg}
          minDate={curMinDate}
          minDateMessage={CUSTOM_DATE_PICKER_CONSTS.minDateMsg}
          invalidDateMessage={CUSTOM_DATE_PICKER_CONSTS.invalidDateMsg}
          id={curId}
          label={curName}
          value={curValue}
          onChange={changeDate}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </div>
      {curValue && (
        <IconButton
          edge='end'
          size='small'
          onClick={() => changeDate(null)}
          style={{
            position: 'absolute',
            marginTop: '8px',
            left: `${(datePickerRef?.current?.offsetLeft || 0) + (datePickerRef?.current?.clientWidth || 0) - 80}px`
          }}
        >
          <Clear fontSize='small' />
        </IconButton>
      )}
    </>
  )
}

export default CustomDatePicker
