import React, {FC} from 'react'

const NnIcon: FC<any> = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.6667 4.16667L12.8917 7.27268C13.0483 7.66973 13.1266 7.86825 13.2464 8.03565C13.3527 8.18404 13.4826 8.31396 13.631 8.42023C13.7984 8.54012 13.9969 8.61841 14.394 8.77501L17.5 10L14.394 11.225C13.9969 11.3816 13.7984 11.4599 13.631 11.5798C13.4826 11.686 13.3527 11.816 13.2464 11.9643C13.1266 12.1317 13.0483 12.3303 12.8917 12.7273L11.6667 15.8333L10.4417 12.7273C10.2851 12.3303 10.2068 12.1317 10.0869 11.9643C9.98062 11.816 9.8507 11.686 9.70232 11.5798C9.53492 11.4599 9.33639 11.3816 8.93935 11.225L5.83333 10L8.93934 8.77501C9.33639 8.61841 9.53492 8.54012 9.70232 8.42023C9.8507 8.31396 9.98062 8.18404 10.0869 8.03565C10.2068 7.86825 10.2851 7.66973 10.4417 7.27268L11.6667 4.16667Z'
        fill='#222222'
      />
      <path
        d='M4.16667 2.5V5.83333M5.83333 4.16667H2.5M4.16667 14.1667V17.5M5.83333 15.8333H2.5M11.6667 4.16667L12.8917 7.27268C13.0483 7.66973 13.1266 7.86825 13.2464 8.03565C13.3527 8.18404 13.4826 8.31396 13.631 8.42023C13.7984 8.54012 13.9969 8.61841 14.394 8.77501L17.5 10L14.394 11.225C13.9969 11.3816 13.7984 11.4599 13.631 11.5798C13.4826 11.686 13.3527 11.816 13.2464 11.9643C13.1266 12.1317 13.0483 12.3303 12.8917 12.7273L11.6667 15.8333L10.4417 12.7273C10.2851 12.3303 10.2068 12.1317 10.0869 11.9643C9.98062 11.816 9.8507 11.686 9.70232 11.5798C9.53492 11.4599 9.33639 11.3816 8.93935 11.225L5.83333 10L8.93934 8.77501C9.33639 8.61841 9.53492 8.54012 9.70232 8.42023C9.8507 8.31396 9.98062 8.18404 10.0869 8.03565C10.2068 7.86825 10.2851 7.66973 10.4417 7.27268L11.6667 4.16667Z'
        stroke='#222222'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default NnIcon
