import {CommentStatus} from '@graphql/types'
import {defaultPublication} from '@stores/publicationStore/publicationStore.defaultValues'
import {UiAutoModeratorResult} from 'entities/types/UiAutoModeratorResult'
import {UiComment} from 'entities/types/UiComment'
import {UiCommentAuthor} from 'entities/types/UiCommentAuthor'

const defaultAuthor: UiCommentAuthor = {
  ipAddress: '',
  name: '',
  sessionId: '',
  user: undefined
}

const defaultAutoModeratorResult: UiAutoModeratorResult = {
  authorName: '',
  text: ''
}

const defaultComment: UiComment = {
  allComments: 0,
  author: defaultAuthor,
  autoModeratorResult: defaultAutoModeratorResult,
  createdAt: new Date(),
  child: undefined,
  id: '',
  isBad: undefined,
  moderatedAt: new Date(),
  newComments: 0,
  parentComment: undefined,
  publication: defaultPublication,
  status: CommentStatus.Review,
  text: '',
  uid: '',
  updatedAt: new Date(),
  viewed: false
}

export default defaultComment
