import {Quill} from 'react-quill'

const Embed = Quill.import('blots/block/embed')

export type HTMLBlot = {
  id: string
  html: string
}

export default class Html extends Embed {
  constructor(node: HTMLElement) {
    super(node)
  }

  static create(htmlBlot: HTMLBlot): HTMLElement {
    const node = super.create(htmlBlot)
    const {id, html} = htmlBlot
    node.setAttribute('data-id', 'html')
    node.setAttribute('html', html)
    node.setAttribute('id', id)
    node.id = id
    // node.innerText = value
    const childNode = document.createElement('div')
    childNode.innerText = html
    node.append(childNode)
    node.setAttribute('contenteditable', false)

    const htmlButtons = document.createElement('div')
    htmlButtons.classList.add('htmlButtons')

    const deleteHtml = document.createElement('img')
    deleteHtml.setAttribute('src', '/deleteItem.jpg')
    deleteHtml.setAttribute('draggable', 'false')
    deleteHtml.classList.add('delete-item')

    const deleteHtmlEvent = new CustomEvent('deleteHtmlEvent', {
      detail: {id},
      bubbles: true
    })
    deleteHtml.addEventListener('click', () => {
      return window.dispatchEvent(deleteHtmlEvent)
    })

    const changeHtml = document.createElement('img')
    changeHtml.setAttribute('src', '/changeItem.jpg')
    changeHtml.setAttribute('draggable', 'false')
    changeHtml.classList.add('change-item')

    htmlButtons.prepend(changeHtml)
    htmlButtons.prepend(deleteHtml)
    node.prepend(htmlButtons)

    const changeHtmlEvent = new CustomEvent('openModalHTML', {
      detail: {htmlBlot},
      bubbles: true
    })
    changeHtml.addEventListener('click', () => {
      return window.dispatchEvent(changeHtmlEvent)
    })
    return node
  }

  static value(node: HTMLElement): HTMLBlot {
    return {
      id: node.getAttribute('id') || '',
      html: node.getAttribute('html') || ''
    }
  }
}

Html.tagName = 'div'
Html.className = 'html'
Html.blotName = 'custom-html'
