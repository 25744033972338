import * as Types from '../../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type BannerQueryVariables = Types.Exact<{
  regionUid: Types.Scalars['UUID']
}>

export type BannerQuery = {__typename?: 'Query'} & {
  banner?: Types.Maybe<{__typename?: 'Banner'} & BannerFragmentFragment>
}

export type UpdateBannerMutationVariables = Types.Exact<{
  data: Types.UpdateBannerInput
}>

export type UpdateBannerMutation = {__typename?: 'Mutation'} & {
  updateBanner?: Types.Maybe<{__typename?: 'UpdateBannerPayload'} & Pick<Types.UpdateBannerPayload, 'status'>>
}

export type UpdateBannerForAllRegionsMutationVariables = Types.Exact<{
  data: Types.UpdateBannerForAllRegionsInput
}>

export type UpdateBannerForAllRegionsMutation = {__typename?: 'Mutation'} & {
  updateBannerForAllRegions?: Types.Maybe<
    {__typename?: 'UpdateBannerForAllRegionsPayload'} & Pick<Types.UpdateBannerForAllRegionsPayload, 'status'>
  >
}

export type BannerFragmentFragment = {__typename?: 'Banner'} & Pick<
  Types.Banner,
  'createdAt' | 'description' | 'id' | 'title' | 'uid' | 'updatedAt' | 'url'
> & {
    region: {__typename?: 'Region'} & Pick<Types.Region, 'title' | 'uid'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'uid' | 'src'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
        latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'uid'> & {
            crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'width' | 'height' | 'left' | 'top'>
            primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
          }
      }
  }

export const BannerFragmentFragmentDoc = gql`
  fragment BannerFragment on Banner {
    createdAt
    description
    id
    region {
      title
      uid
    }
    title
    uid
    updatedAt
    url
    figure {
      uid
      cropperPreview {
        url
      }
      src
      latestVersion {
        uid
        crop {
          width
          height
          left
          top
        }
        primaryProcessedImage {
          url
        }
      }
    }
  }
`
export const BannerDocument = gql`
  query banner($regionUid: UUID!) {
    banner(regionUid: $regionUid) {
      ...BannerFragment
    }
  }
  ${BannerFragmentFragmentDoc}
`

/**
 * __useBannerQuery__
 *
 * To run a query within a React component, call `useBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerQuery({
 *   variables: {
 *      regionUid: // value for 'regionUid'
 *   },
 * });
 */
export function useBannerQuery(baseOptions: Apollo.QueryHookOptions<BannerQuery, BannerQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<BannerQuery, BannerQueryVariables>(BannerDocument, options)
}
export function useBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BannerQuery, BannerQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<BannerQuery, BannerQueryVariables>(BannerDocument, options)
}
export type BannerQueryHookResult = ReturnType<typeof useBannerQuery>
export type BannerLazyQueryHookResult = ReturnType<typeof useBannerLazyQuery>
export type BannerQueryResult = Apollo.QueryResult<BannerQuery, BannerQueryVariables>
export const UpdateBannerDocument = gql`
  mutation updateBanner($data: UpdateBannerInput!) {
    updateBanner(data: $data) {
      status
    }
  }
`
export type UpdateBannerMutationFn = Apollo.MutationFunction<UpdateBannerMutation, UpdateBannerMutationVariables>

/**
 * __useUpdateBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerMutation, { data, loading, error }] = useUpdateBannerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBannerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBannerMutation, UpdateBannerMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(UpdateBannerDocument, options)
}
export type UpdateBannerMutationHookResult = ReturnType<typeof useUpdateBannerMutation>
export type UpdateBannerMutationResult = Apollo.MutationResult<UpdateBannerMutation>
export type UpdateBannerMutationOptions = Apollo.BaseMutationOptions<
  UpdateBannerMutation,
  UpdateBannerMutationVariables
>
export const UpdateBannerForAllRegionsDocument = gql`
  mutation updateBannerForAllRegions($data: UpdateBannerForAllRegionsInput!) {
    updateBannerForAllRegions(data: $data) {
      status
    }
  }
`
export type UpdateBannerForAllRegionsMutationFn = Apollo.MutationFunction<
  UpdateBannerForAllRegionsMutation,
  UpdateBannerForAllRegionsMutationVariables
>

/**
 * __useUpdateBannerForAllRegionsMutation__
 *
 * To run a mutation, you first call `useUpdateBannerForAllRegionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerForAllRegionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerForAllRegionsMutation, { data, loading, error }] = useUpdateBannerForAllRegionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBannerForAllRegionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBannerForAllRegionsMutation,
    UpdateBannerForAllRegionsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateBannerForAllRegionsMutation, UpdateBannerForAllRegionsMutationVariables>(
    UpdateBannerForAllRegionsDocument,
    options
  )
}
export type UpdateBannerForAllRegionsMutationHookResult = ReturnType<typeof useUpdateBannerForAllRegionsMutation>
export type UpdateBannerForAllRegionsMutationResult = Apollo.MutationResult<UpdateBannerForAllRegionsMutation>
export type UpdateBannerForAllRegionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateBannerForAllRegionsMutation,
  UpdateBannerForAllRegionsMutationVariables
>
