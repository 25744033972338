const checkIsQuoteClosing = (index: number, substring: string): boolean => {
  // Если начало строки, то это открывающая кавычка, возвращаем false
  if (index === 0) {
    return false
  }

  for (let i = 0; i < substring.length; i++) {
    const element = substring[i]

    // Если после кавычки есть буквы и цифры, то это открывающая кавычка, возвращаем false
    if (/[A-ZА-Я0-9]/i.test(element)) {
      return false
    }

    // Если после кавычки есть открывающие скобки, то это открывающая кавычка, возвращаем false
    if (/[\(\[\<]/.test(element)) {
      return false
    }

    // Если после кавычки есть закрывающие скобки, то это закрывающая кавычка, возвращаем true
    if (/[\)\]\>]/.test(element)) {
      return true
    }

    // Если после кавычки есть пробел, то это закрывающая кавычка, возвращаем true
    if (/\s/.test(element)) {
      return true
    }
  }

  return true
}

/**
 * @param {string} text - Строка, в которой будут заменены кавычки
 * @param {Object.<string, string>} QuotePairs - Объект содержащий пары ключ - значение, где ключ - это открывающая кавычка, а значение - закрывающая
 * @param {RegExp} quotesRegExp - Регулярное выражение, содержащее символы, которые будут заменяться на кавычки
 * @param {Array.<string>} quoteLevels - Массив, описывающий уровни вложенности кавычек.
 * Массив с тремя разными открывающими кавычками ["«", "„", "‚"] вернет «„‚Hello world‘“», а ["«"] вернет «««Hello world»»»
 * @returns {string} Строка с замененными кавычками
 * @description Автоподмена кавычек
 */

export const quotesReplacer = (
  text: string,
  QuotePairs: Record<string, string>,
  quotesRegExp = /\"/,
  quoteLevels = ['«']
): string => {
  const result: string[] = []
  const stack: number[] = []

  for (let i = 0; i < text.length; i++) {
    result[i] = text[i]
    let isOpeningQuote = false
    let isClosingQuote = false

    if (quotesRegExp.test(text[i])) {
      const rightSubstring = text.slice(i + 1, text.length)
      isClosingQuote = checkIsQuoteClosing(i, rightSubstring)
      isOpeningQuote = !isClosingQuote
    }

    if (isOpeningQuote) {
      const openingQuote = quoteLevels[stack.length % quoteLevels.length]
      result[i] = openingQuote
      stack.push(i)
    }

    if (isClosingQuote) {
      stack.pop()
      const openingQuote = quoteLevels[stack.length % quoteLevels.length]
      const closingQuote = QuotePairs[openingQuote]
      result[i] = closingQuote
    }
  }

  return result.join('')
}
