import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Typography} from '@mui/material'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatClearIcon from '@mui/icons-material/FormatClear'
import LinkIcon from '@mui/icons-material/Link'
import ReceiptIcon from '@mui/icons-material/Receipt'
import YouTubeIcon from '@mui/icons-material/YouTube'
import ReactQuill, {Quill} from 'react-quill'
import {observer} from 'mobx-react-lite'
import Tooltip from '@components/UI/Tooltip/Tooltip'
import LinkOffIcon from '@components/UI/Icons/LinkOff'
import BookOpenIcon from '@components/UI/Icons/BookOpen'
import FullScreenIcon from '@components/UI/Icons/FullScreen'
import FullScreenCloseIcon from '@components/UI/Icons/FullScreenClose'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'
import {EyeIcon} from '@components/UI/Icons/EyeIcon'
import useDeviceDetect from '@utils/useDeviceDetect'
import {useStore} from '@stores/rootStoreContext'
import {ArrowBack} from '../SvgIcon/ArrowBack'
import {ArrowForward} from '../SvgIcon/ArrowForward'
import {AlignHorizontal} from '../SvgIcon/AlignHorizontal'
import {BlockquoteBlot} from '../Blots'
import ToolbarVideo from './ToolbarVideo/ToolbarVideo'
import ToolbarHtml from './ToolbarHtml/ToolbarHtml'
import ToolbarImage from './ToolbarImage/ToolbarImage'
import ToolbarSidebar from './ToolbarSidebar/ToolbarSidebar'
import ToolbarSize from './ToolbarSize/ToolbarSize'
import ToolbarNn from './ToolbarNn/ToolbarNn'
import {StyledCounter, StyledToggleButton, StyledToggleButtonGroup, ToolbarWrapper} from './Toolbar.styles'
import ToolbarLink from './ToolbarLink/ToolbarLink'
import {TOOLBAR_CONSTS} from './Toolbar.consts'

const icons = Quill?.import('ui/icons')

type ToolbarProps = {
  toolbarId: string
  editorLength: number
  editorRef: React.MutableRefObject<ReactQuill | null>
  handleFocus: () => void
  runTypograph: () => void
  toggleFullScreen: () => void
  isEditorFullScreen: boolean
}

const Toolbar: FC<ToolbarProps> = ({
  toolbarId,
  editorLength,
  editorRef,
  handleFocus,
  runTypograph,
  toggleFullScreen,
  isEditorFullScreen
}) => {
  const {publicationStore} = useStore()
  const {publication, imageModalStore} = publicationStore
  const [isOpenInsertModal, setIsOpenInsertModal] = useState(false)
  const [isOpenSidebarModal, setIsOpenSidebarModal] = useState(false)
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false)
  const [isOpenHtmlModal, setIsOpenHtmlModal] = useState(false)
  const {isMobile} = useDeviceDetect()
  const [showExtendedFields, setShowExtendedFields] = useState(true)

  icons.bold = useMemo(() => <FormatBoldIcon />, [])
  icons.italic = useMemo(() => <FormatItalicIcon />, [])
  icons.clean = useMemo(() => <FormatClearIcon />, [])
  icons.list = useMemo(() => <FormatListBulletedIcon />, [])
  icons.size = useMemo(() => <Typography variant='h1'>T</Typography>, [])

  const undo = useCallback(() => {
    const quill = (editorRef.current as any).getEditor()
    const range = quill.getSelection(true)
    if (range) {
      setTimeout(() => {
        quill.history.undo()
      }, 0)
    }
  }, [editorRef])

  const redo = useCallback(() => {
    const quill = (editorRef.current as any).getEditor()
    const range = quill.getSelection(true)
    if (range) {
      setTimeout(() => {
        quill.history.redo()
      }, 0)
    }
  }, [editorRef])

  const removeLink = useCallback(() => {
    if (editorRef.current) {
      const quill = (editorRef.current as any).getEditor()
      const range = quill.getSelection(true)

      if (range) {
        setTimeout(() => {
          quill.format('link', false, 'user')
        }, 200)
      }
    }
  }, [editorRef])

  const goToPreview = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_PREVIEW_URL + (publication.typeSlug as string)}/${publication.deprecatedId}`,
      '_blank'
    )
  }, [publication])

  useEffect(() => {
    setShowExtendedFields(isEditorFullScreen || !isMobile)
  }, [isMobile, isEditorFullScreen])

  return (
    <>
      <ToolbarWrapper id={toolbarId}>
        <StyledToggleButtonGroup>
          <ControlTooltip title={isEditorFullScreen ? TOOLBAR_CONSTS.exitFullScreenMsg : TOOLBAR_CONSTS.fullScreenMsg}>
            <StyledToggleButton
              id='toolbar-typograph-fullscreen-btn'
              value='typograph'
              aria-label='typograph'
              onClick={toggleFullScreen}
            >
              {isEditorFullScreen ? <FullScreenCloseIcon /> : <FullScreenIcon />}
            </StyledToggleButton>
          </ControlTooltip>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup>
          <Tooltip title={TOOLBAR_CONSTS.typographMsg}>
            <StyledToggleButton
              id='toolbar-typograph-btn'
              value='typograph'
              aria-label='typograph'
              onClick={runTypograph}
            >
              <BookOpenIcon />
            </StyledToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup>
          <Tooltip title={TOOLBAR_CONSTS.cancelMsg}>
            <StyledToggleButton value='left' aria-label='left aligned' onClick={undo} id='toolbar-left-btn'>
              <ArrowBack />
            </StyledToggleButton>
          </Tooltip>
          {showExtendedFields && (
            <Tooltip title={TOOLBAR_CONSTS.repeatMsg}>
              <StyledToggleButton value='center' aria-label='centered' onClick={redo} id='toolbar-centered-btn'>
                <ArrowForward />
              </StyledToggleButton>
            </Tooltip>
          )}
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup size='small' aria-label='text formatting' id='toolbar-small-btn-group'>
          <ToolbarSize editorRef={editorRef} />
          {showExtendedFields && (
            <Tooltip title={TOOLBAR_CONSTS.boldfaceMsg}>
              <StyledToggleButton aria-label='bold' ql='bold' value='bold' id='toolbar-bold-btn'>
                <FormatBoldIcon />
              </StyledToggleButton>
            </Tooltip>
          )}
          {showExtendedFields && (
            <Tooltip title={TOOLBAR_CONSTS.italicMsg}>
              <StyledToggleButton aria-label='italic' ql='italic' value='italic' id='toolbar-italic-btn'>
                <FormatItalicIcon />
              </StyledToggleButton>
            </Tooltip>
          )}
          {showExtendedFields && (
            <Tooltip title={TOOLBAR_CONSTS.numberedListMsg}>
              <StyledToggleButton aria-label='ordered' ql='list' value='ordered' id='toolbar-ordered-btn'>
                <FormatListNumberedIcon />
              </StyledToggleButton>
            </Tooltip>
          )}
          {showExtendedFields && (
            <Tooltip title={TOOLBAR_CONSTS.bulletedListMsg}>
              <StyledToggleButton aria-label='bullet' ql='list' value='bullet' id='toolbar-bullet-btn'>
                <FormatListBulletedIcon />
              </StyledToggleButton>
            </Tooltip>
          )}
          {showExtendedFields && (
            <Tooltip title={TOOLBAR_CONSTS.cancelFormattingMsg}>
              <StyledToggleButton aria-label='clean' ql='clean' value='clean' id='toolbar-clean-btn'>
                <FormatClearIcon />
              </StyledToggleButton>
            </Tooltip>
          )}
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup>
          <Tooltip title={TOOLBAR_CONSTS.linkMsg}>
            <StyledToggleButton
              value='LinkIcon'
              aria-label='left aligned'
              onClick={() => setIsOpenInsertModal(true)}
              id='toolbar-link-btn'
            >
              <LinkIcon />
            </StyledToggleButton>
          </Tooltip>
          {showExtendedFields && (
            <Tooltip title={TOOLBAR_CONSTS.removeLinkMsg}>
              <StyledToggleButton
                value='LinkIcon'
                aria-label='left aligned'
                onClick={removeLink}
                id='toolbar-remove-link-btn'
              >
                <LinkOffIcon />
              </StyledToggleButton>
            </Tooltip>
          )}
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup>
          <Tooltip title={TOOLBAR_CONSTS.takeoutForTextMsg}>
            <StyledToggleButton
              aria-label='left aligned'
              ql={BlockquoteBlot.blotName}
              value={BlockquoteBlot.blotName}
              id={`toolbar-${BlockquoteBlot.blotName}-btn`}
            >
              <AlignHorizontal />
            </StyledToggleButton>
          </Tooltip>
          <Tooltip title={TOOLBAR_CONSTS.pasteSidebarMsg}>
            <StyledToggleButton
              value='ReceiptIcon'
              id='toolbar-sidebar-btn'
              aria-label='left aligned'
              onClick={() => setIsOpenSidebarModal(true)}
            >
              <ReceiptIcon />
            </StyledToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup
          size='small'
          aria-label='text formatting'
          id='toolbar-small-btn-group'
        ></StyledToggleButtonGroup>
        <StyledToggleButtonGroup>
          <Tooltip title={TOOLBAR_CONSTS.pasteImageMsg}>
            <ToolbarImage editorRef={editorRef} />
          </Tooltip>
          <Tooltip title={TOOLBAR_CONSTS.pasteVideoMsg}>
            <StyledToggleButton
              value='video'
              aria-label='video'
              onClick={() => setIsOpenVideoModal(true)}
              id='toolbar-video-btn'
            >
              <YouTubeIcon />
            </StyledToggleButton>
          </Tooltip>
          <Tooltip title={TOOLBAR_CONSTS.pasteHtmlMsg}>
            <StyledToggleButton
              value='YouTubeIcon'
              aria-label='left aligned'
              onClick={() => setIsOpenHtmlModal(true)}
              id='toolbar-html-btn'
            >
              HTML
            </StyledToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        {publication?.deprecatedId && (
          <StyledToggleButtonGroup>
            <Tooltip title={TOOLBAR_CONSTS.previewMsg}>
              <StyledToggleButton id='toolbar-preview-btn' value='preview' onClick={goToPreview}>
                <EyeIcon />
              </StyledToggleButton>
            </Tooltip>
          </StyledToggleButtonGroup>
        )}
        <StyledToggleButtonGroup size='small' aria-label='text formatting' id='toolbar-small-btn-group'>
          <ToolbarNn />
        </StyledToggleButtonGroup>
        <StyledCounter>
          {TOOLBAR_CONSTS.textMsg}: {editorLength}
        </StyledCounter>
      </ToolbarWrapper>
      <ToolbarLink
        editorRef={editorRef}
        isOpenInsertModal={isOpenInsertModal}
        closeDialogLink={() => setIsOpenInsertModal(false)}
        handleFocus={handleFocus}
      />
      <ToolbarSidebar
        editorRef={editorRef}
        isOpenSidebarModal={isOpenSidebarModal}
        closeDialogSidebar={() => setIsOpenSidebarModal(false)}
      />
      <ToolbarHtml
        editorRef={editorRef}
        isOpenHtmlModal={isOpenHtmlModal}
        closeDialogHtml={() => setIsOpenHtmlModal(false)}
      />
      <ToolbarVideo
        editorRef={editorRef}
        isOpenVideoModal={isOpenVideoModal}
        closeDialogVideo={() => setIsOpenVideoModal(false)}
      />
    </>
  )
}

export default observer(Toolbar)
