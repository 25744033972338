import {FC, useCallback, useEffect, useState} from 'react'
import {Controller, useForm, UseFormReturn} from 'react-hook-form'
import {Box} from '@mui/material'
import {generateGuid} from '@utils/generateGuid'
import {CreateFigureVersionInput, FastNewsInput, Figure} from '@graphql/types'
import PublicationFormTitle from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitle/PublicationFormTitle'
import {useStore} from '@stores/rootStoreContext'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {
  replaceValue,
  validateTitle
} from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitle/PublicationFormTitle.utils'
import {ETitlesField} from '@components/Publication/Form/PublicationFormTitles/PublicationFormTitles.types'
import SimpleModal from '../SimpleModal/SimpleModal'
import {
  useCreateFastNewsMutation,
  useCreateFigureMutation,
  useCreateFigureVersionMutation
} from '../../Publication/Form/gql/PublicationEditor.generated'
import AnnounceImageModalFields from '../ImageEditor/AnnounceImageModalFields/AnnounceImageModalFields'
import BreakingNewsModalFields from '../BreakingNewsModalFields/BreakingNewsModalFields'
import {BreakingNewsInputType} from '../BreakingNewsModalFields/BreakingNewsModalFieldsProps'
import {BREAKING_NEWS_MODAL_CONSTS} from './BreakingNewsModal.consts'
import {BreakingNewsModalProps} from './BreakingNewsModalProps'
import {BreakingNewsModalFieldEntries, EBreakingNewsModalField} from './BreakingNewsModal.types'

const BreakingNewsModal: FC<BreakingNewsModalProps> = ({
  open,
  title,
  okLabel,
  onClose,
  defaultFigure,
  figureIsLoading,
  modalId,
  figureTypeSlug,
  fullWidth,
  setRefetchDateTime
}) => {
  const {cropperStore} = useStore()
  const {cropper} = cropperStore

  const [figure, setFigure] = useState<Figure>(
    (defaultFigure as Figure) ||
      ({
        src: 'https://ps.ura.news/776771',
        description: BREAKING_NEWS_MODAL_CONSTS.breakinNewsMsg
      } as Figure)
  )
  const [currentFigure, setCurrentFigure] = useState<Figure>(
    (defaultFigure as Figure) ||
      ({
        src: 'https://ps.ura.news/776771',
        description: BREAKING_NEWS_MODAL_CONSTS.breakinNewsMsg
      } as Figure)
  )

  const [imageDescription, setImageDescription] = useState('')
  const [imageAuthor, setImageAuthor] = useState('')

  const setAuthor = useCallback(
    (val: string) => {
      setImageAuthor(val)
    },
    [setImageAuthor]
  )

  const setDescription = useCallback(
    (val: string) => {
      setImageDescription(val)
    },
    [setImageDescription]
  )

  const defaultValues = {
    title: ''
  }

  const methodsForm: UseFormReturn<PublicationInputType> = useForm({
    defaultValues
  })

  const [headerErrorText, setHeaderErrorText] = useState('')
  const [disabledAnnounceImageFormState, setDisabledAnnounceImageFormState] = useState(true)
  const [disabledBreakingNewsFormState, setDisabledBreakingNewsFormState] = useState(false)
  const [createFigure, {loading: createFigureLoading}] = useCreateFigureMutation()
  const [createFigureVersion, {loading: createFigureVersionLoading}] = useCreateFigureVersionMutation()

  const createFigureAction = useCallback(() => {
    const newFigure = createFigure({
      variables: {
        data: {
          figureTypeSlug: 'publication',
          src: currentFigure?.src || '',
          hasWatermark: false,
          hasDiagonalWatermark: false,
          author: imageAuthor,
          description: imageDescription
        }
      }
    })
    return newFigure
  }, [createFigure, currentFigure, imageAuthor, imageDescription])

  const createFigureVersionAction = useCallback(
    (createFigureVersionInputData: CreateFigureVersionInput) => {
      const newFigure = createFigureVersion({
        variables: {
          data: createFigureVersionInputData
        }
      })

      return newFigure
    },
    [createFigureVersion]
  )

  const [createFastNews, {loading: createFastNewsLoading}] = useCreateFastNewsMutation()

  const [breakingNewsModalFieldValues, setBreakingNewsModalFieldValues] = useState<BreakingNewsInputType>(
    {} as BreakingNewsInputType
  )

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)

  const toggleIsOpenConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(!isOpenConfirmDialog)
  }, [isOpenConfirmDialog])

  const closeModal = useCallback(() => {
    onClose()
    setIsOpenConfirmDialog(false)
    setFigure(
      (defaultFigure as Figure) ||
        ({
          src: 'https://ps.ura.news/776771',
          description: BREAKING_NEWS_MODAL_CONSTS.breakinNewsMsg
        } as Figure)
    )

    setBreakingNewsModalFieldValues({} as BreakingNewsInputType)
  }, [onClose, defaultFigure, setFigure, setBreakingNewsModalFieldValues])

  const createBreakingNews = useCallback(async () => {
    const newFigure = await createFigureAction()

    const newFigureVersion = await createFigureVersionAction({
      figureId: newFigure?.data?.createFigure?.figure?.id || '',
      hasWatermark: false,
      hasDiagonalWatermark: false,
      crop: cropper
    } as CreateFigureVersionInput)

    const fastNewsData = {
      title: methodsForm.getValues('title'),
      text: breakingNewsModalFieldValues.text,
      regionUids: breakingNewsModalFieldValues.regionUids,
      publishedAt: breakingNewsModalFieldValues.publishedAt,
      announcingFigureId: newFigure?.data?.createFigure?.figure?.id,
      announcingFigureVersionUid: newFigureVersion?.data?.createFigureVersion?.figureVersion?.uid || '',
      keywords: breakingNewsModalFieldValues.keywords || ''
    } as FastNewsInput

    const fastNews = await createFastNews({
      variables: {
        data: fastNewsData
      }
    })

    if (!fastNews?.errors) {
      closeModal()
      setRefetchDateTime(new Date())
    }
  }, [
    breakingNewsModalFieldValues.keywords,
    breakingNewsModalFieldValues.publishedAt,
    breakingNewsModalFieldValues.regionUids,
    breakingNewsModalFieldValues.text,
    closeModal,
    createFastNews,
    createFigureAction,
    createFigureVersionAction,
    cropper,
    methodsForm,
    setRefetchDateTime
  ])

  const [okBtnDisabled, setOkBtnDisabled] = useState(true)

  useEffect(() => {
    setOkBtnDisabled(
      !!headerErrorText ||
        disabledAnnounceImageFormState ||
        disabledBreakingNewsFormState ||
        createFigureLoading ||
        createFigureVersionLoading ||
        createFastNewsLoading
    )
  }, [
    setOkBtnDisabled,
    headerErrorText,
    disabledAnnounceImageFormState,
    disabledBreakingNewsFormState,
    createFigureLoading,
    createFigureVersionLoading,
    createFastNewsLoading
  ])

  const handleChange = useCallback(
    (fieldValue: string, fieldName: EBreakingNewsModalField) => {
      const value = replaceValue(fieldValue)
      methodsForm.setValue(fieldName, value)

      const fieldState = methodsForm.getFieldState(fieldName)
      const hasErrors = Boolean(fieldState?.invalid)
      if (hasErrors) {
        methodsForm.clearErrors(fieldName)
        setOkBtnDisabled(false)
      }

      const {isInvalid, errorMessage} = validateTitle({
        fieldName,
        text: value,
        maxErrorLength: BreakingNewsModalFieldEntries[fieldName].maxLength,
        titleMaxWordsCount: BreakingNewsModalFieldEntries[fieldName].titleMaxWordsCount
      })

      if (isInvalid) {
        methodsForm.setError(fieldName, {
          message: errorMessage
        })
        setOkBtnDisabled(true)
      }
    },
    [methodsForm]
  )

  return (
    <SimpleModal
      open={open}
      mode='alert'
      onClose={toggleIsOpenConfirmDialog}
      title={title}
      text={''}
      onOk={createBreakingNews}
      onNo={toggleIsOpenConfirmDialog}
      okButtonDisabled={okBtnDisabled}
      okButtonDisabledTooltip={BREAKING_NEWS_MODAL_CONSTS.fillRequiredFields}
      noButtonDisabled={false}
      okLabel={okLabel}
      noLabel={''}
      loading={
        !!figureIsLoading || createFigureLoading || createFigureVersionLoading || createFastNewsLoading ? true : false
      }
    >
      <Controller
        name={ETitlesField.Title}
        control={methodsForm.control}
        render={({field}) => <PublicationFormTitle methodsForm={methodsForm} field={field} onChange={handleChange} />}
      />
      <Box display='flex' flexDirection={'row'} marginTop={'10px'}>
        <Box display='flex' flexDirection={'column'}>
          <AnnounceImageModalFields
            defaultFigure={figure}
            // defaultSocialImage={defaultSocialImage}
            modalId={modalId || generateGuid()}
            figureTypeSlug={figureTypeSlug}
            defaultExtParams={{isFullWidth: fullWidth || false}}
            figureIsLoading={
              figureIsLoading
              // figureIsLoading || createFigureVersionLoading
            }
            breakingNews={true}
            setDisabledFormState={setDisabledAnnounceImageFormState}
            setCurrentFigure={setCurrentFigure}
            setCurrentDescription={setDescription}
            setCurrentAuthor={setAuthor}
          />
        </Box>
        <Box display='flex' flexDirection={'column'} width={'450px'} marginLeft={'16px'}>
          <BreakingNewsModalFields
            setDisabledFormState={setDisabledBreakingNewsFormState}
            setBreakingNewsModalFieldValues={setBreakingNewsModalFieldValues}
            breakingNewsModalFieldValues={breakingNewsModalFieldValues}
          />
        </Box>
      </Box>
      <SimpleModal
        open={isOpenConfirmDialog}
        mode='alert'
        title={BREAKING_NEWS_MODAL_CONSTS.warningMsg}
        okLabel={BREAKING_NEWS_MODAL_CONSTS.closeConfirmBtn}
        onOk={closeModal}
        onYes={closeModal}
        onClose={toggleIsOpenConfirmDialog}
        onAbort={toggleIsOpenConfirmDialog}
        onNo={toggleIsOpenConfirmDialog}
        text={BREAKING_NEWS_MODAL_CONSTS.closeConfirmationText}
      />
    </SimpleModal>
  )
}
export default BreakingNewsModal
