import {CommentsConnection} from '@graphql/types'
import {UiPublicationForCommentsCountResult} from '@entities/types/UiPublication'
import {defaultPublication} from '@stores/publicationStore/publicationStore.defaultValues'
import {UiCommentsWithCommentsCountEdge} from '@entities/types/UiCommentsEdge'
import {UiCommentsConnectionWithCommentsCount} from '@entities/types/UiCommentsConnection'
import defaultComment from '../comments.consts'

const getCommentsCountGroupByPublicationUidAdapterToClient = (
  response?: CommentsConnection
): UiCommentsConnectionWithCommentsCount => {
  if (!response) {
    return {
      edges: [],
      pageInfo: {
        endCursor: '',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: ''
      }
      // totalCount: 0
    }
  }

  const edges: UiCommentsWithCommentsCountEdge[] = []

  response.edges.forEach(comments => {
    if (comments?.node) {
      const {allComments, newComments, publication} = comments?.node

      let publicationElement: UiPublicationForCommentsCountResult | null = null

      if (publication) {
        const {isAutomaticCommentsDisabled, title, typeSlug, uid} = publication

        publicationElement = {
          uid: uid || '',
          isAutomaticCommentsDisabled: isAutomaticCommentsDisabled ?? defaultPublication.isAutomaticCommentsDisabled,
          title: title || '',
          typeSlug: typeSlug ?? defaultPublication.typeSlug
        }
      }

      edges.push({
        cursor: comments?.cursor || '',
        node: {
          allComments: allComments ?? defaultComment.allComments,
          newComments: newComments ?? defaultComment.newComments,
          publication: publicationElement
        }
      })
    }
  })

  const {endCursor, hasNextPage, hasPreviousPage} = response.pageInfo

  return {
    edges,
    pageInfo: {
      endCursor: endCursor || '',
      hasNextPage: hasNextPage || false,
      hasPreviousPage: hasPreviousPage || false,
      startCursor: ''
    }
    // totalCount: response.totalCount || 0
  }
}

export {getCommentsCountGroupByPublicationUidAdapterToClient}
