import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type RemovePublicationsFromCollectionMutationVariables = Types.Exact<{
  data: Types.RemovePublicationsFromCollectionInput
}>

export type RemovePublicationsFromCollectionMutation = {__typename?: 'Mutation'} & {
  removePublicationsFromCollection?: Types.Maybe<
    {__typename?: 'RemovePublicationsFromCollectionPayload'} & {
      collection?: Types.Maybe<
        {__typename?: 'Collection'} & Pick<Types.Collection, 'uid'> & {
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<
                  Types.Maybe<
                    {__typename?: 'PublicationsEdge'} & {
                      node?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'uid'>>
                    }
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type AddPublicationToCollectionMutationVariables = Types.Exact<{
  data: Types.AddPublicationsToCollectionInput
}>

export type AddPublicationToCollectionMutation = {__typename?: 'Mutation'} & {
  addPublicationsToCollection?: Types.Maybe<
    {__typename?: 'AddPublicationsToCollectionPayload'} & {
      collection?: Types.Maybe<
        {__typename?: 'Collection'} & Pick<Types.Collection, 'uid'> & {
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<
                  Types.Maybe<
                    {__typename?: 'PublicationsEdge'} & {
                      node?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'uid'>>
                    }
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type PublicationsByCollectionUidQueryVariables = Types.Exact<{
  collectionUid?: Types.Maybe<Types.Scalars['UUID']>
  limit?: Types.Maybe<Types.Scalars['Int']>
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
}>

export type PublicationsByCollectionUidQuery = {__typename?: 'Query'} & {
  publications?: Types.Maybe<
    {__typename?: 'PublicationsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'PublicationsEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'Publication'} & Pick<Types.Publication, 'uid' | 'title'> & {
                  regions?: Types.Maybe<
                    Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>
                  >
                  authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
                }
            >
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    }
  >
}

export const RemovePublicationsFromCollectionDocument = gql`
  mutation removePublicationsFromCollection($data: RemovePublicationsFromCollectionInput!) {
    removePublicationsFromCollection(data: $data) {
      collection {
        uid
        publications {
          edges {
            node {
              uid
            }
          }
        }
      }
    }
  }
`
export type RemovePublicationsFromCollectionMutationFn = Apollo.MutationFunction<
  RemovePublicationsFromCollectionMutation,
  RemovePublicationsFromCollectionMutationVariables
>

/**
 * __useRemovePublicationsFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemovePublicationsFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePublicationsFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePublicationsFromCollectionMutation, { data, loading, error }] = useRemovePublicationsFromCollectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemovePublicationsFromCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePublicationsFromCollectionMutation,
    RemovePublicationsFromCollectionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<
    RemovePublicationsFromCollectionMutation,
    RemovePublicationsFromCollectionMutationVariables
  >(RemovePublicationsFromCollectionDocument, options)
}
export type RemovePublicationsFromCollectionMutationHookResult = ReturnType<
  typeof useRemovePublicationsFromCollectionMutation
>
export type RemovePublicationsFromCollectionMutationResult =
  Apollo.MutationResult<RemovePublicationsFromCollectionMutation>
export type RemovePublicationsFromCollectionMutationOptions = Apollo.BaseMutationOptions<
  RemovePublicationsFromCollectionMutation,
  RemovePublicationsFromCollectionMutationVariables
>
export const AddPublicationToCollectionDocument = gql`
  mutation addPublicationToCollection($data: AddPublicationsToCollectionInput!) {
    addPublicationsToCollection(data: $data) {
      collection {
        uid
        publications {
          edges {
            node {
              uid
            }
          }
        }
      }
    }
  }
`
export type AddPublicationToCollectionMutationFn = Apollo.MutationFunction<
  AddPublicationToCollectionMutation,
  AddPublicationToCollectionMutationVariables
>

/**
 * __useAddPublicationToCollectionMutation__
 *
 * To run a mutation, you first call `useAddPublicationToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPublicationToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPublicationToCollectionMutation, { data, loading, error }] = useAddPublicationToCollectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddPublicationToCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPublicationToCollectionMutation,
    AddPublicationToCollectionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<AddPublicationToCollectionMutation, AddPublicationToCollectionMutationVariables>(
    AddPublicationToCollectionDocument,
    options
  )
}
export type AddPublicationToCollectionMutationHookResult = ReturnType<typeof useAddPublicationToCollectionMutation>
export type AddPublicationToCollectionMutationResult = Apollo.MutationResult<AddPublicationToCollectionMutation>
export type AddPublicationToCollectionMutationOptions = Apollo.BaseMutationOptions<
  AddPublicationToCollectionMutation,
  AddPublicationToCollectionMutationVariables
>
export const PublicationsByCollectionUidDocument = gql`
  query publicationsByCollectionUid($collectionUid: UUID, $limit: Int = 15, $first: Int, $after: String) {
    publications(
      limit: $limit
      filter: {collectionUid: $collectionUid}
      sort: PUBLISHED_AT_DESC
      first: $first
      after: $after
    ) {
      edges {
        node {
          uid
          title
          regions {
            uid
            title
          }
          authors {
            uid
            firstName
            lastName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

/**
 * __usePublicationsByCollectionUidQuery__
 *
 * To run a query within a React component, call `usePublicationsByCollectionUidQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationsByCollectionUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationsByCollectionUidQuery({
 *   variables: {
 *      collectionUid: // value for 'collectionUid'
 *      limit: // value for 'limit'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePublicationsByCollectionUidQuery(
  baseOptions?: Apollo.QueryHookOptions<PublicationsByCollectionUidQuery, PublicationsByCollectionUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationsByCollectionUidQuery, PublicationsByCollectionUidQueryVariables>(
    PublicationsByCollectionUidDocument,
    options
  )
}
export function usePublicationsByCollectionUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationsByCollectionUidQuery, PublicationsByCollectionUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationsByCollectionUidQuery, PublicationsByCollectionUidQueryVariables>(
    PublicationsByCollectionUidDocument,
    options
  )
}
export type PublicationsByCollectionUidQueryHookResult = ReturnType<typeof usePublicationsByCollectionUidQuery>
export type PublicationsByCollectionUidLazyQueryHookResult = ReturnType<typeof usePublicationsByCollectionUidLazyQuery>
export type PublicationsByCollectionUidQueryResult = Apollo.QueryResult<
  PublicationsByCollectionUidQuery,
  PublicationsByCollectionUidQueryVariables
>
