import {Quill} from 'react-quill'

const Embed = Quill.import('blots/embed')

export default class Link extends Embed {
  constructor(node: HTMLElement) {
    super(node)
  }

  static create(link: string, text: string): HTMLElement {
    const node = super.create()
    node.setAttribute('href', link)
    node.setAttribute('target', '_blank')
    node.innerText = text
    return node
  }
}

Link.tagName = 'a'
Link.className = 'link'
Link.blotName = 'custom-link'
