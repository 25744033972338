import React, {useEffect, useState} from 'react'
import {Box, Button, IconButton, LinearProgress, TextField, Typography} from '@mui/material'
import styled from 'styled-components'
import {useSnackbar} from 'notistack'
import InfiniteScroll from 'react-infinite-scroll-component'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import PageContainer from '@components/UI/PageContainer/PageContainer'

const Title = styled(Typography)`
  grid-area: title;
  font-weight: 500;
  line-height: 1.2;
  color: #222;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: full;
  margin: 0 auto;
  padding-bottom: 20px; /* добавленный отступ снизу у FormContainer */
`

const ItemWrapper = styled.div`
  font-size: 14px;
  padding-left: 12px;
  line-height: 1.2;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
`
const ItemContentWrapper = styled.div``

const ItemInfo = styled.div`
  display: flex;
`

const TextFieldContainer = styled.div`
  margin-bottom: 16px;
  width: 100%; /* чтобы текстовые поля занимали всю ширину своих контейнеров */
`

const Plashka = styled.div`
  background-color: white; /* или любой другой цвет */
  border-radius: 8px; /* скругление углов */
  padding: 20px; /* внутренние отступы */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* тень */
  border: 1px solid #ccc; /* добавленная обводка */
  width: 100%; /* или задайте фиксированную ширину */
  max-width: 100%; /* максимальная ширина плашки */
`

const Persons: React.FC = () => {
  const [url, setUrl] = useState('')
  const [fullName, setFullName] = useState('')
  const snackbar = useSnackbar()
  const [people, setPeople] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const fetchPeople = async (pageNumber = 1) => {
    try {
      const response = await fetch(`https://a.uran.news/person/pagination/?page=${pageNumber}`)
      if (response.status === 404) {
        // Предположительно, не найдено
        setHasMore(false) // Если данных нет, прекращаем загрузку
        return
      }
      if (!response.ok) {
        throw new Error('Failed to fetch data')
      }

      const data = await response.json()
      const result_data = data['results']

      if (Array.isArray(result_data)) {
        setPeople(prevPeople => (pageNumber === 1 ? result_data : [...prevPeople, ...result_data]))
        setHasMore(result_data.length > 0)
      } else {
        snackbar.enqueueSnackbar('Получены некорректные данные', {variant: 'error', autoHideDuration: 5000})
      }
    } catch (error) {
      snackbar.enqueueSnackbar(`Ошибка: ${error.message}`, {variant: 'error', autoHideDuration: 5000})
      setHasMore(false) // Прекращаем загрузку при ошибке
    }
  }

  useEffect(() => {
    fetchPeople()
  }, [])

  const fetchMoreData = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1)
      fetchPeople(page + 1)
    }
  }

  const CreatePerson = async () => {
    if (url.length === 0 && fullName.length === 0) {
      snackbar.enqueueSnackbar('Сначала напишите URL страницы персоны и ФИО', {variant: 'info', autoHideDuration: 5000})
      return
    } else if (url.length === 0) {
      snackbar.enqueueSnackbar('Сначала напишите URL страницы персоны', {variant: 'info', autoHideDuration: 5000})
      return
    } else if (fullName.length === 0) {
      snackbar.enqueueSnackbar('Сначала напишите ФИО персоны', {variant: 'info', autoHideDuration: 5000})
      return
    }

    const data = JSON.stringify({
      text: fullName,
      url: url
    })

    try {
      const response = await fetch('https://a.uran.news/person/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: data
      })
      const result = await response.json()

      if (!response.ok) {
        snackbar.enqueueSnackbar(result['error'], {variant: 'error', autoHideDuration: 10000})
      } else {
        snackbar.enqueueSnackbar('Персона успешно добавлена', {variant: 'success', autoHideDuration: 10000})
        // Очистка полей
        setUrl('')
        setFullName('')

        // Обновление списка людей после создания нового
        setPeople(prevPeople => [...prevPeople, {full_name: fullName, url: url, id: result['id']}])
      }
    } catch (error) {
      snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
    }
  }

  const DeletePerson = async id => {
    try {
      const response = await fetch(`https://a.uran.news/person/delete/${id}`, {
        method: 'DELETE'
      })

      const result = await response.json()
      if (!response.ok) {
        snackbar.enqueueSnackbar(result['error'], {variant: 'error', autoHideDuration: 10000})
      } else {
        snackbar.enqueueSnackbar(result['text'], {variant: 'success', autoHideDuration: 10000})
        setPeople(prevPeople => prevPeople.filter(person => person.id !== id)) // Удаляем элемент из состояния
      }
    } catch (error) {
      snackbar.enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
    }
  }

  return (
    <PageContainer>
      <FormContainer>
        <Plashka>
          <TextFieldContainer>
            <TextField
              label='URL'
              variant='outlined'
              value={url}
              onChange={e => setUrl(e.target.value)}
              style={{
                width: '100%'
              }}
              InputProps={{
                style: {
                  height: '45px',
                  padding: '0 14px'
                }
              }}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <TextField
              label='Полное имя (Ф.И.О)'
              variant='outlined'
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              style={{
                width: '100%'
              }}
              InputProps={{
                style: {
                  height: '45px',
                  padding: '0 14px'
                }
              }}
            />
          </TextFieldContainer>
          <Button variant='contained' color='primary' onClick={CreatePerson}>
            Добавить
          </Button>
        </Plashka>
      </FormContainer>
      <InfiniteScroll
        dataLength={people.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<LinearProgress color='primary' />}
        height='78vh'
        //endMessage={<Typography variant='body2'>No more data to load.</Typography>}
      >
        <Box paddingRight='15px'>
          {people.map(person => (
            <ItemWrapper key={person.id}>
              <ItemInfo>
                <ItemContentWrapper>
                  <Title>
                    <a href={person.url} title='Перейти на страницу персоны'>
                      {person.full_name}
                    </a>
                  </Title>
                  <Typography color='secondary' component='span' variant='subtitle1'>
                    {person.url}
                  </Typography>
                </ItemContentWrapper>
              </ItemInfo>
              <IconButton aria-label='Удалить персону' onClick={() => DeletePerson(person.id)}>
                <DeleteForeverIcon className='icon' color='disabled' />
              </IconButton>
            </ItemWrapper>
          ))}
        </Box>
      </InfiniteScroll>
    </PageContainer>
  )
}

export default Persons
