import {FC, useCallback, useEffect, useState} from 'react'
import {CircularProgress, Divider, Typography} from '@mui/material'
import {moderateStatusCommentAdapterToClient} from '@entities/api/comments/adapters/moderateStatusCommentAdapterToClient'
import {lightGrayColor2, lightYellowColor} from '@theme/vars'
import {useStore} from '@stores/rootStoreContext'
import {EyeIcon} from '@components/UI/Icons/EyeIcon'
import ForbiddenIcon from '@components/UI/Icons/ForbiddenIcon'
import {CommentStatus} from '@graphql/types'
import {CommentItemButtons} from '../CommentItemButtons/CommentItemButtons'
import {useModerateStatusCommentMutation} from '../gql/Comments.generated'
import CommentItemInfo from './CommentItemInfo/CommentItemInfo'
import {COMMENT_ITEM_CONSTS} from './CommentItem.consts'
import {ButtonWrapper, TableCell, TableRow} from './CommentItem.styles'
import {CommentItemProps} from './CommentItemProps'

/**
 * Компонент для отображения комментария
 * @param param0
 * @returns
 */
const CommentItem: FC<CommentItemProps> = ({
  publicationUid,
  ind,
  comment,
  moderateCommentsAction,
  level,
  refetchData
}) => {
  const {commentsStore} = useStore()
  const {moderateCommentByUid, moderateCommentsActionLoading} = commentsStore

  const [postInDisabled, setPostInDisabled] = useState(comment.isBad === undefined)
  const [moderateStatusCommentLoading, setModerateStatusCommentLoading] = useState(false)
  const [moderateStatusComment] = useModerateStatusCommentMutation()
  const [currentComment, setCurrentComment] = useState(comment)

  useEffect(() => {
    setCurrentComment(comment)
    setPostInDisabled(comment.isBad === undefined)
  }, [comment])

  /**
   * Модерация комментария
   * @param commentUid - uid комментария
   * @param value - значение
   */
  const moderateComment = useCallback(
    (commentUid, viewed, value) => {
      setPostInDisabled(false)
      moderateCommentByUid(ind, commentUid, viewed, value)
    },
    [ind, moderateCommentByUid]
  )

  /**
   * Обрабатывает модерацию статуса комментария
   * @param commentUid - uid комментария
   * @param status - статус комментария
   * @param viewed - просмотрен ли комментарий
   */
  const handleModerateStatusComment = useCallback(
    async (commentUid: string, status: CommentStatus, viewed: boolean) => {
      setModerateStatusCommentLoading(true)
      try {
        const result = await moderateStatusComment({
          variables: {
            data: {commentUid, status, statusViewed: viewed}
          }
        })
        if (!moderateStatusCommentAdapterToClient(result)) {
          throw new Error('Ошибка при модерации')
        }
        if (publicationUid) {
          setCurrentComment(prev => ({...prev, status, viewed}))
        }
        refetchData && refetchData()
      } catch (error) {
        console.error(error)
      } finally {
        setModerateStatusCommentLoading(false)
      }
    },
    [moderateStatusComment, publicationUid, refetchData]
  )

  const moderatePublicationComment = useCallback(
    (commentUid: string, viewed: boolean, value?: boolean) => {
      const status =
        value === true ? CommentStatus.Published : value === false ? CommentStatus.Rejected : CommentStatus.Review
      handleModerateStatusComment(commentUid, status, viewed)
    },
    [handleModerateStatusComment]
  )

  /**
   * Рендерит кнопки для модерации комментария для публикации
   * @returns
   */
  const renderModerateButtonsForPublication = () => {
    const isBad =
      currentComment.status === CommentStatus.Rejected
        ? true
        : currentComment.status === CommentStatus.Published
        ? false
        : undefined

    return (
      <CommentItemButtons
        commentUid={currentComment.uid}
        isBad={isBad}
        viewed={currentComment.viewed}
        moderateComment={moderatePublicationComment}
      />
    )
  }

  /**
   * Рендерит кнопки для модерации комментария
   * @returns
   */
  const renderModerateButtons = () => {
    const buttons = {
      [CommentStatus.Review]: (
        <>
          <CommentItemButtons
            commentUid={currentComment.uid}
            isBad={currentComment.isBad}
            viewed={currentComment.viewed}
            moderateComment={moderateComment}
          />
          <ButtonWrapper
            disabled={postInDisabled || moderateCommentsActionLoading}
            id={`post-in-button-${currentComment.uid}`}
            onClick={() => moderateCommentsAction(ind)}
          >
            {moderateCommentsActionLoading && <CircularProgress size='1rem' />}
            <Typography variant='text14M'>
              {ind > 0 ? COMMENT_ITEM_CONSTS.postItAbove : COMMENT_ITEM_CONSTS.postIt}
            </Typography>
          </ButtonWrapper>
        </>
      ),
      [CommentStatus.Published]: (
        <>
          <ButtonWrapper
            id={`for-consideration-button-${currentComment.uid}`}
            onClick={() => handleModerateStatusComment(currentComment.uid, CommentStatus.Review, false)}
            disabled={moderateStatusCommentLoading}
          >
            {moderateStatusCommentLoading ? <CircularProgress size='1rem' /> : <EyeIcon />}
            <Typography variant='text14M' marginLeft={'4px'}>
              {COMMENT_ITEM_CONSTS.forСonsideration}
            </Typography>
          </ButtonWrapper>
          <ButtonWrapper
            id={`block-from-published-button-${currentComment.uid}`}
            onClick={() => handleModerateStatusComment(currentComment.uid, CommentStatus.Rejected, false)}
            disabled={moderateStatusCommentLoading}
          >
            {moderateStatusCommentLoading ? <CircularProgress size='1rem' /> : <ForbiddenIcon width={16} height={16} />}
            <Typography variant='text14M' marginLeft={'4px'}>
              {COMMENT_ITEM_CONSTS.block}
            </Typography>
          </ButtonWrapper>
        </>
      ),
      [CommentStatus.Rejected]: (
        <>
          <ButtonWrapper
            id={`for-consideration-from-rejectedbutton-${currentComment.uid}`}
            onClick={() => handleModerateStatusComment(currentComment.uid, CommentStatus.Review, false)}
            disabled={moderateStatusCommentLoading}
          >
            {moderateStatusCommentLoading ? <CircularProgress size='1rem' /> : <EyeIcon />}
            <Typography variant='text14M' marginLeft={'4px'}>
              {COMMENT_ITEM_CONSTS.forСonsideration}
            </Typography>
          </ButtonWrapper>
        </>
      )
    }

    return buttons[currentComment.status] || null
  }

  return (
    <div
      style={{
        backgroundColor:
          publicationUid && currentComment.status === CommentStatus.Review ? lightYellowColor : 'inherit',
        paddingLeft: '5px'
      }}
    >
      <TableRow>
        <CommentItemInfo comment={currentComment} publicationUid={publicationUid} level={level} />
        <TableCell
          style={{
            paddingRight: '5px'
          }}
        >
          {publicationUid ? renderModerateButtonsForPublication() : renderModerateButtons()}
        </TableCell>
      </TableRow>
      <Divider sx={{backgroundColor: lightGrayColor2, marginTop: '16px'}} />
    </div>
  )
}

export default CommentItem
