export const INPUT_LABELS = {
  firstName: 'Имя',
  lastName: 'Фамилия',
  organization: 'Организация',
  description: 'Описание'
}

export const BUTTON_LABELS = {
  create: 'Добавить',
  update: 'Обновить',
  cancel: 'Отменить'
}

export const LIST_LABELS = {
  noTwins: 'Список не загрузился',
  loadTwins: 'Загрузить ещё'
}

export const CHECKBOX_LABEL = 'Организация'
