import {FC, useCallback, useMemo, useState} from 'react'
import {useKeycloak} from '@react-keycloak/web'
import {Box, Checkbox, FormControlLabel} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useFormContext} from 'react-hook-form'
import {useStore} from '@stores/rootStoreContext'

import {PublicationStatus} from '@graphql/types'
import RHFCheckboxLabel from '@components/Publication/Form/RHFCheckboxLabel/RHFCheckboxLabel'
import LinkPicker from '@components/Publication/Form/Pickers/LinkPicker/LinkPicker'
import StorySelect from '@components/Publication/Form/Selectors/StorySelect/StorySelect'
import AuthorsSelect from '@components/Publication/Form/Selectors/AuthorsSelect/AuthorsSelect'
import PublicationDestinationPicker from '@components/Publication/Form/PublicationDestinationPicker/PublicationDestinationPicker'
import RubricPicker from '@components/Publication/Form/Pickers/RubricPicker/RubricPicker'
import UnpublishedNewsPicker from '@components/Publication/Form/Pickers/UnpublishedNewsPicker/UnpublishedNewsPicker'
import {YandexVideos} from '@components/Publication/Form/YandexVideos/YandexVideos'
import SpecialProjectInput from '@components/Publication/Form/InputForSpecialProject/InputForSpecialProject'
import RegionsWithOverviewsBlocksSelect from '@components/Publication/Form/Selectors/RegionsWithOverviewsBlocksSelect/RegionsWithOverviewsBlocksSelect'
import PublicationFormOverviewBlockImage from '@components/Publication/Form/PublicationFormOverviewBlockImage/PublicationFormOverviewBlockImage'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import PublicationFormCheckingPhotoEditorCheckbox from '@components/Publication/Form/PublicationFormDetails/PublicationFormCheckingPhotoEditorCheckbox/PublicationFormCheckingPhotoEditorCheckbox'
import PublicationActionButton from './PublicationActionButton/PublicationActionButton'
import {PublicationFormDetailsProps} from './PublicationFormDetailsProps'
import PublicationFormOnlineTranslationCheckbox from './PublicationFormOnlineTranslationCheckbox/PublicationFormOnlineTranslationCheckbox'
import {PUBLICATION_FORM_DETAILS_CONSTS} from './PublicationFormDetails.consts'
import {
  DetailsRowSelects,
  DetailsRowSelectsCheckBoxes,
  DeviderWrapper,
  PublicationFormDetailsWrapper,
  SpecialProjectWithInsideWrapper
} from './PublicationFormDetails.styles'

/**
 * Компонент для работы с формой расширенных параметров публикации
 * @param param0
 * @returns
 */
const PublicationFormDetails: FC<PublicationFormDetailsProps> = ({
  changeLinks,
  setOverviewBlockFigureCache,
  refetchOverviewBlocks
}) => {
  const {keycloak} = useKeycloak()
  const cantEditOverviewBlocks = useMemo(
    () =>
      !(
        keycloak.hasResourceRole('edit-published', 'publications') ||
        keycloak.hasResourceRole('manage-main-page', 'main-page')
      ),
    [keycloak]
  )
  const {getValues} = useFormContext<PublicationInputType>()
  const {publicationStore} = useStore()
  const {publication} = publicationStore
  const [isSpecialProject, setIsSpecialProject] = useState(!!publication.specProjectUrl)

  const toggleIsSpecialProject = useCallback(() => {
    setIsSpecialProject(!isSpecialProject)
  }, [isSpecialProject])

  const [hasOverviewBlock, setHasOverviewBlock] = useState(false)

  return (
    <PublicationFormDetailsWrapper>
      <DetailsRowSelects>
        <LinkPicker changeLinks={changeLinks} />
      </DetailsRowSelects>

      <DetailsRowSelects>
        <PublicationFormOnlineTranslationCheckbox disabled={getValues('cantEditPublication')} />
      </DetailsRowSelects>
      <DetailsRowSelects>
        <AuthorsSelect />
      </DetailsRowSelects>
      <DetailsRowSelects
        style={{
          marginBottom: '24px'
        }}
      >
        <RegionsWithOverviewsBlocksSelect
          setHasOverviewBlock={setHasOverviewBlock}
          refetchOverviewBlocks={refetchOverviewBlocks}
        />
        {hasOverviewBlock && !cantEditOverviewBlocks && (
          <PublicationFormOverviewBlockImage
            defaultOverviewBlockImage={publication?.overviewFigure}
            announceImage={publication?.announceImage}
            publicationTypeSlug={publication?.typeSlug || ''}
            overviewBlockFigureCache={undefined}
            setOverviewBlockFigureCache={setOverviewBlockFigureCache}
          />
        )}
      </DetailsRowSelects>
      <DetailsRowSelects>
        <StorySelect />
        {/* <TagsSelect />
                <CollectionsSelect /> */}
      </DetailsRowSelects>
      <DetailsRowSelects>
        <RubricPicker />
      </DetailsRowSelects>
      <YandexVideos />
      <DetailsRowSelectsCheckBoxes>
        <PublicationDestinationPicker />
        <Box borderTop={'1px solid #EDEEF1'} marginTop={'22px'}>
          {keycloak.hasResourceRole('toggle-important', 'publications') && (
            <>
              {publication.typeSlug !== 'articles' && (
                <RHFCheckboxLabel
                  name='isImportant'
                  label={PUBLICATION_FORM_DETAILS_CONSTS.isImportantMsg}
                  style={{
                    marginRight: '64px',
                    width: '222px'
                  }}
                  disabled={getValues('cantEditPublication')}
                />
              )}
            </>
          )}
          {keycloak.hasResourceRole('toggle-publication-comments', 'comments') && (
            <RHFCheckboxLabel
              name='isCommentsDisabled'
              label={PUBLICATION_FORM_DETAILS_CONSTS.disableCommentsMsg}
              disabled={getValues('cantEditPublication')}
              style={{
                marginRight: '64px',
                width: '222px'
              }}
            />
          )}
          {keycloak.hasResourceRole('toggle-publication-comments', 'comments') && (
            <RHFCheckboxLabel
              name='isAutomaticCommentsDisabled'
              label={PUBLICATION_FORM_DETAILS_CONSTS.disableCommentsAutoPublishingMsg}
              disabled={getValues('cantEditPublication')}
              style={{
                marginRight: '64px'
              }}
            />
          )}
        </Box>
      </DetailsRowSelectsCheckBoxes>
      <DeviderWrapper />
      <DetailsRowSelectsCheckBoxes>
        <div>
          <SpecialProjectWithInsideWrapper>
            <FormControlLabel
              style={{width: '271px'}}
              control={
                <Checkbox
                  checked={isSpecialProject}
                  onChange={toggleIsSpecialProject}
                  color='primary'
                  disabled={getValues('cantEditPublication')}
                />
              }
              label={`${PUBLICATION_FORM_DETAILS_CONSTS.includeMsg} в спецпроект`}
            />
            {publication.typeSlug === 'articles' && (
              <RHFCheckboxLabel
                name='isInside'
                label={PUBLICATION_FORM_DETAILS_CONSTS.insideMsg}
                disabled={getValues('cantEditPublication')}
              />
            )}
          </SpecialProjectWithInsideWrapper>
        </div>
      </DetailsRowSelectsCheckBoxes>
      {isSpecialProject && <SpecialProjectInput links={publication.specProjectUrl || ''} />}
      <DeviderWrapper />
      <DetailsRowSelectsCheckBoxes>
        <RHFCheckboxLabel
          name='checkedByEditor'
          label={PUBLICATION_FORM_DETAILS_CONSTS.checkedByEditor}
          // disabled={getPublicationValues('cantEditPublication')}
          disabled={true}
          style={{
            width: '275px',
            marginRight: '12px'
          }}
        />
        {keycloak.hasResourceRole('check-bildeditor', 'publications') &&
          publication?.status === PublicationStatus.Published && (
            <PublicationFormCheckingPhotoEditorCheckbox disabled={false} width={'271px'} />
          )}
      </DetailsRowSelectsCheckBoxes>
      <DetailsRowSelects />
      {publication.status === 'UNPUBLISHED' && (
        <DetailsRowSelects>
          <UnpublishedNewsPicker />
        </DetailsRowSelects>
      )}
      <PublicationActionButton hasOverviewBlock={hasOverviewBlock} />
    </PublicationFormDetailsWrapper>
  )
}

export default observer(PublicationFormDetails)
