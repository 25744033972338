const QuotePairs = {
  '«': '»', // DOUBLE ANGLE QUOTATION MARK
  '“': '”', // DOUBLE QUOTATION MARK
  '„': '“', // DOUBLE 9 QUOTATION MARK

  '‹': '›', // SINGLE ANGLE QUOTATION MARK
  '‘': '’', // SINGLE QUOTATION MARK
  '‚': '‘', // SINGLE 9 QUOTATION MARK

  '"': '"' // QUOTATION MARK
}

const quotesSet = Object.entries(QuotePairs).reduce<Set<string>>((quotesSet, [key, value]) => {
  quotesSet.add(`\\${key}`)
  quotesSet.add(`\\${value}`)
  return quotesSet
}, new Set())

const quotesString = [...quotesSet].join('')

const quotesRegExp = new RegExp(`[${quotesString}]`)

export {QuotePairs, quotesRegExp}
