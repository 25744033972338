import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type StoriesListQueryVariables = Types.Exact<{
  isActive: Types.Scalars['Boolean']
  search?: Types.Maybe<Types.Scalars['String']>
  dateUpdatedFrom?: Types.Maybe<Types.Scalars['DateTime']>
  after?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
}>

export type StoriesListQuery = {__typename?: 'Query'} & {
  stories?: Types.Maybe<
    {__typename?: 'StoriesConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'StoriesEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'Story'} & Pick<
                Types.Story,
                'uid' | 'title' | 'publicationsCount' | 'updatedAt' | 'isActive' | 'createdAt'
              > & {dayThemeRegions: Array<{__typename?: 'Region'} & Pick<Types.Region, 'title'>>}
            >
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
    }
  >
}

export type DeactivateStoryMutationVariables = Types.Exact<{
  data: Types.DeactivateStoryInput
}>

export type DeactivateStoryMutation = {__typename?: 'Mutation'} & {
  deactivateStory?: Types.Maybe<{__typename?: 'DeactivateStoryPayload'} & Pick<Types.DeactivateStoryPayload, 'status'>>
}

export type ActivateStoryMutationVariables = Types.Exact<{
  data: Types.ActivateStoryInput
}>

export type ActivateStoryMutation = {__typename?: 'Mutation'} & {
  activateStory?: Types.Maybe<{__typename?: 'ActivateStoryPayload'} & Pick<Types.ActivateStoryPayload, 'status'>>
}

export const StoriesListDocument = gql`
  query storiesList($isActive: Boolean!, $search: String, $dateUpdatedFrom: DateTime, $after: String, $first: Int) {
    stories(
      limit: 15
      filter: {isActive: $isActive, search: $search, dateUpdatedFrom: $dateUpdatedFrom}
      first: $first
      after: $after
    ) {
      edges {
        node {
          uid
          title
          publicationsCount
          updatedAt
          isActive
          createdAt
          dayThemeRegions {
            title
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

/**
 * __useStoriesListQuery__
 *
 * To run a query within a React component, call `useStoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesListQuery({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      search: // value for 'search'
 *      dateUpdatedFrom: // value for 'dateUpdatedFrom'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStoriesListQuery(baseOptions: Apollo.QueryHookOptions<StoriesListQuery, StoriesListQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<StoriesListQuery, StoriesListQueryVariables>(StoriesListDocument, options)
}
export function useStoriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoriesListQuery, StoriesListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<StoriesListQuery, StoriesListQueryVariables>(StoriesListDocument, options)
}
export type StoriesListQueryHookResult = ReturnType<typeof useStoriesListQuery>
export type StoriesListLazyQueryHookResult = ReturnType<typeof useStoriesListLazyQuery>
export type StoriesListQueryResult = Apollo.QueryResult<StoriesListQuery, StoriesListQueryVariables>
export const DeactivateStoryDocument = gql`
  mutation deactivateStory($data: DeactivateStoryInput!) {
    deactivateStory(data: $data) {
      status
    }
  }
`
export type DeactivateStoryMutationFn = Apollo.MutationFunction<
  DeactivateStoryMutation,
  DeactivateStoryMutationVariables
>

/**
 * __useDeactivateStoryMutation__
 *
 * To run a mutation, you first call `useDeactivateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateStoryMutation, { data, loading, error }] = useDeactivateStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeactivateStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateStoryMutation, DeactivateStoryMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<DeactivateStoryMutation, DeactivateStoryMutationVariables>(DeactivateStoryDocument, options)
}
export type DeactivateStoryMutationHookResult = ReturnType<typeof useDeactivateStoryMutation>
export type DeactivateStoryMutationResult = Apollo.MutationResult<DeactivateStoryMutation>
export type DeactivateStoryMutationOptions = Apollo.BaseMutationOptions<
  DeactivateStoryMutation,
  DeactivateStoryMutationVariables
>
export const ActivateStoryDocument = gql`
  mutation activateStory($data: ActivateStoryInput!) {
    activateStory(data: $data) {
      status
    }
  }
`
export type ActivateStoryMutationFn = Apollo.MutationFunction<ActivateStoryMutation, ActivateStoryMutationVariables>

/**
 * __useActivateStoryMutation__
 *
 * To run a mutation, you first call `useActivateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateStoryMutation, { data, loading, error }] = useActivateStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useActivateStoryMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateStoryMutation, ActivateStoryMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<ActivateStoryMutation, ActivateStoryMutationVariables>(ActivateStoryDocument, options)
}
export type ActivateStoryMutationHookResult = ReturnType<typeof useActivateStoryMutation>
export type ActivateStoryMutationResult = Apollo.MutationResult<ActivateStoryMutation>
export type ActivateStoryMutationOptions = Apollo.BaseMutationOptions<
  ActivateStoryMutation,
  ActivateStoryMutationVariables
>
