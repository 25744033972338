import {Quill} from 'react-quill'

const Embed = Quill.import('blots/embed')

export default class SubHeader extends Embed {
    constructor(node: HTMLElement) {
        super(node)
    }

    static create(): HTMLElement {
        const node = super.create()
        const imageWrapper = document.createElement('div')
        imageWrapper.className = 'imageWrapper'
        node.appendChild(imageWrapper)
        node.setAttribute('contenteditable', 'true')
        return node
    }
}

SubHeader.tagName = 'p'
SubHeader.className = 'subHeader'
SubHeader.blotName = 'custom-subHeader'
