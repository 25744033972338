import styled from 'styled-components'

export const WrapperContent = styled.div<{isWarning: boolean}>`
  width: 440px;
  background-color: ${({isWarning}) => (isWarning ? '#F6E7C9' : '#f2dddd')};
  color: ${({isWarning}) => (isWarning ? '#E44F15' : '#eb3846')};
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  padding: 8px;
  @media (max-width: 480px) {
    width: 340px;
  }
`

export const LeftBlockWrapper = styled.div`
  align-items: flex-start;
  padding: 7px;
`

export const StyledUl = styled.ul`
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 20.8px;

    &::before {
      content: '•';
      color: #eb3846;
      font-size: 20px;
      display: inline-block;
      margin-right: 10px;
    }

    @media (max-width: 480px) {
      align-items: flex-start;
      font-size: 14px;
    }
  }
`
export const Title = styled.div`
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 20.8px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`
