import React, {FC, useCallback, useEffect, useState} from 'react'
import {Quill} from 'quill'
import {Quill as ReactQuill} from 'react-quill'
import {getQuillEditor} from '@utils/getQuillEditor'
import {SideBarBlot} from '../../Blots/SideBar/SideBar'
import {SideBar} from '../../Blots'
import TextEditorSidebarModal from '../../../UI/TextEditorSidebarModal/TextEditorSidebarModal'
import {ToolbarSidebarProps} from './ToolbarSidebarProps'

const Delta = ReactQuill.import('delta')

const ToolbarSideBar: FC<ToolbarSidebarProps> = ({editorRef, isOpenSidebarModal, closeDialogSidebar}) => {
  const [isOpenSidebarModalVal, setIsOpenSidebarModalVal] = useState(false)
  const [sidebarModalId, setSidebarModalId] = useState('')
  const [currentSideBarPublicationUrl, setCurrentSideBarPublicationUrl] = useState('')

  const toggleSidebarModal = useCallback(() => {
    setIsOpenSidebarModalVal(!isOpenSidebarModalVal)
    setSidebarModalId('')
    setCurrentSideBarPublicationUrl('')
    closeDialogSidebar()
  }, [isOpenSidebarModalVal, closeDialogSidebar])

  useEffect(() => {
    setIsOpenSidebarModalVal(isOpenSidebarModal)
    setCurrentSideBarPublicationUrl('')
  }, [isOpenSidebarModal])

  const openModalSideBar = e => {
    setCurrentSideBarPublicationUrl(e.detail.sideBarBlot?.sidebarId || '')
    setSidebarModalId(e.detail?.sideBarBlot?.id || '')
    setIsOpenSidebarModalVal(true)
  }

  useEffect(() => {
    window.addEventListener('openModalSideBar', e => openModalSideBar(e))

    return () => {
      window.removeEventListener('openModalSideBar', e => openModalSideBar(e))
    }
  }, [])

  const setSideBar = useCallback(
    (sidebarBlot: SideBarBlot) => {
      const quill = getQuillEditor(editorRef)

      if (quill) {
        let isNew = true
        const contents = quill.getContents()

        const ops = contents.map(operation => {
          if (
            operation.insert[SideBar.blotName] &&
            operation.insert[SideBar.blotName].id &&
            operation.insert[SideBar.blotName].id === sidebarBlot.id
          ) {
            isNew = false

            return {
              insert: {
                [SideBar.blotName]: sidebarBlot
              }
            }
          }

          return operation
        })

        if (isNew) {
          const length = quill.getLength()
          const {index} = quill.getSelection(true)

          quill.insertEmbed(index, SideBar.blotName, sidebarBlot, 'user')

          if (length < 1 + index) {
            quill.insertText(index + 1, '\n', 'user')
          }

          return
        }

        quill.setContents(
          new Delta({
            ops
          }),
          'user'
        )
      }
    },
    [editorRef]
  )

  return (
    <>
      <TextEditorSidebarModal
        modalId={sidebarModalId}
        open={isOpenSidebarModalVal}
        currentSideBarPublicationUrl={currentSideBarPublicationUrl}
        okAction={setSideBar}
        toggleModal={toggleSidebarModal}
        onClose={toggleSidebarModal}
        onNo={toggleSidebarModal}
      />
    </>
  )
}
export default ToolbarSideBar
