import React, {FC, useEffect, useState} from 'react'
import {CircularProgress, Typography} from '@mui/material'
import {ADD_IMAGE_TO_GALLERY_BUTTON_CONSTS} from '../AddImageToGalleryButton/AddImageToGalleryButton.consts'
import {ControlTooltipWrapper, UploadButtonWrapper} from './UploadGalleryButton.styles'
import {UPLOAD_GALLERY_BUTTON_CONSTS} from './UploadGalleryButton.consts'
import {UploadGalleryButtonProps} from './UploadGalleryButtonProps'

export const UploadGalleryButton: FC<UploadGalleryButtonProps> = ({
  imagesCount,
  imagesCountLimit,
  isInvalid,
  loading,
  getGalleryPsUrlsAction
}) => {
  const [curImagesCount, setCurImagesCount] = useState(imagesCount || 0)

  useEffect(() => {
    setCurImagesCount(imagesCount || 0)
  }, [imagesCount])

  return (
    <ControlTooltipWrapper
      title={UPLOAD_GALLERY_BUTTON_CONSTS.uploadGalleryTooltip}
      disabledTitle={ADD_IMAGE_TO_GALLERY_BUTTON_CONSTS.imagesCountLimitMessage}
      disabled={curImagesCount >= imagesCountLimit}
      color={'default'}
    >
      <UploadButtonWrapper
        variant='outlined'
        disabled={isInvalid || loading || curImagesCount >= imagesCountLimit}
        onClick={() => getGalleryPsUrlsAction()}
      >
        {loading && <CircularProgress size='1rem' />}
        <Typography variant='text14M'>{UPLOAD_GALLERY_BUTTON_CONSTS.uploadGallery}</Typography>
      </UploadButtonWrapper>
    </ControlTooltipWrapper>
  )
}
