import styled from 'styled-components'
import {blackColor, blueColor, lightgrayColor, mainTextColor} from '../../../../../@theme/vars'
import {Button, ButtonGroup} from '@mui/material'

export const SubRubricTitleWrapper = styled.div`
  color: ${blackColor};
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  box-shadow: none;
  margin-bottom: 12px;
`

export const SubRubricPickerButtonGroup = styled(ButtonGroup)`
  box-shadow: none;
  display: block;
`

export const SubRubricPickerButton = styled(Button)`
  color: var(--black, #222);
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 12px 20px;
  margin-bottom: 16px;
  border: 1px solid ${lightgrayColor} !important;
  background-color: inherit;
  box-shadow: none;

  &:hover {
    background-color: #d5d5d5;
    color: ${mainTextColor};
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border: 1px solid #bdbdbd;
  }

  &.active {
    background-color: ${blueColor};
    outline: none;
    color: #fafafa;
    border: 1px solid ${lightgrayColor};
  }
`
