import styled from 'styled-components'
import {blueColor, lightGrayColor2, lightGreenColor, lightRedColor2, mainTextColor} from '../../../@theme/vars'
import {Button} from '@mui/material'

export const ReportsListTableWrapper = styled.div`
  overflow-x: auto;
`

export const TableWrapper = styled.div`
  width: 1257px;
`

export const RestoreFieldButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-bottom: 12px;
`

export const RestoreFieldButton = styled(Button)`
  background-color: ${lightRedColor2};
  font-size: 12px;
  font-weight: 500;
  color: ${mainTextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  border-radius: 12px;
  padding: 5px 12px;
  margin-top: 8px;
  margin-right: 8px;
`

export const RestoreAllFieldButton = styled(Button)`
  background-color: ${lightGreenColor};
  font-size: 12px;
  font-weight: 500;
  color: ${mainTextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  border-radius: 12px;
  padding: 5px 12px;
  margin-top: 8px;
  margin-right: 8px;
`
