import {RefObject, useLayoutEffect, useState} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export interface Bounds {
    left: number;
    height: number;
    top: number;
    right: number;
    bottom: number;
    width: number;
}

export function useMeasure(ref: RefObject<HTMLDivElement | null>) {
    const [bounds, setBounds] = useState<Bounds>({
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        right: 0,
        bottom: 0
    })

    const [observer] = useState(
        () =>
            // @ts-ignore
            new ResizeObserver(([entry]) => {
                setBounds(entry.target.getBoundingClientRect())
            })
    )

    useLayoutEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
        }
        return () => observer.disconnect()
    }, [ref, observer])

    function remeasure() {
        setBounds(ref.current!.getBoundingClientRect())
    }

    return {bounds, remeasure}
}
