import React, {FC} from 'react'

const LightingIcon: FC<any> = () => {
    return (
        <svg
            width='14'
            height='24'
            viewBox='0 0 14 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M5.66812 24H4.33479L5.66812 14.6667H1.00145C0.22812 14.6667 0.241453 14.24 0.494787 13.7867C0.74812 13.3333 0.561454 13.68 0.58812 13.6267C2.30812 10.5867 4.89479 6.05333 8.33479 0H9.66812L8.33479 9.33333H13.0015C13.6548 9.33333 13.7481 9.77333 13.6281 10.0133L13.5348 10.2133C8.28145 19.4 5.66812 24 5.66812 24Z'
                fill='#1964E7'
            />
        </svg>
    )
}

export default LightingIcon
