export const LAST_PUBLICATION_COMMENTS_CONTENT_CONSTS = {
  allComments: 'Все комментарии',
  isBad: 'Плохие',
  isNew: 'Новые',
  loading: 'Загрузка...',
  newComments: 'Новых комментариев',
  nothingWasFounded: 'Ничего не найдено.',
  searchByTitle: 'Поиск по заголовку',
  searchResult: 'Результаты поиска:',
  sortFieldLabel: 'Сортировка',
  title: 'Заголовок',
  type: 'Тип'
}
