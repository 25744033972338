import {FC, useCallback} from 'react'
import {toast} from 'react-toastify'
import {Quill} from 'react-quill'
import {useStore} from '@stores/rootStoreContext'
import {getQuillEditor} from '@utils/getQuillEditor'
import {fetchGenerateText} from '@fetch/NnAPI'
import {Button, MenuItem} from '../ToolbarNn.styles'
import {TOOLBAR_BUTTONS_TITLES} from '../ToolbarNn.consts'

const Delta = Quill.import('delta')

type Props = {
  editorRef: React.MutableRefObject<any>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
}

const GenerateTextButton: FC<Props> = ({editorRef, setIsLoading, handleClose}) => {
  const {publicationStore} = useStore()

  const handleGenerateText = useCallback(async () => {
    handleClose()
    const quill = getQuillEditor(editorRef)
    const {publication} = publicationStore
    const publicationId = publication?.deprecatedId
    const publicationUid = publication?.id

    if (quill) {
      let requestText = ''
      const delta = quill.getContents()

      delta.forEach(item => {
        if (typeof item.insert === 'string') requestText += item.insert + ' '
      })

      if (requestText.length <= 1) {
        toast.info(TOOLBAR_BUTTONS_TITLES.notFoundText, {
          containerId: 'success',
          autoClose: 10000,
          hideProgressBar: true
        })
        return setIsLoading(false)
      }

      const data = {text: requestText, publicationId, publicationUid}
      setIsLoading(true)

      try {
        const generateTextJson = await fetchGenerateText(data)
        if (!generateTextJson) {
          console.error('Не удалось получить данные от сервера.')
          return
        }
        const regExp = /\n\s*\n+/g
        const text = generateTextJson.text.replace(regExp, '\n')
        quill.setContents(
          new Delta({
            ops: [
              {
                insert: text
              }
            ]
          }),
          'user'
        )
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        // enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
      } finally {
        setIsLoading(false)
      }
    }
  }, [editorRef, publicationStore, setIsLoading, handleClose])

  return (
    <MenuItem>
      <Button onClick={handleGenerateText}>{TOOLBAR_BUTTONS_TITLES.generateTextTopic}</Button>
    </MenuItem>
  )
}

export default GenerateTextButton
