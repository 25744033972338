import * as Types from '../../../../../@graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TopNewsSelectionHistoryQueryVariables = Types.Exact<{
  date: Types.Scalars['Date'];
  regionId: Types.Scalars['UUID'];
}>;


export type TopNewsSelectionHistoryQuery = (
  { __typename?: 'Query' }
  & { topNewsSelectionHistory?: Types.Maybe<Array<(
    { __typename?: 'TopNewsBlock' }
    & Pick<Types.TopNewsBlock, 'uid' | 'position' | 'addedAt' | 'removedAt' | 'url' | 'title' | 'isExclusive' | 'isAd' | 'clickCount' | 'createdAt' | 'updatedAt'>
    & { region?: Types.Maybe<(
      { __typename?: 'Region' }
      & Pick<Types.Region, 'id'>
    )>, figureVersion?: Types.Maybe<(
      { __typename?: 'FigureVersion' }
      & { figure: (
        { __typename?: 'Figure' }
        & Pick<Types.Figure, 'uid'>
        & { latestVersion: (
          { __typename?: 'FigureVersion' }
          & Pick<Types.FigureVersion, 'uid'>
          & { primaryProcessedImage?: Types.Maybe<(
            { __typename?: 'Image' }
            & Pick<Types.Image, 'url'>
          )> }
        ) }
      ), primaryProcessedImage?: Types.Maybe<(
        { __typename?: 'Image' }
        & Pick<Types.Image, 'url'>
      )> }
    )> }
  )>> }
);


export const TopNewsSelectionHistoryDocument = gql`
    query topNewsSelectionHistory($date: Date!, $regionId: UUID!) {
  topNewsSelectionHistory(regionId: $regionId, date: $date) {
    uid
    region {
      id
    }
    figureVersion {
      figure {
        uid
        latestVersion {
          uid
          primaryProcessedImage {
            url
          }
        }
      }
      primaryProcessedImage {
        url
      }
    }
    position
    addedAt
    removedAt
    url
    title
    isExclusive
    isAd
    clickCount
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useTopNewsSelectionHistoryQuery__
 *
 * To run a query within a React component, call `useTopNewsSelectionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNewsSelectionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNewsSelectionHistoryQuery({
 *   variables: {
 *      date: // value for 'date'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useTopNewsSelectionHistoryQuery(baseOptions: Apollo.QueryHookOptions<TopNewsSelectionHistoryQuery, TopNewsSelectionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopNewsSelectionHistoryQuery, TopNewsSelectionHistoryQueryVariables>(TopNewsSelectionHistoryDocument, options);
      }
export function useTopNewsSelectionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopNewsSelectionHistoryQuery, TopNewsSelectionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopNewsSelectionHistoryQuery, TopNewsSelectionHistoryQueryVariables>(TopNewsSelectionHistoryDocument, options);
        }
export type TopNewsSelectionHistoryQueryHookResult = ReturnType<typeof useTopNewsSelectionHistoryQuery>;
export type TopNewsSelectionHistoryLazyQueryHookResult = ReturnType<typeof useTopNewsSelectionHistoryLazyQuery>;
export type TopNewsSelectionHistoryQueryResult = Apollo.QueryResult<TopNewsSelectionHistoryQuery, TopNewsSelectionHistoryQueryVariables>;