import styled from 'styled-components'

export const CropperWrapper = styled.div`
  position: absolute;
  right: 25px;

  & img {
    width: 340px;
    height: 180px;

    @media screen and (max-width: 654px) {
      position: relative;
      width: calc(100% - 30px);
      min-height: 180px;
      height: auto;
    }
  }

  @media screen and (max-width: 654px) {
    position: inherit;
  }

  & div.backdrop > div.cropper-container div.cropper-crop-box {
    span.cropper-face.cropper-move {
      background-color: #000;
      opacity: 0.5;
    }
  }
`

export const SocialImageTitleWrapper = styled.div`
  position: absolute;
  width: 338px;
  // height: 178px;
  left: 12px;
  bottom: calc(100% - 178px);
  align-items: end;
  display: flex;

  @media screen and (max-width: 654px) {
    position: relative;
    width: calc(100% - 24px);
  }
`

export const SocialImageTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  width: 90%;
  color: #ffffff;
`

export const LogoWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;

  @media screen and (max-width: 654px) {
    position: relative;
    display: flex;
    margin-top: -76px;
    top: 84px;
  }
`
export const DragAndDropIconWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;

  @media screen and (max-width: 654px) {
    position: relative;
    display: flex;
    align-self: flex-end;
    top: 48px;
    left: 90%;
    width: 36px;
    z-index: 1;
  }
  @media screen and (max-width: 480px) {
    left: 86%;
  }
  @media screen and (max-width: 400px) {
    left: 84%;
  }
`

export const AnnounceImageTitle = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #4e4e4e;
  width: 100%;
  margin-top: 12px;

  @media screen and (max-width: 654px) {
    margin-top: -12px;
  }
`
