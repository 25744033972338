import React, {FC} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import styled from 'styled-components'
import {ArrowBack} from '@mui/icons-material'
import {useHistory} from 'react-router-dom'
import {Box, LinearProgress, Typography, IconButton} from '@mui/material'
import BlockChange from '@components/MainSelection/TopNews/BlockChange/BlockChange'
import {useParams} from 'react-router'
import {useTopNewsBlockHistoryQuery} from './gql/TopNewsBlockHistory.generated'
import {TopNewsBlockChange} from '@graphql/types'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`

const TopNewsBlockHistoryPage: FC = () => {
  const {uid} = useParams<{uid: string}>()
  const history = useHistory()
  const {data, loading} = useTopNewsBlockHistoryQuery({
    variables: {topNewsBlockId: uid},
    fetchPolicy: 'cache-and-network'
  })

  if (loading) return <LinearProgress />

  return (
    <PageContainer>
      <Wrapper>
        <Header>
          <IconButton size='small' title='Назад' color='secondary' onClick={history.goBack}>
            <ArrowBack />
          </IconButton>
          <Box marginLeft='25px'>
            <Typography variant='h1'>История блока</Typography>
          </Box>
        </Header>
        <Box>
          {data?.topNewsBlockHistory?.map((change, i) => (
            <BlockChange key={change.uid} change={change as TopNewsBlockChange} index={i} />
          ))}
        </Box>
      </Wrapper>
    </PageContainer>
  )
}

export default TopNewsBlockHistoryPage
