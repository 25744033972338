import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

export const CashIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox='-1 -2 16 16' {...props}>
      <path
        d='M12.667 7.33341V2.00008C12.667 1.26675 12.067 0.666748 11.3337 0.666748H2.00033C1.26699 0.666748 0.666992 1.26675 0.666992 2.00008V7.33341C0.666992 8.06675 1.26699 8.66675 2.00033 8.66675H11.3337C12.067 8.66675 12.667 8.06675 12.667 7.33341ZM11.3337 7.33341H2.00033V2.00008H11.3337V7.33341ZM6.66699 2.66675C5.56033 2.66675 4.66699 3.56008 4.66699 4.66675C4.66699 5.77341 5.56033 6.66675 6.66699 6.66675C7.77366 6.66675 8.66699 5.77341 8.66699 4.66675C8.66699 3.56008 7.77366 2.66675 6.66699 2.66675ZM15.3337 2.66675V10.0001C15.3337 10.7334 14.7337 11.3334 14.0003 11.3334H2.66699C2.66699 10.6667 2.66699 10.7334 2.66699 10.0001H14.0003V2.66675C14.7337 2.66675 14.667 2.66675 15.3337 2.66675Z'
        fill='#C4C4C4'
      />
    </SvgIcon>
  )
}
