import React, {ChangeEvent, FC, useState} from 'react'
import {Tabs, Tab, TextField, OutlinedInput, InputAdornment, IconButton, InputLabel} from '@mui/material'
import styled from 'styled-components'
import StoriesListContent from './StoriesListContent/StoriesListContent'
import {DatePicker} from '@mui/x-date-pickers'
import {Clear} from '@mui/icons-material'
import {StyledFormControl} from '@components/UI/styled'

type TabPanelPropsProps = {
  index: number
  value: number
}

type StoriesTabsTypeProps = {
  tabNames: {
    title: string
    name: string
    isActive: boolean
  }[]
}

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid #c4c4c4;
`

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const KeyboardDatePickerWrapper = styled(DatePicker)`
  display: flex;
  width: 274px;
  & input {
    padding: 0;
  }

  @media (max-width: 650px) {
    margin-bottom: 12px;
    width: calc(100% - 20px);
  }
`

const StoriesList: FC<StoriesTabsTypeProps> = ({tabNames}) => {
  const [value, setValue] = useState(0)
  const [searchString, setSearchString] = useState('')
  const [searchDate, setSearchDate] = useState<any>(null)
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      <StyledTabs value={value} onChange={handleChange} indicatorColor='primary' textColor='primary'>
        {tabNames.map((tab, i) => (
          <Tab label={tab.title} key={i} />
        ))}
      </StyledTabs>

      <SearchWrapper>
        <StyledFormControl style={{margin: '16px 24px 8px 0', width: '300px'}}>
          <InputLabel htmlFor='outlined-basic' style={{marginTop: '-3px'}}>
            {'Поиск по заголовку...'}
          </InputLabel>
          <OutlinedInput
            id='outlined-basic'
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                {searchString && (
                  <IconButton edge='end' size='small' onClick={() => setSearchString('')}>
                    <Clear fontSize='small' />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </StyledFormControl>
        <div style={{margin: '16px 24px 8px 0'}}>
          <KeyboardDatePickerWrapper
            disableToolbar
            variant='inline'
            inputFormat='yyyy-MM-dd'
            margin='normal'
            id='date-picker-inline'
            label='Дата'
            value={searchDate}
            onChange={date => setSearchDate(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            slotProps={{
              openPickerButton: {color: 'primary'},
              field: {clearable: true}
            }}
          />
        </div>
      </SearchWrapper>

      {tabNames.map((tab, i) => (
        <TabPanel value={value} index={i} key={i}>
          <StoriesListContent isActive={tab?.isActive} search={searchString} dateUpdatedFrom={searchDate} />
        </TabPanel>
      ))}
    </>
  )
}

const TabPanel: FC<TabPanelPropsProps> = props => {
  const {children, value, index} = props
  return <>{value === index && <>{children}</>}</>
}

export default StoriesList
