import React, {ChangeEvent, cloneElement, FC, ReactElement, useState} from 'react'
import {Tab} from '@mui/material'
import {TabsWithBorder} from '../styled'
import TabPanel from '../TabPanel/TabPanel'
import useWindowResize from '../../../hooks/useWindowResize'
import {useRegionsMainQuery} from './gql/RegionTabs.generated'

type RegionTabsProps = {
  children: ReactElement
}

const RegionTabs: FC<RegionTabsProps> = ({children}) => {
  const {data, loading} = useRegionsMainQuery()
  const [value, setValue] = useState(0)
  const [width] = useWindowResize()

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {width && !loading && data?.regions?.length && (
        <TabsWithBorder
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='inherit'
          scrollButtons={true}
          variant='scrollable'
        >
          {data?.regions?.map(region => (
            <Tab label={region.title} key={region.uid} />
          ))}
        </TabsWithBorder>
      )}

      {data?.regions?.map((region, i) => (
        <TabPanel value={value} index={i} key={region.uid}>
          {cloneElement(children, {
            regionId: region.uid || ''
          })}
        </TabPanel>
      ))}
    </>
  )
}

export default RegionTabs
