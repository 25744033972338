import React, {FC, useEffect, useRef, useState} from 'react'
import useWindowResize from '../../../../hooks/useWindowResize'
import styled from 'styled-components'
import {GridContextProvider, GridItem, swap} from '../../../../libs/react-dnd/grid'
import {SizedDropZone} from '../../../UI/styled'
import MainSelectionCard from '../../MainSelectionCard/MainSelectionCard'
import {CardSize} from '../../../../components/MainSelection/MainSelectionCard/MainSelectionCardProps'
import {OverviewBlock} from '@graphql/types'

const Wrapper = styled.div`
  width: 100%;
  max-width: 962px;
`

type OverviewGridDropProps = {
  overviewBlocks: OverviewBlock[]
  handleDeleteBlock: (blockUid: string) => void
  handleOpenEditorCardModal: (blockUid: string) => void
  onChangeGridDrop: (nextState: OverviewBlock[], overviewBlockUid: string, position: number) => void
}

const OverviewGridDrop: FC<OverviewGridDropProps> = ({
  overviewBlocks,
  handleOpenEditorCardModal,
  handleDeleteBlock,
  onChangeGridDrop
}) => {
  const [boxesPerRow, setBoxesPerRow] = useState(3)
  const boxGridDropZoneRef = useRef<any>(null)

  const [width] = useWindowResize()

  useEffect(() => {
    const fullBoxWidth = Math.floor(boxGridDropZoneRef.current.clientWidth / CardSize.overview.width)
    const countBoxes = fullBoxWidth < 3 ? fullBoxWidth : 3
    setBoxesPerRow(countBoxes)
  }, [boxGridDropZoneRef?.current?.clientWidth, width])

  const onChange = (sourceId: any, sourceIndex: number, targetIndex: number): void => {
    const nextState = swap(overviewBlocks, sourceIndex, targetIndex)
    const position = targetIndex < overviewBlocks.length - 1 ? targetIndex : overviewBlocks.length - 1
    void onChangeGridDrop(nextState, overviewBlocks[sourceIndex].uid, position)
  }
  return (
    <Wrapper ref={boxGridDropZoneRef}>
      <GridContextProvider onChange={onChange}>
        <SizedDropZone
          id='items'
          boxesPerRow={boxesPerRow}
          rowHeight={CardSize.overview.height + 30}
          $heightZone={
            overviewBlocks.length <= 4
              ? CardSize.overview.height + 30
              : (overviewBlocks.length / boxesPerRow) * CardSize.overview.height + 30
          }
        >
          {overviewBlocks.map((overviewBlock, index) => (
            <GridItem key={overviewBlock.uid}>
              <MainSelectionCard
                key={overviewBlock.uid}
                index={index}
                data={overviewBlock}
                overview
                handleDeleteBlock={handleDeleteBlock}
                handleOpenEditor={handleOpenEditorCardModal}
              />
            </GridItem>
          ))}
        </SizedDropZone>
      </GridContextProvider>
    </Wrapper>
  )
}

export default OverviewGridDrop
