import React, {FC, useEffect, useState} from 'react'
import {Typography} from '@mui/material'
import {ADD_IMAGE_TO_GALLERY_BUTTON_CONSTS} from './AddImageToGalleryButton.consts'
import {AddImageToGalleryButtonProps} from './AddImageToGalleryButtonProps'
import {ControlTooltipWrapper, UploadPhotoButtonWrapper} from './AddImageToGalleryButton.styles'

export const AddImageToGalleryButton: FC<AddImageToGalleryButtonProps> = ({
  imagesCount,
  imagesCountLimit,
  loading,
  openImageDialog
}) => {
  const [curImagesCount, setCurImagesCount] = useState(imagesCount || 0)

  useEffect(() => {
    setCurImagesCount(imagesCount || 0)
  }, [imagesCount])

  return (
    <ControlTooltipWrapper
      title={ADD_IMAGE_TO_GALLERY_BUTTON_CONSTS.addPhotoTooltip}
      disabledTitle={ADD_IMAGE_TO_GALLERY_BUTTON_CONSTS.imagesCountLimitMessage}
      disabled={curImagesCount >= imagesCountLimit}
      color={'default'}
      withoutTooltipIfActive={true}
    >
      <UploadPhotoButtonWrapper
        onClick={openImageDialog}
        disabled={loading || curImagesCount >= imagesCountLimit}
        id={'upload-gallery-btn'}
      >
        <Typography variant='text16R'>{ADD_IMAGE_TO_GALLERY_BUTTON_CONSTS.addPhoto}</Typography>
      </UploadPhotoButtonWrapper>
    </ControlTooltipWrapper>
  )
}
