import React, {FC, useCallback, useState} from 'react'
import {StyledFormControl, ButtonForInput} from '../styled'
import {Box, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'
import Clear from '@mui/icons-material/Clear'
import {useSearchPublicationByDeprecatedIdLazyQuery} from './gql/SearchPublication.generated'
import {useFormContext, useWatch} from 'react-hook-form'

const label = 'Ссылка на публикацию'

const RHFSearchPublication: FC = () => {
  const [url, setUrl] = useState('')
  const [error, setError] = useState('')

  const {control} = useFormContext()
  const fields = useWatch({control})

  const [searchPublication] = useSearchPublicationByDeprecatedIdLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      fields.onAdded(data.publicationByDeprecatedId)
    }
  })

  const handleSetUrlErrors = useCallback((url: string) => {
    setUrl(url)
    setError('')
  }, [])

  const getOldId = useCallback(() => {
    const regExp = /\/?(\d{10})\/?/
    const match = regExp.exec(url)
    const deprecatedId = match ? match[1] : ''

    if (!deprecatedId) {
      setError('Неверная ссылка')
      return
    }

    try {
      searchPublication({
        variables: {deprecatedId: +deprecatedId}
      })
    } catch {}

    setUrl('')
  }, [searchPublication, url])
  return (
    <Box display='flex' justifyContent='center'>
      <StyledFormControl width='346px'>
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          label={label}
          onChange={e => handleSetUrlErrors(e.target.value)}
          value={url}
          error={!!error}
          endAdornment={
            <InputAdornment position='end'>
              {url && (
                <IconButton edge='end' size='small' onClick={() => handleSetUrlErrors('')}>
                  <Clear fontSize='small' />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <FormHelperText>{error}</FormHelperText>
      </StyledFormControl>
      <ButtonForInput variant='outlined' color='primary' disabled={!url} onClick={getOldId}>
        Добавить
      </ButtonForInput>
    </Box>
  )
}

export default RHFSearchPublication
