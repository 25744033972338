/**
 * @constant Статические данные для BildCheckListTableItem
 */
export const BILD_CHECK_LIST_TABLE_ITEM_CONSTS = {
  addMsg: 'Добавить',
  approveMsg: 'Одобрить',
  editMsg: 'Редактировать',
  goToNewsEditingMsg: 'Перейти в редактирование публикации',
  withoutNameMsg: 'Без названия',
  checkedByRedactor: 'Проверена редактором',
  setCheckedMsg: 'Одобрить?',
  unChecked: 'Не проверена редактором'
}
