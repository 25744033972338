import styled from 'styled-components'
import {mainTextColor, blueColor, grey35, whiteColor} from '../../../../@theme/vars'
import {Accordion, AccordionSummary, Button} from '@mui/material'

export const AccordionWrapper = styled(Accordion)`
  border: 1px solid ${grey35};
  border-radius: 6px;
  box-shadow: none;
  background-color: ${whiteColor};
`

export const AccordionSummaryWrapper = styled(AccordionSummary)`
  color: ${blueColor};
  border-bottom: 1px solid ${grey35};
  background-color: #f1f6ff;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px !important;
  }

  & div.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0px !important;
  }

  &.Mui-disabled {
    opacity: 1;
  }
  &.MuiAccordionSummary-content {
    margin: 12px 0px !important;
  }
`

export const YandexVideosTitleWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: ${mainTextColor};
  padding-left: 4px;

  &.disabled {
    color: ${mainTextColor};
    background-color: #f1f6ff;
    opacity: 1;
  }
`

export const YandexVideosFooterWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 20px 24px 20px;
  border-radius: 0px 0px 6px 6px;
  background-color: ${whiteColor};
`

export const YandexVideosAddButton = styled(Button)`
  width: 256px;
  color: ${blueColor};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 8px 26px;

  @media screen and (max-width: 500px) {
    align-self: center;
    width: 100%;
  }
`
