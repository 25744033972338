import React, {FC, useEffect, useRef, useState} from 'react'
import Cropper, {ReactCropperElement} from 'react-cropper'
import {debounce} from 'lodash'
import UraLogoIcon from '@components/UI/Icons/UraLogo'
import DragAndDropIcon from '@components/UI/Icons/DragAndDrop'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'
import useWindowResize from '../../../../hooks/useWindowResize'
import {
  AnnounceImageTitle,
  CropperWrapper,
  DragAndDropIconWrapper,
  LogoWrapper,
  SocialImageTitle,
  SocialImageTitleWrapper
} from './SocialImage.styles'
import {SocialImageProps} from './SocialImageProps'
import {SOCIAL_IMAGE_CONSTS} from './SocialImage.consts'

/**
 * Компонент отрисовки картинки для соц.сетей
 * @param param0
 * @returns
 */
export const SocialImage: FC<SocialImageProps> = ({socialImage, publicationTitle, setAlygnY}) => {
  const cropperRef = useRef<ReactCropperElement>(null)

  const [imageUrl, setImageUrl] = useState<string>(socialImage?.socialMediaFigureVersion?.processedImage?.url || '')
  const [width] = useWindowResize()
  const [containerWidth, setContainerWidth] = useState<number | undefined>()
  const [containerHeight, setContainerHeight] = useState<number | undefined>()
  const [curAlignY, setCurAlignY] = useState(socialImage?.socialMediaFigureVersion?.alignY || 0)

  const resizeContainer = debounce(newWidth => {
    setContainerWidth(newWidth >= 655 ? 340 : undefined)
    setContainerHeight(newWidth >= 655 ? 180 : undefined)
  }, 100)

  useEffect(() => {
    resizeContainer(width)
  }, [width, resizeContainer])

  const [currentCropper, setCurrentCropper] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0
  })

  useEffect(() => {
    setImageUrl(
      socialImage?.socialMediaFigureVersion?.processedImage?.url ||
        socialImage?.socialMediaFigureVersion?.announceFigureVersion?.figure?.latestVersion?.primaryProcessedImage
          ?.url ||
        ''
    )
    setCurAlignY(socialImage?.socialMediaFigureVersion?.alignY || 0)
  }, [socialImage?.socialMediaFigureVersion])

  // useEffect(() => {
  //   if (cropperRef?.current) {
  //     const imageElement: ReactCropperElement = cropperRef.current
  //     const cropper: Cropper.Data | undefined = imageElement?.cropper?.getData(true)

  //     setCurrentCropper({
  //       left: cropper?.x || 0,
  //       top: curAlignY * ((imageElement?.height || 0) - (width >= 655 ? 340 : width - 45)),
  //       width: cropper?.width || 0,
  //       height: cropper?.height || 0
  //     })
  //   }
  // }, [curAlignY, width, cropperRef])

  const onCrop = debounce(() => {
    if (cropperRef?.current) {
      const imageElement: ReactCropperElement = cropperRef?.current
      const cropper: Cropper.Data | undefined = imageElement?.cropper?.getData(true)

      setCurrentCropper({
        left: cropper?.x || 0,
        top: cropper?.y || 0,
        width: cropper?.width || 0,
        height: cropper?.height || 0
      })

      const newAlignY = currentCropper.top / ((imageElement?.height || 0) - currentCropper.height)
      setCurAlignY(newAlignY)
      setAlygnY(newAlignY || 0)
    }
  }, 300)

  return (
    <ControlTooltip title={SOCIAL_IMAGE_CONSTS.socialImageTooltipMsg} enterDelay={0} leaveDelay={0} color={'default'}>
      <CropperWrapper>
        <LogoWrapper>
          <UraLogoIcon />
        </LogoWrapper>
        <DragAndDropIconWrapper>
          <DragAndDropIcon />
        </DragAndDropIconWrapper>
        <Cropper
          src={imageUrl}
          aspectRatio={181 / 95}
          movable={true}
          scalable={false}
          zoomable={false}
          cropBoxMovable={false}
          cropBoxResizable={false}
          viewMode={3}
          checkCrossOrigin={true}
          toggleDragModeOnDblclick={false}
          dragMode='move'
          className='backdrop'
          guides={false}
          ref={cropperRef}
          center={false}
          crop={onCrop}
          autoCropArea={1}
          minCropBoxWidth={containerWidth}
          minCropBoxHeight={containerHeight}
          minContainerWidth={containerWidth}
          minCanvasWidth={containerWidth}
          background={false}
          id='social-image-cropper'
          data={{
            y: (socialImage?.socialMediaFigureVersion?.alignY || 0) * ((cropperRef?.current?.height || 0) - 369),
            x: currentCropper?.left || 0,
            width: currentCropper?.width || 0,
            height: currentCropper?.height || 0
          }}
        />
        <SocialImageTitleWrapper>
          <SocialImageTitle>{publicationTitle}</SocialImageTitle>
        </SocialImageTitleWrapper>
        <AnnounceImageTitle>{SOCIAL_IMAGE_CONSTS.socialAnnounceImageMsg}</AnnounceImageTitle>
      </CropperWrapper>
    </ControlTooltip>
  )
}
