import {Button} from '@mui/material'
import {grayColor, grey20} from '../../../../../../@theme/vars'
import styled from 'styled-components'

export const MainPageCell = styled.div`
  padding: 5px;
  display: flex;
  width: 202px;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  margin: auto;
  background: #fff;
  /* position: absolute;
  z-index: 100; */
  margin-left: -144px;
`

export const CellButton = styled(Button)`
  width: 60px;
  border: 1px solid ${grey20};
  border-radius: 0;
  color: ${grayColor};
  display: flex;
  background: #fff;
`
