import styled from 'styled-components'
import {blueColor, grayColor, grey35, mainTextColor, whiteColor} from '../../../../../@theme/vars'
import {Accordion, AccordionSummary, MenuItem} from '@mui/material'
import {StyledFormControl} from '@components/UI/styled'
import {Controller} from 'react-hook-form'

export const AccordionWrapper = styled(Accordion)`
  border: 1px solid ${grey35};
  border-radius: 6px;
  box-shadow: none;
  background-color: ${whiteColor};
  width: 100%;
  margin: 0px 0px;
`

export const AccordionSummaryWrapper = styled(AccordionSummary)`
  color: ${blueColor};
  border-bottom: 1px solid ${grey35};
  background-color: #f1f6ff;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  padding-right: 10px;

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px !important;
  }

  &.Mui-disabled {
    opacity: 1;
  }

  & div.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0px !important;
  }
`

export const RegionsTitleWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${mainTextColor};
  padding-left: 4px;
  width: 100%;

  &.disabled {
    color: ${mainTextColor};
    background-color: #f1f6ff;
    opacity: 1;
  }
`

export const RegionsSelectedTitlesWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${mainTextColor};
`

export const ArrowDropDownIconWrapper = styled.div`
  color: ${mainTextColor};
`

export const RegionsWithOverviewBlocksControllerWrapper = styled(Controller)`
  padding: 0px !important;

  &.MuiAccordionDetails-root {
    padding: 0px;
  }
`

export const RegionsWithOverviewBlocksStyledFormControl = styled(StyledFormControl)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`

export const RegionsWithOverviewBlocksMenuItem = styled(MenuItem)`
  width: 50%;
  border-bottom: 1px solid ${grey35};
  border-right: 1px solid ${grey35};
  padding-left: 15px;
  color: ${grayColor};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`

export const RegionsWithOverviewBlocksMenuItemTitle = styled.div`
  width: calc(100% - 126px);
  height: auto;
`
