import React, {FC} from 'react'
import {observer} from 'mobx-react-lite'
import {Checkbox, FormControlLabel} from '@mui/material'
import {useStore} from '@stores/rootStoreContext'
import {PUBLICATION_FORM_DETAILS_CONSTS} from '../../PublicationForm/PublicationFormOverviewBlocks/PublicationFormDetails/PublicationFormDetails.consts'
import {IPublicationFormCheckingPhotoEditorCheckbox} from './IPublicationFormCheckingPhotoEditorCheckbox'

const PublicationFormCheckingPhotoEditorCheckbox: FC<IPublicationFormCheckingPhotoEditorCheckbox> = ({
  disabled,
  width
}) => {
  const {publicationStore} = useStore()
  const {checkingPhotoEditorCache, setCheckingPhotoEditorCache} = publicationStore

  return (
    <FormControlLabel
      style={{width}}
      control={
        <Checkbox
          checked={checkingPhotoEditorCache}
          color='primary'
          disabled={disabled}
          onChange={() => {
            setCheckingPhotoEditorCache(!checkingPhotoEditorCache)
          }}
        />
      }
      label={PUBLICATION_FORM_DETAILS_CONSTS.checkingPhotoEditor}
    />
  )
}

export default observer(PublicationFormCheckingPhotoEditorCheckbox)
