import * as Types from '../../../../@graphql/types'

import {PublicationEdgeCollectionFragment} from '../../../../pages/Collections/gql/Collections.generated'
import {gql} from '@apollo/client'
import {PublicationEdgeCollectionFragmentDoc} from '../../../../pages/Collections/gql/Collections.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type UpdateTagMutationVariables = Types.Exact<{
  data: Types.UpdateTagInput
}>

export type UpdateTagMutation = {__typename?: 'Mutation'} & {
  updateTag?: Types.Maybe<
    {__typename?: 'UpdateTagPayload'} & {
      tag?: Types.Maybe<
        {__typename?: 'Tag'} & Pick<Types.Tag, 'uid' | 'title' | 'description' | 'priority'> & {
            figure?: Types.Maybe<{__typename?: 'Figure'} & FigureFragment>
            relatedTags: Array<{__typename?: 'Tag'} & Pick<Types.Tag, 'title' | 'uid'>>
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<Types.Maybe<{__typename?: 'PublicationsEdge'} & PublicationEdgeCollectionFragment>>
                pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
              }
            >
          }
      >
    }
  >
}

export type RemovePublicationsFromTagMutationVariables = Types.Exact<{
  data: Types.RemovePublicationsFromTagInput
}>

export type RemovePublicationsFromTagMutation = {__typename?: 'Mutation'} & {
  removePublicationsFromTag?: Types.Maybe<
    {__typename?: 'RemovePublicationsFromTagPayload'} & {
      tag?: Types.Maybe<
        {__typename?: 'Tag'} & Pick<Types.Tag, 'uid'> & {
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<
                  Types.Maybe<
                    {__typename?: 'PublicationsEdge'} & {
                      node?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'uid'>>
                    }
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type AddPublicationsToTagMutationVariables = Types.Exact<{
  data: Types.AddPublicationsToTagInput
}>

export type AddPublicationsToTagMutation = {__typename?: 'Mutation'} & {
  addPublicationsToTag?: Types.Maybe<
    {__typename?: 'AddPublicationsToTagPayload'} & {
      tag?: Types.Maybe<
        {__typename?: 'Tag'} & Pick<Types.Tag, 'uid'> & {
            publications?: Types.Maybe<
              {__typename?: 'PublicationsConnection'} & {
                edges: Array<
                  Types.Maybe<
                    {__typename?: 'PublicationsEdge'} & {
                      node?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'uid'>>
                    }
                  >
                >
              }
            >
          }
      >
    }
  >
}

export type PublicationsByTagUidQueryVariables = Types.Exact<{
  tagUid?: Types.Maybe<Types.Scalars['UUID']>
  limit?: Types.Maybe<Types.Scalars['Int']>
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
}>

export type PublicationsByTagUidQuery = {__typename?: 'Query'} & {
  publications?: Types.Maybe<
    {__typename?: 'PublicationsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'PublicationsEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'Publication'} & Pick<Types.Publication, 'uid' | 'title'> & {
                  regions?: Types.Maybe<
                    Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>
                  >
                  authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
                }
            >
          }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
    }
  >
}

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<Types.FigureType, 'id' | 'uid' | 'slug'>
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
        crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
        primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        processedImages: Array<{__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>>
      }
  }

export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const UpdateTagDocument = gql`
  mutation updateTag($data: UpdateTagInput!) {
    updateTag(data: $data) {
      tag {
        uid
        title
        description
        figure {
          ...Figure
        }
        priority
        relatedTags {
          title
          uid
        }
        publications {
          edges {
            ...PublicationEdgeCollection
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${FigureFragmentDoc}
  ${PublicationEdgeCollectionFragmentDoc}
`
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options)
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>
export const RemovePublicationsFromTagDocument = gql`
  mutation removePublicationsFromTag($data: RemovePublicationsFromTagInput!) {
    removePublicationsFromTag(data: $data) {
      tag {
        uid
        publications {
          edges {
            node {
              uid
            }
          }
        }
      }
    }
  }
`
export type RemovePublicationsFromTagMutationFn = Apollo.MutationFunction<
  RemovePublicationsFromTagMutation,
  RemovePublicationsFromTagMutationVariables
>

/**
 * __useRemovePublicationsFromTagMutation__
 *
 * To run a mutation, you first call `useRemovePublicationsFromTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePublicationsFromTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePublicationsFromTagMutation, { data, loading, error }] = useRemovePublicationsFromTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemovePublicationsFromTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePublicationsFromTagMutation,
    RemovePublicationsFromTagMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<RemovePublicationsFromTagMutation, RemovePublicationsFromTagMutationVariables>(
    RemovePublicationsFromTagDocument,
    options
  )
}
export type RemovePublicationsFromTagMutationHookResult = ReturnType<typeof useRemovePublicationsFromTagMutation>
export type RemovePublicationsFromTagMutationResult = Apollo.MutationResult<RemovePublicationsFromTagMutation>
export type RemovePublicationsFromTagMutationOptions = Apollo.BaseMutationOptions<
  RemovePublicationsFromTagMutation,
  RemovePublicationsFromTagMutationVariables
>
export const AddPublicationsToTagDocument = gql`
  mutation addPublicationsToTag($data: AddPublicationsToTagInput!) {
    addPublicationsToTag(data: $data) {
      tag {
        uid
        publications {
          edges {
            node {
              uid
            }
          }
        }
      }
    }
  }
`
export type AddPublicationsToTagMutationFn = Apollo.MutationFunction<
  AddPublicationsToTagMutation,
  AddPublicationsToTagMutationVariables
>

/**
 * __useAddPublicationsToTagMutation__
 *
 * To run a mutation, you first call `useAddPublicationsToTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPublicationsToTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPublicationsToTagMutation, { data, loading, error }] = useAddPublicationsToTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddPublicationsToTagMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPublicationsToTagMutation, AddPublicationsToTagMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<AddPublicationsToTagMutation, AddPublicationsToTagMutationVariables>(
    AddPublicationsToTagDocument,
    options
  )
}
export type AddPublicationsToTagMutationHookResult = ReturnType<typeof useAddPublicationsToTagMutation>
export type AddPublicationsToTagMutationResult = Apollo.MutationResult<AddPublicationsToTagMutation>
export type AddPublicationsToTagMutationOptions = Apollo.BaseMutationOptions<
  AddPublicationsToTagMutation,
  AddPublicationsToTagMutationVariables
>
export const PublicationsByTagUidDocument = gql`
  query publicationsByTagUid($tagUid: UUID, $limit: Int = 15, $first: Int, $after: String) {
    publications(limit: $limit, filter: {tagUid: $tagUid}, sort: PUBLISHED_AT_DESC, first: $first, after: $after) {
      edges {
        node {
          uid
          title
          regions {
            uid
            title
          }
          authors {
            uid
            firstName
            lastName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

/**
 * __usePublicationsByTagUidQuery__
 *
 * To run a query within a React component, call `usePublicationsByTagUidQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationsByTagUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationsByTagUidQuery({
 *   variables: {
 *      tagUid: // value for 'tagUid'
 *      limit: // value for 'limit'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePublicationsByTagUidQuery(
  baseOptions?: Apollo.QueryHookOptions<PublicationsByTagUidQuery, PublicationsByTagUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationsByTagUidQuery, PublicationsByTagUidQueryVariables>(
    PublicationsByTagUidDocument,
    options
  )
}
export function usePublicationsByTagUidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationsByTagUidQuery, PublicationsByTagUidQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationsByTagUidQuery, PublicationsByTagUidQueryVariables>(
    PublicationsByTagUidDocument,
    options
  )
}
export type PublicationsByTagUidQueryHookResult = ReturnType<typeof usePublicationsByTagUidQuery>
export type PublicationsByTagUidLazyQueryHookResult = ReturnType<typeof usePublicationsByTagUidLazyQuery>
export type PublicationsByTagUidQueryResult = Apollo.QueryResult<
  PublicationsByTagUidQuery,
  PublicationsByTagUidQueryVariables
>
