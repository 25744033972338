import {Button, InputLabel, OutlinedInput} from '@mui/material'
import {styled} from 'styled-components'
import {screenS} from '@theme/vars'
import {StyledFormControl} from '../../styled'

export const GalleryUploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -12px;
  width: 100%;

  @media screen and (max-width: ${screenS}) {
    margin-left: 0px;
  }
`

export const ColumnContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const UploadUrlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 8px;

  @media screen and (max-width: ${screenS}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

export const StyledFormControlWrapper = styled(StyledFormControl)`
  width: 486px;

  @media screen and (max-width: ${screenS}) {
    width: 100%;
  }
`

export const InputLabelWrapper = styled(InputLabel)`
  width: 100%;
`

export const OutlinedInputWrapper = styled(OutlinedInput)`
  width: 100%;
`

export const UploadButtonWrapper = styled(Button)`
  margin-top: 0;
  margin-left: 8px;
  width: 178px;

  @media screen and (max-width: ${screenS}) {
    margin-top: 12px;
    margin-left: 0;
    width: 100%;
  }
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
`
