import React, {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import CloseIcon from '@components/UI/Icons/CloseIcon'
import SortIcon from '@components/UI/Icons/SortIcon'
import {
  ButtonWrapper,
  TableHeaderCellButtonsWrapper,
  TableHeaderCellWrapper,
  TitleWrapper
} from './TableHeaderCell.styles'
import {TableHeaderCellProps} from './TableHeaderCellProps'
import {Tooltip} from '@mui/material'
import {TABLE_HEADER_CELL_CONSTS} from './TableHeaderCell.consts'
import {useStore} from '@stores/rootStoreContext'
import ActiveSortIcon from '@components/UI/Icons/ActiveSortIcon'
import {SortOrder} from '../../../../types/SortOrder'

const TableHeaderCell: FC<TableHeaderCellProps> = ({fieldName, title, onRemove}) => {
  const {reportsListStore} = useStore()
  const {sortFieldName, sortDirection, setSortFieldName, setSortDirection} = reportsListStore

  const [sortIconVisible, setSortIconVisible] = useState(false)
  const [direction, setDirection] = useState<SortOrder>(sortDirection)
  const [transformStyle, setTransformStyle] = useState(
    sortDirection === SortOrder.Asc ? 'rotate(180deg)' : 'rotate(0deg)'
  )

  const onChangeSort = useCallback(
    (name: string) => {
      setSortFieldName(name)
      setDirection(prev => (prev === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc))
    },
    [setSortFieldName]
  )

  useEffect(() => {
    setSortDirection(direction)
    setTransformStyle(direction === SortOrder.Asc ? 'rotate(180deg)' : 'rotate(0deg)')
  }, [direction, setSortDirection])

  return (
    <TableHeaderCellWrapper
      onMouseEnter={() => {
        setSortIconVisible(true)
      }}
      onMouseLeave={() => {
        setSortIconVisible(false)
      }}
    >
      {sortFieldName && (
        <TableHeaderCellButtonsWrapper>
          <Tooltip
            title={
              sortFieldName === fieldName
                ? TABLE_HEADER_CELL_CONSTS.changeSortOrder
                : TABLE_HEADER_CELL_CONSTS.sortByFieldMsg
            }
          >
            <ButtonWrapper
              onClick={() => {
                onChangeSort(fieldName)
              }}
              style={{
                transform: transformStyle
              }}
            >
              {sortFieldName === fieldName ? <ActiveSortIcon /> : sortIconVisible && <SortIcon />}
            </ButtonWrapper>
          </Tooltip>
          <Tooltip title={TABLE_HEADER_CELL_CONSTS.deleteColumnMsg}>
            <ButtonWrapper
              onClick={() => {
                onRemove(fieldName)
              }}
            >
              <CloseIcon />
            </ButtonWrapper>
          </Tooltip>
        </TableHeaderCellButtonsWrapper>
      )}
      <TitleWrapper>{title}</TitleWrapper>
    </TableHeaderCellWrapper>
  )
}

export default observer(TableHeaderCell)
