import React, {FC, useState} from 'react'
import {Box, useMediaQuery, IconButton, Button, AppBar, Toolbar} from '@mui/material'
import {NavLink} from 'react-router-dom'
import {items, newPublicationItem} from './menu-items'
import MenuButton from './MenuButton/MenuButton'
import {useKeycloak} from '@react-keycloak/web'
import NewPublication from './NewPublication/NewPublication'
import {ApolloQueryResult, useApolloClient} from '@apollo/client'

import styled from 'styled-components'
import CustomDrawer from './CustomDrawer/CustomDrawer'
import {HelpOutline, Menu} from '@mui/icons-material'
import {Logout} from '../Icons/Logout'
import LightingIcon from '../Icons/Lightning'

const Wrapper = styled(Toolbar)`
  margin: 0 auto;
  padding: 0 10px;
  justify-content: space-between;
  max-width: 1272px;
`

export const StyledNavLink = styled(NavLink)`
  color: #707070;

  &.active {
    color: #1964e7;
    & button {
      color: #1964e7;
    }
  }
`

export const StyledButton = styled(Button)`
  color: inherit;
  font-weight: 400;
  text-transform: none;
`

export const WrapperButtons = styled.div`
  width: 124px;
  display: flex;
  justify-content: space-around;
`

const Navbar: FC = () => {
  const [open, setOpen] = useState(false)
  const {keycloak} = useKeycloak()
  const client = useApolloClient()
  const isMobile = useMediaQuery('(max-width:1000px)')

  const handleLogout = async (): Promise<ApolloQueryResult<any>[] | null> => {
    await keycloak.logout({redirectUri: window.location.origin})
    return client.resetStore()
  }

  return (
    <AppBar position='sticky' color='inherit' style={{display: 'block'}}>
      <Wrapper>
        {isMobile ? (
          <Box order={1}>
            <IconButton color='primary' onClick={() => setOpen(true)} edge='start'>
              <Menu />
            </IconButton>

            <CustomDrawer open={open} setOpen={setOpen} handleLogout={handleLogout} />
          </Box>
        ) : (
          <>
            {items.map((item, ind) => {
              if (item.hasOwnProperty('type')) {
                return <MenuButton key={'navbar' + item.title + ind} {...item} />
              }
              return (
                <StyledNavLink exact to={item.route} key={item.title}>
                  <StyledButton disabled={item.disabled}>
                    {item.icon === 'lighting' && (
                      <span
                        style={{
                          display: 'flex'
                        }}
                      >
                        <LightingIcon />
                        <span>&nbsp;</span>
                      </span>
                    )}
                    {item.title}
                  </StyledButton>
                </StyledNavLink>
              )
            })}
            <WrapperButtons>
              <StyledNavLink exact to='/reference' key='reference'>
                <IconButton size='large'>
                  <HelpOutline />
                </IconButton>
              </StyledNavLink>

              <IconButton onClick={handleLogout}>
                <Logout />
              </IconButton>
            </WrapperButtons>
          </>
        )}

        <NewPublication item={newPublicationItem} />
      </Wrapper>
    </AppBar>
  )
}

export default Navbar
