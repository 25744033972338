import React, {FC} from 'react'
import OnlineTranslationFields from '@components/TextEditor/OnlineTranslationFields/OnlineTranslationFields'
import {OnlineTranslationWrapper} from './OnlineTranslation.styles'

const OnlineTranslation: FC = () => {
  return (
    <OnlineTranslationWrapper
      style={{
        margin: 'auto',
        width: 'calc(100% - 20px)'
      }}
    >
      <OnlineTranslationFields />
    </OnlineTranslationWrapper>
  )
}

export default OnlineTranslation
