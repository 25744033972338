import React, {FC, ReactNode} from 'react'
import {Box, DialogProps} from '@mui/material'
import {ALERT_MODE, MODAL_MODE, ModalMode} from '../../../types/ModalMode'
import {DEFAULT_STYLE, ModalStyle} from '../../../types/ModalStyle'
import {StyledDialog} from './SimpleModal.styles'
import {ModalContent} from './ModalContent/ModalContent'
import {ModalActions} from './ModalActions/ModalActions'
import {ModalTitle} from './ModalTitle/ModalTitle'

export interface SimpleModalStyleOptions {
  buttonsMinWidth?: number
  okButtonMinWidth?: number
  yesButtonMinWidth?: number
  noButtonMinWidth?: number
}

export interface SimpleModalProps extends DialogProps {
  /**
   * Текст заголовка
   */
  title?: string
  /**
   * Сообщение с дефолтным стилем
   */
  text?: string
  /**
   * Сообщение с дефолтным стилем
   */
  secondText?: string
  /**
   * Если сервер присылает сообщение в html формате
   */
  html?: string
  /**
   * Стиль модалки
   */
  variant?: ModalStyle
  /**
   * Режим работы, без кнопок, с одной "Ок", с двумя "Yes" и "No"
   */
  mode?: ModalMode
  /**
   * Показывает загрузчик и блокирует кнопки
   */
  loading?: boolean
  /**
   * Кастомный текст на кнопке Ok
   */
  okLabel?: string
  /**
   * Кастомный текст на кнопке Yes
   */
  yesLabel?: string
  /**
   * Кастомный текст на кнопке No
   */
  noLabel?: string
  /**
   * Деактивирует кнопку "Ок"
   */
  okButtonDisabled?: boolean
  /**
   * Деактивирует кнопку "Yes"
   */
  yesButtonDisabled?: boolean
  /**
   * Деактивирует кнопку "No"
   */
  noButtonDisabled?: boolean
  /**
   * Показывать иконку Закрыть (крестик)
   */
  showCloseButton?: boolean
  /**
   * Дополнительные параметры для внешнего оформления
   */
  stylesOptions?: SimpleModalStyleOptions
  /**
   * Клик по кнопке в режиме "alert"
   */
  onOk?: () => void
  /**
   * Клик по кнопке Yes в режиме "dialog"
   */
  onYes?: () => void
  /**
   * Клик по кнопке No в режиме "dialog"
   */
  onNo?: () => void
  /**
   * Добавляет кнопу в header заголовка
   */
  headerEndIcon?: ReactNode
  /**
   * Текст подсказки на кнопке при условии disable
   */
  okButtonDisabledTooltip?: string
}

const SimpleModal: FC<SimpleModalProps> = props => {
  const {id, title, text, html, mode, ...other} = props

  return (
    <StyledDialog
      id={id}
      aria-labelledby={title ? `${id}_title` : undefined}
      aria-describedby={text || html ? `${id}_content` : undefined}
      fullWidth
      mode={mode}
      {...other}
    >
      <ModalTitle {...props} />
      <Box mt={!title ? 8 : 0} />
      <ModalContent {...props} />
      <Box mb={mode === MODAL_MODE ? 8 : 0} />
      <ModalActions {...props} />
    </StyledDialog>
  )
}

SimpleModal.displayName = 'SimpleModal'

SimpleModal.defaultProps = {
  id: 'modal',
  mode: ALERT_MODE,
  variant: DEFAULT_STYLE,
  loading: false,
  okButtonDisabled: false,
  yesButtonDisabled: false,
  noButtonDisabled: false,
  stylesOptions: {},
  onOk: () => null,
  onYes: () => null,
  onNo: () => null
}

export default SimpleModal
