export const BREAKING_NEWS_MODAL_CONSTS = {
    breakinNewsMsg: 'Срочная новость',
    characterLimitReachedMsg: 'Достигнут лимит по кол-ву символов',
    closeConfirmationText:
        'Внесенные изменения не сохранятся. Вы уверены, что хотите закрыть окно?',
    closeConfirmBtn: 'Да, закрыть',
    fillRequiredFields: 'Заполните обязательные поля',
    publishAtNowMsg:
        'Публикация выйдет, когда вы нажмёте на кнопку Опубликовать',
    dateTimeMsg: 'Дата и время',
    hourForms: ['час', 'часа', 'часов'],
    headerMsg: 'Заголовок',
    maxHeaderLength: 110,
    warningMsg: 'Внимание'
}
