import React, {FC, useState, useEffect} from 'react'
import {ButtonForInput} from '../../UI/styled'
import {FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'
import Clear from '@mui/icons-material/Clear'
import {MATERIAL_URL_CONSTS} from './MaterialUrl.consts'
import {redColor} from './../../../@theme/vars'
import {FormControlFlex, MainBox} from './MaterialUrl.styles'

type MaterialUrlProps = {
  setUrl: any
  handleAddMaterial: () => void
  setDataLoaded?: any
  dataLoaded?: boolean
  blockCount: number
}

const MaterialUrl: FC<MaterialUrlProps> = ({setUrl, handleAddMaterial, setDataLoaded, dataLoaded, blockCount}) => {
  const [invalidLink, setInvalidLink] = useState('')
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    const regex = /^http(s)?\:\/\/(.{0,10})ura\.news\/((story)|(articles)|(news))\/\d{1,10}$/g
    const fullMatch = currentUrl.slice().replace(regex, '')
    setInvalidLink(fullMatch.length > 0 ? MATERIAL_URL_CONSTS.wrongLinkMsg : '')
  }, [currentUrl])

  const [disableBtn, setDisableBtn] = useState(blockCount < 8 ? false : true)

  useEffect(() => {
    setDisableBtn(blockCount < 8 ? false : true)
  }, [blockCount])

  return (
    <MainBox>
      <FormControlFlex>
        <InputLabel>{MATERIAL_URL_CONSTS.labelMsg}</InputLabel>
        <OutlinedInput
          label={MATERIAL_URL_CONSTS.labelMsg}
          onChange={e => {
            setCurrentUrl(e.target.value)
            setUrl(e.target.value)
          }}
          value={currentUrl}
          endAdornment={
            <InputAdornment position='end'>
              {currentUrl && (
                <IconButton
                  edge='end'
                  size='small'
                  onClick={() => {
                    setUrl('')
                    setCurrentUrl('')
                  }}
                >
                  <Clear fontSize='small' />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        {invalidLink && (
          <FormHelperText
            style={{
              color: redColor
            }}
          >
            {invalidLink}
          </FormHelperText>
        )}
      </FormControlFlex>
      <ButtonForInput
        variant='outlined'
        color='primary'
        disabled={!currentUrl || invalidLink?.length > 0 || !dataLoaded || disableBtn}
        onClick={() => {
          handleAddMaterial()
          setDataLoaded(false)
          setUrl('')
          setCurrentUrl('')
        }}
      >
        {MATERIAL_URL_CONSTS.addMsg}
      </ButtonForInput>
    </MainBox>
  )
}

export default MaterialUrl
