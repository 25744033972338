import React, {FC} from 'react'

const ChevronIcon: FC<any> = () => {
    return (
        <svg
            width='6'
            height='4'
            viewBox='0 0 6 4'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M0 0L3 4L6 0H0Z' fill='#222222' />
        </svg>
    )
}

export default ChevronIcon
