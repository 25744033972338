import {Button} from '@mui/material'
import styled from 'styled-components'
import {screenS} from '@theme/vars'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'

export const UploadButtonWrapper = styled(Button)`
  margin-top: 0;
  margin-left: 8px;
  width: 178px;

  @media screen and (max-width: ${screenS}) {
    margin-top: 12px;
    margin-left: 0;
    width: 100%;
  }
`

export const ControlTooltipWrapper = styled(ControlTooltip)`
  & button {
    text-transform: none !important;
    height: 42px !important;
    letter-spacing: normal !important;
  }
`
