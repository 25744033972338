import React, {FC, useEffect, useState} from 'react'
import {StyledFormControl} from '../styled'
import {Box, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography} from '@mui/material'
import Clear from '@mui/icons-material/Clear'
import styled, {css} from 'styled-components'
import {redColor} from '../../../@theme/vars'

type InputWithLengthProps = {
  label: string
  title: string
  errorTitle: string
  maxLength: number
  width?: string
  multiline?: boolean
  clearTitle: () => void
  setTitle: (title: string) => void
}

const StyledLength = styled(Typography)<{isMaxLength: boolean}>`
  ${({isMaxLength}) =>
    isMaxLength &&
    css`
      color: ${redColor};
    `}
`

const InputWithLength: FC<InputWithLengthProps> = ({
  label,
  title,
  setTitle,
  errorTitle,
  clearTitle,
  maxLength,
  multiline = false,
  width = '414px'
}) => {
  const [titleLength, setTitleLength] = useState(0)

  useEffect(() => setTitleLength(title?.length || 0), [title])

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
      <StyledFormControl width={width}>
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          inputProps={{maxLength: maxLength}}
          label={label}
          onChange={e => setTitle(e.target.value)}
          value={title}
          type='text'
          error={!!errorTitle}
          multiline={multiline}
          endAdornment={
            <InputAdornment position='end'>
              {title && (
                <IconButton edge='end' size='small' onClick={clearTitle}>
                  <Clear fontSize='small' />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <FormHelperText>{errorTitle}</FormHelperText>
      </StyledFormControl>
      <StyledLength variant='subtitle1' isMaxLength={titleLength === maxLength}>
        {titleLength}/{maxLength}
      </StyledLength>
    </Box>
  )
}

export default InputWithLength
