import React, {FC, useCallback, useEffect, useState} from 'react'
import {IconButton, Tooltip} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {generateGuid} from '@utils/generateGuid'
import {Crop, CropInput, Figure, FigureVersion, GalleryImage} from '@graphql/types'
import {useCreateFigureVersionMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {useStore} from '@stores/rootStoreContext'
import {CropperType} from '../../../../types/CropperType'
import ImageCropper from '../ImageCropper/ImageCropper'

import DeleteIcon from '../../Icons/DeleteIcon'
import {GALLERY_IMAGE_EDITOR_CONSTS} from './GalleryImageEditor.consts'
import {GalleryImageEditorProps} from './GalleryImageEditorProps'
import {DeleteButtonWrapper, GalleryImageWrapper, SimpleModalWrapper} from './GalleryImageEditor.styles'

const GalleryImageEditor: FC<GalleryImageEditorProps> = ({modalId, open, onClose, figureIsLoading}) => {
  const {cropperStore, publicationStore} = useStore()
  const {cropper, cropperIsValid, isCropperChanged, setCropper, setIsCropperChanged} = cropperStore
  const {galleryModalStore, setCheckingPhotoEditorCache} = publicationStore
  const {galleryImageForImageEditor, setGalleryImageById, setIsModalDirty} = galleryModalStore

  const [id, setId] = useState(galleryImageForImageEditor?.id || generateGuid())
  const [uid, setUid] = useState(galleryImageForImageEditor?.uid || generateGuid())
  const [loading, setLoading] = useState(false)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [hasWatermark, setHasWatermark] = useState(!!galleryImageForImageEditor?.figureVersion?.figure?.hasWatermark)
  const [imageUrl, setImageUrl] = useState(galleryImageForImageEditor?.figureVersion?.figure?.src)
  const [figure, setFigure] = useState<Figure>((galleryImageForImageEditor?.figureVersion?.figure || {}) as Figure)
  // const [figureVersionId, setFigureVersionId] = useState(figureForImageEditor?.latestVersion?.id)
  // const [figureVersionUid, setFigureVersionUid] = useState(figureForImageEditor?.latestVersion?.uid)
  const [description, setDescription] = useState(galleryImageForImageEditor?.description)
  const [author, setAuthor] = useState(galleryImageForImageEditor?.author)
  const [createFigureVersion, {loading: createFigureVersionLoading}] = useCreateFigureVersionMutation()

  useEffect(() => {
    console.log('galleryImageForImageEditor', galleryImageForImageEditor)
    setFigure(galleryImageForImageEditor?.figureVersion?.figure as Figure)
    setId(galleryImageForImageEditor?.id || generateGuid())
    setUid(galleryImageForImageEditor?.uid || generateGuid())
    setImageUrl(galleryImageForImageEditor?.figureVersion?.figure?.src || '')
    setAuthor(galleryImageForImageEditor?.author || '')
    setDescription(galleryImageForImageEditor?.description || '')

    if (galleryImageForImageEditor?.figureVersion?.figure?.latestVersion?.crop) {
      setCropper(
        (galleryImageForImageEditor?.figureVersion?.crop ||
          galleryImageForImageEditor?.figureVersion?.figure?.latestVersion?.crop) as CropInput
      )
      setIsCropperChanged(true)
      return
    }
    setIsCropperChanged(false)
  }, [galleryImageForImageEditor, setAuthor, setCropper, setIsCropperChanged])

  useEffect(() => {
    setLoading(figureIsLoading || createFigureVersionLoading)
  }, [figureIsLoading, createFigureVersionLoading])

  const addGalleryImage = useCallback(
    (newValue: GalleryImage): void => {
      setGalleryImageById(newValue)
    },
    [setGalleryImageById]
  )

  const createGalleryImageAction = useCallback(async () => {
    const newFigure = await createFigureVersion({
      variables: {
        data: {
          figureId: figure?.id,
          hasWatermark: hasWatermark,
          hasDiagonalWatermark: false,
          crop: cropper
        }
      }
    })

    if (!newFigure.errors?.length) {
      const galleryImage: GalleryImage = {
        id: id || generateGuid(),
        uid: uid || id || generateGuid(),
        author: author || '',
        description: description || '',
        figureVersion: {
          crop: cropper as Crop,
          id: newFigure.data?.createFigureVersion?.figureVersion?.id || '',
          uid: newFigure.data?.createFigureVersion?.figureVersion?.uid || '',
          primaryProcessedImage: {
            url:
              newFigure.data?.createFigureVersion?.figureVersion?.figure?.latestVersion?.primaryProcessedImage?.url ||
              ''
          },
          // figure: {
          //   id: newFigure.data?.createFigureVersion?.figureVersion?.figure?.id || '',
          //   uid: newFigure.data?.createFigureVersion?.figureVersion?.figure?.uid || '',
          //   sourceImage: {
          //     url: newFigure.data?.createFigureVersion?.figureVersion?.figure?.sourceImage.url
          //   },
          //   cropperPreview: {
          //     url: newFigure.data?.createFigureVersion?.figureVersion?.figure?.cropperPreview?.url || ''
          //   },
          //   latestVersion: newFigure.data?.createFigureVersion?.figureVersion
          // }
          figure: (newFigure.data?.createFigureVersion?.figureVersion?.figure || figure) as Figure
          // latestVersion:
          //   newFigure.data?.createFigureVersion?.figureVersion?.latestVersion || figure?.latestVersion || {}
        } as FigureVersion,
        gallery: modalId,
        __typename: 'GalleryImage'
      }

      addGalleryImage(galleryImage)
      setIsModalDirty(true)
      setCheckingPhotoEditorCache(false)

      onClose()
      setId('')
      setUid('')
    }
  }, [
    createFigureVersion,
    hasWatermark,
    cropper,
    author,
    description,
    figure?.id,
    modalId,
    // setFigureVersionId,
    // setFigureVersionUid,
    addGalleryImage,
    onClose,
    setCheckingPhotoEditorCache,
    setIsModalDirty,
    id,
    uid
  ])

  const closeModal = useCallback(() => {
    // setAltDirty(false)
    // setAuthorDirty(false)
    // setDescriptionDirty(false)
    setIsOpenConfirmDialog(false)
    onClose()
  }, [onClose])

  const clearFigure = useCallback(() => {
    setFigure({} as Figure)
    setImageUrl('')
  }, [])

  useEffect(() => {
    if (figure?.author) {
      setAuthor(figure.author)
    }
    if (figure?.description) {
      setDescription(figure.description)
    }
  }, [figure, setAuthor, setDescription])

  return (
    <SimpleModalWrapper
      open={open}
      mode='alert'
      onClose={closeModal}
      title={GALLERY_IMAGE_EDITOR_CONSTS.title}
      text={''}
      onOk={createGalleryImageAction}
      onNo={closeModal}
      okButtonDisabled={loading || !figure?.id}
      okButtonDisabledTooltip={''}
      noButtonDisabled={false}
      okLabel={GALLERY_IMAGE_EDITOR_CONSTS.pastePhoto}
      noLabel={GALLERY_IMAGE_EDITOR_CONSTS.cancel}
      loading={loading}
    >
      <GalleryImageWrapper>
        <ImageCropper
          figure={figure}
          imageUrl={imageUrl}
          description={''}
          hasWatermark={hasWatermark}
          hasDiagonalWatermark={false}
          setImageUrl={setImageUrl}
          setHasWatermark={setHasWatermark}
          setFigure={setFigure}
          ratio={25 / 17}
          figureTypeSlug={'publication'}
          cropperType={CropperType.isGallery}
          isCustomRatio={true}
          figureIsLoading={figureIsLoading}
        />
        {figure?.src && (
          <DeleteButtonWrapper>
            <Tooltip title={GALLERY_IMAGE_EDITOR_CONSTS.setAnotherPhotoLabelMsg}>
              <IconButton
                edge='end'
                size='small'
                id={`gallery-image-editor-modal-delete-photo-${id}`}
                onClick={() => clearFigure()}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </DeleteButtonWrapper>
        )}
      </GalleryImageWrapper>
    </SimpleModalWrapper>
  )
}

export default observer(GalleryImageEditor)
