import styled from 'styled-components'
import {Button as UIButton, FormControlLabel as UIFormControlLabel} from '@mui/material'
import {StyledFormControl as UIFormControl} from '@components/UI/styled'

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
export const Button = styled(UIButton)`
  width: 130px;
`

export const StyledFormControl = styled(UIFormControl)`
  width: 100%;
`

export const FormControlLabel = styled(UIFormControlLabel)`
  width: 100%;
`
