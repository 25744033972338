import React, {FC, useCallback, useEffect, useState} from 'react'

import {observer} from 'mobx-react-lite'
import {AccordionDetails} from '@mui/material'
import {ArrowDropDownIcon} from '@mui/x-date-pickers'
import {generateGuid} from '@utils/generateGuid'
import {lightGrayColor2} from '@theme/vars'
import {useStore} from '@stores/rootStoreContext'
import {ConfirmationModal} from '@components/UI/ConfirmationModal/ConfirmationModal'

import {GalleryImage} from '@graphql/types'
import GalleryUploader from '../GalleryUploader/GalleryUploader'
import {GALLERY_EDITOR_CONSTS} from './GalleryEditor.consts'
import {GalleryEditorProps} from './GalleryEditorProps'
import GalleryEditorGridDrop from './GalleryEditorGridDrop/GalleryEditorGridDrop'
import {
  AccordionSummaryTitleWrapper,
  AccordionSummaryWrapper,
  AccordionWrapper,
  ArrowDropDownIconWrapper,
  DividerWrapper,
  GalleryEditorGridDropWrapper,
  SimpleModalWrapper
} from './GalleryEditor.styles'

/**
 * Компонент для редактирования содержимого галереи
 * @param param0
 * @returns
 */
const GalleryEditor: FC<GalleryEditorProps> = ({modalId, open, setGallery, onClose}) => {
  const [galleryId, setGalleryId] = useState(modalId || generateGuid())
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [isDirty, setIsDirty] = useState(false)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [isSwapItems, setIsSwapItems] = useState(false)

  const {publicationStore} = useStore()
  const {galleryModalStore} = publicationStore
  const {galleryDetail, galleryErrors, galleryModalLoading, isModalDirty, setShowUploadFields, setIsModalDirty} =
    galleryModalStore
  const [galleryBlocks, setGalleryBlocks] = useState<GalleryImage[]>(
    galleryDetail.galleryImages || GALLERY_EDITOR_CONSTS.galleryBlocks || []
  )
  const [expanded, setExpanded] = useState(galleryDetail.galleryImages?.length > 0 ? 'gallery-accordion' : '')

  useEffect(() => {
    setIsDirty(isModalDirty)
  }, [isModalDirty])

  useEffect(() => {
    if (galleryErrors.size > 0) {
      setIsValid(false)
      return
    }
    setIsValid(true)
  }, [galleryErrors, galleryErrors.size])

  useEffect(() => {
    setLoading(galleryModalLoading)
  }, [galleryModalLoading])

  useEffect(() => {
    setGalleryId(modalId || generateGuid())
  }, [modalId])

  useEffect(() => {
    setGalleryBlocks(galleryDetail.galleryImages || [])
  }, [galleryDetail.galleryImages, galleryDetail.galleryImages.length])

  const createGalleryAction = useCallback(() => {
    const curGalleryId = galleryId || generateGuid()
    onClose()
    setGalleryId('')
    setGallery({
      id: curGalleryId,
      uid: curGalleryId,
      galleryImages: galleryBlocks
    })
    setIsModalDirty(false)
  }, [onClose, setGalleryId, galleryId, galleryBlocks, setGallery, setIsModalDirty])

  const closeModal = useCallback(() => {
    setIsOpenConfirmDialog(false)
    setIsModalDirty(false)
    onClose()
  }, [onClose, setIsModalDirty])

  const toggleIsOpenConfirmDialog = useCallback(() => {
    !isDirty ? closeModal() : setIsOpenConfirmDialog(!isOpenConfirmDialog)
  }, [isOpenConfirmDialog, isDirty, closeModal])

  const [okButtonDisabled, setOkButtonDisabled] = useState(true)
  const [okButtonDisabledTooltip, setOkButtonDisabledTooltip] = useState('')

  useEffect(() => {
    setOkButtonDisabled(!isValid || !isDirty || loading || galleryDetail.galleryImages.length < 2)

    if (galleryDetail.galleryImages.length < 2) {
      setOkButtonDisabledTooltip(GALLERY_EDITOR_CONSTS.needToAddCoupleOfPhoto)
      return
    }

    setOkButtonDisabledTooltip(isDirty ? GALLERY_EDITOR_CONSTS.fillRequiredFields : GALLERY_EDITOR_CONSTS.makeChangeMsg)
  }, [isValid, loading, galleryDetail.galleryImages.length, isDirty])

  const handleChange = (panel: string) => (event: any, newExpanded: boolean) => {
    const newVal = newExpanded ? panel : ''
    setExpanded(newVal)
    setShowUploadFields(!!newVal)
  }

  useEffect(() => {
    const newVal = modalId ? '' : 'gallery-accordion'
    setExpanded(modalId ? '' : 'gallery-accordion')
    setShowUploadFields(!!newVal)
  }, [modalId, setShowUploadFields])

  return (
    <SimpleModalWrapper
      open={open}
      mode='alert'
      onClose={toggleIsOpenConfirmDialog}
      title={GALLERY_EDITOR_CONSTS.title}
      text={''}
      onOk={createGalleryAction}
      onNo={toggleIsOpenConfirmDialog}
      okButtonDisabled={okButtonDisabled}
      okButtonDisabledTooltip={okButtonDisabledTooltip}
      noButtonDisabled={false}
      okLabel={GALLERY_EDITOR_CONSTS.pasteGallery}
      noLabel={GALLERY_EDITOR_CONSTS.cancel}
      loading={loading}
    >
      {galleryBlocks?.length > 0 && (
        <GalleryEditorGridDropWrapper>
          <GalleryEditorGridDrop galleryBlocks={galleryBlocks} onSwapItems={setIsSwapItems} />

          <DividerWrapper
            flexItem
            sx={{
              backgroundColor: lightGrayColor2
            }}
          />
        </GalleryEditorGridDropWrapper>
      )}
      <AccordionWrapper square expanded={expanded === 'gallery-accordion'} onChange={handleChange('gallery-accordion')}>
        <AccordionSummaryWrapper aria-controls='gallery-accordion-content' id='gallery-accordion-header'>
          <AccordionSummaryTitleWrapper>
            {expanded ? GALLERY_EDITOR_CONSTS.hideUploadPanel : GALLERY_EDITOR_CONSTS.showUploadPanel}
          </AccordionSummaryTitleWrapper>
          <ArrowDropDownIconWrapper>
            <ArrowDropDownIcon
              fontSize='medium'
              style={{
                transform: `${expanded ? 'rotate(180deg)' : 'rotate(0deg)'}`
              }}
            />
          </ArrowDropDownIconWrapper>
        </AccordionSummaryWrapper>
        <AccordionDetails
          style={{
            padding: '0'
          }}
        >
          <GalleryUploader />
        </AccordionDetails>
      </AccordionWrapper>
      <ConfirmationModal
        id={'close-gallery'}
        confirmText={GALLERY_EDITOR_CONSTS.closeConfirmationText}
        isOpenConfirmDialog={isOpenConfirmDialog}
        closeConfirmModal={closeModal}
        okLabel={GALLERY_EDITOR_CONSTS.closeConfirmBtn}
        toggleIsOpenConfirmDialog={toggleIsOpenConfirmDialog}
        firstSentenceText={GALLERY_EDITOR_CONSTS.firstSentenceText}
      />
    </SimpleModalWrapper>
  )
}

export default observer(GalleryEditor)
