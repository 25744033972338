import {makeAutoObservable} from 'mobx'
import {InoagentResponse} from '@fetch/NnApi/NnAPI.types'
import IAgentCheckStore from './IAgentCheckStore'
class AgentCheckStore implements IAgentCheckStore {
  isOpen = false
  isError = false
  isSameNames = false
  isChecking = false
  isPending = false
  isDisabled = false
  isSave = false
  isDisabledModalButton = false
  actionType = ''
  listInoagent = {} as InoagentResponse
  inoagentMark: boolean | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  setParamsDefault = (): void => {
    this.isOpen = false
    this.isError = false
    this.isSameNames = false
    this.isChecking = false
    this.isPending = false
    this.isDisabled = false
    this.isSave = false
    this.actionType = ''
    this.listInoagent = {} as InoagentResponse
    this.inoagentMark = undefined
  }

  setSameNames = (isSameNames: boolean): void => {
    this.isSameNames = isSameNames
  }
  setSave = (save: boolean): void => {
    this.isSave = save
  }
  startChecking = (): void => {
    this.isChecking = true
    this.isDisabled = true
  }

  stopChecking = (): void => {
    this.isSave = false
    this.isChecking = false
    this.isDisabled = false
    this.isPending = false
  }
  findedSameNames = (response: InoagentResponse): void => {
    this.isChecking = false
    this.listInoagent = response
    this.isSameNames = true
  }
  findedInoagent = (response: InoagentResponse, actionType: string): void => {
    this.isChecking = false
    this.listInoagent = response
    this.isOpen = true
    this.actionType = actionType
  }
  notFoundInoagent = (response: InoagentResponse, actionType: string): void => {
    this.isChecking = false
    this.actionType = actionType
    this.inoagentMark = response['INOAGENT MARKED']
  }
  setError = (actionType: string): void => {
    this.isError = true
    this.actionType = actionType
    this.isChecking = false
    this.listInoagent = {} as InoagentResponse
  }
  // Пользователь отменил действие
  onCancel = (): void => {
    this.isOpen = false
    this.isChecking = false
    this.isPending = false
    this.isDisabled = false
    this.isError = false
    this.actionType = ''
  }
  onConfirm = (): void => {
    this.isError = false
    this.isOpen = false
    this.isPending = true
    this.isDisabled = true
  }
  setDisabledModalButton = (disabled: boolean): void => {
    this.isDisabledModalButton = disabled
  }
}

export default AgentCheckStore
