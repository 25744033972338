import styled from 'styled-components'
import {Button, FormControlLabel} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers'
import {errorColor, mainTextColor} from '../../../@theme/vars'
import AuthorPicker from '@components/UI/AuthorPicker/AuthorPicker'
import RegionPicker from '@components/UI/RegionPicker/RegionPicker'
import {StyledFormControl} from '@components/UI/styled'

const screenLargeSize = '1135px'
const screenMiddleSize = '979px'
const screenSize = '650px'

export const FilterFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const MainRowContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: ${screenMiddleSize}) {
    flex-direction: column;
  }
`

export const FiltersRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  @media (max-width: ${screenSize}) {
    flex-direction: column;
    width: 100%;
  }
`

export const FiltersColumnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${screenSize}) {
    width: 100%;
  }
`

export const SecondColumnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
  flex-direction: column;

  @media (max-width: ${screenMiddleSize}) {
    margin-left: 0px;
    width: 565px;
    margin-bottom: 12px;
  }

  @media (max-width: ${screenSize}) {
    margin-left: 0px;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${screenLargeSize}) {
    flex-direction: column;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${screenLargeSize}) {
    flex-direction: row;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
`

export const ButtonWrapper = styled(Button)`
  width: 200px;
  height: 42px;
  margin-bottom: 12px;

  @media (max-width: 450px) {
    width: calc(100% - 20px);
  }
`

export const ExportToFileButtonWrapper = styled.div`
  width: 200px;
  margin-bottom: 12px;
  margin-left: 0px;

  @media (max-width: ${screenLargeSize}) {
    margin-left: 16px;
  }

  @media (max-width: 450px) {
    margin-left: 0px;
    width: calc(100% - 20px);
  }
`

export const FormControlLabelWrapper = styled(FormControlLabel)`
  margin-left: 40px;
  color: ${mainTextColor};

  @media (max-width: ${screenSize}) {
    margin-left: -12px;
  }
`

export const KeyboardDatePickerWrapper1 = styled(DatePicker)`
  display: flex;
  width: 274px;
  & input {
    padding: 0;
  }

  @media (max-width: ${screenSize}) {
    margin-bottom: 12px;
    width: calc(100% - 20px);
  }
`

export const KeyboardDatePickerWrapper2 = styled(DatePicker)`
  display: flex;
  width: 274px;
  margin-left: 16px;
  & input {
    padding: 0;
  }

  @media (max-width: ${screenSize}) {
    margin-left: 0px;
    margin-bottom: 12px;
    width: calc(100% - 20px);
  }
`

export const BoxWithInfo = styled.div`
  display: flex;
  align-items: center;
`

export const AuthorPickerWrapper = styled(AuthorPicker)`
  width: 496px;
  margin-bottom: 12px;

  @media (max-width: ${screenMiddleSize}) {
    display: flex;
    width: 565px;
  }

  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
  }
`

export const RegionPickerWrapper = styled(RegionPicker)`
  width: 496px;

  @media (max-width: ${screenMiddleSize}) {
    display: flex;
    width: 565px;
  }
  @media (max-width: ${screenSize}) {
    display: flex;
    width: calc(100% - 20px);
    margin-bottom: 12px;
  }
`

export const RubricAndPublicationUrlContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  @media (max-width: ${screenMiddleSize}) {
    flex-direction: column;
  }
  @media (max-width: ${screenSize}) {
    width: calc(100% - 20px);
  }
`

export const StyledFormControlWrapper = styled(StyledFormControl)`
  width: 315px;
  height: 42px;
  margin-left: 16px;
  display: flex;

  @media (max-width: ${screenMiddleSize}) {
    width: 565px;
    margin-left: 0px;
    /* margin-bottom: 12px; */
  }

  @media (max-width: ${screenSize}) {
    width: 100%;
    margin-left: 0px;
    /* margin-bottom: 12px; */
  }
`

export const ErrorContainer = styled.div`
  color: ${errorColor};
`
