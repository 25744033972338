import React, {FC} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import {tabNamesType} from '../../models/models'
import DictionariesTabList from '@components/Dictionaries/DictionariesTabList'
import {Typography} from '@mui/material'

const tabNames: tabNamesType = [
  {name: 'words-bad', title: 'плохие слова'},
  {name: 'words-author', title: 'запрещенные ники'},
  {name: 'words-warning', title: 'подозрительные слова'},
  {name: 'words-good', title: 'белый список'}
]

const DictionariesPage: FC = () => {
  const dictionaries = 'Словари'
  return (
    <PageContainer>
      <Typography variant='h4'>{dictionaries}</Typography>
      <DictionariesTabList tabNames={tabNames} />
    </PageContainer>
  )
}

export default DictionariesPage
