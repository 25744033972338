import {makeAutoObservable, runInAction} from 'mobx'
import {UiPublication} from 'entities/types/UiPublication'
import IPublicationStore from './IPublicationStore'
import ImageModalStore from './modals/imageModalStore/imageModalStore'
import AnnounceImageModalStore from './modals/announceImageModalStore/announceImageModalStore'
import OverviewBlockImageModalStore from './modals/overviewBlockImageModalStore/overviewBlockImageModalStore'
import RubricAndSubrubricStore from './rubricAndSubrubricStore/rubricAndSubrubricStore'
import GalleryModalStore from './modals/galleryModalStore/galleryModalStore'
import {defaultPublication} from './publicationStore.defaultValues'
class PublicationStore implements IPublicationStore {
  announceImageModalStore = new AnnounceImageModalStore(this)
  galleryModalStore = new GalleryModalStore(this)
  imageModalStore = new ImageModalStore(this)
  overviewBlockImageModalStore = new OverviewBlockImageModalStore(this)
  rubricAndSubrubricStore = new RubricAndSubrubricStore(this)

  publication: UiPublication = defaultPublication

  private _submitHandler: (() => Promise<void>) | null = null
  publicationLoading = false
  publicationContentLoading = false
  destinationsDirty = false
  checkYandexDzenTitleValidation = false
  checkingPhotoEditor = false
  checkingPhotoEditorCache = false
  hasTitlesError = false
  publicationFormDirty = false

  constructor() {
    makeAutoObservable(this)
  }

  get submitHandler() {
    return this._submitHandler
  }

  registerSubmitHandler(handler: () => Promise<void>) {
    runInAction(() => {
      this._submitHandler = handler
    })
  }

  unregisterSubmitHandler() {
    runInAction(() => {
      this._submitHandler = null
    })
  }

  setCheckingPhotoEditor = (value: boolean): void => {
    this.checkingPhotoEditor = value
  }

  setCheckingPhotoEditorCache = (value: boolean): void => {
    this.checkingPhotoEditorCache = value
  }

  setPublication = (value: UiPublication): void => {
    this.publication = value
  }

  setPublicationWithPrevState = (cb: (prev: UiPublication) => UiPublication): void => {
    this.publication = cb(this.publication)
  }

  setDefaultPublication = (): void => {
    this.publication = defaultPublication
  }

  setPublicationLoading = (value: boolean): void => {
    this.publicationLoading = value
  }

  setPublicationContentLoading = (value: boolean): void => {
    this.publicationContentLoading = value
  }

  setDestinationsDirty = (value: boolean): void => {
    this.destinationsDirty = value
  }

  setCheckYandexDzenTitleValidation = (value: boolean): void => {
    this.checkYandexDzenTitleValidation = value
  }

  setTitlesError = (value: boolean): void => {
    this.hasTitlesError = value
  }
  setPublicationFormDirty = (value: boolean): void => {
    this.publicationFormDirty = value
  }
}

export default PublicationStore
