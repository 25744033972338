import React, {FC} from 'react'

const DeleteIcon: FC<any> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='11.5' fill='white' stroke='#C4C4C4' />
      <path
        d='M16.8872 8.09414L15.9024 7.10938L11.9983 11.0135L8.09414 7.10938L7.10938 8.09414L11.0135 11.9983L7.10938 15.9024L8.09414 16.8872L11.9983 12.983L15.9024 16.8872L16.8872 15.9024L12.983 11.9983L16.8872 8.09414Z'
        fill='#EB3846'
      />
    </svg>
  )
}

export default DeleteIcon
