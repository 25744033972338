import React, {FC} from 'react'
import {isEmpty} from 'lodash'
import {DoNotDistrubIcon} from '../Icons/DoNotDistrub'
import {ListInoAgentProps} from './ListInoagentProp'
import {LeftBlockWrapper, StyledUl, Title, WrapperContent} from './ListInoagent.styles'
import {TITLE_EXTREMIST, TITLE_INOAGENT, TITLE_TEXT} from './ListInoagent.consts'

/**
 * Компонент ListInoagent
 * Предназначен для отображения списка иноагентов и экстремистов, найденных в тексте.
 * Принимает объект со списками иноагентов и экстремистов и отображает их в виде заголовков и элементов списка.
 * @param {Object} listInoagent - Объект со списками иноагентов и экстремистов
 * @returns {JSX.Element} Возвращает JSX элемент со списком иноагентов и экстремистов
 */
const ListInoagent: FC<ListInoAgentProps> = ({listInoagent}) => {
  return (
    <WrapperContent>
      <LeftBlockWrapper>
        <DoNotDistrubIcon fontSize='medium' />
      </LeftBlockWrapper>
      <div>
        <Title>
          {TITLE_TEXT} {listInoagent.INOAGENT.length !== 0 ? TITLE_INOAGENT : TITLE_EXTREMIST}
        </Title>
        <StyledUl>
          {listInoagent.INOAGENT.length !== 0 && (
            <>
              {listInoagent.INOAGENT.map((inoagent, index) => (
                <li key={inoagent + index}>{inoagent}</li>
              ))}
            </>
          )}
          {listInoagent.EXTREMIST.length !== 0 && (
            <>
              {listInoagent.INOAGENT.length !== 0 && <Title>{TITLE_EXTREMIST}</Title>}
              {listInoagent.EXTREMIST.map((extremist, index) => (
                <li key={extremist + index}>{extremist}</li>
              ))}
            </>
          )}
        </StyledUl>
      </div>
    </WrapperContent>
  )
}

export default ListInoagent
