import styled, {css} from 'styled-components'
import {GridDropZone} from '../../../libs/react-dnd/grid'

const SizedDropZone = styled(GridDropZone)<{
  $heightZone?: number
}>`
  ${({$heightZone}) =>
    $heightZone &&
    css`
      height: ${$heightZone}px;
      max-height: ${$heightZone}px;
    `}
`

export default SizedDropZone
