import React, {FC} from 'react'
import {Box, Typography} from '@mui/material'
import {NothinFoundedWrapper} from './NothingFounded.styles'
import {NothingFoundedProps} from './NothingFoundedProps'

export const NothingFounded: FC<NothingFoundedProps> = ({title, texts}) => {
  return (
    <NothinFoundedWrapper>
      {title && (
        <Box marginTop={'24px'}>
          <Typography variant='text18M'>{title}</Typography>
        </Box>
      )}
      {texts.map((text, ind) => (
        <Box marginTop={'16px'} key={ind}>
          <Typography variant='text20R'>{text}</Typography>
        </Box>
      ))}
    </NothinFoundedWrapper>
  )
}
