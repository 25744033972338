export const BREAKING_NEWS_MODAL_FIELDS_CONSTS = {
    fillRequiredFields: 'Заполните обязательные поля',
    publishAtNowMsg: 'Текущее время определится автоматически',
    dateTimeMsg: 'Дата и время',
    hourForms: ['час', 'часа', 'часов'],
    keyWords: 'Срочная новость',
    fullDate: 'MM/dd/yyyy HH:mm',
    textMsg: 'Текст',
    delayedPubl: `Отложенная публикация`,
    maxPublicationLength: 2500,
    mayBeThisArticleMsg: `Новость не должна быть больше 2500. Может это статья?`,
    characterLimitReachedMsg: 'Достигнут лимит по кол-ву символов'
}
