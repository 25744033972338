import React, {FC, MouseEvent, useCallback, useState} from 'react'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import {MenuItem} from '@mui/material'
import ChevronIcon from '@components/UI/Icons/Chevron'
import {StyledToggleButton} from '../../Toolbar.styles'
import {TOOLBAR_IMAGE_BUTTON_CONSTS} from './ToolbarImageButton.consts'
import {StyledMenu} from './ToolbarImageButton.styles'

export type ToolbarImageButtonProps = {
  addNewImage: () => void
  addNewGallery: () => void
}

export const ToolbarImageButton: FC<ToolbarImageButtonProps> = ({addNewImage, addNewGallery}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorElement)

  const handleClose = useCallback(() => {
    setAnchorElement(null)
  }, [])

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  return (
    <>
      <StyledToggleButton
        id='paste-image-btn'
        value='InsertPhotoIcon'
        aria-label='left aligned'
        aria-controls={open ? 'paste-image-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <InsertPhotoIcon />
        <span>&nbsp;</span>
        <ChevronIcon></ChevronIcon>
      </StyledToggleButton>
      <StyledMenu
        id='paste-image-menu'
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'paste-image-btn'
        }}
      >
        <MenuItem
          onClick={() => {
            addNewImage()
            handleClose()
          }}
        >
          {TOOLBAR_IMAGE_BUTTON_CONSTS.pastePhotoMsg}
        </MenuItem>
        <MenuItem
          onClick={() => {
            addNewGallery()
            handleClose()
          }}
        >
          {TOOLBAR_IMAGE_BUTTON_CONSTS.pasteGalleryMsg}
        </MenuItem>
      </StyledMenu>
    </>
  )
}
