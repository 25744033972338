import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type CollectionListQueryVariables = Types.Exact<{[key: string]: never}>

export type CollectionListQuery = {__typename?: 'Query'} & {
  collections?: Types.Maybe<
    {__typename?: 'CollectionsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'CollectionsEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'Collection'} & Pick<Types.Collection, 'uid' | 'title' | 'publicationsCount'>
            >
          }
        >
      >
    }
  >
}

export type CollectionQueryVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type CollectionQuery = {__typename?: 'Query'} & {
  collection?: Types.Maybe<
    {__typename?: 'Collection'} & Pick<Types.Collection, 'uid' | 'title' | 'description'> & {
        publications?: Types.Maybe<
          {__typename?: 'PublicationsConnection'} & {
            edges: Array<Types.Maybe<{__typename?: 'PublicationsEdge'} & PublicationEdgeCollectionFragment>>
            pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
          }
        >
      }
  >
}

export type CreateCollectionMutationVariables = Types.Exact<{
  title: Types.Scalars['String']
  description: Types.Scalars['String']
}>

export type CreateCollectionMutation = {__typename?: 'Mutation'} & {
  createCollection?: Types.Maybe<
    {__typename?: 'CreateCollectionPayload'} & {
      collection?: Types.Maybe<{__typename?: 'Collection'} & CollectionItemFragment>
    }
  >
}

export type UpdateCollectionMutationVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
  title?: Types.Maybe<Types.Scalars['String']>
  description?: Types.Maybe<Types.Scalars['String']>
}>

export type UpdateCollectionMutation = {__typename?: 'Mutation'} & {
  updateCollection?: Types.Maybe<
    {__typename?: 'UpdateCollectionPayload'} & {
      collection?: Types.Maybe<{__typename?: 'Collection'} & CollectionItemFragment>
    }
  >
}

export type DeactivateCollectionMutationVariables = Types.Exact<{
  uid: Types.Scalars['UUID']
}>

export type DeactivateCollectionMutation = {__typename?: 'Mutation'} & {
  deactivateCollection?: Types.Maybe<
    {__typename?: 'DeactivateCollectionPayload'} & {
      collection?: Types.Maybe<{__typename?: 'Collection'} & CollectionItemFragment>
    }
  >
}

export type PublicationEdgeCollectionFragment = {__typename?: 'PublicationsEdge'} & {
  node?: Types.Maybe<
    {__typename?: 'Publication'} & Pick<Types.Publication, 'uid' | 'title' | 'publishedAt'> & {
        regions?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>>
        authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
      }
  >
}

export type CollectionItemFragment = {__typename?: 'Collection'} & Pick<
  Types.Collection,
  'uid' | 'title' | 'description'
> & {
    publications?: Types.Maybe<
      {__typename?: 'PublicationsConnection'} & {
        edges: Array<Types.Maybe<{__typename?: 'PublicationsEdge'} & PublicationEdgeCollectionFragment>>
      }
    >
  }

export const PublicationEdgeCollectionFragmentDoc = gql`
  fragment PublicationEdgeCollection on PublicationsEdge {
    node {
      uid
      title
      publishedAt
      regions {
        uid
        title
      }
      authors {
        uid
        firstName
        lastName
      }
    }
  }
`
export const CollectionItemFragmentDoc = gql`
  fragment CollectionItem on Collection {
    uid
    title
    description
    publications {
      edges {
        ...PublicationEdgeCollection
      }
    }
  }
  ${PublicationEdgeCollectionFragmentDoc}
`
export const CollectionListDocument = gql`
  query collectionList {
    collections(limit: 50) {
      edges {
        node {
          uid
          title
          publicationsCount
        }
      }
    }
  }
`

/**
 * __useCollectionListQuery__
 *
 * To run a query within a React component, call `useCollectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollectionListQuery(
  baseOptions?: Apollo.QueryHookOptions<CollectionListQuery, CollectionListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<CollectionListQuery, CollectionListQueryVariables>(CollectionListDocument, options)
}
export function useCollectionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectionListQuery, CollectionListQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<CollectionListQuery, CollectionListQueryVariables>(CollectionListDocument, options)
}
export type CollectionListQueryHookResult = ReturnType<typeof useCollectionListQuery>
export type CollectionListLazyQueryHookResult = ReturnType<typeof useCollectionListLazyQuery>
export type CollectionListQueryResult = Apollo.QueryResult<CollectionListQuery, CollectionListQueryVariables>
export const CollectionDocument = gql`
  query collection($uid: UUID!) {
    collection(uid: $uid) {
      uid
      title
      description
      publications {
        edges {
          ...PublicationEdgeCollection
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PublicationEdgeCollectionFragmentDoc}
`

/**
 * __useCollectionQuery__
 *
 * To run a query within a React component, call `useCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCollectionQuery(baseOptions: Apollo.QueryHookOptions<CollectionQuery, CollectionQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, options)
}
export function useCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CollectionQuery, CollectionQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, options)
}
export type CollectionQueryHookResult = ReturnType<typeof useCollectionQuery>
export type CollectionLazyQueryHookResult = ReturnType<typeof useCollectionLazyQuery>
export type CollectionQueryResult = Apollo.QueryResult<CollectionQuery, CollectionQueryVariables>
export const CreateCollectionDocument = gql`
  mutation createCollection($title: String!, $description: String!) {
    createCollection(data: {title: $title, description: $description}) {
      collection {
        ...CollectionItem
      }
    }
  }
  ${CollectionItemFragmentDoc}
`
export type CreateCollectionMutationFn = Apollo.MutationFunction<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCollectionMutation, CreateCollectionMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(
    CreateCollectionDocument,
    options
  )
}
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<
  CreateCollectionMutation,
  CreateCollectionMutationVariables
>
export const UpdateCollectionDocument = gql`
  mutation updateCollection($uid: UUID!, $title: String, $description: String) {
    updateCollection(data: {collectionUid: $uid, title: $title, description: $description}) {
      collection {
        ...CollectionItem
      }
    }
  }
  ${CollectionItemFragmentDoc}
`
export type UpdateCollectionMutationFn = Apollo.MutationFunction<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(
    UpdateCollectionDocument,
    options
  )
}
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCollectionMutation,
  UpdateCollectionMutationVariables
>
export const DeactivateCollectionDocument = gql`
  mutation deactivateCollection($uid: UUID!) {
    deactivateCollection(data: {collectionUid: $uid}) {
      collection {
        ...CollectionItem
      }
    }
  }
  ${CollectionItemFragmentDoc}
`
export type DeactivateCollectionMutationFn = Apollo.MutationFunction<
  DeactivateCollectionMutation,
  DeactivateCollectionMutationVariables
>

/**
 * __useDeactivateCollectionMutation__
 *
 * To run a mutation, you first call `useDeactivateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCollectionMutation, { data, loading, error }] = useDeactivateCollectionMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeactivateCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateCollectionMutation, DeactivateCollectionMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<DeactivateCollectionMutation, DeactivateCollectionMutationVariables>(
    DeactivateCollectionDocument,
    options
  )
}
export type DeactivateCollectionMutationHookResult = ReturnType<typeof useDeactivateCollectionMutation>
export type DeactivateCollectionMutationResult = Apollo.MutationResult<DeactivateCollectionMutation>
export type DeactivateCollectionMutationOptions = Apollo.BaseMutationOptions<
  DeactivateCollectionMutation,
  DeactivateCollectionMutationVariables
>
