import {Box} from '@mui/material'
import React, {FC, useCallback, useEffect, useState} from 'react'

import {useFormContext, useWatch} from 'react-hook-form'
import ImagePreview from '@components/UI/ImageEditor/ImagePreview/ImagePreview'
import ImageEditorModal from '@components/UI/ImageEditor/ImageEditorModal/ImageEditorModal'
import RHFInput from '@components/Collection/Form/RHFInput/RHFInput'
import {ImageBlotProps} from '@components/TextEditor/Blots/ImageBlot/ImageBlotProps'
import {Figure} from '@graphql/types'
import {EDITOR_CONSTS} from './Editor.consts'
import {EditorWrapper, TitleWrapper} from './Editor.styles'

type EditorProps = {
  figureTypeSlug: string
}

const Editor: FC<EditorProps> = ({figureTypeSlug}) => {
  const [isOpenDialogImage, setIsOpenDialogImage] = useState(false)
  const {control, setValue} = useFormContext()
  const fields = useWatch({control})
  const toggleIsOpenDialogImage = useCallback(() => {
    setIsOpenDialogImage(!isOpenDialogImage)
  }, [isOpenDialogImage])

  const setImage = useCallback(
    (value: ImageBlotProps) => {
      const newFigure = {
        id: value.figureId,
        uid: value.figureUid,
        src: value.src,
        alt: value.alt,
        author: value.author,
        description: value.description,
        hasWatermark: value.hasWatermark,
        hasDiagonalWatermark: value.hasDiagonalWatermark,
        latestVersion: {
          primaryProcessedImage: {
            url: value.cropperPreviewUrl
          }
        }
      } as Figure
      setValue('figure', newFigure)
    },
    [setValue]
  )

  return (
    <>
      <EditorWrapper>
        <TitleWrapper>
          <Box width={82} height={82} borderRadius={4} mr={3}>
            <ImagePreview
              url={fields.figure?.latestVersion?.primaryProcessedImage?.url || ''}
              handleOpenDialog={toggleIsOpenDialogImage}
            />
          </Box>
          <RHFInput name='title' label={EDITOR_CONSTS.titleLabelMsg} width='490px' marginTop='8px' required />
        </TitleWrapper>
        <Box alignSelf='center'>
          <RHFInput
            name='description'
            label={EDITOR_CONSTS.descriptionLabelMsg}
            width='600px'
            marginTop='24px'
            multiline={true}
          />
        </Box>
      </EditorWrapper>
      <ImageEditorModal
        modalId={fields.modalId}
        defaultFigure={fields.figure}
        open={isOpenDialogImage}
        addFigure={setImage}
        onClose={toggleIsOpenDialogImage}
        title={EDITOR_CONSTS.insertingAPhotoMsg}
        text=''
        okLabel={EDITOR_CONSTS.insertMsg}
        noLabel={fields.figure?.uid ? EDITOR_CONSTS.deleteMsg : ''}
        figureTypeSlug={figureTypeSlug}
      />
    </>
  )
}

export default Editor
