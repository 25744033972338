import {quotesReplacer} from '@components/TextEditor/Editor/utils/quotesReplacer'
import {dashReplacerHook} from '@components/TextEditor/Editor/utils/dashReplacerHook'
import {ValidationStatus} from '@utils/models/errorStatus'
import {validateStringBySymbols} from '@utils/validation/validateStringBySymbols'
import {validateTitleByWordsCount} from '@utils/validation/validateTitleByWordsCount'
import {validateTitleBySentenceCount} from '@utils/validation/validateTitleBySentenceCount'
import {validateStringByMaxLength} from '@utils/validation/validateByStringMaxLength'
import {QuotePairs, quotesRegExp} from '../../../../../types/QuotePairs'
import {SENTENCE_SEPARATOR_REG_EX} from '../PublicationFormTitles.consts'
import type {IValidateTitle} from './PublicationFormTitle.types'

const replaceValue = (value: string): string => {
  const hasQuotationMark = quotesRegExp.test(value)
  const hasDash = / - /.test(value)

  if (hasQuotationMark) {
    value = quotesReplacer(value, QuotePairs, quotesRegExp)
  }

  if (hasDash) {
    value = dashReplacerHook(value)
  }

  return value
}

const validateTitle: IValidateTitle = ({
  fieldName,
  text,
  officialWords,
  maxErrorLength,
  titleMaxWordsCount,
  maxWarningLength = 0
}) => {
  const validateStringBySymbolsRes = validateStringBySymbols(text || '')

  if (validateStringBySymbolsRes.status === ValidationStatus.Error) {
    return {
      isInvalid: true,
      errorMessage: validateStringBySymbolsRes.message
    }
  }

  const validateStringByMaxLengthRes = validateStringByMaxLength(text || '', maxErrorLength, maxWarningLength)

  if (validateStringByMaxLengthRes.status === ValidationStatus.Error) {
    return {
      isInvalid: true,
      errorMessage: validateStringByMaxLengthRes.message
    }
  }

  const validateTitleBySentenceCountRes = validateTitleBySentenceCount(fieldName, text || '', officialWords || [])

  if (validateTitleBySentenceCountRes.status === ValidationStatus.Error) {
    return {
      isInvalid: true,
      errorMessage: validateTitleBySentenceCountRes.message
    }
  }

  const sentences = text?.split(SENTENCE_SEPARATOR_REG_EX) || []
  const firstSentence = (sentences.length && sentences[0]) || ''

  if (titleMaxWordsCount && titleMaxWordsCount > 0) {
    const validateTitleByWordsCountRes = validateTitleByWordsCount(firstSentence, titleMaxWordsCount || 0)

    if (validateTitleByWordsCountRes.status === ValidationStatus.Error) {
      return {
        isInvalid: false,
        errorMessage: validateTitleByWordsCountRes.message
      }
    }
  }

  return {
    isInvalid: false,
    errorMessage: ''
  }
}

const validateWords = ({text, invalidWords}: {text: string; invalidWords: string[]}) => {
  const words = text?.match(/[А-ЯA-Z0-9]+/gi) ?? []
  const groupWords = text?.match(/[А-ЯA-Z]+, [А-ЯA-Z]+/gi) ?? []
  const result = [...words, ...groupWords]
    .filter(word => invalidWords?.some(badWord => badWord.toUpperCase() === word.toUpperCase()))
    .join(', ')

  if (result !== '') {
    return {
      isInvalid: true,
      errorMessage: `Заголовок не соответствует правилам заголовков для Яндекс.Новостей: ${result}`
    }
  }

  return {
    isInvalid: false,
    errorMessage: ''
  }
}

export {replaceValue, validateTitle, validateWords}
