import styled from 'styled-components'

export const StyledTitlePublicationList = styled.h2`
  margin: 36px 0px 8px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #222;
`
