export const OVERVIEW_BLOCK_IMAGE_MODAL_CONSTS = {
  altLabelMsg: 'Описание Alt',
  authorLabelMsg: 'Автор',
  characterLimitReachedMsg: 'Достигнут лимит по кол-ву символов',
  descriptionLabelMsg: 'Описание',
  diagonalWatermarkLabelMsg: 'Ватермарка на всё фото',
  fieldIsRequiredMsg: 'Поле обязательно к заполнению',
  fillRequiredFields: 'Заполните обязательные поля',
  fullWidthLabelMsg: 'На всю ширину',
  maxAltLength: 777,
  maxDescriptionLength: 300,
  maxLength: 250,
  setAnotherPhotoLabelMsg: 'Установить другое фото'
}
