enum CropperType {
  isAnnounce = 'isAnnounce',
  isBreakingNews = 'isBreakingNews',
  isGallery = 'isGallery',
  isImageEditorModal = 'isImageEditorModal',
  isImageLoader = 'isImageLoader',
  isModalAddPublicationToMainPage = 'isModalAddPublicationToMainPage',
  isOverviewBlock = 'isOverviewBlock',
  isOverviewBlockImageModal = 'isOverviewBlockImageModal',
  isSmmToolsPage = 'isSmmToolsPage',
  isTopNewsBlock = 'isTopNewsBlock',
  isYandexVideo = 'isYandexVideo'
}

export {CropperType}
