export const OVERVIEW_BLOCK_IMGAGE_MODAL_STORE_CONSTS = {
  defaultImageDetail: {
    id: '',
    src: '',
    cropperPreviewUrl: '',
    crop: {
      height: 0,
      left: 0,
      top: 0,
      width: 0
    },
    figureId: '',
    figureUid: '',
    figureVersionId: '',
    isFullWidth: false,
    hasWatermark: false,
    hasDiagonalWatermark: false
  }
} as const
