import {Quill} from 'react-quill'
// @ts-ignore
global.Quill = Quill
import Link from './LinkBlot/Link'
import Html from './HtmlBlot/Html'
import Video from './VideoBlot/Video'
import ImageBlot from './ImageBlot/ImageBlot'
import SideBar from './SideBar/SideBar'
import SubHeader from './SubHeader/SubHeader'
import TextRssBlock from './TextRss/TextRss'
import OnlineTranslationDividerBlot from './OnlineTranslationDividerBlot/OnlineTranslationDividerBlot'
import BlockquoteBlot from './BlockquoteBlot/BlockquoteBlot'
import GalleryBlot from './GalleryBlot/GalleryBlot'

const Inline = Quill.import('blots/inline')

function registerBlots(type: string, blots: any[]) {
  const registerObject = blots.reduce((acc, blot) => ({...acc, [`${type}/${blot.blotName}`]: blot}), {})
  Quill.register(registerObject)
}
Quill.debug('error')
registerBlots('formats', [
  Link,
  Html,
  Video,
  ImageBlot,
  GalleryBlot,
  SideBar,
  SubHeader,
  Inline,
  TextRssBlock,
  OnlineTranslationDividerBlot,
  BlockquoteBlot
])

export {
  Link,
  Html,
  Video,
  ImageBlot,
  GalleryBlot,
  SideBar,
  SubHeader,
  TextRssBlock,
  OnlineTranslationDividerBlot,
  BlockquoteBlot
}
