import {OutlinedInput} from '@mui/material'
import styled from 'styled-components'

export const TitleLength = styled.div`
  padding: 10.5px 0px 10.5px 10px;
  vertical-align: middle;
  text-align: end;
  min-width: fit-content;
`

export const OutlinedInputWrapper = styled(OutlinedInput)`
  font-size: 16px;
  font-style: inherit;
  float: left;
  width: calc(100% - 70px);

  &[class*='yandex-dzen-warning'] {
    & fieldset {
      border-color: currentColor !important;
    }
  }
`
