import React, {FC} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {Checkbox, InputLabel, MenuItem, Select} from '@mui/material'
import {StyledFormControl} from '@components/UI/styled'

type SelectProps = {
  name: string
  label: string
  items: any[]
  multiply?: boolean
  width?: string
  marginTop?: string
  marginBottom?: string
  marginLeft?: string
  getItems?: () => void
  onLoadMore?: () => void
  disabled?: boolean
  secondName?: string
}

const RHFSelect: FC<SelectProps> = ({
  name,
  label,
  items,
  multiply = false,
  width = '170px',
  marginTop,
  marginBottom,
  marginLeft,
  getItems,
  onLoadMore,
  disabled,
  secondName = ''
}) => {
  const {control, getValues} = useFormContext()

  const handleScroll = e => {
    const target = e.target
    if (target && Math.round(target.scrollHeight - target.scrollTop) === target.clientHeight) {
      if (onLoadMore) {
        onLoadMore()
      }
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <StyledFormControl
          width={width}
          onFocus={getItems}
          margintop={marginTop}
          marginbottom={marginBottom || '20px'}
          marginleft={marginLeft}
        >
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            disabled={disabled || getValues('cantEditPublication')}
            multiple={multiply}
            label={label}
            renderValue={
              multiply
                ? selected => {
                    if (secondName && (selected as Array<string>).length === 1) {
                      return ''
                    } else {
                      return `Выбрано ${(selected as Array<string>).length - 1}`
                    }
                  }
                : undefined
            }
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              style: {maxHeight: '100%'},
              onScrollCapture: e => handleScroll(e)
            }}
          >
            {!multiply && (
              <MenuItem value=''>
                <em>Не выбрано</em>
              </MenuItem>
            )}

            {items.map(item => (
              <MenuItem key={item.uid} value={item.uid} disabled={item?.title === 'Все регионы'}>
                {multiply && <Checkbox color='primary' checked={getValues(name).includes(item.uid)} />}
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      )}
    />
  )
}

export default RHFSelect
