import React, {FC, useState, useEffect} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {TextField, Typography, Box} from '@mui/material'
import {StyledFormControl} from '../../UI/styled'
import styled, {css} from 'styled-components'
import {redColor} from '../../../@theme/vars'

const StyledLength = styled(Typography)<{isMaxLength: boolean}>`
  margin-left: 8px;
  ${({isMaxLength}) =>
    isMaxLength &&
    css`
      color: ${redColor};
    `}
`

type RHFInputWithLengthProps = {
  name: string
  label: string
  width?: string
  required?: boolean
  marginTop?: string
  marginLeft?: string
  multiline?: boolean
  disabled?: boolean
  maxLength: number
  value: string
}

const RHFInputWithLength: FC<RHFInputWithLengthProps> = ({
  name,
  label,
  width = '572px',
  required,
  marginTop = '0',
  marginLeft = '0',
  multiline = false,
  disabled = false,
  maxLength,
  value
}) => {
  const {control} = useFormContext()
  const [valueLength, setValueLength] = useState(0)

  useEffect(() => setValueLength(value?.length || 0), [value])
  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field}) => (
        <StyledFormControl width={width} marginbottom='15px' margintop={marginTop} marginleft={marginLeft}>
          <Box display='flex' alignItems='center'>
            <TextField
              {...field}
              label={label}
              fullWidth
              multiline={multiline}
              disabled={disabled}
              inputProps={{maxLength: maxLength}}
            />
            <StyledLength variant='subtitle1' isMaxLength={valueLength === maxLength}>
              {valueLength}/{maxLength}
            </StyledLength>
          </Box>
        </StyledFormControl>
      )}
    />
  )
}

export default RHFInputWithLength
