import {IconButton, Paper} from '@mui/material'
import {blueColor, errorColor, greenColor, grey20, greyBG, mainTextColor} from '../../../../@theme/vars'
import styled from 'styled-components'

export const PublicationInfoWrapper = styled.div`
  margin-left: 32px;
  float: right;
`

export const PublicationInfoPaper = styled(Paper)`
  width: 280px;
  padding: 20px;
  border: 1px solid ${grey20};
  background: ${greyBG};
  box-shadow: 0px 2px 8px 0px rgba(34, 34, 34, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const PublicationInfoPaperClose = styled(IconButton)`
  position: absolute;
  right: 0px;
  top: 0px;
`

export const PublicationInfoPaperRow = styled.div`
  color: ${mainTextColor};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 8px;
`

export const PublicationInfoDevider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${grey20};
  margin-bottom: 16px;
`

export const PublicationCheckedByBildEditor = styled.div`
  color: ${greenColor};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 16px;
`

export const PublicationNotCheckedByBildEditor = styled.div`
  color: ${errorColor};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 16px;
`

export const PublicationInfoLink = styled.a`
  color: ${blueColor};
  padding: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 8px;
`

export const PublicationInfoMakeArticleBtn = styled.button`
  background-color: inherit;
  border: none;
  color: ${blueColor};
  padding: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  cursor: pointer;
`
