import {makeAutoObservable} from 'mobx'
import {CropInput} from '@graphql/types'
import {CropperType} from '../../types/CropperType'
import ICropperStore from './ICropperStore'

class CropperStore implements ICropperStore {
  cropperSrc = ''
  cropperType = CropperType.isAnnounce
  cropper = {
    left: 0,
    top: 0,
    width: 0,
    height: 0
  } as CropInput

  isCropperChanged = false
  isCustomRatio = false
  cropperIsValid = true
  ratio = 16 / 9

  constructor() {
    makeAutoObservable(this)
  }

  setCropper = (value: CropInput): void => {
    if (!value) {
      this.cropper = {
        left: 0,
        top: 0,
        width: 0,
        height: 0
      } as CropInput
      return
    }
    this.cropper.height = value.height
    this.cropper.top = value.top
    this.cropper.left = value.left
    this.cropper.width = value.width
  }

  setCropperSrc = (value: string): void => {
    this.cropperSrc = value
  }

  setCropperType = (value: CropperType): void => {
    this.cropperType = value
  }

  setIsCropperChanged = (value: boolean): void => {
    this.isCropperChanged = value
  }

  setIsCustomRatio = (value: boolean): void => {
    this.isCustomRatio = value
  }

  setCropperIsValid = (value: boolean): void => {
    this.cropperIsValid = value
  }

  setRatio = (value: number): void => {
    this.ratio = value
  }
}

export default CropperStore
