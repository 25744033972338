import React, {FC} from 'react'

export const EyeIcon: FC<any> = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Icons' clipPath='url(#clip0_8406_82036)'>
        <path
          id='Vector'
          d='M9.9987 4.71875C6.96839 4.71875 4.38052 6.61931 3.33203 9.30208C4.38052 11.9849 6.96839 13.8854 9.9987 13.8854C13.029 13.8854 15.6169 11.9849 16.6654 9.30208C15.6169 6.61931 13.029 4.71875 9.9987 4.71875ZM9.9987 12.3576C8.32597 12.3576 6.96839 10.9887 6.96839 9.30208C6.96839 7.61542 8.32597 6.24653 9.9987 6.24653C11.6714 6.24653 13.029 7.61542 13.029 9.30208C13.029 10.9887 11.6714 12.3576 9.9987 12.3576ZM9.9987 7.46875C8.99264 7.46875 8.18052 8.28764 8.18052 9.30208C8.18052 10.3165 8.99264 11.1354 9.9987 11.1354C11.0048 11.1354 11.8169 10.3165 11.8169 9.30208C11.8169 8.28764 11.0048 7.46875 9.9987 7.46875Z'
          fill='#222222'
        />
      </g>
      <defs>
        <clipPath id='clip0_8406_82036'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
