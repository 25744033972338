import styled from 'styled-components'
import {FormHelperText} from '@mui/material'
import {redColor} from '@theme/vars'

export const TitleLength = styled.div`
  padding: 10.5px 0px 10.5px 10px;
  vertical-align: middle;
  text-align: end;
  min-width: fit-content;
`

export const FormHelperErrorText = styled(FormHelperText)`
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${redColor};
`
