import * as Types from '../../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type TopNewsSelectionQueryVariables = Types.Exact<{
  regionId: Types.Scalars['UUID']
}>

export type TopNewsSelectionQuery = {__typename?: 'Query'} & {
  topNewsSelection?: Types.Maybe<Array<{__typename?: 'TopNewsBlock'} & TopNewsBlockFragmentFragment>>
}

export type UpdateTopNewsSelectionMutationVariables = Types.Exact<{
  data: Types.UpdateTopNewsSelectionInput
}>

export type UpdateTopNewsSelectionMutation = {__typename?: 'Mutation'} & {
  updateTopNewsSelection?: Types.Maybe<
    {__typename?: 'UpdateTopNewsSelectionPayload'} & Pick<Types.UpdateTopNewsSelectionPayload, 'status'>
  >
}

export type SaveTopNewsBlockMutationVariables = Types.Exact<{
  data: Types.SaveTopNewsBlockInput
}>

export type SaveTopNewsBlockMutation = {__typename?: 'Mutation'} & {
  saveTopNewsBlock?: Types.Maybe<
    {__typename?: 'SaveTopNewsBlockPayload'} & {
      block?: Types.Maybe<{__typename?: 'TopNewsBlock'} & TopNewsBlockFragmentFragment>
    }
  >
}

export type RegionCountBlocksQueryVariables = Types.Exact<{
  regionId: Types.Scalars['UUID']
}>

export type RegionCountBlocksQuery = {__typename?: 'Query'} & {
  region?: Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'requiredCountOfTopNewsBlocks'>>
}

export type TopNewsBlockFragmentFragment = {__typename?: 'TopNewsBlock'} & Pick<
  Types.TopNewsBlock,
  'uid' | 'position' | 'title' | 'isExclusive' | 'isAd' | 'url' | 'clickCount'
> & {
    figureVersion?: Types.Maybe<
      {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id'> & {
          figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'uid' | 'src'> & {
              cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
            }
          crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'height' | 'left' | 'top' | 'width'>
          primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        }
    >
  }

export type PublicationByUrlForBannerQueryVariables = Types.Exact<{
  url: Types.Scalars['String']
}>

export type PublicationByUrlForBannerQuery = {__typename?: 'Query'} & {
  publicationByUrl?: Types.Maybe<
    | ({__typename: 'ExternalPublication'} & Pick<
        Types.ExternalPublication,
        'url' | 'title' | 'subtitle' | 'announcingImageUrl'
      >)
    | ({__typename: 'Publication'} & Pick<Types.Publication, 'uid' | 'title'> & {
          announceImage: {__typename?: 'AnnounceImage'} & Pick<Types.AnnounceImage, 'id'> & {
              figureVersion?: Types.Maybe<{__typename?: 'FigureVersion'} & FigureVersionFragmentFragment>
            }
        })
  >
}

export type FigureVersionFragmentFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'src' | 'uid'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
      }
  }

export const TopNewsBlockFragmentFragmentDoc = gql`
  fragment TopNewsBlockFragment on TopNewsBlock {
    uid
    position
    title
    isExclusive
    isAd
    url
    clickCount
    figureVersion {
      figure {
        id
        uid
        cropperPreview {
          url
        }
        src
      }
      id
      crop {
        height
        left
        top
        width
      }
      primaryProcessedImage {
        url
      }
    }
  }
`
export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const TopNewsSelectionDocument = gql`
  query topNewsSelection($regionId: UUID!) {
    topNewsSelection(regionId: $regionId) {
      ...TopNewsBlockFragment
    }
  }
  ${TopNewsBlockFragmentFragmentDoc}
`

/**
 * __useTopNewsSelectionQuery__
 *
 * To run a query within a React component, call `useTopNewsSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNewsSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNewsSelectionQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useTopNewsSelectionQuery(
  baseOptions: Apollo.QueryHookOptions<TopNewsSelectionQuery, TopNewsSelectionQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<TopNewsSelectionQuery, TopNewsSelectionQueryVariables>(TopNewsSelectionDocument, options)
}
export function useTopNewsSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopNewsSelectionQuery, TopNewsSelectionQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<TopNewsSelectionQuery, TopNewsSelectionQueryVariables>(TopNewsSelectionDocument, options)
}
export type TopNewsSelectionQueryHookResult = ReturnType<typeof useTopNewsSelectionQuery>
export type TopNewsSelectionLazyQueryHookResult = ReturnType<typeof useTopNewsSelectionLazyQuery>
export type TopNewsSelectionQueryResult = Apollo.QueryResult<TopNewsSelectionQuery, TopNewsSelectionQueryVariables>
export const UpdateTopNewsSelectionDocument = gql`
  mutation updateTopNewsSelection($data: UpdateTopNewsSelectionInput!) {
    updateTopNewsSelection(data: $data) {
      status
    }
  }
`
export type UpdateTopNewsSelectionMutationFn = Apollo.MutationFunction<
  UpdateTopNewsSelectionMutation,
  UpdateTopNewsSelectionMutationVariables
>

/**
 * __useUpdateTopNewsSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateTopNewsSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopNewsSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopNewsSelectionMutation, { data, loading, error }] = useUpdateTopNewsSelectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTopNewsSelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTopNewsSelectionMutation, UpdateTopNewsSelectionMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<UpdateTopNewsSelectionMutation, UpdateTopNewsSelectionMutationVariables>(
    UpdateTopNewsSelectionDocument,
    options
  )
}
export type UpdateTopNewsSelectionMutationHookResult = ReturnType<typeof useUpdateTopNewsSelectionMutation>
export type UpdateTopNewsSelectionMutationResult = Apollo.MutationResult<UpdateTopNewsSelectionMutation>
export type UpdateTopNewsSelectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateTopNewsSelectionMutation,
  UpdateTopNewsSelectionMutationVariables
>
export const SaveTopNewsBlockDocument = gql`
  mutation saveTopNewsBlock($data: SaveTopNewsBlockInput!) {
    saveTopNewsBlock(data: $data) {
      block {
        ...TopNewsBlockFragment
      }
    }
  }
  ${TopNewsBlockFragmentFragmentDoc}
`
export type SaveTopNewsBlockMutationFn = Apollo.MutationFunction<
  SaveTopNewsBlockMutation,
  SaveTopNewsBlockMutationVariables
>

/**
 * __useSaveTopNewsBlockMutation__
 *
 * To run a mutation, you first call `useSaveTopNewsBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTopNewsBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTopNewsBlockMutation, { data, loading, error }] = useSaveTopNewsBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveTopNewsBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveTopNewsBlockMutation, SaveTopNewsBlockMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SaveTopNewsBlockMutation, SaveTopNewsBlockMutationVariables>(
    SaveTopNewsBlockDocument,
    options
  )
}
export type SaveTopNewsBlockMutationHookResult = ReturnType<typeof useSaveTopNewsBlockMutation>
export type SaveTopNewsBlockMutationResult = Apollo.MutationResult<SaveTopNewsBlockMutation>
export type SaveTopNewsBlockMutationOptions = Apollo.BaseMutationOptions<
  SaveTopNewsBlockMutation,
  SaveTopNewsBlockMutationVariables
>
export const RegionCountBlocksDocument = gql`
  query regionCountBlocks($regionId: UUID!) {
    region(uid: $regionId) {
      uid
      requiredCountOfTopNewsBlocks
    }
  }
`

/**
 * __useRegionCountBlocksQuery__
 *
 * To run a query within a React component, call `useRegionCountBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionCountBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionCountBlocksQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useRegionCountBlocksQuery(
  baseOptions: Apollo.QueryHookOptions<RegionCountBlocksQuery, RegionCountBlocksQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<RegionCountBlocksQuery, RegionCountBlocksQueryVariables>(RegionCountBlocksDocument, options)
}
export function useRegionCountBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionCountBlocksQuery, RegionCountBlocksQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<RegionCountBlocksQuery, RegionCountBlocksQueryVariables>(
    RegionCountBlocksDocument,
    options
  )
}
export type RegionCountBlocksQueryHookResult = ReturnType<typeof useRegionCountBlocksQuery>
export type RegionCountBlocksLazyQueryHookResult = ReturnType<typeof useRegionCountBlocksLazyQuery>
export type RegionCountBlocksQueryResult = Apollo.QueryResult<RegionCountBlocksQuery, RegionCountBlocksQueryVariables>
export const PublicationByUrlForBannerDocument = gql`
  query publicationByUrlForBanner($url: String!) {
    publicationByUrl(url: $url) {
      __typename
      ... on Publication {
        uid
        title
        announceImage {
          id
          figureVersion {
            ...FigureVersionFragment
          }
        }
      }
      ... on ExternalPublication {
        url
        title
        subtitle
        announcingImageUrl
      }
    }
  }
  ${FigureVersionFragmentFragmentDoc}
`

/**
 * __usePublicationByUrlForBannerQuery__
 *
 * To run a query within a React component, call `usePublicationByUrlForBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationByUrlForBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationByUrlForBannerQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function usePublicationByUrlForBannerQuery(
  baseOptions: Apollo.QueryHookOptions<PublicationByUrlForBannerQuery, PublicationByUrlForBannerQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationByUrlForBannerQuery, PublicationByUrlForBannerQueryVariables>(
    PublicationByUrlForBannerDocument,
    options
  )
}
export function usePublicationByUrlForBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationByUrlForBannerQuery, PublicationByUrlForBannerQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationByUrlForBannerQuery, PublicationByUrlForBannerQueryVariables>(
    PublicationByUrlForBannerDocument,
    options
  )
}
export type PublicationByUrlForBannerQueryHookResult = ReturnType<typeof usePublicationByUrlForBannerQuery>
export type PublicationByUrlForBannerLazyQueryHookResult = ReturnType<typeof usePublicationByUrlForBannerLazyQuery>
export type PublicationByUrlForBannerQueryResult = Apollo.QueryResult<
  PublicationByUrlForBannerQuery,
  PublicationByUrlForBannerQueryVariables
>
