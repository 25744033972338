import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'
import {blueColor} from '../../../@theme/vars'

export const SimpleFigure: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path
        d='M42 6H6C4 6 2 8 2 10V38C2 40.2 3.8 42 6 42H42C44 42 46 40 46 38V10C46 8 44 6 42 6ZM42 37.84C41.96 37.9 41.88 37.96 41.84 38H6V10.16L6.16 10H41.82C41.88 10.04 41.94 10.12 41.98 10.16V37.84H42ZM22 31.02L17 25L10 34H38L29 22L22 31.02Z'
        fill={props.color === 'primary' ? blueColor : '#C4C4C4'}
      />
    </SvgIcon>
  )
}
