import styled from 'styled-components'
import {lightgrayColor} from '../../../@theme/vars'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'

export const Wrapper = styled(Paper)`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: 12px 10px;
  border: 1px solid ${lightgrayColor};
  background-color: #f1f6ff;
  color: #1964e7;
`

export const StylePopper = styled(Popper)`
  z-index: 1200;
  /* top: -10px !important; */
`

export const Arrow = styled.span`
  position: absolute;
  font-size: 7px;
  width: 3em;
  height: 3em;
  box-sizing: border-box;
  color: #fff;
  bottom: -10px;

  &::before {
    content: ' ';
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0px -1px rgb(0 0 0 / 20%), 2px 2px 0 0 rgb(0 0 0 / 14%), 2px 2px 3px 0 rgb(0 0 0 / 12%);
    background-color: #fff;
    transform: rotate(45deg);
    background-color: #f1f6ff;
  }
`
