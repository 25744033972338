import {Box, Typography} from '@mui/material'
import styled from 'styled-components'
import {screenS} from '../../../../@theme/vars'

export const Wrapper = styled.div`
  max-width: 1304px;
  margin: 0 auto;
`

export const Header = styled.div`
  margin-bottom: 25px;
  margin-right: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: ${screenS}) {
    flex-direction: column-reverse;
  }
`

export const StyledError = styled.div`
  padding: 12px;
  background-color: red;
  cursor: pointer;
  color: white;
`

export const HistoryAndSaveBox = styled(Box)`
  display: flex;
  @media screen and (max-width: ${screenS}) {
    flex-direction: column;
    width: calc(100% - 40px);
    min-width: 340px;
    max-width: 450px;
    gap: 12px;
    margin: 0 auto;
  }
`

export const ModalDescription = styled(Typography)`
  font-weight: normal;
  margin-bottom: 15px;
`
