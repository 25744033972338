import {Quill} from 'react-quill'
import {yellowColor} from '../../../../@theme/vars'

const Embed = Quill.import('blots/block/embed')

export type SideBarBlot = {
  imageSrc: string
  title: string
  id: string
  sidebarId: string
  publicationUrl: string
}

export default class SideBar extends Embed {
  constructor(node: HTMLElement) {
    super(node)
    if (node) {
      node.setAttribute(
        'style',
        `
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 5px 25px 10px 0;
          padding: 20px;
          width: 250px;
          border: 1px solid ${yellowColor};
          float: left;
          clear: left;
        `
      )
    }
  }

  static create(sideBarBlot: SideBarBlot): HTMLElement {
    const {imageSrc, title, id, sidebarId, publicationUrl} = sideBarBlot
    const node = super.create(imageSrc)
    const header = document.createElement('div')
    header.innerText = 'НОВОСТЬ ПО ТЕМЕ'
    header.setAttribute('sidebarheader', 'true')
    header.setAttribute(
      'style',
      `
        margin-bottom: 5px;
        text-transform: uppercase;
        font-size: 9px;
        line-height: 9px;
        letter-spacing: 1px;
      `
    )

    const image = document.createElement('img')
    const linkImage = document.createElement('a')
    const publicationTitle = document.createElement('a')
    image.setAttribute('draggable', 'false')
    node.setAttribute('title', title)
    node.setAttribute('imageSrc', imageSrc)
    node.setAttribute('id', id)
    node.setAttribute('sidebarid', sidebarId)
    node.setAttribute('tabindex', '-1')
    node.setAttribute('contenteditable', 'false')
    node.setAttribute('publicationUrl', publicationUrl)
    linkImage.setAttribute('href', publicationUrl)
    publicationTitle.setAttribute('href', publicationUrl)

    publicationTitle.innerText = title
    publicationTitle.setAttribute(
      'style',
      `
        margin: 10px 0px;
        font-size: 20px;
        color: #000;
        line-height: 20px;
      `
    )

    image.setAttribute('src', imageSrc)
    linkImage.append(image)
    node.append(header, linkImage, publicationTitle)

    const deleteSideBar = document.createElement('img')
    deleteSideBar.setAttribute('src', '/deleteItem.jpg')
    deleteSideBar.setAttribute('draggable', 'false')
    deleteSideBar.classList.add('delete-item')

    const deleteSideBarEvent = new CustomEvent('deleteSideBarEvent', {
      detail: {id},
      bubbles: true
    })
    deleteSideBar.addEventListener('click', () => {
      return window.dispatchEvent(deleteSideBarEvent)
    })

    const changeSideBar = document.createElement('img')
    changeSideBar.setAttribute('src', '/changeItem.jpg')
    changeSideBar.setAttribute('draggable', 'false')
    changeSideBar.classList.add('change-item')

    node.prepend(changeSideBar, deleteSideBar)

    const changeSideBarEvent = new CustomEvent('openModalSideBar', {
      detail: {sideBarBlot},
      bubbles: true
    })
    changeSideBar.addEventListener('click', () => {
      return window.dispatchEvent(changeSideBarEvent)
    })
    return node
  }

  static value(node: HTMLElement): SideBarBlot {
    return {
      imageSrc: node.getAttribute('imageSrc') || '',
      title: node.getAttribute('title') || '',
      id: node.getAttribute('id') || '',
      sidebarId: node.getAttribute('sidebarid') || '',
      publicationUrl: node.getAttribute('publicationUrl') || ''
    }
  }
}

SideBar.tagName = 'div'
SideBar.className = 'sideBar'
SideBar.blotName = 'custom-sideBar'
