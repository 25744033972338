import Quill from 'quill'

interface EditorRef {
  current: {
    getEditor: () => Quill
  } | null
}

/**
 * Получает экземпляр Quill из editorRef.
 * @param editorRef Ссылка на редактор.
 * @returns Экземпляр Quill или undefined, если editorRef.current равен null.
 */
export const getQuillEditor = (editorRef: EditorRef): Quill | undefined => {
  if (editorRef.current !== null) {
    return editorRef.current.getEditor()
  }
  return undefined
}
