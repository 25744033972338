import React from 'react'
import ReactDOM from 'react-dom'
import './@theme/_reset.scss'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {ReactKeycloakProvider} from '@react-keycloak/web'
import keycloakClient, {initOptions} from './@auth/keyacloak-client'

ReactDOM.render(
    <ReactKeycloakProvider
        authClient={keycloakClient()}
        initOptions={initOptions}
    >
        <App />
    </ReactKeycloakProvider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
