import React, {FC, Fragment, useEffect, useState, createContext} from 'react'
import format from 'date-fns/format'
import {ru} from 'date-fns/locale'
import PublicationItem from '../Item/PublicationItem'
import styled from 'styled-components'
import {PublicationNodeFragment} from '../gql/PublicationList.generated'
import {Box} from '@mui/material'

type ListType = {
  publicationsGroup: object
}

export const StyledTitlePublicationList = styled.h2`
  margin: 12px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #222;
`
export const LengthContext = createContext<number>(0)
const PublicationList: FC<ListType> = ({publicationsGroup}) => {
  const [focusedElementId, setFocusedElementId] = useState('')
  const [length, setLength] = useState(0)

  useEffect(() => {
    const calculatedLength = Object.keys(publicationsGroup).reduce(
      (total, arr) => total + publicationsGroup[arr].length,
      0
    )
    setLength(calculatedLength)
  }, [publicationsGroup])

  return (
    <LengthContext.Provider value={length}>
      <Box paddingRight='15px'>
        {Object.keys(publicationsGroup).map(publicationItem => (
          <Fragment key={publicationItem}>
            <StyledTitlePublicationList id='publication-list-date'>
              {format(new Date(publicationItem), 'd MMMM', {
                locale: ru
              })}
            </StyledTitlePublicationList>

            {publicationsGroup[publicationItem].map(publication => (
              <PublicationItem
                publication={publication.node as PublicationNodeFragment}
                focusedElementId={focusedElementId}
                setFocusedElementId={setFocusedElementId}
                key={publication.node?.uid}
              />
            ))}
          </Fragment>
        ))}
      </Box>
    </LengthContext.Provider>
  )
}

export default PublicationList
