import React, {FC, useMemo, useState} from 'react'
import {Box, LinearProgress} from '@mui/material'
import {format, isValid} from 'date-fns'
import {useTopNewsSelectionHistoryQuery} from '../../../../components/MainSelection/TopNews/TopNewsSelectionHistory/gql/TopNewsSelectionHistory.generated'
import MainSelectionCard from '../../../../components/MainSelection/MainSelectionCard/MainSelectionCard'
import {TopNewsHistoryContentProps} from './TopNewsHistoryContentProps'
import {BlocksWrapper, KeyboardDatePickerWrapper} from './TopNewsHistoryContent.styles'
import {TOP_NEWS_HISTORY_CONTENT_CONSTS} from './TopNewsHistoryContent.consts'

export const TopNewsHistoryContent: FC<TopNewsHistoryContentProps> = ({regionId}) => {
  const [searchDate, setSearchDate] = useState<any>(new Date())

  const variables = useMemo(
    () => ({
      date: searchDate && isValid(searchDate) ? format(searchDate, 'yyyy-MM-dd') : null,
      regionId: regionId
    }),
    [regionId, searchDate]
  )

  const {data, loading} = useTopNewsSelectionHistoryQuery({
    variables: variables,
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  })

  return (
    <div>
      <Box width={'100%'}>
        <KeyboardDatePickerWrapper
          disableToolbar
          variant='inline'
          format='yyyy-MM-dd'
          id='date-picker-inline'
          label='Поиск по дате'
          value={searchDate}
          onChange={date => setSearchDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          style={{padding: '0'}}
          slotProps={{
            openPickerButton: {color: 'primary'}
          }}
        />
      </Box>
      <BlocksWrapper>
        {data?.topNewsSelectionHistory?.length ? (
          data?.topNewsSelectionHistory?.map((item, ind) => (
            <MainSelectionCard
              key={item.uid}
              index={ind}
              read={true}
              regionId={regionId}
              overview={false}
              data={{
                uid: item.uid,
                figureVersion: item.figureVersion,
                isExclusive: item.isExclusive,
                isAd: item.isAd,
                url: item.url,
                title: item.title
              }}
              size={'topNewsHistory'}
            />
          ))
        ) : (
          <div>{TOP_NEWS_HISTORY_CONTENT_CONSTS.publicationsNotExists}</div>
        )}
        {loading && <LinearProgress />}
      </BlocksWrapper>
    </div>
  )
}
