import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

export const ArrowBack: FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.75 6.83333C8.54167 6.83333 6.54167 7.65833 5 9L2 6V13.5H9.5L6.48333 10.4833C7.64167 9.51667 9.11667 8.91667 10.75 8.91667C13.7 8.91667 16.2083 10.8417 17.0833 13.5L19.0583 12.85C17.9 9.35833 14.625 6.83333 10.75 6.83333Z'
          fill='#222222'
        />
      </svg>
    </SvgIcon>
  )
}
