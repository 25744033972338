import React from 'react'
import {TooltipProps as MUITooltipProps} from '@mui/material'
import {StyledTooltip} from './styles'

type OmitMUIProps = 'disableFocusListener' | 'disableTouchListener' | 'classes'

export interface TooltipProps extends Omit<MUITooltipProps, OmitMUIProps> {
  trigger?: 'hoverOrTouch' | 'focusOrTouch' | 'hover'
  ref?: React.Ref<any>
  color?: string
}

// eslint-disable-next-line react/display-name
const Tooltip = React.forwardRef((props: TooltipProps, ref: any) => {
  return <StyledTooltip ref={ref} enterTouchDelay={300} {...props} />
})

Tooltip.defaultProps = {
  trigger: 'hoverOrTouch'
}

export default Tooltip
