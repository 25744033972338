import styled from 'styled-components'
import {grayColor, screenS} from '../../@theme/vars'
import {Tab} from '@mui/material'

export const PageName = styled.div`
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: #222;
  @media (max-width: ${screenS}) {
    font-size: 16px;
  }
`

export const TabWrapper = styled(Tab)`
  color: ${grayColor};
`
