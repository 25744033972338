import React, {FC} from 'react'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import {Typography} from '@mui/material'

const NotFound: FC = () => {
  return (
    <PageContainer>
      <Typography variant='h4'>Страница не найдена</Typography>
    </PageContainer>
  )
}

export default NotFound
