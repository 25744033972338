import {CircularProgress} from '@mui/material'
import {useKeycloak} from '@react-keycloak/web'
import {format} from 'date-fns'
import {isNull} from 'lodash'
import {observer} from 'mobx-react-lite'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useFormContext, useWatch} from 'react-hook-form'
import {toast} from 'react-toastify'
import {AgentCheckService} from '@service/index'
import {usePublishOverviewBlocksDraftMutation} from '@components/MainSelection/Overview/OverviewContent/gql/OverviewContent.generated'
import {
  usePublishPublicationMutation,
  useSendPublicationForReviewMutation,
  useUnpublishPublicationMutation,
  useUpdatePublicationMutation
} from '@components/Publication/Form/gql/PublicationEditor.generated'
import {UNPUBLISHED_NEWS_PICKER_CONSTS} from '@components/Publication/Form/Pickers/UnpublishedNewsPicker/UnpublishedNewsPicker.consts'
import {PublicationInputType} from '@components/Publication/Form/PublicationForm/PublicationInputType'
import {PublicationLoading} from '@components/Publication/Form/PublicationLoading/PublicationLoading'
import ControlTooltip from '@components/UI/Tooltip/ControlTooltip'
import {PublicationStatus, UpdatePublicationInput} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import AgentCheckNotification from '@components/AgentCheckNotification/AgentCheckNotification'
import Tooltip from '@components/UI/Tooltip/Tooltip'
import {PUBLICATION_ACTION_BUTTON_CONSTS} from './PublicationActionButton.consts'
import {
  PublicationActionButtonBtn,
  PublicationActionButtonControlTooltipWrapper,
  PublicationActionButtonFields,
  PublicationActionButtonFieldsButtons,
  PublicationActionButtonFieldsDateAndStatus,
  PublicationActionButtonFieldsDateAndStatusBlock,
  PublicationActionButtonWrapper,
  PublicationRemovedWrapper
} from './PublicationActionButton.styles'
import {PublicationActionButtonProps} from './PublicationActionButtonProps'
import {PublicationDate} from './PublicationDate/PublicationDate'

const PublicationActionButton: FC<PublicationActionButtonProps> = ({hasOverviewBlock}) => {
  const {publicationStore, editorStore, regionsWithOverviewBlocksStore, agentCheckStore, dialogStore} = useStore()
  const {
    publication,
    publicationLoading,
    publicationContentLoading,
    setPublication,
    setPublicationWithPrevState,
    destinationsDirty,
    rubricAndSubrubricStore,
    announceImageModalStore,
    hasTitlesError,
    publicationFormDirty,
    checkYandexDzenTitleValidation
  } = publicationStore

  const {editorDirty, editorTextLength, editorRef} = editorStore
  const {isRollUp} = dialogStore
  const {isDisabled, isPending, isChecking, isOpen, isError} = agentCheckStore
  const {overviewBlocksLoading} = regionsWithOverviewBlocksStore
  const {rubricIdCache, subrubricIdCache} = rubricAndSubrubricStore
  const {control} = useFormContext<PublicationInputType>()
  const fields = useWatch({control})

  const {keycloak} = useKeycloak()
  const [sendPublicationForReview] = useSendPublicationForReviewMutation()
  const [unpublishPublication, {loading: unpublishLoading}] = useUnpublishPublicationMutation()

  useEffect(() => {
    agentCheckStore.setParamsDefault()
  }, [])

  const sendPublicationForReviewAction = useCallback(async () => {
    try {
      const newPublicationShema = await sendPublicationForReview({
        variables: {
          data: {
            publicationUid: publication?.uid
          }
        }
      })
      if (newPublicationShema.data?.sendPublicationForReview?.publication?.status) {
        const newPublication = publication
        newPublication.status = newPublicationShema.data?.sendPublicationForReview?.publication?.status
        setPublication(newPublication)
      }
      toast.success(PUBLICATION_ACTION_BUTTON_CONSTS.savedMsg, {
        autoClose: false,
        hideProgressBar: true,
        containerId: 'success'
      })
    } catch {
      console.error(PUBLICATION_ACTION_BUTTON_CONSTS.errorMsg)
    }
  }, [publication, sendPublicationForReview, setPublication])

  const publishPublicationAction = useCallback(() => {
    const AgentCheck = new AgentCheckService({
      publicationStore,
      agentCheckStore,
      setPublicationWithPrevState
    })

    AgentCheck.checkAgents('Опубликовать', fields as PublicationInputType, editorRef, publication)
  }, [fields, setPublicationWithPrevState, editorRef, publicationStore, agentCheckStore, publication])

  const unpublishPublicationAction = useCallback(async () => {
    try {
      const unpublishPublicationResult = await unpublishPublication({
        variables: {
          data: {
            publicationUid: publication?.uid,
            redirectUrl: UNPUBLISHED_NEWS_PICKER_CONSTS.homePage
          }
        }
      })

      if (!unpublishPublicationResult.errors?.length) {
        setPublicationWithPrevState(prev => ({
          ...prev,
          status:
            unpublishPublicationResult.data?.unpublishPublication?.publication?.status || PublicationStatus.Unpublished,
          redirectUrl:
            unpublishPublicationResult.data?.unpublishPublication?.publication?.redirectUrl ||
            UNPUBLISHED_NEWS_PICKER_CONSTS.homePage
        }))
      }

      toast.success(PUBLICATION_ACTION_BUTTON_CONSTS.savedMsg, {
        autoClose: false,
        hideProgressBar: true,
        containerId: 'success'
      })
    } catch {
      console.error(PUBLICATION_ACTION_BUTTON_CONSTS.errorMsg)
    }
  }, [publication?.uid, setPublicationWithPrevState, unpublishPublication])

  const closePublicationAction = useCallback(() => {
    window.location.href = '/news'
  }, [])

  const buttonDisabledTooltipMessage = useMemo(() => {
    const errors: string[] = []
    let actionType = [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
      ? PUBLICATION_ACTION_BUTTON_CONSTS.publishMsg.toLowerCase()
      : PUBLICATION_ACTION_BUTTON_CONSTS.saveMsg.toLowerCase()

    if (!keycloak.hasResourceRole('publish', 'publications')) {
      actionType = PUBLICATION_ACTION_BUTTON_CONSTS.sendToReviewMsg
    }
    if (!fields.title) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.titleMsg)
    }
    if (!fields.seoTitle) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.seoTitleMsg)
    }
    if (!fields.regionUids || fields.regionUids?.length < 2) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.regionMsg)
    }
    if (!rubricIdCache) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.rubricMsg)
    }
    if (!fields.keywords?.length) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.keywordsMsg)
    }
    if (!fields.authors?.length) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.authorMsg)
    }
    if (!editorTextLength) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.textMsg)
    }
    if (!fields.announceFigureVersionId && !fields?.announceImage?.figureVersion?.id) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.announcePhotoMSg)
    }
    if (checkYandexDzenTitleValidation && !fields.yandexDzenTitle) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.yandexDzenTitle)
    }
    if (isRollUp) {
      return PUBLICATION_ACTION_BUTTON_CONSTS.tooltipDialogNn
    }
    if (
      !editorDirty &&
      !publicationFormDirty &&
      !destinationsDirty &&
      ![PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status)
    ) {
      return PUBLICATION_ACTION_BUTTON_CONSTS.needSomeChangesMSg
    }
    if (
      publication?.status === 'PUBLISHED' &&
      publication.typeSlug === 'news' &&
      hasOverviewBlock &&
      !fields.overviewTitle
    ) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.overviewTitleMsg)
    }
    if (publication?.status === 'PUBLISHED' && hasOverviewBlock && !fields.overviewFigureVersionId) {
      errors.push(PUBLICATION_ACTION_BUTTON_CONSTS.overviewFigureMsg)
    }
    if (errors?.length) {
      return `Чтобы ${actionType} материал ${errors.length > 1 ? 'нужны' : 'нужно заполнить поле'} ${errors.join(', ')}`
    }

    return ''
  }, [
    fields,
    publication,
    editorDirty,
    publicationFormDirty,
    destinationsDirty,
    editorTextLength,
    hasOverviewBlock,
    rubricIdCache,
    checkYandexDzenTitleValidation,
    isRollUp
  ])

  const [disableForm, setDisableForm] = useState(false)
  const isDirty = editorDirty || publicationFormDirty || destinationsDirty

  useEffect(() => {
    const hasFormErrors = Object.keys(control._formState.errors).length > 0

    setDisableForm(
      hasFormErrors ||
        !fields.title ||
        !fields.seoTitle ||
        !rubricIdCache ||
        !fields.authors?.length ||
        !fields.regionUids ||
        fields.regionUids?.length < 2 ||
        !fields.keywords?.length ||
        (!fields.announceFigureVersionId && !fields?.announceImage?.figureVersion?.id) ||
        (publication.typeSlug === 'news' && hasOverviewBlock && !fields.overviewTitle) ||
        (keycloak.hasResourceRole('publish', 'publications') && hasOverviewBlock && !fields.overviewFigureVersionId) ||
        !editorTextLength ||
        overviewBlocksLoading ||
        (checkYandexDzenTitleValidation && !fields.yandexDzenTitle) ||
        publicationLoading
    )
  }, [
    control._formState.errors,
    fields,
    rubricIdCache,
    editorTextLength,
    publication?.status,
    publication?.typeSlug,
    publication.destinations,
    overviewBlocksLoading,
    publicationLoading,
    hasOverviewBlock,
    editorDirty,
    publicationFormDirty,
    destinationsDirty,
    checkYandexDzenTitleValidation
  ])

  const [loading, setLoading] = useState(
    publicationLoading || publicationContentLoading || overviewBlocksLoading || false
  )

  useEffect(() => {
    setLoading(publicationLoading || publicationContentLoading || overviewBlocksLoading)
  }, [publicationLoading, publicationContentLoading, overviewBlocksLoading])

  useEffect(() => {
    if (isOpen || isError) {
      agentCheckStore.setDisabledModalButton(disableForm || hasTitlesError || isRollUp)
    }
  }, [disableForm, hasTitlesError, isDirty, isRollUp, agentCheckStore, isOpen, isError])

  return (
    <PublicationActionButtonWrapper>
      <PublicationActionButtonFields>
        <PublicationActionButtonFieldsDateAndStatus>
          <PublicationActionButtonFieldsDateAndStatusBlock>
            <AgentCheckNotification />
            {publication?.status === 'UNPUBLISHED' && (
              <PublicationRemovedWrapper>
                {UNPUBLISHED_NEWS_PICKER_CONSTS.publicationHasBeenRemoved}
              </PublicationRemovedWrapper>
            )}
            <PublicationDate publication={publication} />
          </PublicationActionButtonFieldsDateAndStatusBlock>
          <PublicationLoading publicationStatus={publication?.status} publicationLoading={loading} />
        </PublicationActionButtonFieldsDateAndStatus>
        <PublicationActionButtonFieldsButtons>
          {([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) ||
            publication?.status === 'UNPUBLISHED') &&
            !keycloak.hasResourceRole('publish', 'publications') && (
              <PublicationActionButtonControlTooltipWrapper
                title=''
                enterDelay={1000}
                disabledTitle={buttonDisabledTooltipMessage}
                color={'default'}
                disabled={disableForm}
                withoutTooltipIfActive={true}
              >
                <PublicationActionButtonBtn
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={sendPublicationForReviewAction}
                  disabled={disableForm}
                >
                  {PUBLICATION_ACTION_BUTTON_CONSTS.forReviewMsg}
                </PublicationActionButtonBtn>
              </PublicationActionButtonControlTooltipWrapper>
            )}
          {publication?.status === 'PUBLISHED' && keycloak.hasResourceRole('unpublish', 'publications') && (
            <ControlTooltip
              title=''
              enterDelay={1000}
              disabledTitle={PUBLICATION_ACTION_BUTTON_CONSTS.removePublicationFromSiteMsg}
              color={'default'}
              withoutTooltipIfActive={true}
              style={{width: '100%', minWidth: '210px'}}
            >
              <PublicationActionButtonBtn
                variant='outlined'
                color='primary'
                size='small'
                onClick={unpublishPublicationAction}
                disabled={unpublishLoading}
              >
                {unpublishLoading ? (
                  <CircularProgress size='1rem' />
                ) : (
                  <span>{PUBLICATION_ACTION_BUTTON_CONSTS.removeFromSiteMsg}</span>
                )}
              </PublicationActionButtonBtn>
            </ControlTooltip>
          )}
          {publication?.status === 'PUBLISHED' && !keycloak.hasResourceRole('edit-published', 'publications') && (
            <PublicationActionButtonBtn
              variant='contained'
              color='primary'
              size='small'
              onClick={closePublicationAction}
            >
              {PUBLICATION_ACTION_BUTTON_CONSTS.closeMsg}
            </PublicationActionButtonBtn>
          )}
          {(publication?.status === 'PUBLISHED' || publication?.status === 'UNPUBLISHED') &&
            keycloak.hasResourceRole('edit-published', 'publications') && (
              <PublicationActionButtonControlTooltipWrapper
                title=''
                enterDelay={1000}
                disabledTitle={buttonDisabledTooltipMessage}
                color={'default'}
                disabled={disableForm || !isDirty || isRollUp}
                withoutTooltipIfActive={true}
              >
                <PublicationActionButtonBtn
                  type='submit'
                  variant={`${publication?.status === 'PUBLISHED' ? 'contained' : 'outlined'}`}
                  color='primary'
                  size='small'
                  disabled={disableForm || hasTitlesError || !isDirty || isRollUp || isDisabled}
                >
                  {isChecking ? (
                    <>
                      <span style={{marginRight: '6px'}}>{PUBLICATION_ACTION_BUTTON_CONSTS.checkingMsg} </span>
                      <CircularProgress size='1rem' />
                    </>
                  ) : isPending ? (
                    <>
                      <span style={{marginRight: '6px'}}>{PUBLICATION_ACTION_BUTTON_CONSTS.savingMsg} </span>
                      <CircularProgress size='1rem' />
                    </>
                  ) : (
                    <span>{PUBLICATION_ACTION_BUTTON_CONSTS.saveMsg}</span>
                  )}
                </PublicationActionButtonBtn>
              </PublicationActionButtonControlTooltipWrapper>
            )}
          {([PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) ||
            publication?.status === PublicationStatus.Review ||
            publication?.status === PublicationStatus.Unpublished) &&
            keycloak.hasResourceRole('publish', 'publications') && (
              <ControlTooltip
                title=''
                disabledTitle={buttonDisabledTooltipMessage}
                enterDelay={500}
                disabled={disableForm || isRollUp}
                color={'default'}
                withoutTooltipIfActive={true}
              >
                <Tooltip title={isChecking ? PUBLICATION_ACTION_BUTTON_CONSTS.tooltipCheckingMsg : ''}>
                  <span>
                    <PublicationActionButtonBtn
                      variant='contained'
                      color='primary'
                      size='small'
                      onClick={publishPublicationAction}
                      disabled={disableForm || hasTitlesError || isDisabled || isRollUp}
                    >
                      {isChecking ? (
                        <>
                          <span style={{marginRight: '6px'}}>{PUBLICATION_ACTION_BUTTON_CONSTS.checkingMsg} </span>
                          <CircularProgress size='1rem' />
                        </>
                      ) : isPending ? (
                        <>
                          <span style={{marginRight: '6px'}}>{PUBLICATION_ACTION_BUTTON_CONSTS.savingMsg} </span>
                          <CircularProgress size='1rem' />
                        </>
                      ) : (
                        <span>{PUBLICATION_ACTION_BUTTON_CONSTS.publishMsg}</span>
                      )}
                    </PublicationActionButtonBtn>
                  </span>
                </Tooltip>
              </ControlTooltip>
            )}
        </PublicationActionButtonFieldsButtons>
      </PublicationActionButtonFields>
    </PublicationActionButtonWrapper>
  )
}

export default observer(PublicationActionButton)
