import {makeAutoObservable} from 'mobx'
import {SortOrder} from '../../types/SortOrder'
import ITableStore from './ITableStore'

class TableStore implements ITableStore {
  sortFieldName = 'publishedAtDate'
  sortDirection: SortOrder = SortOrder.Desc

  constructor() {
    makeAutoObservable(this)
  }

  setSortFieldName = (value: string): void => {
    this.sortFieldName = value
  }

  setSortDirection = (value: SortOrder): void => {
    this.sortDirection = value
  }
}

export default TableStore
