import styled from 'styled-components'
import {StyledFormControl} from '@components/UI/styled'
import {lightGrayColor2, mainTextColor, whiteColor} from '@theme/vars'

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`
export const StyledFormControlWrapper = styled(StyledFormControl)`
  width: 360px;

  @media (max-width: 580px) {
    margin-left: 0px;
    margin-top: 20px;
    width: 100%;
  }
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 64vh;
  position: absolute;
  /* opacity: 0.5; */
  z-index: 1;
  background-color: ${whiteColor};
`

export const TableWrapper = styled.div`
  width: fit-content;
  margin-top: 25px;
  overflow-x: auto;
  max-width: calc(100% + 17px);

  @media screen and (max-width: 1287px) {
    max-width: calc(100% - 5px);
  }
`

export const TableHeadRow = styled.div`
  background-color: #f1f6ff;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid ${lightGrayColor2};
  border-bottom: 1px solid ${lightGrayColor2};
  color: ${mainTextColor};
  display: flex;
  flex-direction: row;
`

export const TableHeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
`
