import React, {FC, useCallback, useRef} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {LinearProgress} from '@mui/material'
import {ReportListTableBodyProps} from './ReportListTableBodyProps'
import {useStore} from '@stores/rootStoreContext'
import {ReportListTableBodyFields} from '../ReportListTableBodyFields/ReportListTableBodyFields'
import {observer} from 'mobx-react-lite'
import {REPORT_LIST_TABLE_BODY_CONSTS} from './ReportListTableBody.consts'
import {NothingFounded} from '@components/UI/NothingFounded/NothingFounded'
import {Spinner} from '@components/UI/Spinner/Spinner'
import {SpinnerWrapper} from './ReportListTableBody.styles'

/**
 * Компонент отрисовки тела таблицы отчетов
 * @param param0
 * @returns
 */
const ReportListTableBody: FC<ReportListTableBodyProps> = ({
  fields,
  publicationList,
  dataLength,
  hasMore,
  fetchMore,
  setScrollPosition
}) => {
  const ref = useRef<any>(null)
  const {reportsListStore} = useStore()
  const {loadingExport, exportProgress, loadingData, isEmptyList} = reportsListStore

  const onScroll = useCallback(val => {
    if (val) {
      setScrollPosition(val.scrollTop)
    }
  }, [])

  return (
    <>
      {loadingExport && exportProgress > 0 && (
        <SpinnerWrapper>
          <Spinner title={REPORT_LIST_TABLE_BODY_CONSTS.reportExport} progress={exportProgress} />
        </SpinnerWrapper>
      )}
      {!isEmptyList ? (
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchMore}
          hasMore={hasMore === true}
          loader={loadingData && <LinearProgress color='primary' />}
          height='50vh'
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            scrollBehavior: 'smooth',
            width: '1258px',
            marginBottom: '10px'
          }}
          ref={ref}
          onScroll={e => onScroll(e.target)}
        >
          <ReportListTableBodyFields fields={fields} publicationList={publicationList} />
          {/* <ScrollToTopButton refContainer={ref} pos={scrollPosition} /> */}
        </InfiniteScroll>
      ) : (
        <NothingFounded
          texts={[REPORT_LIST_TABLE_BODY_CONSTS.nothingFounded, REPORT_LIST_TABLE_BODY_CONSTS.tryChangeRequestParams]}
        />
      )}
    </>
  )
}

export default observer(ReportListTableBody)
