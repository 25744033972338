import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {AccordionDetails, CircularProgress} from '@mui/material'
import {FC} from 'react'
import {observer} from 'mobx-react-lite'
import {Videocam} from '@mui/icons-material'
import {useKeycloak} from '@react-keycloak/web'
import {toast} from 'react-toastify'
import {useFormContext} from 'react-hook-form'
import {useUpdatePublicationExportMutation} from '@components/Publication/Form/gql/PublicationEditor.generated'
import UploadVideoModal from '@components/UI/UploadVideoModal/UploadVideoModal'
import {PublicationYandexNewsVideo, PublicationYandexNewsVideoInput} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {VideoBlot} from '@components/TextEditor/Blots/VideoBlot/Video'
import {mainTextColor, whiteColor} from '../../../../@theme/vars'
import {
  AccordionSummaryWrapper,
  AccordionWrapper,
  YandexVideosAddButton,
  YandexVideosFooterWrapper,
  YandexVideosTitleWrapper
} from './YandexVideos.styles'
import {YANDEX_VIDEOS_CONSTS} from './YandexVideos.consts'
import {YandexVideosList} from './YandexVideosList/YandexVideosList'

export const YandexVideos: FC<any> = observer(() => {
  const {keycloak} = useKeycloak()
  const {publicationStore} = useStore()
  const {publication, setPublicationWithPrevState} = publicationStore
  const {setValue} = useFormContext()
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false)
  const [videos, setVideos] = useState<PublicationYandexNewsVideo[]>([])
  const [currentVideos, setCurrentVideos] = useState<PublicationYandexNewsVideo[]>([])
  const cantEditPublication = useMemo(
    () => publication?.status === 'PUBLISHED' && !keycloak.hasResourceRole('edit-published', 'publications'),

    [keycloak, publication?.status]
  )
  const [expanded, setExpanded] = React.useState('yandex-videos-accordion')
  const [updateExport] = useUpdatePublicationExportMutation()
  const [currentVideo, setCurrentVideo] = useState<PublicationYandexNewsVideo>()
  const [videoForIncluding, setVideoForIncluding] = useState<PublicationYandexNewsVideo>()
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const [yandexVideosLoading, setYandexVideosLoading] = useState(false)
  const [upd, setUpd] = useState(false)
  const [currIncludeInNews, setCurrIncludeInNews] = useState(false)

  useEffect(() => {
    setCurrentVideos(videos)
  }, [videos])

  useEffect(() => {
    setVideos(publication?.yandexNewsVideos || [])
  }, [publication?.yandexNewsVideos])

  const toggleVideoModal = useCallback(() => {
    setCurrentVideo({} as PublicationYandexNewsVideo)
    setIsOpenVideoModal(!isOpenVideoModal)
  }, [isOpenVideoModal])

  const dataToSend = useCallback((videos: PublicationYandexNewsVideo[]): PublicationYandexNewsVideoInput[] => {
    return (
      videos?.map(video => {
        return {
          uri: video.uri,
          title: video.title,
          thumbnailUid: video.thumbnail?.uid
        } as PublicationYandexNewsVideoInput
      }) || []
    )
  }, [])

  const updatePublicationExport = useCallback(
    async (isDeleting = false, includeInNews = false, videosAfterDeleting?: PublicationYandexNewsVideoInput[]) => {
      const yandexNewsVideos = isDeleting === false ? dataToSend(currentVideos) : videosAfterDeleting
      if (includeInNews === true) {
        const insertVideoInEditorEvent = new CustomEvent('insertVideoInEditor', {
          detail: {
            yandexNewsVideo: {
              id: videoForIncluding?.id || '',
              src: videoForIncluding?.uri || '',
              title: videoForIncluding?.title || '',
              allow: ''
            } as VideoBlot
          },
          bubbles: true
        })

        window.dispatchEvent(insertVideoInEditorEvent)
      }

      setYandexVideosLoading(true)

      const newVideo = await updateExport({
        variables: {
          data: {
            publicationUid: publication.uid,
            yandexNewsVideos: yandexNewsVideos
          }
        }
      })
      setValue('yandexNewsVideos', newVideo.data?.updatePublicationExport?.publication?.yandexNewsVideos)
      setYandexVideosLoading(false)
      setUpd(false)

      if (!newVideo?.errors?.length) {
        setPublicationWithPrevState(prev => ({
          ...prev,
          yandexNewsVideos:
            (newVideo.data?.updatePublicationExport?.publication?.yandexNewsVideos as PublicationYandexNewsVideo[]) ||
            []
        }))

        const successMsg = isDeleting
          ? YANDEX_VIDEOS_CONSTS.yandexVideoDeletedMsg
          : YANDEX_VIDEOS_CONSTS.yandexVideoSavedMsg
        toast.success(successMsg, {
          autoClose: 1000,
          hideProgressBar: true,
          containerId: 'success'
        })
      }
    },
    [updateExport, setPublicationWithPrevState, publication.uid, dataToSend, setValue, videoForIncluding, currentVideos]
  )

  useEffect(() => {
    if (upd) {
      const timer = setTimeout(() => updatePublicationExport(false, currIncludeInNews, []), 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [updatePublicationExport, currIncludeInNews, upd])

  const deleteYandexVideo = useCallback(
    (uid: string) => {
      const newVideos = videos.filter(video => video.uid !== uid)
      setCurrentVideos(newVideos || [])
      const videosAfterDeleting = dataToSend(newVideos)
      updatePublicationExport(true, false, videosAfterDeleting)
    },
    [videos, updatePublicationExport, dataToSend]
  )

  const editYandexVideo = useCallback(
    uid => {
      const publicationYandexvideos = currentVideos || []
      const currentYandexVideo = publicationYandexvideos.find(item => item.uid === uid)
      if (currentYandexVideo) {
        toggleVideoModal()
        setCurrentVideo(currentYandexVideo)
      }
    },
    [setCurrentVideo, toggleVideoModal, currentVideos]
  )

  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <AccordionWrapper
        square
        expanded={expanded === 'yandex-videos-accordion'}
        onChange={handleChange('yandex-videos-accordion')}
        disabled
      >
        <AccordionSummaryWrapper aria-controls='yandex-videos-accordion-content' id='yandex-videos-accordion-header'>
          <YandexVideosTitleWrapper>
            {YANDEX_VIDEOS_CONSTS.yandexVideoMsg}
            {yandexVideosLoading === false && publication?.yandexNewsVideos?.length > 0 && (
              <span>({publication?.yandexNewsVideos?.length})</span>
            )}
            {yandexVideosLoading === true && <CircularProgress size='1rem' />}
          </YandexVideosTitleWrapper>
        </AccordionSummaryWrapper>
        <AccordionDetails
          style={{
            backgroundColor: whiteColor
          }}
        >
          <YandexVideosList deleteYandexVideo={deleteYandexVideo} editYandexVideo={editYandexVideo} />
        </AccordionDetails>
        <YandexVideosFooterWrapper>
          <YandexVideosAddButton
            variant='outlined'
            id='add-new-yandex-video'
            disabled={cantEditPublication}
            onClick={toggleVideoModal}
          >
            <Videocam />
            <span>&nbsp;</span>
            <span
              style={{
                color: mainTextColor
              }}
            >
              {YANDEX_VIDEOS_CONSTS.addYandexVideoMsg}
            </span>
          </YandexVideosAddButton>
        </YandexVideosFooterWrapper>
      </AccordionWrapper>
      <UploadVideoModal
        open={isOpenVideoModal}
        onClose={toggleVideoModal}
        title={YANDEX_VIDEOS_CONSTS.videoSettingsMsg}
        okLabel={YANDEX_VIDEOS_CONSTS.saveMsg}
        video={currentVideo}
        setVideos={setVideos}
        setVideoForIncluding={setVideoForIncluding}
        setUpd={setUpd}
        setCurrIncludeInNews={setCurrIncludeInNews}
      />
    </div>
  )
})
