import {Region} from '@graphql/types'
import React, {FC, useEffect, useState} from 'react'
import {useRegionsLazyQuery} from '../../gql/PublicationEditor.generated'
import RHFSelect from '../../RHFSelect/RHFSelect'

type RegionsSelectProps = {
  width?: string
  setRegions?: (val: Region[]) => void
}
const RegionsSelect: FC<RegionsSelectProps> = ({width, setRegions}) => {
  const [getRegions, {data}] = useRegionsLazyQuery()

  useEffect(() => {
    setRegions && setRegions((data?.regions as Region[]) || [])
  }, [data, setRegions])

  return (
    <RHFSelect
      name='regionUids'
      label='Регионы'
      width={width || '360px'}
      getItems={getRegions}
      items={data?.regions || []}
      multiply
      secondName='regions'
    />
  )
}

export default RegionsSelect
