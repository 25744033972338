import {Figure, FigureVersion, GalleryImage} from '@graphql/types'

export const GALLERY_EDITOR_CONSTS = {
  cancel: 'Отмена',
  closeConfirmationText: 'Вы уверены, что хотите закрыть окно?',
  closeConfirmBtn: 'Да, закрыть',
  fillRequiredFields: 'Заполните обязательные поля',
  firstSentenceText: 'Внесенные изменения не сохранятся.',
  from: 'из',
  galleryLink: 'Ссылка на галерею',
  hideUploadPanel: 'Скрыть панель загрузки',
  makeChangeMsg: 'Нет изменений для сохранения',
  needToAddCoupleOfPhoto: 'Нужно добавить хотя бы пару фотографий',
  pasteGallery: 'Вставить галерею',
  photo: 'Фото',
  showUploadPanel: 'Показать панель загрузки',
  title: 'Вставка галереи',
  uploadGallery: 'Загрузить галерею',
  warningMsg: 'Внимание',
  galleryBlocks: [
    {
      id: '1',
      description: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории',
      author: 'Сергей Русанов © URA.RU',
      figureVersion: {
        id: '2c38c8db-be96-4bfc-a988-44842fe9c2dd',
        uid: '2c38c8db-be96-4bfc-a988-44842fe9c2dd',
        primaryProcessedImage: {
          url: 'https://dev3.uranews.xyz/static/figures/720/786/82a6af27-57bb-4e22-b372-6117ca00ccf2/w7sffd/M-760.1.8.jpg'
        },
        figure: {
          alt: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории',
          uid: '82a6af27-57bb-4e22-b372-6117ca00ccf2',
          sourceImage: {
            url: 'https://dev3.uranews.xyz/static/figures/720/786/82a6af27-57bb-4e22-b372-6117ca00ccf2/2cy6dq-source.jpg'
          }
        } as Figure
      } as FigureVersion
    } as GalleryImage,
    {
      id: '2',
      description: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории',
      author: 'Сергей Русанов © URA.RU',
      figureVersion: {
        id: '0981d4c8-65a3-4cff-b8af-007f676da743',
        uid: '0981d4c8-65a3-4cff-b8af-007f676da743',
        primaryProcessedImage: {
          url: 'https://dev3.uranews.xyz/static/figures/540/98/edd28e43-ca06-483e-9919-e6f6d4cbd18a/xbob5x/M-760.1.5.jpg'
        },
        figure: {
          alt: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории',
          uid: 'edd28e43-ca06-483e-9919-e6f6d4cbd18a',
          sourceImage: {
            url: 'https://dev3.uranews.xyz/static/figures/540/98/edd28e43-ca06-483e-9919-e6f6d4cbd18a/iabz5m-source.jpg'
          }
        } as Figure
      } as FigureVersion
    } as GalleryImage,
    {
      id: '3',
      description: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории',
      author: 'Сергей Русанов © URA.RU',
      figureVersion: {
        id: '08817e69-32b1-47f4-9a47-20a5c3b149e8',
        uid: '08817e69-32b1-47f4-9a47-20a5c3b149e8',
        primaryProcessedImage: {
          url: 'https://dev3.uranews.xyz/static/figures/227/440/2f0dfb96-7335-4f6e-a6dd-cd1aed356fd7/cwqvxj/M-760.0.8.jpg'
        },
        figure: {
          alt: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории',
          uid: '2f0dfb96-7335-4f6e-a6dd-cd1aed356fd7',
          sourceImage: {
            url: 'https://dev3.uranews.xyz/static/figures/227/440/2f0dfb96-7335-4f6e-a6dd-cd1aed356fd7/59qisu-source.jpg'
          }
        } as Figure
      } as FigureVersion
    } as GalleryImage

    // {
    //   id: '1',
    //   uid: '1',
    //   figureVersion: {
    //     id: '2c38c8db-be96-4bfc-a988-44842fe9c2dd',
    //     uid: '2c38c8db-be96-4bfc-a988-44842fe9c2dd',
    //     primaryProcessedImage: {
    //       url: 'https://dev3.uranews.xyz/static/figures/720/786/82a6af27-57bb-4e22-b372-6117ca00ccf2/w7sffd/M-760.1.8.jpg'
    //     }
    //   } as FigureVersion,
    //   description: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории',
    //   author: 'Сергей Русанов © URA.RU',
    //   alt: 'Главной особенностью фестиваля в 2024 году стал формат творческой лаборатории'
    // },
    // {
    //   id: '2',
    //   uid: '2',
    //   figureVersion: {
    //     id: '0981d4c8-65a3-4cff-b8af-007f676da743',
    //     uid: '0981d4c8-65a3-4cff-b8af-007f676da743',
    //     primaryProcessedImage: {
    //       url: 'https://dev3.uranews.xyz/static/figures/540/98/edd28e43-ca06-483e-9919-e6f6d4cbd18a/xbob5x/M-760.1.5.jpg'
    //     }
    //   } as FigureVersion,
    //   description: 'При покупке квартиры рассрочку в ЖК "Крылов" первый платеж составит 10% от стоимости недвижимости',
    //   author: 'ДСК-1',
    //   alt: 'Дома/новостройки'
    // },
    // {
    //   id: '5',
    //   uid: '5',
    //   figureVersion: {
    //     id: '08817e69-32b1-47f4-9a47-20a5c3b149e8',
    //     uid: '08817e69-32b1-47f4-9a47-20a5c3b149e8',
    //     primaryProcessedImage: {
    //       url: 'https://dev3.uranews.xyz/static/figures/227/440/2f0dfb96-7335-4f6e-a6dd-cd1aed356fd7/cwqvxj/M-760.0.8.jpg'
    //     }
    //   } as FigureVersion,
    //   description: 'тестовое описание 5',
    //   author: 'тестовый автор 5',
    //   alt: 'тестовое описание alt 5'
    // }

    // {
    //   id: '3',
    //   uid: '3',
    //   figureVersion: {
    //     primaryProcessedImage: {
    //       url: 'https://dev3.uranews.xyz/static/figures/654/657/0490c7d9-41e9-4880-99ab-18382177c59b/kf3ej8/M-760.1.5.jpg'
    //     }
    //   } as FigureVersion,
    //   description: 'тестовое описание 3',
    //   author: 'тестовый автор 3',
    //   alt: 'тестовое описание alt 3'
    // },
    // {
    //   id: '4',
    //   uid: '4',
    //   figureVersion: {
    //     primaryProcessedImage: {
    //       url: 'https://s.ura.news/images/news/upload/news/807/606/1052807606/847218_xxi_saezd_partii_Edinaya_Rossiya_Moskva_alaudinov_apti_250x0_3600.2406.0.0.jpg'
    //     }
    //   } as FigureVersion,
    //   description: 'тестовое описание 4',
    //   author: 'тестовый автор 4',
    //   alt: 'тестовое описание alt 4'
    // },
  ]
}
