import {Menu, Button as UIButton, MenuItem as UIMenuItem} from '@mui/material'
import styled from 'styled-components'

function filterProps(...args: string[]) {
  return p => args && !args.includes(p)
}
interface ToolbarButtonProps {
  ql: string

  content: string
}

export const StyledMenu = styled(Menu)
  .withConfig({
    shouldForwardProp: filterProps('ql')
  })
  .attrs<Pick<ToolbarButtonProps, 'ql'>>(({ql, className}) => ({
    className: [`ql-${ql}`, className].join(' ').trim()
  }))<{$width?: string; ql?: string}>`
    margin-left: 12px;
    &.MuiInput-underline:before {
        display: none;
    }
`

export const MenuItem = styled(UIMenuItem)`
  padding: 0 !important;
`
export const Button = styled(UIButton)`
  width: 100%;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 1.5;
  color: black;
  font-weight: 400;
  font-family: Roboto;
`

export const TitleToast = styled.span`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
`
