import {FC, useCallback} from 'react'
import {Quill} from 'react-quill'
import {toast} from 'react-toastify'
import {useStore} from '@stores/rootStoreContext'
import {getQuillEditor} from '@utils/getQuillEditor'
import {fetchRewordText, fetchRewriteText} from '@fetch/NnAPI'
import {Button, MenuItem} from '../ToolbarNn.styles'
import {TOOLBAR_BUTTONS_TITLES} from '../ToolbarNn.consts'
import type {RangeStatic} from 'quill'

const Delta = Quill.import('delta')

type Props = {
  editorRef: React.MutableRefObject<any>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isRewriting: boolean
  handleClose: () => void
}

const RewriteTextButton: FC<Props> = ({editorRef, setIsLoading, isRewriting, handleClose}) => {
  const {publicationStore} = useStore()

  const handleRewordText = useCallback(async () => {
    handleClose()
    const quill = getQuillEditor(editorRef)
    let range: RangeStatic | undefined

    if (quill) {
      range = quill?.getSelection(true)
    }

    if (quill && range) {
      const {publication} = publicationStore
      const publicationId = publication?.deprecatedId
      const publicationUid = publication?.id
      const {index, length} = range
      let delta = quill.getContents()
      let full_text = ''

      delta.forEach(item => {
        if (typeof item.insert === 'string') {
          full_text += item.insert
          full_text += ' '
        }
      })
      delta = quill.getContents(index, length)
      const selectingText = delta?.ops?.reduce((string, op) => string + op.insert, '')

      if (!selectingText) {
        toast.info(TOOLBAR_BUTTONS_TITLES.notSelectionText, {
          containerId: 'success',
          autoClose: 10000,
          hideProgressBar: true
        })
        return setIsLoading(false)
      }

      const data = {text: selectingText, publicationId, publicationUid, full_text}
      setIsLoading(true)

      try {
        const rewriteTextJson = isRewriting ? await fetchRewordText(data) : await fetchRewriteText(data)
        if (!rewriteTextJson) {
          console.error('Не удалось получить данные от сервера.')
          return
        }
        const text = rewriteTextJson.text

        quill.updateContents(
          index !== 0
            ? new Delta({
                ops: [
                  {
                    retain: index
                  },
                  {
                    delete: length
                  },
                  {
                    insert: text
                  }
                ]
              })
            : new Delta({
                ops: [
                  {
                    delete: length
                  },
                  {
                    insert: text
                  }
                ]
              }),
          'user'
        )
      } catch (error) {
        console.error(error)
        //   enqueueSnackbar(`Error: ${error.message}`, {variant: 'error', autoHideDuration: 10000})
      } finally {
        setIsLoading(false)
      }
    }
  }, [editorRef, publicationStore, setIsLoading, handleClose, isRewriting])

  return (
    <MenuItem>
      <Button onClick={handleRewordText}>
        {isRewriting ? TOOLBAR_BUTTONS_TITLES.rewriteText.rewrite : TOOLBAR_BUTTONS_TITLES.rewriteText.overwrite}
      </Button>
    </MenuItem>
  )
}

export default RewriteTextButton
