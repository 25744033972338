import React, {FC} from 'react'

const TextIcon: FC<any> = () => {
    return (
        <svg
            width='12'
            height='14'
            viewBox='0 0 12 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M0 3.33333V0H11.6667V3.33333H7.5V13.3333H4.16667V3.33333H0Z'
                fill='#222222'
            />
        </svg>
    )
}

export default TextIcon
