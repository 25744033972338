import {FC, useCallback} from 'react'
import {Typography} from '@mui/material'
import {useHistory} from 'react-router'
import {grayBorder} from '@theme/vars'
import {LastPublicationCommentItemProps} from './LastPublicationCommentItemProps'
import {TableCellWrapper, TableRowWrapper} from './LastPublicationCommentItem.styles'
import {LAST_PUBLICATION_COMMENT_ITEM} from './LastPublicationCommentItem.consts'

const LastPublicationCommentItem: FC<LastPublicationCommentItemProps> = ({item}) => {
  const history = useHistory()

  const handleToPublicationComments = useCallback(() => {
    history.push(`/comments/last-publication-comments/${item.publication?.uid}`)
  }, [history, item.publication?.uid])

  return (
    <TableRowWrapper onClick={handleToPublicationComments}>
      <TableCellWrapper style={{width: '121px'}}>
        <Typography variant='text14R'>
          {item.publication?.typeSlug === 'news'
            ? LAST_PUBLICATION_COMMENT_ITEM.news
            : LAST_PUBLICATION_COMMENT_ITEM.article}
        </Typography>
      </TableCellWrapper>
      <TableCellWrapper style={{width: '880px'}}>
        <Typography
          variant='text14R'
          className={item.publication?.isAutomaticCommentsDisabled === true ? 'is-automatic-comments-disabled' : ''}
        >
          {item.publication?.title || LAST_PUBLICATION_COMMENT_ITEM.errorInData}
        </Typography>
      </TableCellWrapper>
      <TableCellWrapper style={{width: '143px'}} className='comment-count'>
        <Typography variant='text14R' className='new-comments'>
          {item.newComments}
        </Typography>
      </TableCellWrapper>
      <TableCellWrapper style={{width: '119px', borderRight: `1px solid ${grayBorder}`}} className='comment-count'>
        <Typography variant='text14R'>{item.allComments}</Typography>
      </TableCellWrapper>
    </TableRowWrapper>
  )
}

export default LastPublicationCommentItem
