export const clearRemovedUrlsPersons = (publicationId: string): void => {
  const removedPersonsKey = `urlsPersons_${publicationId}`
  localStorage.removeItem(removedPersonsKey)
}

export const getPersonsUrls = (publicationId: string) => {
  const urlsPersonsKey = `urlsPersons_${publicationId}`
  const urlsPersons = JSON.parse(localStorage.getItem(urlsPersonsKey)) || []
  return urlsPersons
}

export const addPersonsUrls = (publicationId: string, url: string): void => {
  const urlsPersonsKey = `urlsPersons_${publicationId}`
  const urlsPersons = getPersonsUrls(publicationId)

  if (!urlsPersons.includes(url)) {
    urlsPersons.push(url)
    localStorage.setItem(urlsPersonsKey, JSON.stringify(urlsPersons))
  }
}
