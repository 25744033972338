import React, {FC} from 'react'
import {SvgIcon, SvgIconProps} from '@mui/material'

const InfoWarningIcon: FC<any> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.466 8.33366H17.5327V11.4003H14.466V8.33366ZM14.466 14.467H17.5327V23.667H14.466V14.467ZM15.9993 0.666992C7.53535 0.666992 0.666016 7.53633 0.666016 16.0003C0.666016 24.4643 7.53535 31.3337 15.9993 31.3337C24.4633 31.3337 31.3327 24.4643 31.3327 16.0003C31.3327 7.53633 24.4633 0.666992 15.9993 0.666992ZM15.9993 28.267C9.23735 28.267 3.73268 22.7623 3.73268 16.0003C3.73268 9.23833 9.23735 3.73366 15.9993 3.73366C22.7613 3.73366 28.266 9.23833 28.266 16.0003C28.266 22.7623 22.7613 28.267 15.9993 28.267Z'
        fill='#EB3846'
      />
    </svg>
  </SvgIcon>
)

export default InfoWarningIcon
