import React, {FC, useCallback, useEffect, useState} from 'react'
import ArrowUpIcon from '../Icons/ArrowUpIcon'
import {ScrollToTopBtn} from './ScrollToTopButton.styles'

export type ScrollToTopButtonProps = {
  refContainer: any
  pos: number
}

const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({refContainer, pos}) => {
  const [opacity, setOpacity] = useState('1')

  useEffect(() => {
    setOpacity(pos > 50 ? '1' : '0')
  }, [pos])

  const handleTop = useCallback(() => {
    if (refContainer?.current?.scrollTop) {
      refContainer.current.scrollTop = 0
    }
    if (refContainer.current?.el) {
      refContainer.current.el.scrollTop = 0
    }
  }, [refContainer])

  return (
    <ScrollToTopBtn
      onClick={handleTop}
      id='scroll-to-top-btn'
      style={{
        opacity: opacity
      }}
    >
      <ArrowUpIcon width={30} height={30} />
    </ScrollToTopBtn>
  )
}

export default ScrollToTopButton
