import styled, {css} from 'styled-components'
import {Dialog} from '@mui/material'
import {FC} from 'react'
import {CONFIRMATION_MODE, ModalMode} from '../../../types/ModalMode'
import {SimpleModalProps} from './SimpleModal'

export const StyledDialog: FC<SimpleModalProps> = styled(Dialog).attrs(() => ({
  classes: {
    paper: 'paper'
  }
}))<{mode?: ModalMode}>`
  & .paper {
    padding-bottom: 15px;
    overflow-y: unset;
    width: ${props => (props.mode === CONFIRMATION_MODE ? css`450px` : css`720px`)};

    @media screen and (max-width: 960px) {
      margin: 0;
      width: 100%;
    }
  }

  & .paper > div {
    overflow-x: hidden;

    @media screen and (max-width: 960px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media screen and (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`
