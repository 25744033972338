import {fetchInstanse, fetchInstanseForDialogNer, handleRequest} from '../ky-client'
import {
  BackWithRegionsRequest,
  CheckTextRequest,
  InoagentResponse,
  KeywordsResponse,
  NerDialogRequest,
  NerTitleRequest,
  NerToolbarRequest,
  PersonResponse
} from './NnAPI.types'

//TODO Типизация ответов
export const fetchAutoKeywords = async (data: NerToolbarRequest, signal: AbortSignal): Promise<KeywordsResponse> => {
  return await fetchInstanse
    .post('keywords/', {
      json: data,
      signal: signal
    })
    .json()
}

export const fetchPersons = async (data: NerToolbarRequest, signal: AbortSignal): Promise<PersonResponse> => {
  return await fetchInstanse
    .post('person/', {
      json: data,
      signal: signal
    })
    .json()
}

// Запрос для проверки агентов
export const fetchCheckInoagents = async (data: NerToolbarRequest): Promise<InoagentResponse> => {
  return await fetchInstanse(`ner/`, {
    method: 'POST',
    json: data,
    timeout: 12000
  }).json()
}

// Запрос на генерацию бека
export const fetchGenerateBack = async (data: NerToolbarRequest) => {
  return await handleRequest('back/', {
    method: 'POST',
    json: data
  })
}

// Запрос на генерацию бека
export const fetchGenerateBackWithRegions = async (data: BackWithRegionsRequest) => {
  return await handleRequest('back/back_regions/', {
    method: 'POST',
    json: data
  })
}

// Запрос на генерацию текста по теме
export const fetchGenerateText = async (data: NerDialogRequest, signal: AbortSignal): Promise<string> => {
  return await fetchInstanseForDialogNer
    .post('generate/', {
      json: data,
      signal
    })
    .text()
}

// Запрос на переписывание текста
export const fetchRephraseText = async (data: NerDialogRequest, signal: AbortSignal): Promise<string> => {
  return await fetchInstanseForDialogNer
    .post('rephrase/', {
      json: data,
      signal
    })
    .text()
}

// Запрос для рерайта текста
export const fetchRewriteText = async (data: NerDialogRequest, signal: AbortSignal): Promise<string> => {
  return await fetchInstanseForDialogNer
    .post('rewrite/', {
      json: data,
      signal
    })
    .text()
}

export const fetchAutoTitle = async (data: NerTitleRequest) => {
  return await handleRequest('title/', {
    method: 'POST',
    json: data
  })
}

// Запрос для текст.ру
export const fetchCheckText = async (data: CheckTextRequest) => {
  try {
    return await fetchInstanse.post('textru/', {
      json: data
    })
  } catch (e) {
    console.error(e)
  }
}
