import * as Types from '../../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type PublicationsQueryVariables = Types.Exact<{
  status?: Types.Maybe<Types.PublicationStatus>
  typeSlug?: Types.Maybe<Types.Scalars['String']>
  authorUid?: Types.Maybe<Types.Scalars['UUID']>
  after?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
  datePublished?: Types.Maybe<Types.Scalars['DateTime']>
  regionUid?: Types.Maybe<Types.Scalars['UUID']>
  search?: Types.Maybe<Types.Scalars['String']>
  sort?: Types.Maybe<Types.PublicationsSort>
}>

export type PublicationsQuery = {__typename?: 'Query'} & {
  publications?: Types.Maybe<
    {__typename?: 'PublicationsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'PublicationsEdge'} & Pick<Types.PublicationsEdge, 'cursor'> & {
              node?: Types.Maybe<{__typename?: 'Publication'} & PublicationNodeFragment>
            }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
    }
  >
}

export type GetPublicationsWithCommentsCountQueryVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>
  first?: Types.Maybe<Types.Scalars['Int']>
  search?: Types.Maybe<Types.Scalars['String']>
  sort?: Types.Maybe<Types.PublicationsSort>
  status?: Types.Maybe<Types.PublicationStatus>
}>

export type GetPublicationsWithCommentsCountQuery = {__typename?: 'Query'} & {
  publications?: Types.Maybe<
    {__typename?: 'PublicationsConnection'} & {
      edges: Array<
        Types.Maybe<
          {__typename?: 'PublicationsEdge'} & Pick<Types.PublicationsEdge, 'cursor'> & {
              node?: Types.Maybe<
                {__typename?: 'Publication'} & Pick<
                  Types.Publication,
                  'isAutomaticCommentsDisabled' | 'status' | 'title' | 'typeSlug' | 'uid'
                >
              >
            }
        >
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    }
  >
}

export type SetImportantMutationVariables = Types.Exact<{
  publicationUid: Types.Scalars['UUID']
  isImportant?: Types.Maybe<Types.Scalars['Boolean']>
}>

export type SetImportantMutation = {__typename?: 'Mutation'} & {
  updatePublication?: Types.Maybe<
    {__typename?: 'UpdatePublicationPayload'} & {
      publication?: Types.Maybe<{__typename?: 'Publication'} & Pick<Types.Publication, 'uid' | 'isImportant'>>
    }
  >
}

export type SetDestinationMutationVariables = Types.Exact<{
  publicationUid: Types.Scalars['UUID']
  destinationSlugs?: Types.Maybe<Array<Types.Scalars['String']> | Types.Scalars['String']>
}>

export type SetDestinationMutation = {__typename?: 'Mutation'} & {
  updatePublicationExport?: Types.Maybe<
    {__typename?: 'UpdatePublicationExportPayload'} & {
      publication?: Types.Maybe<
        {__typename?: 'Publication'} & Pick<Types.Publication, 'uid'> & {
            destinations?: Types.Maybe<
              Array<Types.Maybe<{__typename?: 'Destination'} & Pick<Types.Destination, 'uid' | 'slug'>>>
            >
          }
      >
    }
  >
}

export type PublicationNodeFragment = {__typename?: 'Publication'} & Pick<
  Types.Publication,
  | 'id'
  | 'uid'
  | 'badwords'
  | 'title'
  | 'publishedAt'
  | 'createdAt'
  | 'publishedAtDate'
  | 'status'
  | 'isImportant'
  | 'sendToRambler'
  | 'hasOverviewBlock'
  | 'hasTopNewsBlock'
  | 'specProjectUrl'
  | 'deprecatedId'
  | 'typeSlug'
> & {
    destinations?: Types.Maybe<
      Array<Types.Maybe<{__typename?: 'Destination'} & Pick<Types.Destination, 'uid' | 'title' | 'slug'>>>
    >
    regions?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Region'} & Pick<Types.Region, 'uid' | 'title'>>>>
    authors: Array<{__typename?: 'Author'} & Pick<Types.Author, 'uid' | 'firstName' | 'lastName'>>
    yandexNewsVideos: Array<{__typename?: 'PublicationYandexNewsVideo'} & Pick<Types.PublicationYandexNewsVideo, 'uri'>>
    yandexZenCategories: Array<{__typename?: 'YandexZenCategory'} & Pick<Types.YandexZenCategory, 'uid' | 'title'>>
  }

export type AuthorsFilterQueryVariables = Types.Exact<{
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
}>

export type AuthorsFilterQuery = {__typename?: 'Query'} & {
  staff?: Types.Maybe<
    {__typename?: 'UsersConnection'} & {
      edges: Array<
        Types.Maybe<{__typename?: 'UsersEdge'} & Pick<Types.UsersEdge, 'cursor'> & AuthorsFilterNodeFragment>
      >
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
    }
  >
}

export type AuthorsFilterNodeFragment = {__typename?: 'UsersEdge'} & {
  node?: Types.Maybe<{__typename?: 'User'} & Pick<Types.User, 'uid' | 'firstname' | 'lastname'>>
}

export type AddToTopNewsMutationVariables = Types.Exact<{
  data: Types.AddPublicationToTopNewsFromPublicationsListInput
}>

export type AddToTopNewsMutation = {__typename?: 'Mutation'} & {
  addPublicationToTopNewsFromPublicationsList?: Types.Maybe<
    {__typename?: 'AddPublicationToTopNewsFromPublicationsListPayload'} & Pick<
      Types.AddPublicationToTopNewsFromPublicationsListPayload,
      'status'
    >
  >
}

export type AddToOverviewMutationVariables = Types.Exact<{
  data: Types.AddPublicationToOverviewFromPublicationsListInput
}>

export type AddToOverviewMutation = {__typename?: 'Mutation'} & {
  addPublicationToOverviewFromPublicationsList?: Types.Maybe<
    {__typename?: 'AddPublicationToOverviewFromPublicationsListPayload'} & Pick<
      Types.AddPublicationToOverviewFromPublicationsListPayload,
      'status'
    >
  >
}

export type FigureFragment = {__typename?: 'Figure'} & Pick<
  Types.Figure,
  | 'id'
  | 'uid'
  | 'src'
  | 'alt'
  | 'author'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'hasWatermark'
  | 'hasDiagonalWatermark'
> & {
    type: {__typename?: 'FigureType'} & Pick<Types.FigureType, 'id' | 'uid' | 'slug'>
    cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
    latestVersion: {__typename?: 'FigureVersion'} & Pick<Types.FigureVersion, 'id' | 'uid'> & {
        crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
        primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
        processedImages: Array<{__typename?: 'Image'} & Pick<Types.Image, 'code' | 'url'>>
      }
  }

export type FigureVersionFragmentFragment = {__typename?: 'FigureVersion'} & Pick<
  Types.FigureVersion,
  'id' | 'uid' | 'hasWatermark' | 'hasDiagonalWatermark'
> & {
    primaryProcessedImage?: Types.Maybe<{__typename?: 'Image'} & Pick<Types.Image, 'url'>>
    crop: {__typename?: 'Crop'} & Pick<Types.Crop, 'left' | 'top' | 'width' | 'height'>
    figure: {__typename?: 'Figure'} & Pick<Types.Figure, 'id' | 'src' | 'uid'> & {
        cropperPreview: {__typename?: 'Image'} & Pick<Types.Image, 'url'>
      }
  }

export const PublicationNodeFragmentDoc = gql`
  fragment PublicationNode on Publication {
    id
    uid
    badwords
    title
    publishedAt
    createdAt
    publishedAtDate
    status
    isImportant
    sendToRambler
    hasOverviewBlock
    hasTopNewsBlock
    specProjectUrl
    deprecatedId
    typeSlug
    destinations {
      uid
      title
      slug
    }
    regions {
      uid
      title
    }
    authors {
      uid
      firstName
      lastName
    }
    yandexNewsVideos {
      uri
    }
    yandexZenCategories {
      uid
      title
    }
  }
`
export const AuthorsFilterNodeFragmentDoc = gql`
  fragment AuthorsFilterNode on UsersEdge {
    node {
      uid
      firstname
      lastname
    }
  }
`
export const FigureFragmentDoc = gql`
  fragment Figure on Figure {
    id
    uid
    src
    alt
    author
    description
    createdAt
    updatedAt
    hasWatermark
    hasDiagonalWatermark
    type {
      id
      uid
      slug
    }
    cropperPreview {
      url
    }
    latestVersion {
      id
      uid
      crop {
        left
        top
        width
        height
      }
      primaryProcessedImage {
        url
      }
      processedImages {
        code
        url
      }
    }
  }
`
export const FigureVersionFragmentFragmentDoc = gql`
  fragment FigureVersionFragment on FigureVersion {
    id
    uid
    hasWatermark
    hasDiagonalWatermark
    primaryProcessedImage {
      url
    }
    crop {
      left
      top
      width
      height
    }
    figure {
      id
      src
      uid
      cropperPreview {
        url
      }
    }
  }
`
export const PublicationsDocument = gql`
  query publications(
    $status: PublicationStatus
    $typeSlug: String
    $authorUid: UUID
    $after: String
    $first: Int
    $datePublished: DateTime
    $regionUid: UUID
    $search: String
    $sort: PublicationsSort
  ) {
    publications(
      sort: $sort
      limit: 30
      filter: {
        status: $status
        typeSlug: $typeSlug
        authorUid: $authorUid
        datePublished: $datePublished
        regionUid: $regionUid
        search: $search
      }
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          ...PublicationNode
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PublicationNodeFragmentDoc}
`

/**
 * __usePublicationsQuery__
 *
 * To run a query within a React component, call `usePublicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicationsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      typeSlug: // value for 'typeSlug'
 *      authorUid: // value for 'authorUid'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      datePublished: // value for 'datePublished'
 *      regionUid: // value for 'regionUid'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePublicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<PublicationsQuery, PublicationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<PublicationsQuery, PublicationsQueryVariables>(PublicationsDocument, options)
}
export function usePublicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicationsQuery, PublicationsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<PublicationsQuery, PublicationsQueryVariables>(PublicationsDocument, options)
}
export type PublicationsQueryHookResult = ReturnType<typeof usePublicationsQuery>
export type PublicationsLazyQueryHookResult = ReturnType<typeof usePublicationsLazyQuery>
export type PublicationsQueryResult = Apollo.QueryResult<PublicationsQuery, PublicationsQueryVariables>
export const GetPublicationsWithCommentsCountDocument = gql`
  query getPublicationsWithCommentsCount(
    $after: String
    $first: Int
    $search: String
    $sort: PublicationsSort
    $status: PublicationStatus
  ) {
    publications(sort: $sort, limit: 30, filter: {status: $status, search: $search}, first: $first, after: $after) {
      edges {
        cursor
        node {
          isAutomaticCommentsDisabled
          status
          title
          typeSlug
          uid
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

/**
 * __useGetPublicationsWithCommentsCountQuery__
 *
 * To run a query within a React component, call `useGetPublicationsWithCommentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicationsWithCommentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicationsWithCommentsCountQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetPublicationsWithCommentsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPublicationsWithCommentsCountQuery,
    GetPublicationsWithCommentsCountQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<GetPublicationsWithCommentsCountQuery, GetPublicationsWithCommentsCountQueryVariables>(
    GetPublicationsWithCommentsCountDocument,
    options
  )
}
export function useGetPublicationsWithCommentsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicationsWithCommentsCountQuery,
    GetPublicationsWithCommentsCountQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<GetPublicationsWithCommentsCountQuery, GetPublicationsWithCommentsCountQueryVariables>(
    GetPublicationsWithCommentsCountDocument,
    options
  )
}
export type GetPublicationsWithCommentsCountQueryHookResult = ReturnType<
  typeof useGetPublicationsWithCommentsCountQuery
>
export type GetPublicationsWithCommentsCountLazyQueryHookResult = ReturnType<
  typeof useGetPublicationsWithCommentsCountLazyQuery
>
export type GetPublicationsWithCommentsCountQueryResult = Apollo.QueryResult<
  GetPublicationsWithCommentsCountQuery,
  GetPublicationsWithCommentsCountQueryVariables
>
export const SetImportantDocument = gql`
  mutation setImportant($publicationUid: UUID!, $isImportant: Boolean) {
    updatePublication(data: {publicationUid: $publicationUid, isImportant: $isImportant}) {
      publication {
        uid
        isImportant
      }
    }
  }
`
export type SetImportantMutationFn = Apollo.MutationFunction<SetImportantMutation, SetImportantMutationVariables>

/**
 * __useSetImportantMutation__
 *
 * To run a mutation, you first call `useSetImportantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetImportantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setImportantMutation, { data, loading, error }] = useSetImportantMutation({
 *   variables: {
 *      publicationUid: // value for 'publicationUid'
 *      isImportant: // value for 'isImportant'
 *   },
 * });
 */
export function useSetImportantMutation(
  baseOptions?: Apollo.MutationHookOptions<SetImportantMutation, SetImportantMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SetImportantMutation, SetImportantMutationVariables>(SetImportantDocument, options)
}
export type SetImportantMutationHookResult = ReturnType<typeof useSetImportantMutation>
export type SetImportantMutationResult = Apollo.MutationResult<SetImportantMutation>
export type SetImportantMutationOptions = Apollo.BaseMutationOptions<
  SetImportantMutation,
  SetImportantMutationVariables
>
export const SetDestinationDocument = gql`
  mutation setDestination($publicationUid: UUID!, $destinationSlugs: [String!]) {
    updatePublicationExport(data: {publicationUid: $publicationUid, destinationSlugs: $destinationSlugs}) {
      publication {
        uid
        destinations {
          uid
          slug
        }
      }
    }
  }
`
export type SetDestinationMutationFn = Apollo.MutationFunction<SetDestinationMutation, SetDestinationMutationVariables>

/**
 * __useSetDestinationMutation__
 *
 * To run a mutation, you first call `useSetDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDestinationMutation, { data, loading, error }] = useSetDestinationMutation({
 *   variables: {
 *      publicationUid: // value for 'publicationUid'
 *      destinationSlugs: // value for 'destinationSlugs'
 *   },
 * });
 */
export function useSetDestinationMutation(
  baseOptions?: Apollo.MutationHookOptions<SetDestinationMutation, SetDestinationMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<SetDestinationMutation, SetDestinationMutationVariables>(SetDestinationDocument, options)
}
export type SetDestinationMutationHookResult = ReturnType<typeof useSetDestinationMutation>
export type SetDestinationMutationResult = Apollo.MutationResult<SetDestinationMutation>
export type SetDestinationMutationOptions = Apollo.BaseMutationOptions<
  SetDestinationMutation,
  SetDestinationMutationVariables
>
export const AuthorsFilterDocument = gql`
  query authorsFilter($first: Int, $after: String) {
    staff(limit: 10, sort: FIRSTNAME_ASC_LASTNAME_ASC, first: $first, after: $after) {
      edges {
        cursor
        ...AuthorsFilterNode
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${AuthorsFilterNodeFragmentDoc}
`

/**
 * __useAuthorsFilterQuery__
 *
 * To run a query within a React component, call `useAuthorsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorsFilterQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAuthorsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<AuthorsFilterQuery, AuthorsFilterQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<AuthorsFilterQuery, AuthorsFilterQueryVariables>(AuthorsFilterDocument, options)
}
export function useAuthorsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthorsFilterQuery, AuthorsFilterQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<AuthorsFilterQuery, AuthorsFilterQueryVariables>(AuthorsFilterDocument, options)
}
export type AuthorsFilterQueryHookResult = ReturnType<typeof useAuthorsFilterQuery>
export type AuthorsFilterLazyQueryHookResult = ReturnType<typeof useAuthorsFilterLazyQuery>
export type AuthorsFilterQueryResult = Apollo.QueryResult<AuthorsFilterQuery, AuthorsFilterQueryVariables>
export const AddToTopNewsDocument = gql`
  mutation AddToTopNews($data: AddPublicationToTopNewsFromPublicationsListInput!) {
    addPublicationToTopNewsFromPublicationsList(data: $data) {
      status
    }
  }
`
export type AddToTopNewsMutationFn = Apollo.MutationFunction<AddToTopNewsMutation, AddToTopNewsMutationVariables>

/**
 * __useAddToTopNewsMutation__
 *
 * To run a mutation, you first call `useAddToTopNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToTopNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToTopNewsMutation, { data, loading, error }] = useAddToTopNewsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddToTopNewsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToTopNewsMutation, AddToTopNewsMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<AddToTopNewsMutation, AddToTopNewsMutationVariables>(AddToTopNewsDocument, options)
}
export type AddToTopNewsMutationHookResult = ReturnType<typeof useAddToTopNewsMutation>
export type AddToTopNewsMutationResult = Apollo.MutationResult<AddToTopNewsMutation>
export type AddToTopNewsMutationOptions = Apollo.BaseMutationOptions<
  AddToTopNewsMutation,
  AddToTopNewsMutationVariables
>
export const AddToOverviewDocument = gql`
  mutation AddToOverview($data: AddPublicationToOverviewFromPublicationsListInput!) {
    addPublicationToOverviewFromPublicationsList(data: $data) {
      status
    }
  }
`
export type AddToOverviewMutationFn = Apollo.MutationFunction<AddToOverviewMutation, AddToOverviewMutationVariables>

/**
 * __useAddToOverviewMutation__
 *
 * To run a mutation, you first call `useAddToOverviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToOverviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToOverviewMutation, { data, loading, error }] = useAddToOverviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddToOverviewMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToOverviewMutation, AddToOverviewMutationVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<AddToOverviewMutation, AddToOverviewMutationVariables>(AddToOverviewDocument, options)
}
export type AddToOverviewMutationHookResult = ReturnType<typeof useAddToOverviewMutation>
export type AddToOverviewMutationResult = Apollo.MutationResult<AddToOverviewMutation>
export type AddToOverviewMutationOptions = Apollo.BaseMutationOptions<
  AddToOverviewMutation,
  AddToOverviewMutationVariables
>
