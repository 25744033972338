import React, {FC, useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {ArrowBack} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import {FormProvider, useForm} from 'react-hook-form'
import RHFInput from './RHFInput/RHFInput'
import {Collection, Publication} from '@graphql/types'
import RelatedPublications from '../../UI/RelatedPublications/RelatedPublications'
import {useUpdateCollectionMutation} from '@pages/Collections/gql/Collections.generated'
import {useSnackbar} from 'notistack'
import {
  useAddPublicationToCollectionMutation,
  usePublicationsByCollectionUidQuery,
  useRemovePublicationsFromCollectionMutation
} from './gql/CollectionForm.generated'
import {Box, Button, Divider, IconButton, LinearProgress, Typography} from '@mui/material'
import RHFSearchPublication from '../../UI/RHFSearchPublication/RHFSearchPublication'
import InfiniteScroll from 'react-infinite-scroll-component'

type CollectionFormProps = {
  collection: Collection
}

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 978px;
  width: 100%;
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`

type CollectionFormType = {
  title: string
  description: string
  publications: any
  onRemove: (uid: string) => void
  onAdded: (publication: Publication) => void
  onFetchMore: () => void
}

const CollectionForm: FC<CollectionFormProps> = ({collection}) => {
  const [publicationsServer, setPublicationsServer] = useState<Publication[]>([])
  const [publicationsAdded, setPublicationsAdded] = useState<Publication[]>([])
  const [publicationsDeleted, setPublicationsDeleted] = useState<string[]>([])

  const [updateCollection, {loading}] = useUpdateCollectionMutation()
  const [addPublication] = useAddPublicationToCollectionMutation()
  const [removePublication] = useRemovePublicationsFromCollectionMutation()
  const snackbar = useSnackbar()

  const onAdded = useCallback(
    (publication: Publication) => {
      if ([...publicationsServer, ...publicationsAdded].map(v => v?.uid).includes(publication.uid)) {
        snackbar.enqueueSnackbar('Уже добавлена', {variant: 'error'})
      } else {
        setPublicationsAdded(prevState => [publication, ...prevState])
      }
      setPublicationsDeleted(prevState => prevState.filter(uid => uid !== publication.uid))
    },
    [publicationsAdded, publicationsServer, snackbar]
  )

  const onRemove = useCallback(
    (publicationUid: string) => {
      if (publicationsDeleted.includes(publicationUid)) {
        return
      }
      if (publicationsServer.map(publication => publication.uid).includes(publicationUid)) {
        setPublicationsDeleted(prevState => [publicationUid, ...prevState])
      }
      setPublicationsAdded(prevState => prevState.filter(publication => publication.uid !== publicationUid))
      setPublicationsServer(prevState => prevState.filter(publication => publication.uid !== publicationUid))
    },
    [publicationsDeleted, publicationsServer]
  )

  const defaultValues = {
    title: collection?.title || '',
    description: collection?.description || '',
    publications: publicationsServer,
    onRemove: onRemove,
    onAdded: onAdded
  }

  const {control, setValue, handleSubmit, formState, ...methodsForm} = useForm<CollectionFormType>({
    defaultValues: defaultValues
  })

  useEffect(() => {
    setValue('publications', [...publicationsAdded, ...publicationsServer])
    setValue('onAdded', onAdded)
    setValue('onRemove', onRemove)
  }, [onAdded, onRemove, publicationsAdded, publicationsServer, setValue])

  const {data, fetchMore, refetch} = usePublicationsByCollectionUidQuery({
    variables: {collectionUid: collection.uid},
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setPublicationsServer(data?.publications?.edges.map(edge => edge?.node as Publication) as Publication[])
      setValue(
        'publications',
        data?.publications?.edges.map(edge => edge?.node as Publication)
      )
    }
  })

  const onSubmitCollection = useCallback(
    async (data: CollectionFormType) => {
      try {
        await updateCollection({
          variables: {
            uid: collection.uid,
            title: data.title,
            description: data.description
          }
        })
        if (publicationsDeleted.length) {
          await removePublication({
            variables: {
              data: {
                collectionUid: collection.uid,
                publicationUids: publicationsDeleted
              }
            }
          })
          setPublicationsDeleted([])
        }
        if (publicationsAdded.length) {
          await addPublication({
            variables: {
              data: {
                collectionUid: collection.uid,
                publicationUids: publicationsAdded.map(publication => publication.uid)
              }
            }
          })
          setPublicationsAdded([])
        }

        setTimeout(
          () =>
            refetch({
              collectionUid: collection.uid,
              limit: 16
            }),
          1000
        )
        snackbar.enqueueSnackbar('Сохранено', {variant: 'success'})
      } catch (e) {
        console.error(e)
      }
    },
    [
      addPublication,
      collection.uid,
      publicationsAdded,
      publicationsDeleted,
      refetch,
      removePublication,
      snackbar,
      updateCollection
    ]
  )

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        first: 10,
        after: data?.publications?.pageInfo.endCursor
      }
    })
  }, [data?.publications?.pageInfo.endCursor, fetchMore])

  return (
    <FormProvider
      {...{
        formState,
        setValue,
        control,
        handleSubmit,
        ...methodsForm
      }}
    >
      <FormWrapper noValidate onSubmit={handleSubmit(onSubmitCollection)}>
        <HeaderWrapper>
          <Link to='/collections'>
            <IconButton size='small' title='Назад'>
              <ArrowBack />
            </IconButton>
          </Link>

          <Typography variant='h1'>Редактирование коллекции</Typography>
          <Button color='primary' variant='contained' type='submit' disabled={!formState.isValid || loading}>
            Сохранить
          </Button>
        </HeaderWrapper>

        <ContentWrapper>
          <FieldsWrapper>
            <RHFInput name='title' label='Название' required />
            <RHFInput name='description' label='Описание' />
          </FieldsWrapper>

          <Box width='100%'>
            <RHFSearchPublication />
            <Box marginTop='30px'>
              <Divider />
              {collection.publications?.edges.length || [...publicationsAdded, ...publicationsServer].length ? (
                <InfiniteScroll
                  dataLength={data?.publications?.edges.length || 0}
                  next={fetchMoreData}
                  hasMore={!!data?.publications?.pageInfo.hasNextPage}
                  loader={<LinearProgress color='primary' />}
                  height='64vh'
                >
                  <RelatedPublications />
                </InfiniteScroll>
              ) : (
                <Box marginTop='15px'>
                  <Typography variant='h2'>Публикации отсутствуют</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </ContentWrapper>
      </FormWrapper>
    </FormProvider>
  )
}

export default CollectionForm
