import {MetricDataType} from '../../../../types/MetricDataType'
import {ReportsListTableDataProps} from '../ReportsListTableDataProps'

/**
 * Функция обработки данных из метрики
 * @param metricResult данные из метрики
 * @param list данные из бека, которые будут дополняться данными из метрики
 * @returns
 */
export const mapMetricDataResult = (
  metricResult: MetricDataType,
  list: ReportsListTableDataProps[]
): ReportsListTableDataProps[] => {
  if (metricResult) {
    Object.keys(metricResult).forEach((item: string) => {
      const publicationInd: number = list.findIndex(
        p => p.canonicalUrl && p.canonicalUrl.replace('https://ura.news', '') == item
      )
      if (publicationInd) {
        list[publicationInd].viewsCount = metricResult[item].pageviews || 0
        list[publicationInd].uniqueUsersCount = metricResult[item].users || 0
        list[publicationInd].withoutRecommendedSystems = metricResult[item].without_visits || 0
        list[publicationInd].socialVisits = metricResult[item].social_visits || 0
        list[publicationInd].percentSocial = metricResult[item].percent_social || 0
      }
    })
  }
  return list
}
