export const TOP_NEWS_BANNER_HISTORY_ITEM_CONSTS = {
  copyToClipboardMsg: 'Копировать в буфер обмена',
  createBannerMsg: 'поставил(а) баннер на сайте',
  exclusiveMsg: 'Эксклюзив',
  makeChangesMsg: 'внес(ла) изменения',
  no: 'нет',
  priorityMsg: 'Приоритет',
  publicationLinkMsg: 'Ссылка на публикацию',
  yes: 'да'
}
