import * as Types from '../../../@graphql/types'

import {gql} from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type StaffQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>
  departmentUid?: Types.Maybe<Types.Scalars['UUID']>
  isFired?: Types.Maybe<Types.Scalars['Boolean']>
  first?: Types.Maybe<Types.Scalars['Int']>
  after?: Types.Maybe<Types.Scalars['String']>
}>

export type StaffQuery = {__typename?: 'Query'} & {
  staff?: Types.Maybe<
    {__typename?: 'UsersConnection'} & {
      pageInfo: {__typename?: 'PageInfo'} & Pick<Types.PageInfo, 'endCursor' | 'hasNextPage'>
      edges: Array<
        Types.Maybe<
          {__typename?: 'UsersEdge'} & {
            node?: Types.Maybe<
              {__typename?: 'User'} & Pick<
                Types.User,
                'uid' | 'firstname' | 'lastname' | 'isFired' | 'phone' | 'email' | 'avatarUrl'
              > & {
                  department?: Types.Maybe<{__typename?: 'Department'} & Pick<Types.Department, 'uid' | 'name'>>
                  accessLevel?: Types.Maybe<{__typename?: 'AccessLevel'} & Pick<Types.AccessLevel, 'uid' | 'name'>>
                }
            >
          }
        >
      >
    }
  >
}

export type DepartmentsQueryVariables = Types.Exact<{[key: string]: never}>

export type DepartmentsQuery = {__typename?: 'Query'} & {
  departments?: Types.Maybe<Array<Types.Maybe<{__typename?: 'Department'} & Pick<Types.Department, 'uid' | 'name'>>>>
}

export const StaffDocument = gql`
  query staff($search: String, $departmentUid: UUID, $isFired: Boolean, $first: Int, $after: String) {
    staff(
      filter: {search: $search, departmentUid: $departmentUid, isFired: $isFired}
      sort: FIRSTNAME_ASC_LASTNAME_ASC
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          uid
          firstname
          lastname
          isFired
          department {
            uid
            name
          }
          accessLevel {
            uid
            name
          }
          phone
          email
          avatarUrl
        }
      }
    }
  }
`

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      search: // value for 'search'
 *      departmentUid: // value for 'departmentUid'
 *      isFired: // value for 'isFired'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useStaffQuery(baseOptions?: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options)
}
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options)
}
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>
export const DepartmentsDocument = gql`
  query departments {
    departments {
      uid
      name
    }
  }
`

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options)
}
export function useDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options)
}
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>
