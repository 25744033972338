import React, {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Controller, useFormContext} from 'react-hook-form'
import {InfoOutlined} from '@mui/icons-material'
import {Typography} from '@mui/material'
import {useStore} from '@stores/rootStoreContext'
import PublicationOverviewBlockFieldsTitle from '@components/Publication/Form/PublicationForm/PublicationOverviewBlockFieldsTitle/PublicationOverviewBlockFieldsTitle'
import {PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS} from './PublicationOverviewBlockFields.consts'
import {
  PublicationOverviewBlockFieldsWrapper,
  PublicationOverviewBlockInfo
} from './PublicationOverviewBlockFields.styles'

export const PublicationOverviewBlockFields: FC = observer(() => {
  const {publicationStore} = useStore()
  const {publication} = publicationStore
  const [titleErrorText, setTitleErrorText] = useState('')
  const [subtitleErrorText, setSubtitleErrorText] = useState('')
  const {control, setValue, getValues} = useFormContext()

  useEffect(() => {
    setValue('overviewTitle', publication.overviewTitle)
  }, [publication.overviewTitle, setValue])

  return (
    <PublicationOverviewBlockFieldsWrapper>
      <Controller
        name='overviewTitle'
        control={control}
        render={({field}) => (
          <div style={{width: '100%', marginBottom: '20px'}}>
            <PublicationOverviewBlockFieldsTitle
              titleLabel={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.titleMsg}
              titleMaxLength={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.titleMaxLength}
              field={field}
              textErrorChange={setTitleErrorText}
              required={true}
            />
            {!titleErrorText?.length && (
              <Typography color='primary' component='div'>
                <PublicationOverviewBlockInfo>
                  <InfoOutlined
                    style={{
                      color: '#1964e7',
                      transform: 'scale(0.6)',
                      marginLeft: '-6px'
                    }}
                  />
                  {PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.autocompleteMsg}
                </PublicationOverviewBlockInfo>
              </Typography>
            )}
          </div>
        )}
      />
      <Controller
        name='overviewDescription'
        control={control}
        render={({field}) => (
          <div style={{width: '100%', marginBottom: '20px'}}>
            <PublicationOverviewBlockFieldsTitle
              titleLabel={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.subtitleMsg}
              titleMaxLength={PUBLICATION_OVERVIEW_BLOCK_FIELDS_CONSTS.subtitleMaxLength}
              field={field}
              textErrorChange={setSubtitleErrorText}
              required={false}
            />
          </div>
        )}
      />
    </PublicationOverviewBlockFieldsWrapper>
  )
})
