import React, {ChangeEvent, FC, useState} from 'react'
import {TabsWithBorder} from '../UI/styled'
import {TabsListProps} from '../../models/models'
import TabPanel from '../UI/TabPanel/TabPanel'
import DictionariesTabContent from './DictionariesTabContent/DictionariesTabContent'
import {Tab} from '@mui/material'

const DictionariesTabList: FC<TabsListProps> = ({tabNames}) => {
  const [value, setValue] = useState(0)
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  return (
    <>
      <TabsWithBorder
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        scrollButtons={true}
        variant='scrollable'
      >
        {tabNames.map(tab => (
          <Tab label={tab.title} key={tab.name} />
        ))}
      </TabsWithBorder>

      {tabNames.map((tab, i) => (
        <TabPanel value={value} index={i} key={tab.name}>
          <DictionariesTabContent status={tab.name} />
        </TabPanel>
      ))}
    </>
  )
}

export default DictionariesTabList
