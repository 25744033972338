import {CommentsSort} from '@graphql/types'

export const COMMENTS_MODERATION_CONTENT_CONSTS = {
  initialPageInfo: {
    endCursor: '',
    hasNextPage: true,
    hasPreviousPage: false,
    startCursor: ''
  },
  isBad: 'Плохие',
  isNew: 'Новые',
  loading: 'Загрузка...',
  nothingWasFounded: 'Ничего не найдено',
  searchByWord: 'Поиск по слову',
  screenSCustom: 580,
  sortFieldLabel: 'Сортировка',
  sortValues: [
    {
      label: 'Сначала новые',
      value: CommentsSort.CreatedAtDesc
    },
    {
      label: 'Сначала старые',
      value: CommentsSort.CreatedAtAsc
    }
  ]
}
