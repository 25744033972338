import {useLayoutEffect, useState} from 'react'

const useWindowResize = (): number[] => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export default useWindowResize
