import React, {FC} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {TextField, FormHelperText} from '@mui/material'
import {StyledFormControl} from '../../../UI/styled'
import {redColor} from './../../../../@theme/vars'

type RHFInputProps = {
  name: string
  label: string
  width?: string
  required?: boolean
  marginTop?: string
  marginLeft?: string
  multiline?: boolean
  disabled?: boolean
  errorText?: string
}

const RHFInput: FC<RHFInputProps> = ({
  name,
  label,
  width = '572px',
  required,
  marginTop = '0',
  marginLeft = '0',
  multiline = false,
  disabled = false,
  errorText = ''
}) => {
  const {control} = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field}) => (
        <StyledFormControl width={width} marginbottom='15px' margintop={marginTop} marginleft={marginLeft}>
          <TextField {...field} label={label} fullWidth multiline={multiline} disabled={disabled} />
          {errorText && (
            <FormHelperText
              style={{
                color: redColor
              }}
            >
              {errorText}
            </FormHelperText>
          )}
        </StyledFormControl>
      )}
    />
  )
}

export default RHFInput
