import React, {createContext, FC} from 'react'
import {PageContainerWrapper, PageName, ReportsListWrapper} from './ReportsPage.styles'
import {useLocation} from 'react-router'
import ReportsListFilters from './ReportsListFilters/ReportsListFilters'
import ReportsListTable from './ReportsListTable/ReportsListTable'

export const ReportsContext = createContext('')

/**
 * Страница "Отчеты"
 * @returns
 */
export const ReportsPage: FC<any> = () => {
  const location = useLocation()

  return (
    <ReportsContext.Provider value={location.pathname.replace('/', '')}>
      <PageContainerWrapper>
        <PageName id='page-name'>Отчеты</PageName>
        <ReportsListWrapper>
          <ReportsListFilters />
          <ReportsListTable />
        </ReportsListWrapper>
      </PageContainerWrapper>
    </ReportsContext.Provider>
  )
}
