import React, {FC, Fragment, useEffect, useState, createContext} from 'react'
import format from 'date-fns/format'
import {ru} from 'date-fns/locale'
import {Box} from '@mui/material'
import BildCheckListTableItem from '../BildCheckListTableItem/BildCheckListTableItem'
import {BildCheckListPublicationNodeFragment} from '../gql/BildCheckList.generated'
import {StyledTitlePublicationList} from './BildCheckListTable.styles'

/**
 * Входные параметры для компонента BildCheckListTable
 */
type BildCheckListTableProps = {
  publicationsGroup: object
  status: string
}

/**
 * Контекст для BildCheckListLength
 */
export const BildCheckListLengthContext = createContext<number>(0)

/**
 * Компонент отрисовки таблицы в BildCheckListTabPanelContent
 * @param {BildCheckListTableProps}
 * @returns
 */
const BildCheckListTable: FC<BildCheckListTableProps> = ({publicationsGroup, status}) => {
  const [focusedElementId, setFocusedElementId] = useState('')
  const [length, setLength] = useState(0)

  useEffect(() => {
    const calculatedLength = Object.keys(publicationsGroup).reduce(
      (total, arr) => total + publicationsGroup[arr].length,
      0
    )
    setLength(calculatedLength)
  }, [publicationsGroup])

  return (
    <BildCheckListLengthContext.Provider value={length}>
      <Box paddingRight='15px'>
        {Object.keys(publicationsGroup).map(publicationItem => (
          <Fragment key={publicationItem}>
            <StyledTitlePublicationList id='publication-list-date'>
              {format(new Date(publicationItem), 'd MMMM', {
                locale: ru
              })}
            </StyledTitlePublicationList>

            {publicationsGroup[publicationItem].map(publication => (
              <BildCheckListTableItem
                status={status}
                publication={publication.node as BildCheckListPublicationNodeFragment}
                focusedElementId={focusedElementId}
                setFocusedElementId={setFocusedElementId}
                key={publication.node?.uid}
              />
            ))}
          </Fragment>
        ))}
      </Box>
    </BildCheckListLengthContext.Provider>
  )
}

export default BildCheckListTable
