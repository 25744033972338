import styled from 'styled-components'
import {lightgrayColor, mainTextColor} from '@theme/vars'

export const HeaderWithBorder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${lightgrayColor};
  margin-bottom: 20px;
`

export const PublicationInfoWrapper = styled.div`
  min-height: 0px;
  height: auto;
  overflow: hidden;
  transition: ease-in;
  transition-duration: 305ms;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.active {
    height: 0;
    overflow: visible;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export const TextWrapper = styled.div`
  display: block;
  margin-top: 12px;
  max-width: 920px;
  color: ${mainTextColor};

  & p {
    margin-top: 10px;
  }
`
