// Новый файл - паспорт для второго почтальона
import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import {RetryLink} from '@apollo/client/link/retry'
import {toast} from 'react-toastify'
import {onError} from '@apollo/client/link/error'
import {relayStylePagination} from '@apollo/client/utilities'
import {ToastError} from '@components/UI/ToastifyComponents/ToastError/ToastError'
import {generateGuid} from '@utils/generateGuid'
import {APOLLO_CLIENT_CONSTS} from '@graphql/utils/apollo-client.consts'
import {getKeycloakInstance} from '../@auth/keyacloak-client'

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
  if (graphQLErrors) {
    console.error(graphQLErrors)
    for (const graphQLError of graphQLErrors) {
      if (graphQLError.extensions?.code === 'UNAUTHENTICATED') {
        getKeycloakInstance().logout()
        return
      }

      const errorMessage =
        graphQLError.extensions?.userMessage || graphQLError.message || graphQLError.extensions?.code || ''
      const detailInfo = graphQLError.extensions?.exception || graphQLError

      const guid = generateGuid()
      toast(
        <ToastError text={errorMessage} detailInfo={detailInfo} id={guid} canRetry={true} operation={operation} />,
        {
          toastId: guid,
          closeButton: true,
          containerId: 'error'
        }
      )
    }
  }

  if (networkError) {
    let errorMessage = ''
    let canRetry = false

    switch (networkError['statusCode']) {
      case 400:
        errorMessage = APOLLO_CLIENT_CONSTS.error400
        break
      case 403:
        errorMessage = APOLLO_CLIENT_CONSTS.error403
        break
      case 409:
        errorMessage = APOLLO_CLIENT_CONSTS.error409
        break
      case 501:
        errorMessage = APOLLO_CLIENT_CONSTS.error501
        canRetry = true
        break
      case 502:
        errorMessage = APOLLO_CLIENT_CONSTS.error502
        canRetry = true
        break
      case 503:
        errorMessage = APOLLO_CLIENT_CONSTS.error503
        canRetry = true
        break
      case 504:
        errorMessage = APOLLO_CLIENT_CONSTS.error504
        canRetry = true
        break
      default:
        errorMessage = networkError['statusCode'].toString()
    }

    console.error(`[Network error]: ${networkError}`)
    const guid = generateGuid()
    toast(
      <ToastError text={errorMessage} detailInfo={networkError} id={guid} canRetry={canRetry} operation={operation} />,
      {
        toastId: guid,
        containerId: 'error',
        closeButton: true
      }
    )
  }
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: error => !!error
  }
})

const authLink = new ApolloLink((operation, forward) => {
  const token = getKeycloakInstance().token
  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }))
  return forward(operation)
})

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI
})

// Создаем нового почтальона
const serviceClient = new ApolloClient<NormalizedCacheObject>({
  link: from([retryLink, authLink, errorLink.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          stories: relayStylePagination(),
          tags: relayStylePagination(),
          collections: relayStylePagination(),
          staffByCursor: relayStylePagination(),
          authorsFilterByCursor: relayStylePagination(),
          publications: relayStylePagination(),
          comments: relayStylePagination(),
          staff: relayStylePagination()
        }
      },
      PublicationsConnection: {
        merge: true
      }
    },
    dataIdFromObject(responseObject) {
      if (responseObject.cursor) {
        return `${responseObject.__typename}:${responseObject.cursor}`
      }
      if (responseObject.uid) {
        return `${responseObject.__typename}:${responseObject.uid}`
      }
      return defaultDataIdFromObject(responseObject)
    }
  }),
  connectToDevTools: true
})

export default serviceClient
