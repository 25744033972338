import {LinearProgress} from '@mui/material'
import {cloneElement, FC, useCallback, useRef, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ScrollToTopButton from '@components/UI/ScrollToTopButton/ScrollToTopButton'
import {InfiniteScrollWithScrollToTopProps} from './InfiniteScrollWithScrollToTopProps'

const InfiniteScrollWithScrollToTop: FC<InfiniteScrollWithScrollToTopProps> = ({
  children,
  dataLength,
  elementStyle,
  fetchMoreData,
  hasMore
}) => {
  const ref = useRef<any>(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  const onScroll = useCallback(val => {
    if (val) {
      setScrollPosition(val.scrollTop)
    }
  }, [])

  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<LinearProgress color='primary' />}
      ref={ref}
      onScroll={e => onScroll(e.target)}
      height={elementStyle?.height}
      style={elementStyle}
    >
      {cloneElement(children)}
      <ScrollToTopButton refContainer={ref} pos={scrollPosition} />
    </InfiniteScroll>
  )
}

export default InfiniteScrollWithScrollToTop
