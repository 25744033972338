import React, {FC} from 'react'
import styled from 'styled-components'
import {Box} from '@mui/material'

const StyledDiv = styled(Box)`
  margin: 12px auto;
  padding: 0 10px;
  position: relative;
  max-width: 1272px;
`

const PageContainer: FC = props => {
  return <StyledDiv {...props}>{props.children}</StyledDiv>
}

export default PageContainer
