import React, {FC, useCallback} from 'react'
import {useHistory, useParams} from 'react-router'
import {IconButton} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import TopNewsRegionTabs from '@components/MainSelection/TopNews/TopNewsRegionTabs/TopNewsRegionTabs'
import PageContainer from '../../../components/UI/PageContainer/PageContainer'
import {TopNewsHistoryContent} from '../../../components/MainSelection/TopNews/TopNewsHistoryContent/TopNewsHistoryContent'
import {HeaderWithBorder, Title} from './TopNewsHistoryPage.styles'

/**
 * Страница истории изменений баннеров
 * @returns
 */
const TopNewsHistoryPage: FC = () => {
  const history = useHistory()
  const {regionId} = useParams<{regionId: string}>()

  const goBack = useCallback(() => {
    history.push(`/main-page/top-news/${regionId}`)
  }, [history, regionId])

  return (
    <PageContainer>
      <HeaderWithBorder>
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Title>История изменений</Title>
      </HeaderWithBorder>
      <TopNewsRegionTabs>
        <TopNewsHistoryContent />
      </TopNewsRegionTabs>
    </PageContainer>
  )
}

export default TopNewsHistoryPage
