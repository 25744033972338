import React, {ChangeEvent, createContext, FC, useContext, useState} from 'react'
import {useLocation} from 'react-router'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import {TabsWithBorder} from '@components/UI/styled'
import TabPanel from '@components/UI/TabPanel/TabPanel'
import {tabNamesType} from '../../models/models'
import {BILD_CHECK_LIST} from './BildCheckList.consts'

import {BildCheckListLengthContext} from './BildCheckListTable/BildCheckListTable'
import BildCheckListTabPanelContent from './BildCheckListTabPanelContent/BildCheckListTabPanelContent'
import {PageName, TabWrapper} from './BildCheckList.styles'

export const BildCheckType = createContext('')

/**
 * Страница "Проверка бильдредакторов"
 * @returns
 */
const BildCheckListPage: FC<any> = () => {
  const location = useLocation()
  const tabNames: tabNamesType = [
    {name: 'UNCHECKED', title: BILD_CHECK_LIST.uncheckedMsg},
    {name: 'CHECKED', title: BILD_CHECK_LIST.checkedMsg}
  ]

  const [value, setValue] = useState(0)
  const length = useContext(BildCheckListLengthContext)

  /**
   * @ handleChange Функция клика по табу
   * @param event событие
   * @param newValue индекс нового таба
   */
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  const [refetchDateTime, setRefetchDateTime] = useState<Date>()

  return (
    <BildCheckType.Provider value={location.pathname.replace('/', '')}>
      <PageContainer>
        <PageName id='page-name'>{BILD_CHECK_LIST.titleMsg}</PageName>
        <TabsWithBorder value={value} onChange={handleChange} scrollButtons={true} variant='scrollable'>
          {tabNames.map(tab => (
            <TabWrapper label={tab.title} key={tab.name} id={'bild-check-list-status-tab'} />
          ))}
        </TabsWithBorder>
        {tabNames.map((tab, i) => (
          <TabPanel value={value} index={i} key={tab.name}>
            <BildCheckListTabPanelContent status={tab.name} refetchDateTime={refetchDateTime} />
          </TabPanel>
        ))}
      </PageContainer>
    </BildCheckType.Provider>
  )
}

export default BildCheckListPage
