export const PUBLICATION_ACTION_BUTTON_CONSTS = {
  announcePhotoMSg: 'анонс-фото',
  authorMsg: 'автор',
  closeMsg: 'Закрыть',
  errorMsg: 'Ошибка',
  forReviewMsg: 'Закончить',
  keywordsMsg: 'ключевые слова',
  needSomeChangesMSg: 'Для сохранения материала нужно вносить какие-нибудь изменения',
  overviewFigureMsg: 'Картинка для главной',
  overviewTitleMsg: 'Заголовок для главной',
  previewMsg: 'Предпросмотр',
  publishedMsg: 'Новость опубликована',
  willPublishMsg: 'Новость будет опубликована ',
  publishMsg: 'Опубликовать',
  regionMsg: 'Регионы',
  removeFromSiteMsg: 'Снять с сайта',
  removePublicationFromSiteMsg: 'Снять публикацию с сайта',
  rubricMsg: 'рубрика',
  savedMsg: 'Сохранено',
  saveMsg: 'Сохранить',
  savingMsg: 'Сохраняется',
  sendToReviewMsg: 'закончить',
  seoTitleMsg: 'SEO-заголовок',
  textMsg: 'текст',
  titleMsg: 'заголовок',
  yandexDzenTitle: 'заголовок для Дзен.Блогов'
} as const
