import React, {FC, useState, useCallback, useEffect} from 'react'
import {Quill as ReactQuill} from 'react-quill'
import TextEditorInsertModal, {LinkElement} from '@components/UI/TextEditorInsertModal/TextEditorInsertModal'
import {TOOLBAR_LINK_CONSTS} from './ToolbarLink.consts'

const Delta = ReactQuill.import('delta')

type ToolbarVideoProps = {
  editorRef: any
  isOpenInsertModal: boolean
  closeDialogLink: () => void
  handleFocus: () => void
}

const ToolbarLink: FC<ToolbarVideoProps> = ({editorRef, isOpenInsertModal, closeDialogLink, handleFocus}) => {
  const [isOpenInsertModalVal, setIsOpenInsertModalVal] = useState(false)
  const [linkValue, setLinkValue] = useState<LinkElement>({} as LinkElement)

  useEffect(() => {
    setIsOpenInsertModalVal(isOpenInsertModal)
    if (editorRef?.current && isOpenInsertModal) {
      const quill = (editorRef.current as any).getEditor()
      if (quill) {
        const range = quill.getSelection(true)

        if (range) {
          setLinkValue({
            linkText: quill.getText(range.index, range.length) || '',
            description: ''
          })
        }
      }
    }
  }, [isOpenInsertModal, editorRef, setLinkValue])

  const toggleInsertModal = useCallback(() => {
    closeDialogLink()
    setIsOpenInsertModalVal(prev => {
      return !prev
    })
  }, [closeDialogLink])

  const setLink = useCallback(
    (linkValue: string, linkText: string) => {
      if (editorRef.current) {
        const quill = (editorRef.current as any).getEditor()
        const {index, length} = quill.getSelection(true)

        if (index) {
          setTimeout(() => {
            if (linkValue) {
              quill.updateContents(new Delta().retain(index).delete(length).insert(linkText, {link: linkValue}), 'user')
            } else {
              quill.format('link', false)
            }
          }, 200)
        }
      }
    },
    [editorRef]
  )

  return (
    <>
      <TextEditorInsertModal
        bottomText=''
        title={TOOLBAR_LINK_CONSTS.titleModalMsg}
        open={isOpenInsertModalVal}
        link={linkValue}
        descriptionLabel={TOOLBAR_LINK_CONSTS.descriptionLabelMsg}
        okAction={setLink}
        okLabel={TOOLBAR_LINK_CONSTS.pasteMsg}
        toggleModal={toggleInsertModal}
        onClose={() => {
          setLinkValue({
            linkText: '',
            description: ''
          })
          toggleInsertModal()
        }}
      />
    </>
  )
}
export default ToolbarLink
