export const UNPUBLISHED_NEWS_PICKER_CONSTS = {
  publicationHasBeenRemoved: 'Новость снята с сайта',
  addLinkForRedirection: 'Добавьте ссылку для редиректа',
  alternativeLinkForRemovedPub: 'Публикация снята с сайта, вместо нее читатель видит',
  ifNotExists: 'Если не заполнено, то редирект на главную',
  linkForRedirectMsg: 'Ссылка для редиректа',
  relatedLinkMsg: 'Вставить ссылку',
  invalidLinkMsg: 'Неверная ссылка',
  errorMsg: 'Ошибка',
  savedMsg: 'Сохранено',
  homePage: 'https://ura.news/'
}
