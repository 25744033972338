import {CircularProgress, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {FC, MouseEvent, useEffect, useState} from 'react'
import {useKeycloak} from '@react-keycloak/web'
import {useStore} from '@stores/rootStoreContext'
import Tooltip from '@components/UI/Tooltip/Tooltip'
import NnIcon from '@components/UI/Icons/NnIcon'
import ChevronIcon from '@components/UI/Icons/Chevron'
import {getQuillEditor} from '@utils/getQuillEditor'
import {useSnackbarPersons} from '@hooks/useSnackbarPersons/useSnackbarPersons'
import {NerToolbarRequest} from '@fetch/NnApi/NnAPI.types'
import {StyledToggleButton} from '../Toolbar.styles'
import {TOOLBAR_SIZE_CONSTS} from './ToolbarNn.consts'
import {StyledMenu} from './ToolbarNn.styles'
import GenerateTextButton from './ButtonsNn/GenerateTextTopic'
import WriteBackButton from './ButtonsNn/WriteBackButton'
import RewriteTextButton from './ButtonsNn/RewriteTextButton'
import DialogNn from './DialogNn/DialogNn'

const ToolbarNn: FC = () => {
  const {publicationStore, editorStore, dialogStore} = useStore()
  const {isLoading, isRollUp} = dialogStore
  const {publication} = publicationStore
  const {keycloak} = useKeycloak()

  const [authorId, setAuthorId] = useState<string>('')

  useEffect(() => {
    if (keycloak.tokenParsed?.sub) {
      setAuthorId(keycloak.tokenParsed?.sub)
    }
  }, [keycloak.tokenParsed?.sub])

  const {editorLoadingNn, editorRef, editorTextLength} = editorStore

  const {getPersonsInfo} = useSnackbarPersons(editorRef, true, publication.uid)

  useEffect(() => {
    if (editorLoadingNn) {
      const quill = getQuillEditor(editorRef)
      let requestText = ''

      if (quill) {
        const delta = quill.getContents()

        delta.forEach(item => {
          if (typeof item.insert === 'string') {
            requestText += item.insert
            requestText += ' '
          }
        })
        const data: NerToolbarRequest = {
          params: requestText.replace(/\n/g, ' '),
          publicationId: publication.deprecatedId,
          publicationUid: publication.id,
          authorId
        }

        getPersonsInfo({
          data,
          setIsLoading: (value: boolean) => {
            dialogStore.setIsLoading(value)
          },
          editorTextLength
        })
      }
    }
  }, [editorLoadingNn, publication.deprecatedId, publication.id, getPersonsInfo, editorRef])

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorElement)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <DialogNn editorRef={editorRef} authorId={authorId} />
      <Tooltip title={isLoading ? TOOLBAR_SIZE_CONSTS.textLoading : TOOLBAR_SIZE_CONSTS.textSize}>
        <StyledToggleButton
          id='basic-button'
          value=''
          aria-controls={open ? 'text-size-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          disabled={isLoading || isRollUp}
        >
          {isLoading || isRollUp ? <CircularProgress disableShrink color='secondary' size='1rem' /> : <NnIcon></NnIcon>}
          <Typography
            color='textPrimary'
            style={{
              display: 'contents',
              verticalAlign: 'middle'
            }}
          >
            <span>&nbsp;</span>
            <ChevronIcon></ChevronIcon>
          </Typography>
        </StyledToggleButton>
      </Tooltip>
      <StyledMenu
        id='text-size-menu'
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <WriteBackButton
          editorRef={editorRef}
          handleClose={handleClose}
          isRegionsResponse={false}
          authorId={authorId}
        />
        <WriteBackButton editorRef={editorRef} handleClose={handleClose} isRegionsResponse={true} authorId={authorId} />
        <GenerateTextButton editorRef={editorRef} handleClose={handleClose} authorId={authorId} />
        <RewriteTextButton editorRef={editorRef} handleClose={handleClose} isRewriting={false} authorId={authorId} />
        <RewriteTextButton editorRef={editorRef} handleClose={handleClose} isRewriting={true} authorId={authorId} />
      </StyledMenu>
    </>
  )
}
export default observer(ToolbarNn)

// const setSizeCallback_alisa = useCallback(
//   (data: any) => {
//     const quill = getQuillEditor(editorRef)
//     let range: RangeStatic | undefined

//     if (quill) {
//       range = quill?.getSelection(true)
//     }

//     if (quill && range) {
//       // Получаем значение по ключу text
//       const text = data.text

//       if (text === 'Нет похожих публикаций') {
//         snackbar.enqueueSnackbar('Нет похожих публикаций', {variant: 'error', autoHideDuration: 10000})
//       }
//       if (text === 'Такой рубрики нет') {
//         snackbar.enqueueSnackbar('Такой рубрики нет', {variant: 'error', autoHideDuration: 10000})
//       }
//       if (text === 'Не смогли найти похожую публикации по выбранной рубрике и регионам') {
//         snackbar.enqueueSnackbar('Не смогли найти похожую публикации по выбранной рубрике и регионам', {
//           variant: 'error',
//           autoHideDuration: 10000
//         })
//       } else {
//         // Получаем текущую длину контента
//         const currentLength = quill.getLength()

//         // Получаем последний символ
//         const lastChar = quill.getText(currentLength)

//         // Определяем позицию вставки текста
//         let insertPosition
//         if (lastChar === '\n') {
//           insertPosition = currentLength - 4
//         } else {
//           insertPosition = currentLength + 1
//         }

//         // Вставляем текст в указанную позицию
//         quill.insertText(insertPosition, text.toString(), 'user')

//         // Устанавливаем курсор в конец вставленного текста
//         const insertedLength = text.length
//         quill.setSelection(insertPosition + insertedLength, 0)

//         // Вставка слова с ссылкой
//         const word = data.word
//         const url = data.url
//         const index = text.indexOf(word) - 1

//         if (index !== -1) {
//           // Вставляем слово с ссылкой в нужную позицию
//           quill.insertText(insertPosition + index, word, {link: url}, 'user')
//           // Удаляем старое слово (после вставки, чтобы не сдвигать текст)
//           quill.deleteText(insertPosition + index + word.length, word.length, 'user')
//         }
//       }

//       setIsLoading(false)
//     }
//   },
//   [editorRef, snackbar]
// )

// бэк вектор
// if (title === 'Написать бэк (вектор)') {
//   if (requestText.length <= 1) {
//     snackbar.enqueueSnackbar('Сначала напишите текст', {variant: 'info', autoHideDuration: 10000})
//     setIsLoading(false)
//   } else {
//     const regions = publicationStore.publication?.regions?.map(v => v?.uid)
//     const rubric = publicationStore.publication?.rubric?.title
//     const subrubric = publicationStore.publication?.subrubric?.title

//     const data = JSON.stringify({
//       text: requestText,
//       regions,
//       rubric,
//       subrubric,
//       publication_id,
//       publication_uid
//     })

//     if (rubric === undefined) {
//       snackbar.enqueueSnackbar('Сначала выберите рубрику', {variant: 'info', autoHideDuration: 10000})
//       setIsLoading(false)
//     } else {
//       try {
//         const getNNTextData = await fetch(`${baseURL}back/vector`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({
//             params: data
//           })
//         })

//         const getNNTextDataJson = await getNNTextData.json()

//         setSizeCallback_alisa(getNNTextDataJson)
//       } catch (error) {
//         snackbar.enqueueSnackbar(`Error: ${error}`, {variant: 'error', autoHideDuration: 10000})
//         setIsLoading(false)
//       }
//     }
//   }
// }
