import {forwardRef, useCallback, useEffect} from 'react'
import {CircularProgress, Typography} from '@mui/material'
import {Button} from '@mui/material'
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {useKeycloak} from '@react-keycloak/web'
import {NerTitleRequest} from '@fetch/NnApi/NnAPI.types'
import {redColor, warnColor} from '@theme/vars'
import {useStore} from '@stores/rootStoreContext'
import NnIcon from '@components/UI/Icons/NnIcon'
import PopoverInfoErrorButton from '@components/UI/PopoverInfoErrorButton/PopoverInfoErrorButton'
import {getQuillEditor} from '@utils/getQuillEditor'
import {fetchAutoTitle} from '@fetch'
import {ETitlesField} from '../PublicationFormTitles.types'
import {PUBLICATION_FORM_TITLES_CONSTS, TitleFieldEntries} from '../PublicationFormTitles.consts'
import {PublicationInputType} from '../../PublicationForm/PublicationInputType'
import {FormHelperErrorText, TitleLength} from './PublicationFormTitle.styles'
import {PublicationFormTitleProps} from './PublicationFormTitleProps'
import {PUBLICATION_FORM_TITLE_CONSTS} from './PublicationFormTitle.consts'
import type {FC, Ref} from 'react'
/**
 * PublicationFormTitle is a functional component that renders a form control for publication titles.
 * It includes an input field, error handling, and a button to update the title.
 *
 * @param {PublicationFormTitleProps} props - The properties passed to the component.
 * @param {UseFormReturn<PublicationInputType>} props.methodsForm - The form methods from react-hook-form.
 * @param {boolean} props.disabled - A flag to disable the input field.
 * @param {ControllerRenderProps<PublicationInputType, ETitlesField>} props.field - The field properties from react-hook-form.
 * @param {boolean} [props.fullWidth=false] - A flag to set the input field to full width.
 * @param {(value: string, fieldName: ETitlesField) => void} props.onChange - The function to call when the input value changes.
 * @param {string} [props.customInformationError] - An optional custom error message.
 * @param {React.ReactNode} [props.fillingRules] - Optional filling rules to display in a popover.
 *
 * @returns {JSX.Element} The rendered form control component.
 */
const PublicationFormTitle = forwardRef<HTMLInputElement, PublicationFormTitleProps>(
  (props: PublicationFormTitleProps, ref: Ref<HTMLInputElement>) => {
    const {
      methodsForm,
      disabled = false,
      field,
      fullWidth = false,
      onChange,
      newUpdateTitle,
      customInformationError,
      fillingRules
    } = props
    const {publicationStore, editorStore} = useStore()
    const {publication} = publicationStore
    const {editorRef} = editorStore
    const {getFieldState} = methodsForm
    const fieldState = getFieldState(field.name)
    const hasErrors = Boolean(fieldState?.invalid)
    const {keycloak} = useKeycloak()
    const [authorId, setAuthorId] = useState<string>('')

    useEffect(() => {
      if (keycloak.tokenParsed?.sub) {
        setAuthorId(keycloak.tokenParsed?.sub)
      }
    }, [keycloak.tokenParsed?.sub])

    const baseURL = process.env.REACT_APP_NN

    const errorText = fieldState?.error?.message
    const isSubtitle = field.name === ETitlesField.Subtitle

    const isArticlesTitle = publication.typeSlug === 'articles' && field.name === ETitlesField.Title
    const maxErrorLength = isArticlesTitle
      ? PUBLICATION_FORM_TITLES_CONSTS.articleTitleMaxLength
      : TitleFieldEntries[field.name].maxErrorLength

    const maxWarningLength = isArticlesTitle ? 0 : TitleFieldEntries[field.name].maxWarningLength || 0

    useEffect(() => {
      onChange(field.value, field.name)
    }, [field.name, field.value, ref?.current?.selectionStart, onChange])

    const handleChange = useCallback(
      event => {
        const {value} = event.target

        if (value !== undefined) {
          onChange(value, field.name, event.target)
        }
      },
      [field.name, onChange]
    )

    const snackbar = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)

    const updateTitle = useCallback(async () => {
      const publication_id = publicationStore.publication?.deprecatedId
      const publication_uid = publicationStore.publication?.id

      const quill = getQuillEditor(editorRef)

      if (quill) {
        const {ops} = quill.getContents()
        let text = ''
        ops?.forEach(op => {
          text += ' ' + op.insert
        })

        let data: NerTitleRequest
        // Определяем ключ на основе field.name
        const key = field.name === ETitlesField.Title ? 'params' : field.name === ETitlesField.SeoTitle ? 'seo' : 'dzen'

        if (key === 'params') {
          data = {
            params: {title: text.trim()},
            publicationId: publication_id,
            publicationUid: publication_uid,
            authorId
          }
        } else if (key === 'seo') {
          data = {
            params: {seo: text.trim()},
            publicationId: publication_id,
            publicationUid: publication_uid,
            authorId
          }
        } else {
          data = {
            params: {dzen: text.trim()},
            publicationId: publication_id,
            publicationUid: publication_uid,
            authorId
          }
        }

        if (text.length <= 2) {
          snackbar.enqueueSnackbar(PUBLICATION_FORM_TITLE_CONSTS.writeTextFirst, {
            variant: 'info',
            autoHideDuration: 10000
          })
          setIsLoading(false)
        } else {
          setIsLoading(true)
          try {
            const responseTitle = await fetchAutoTitle(data)

            const text = responseTitle.title

            field.onChange(text)
            setIsLoading(false)
          } catch (error) {
            console.log(error)
            setIsLoading(false)
          }
        }
      }
    }, [editorRef, field, publicationStore.publication?.deprecatedId, publicationStore.publication?.id, snackbar])

    return (
      <FormControl
        fullWidth
        style={{
          marginTop: field.name === ETitlesField.Title ? '0' : '20px'
        }}
      >
        <div style={{flexDirection: 'row'}}>
          <InputLabel
            style={{
              width: `${fullWidth ? '90%' : 'calc(90% - 80px)'}`
            }}
          >
            <Typography variant='text12R'>{TitleFieldEntries[field.name].name}</Typography>
          </InputLabel>
          <OutlinedInput
            label={TitleFieldEntries[field.name].name}
            {...field}
            error={hasErrors || !!customInformationError}
            multiline
            style={{
              fontSize: `${isSubtitle ? '18px' : '16px'}`,
              fontStyle: `${isSubtitle ? 'italic' : 'inherit'}`,
              float: 'left',
              width: `${fullWidth ? '90%' : 'calc(90% - 80px)'}`,
              color: hasErrors ? redColor : 'inherit'
            }}
            disabled={disabled}
            onChange={handleChange}
            onKeyDown={handleChange}
            onClick={handleChange}
            inputRef={ref}
          />
          <div>
            <TitleLength
              style={{
                display: `${fullWidth ? 'flex' : 'block'}`,
                float: 'right',
                color:
                  field.value.length > maxErrorLength
                    ? redColor
                    : maxWarningLength > 0 && field.value.length > maxWarningLength
                    ? warnColor
                    : 'inherit'
              }}
            >
              {`${field.value.length || 0} / ${maxErrorLength}`}
            </TitleLength>
            {(hasErrors || !!customInformationError) && (
              <FormHelperErrorText>
                {errorText || customInformationError}
                {fillingRules && <PopoverInfoErrorButton>{fillingRules}</PopoverInfoErrorButton>}
              </FormHelperErrorText>
            )}
            {maxWarningLength > 0 && field.value.length > maxWarningLength && (
              <FormHelperText
                style={{
                  float: 'left',
                  color: warnColor
                }}
              >
                {PUBLICATION_FORM_TITLE_CONSTS.titleIsTooLong}
              </FormHelperText>
            )}
          </div>
          <Button
            color='secondary'
            variant='outlined'
            style={{
              padding: '0',
              marginLeft: '10px',
              alignItems: 'center',
              justifyContent: 'center',
              boxSizing: 'border-box',
              minWidth: '40px',
              minHeight: '40px'
            }}
            onClick={updateTitle}
          >
            {isLoading ? <CircularProgress disableShrink color='secondary' size='1rem' /> : <NnIcon />}
          </Button>
        </div>
      </FormControl>
    )
  }
)

PublicationFormTitle.displayName = 'PublicationFormTitle'

export default PublicationFormTitle
