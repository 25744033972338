export const TOP_NEWS_EDIT_CONSTS = {
  titleMsg: 'Редактировать баннер',
  priorityMsg: 'Приоритет',
  urlMsg: 'Ссылка',
  titleNameMsg: 'Заголовок',
  saveMsg: 'Сохранить',
  defaultImage: {
    id: 'topNewsEditFigureId',
    src: '',
    cropperPreviewUrl: '',
    crop: {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    },
    figureId: '',
    figureUid: '',
    figureVersionId: '',
    isFullWidth: true,
    isannouncingphoto: false,
    isoverviewblock: false,
    hasWatermark: false,
    hasDiagonalWatermark: false
  },
  deleteBannerMsg: 'Удалить баннер',
  exclusiveMsg: 'Эксклюзив',
  wrongLinkMsg: 'Некорректная ссылка',
  maxTitleLength: 54,
  characterLimitReachedMsg: 'Достигнут лимит по кол-ву символов',
  warningMsg: 'Внимание!',
  closeConfirmBtn: 'Удалить',
  closeConfirmationText: 'Вы уверены, что хотите удалить баннер?',
  successTopNewsMsg: 'Отлично! Баннер сохранен',
  backConfirmationText: 'Внесенные измененения не сохранятся. Вы уверены, что хотите выйти из окна редактирования?',
  backConfirmationBtn: 'Да, выйти',
  toChange: 'Изменить'
}
