import React, {FC, useCallback} from 'react'
import styled from 'styled-components'
import {Box, IconButton, Typography} from '@mui/material'
import {dividerColor, lightgrayColor} from '../../../../@theme/vars'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {TitleLink} from '../../../UI/styled'
import {Collection} from '@graphql/types'
import {useDeactivateCollectionMutation} from '@pages/Collections/gql/Collections.generated'
import {useSnackbar} from 'notistack'

type CollectionItemProps = {
  collection: Collection
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 5px 13px;

  border-bottom: 1px solid ${dividerColor};
`

const StyledIcon = styled(IconButton)`
  color: ${lightgrayColor};
`

const CollectionItem: FC<CollectionItemProps> = ({collection}) => {
  const snackbar = useSnackbar()
  const [deactivateCollection] = useDeactivateCollectionMutation()

  const handleDeleteCollection = useCallback(async () => {
    try {
      await deactivateCollection({
        variables: {uid: collection.uid},
        refetchQueries: ['collectionList']
      })
      snackbar.enqueueSnackbar('Коллекция удалена', {
        variant: 'success'
      })
    } catch (e) {
      console.error(e)
    }
  }, [collection.uid, deactivateCollection, snackbar])

  return (
    <Wrapper>
      <Box>
        <Typography variant='h2'>
          <TitleLink to={`/collections/editor/${collection.uid}`}>{collection.title}</TitleLink>
        </Typography>
        <Box marginTop='7px'>
          <Typography variant='subtitle1'>{collection.publicationsCount} публикации</Typography>
        </Box>
      </Box>
      <Box>
        <StyledIcon size='small' onClick={handleDeleteCollection}>
          <DeleteForeverIcon />
        </StyledIcon>
      </Box>
    </Wrapper>
  )
}

export default CollectionItem
