import {ArrowBack} from '@mui/icons-material'
import {Box, Button, IconButton, Typography} from '@mui/material'
import React, {FC} from 'react'
import styled from 'styled-components'

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #222222;
`

const ActionWrapper = styled.div``

type HeaderProps = {
  title?: string
  onBack?: () => void
  hasPreview?: boolean
}

const Header: FC<HeaderProps> = ({title, onBack, hasPreview = true}) => {
  return (
    <HeaderWrapper>
      <Box marginRight={hasPreview ? '90px' : '64px'}>
        <IconButton size='small' onClick={onBack}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Title color='secondary'>{title}</Title>
      <ActionWrapper>
        {hasPreview && (
          <Button variant='outlined' color='primary' style={{marginRight: '24px'}}>
            Предпросмотр
          </Button>
        )}
        <Button type='submit' variant='contained' color='primary'>
          Сохранить
        </Button>
      </ActionWrapper>
    </HeaderWrapper>
  )
}

export default Header
