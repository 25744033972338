import {
  BlockquoteBlot,
  GalleryBlot,
  Html,
  ImageBlot,
  Link,
  OnlineTranslationDividerBlot,
  SideBar,
  SubHeader,
  TextRssBlock,
  Video
} from '../../Blots'

export const formats = [
  'bold',
  'color',
  'italic',
  'underline',
  'link',
  'clean',
  'bullet',
  'list',
  'ordered',
  'size',
  'small',
  'huge',
  Link.blotName,
  Html.blotName,
  Video.blotName,
  ImageBlot.blotName,
  GalleryBlot.blotName,
  SideBar.blotName,
  SubHeader.blotName,
  TextRssBlock.blotName,
  OnlineTranslationDividerBlot.blotName,
  BlockquoteBlot.blotName,
  'online-class',
  'class'
]
