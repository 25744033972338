import React, {FC, useCallback, useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {Tag} from '@graphql/types'
import {Divider, LinearProgress, TextField, Button, Box} from '@mui/material'
import styled from 'styled-components'
import {useCreateTagMutation, useTagsListQuery} from '@pages/Tags/gql/Tags.generated'
import TagItem from './Item/TagItem'
import CreateTagModal from '../NewTagModal/CrateTagModal'
import InfiniteScroll from 'react-infinite-scroll-component'

const ListWrapper = styled.div`
  padding-right: 5px;
  overflow: hidden;
`

const TagsList: FC = () => {
  const [searchString, setSearchString] = useState('')
  const {data, fetchMore, refetch} = useTagsListQuery({
    variables: {
      search: searchString || null
    }
  })
  const history = useHistory()
  const [createTag] = useCreateTagMutation()
  const snackbar = useSnackbar()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCreateTag = useCallback(
    async data => {
      try {
        const result = await createTag({
          variables: {
            title: data.title,
            description: data.description
          },
          refetchQueries: ['tagsList'],
          awaitRefetchQueries: true
        })
        history.push(`/tag/editor/${result.data?.createTag?.tag?.uid}`)
      } catch {
        snackbar.enqueueSnackbar('Ошибка')
      }
      setIsOpenModal(false)
    },
    [createTag, history, snackbar]
  )

  useEffect(() => {
    refetch()
  }, [refetch, searchString])

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        first: 15,
        after: data?.tags?.pageInfo.endCursor,
        seach: searchString
      }
    })
  }, [data?.tags?.pageInfo.endCursor, fetchMore, searchString])

  return (
    <>
      <Box display='flex' justifyContent='space-between' marginBottom='33px' marginTop='16px'>
        <TextField
          id='outlined-basic'
          label='Поиск по заголовку...'
          variant='standard'
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
        <Button color='primary' variant='contained' onClick={() => setIsOpenModal(true)}>
          Добавить
        </Button>
      </Box>
      <Divider />
      <InfiniteScroll
        dataLength={data?.tags?.edges.length || 0}
        next={fetchMoreData}
        hasMore={!!data?.tags?.pageInfo.hasNextPage}
        loader={<LinearProgress color='primary' />}
        height='75vh'
      >
        <ListWrapper>
          {data?.tags.edges.map(tag => (
            <TagItem key={tag?.node?.uid} tag={tag?.node as Tag} />
          ))}
        </ListWrapper>
      </InfiniteScroll>
      <CreateTagModal open={isOpenModal} onClose={() => setIsOpenModal(false)} onOk={handleCreateTag} />
    </>
  )
}

export default TagsList
