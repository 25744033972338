import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'

export const CopyToClipboardIcon: FC<SvgIconProps> = props => {
  return (
    <svg width='15' height='18' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 0.75H2C1.175 0.75 0.5 1.425 0.5 2.25V12.75H2V2.25H11V0.75ZM10.25 3.75H5C4.175 3.75 3.5075 4.425 3.5075 5.25L3.5 15.75C3.5 16.575 4.1675 17.25 4.9925 17.25H13.25C14.075 17.25 14.75 16.575 14.75 15.75V8.25L10.25 3.75ZM5 15.75V5.25H9.5V9H13.25V15.75H5Z'
        fill='#222222'
      />
    </svg>
  )
}
