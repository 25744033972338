import styled from 'styled-components'
import {Box, Typography} from '@mui/material'
import {
  blueColor,
  grayColor,
  lightBlueBackgroundClr,
  lightblueColor,
  lightgrayColor,
  redColor
} from '../../../@theme/vars'
import {CardSizeType} from './MainSelectionCardProps'

export const Wrapper = styled.div<{size: CardSizeType}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({size}) => `${size.width}px`};
  height: ${({size}) => `${size.height}px`};
  padding: 12px 12px 5px;
  border-radius: 4px;
  background-color: #fff;
`

export const ImageBox = styled.div<{url: string}>`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: ${lightgrayColor};
  background-image: url('${({url}) => url}');
`

export const SerialNumber = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  margin-right: 20px;
  color: ${grayColor};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${lightBlueBackgroundClr};
  text-align: center;
`

export const ImageBoxDataWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: inherit;
`

export const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ClickCounter = styled.div`
  display: flex;
  align-items: center;
  color: ${blueColor};
`

export const NumberClicks = styled(Typography)`
  margin-left: 8px;
  line-height: 18px;
`

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`

export const LabelsCard = styled.div`
  padding: 4px 10px;
  border-radius: 4px;
  background-color: ${blueColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: ${lightblueColor};
`

export const ModalDescription = styled(Typography)`
  font-weight: normal;
  margin-bottom: 15px;
`

export const BoxBreakWord = styled.div`
  padding-top: 16px;
  word-break: break-word;
`

export const BoxBreakWordWithError = styled.div`
  padding-top: 3px;
  word-break: break-word;
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 400;
  color: ${redColor};
`
export const StyledBoxForImg = styled(Box)`
  width: 240px;
  height: 171px;
`
export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 5px;
`

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px;
`
