import React, {FC, useCallback, useEffect, useState} from 'react'
import 'cropperjs/dist/cropper.css'
import {useKeycloak} from '@react-keycloak/web'
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material'
import {Clear} from '@mui/icons-material'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash.debounce'
import {observer} from 'mobx-react-lite'
import {Figure} from '@graphql/types'
import {useStore} from '@stores/rootStoreContext'
import {SimpleFigure} from '../../Icons/simpleFigure'
import {useCreateFigureMutation} from '../../../Publication/Form/gql/PublicationEditor.generated'
import useWindowResize from '../../../../hooks/useWindowResize'
import {CropperType} from '../../../../types/CropperType'
import {redColor} from './../../../../@theme/vars'
import {
  AnnounceImageTitle,
  DetailsRowSelects,
  ImageTooltipWrapper,
  ImageUrlBoxWrapper,
  LabelWrapper,
  SimpleFigureWrapper,
  StyledFormWrapper,
  UploadFromFileButtonWrapper
} from './ImageCropper.styles'
import {IMAGE_CROPPER_CONSTS} from './ImageCropper.consts'
import {ImageCropperProps} from './ImageCropperProps'
import CropperContainer from './CropperContainer/CropperContainer'

/**
 * Компонент для кроппера изображения
 * @param param0
 * @returns
 */
const ImageCropper: FC<ImageCropperProps> = ({
  description = '',
  figure,
  imageUrl,
  hasWatermark,
  hasDiagonalWatermark,
  setImageUrl,
  setAlt,
  setAuthor,
  setDescription,
  setHasWatermark,
  setHasDiagonalWatermark,
  setFigure,
  figureTypeSlug,
  ratio = 16 / 9,
  isCustomRatio = false,
  cropperType,
  figureIsLoading,
  forImageLoader
}) => {
  const {cropperStore} = useStore()
  const {setCropperSrc, setCropperIsValid} = cropperStore

  const [createFigure, {loading: createFigureLoading}] = useCreateFigureMutation()
  const [screenWidth] = useWindowResize()
  const [errorText, setErrorText] = useState('')
  const {keycloak, initialized} = useKeycloak()
  const [fileIsLoading, setFileIsLoading] = useState(false)

  useEffect(() => {
    setFileIsLoading(figureIsLoading || false)
  }, [figureIsLoading])

  const createFigureAction = useCallback(
    async (hasWatermark: boolean, hasDiagonalWatermark: boolean, figureUrl?: string) => {
      const newFigure = await createFigure({
        variables: {
          data: {
            figureTypeSlug:
              cropperType in [CropperType.isAnnounce, CropperType.isBreakingNews]
                ? 'publication'
                : figureTypeSlug || 'publication',
            src: figureUrl || imageUrl || '',
            hasWatermark,
            hasDiagonalWatermark
          }
        }
      })
      if (!newFigure?.errors?.length) {
        setFigure && setFigure(newFigure?.data?.createFigure?.figure as Figure)
        setCropperSrc(figure?.cropperPreview?.url || '')
        setAuthor && setAuthor(newFigure?.data?.createFigure?.figure?.author || '')
        const descriptionValue =
          description || figure?.description || newFigure?.data?.createFigure?.figure?.description || ''

        setDescription && descriptionValue && setDescription(descriptionValue)
        setAlt && setAlt(newFigure?.data?.createFigure?.figure?.alt || descriptionValue || '')
        setHasWatermark && setHasWatermark(newFigure?.data?.createFigure?.figure?.hasWatermark || false)
        setHasDiagonalWatermark &&
          setHasDiagonalWatermark(newFigure?.data?.createFigure?.figure?.hasDiagonalWatermark || false)

        if (setCropperIsValid && newFigure?.data?.createFigure?.figure) {
          setCropperIsValid(true)
        }
      }
    },
    [
      createFigure,
      figureTypeSlug,
      imageUrl,
      setFigure,
      setAuthor,
      setDescription,
      setHasWatermark,
      setHasDiagonalWatermark,
      setCropperIsValid,
      description,
      figure?.description,
      figure?.cropperPreview?.url,
      setAlt,
      cropperType,
      setCropperSrc
    ]
  )

  const uploadFile = useCallback(
    e => {
      const file = e.target.files[0]
      const formData = new FormData()
      setFileIsLoading(true)
      formData.append('file', file)
      const requestOptions = {
        method: 'POST',
        headers: {
          authorization: initialized ? `Bearer ${keycloak.token}` : ''
        },
        body: formData
      }

      fetch(`${process.env.REACT_APP_UPLOADS_IMAGES_URI}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          setFileIsLoading(false)
          createFigureAction(false, hasDiagonalWatermark, data.data.arn)
        })
      e.target.value = null
    },
    [createFigureAction, setFileIsLoading, initialized, keycloak.token, hasDiagonalWatermark]
  )

  const autoCreateFigure = debounce(imageUrl => {
    if (
      imageUrl &&
      imageUrl.indexOf(IMAGE_CROPPER_CONSTS.photoStoreHttp, 0) === -1 &&
      imageUrl.indexOf(IMAGE_CROPPER_CONSTS.photoStoreHttps, 0) === -1
    ) {
      setErrorText(IMAGE_CROPPER_CONSTS.incorrectLinkMsg)
      if (setCropperIsValid) {
        setCropperIsValid(false)
      }
    } else {
      setErrorText('')
      if (setCropperIsValid) {
        setCropperIsValid(true)
      }
      if (imageUrl) {
        createFigureAction(true, hasDiagonalWatermark, imageUrl)
      }
    }
  }, 300)

  const clearLinkUrl = useCallback(() => {
    setImageUrl && setImageUrl('')
    if (setFigure) {
      setFigure({} as Figure)
      setAuthor && setAuthor('')
      setAlt && setAlt('')
    }
    setErrorText('')
  }, [setImageUrl, setFigure, setAuthor, setAlt])

  let simpleFigureWrapperStyle, detailsRowSelectsStyle

  if (forImageLoader === true) {
    simpleFigureWrapperStyle = {
      width: cropperType === CropperType.isYandexVideo ? (screenWidth <= 768 ? '320px' : '212px') : '50%',
      minWidth:
        cropperType === CropperType.isBreakingNews
          ? '206px'
          : cropperType === CropperType.isYandexVideo
          ? '212px'
          : '276px',
      border: '2px dashed #1964e7',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: cropperType === CropperType.isYandexVideo ? (screenWidth <= 768 ? '182px' : '120px') : '156px'
    }
    detailsRowSelectsStyle = {
      flexDirection: cropperType === CropperType.isYandexVideo ? 'column' : 'row-reverse',
      alignItems: cropperType === CropperType.isYandexVideo ? 'start' : 'end',
      marginLeft: cropperType === CropperType.isYandexVideo && screenWidth > 768 ? '12px' : 0,
      marginTop: cropperType === CropperType.isYandexVideo && screenWidth <= 768 ? '12px' : 0
    }
  } else {
    simpleFigureWrapperStyle = {
      minWidth:
        cropperType === CropperType.isBreakingNews
          ? '206px'
          : cropperType === CropperType.isGallery
          ? '250px'
          : '400px',
      maxWidth: '100%',
      height:
        cropperType === CropperType.isBreakingNews ? '147px' : cropperType === CropperType.isGallery ? '180px' : '200px'
    }
    detailsRowSelectsStyle = {
      marginTop: '20px'
    }
  }

  const uploadFileClick = useCallback(() => {
    clearLinkUrl()
    const uploadFileLabel = document.getElementById('contained-button-file-from-modal')
    if (uploadFileLabel) {
      uploadFileLabel.click()
    }
  }, [clearLinkUrl])

  useEffect(() => {
    if (figure?.src === IMAGE_CROPPER_CONSTS.defaultPhotoUrl) {
      autoCreateFigure(figure.src)
    }
  }, [figure?.src])

  useEffect(() => {
    setCropperSrc(figure?.cropperPreview?.url)
  }, [figure?.cropperPreview, setCropperSrc])

  const [urlWidth, setUrlWidth] = useState('100%')
  const [uploadFromFileButtonWidth, setUploadFromFileButtonWidth] = useState('174px')
  const [uploadFromFileButtonMarginTop, setUploadFromFileButtonMarginTop] = useState('0px')
  const [uploadFromFileButtonPadding, setUploadFromFileButtonPadding] = useState('11px 26px 11px 26px')

  useEffect(() => {
    let newUrlWidth = '100%'
    let newUploadFromFileButtonWidth = '100%'
    let newUploadFromFileButtonMarginTop = '8px'
    let newUploadFromFileButtonPadding = '11px 26px 11px 26px'

    if (screenWidth > 768) {
      switch (cropperType) {
        case CropperType.isBreakingNews: {
          newUrlWidth = '206px'
          break
        }
        case CropperType.isYandexVideo: {
          newUrlWidth = '100%'
          newUploadFromFileButtonWidth = '320px'
          break
        }
        case CropperType.isGallery: {
          newUrlWidth = '382px'
          newUploadFromFileButtonWidth = '126px'
          newUploadFromFileButtonPadding = '12.5px 8px'
          newUploadFromFileButtonMarginTop = '0px'
          break
        }
        default: {
          newUrlWidth = '462px'
          newUploadFromFileButtonWidth = '174px'
          newUploadFromFileButtonMarginTop = '0px'
          newUploadFromFileButtonPadding = '11px 26px 11px 26px'
          break
        }
      }
    } else {
      switch (cropperType) {
        case CropperType.isImageLoader: {
          newUploadFromFileButtonMarginTop = '10px'
          break
        }
        default: {
          newUploadFromFileButtonMarginTop = '8px'
          break
        }
      }
    }

    setUrlWidth(newUrlWidth)
    setUploadFromFileButtonWidth(newUploadFromFileButtonWidth)
    setUploadFromFileButtonMarginTop(newUploadFromFileButtonMarginTop)
    setUploadFromFileButtonPadding(newUploadFromFileButtonPadding)
  }, [screenWidth, cropperType])

  return (
    <Box
      display='flex'
      flexDirection={`${
        cropperType === CropperType.isYandexVideo && screenWidth <= 768
          ? 'column'
          : forImageLoader === true
          ? 'row'
          : 'column'
      }`}
      alignItems={`${
        cropperType === CropperType.isBreakingNews || forImageLoader === true || cropperType === CropperType.isAnnounce
          ? 'start'
          : 'center'
      }`}
      width={'100%'}
    >
      {!isEmpty(figure) && !createFigureLoading && figure?.uid ? (
        <CropperContainer
          figure={figure}
          cropperType={cropperType}
          ratio={ratio}
          isCustomRatio={isCustomRatio}
          uploadFileClick={uploadFileClick}
        />
      ) : (
        <LabelWrapper
          htmlFor='contained-button-file-from-modal'
          style={{
            width: cropperType === CropperType.isGallery ? '250px' : '100%'
          }}
        >
          <SimpleFigureWrapper style={{...simpleFigureWrapperStyle}}>
            <SimpleFigure
              width='41.25px'
              height='37.33px'
              viewBox='0 0 48 48'
              color={forImageLoader === true ? 'primary' : 'secondary'}
            />
            {forImageLoader === true && (
              <ImageTooltipWrapper
                style={{
                  width: cropperType === CropperType.isYandexVideo ? (screenWidth <= 768 ? '145px' : '170px') : ''
                }}
              >
                {IMAGE_CROPPER_CONSTS.imageTooltipMsg}
              </ImageTooltipWrapper>
            )}
            {createFigureLoading || fileIsLoading ? (
              <CircularProgress size='3rem' style={{position: 'absolute'}} />
            ) : undefined}
          </SimpleFigureWrapper>
        </LabelWrapper>
      )}
      {cropperType === CropperType.isAnnounce && (
        <AnnounceImageTitle>{IMAGE_CROPPER_CONSTS.announceImageMsg}</AnnounceImageTitle>
      )}
      {!figure?.src && setImageUrl && setFigure && (
        <DetailsRowSelects style={{...detailsRowSelectsStyle}}>
          <ImageUrlBoxWrapper width={urlWidth}>
            <StyledFormWrapper width={urlWidth}>
              <InputLabel
                style={{
                  width: `calc(${urlWidth} - 50px)`,
                  height: '20px'
                }}
              >
                {IMAGE_CROPPER_CONSTS.imageUrlLabel}
              </InputLabel>
              <OutlinedInput
                label={IMAGE_CROPPER_CONSTS.imageUrlLabel}
                error={!!errorText}
                autoFocus
                style={{
                  width: urlWidth,
                  height: '42px'
                }}
                onChange={e => {
                  setImageUrl(e?.target?.value)
                  autoCreateFigure(e?.target?.value)
                }}
                value={imageUrl}
                disabled={createFigureLoading || fileIsLoading}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton edge='end' size='small' onClick={() => clearLinkUrl()}>
                      <Clear fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!!errorText && (
                <FormHelperText
                  style={{
                    color: redColor
                  }}
                >
                  {errorText}
                </FormHelperText>
              )}
            </StyledFormWrapper>
          </ImageUrlBoxWrapper>
          {!figure?.src && (
            <input
              id='contained-button-file-from-modal'
              style={{display: 'none'}}
              type='file'
              accept='.jpg,.jpeg,.png,.webp'
              onChange={uploadFile}
            />
          )}
          {!figure?.src && cropperType !== CropperType.isBreakingNews && (
            <label htmlFor='contained-button-file-from-modal'>
              <UploadFromFileButtonWrapper
                component='span'
                variant='outlined'
                disabled={createFigureLoading || fileIsLoading}
                onClick={() => clearLinkUrl()}
                style={{
                  marginTop: uploadFromFileButtonMarginTop,
                  width: uploadFromFileButtonWidth,
                  padding: uploadFromFileButtonPadding
                }}
              >
                {IMAGE_CROPPER_CONSTS.getFromFileMsg}
              </UploadFromFileButtonWrapper>
            </label>
          )}
        </DetailsRowSelects>
      )}
    </Box>
  )
}

export default observer(ImageCropper)
