import React, {FC, MouseEvent, useCallback, useContext, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Quill} from 'quill'
import {Quill as ReactQuill} from 'react-quill'
import {CropInput, Figure, GalleryImage} from '@graphql/types'
import ImageEditorModal from '@components/UI/ImageEditor/ImageEditorModal/ImageEditorModal'
import {deleteElementById} from '@utils/deleteElementById'
import {FiguresCacheContext} from '@components/Publication/Form/PublicationForm/FiguresCacheContext'
import {useStore} from '@stores/rootStoreContext'

import GalleryEditor from '@components/UI/Gallery/GalleryEditor/GalleryEditor'
import {GalleryBlotProps} from '@components/TextEditor/Blots/GalleryBlot/GalleryBlotProps'
import {getQuillEditor} from '@utils/getQuillEditor'
import {GalleryItem} from '../../../../types/GalleryItem'
import {ImageBlotProps} from '../../Blots/ImageBlot/ImageBlotProps'
import {GalleryBlot, ImageBlot} from '../../Blots'

import {TOOLBAR_IMAGE_CONSTS} from './ToolbarImage.consts'
import {ToolbarImageProps} from './ToolbarImageProps'
import {ToolbarImageButton} from './ToolbarImageButton/ToolbarImageButton'

const Delta = ReactQuill.import('delta')

const ToolbarImage: FC<ToolbarImageProps> = ({editorRef}) => {
  const [isAnnouncePhoto, setIsAnnouncePhoto] = useState(false)
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [imageModalId, setImageModalId] = useState('')
  const [currentFigure, setCurrentFigure] = useState({} as Figure)
  const {figuresCache} = useContext(FiguresCacheContext)
  const {publicationStore} = useStore()
  const {imageModalStore, galleryModalStore} = publicationStore
  const {isOpen, imageDetail, setIsOpen, reset} = imageModalStore
  const {isOpen: galleryIsOpen, galleryDetail, setIsOpen: setGalleryIsOpen, reset: resetGallery} = galleryModalStore

  const addNewImage = useCallback(() => {
    reset()
    setIsOpen(true)
  }, [setIsOpen, reset])

  const addNewGallery = useCallback(() => {
    resetGallery()
    setGalleryIsOpen(true)
  }, [resetGallery, setGalleryIsOpen])

  const closeDialogImage = useCallback(() => {
    setIsAnnouncePhoto(false)
    setImageModalId('')
    setIsOpen(false)
  }, [setIsOpen])

  const closeDialogGallery = useCallback(() => {
    setImageModalId('')
    setGalleryIsOpen(false)
  }, [setGalleryIsOpen])

  useEffect(() => {
    setCurrentFigure({} as Figure)
  }, [isOpen])

  const openModalImage = useCallback(
    (detail: ImageBlotProps) => {
      setIsAnnouncePhoto(detail.isannouncingphoto || false)
      if (detail?.figureUid) {
        const figuresCacheItem = figuresCache.find(item => item.figureUid === detail.figureUid)
        if (figuresCacheItem) {
          setCurrentFigure({
            alt: figuresCacheItem.alt || '',
            author: figuresCacheItem.author || '',
            cropperPreview: {
              url: figuresCacheItem.cropperPreviewUrl || ''
            },
            description: figuresCacheItem.description || '',
            hasWatermark: figuresCacheItem.hasWatermark || false,
            hasDiagonalWatermark: figuresCacheItem.hasDiagonalWatermark || false,
            id: figuresCacheItem.figureId,
            latestVersion: {
              id: detail.id,
              crop: figuresCacheItem.crop as CropInput
            },
            src: figuresCacheItem.src || '',
            uid: figuresCacheItem.figureUid || ''
          } as Figure)
        } else {
          setCurrentFigure({
            alt: detail.alt || '',
            author: detail.author || '',
            cropperPreview: {
              url: detail.cropperPreviewUrl || '',
              size: {
                width: detail.crop.width,
                height: detail.crop.height
              }
            },
            description: detail.description || '',
            hasWatermark: detail.hasWatermark || false,
            hasDiagonalWatermark: detail.hasDiagonalWatermark || false,
            id: detail.figureId,
            latestVersion: {
              id: detail.figureVersionId,
              crop: detail.crop as CropInput
            },
            src: detail.src || '',
            uid: detail.figureUid || ''
          } as Figure)
        }
      }
      setImageModalId(detail.id || detail.figureUid)
      setIsFullWidth(detail.isFullWidth || false)
      setIsOpen(true)
    },
    [figuresCache, setIsOpen]
  )

  useEffect(() => {
    if (isOpen) {
      openModalImage(imageDetail)
    }
  }, [isOpen, imageDetail, openModalImage])

  const openModalGallery = useCallback(
    (detail: GalleryItem) => {
      console.log('detail', detail)
      setImageModalId(detail.uid || '')
      setGalleryIsOpen(true)
    },
    [figuresCache, setGalleryIsOpen]
  )

  useEffect(() => {
    if (galleryIsOpen) {
      openModalGallery(galleryDetail)
    }
  }, [galleryIsOpen, galleryDetail, openModalGallery])

  const setImage = useCallback(
    (imageBlot: ImageBlotProps) => {
      const quill = getQuillEditor(editorRef)

      if (quill) {
        let isNew = true
        const contents = quill.getContents()

        const ops = contents.map(operation => {
          if (
            operation.insert[ImageBlot.blotName] &&
            operation.insert[ImageBlot.blotName].id &&
            operation.insert[ImageBlot.blotName].id === imageBlot.id
          ) {
            isNew = false

            return {
              insert: {
                [ImageBlot.blotName]: imageBlot
              }
            }
          }

          return operation
        })

        if (isNew) {
          const {index} = quill.getSelection(true)
          quill.insertEmbed(index, ImageBlot.blotName, imageBlot, 'user')
          quill.insertText(index + 1, '\n', 'user')

          return
        }

        quill.setContents(
          new Delta({
            ops
          }),
          'user'
        )
      }
    },
    [editorRef]
  )

  const setGallery = useCallback(
    (galleryBlot: GalleryBlotProps) => {
      console.log('galleryBlot', galleryBlot)
      const quill = getQuillEditor(editorRef)

      if (quill) {
        let isNew = true
        const contents = quill.getContents()

        const ops = contents.map(operation => {
          if (
            operation.insert[GalleryBlot.blotName] &&
            operation.insert[GalleryBlot.blotName].uid &&
            operation.insert[GalleryBlot.blotName].uid === galleryBlot.uid
          ) {
            isNew = false

            return {
              insert: {
                [GalleryBlot.blotName]: galleryBlot
              }
            }
          }

          return operation
        })

        if (isNew) {
          const {index} = quill.getSelection(true)
          quill.insertEmbed(index, GalleryBlot.blotName, galleryBlot, 'user')
          quill.insertText(index + 1, '\n', 'user')

          return
        }

        quill.setContents(
          new Delta({
            ops
          }),
          'user'
        )
      }
    },
    [editorRef]
  )

  const updateCacheFigures = useCallback((data: ImageBlotProps) => {
    const figuresCacheItemIndex = figuresCache.findIndex(item => item.figureId === data.figureId)
    if (figuresCacheItemIndex !== -1) {
      figuresCache[figuresCacheItemIndex] = data
    } else {
      figuresCache.push(data)
    }
  }, [])

  return (
    <>
      <ToolbarImageButton addNewImage={addNewImage} addNewGallery={addNewGallery} />
      <ImageEditorModal
        ratio={25 / 17}
        defaultFigure={currentFigure}
        modalId={imageModalId}
        open={isOpen}
        onClose={closeDialogImage}
        onNo={() => {
          setImageModalId('')
        }}
        title={TOOLBAR_IMAGE_CONSTS.insertingImageMsg}
        text=''
        okLabel={currentFigure?.uid ? TOOLBAR_IMAGE_CONSTS.changeMsg : TOOLBAR_IMAGE_CONSTS.pastePhotoMsg}
        noLabel={
          currentFigure?.uid ? (isAnnouncePhoto ? '' : TOOLBAR_IMAGE_CONSTS.deleteMsg) : TOOLBAR_IMAGE_CONSTS.cancelMsg
        }
        addFigure={setImage}
        deleteFigure={
          isAnnouncePhoto
            ? () => {
                setImageModalId('')
              }
            : deleteElementById
        }
        figureTypeSlug='publication'
        needMoreDescription
        isCustomRatio={!isAnnouncePhoto}
        fullWidth={isFullWidth}
        updateCacheFigures={updateCacheFigures}
      />
      <GalleryEditor modalId={imageModalId} open={galleryIsOpen} onClose={closeDialogGallery} setGallery={setGallery} />
    </>
  )
}
export default observer(ToolbarImage)
