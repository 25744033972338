import React from 'react'
import {Typography} from '@mui/material'
import PageContainer from '@components/UI/PageContainer/PageContainer'
import CommentsModerationTabs from '@components/Comments/CommentsModerationTabs/CommentsModerationTabs'
import CommentsModerationContent from '@components/Comments/CommentsModerationContent/CommentsModerationContent'

const CommentsModerationPage: React.FC = () => {
  return (
    <PageContainer>
      <Typography variant='text20M'>Модерация комментариев</Typography>
      <CommentsModerationTabs>
        <CommentsModerationContent />
      </CommentsModerationTabs>
    </PageContainer>
  )
}

export default CommentsModerationPage
