import styled, {keyframes} from 'styled-components'
import {grayColor} from '../../../../@theme/vars'

export const PublicationLoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 130px;
  height: 42px;
  margin-right: 30px;
  margin-left: 10px;
  margin-top: -17px;

  /* @media only screen and (max-width: 980px) {
    margin-top: 30px;
  } */
`

export const PublicationLoadingBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${grayColor};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

export const PublicationLoadingBlockText = styled.span`
  margin-left: 11px;
`

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`

export const RefreshIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  animation: ${rotateAnimation} 2s infinite linear;
`
