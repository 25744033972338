import styled from 'styled-components'
import {Wrapper} from '../ConfirmationButton/ConfirmationButton.styles'

export const HoverWrapper = styled(Wrapper)`
  background-color: #f1f6ff;
  color: #222;
  font-family: 'Roboto';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  display: inline-flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  /* box-shadow: 0px 0px 4px 0px rgba(34, 34, 34, 0.15); */
`
