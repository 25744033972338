import {makeAutoObservable} from 'mobx'
import exportFromJSON, {ExportType} from 'export-from-json'
import {ReportsListFiltersProps} from '@pages/ReportsPage/ReportsListFilters/ReportsListFiltersProps'
import {ReportsListTableDataProps} from '@pages/ReportsPage/ReportsListTable/ReportsListTableDataProps'
import IReportsListStore from './reportsListStore.types'
import {defaultFilters} from './reportsListStore.defaultValues'

class ReportsListStore implements IReportsListStore {
  clickExportBtnCounter = 0
  clickSearchBtnCounter = 0
  exportProgress = 0
  fileFormat: ExportType = exportFromJSON.types.xls
  filters: ReportsListFiltersProps = defaultFilters
  loadingExport = false
  loadingData = false
  isEmptyList = false
  processedRowsCount = 0

  hasOnlineTranslation = false
  onlineTranslationArray: string[] = []

  exportListData: ReportsListTableDataProps[] = []

  constructor() {
    makeAutoObservable(this)
  }

  increaseExportBtnCounter = (): void => {
    this.clickExportBtnCounter += 1
  }

  increaseSearchBtnCounter = (): void => {
    this.clickSearchBtnCounter += 1
  }

  setClickExportBtnCounter = (value: number): void => {
    this.clickExportBtnCounter = value
  }

  setClickSearchBtnCounter = (value: number): void => {
    this.clickSearchBtnCounter = value
  }

  setExportProgress = (value: number): void => {
    this.exportProgress = value
  }

  setFileFormat = (value: ExportType): void => {
    this.fileFormat = value
  }

  setFilters = (value: ReportsListFiltersProps): void => {
    this.filters = value
  }

  setFiltersWithPrevState = (cb: (prev: ReportsListFiltersProps) => ReportsListFiltersProps): void => {
    this.filters = cb(this.filters)
  }

  setLoadingData = (value: boolean): void => {
    this.loadingData = value
  }

  setLoadingExport = (value: boolean): void => {
    this.loadingExport = value
  }

  setIsEmptyList = (value: boolean): void => {
    this.isEmptyList = value
  }

  setProcessedRowsCount = (value: number): void => {
    this.processedRowsCount = value
  }

  setExportListData = (value: ReportsListTableDataProps[]): void => {
    this.exportListData = value
  }
}

export default ReportsListStore
