/**
 * Функция сравнения массивов примитивов
 * @param arr1 первое значение для сравнения
 * @param arr2 второе значение для сравнения
 * @returns {boolean}
 */
export const compareOfTwoArraysWithPrimitives = (arr1: any[], arr2: any[]): boolean => {
  let isEqual = true
  if (arr1?.length !== arr2?.length) {
    isEqual = false
    return isEqual
  }
  arr1.forEach((arrItem1: any) => {
    if (!arr2.includes(arrItem1)) {
      isEqual = false
      return
    }
  })
  return isEqual
}
