import {FC, useCallback, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router'
import {Box, Button, Divider, LinearProgress, Typography} from '@mui/material'
import {format} from 'date-fns'
import {getPublicationTextOnlyByUidAdapterToClient} from '@entities/api/publication/adapters/getPublicationTextOnlyByUidAdapterToClient'
import {getCommentsListAdapterToClient} from '@entities/api/comments/adapters/getCommentsListAdapterToClient'
import defaultComment from '@entities/api/comments/comments.consts'
import {useGetPublicationTextOnlyByUidQuery} from '@pages/Publication/gql/Publication.generated'
import {UiPublicationTextOnlyResult} from 'entities/types/UiPublication'
import {Publication} from '@graphql/types'
import Header from '@components/Common/Header/Header'
import {grayColor, lightGrayColor2, lightRedColor3} from '@theme/vars'
import {UiCommentsConnection} from 'entities/types/UiCommentsConnection'
import {UiPageInfo} from 'entities/types/UiPageInfo'
import InfiniteScrollWithScrollToTop from '@components/InfiniteScrollWithScrollToTop/InfiniteScrollWithScrollToTop'
import CommentItem from '../CommentItem/CommentItem'
import {useGetCommentsListLazyQuery} from '../gql/Comments.generated'
import {PUBLICATION_COMMENTS_CONTENT_CONSTS} from './PublicationCommentsContent.consts'
import {TextWrapper} from './PublicationCommentsContent.styles'
import {UiCommentsEdgeWithLevel} from './UiCommentsEdgeWithLevel'
import {commentEdgesTree} from './PublicationCommentsContent.helpers'

const PublicationCommentsContent: FC = () => {
  const {uid} = useParams<{uid: string}>()

  const history = useHistory()
  const [commentEdges, setCommentEdges] = useState<UiCommentsEdgeWithLevel[]>([])
  const [pageInfo, setPageInfo] = useState<UiPageInfo>({
    endCursor: '',
    hasNextPage: true,
    hasPreviousPage: false,
    startCursor: ''
  })
  const [nothingFounded, setNothingFounded] = useState(false)

  const [publicationData, setPublicationData] = useState<UiPublicationTextOnlyResult>()
  const [publicationContent, setPublicationContent] = useState('')
  const [showPublicationContent, setShowPublicationContent] = useState(true)

  const [getCommentsListLazyQuery, {loading, fetchMore, refetch}] = useGetCommentsListLazyQuery()

  const getCommentsListQueryAction = useCallback(async () => {
    const getCommentsListQueryResult = await getCommentsListLazyQuery({
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      variables: {
        publicationUid: uid
      }
    })
    if (getCommentsListQueryResult) {
      const getCommentsListData: UiCommentsConnection = getCommentsListAdapterToClient(
        getCommentsListQueryResult.data?.comments
      )

      setPageInfo(getCommentsListData.pageInfo)
      setNothingFounded(getCommentsListData.edges.length === 0 ? true : false)

      const newCommentStates =
        getCommentsListData.edges?.map((edge, index) => ({
          index: index,
          commentUid: edge.node?.uid || '',
          isBad: !!edge.node?.autoModeratorResult ? undefined : false
        })) || []

      const resEdjes: UiCommentsEdgeWithLevel[] = commentEdgesTree(getCommentsListData.edges)

      if (getCommentsListData.pageInfo.hasPreviousPage) {
        setCommentEdges(prev => [...prev, ...resEdjes])
        return
      }

      setCommentEdges(resEdjes)
    }
  }, [getCommentsListLazyQuery, uid])

  useEffect(() => {
    const timer = setTimeout(() => {
      setCommentEdges([])
      getCommentsListQueryAction()
    }, 500)
    return () => clearTimeout(timer)
  }, [getCommentsListQueryAction])

  useEffect(() => {
    setCommentEdges([])
    setPageInfo({
      endCursor: '',
      hasNextPage: true,
      hasPreviousPage: false,
      startCursor: ''
    })
    void refetch()
  }, [uid, refetch])

  const fetchMoreData = useCallback(() => {
    void fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first: 10
      }
    }).then(data => {
      if (data) {
        const getCommentsListData: UiCommentsConnection = getCommentsListAdapterToClient(data?.data.comments)

        setPageInfo(getCommentsListData.pageInfo)

        const resEdjes: UiCommentsEdgeWithLevel[] = commentEdgesTree(getCommentsListData.edges)

        if (getCommentsListData.pageInfo.hasPreviousPage) {
          setCommentEdges(prev => [...prev, ...resEdjes])
          return
        }

        setCommentEdges(resEdjes)
      }
    })
  }, [pageInfo.endCursor, fetchMore])

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const [pageHeight, setPageHeight] = useState('calc(100vh - 150px)')

  const moderateCommentsAction = useCallback(
    (ind: number) => {
      refetch().then(data => {
        if (data.data?.comments) {
          const getCommentsListData: UiCommentsConnection = getCommentsListAdapterToClient(data.data?.comments)

          setPageInfo(getCommentsListData.pageInfo)
          setNothingFounded(getCommentsListData.edges.length === 0 ? true : false)

          const newCommentStates =
            getCommentsListData.edges?.map((edge, index) => ({
              index: index,
              commentUid: edge.node?.uid || '',
              isBad: !!edge.node?.autoModeratorResult ? undefined : false
            })) || []

          const resEdjes: UiCommentsEdgeWithLevel[] = commentEdgesTree(getCommentsListData.edges)

          setCommentEdges(resEdjes)
        }
      })
    },
    [refetch]
  )

  const {refetch: refetchPublicationText, loading: publicationDataLoading} = useGetPublicationTextOnlyByUidQuery({
    variables: {uid},
    fetchPolicy: 'cache-and-network',
    onCompleted: ({publication}) => {
      if (publication) {
        const publicationDataValue: UiPublicationTextOnlyResult = getPublicationTextOnlyByUidAdapterToClient(
          publication as Publication
        )
        setPublicationData(publicationDataValue)
        setPublicationContent(publicationDataValue.content.elements.map(item => item.content).join(''))
      }
    }
  })

  useEffect(() => {
    console.log('uid', uid)
    refetchPublicationText()
  }, [uid, refetchPublicationText])

  return (
    <>
      {publicationData && (
        <Header
          title={publicationDataLoading ? PUBLICATION_COMMENTS_CONTENT_CONSTS.loading : `"${publicationData?.title}"`}
          onBack={goBack}
          // ref={headerRef}
          headerStyle={publicationData?.isAutomaticCommentsDisabled ? {backgroundColor: lightRedColor3} : undefined}
          buttonsBlock={
            <div>
              <Button variant='outlined' onClick={() => setShowPublicationContent(prev => !prev)} color='primary'>
                {showPublicationContent
                  ? PUBLICATION_COMMENTS_CONTENT_CONSTS.hidePublication
                  : PUBLICATION_COMMENTS_CONTENT_CONSTS.showPublication}
              </Button>
            </div>
          }
        />
      )}
      <Divider sx={{backgroundColor: lightGrayColor2, marginTop: '16px'}} />
      <InfiniteScrollWithScrollToTop
        dataLength={commentEdges?.length || 0}
        fetchMoreData={fetchMoreData}
        hasMore={!!pageInfo.hasNextPage}
        elementStyle={{
          height: pageHeight
        }}
      >
        <>
          {showPublicationContent && (
            <div>
              {publicationData?.publishedAt && (
                <Box marginTop={'16px'}>
                  <Typography variant='text14R' color={grayColor}>
                    {format(new Date(publicationData?.publishedAt), 'dd.MM.yyyy HH:mm')}
                  </Typography>
                </Box>
              )}
              <TextWrapper dangerouslySetInnerHTML={{__html: publicationContent}} />
              <Divider sx={{backgroundColor: lightGrayColor2, marginTop: showPublicationContent ? '36px' : '16px'}} />
            </div>
          )}
          {commentEdges.map((comment, ind) => (
            <CommentItem
              key={comment.node?.uid || 'comment-item'}
              ind={ind}
              comment={comment.node || defaultComment}
              publicationUid={uid}
              moderateCommentsAction={moderateCommentsAction}
              level={comment.level}
            />
          ))}
          {loading && <LinearProgress color='primary' />}
        </>
      </InfiniteScrollWithScrollToTop>
    </>
  )
}

export default PublicationCommentsContent
