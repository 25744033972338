import React, {FC} from 'react'
import {SvgIconProps} from '@mui/material'

export const DoNotDistrubIcon: FC<SvgIconProps> = props => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4476_39918)'>
        <path
          d='M9.99999 1.66797C5.41666 1.66797 1.66666 5.41797 1.66666 10.0013C1.66666 14.5846 5.41666 18.3346 9.99999 18.3346C14.5833 18.3346 18.3333 14.5846 18.3333 10.0013C18.3333 5.41797 14.5833 1.66797 9.99999 1.66797ZM3.33332 10.0013C3.33332 6.33464 6.33332 3.33464 9.99999 3.33464C11.5 3.33464 12.9167 3.83464 14.0833 4.7513L4.74999 14.0846C3.83332 12.918 3.33332 11.5013 3.33332 10.0013ZM9.99999 16.668C8.49999 16.668 7.08332 16.168 5.91666 15.2513L15.25 5.91797C16.1667 7.08464 16.6667 8.5013 16.6667 10.0013C16.6667 13.668 13.6667 16.668 9.99999 16.668Z'
          fill='#EB3846'
        />
      </g>
      <defs>
        <clipPath id='clip0_4476_39918'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
