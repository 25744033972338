import {Button} from '@mui/material'
import styled from 'styled-components'

export const DetailsRowSelects = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

export const AddButton = styled(Button)`
  margin-left: 12px;
`

export const SimpleFigureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 160px;
  border: 2px dashed #c4c4c4;
  margin: 0;
`

export const StyledImage = styled.img`
  min-width: 220px;
  height: 160px;
  margin-bottom: 8px;
`

export const SidebarPreviewWrapper = styled.div`
  border: 1px solid #ffdd00;
  margin-top: 30px;
  padding: 12px 16px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
