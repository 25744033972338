import {AccordionDetails, CircularProgress} from '@mui/material'
import React, {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {useStore} from '@stores/rootStoreContext'
import {compareOfTwoArraysWithPrimitives} from '@utils/compareOfTwoArraysWithPrimitives'
import {PublicationStatus} from '@graphql/types'
import {useUpdatePublicationRegionsMutation} from '../../gql/PublicationEditor.generated'
import {REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONSTS} from './RegionsWithOverviewsBlocksSelect.consts'
import {
  AccordionSummaryWrapper,
  AccordionWrapper,
  ArrowDropDownIconWrapper,
  RegionsSelectedTitlesWrapper,
  RegionsTitleWrapper
} from './RegionsWithOverviewsBlocksSelect.styles'
import {RegionsWithOverviewsBlocksSelectProps} from './RegionsWithOverviewsBlocksSelectProps'
import {RegionsWithOverviewsBlocksSelectController} from './RegionsWithOverviewsBlocksSelectController/RegionsWithOverviewsBlocksSelectController'

/**
 * Компонент выбора регионов с блоками на главной для публикации
 * @param param0
 * @returns
 */
const RegionsWithOverviewsBlocksSelect: FC<RegionsWithOverviewsBlocksSelectProps> = ({
  setHasOverviewBlock,
  refetchOverviewBlocks
}) => {
  const {publicationStore} = useStore()
  const {publication, setPublicationWithPrevState} = publicationStore
  const [expanded, setExpanded] = useState('regions-accordion')
  const [regions, setRegions] = useState<any[]>([])
  const [regionUids, setRegionUids] = useState<string[]>([])

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [updatePublicationRegions, {loading: updatePublicationRegionsLoading}] = useUpdatePublicationRegionsMutation()

  /**
   * Сохранение регионов в черновиках
   */
  useEffect(() => {
    if (
      [PublicationStatus.Draft, PublicationStatus.Review].includes(publication?.status) &&
      !compareOfTwoArraysWithPrimitives(regionUids || [], publication?.regions?.map(v => v?.uid) || [])
    ) {
      const timer = setTimeout(async () => {
        const variablesData = {
          publicationUid: publication.uid,
          regionUids: regionUids
        }

        const updatePublicationData = await updatePublicationRegions({
          variables: {
            data: variablesData
          }
        })

        if (!updatePublicationData?.errors?.length) {
          setPublicationWithPrevState(prev => ({
            ...prev,
            // @ts-ignore
            regions: updatePublicationData?.data?.updatePublication?.publication?.regions,
            regionUids: updatePublicationData?.data?.updatePublication?.publication?.regions?.map(v => v?.uid) || []
          }))
        }
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [
    regionUids,
    publication.uid,
    publication.title,
    publication.yandexTitle,
    publication.seoTitle,
    publication.status,
    publication.typeSlug,
    publication?.regions,
    updatePublicationRegions,
    setPublicationWithPrevState
  ])

  return (
    <AccordionWrapper square expanded={expanded === 'regions-accordion'} onChange={handleChange('regions-accordion')}>
      <AccordionSummaryWrapper aria-controls='regions-accordion-content' id='regions-accordion-header'>
        <RegionsTitleWrapper>
          <div>
            {REGIONS_WITH_OVERVIEW_BLOCKS_SELECT_CONSTS.regionsMsg}{' '}
            {updatePublicationRegionsLoading && <CircularProgress size='1rem' />}
          </div>
          <RegionsSelectedTitlesWrapper>
            {regions &&
              expanded !== 'regions-accordion' &&
              regions.map(
                region =>
                  regionUids.includes(region.uid) && (
                    <span key={region.uid}>
                      {region.title}
                      {region.position !== '' ? (
                        <span
                          style={{
                            fontWeight: '500',
                            marginLeft: '5px'
                          }}
                        >
                          {region.position}
                        </span>
                      ) : undefined}
                      <span>; </span>
                    </span>
                  )
              )}
          </RegionsSelectedTitlesWrapper>
        </RegionsTitleWrapper>
        <ArrowDropDownIconWrapper>
          <ArrowDropDownIcon
            fontSize='medium'
            style={{
              transform: `${expanded ? 'rotate(180deg)' : 'rotate(0deg)'}`
            }}
          />
        </ArrowDropDownIconWrapper>
      </AccordionSummaryWrapper>
      <AccordionDetails
        style={{
          padding: '0'
        }}
      >
        <RegionsWithOverviewsBlocksSelectController
          publicationRegionsLoading={updatePublicationRegionsLoading}
          setCurRegions={setRegions}
          setHasOverviewBlock={setHasOverviewBlock}
          refetchOverviewBlocks={refetchOverviewBlocks}
          setRegionUids={setRegionUids}
        />
      </AccordionDetails>
    </AccordionWrapper>
  )
}

export default observer(RegionsWithOverviewsBlocksSelect)
