import {createTheme} from '@mui/material/styles'
import {blueColor, lightblueColor, lightgrayColor, redColor, secondTextColor, whiteColor} from './vars'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    text24B: React.CSSProperties
    text20S: React.CSSProperties
    text20R: React.CSSProperties
    text20M: React.CSSProperties
    text18ExB: React.CSSProperties
    text18S: React.CSSProperties
    text18M: React.CSSProperties
    text18R: React.CSSProperties
    text18R130: React.CSSProperties
    text16S: React.CSSProperties
    text16M: React.CSSProperties
    text16R: React.CSSProperties
    text14M: React.CSSProperties
    text14R: React.CSSProperties
    text12M: React.CSSProperties
    text12R: React.CSSProperties
    text28M: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    text24B?: React.CSSProperties
    text20S?: React.CSSProperties
    text20R?: React.CSSProperties
    text20M?: React.CSSProperties
    text18ExB?: React.CSSProperties
    text18S?: React.CSSProperties
    text18M?: React.CSSProperties
    text18R?: React.CSSProperties
    text18R130?: React.CSSProperties
    text16S?: React.CSSProperties
    text16M?: React.CSSProperties
    text16R?: React.CSSProperties
    text14M?: React.CSSProperties
    text14R?: React.CSSProperties
    text12M?: React.CSSProperties
    text12R?: React.CSSProperties
    text28M?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    text24B: true
    text20S: true
    text20R: true
    text20M: true
    text18ExB: true
    text18S: true
    text18M: true
    text18R: true
    text18R130: true
    text16S: true
    text16M: true
    text16R: true
    text14M: true
    text14R: true
    text12M: true
    text12R: true
    text28M: true
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      light: lightblueColor,
      main: blueColor,
      dark: blueColor,
      contrastText: whiteColor
    },
    secondary: {
      light: lightgrayColor,
      main: secondTextColor,
      dark: secondTextColor,
      contrastText: whiteColor
    },
    error: {
      main: redColor
    }
  },
  typography: {
    fontFamily: 'Roboto',
    body1: {
      fontSize: '14px',
      fontWeight: 400
    },
    h1: {
      fontSize: '20px',
      fontWeight: 400
    },
    h2: {
      fontSize: '16px',
      fontWeight: 500
    },
    subtitle1: {
      fontSize: '12px',
      fontWeight: 400,
      color: secondTextColor
    },
    subtitle2: {
      fontSize: '14px',
      color: secondTextColor,
      fontWeight: 400,
      lineHeight: '16px'
    },
    text24B: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '120%'
    },
    text20S: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '120%'
    },
    text20R: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '120%'
    },
    text20M: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '120%'
    },
    text18ExB: {
      fontSize: '18px',
      fontWeight: 800,
      lineHeight: '130%'
    },
    text18S: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '130%'
    },
    text18M: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '130%'
    },
    text18R: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '140%'
    },
    text18R130: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '130%'
    },
    text16S: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '130%'
    },
    text16M: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '130%'
    },
    text16R: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '130%'
    },
    text14M: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '120%'
    },
    text14R: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '120%'
    },
    text12M: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '120%'
    },
    text12R: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '120%'
    },
    text28M: {
      fontSize: '28px',
      fontWeight: 500,
      lineHeight: '120%'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: '1.1876em',
          letterSpacing: '0.01071em'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '16px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minHeight: '42px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // height: '1.1876em',
          minHeight: '42px',
          padding: 0,
          paddingLeft: 0,
          paddingTop: '8.5px',
          paddingBottom: '8.5px',
          marginTop: 0
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: 'auto',
          '.MuiOutlinedInput-root': {
            padding: 0,
            marginTop: 0,
            paddingTop: '1px',
            paddingBottom: '1px',
            paddingLeft: '10px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#fff',
          padding: '8.5px 14px',
          paddingTop: '8.5px',
          paddingBottom: '8.5px',
          '&.Mui-focused': {
            borderWidth: 1
          },
          '&.MuiOutlinedInput-adornedEnd': {
            paddingRight: '14px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          marginLeft: 2,
          transform: 'translate(10px, 14px) scale(1.2)',
          '&.MuiInputLabel-shrink': {
            lineHeight: 1,
            transform: 'translate(14px, -8px) scale(1)'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          color: secondTextColor,
          margin: 0,
          marginLeft: 0,
          marginRight: 0
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'normal',
          fontSize: '14px',
          color: secondTextColor
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: lightgrayColor
        }
      }
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: 'p',
        text16R: 'span'
      }
    },
    MuiMenu: {
      disableScrollLock: true
    },
    MuiFormControl: {
      variant: 'outlined',
      size: 'small',
      styleOverrides: {}
    },
    MuiTextField: {
      variant: 'outlined',
      size: 'small'
    },
    MuiButton: {
      color: 'primary'
    },
    MuiSwitch: {
      color: 'primary'
    },
    MuiLinearProgress: {
      color: 'primary'
    }
  }
})
