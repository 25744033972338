import {Box, Button} from '@mui/material'
import React, {FC, useCallback, useState} from 'react'
import styled from 'styled-components'
import ImagePreview from '@components/UI/ImageEditor/ImagePreview/ImagePreview'
import ImageEditorModal from '@components/UI/ImageEditor/ImageEditorModal/ImageEditorModal'
import {useFormContext, useWatch} from 'react-hook-form'
import RHFInputWithLength from '@components/UI/RHFInputWithLength/RHFInputWithLength'

const EditorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 40px;
`

type BannerEditorProps = {
  updateBanner: () => void
  updateAllBanners: () => void
}

const BannerEditor: FC<BannerEditorProps> = ({updateBanner, updateAllBanners}) => {
  const [isOpenDialogImage, setIsOpenDialogImage] = useState(false)
  const {control, setValue} = useFormContext()
  const fields = useWatch({control})
  const titleLabel = 'Заголовок'
  const urlLabel = 'Ссылка'
  const descriptionLabel = 'Подпись к изображению'
  const toggleIsOpenDialogImage = useCallback(() => {
    setIsOpenDialogImage(!isOpenDialogImage)
  }, [isOpenDialogImage])
  return (
    <>
      <EditorWrapper>
        <Box width={82} height={82} borderRadius={4} mr={3} mt={2}>
          <ImagePreview
            url={fields.figure?.latestVersion?.primaryProcessedImage?.url}
            handleOpenDialog={toggleIsOpenDialogImage}
          />
        </Box>
        <Box marginTop='6px'>
          <Box alignSelf='center' display='flex'>
            <RHFInputWithLength name='url' label={urlLabel} width='240px' maxLength={255} value={fields.url} />
            <RHFInputWithLength
              name='title'
              label={titleLabel}
              width='240px'
              marginLeft='40px'
              maxLength={20}
              value={fields.title}
            />
          </Box>
          <RHFInputWithLength
            name='description'
            label={descriptionLabel}
            width='520px'
            marginTop='12px'
            maxLength={75}
            value={fields.description}
          />
          <Box display='flex' justifyContent='center'>
            <Button variant='outlined' style={{marginRight: '24px'}} onClick={updateAllBanners}>
              Сохранить для всех регионов
            </Button>
            <Button variant='contained' color='primary' onClick={updateBanner}>
              Сохранить
            </Button>
          </Box>
        </Box>
      </EditorWrapper>
      <ImageEditorModal
        modalId={fields.modalId || 'bannerEditorModalId'}
        defaultFigure={fields.figure}
        open={isOpenDialogImage}
        onClose={toggleIsOpenDialogImage}
        title='Вставка фото'
        text=''
        okLabel='Вставить'
        ratio={1 / 1}
        figureTypeSlug='banner'
        setValueFormContext={setValue}
      />
    </>
  )
}

export default BannerEditor
