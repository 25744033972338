enum ElementTypeNames {
  CustomHTMLElement = 'CustomHTMLElement',
  GalleryElement = 'GalleryElement',
  IFrameElement = 'IFrameElement',
  ImageElement = 'ImageElement',
  OnlineBroadcastElement = 'OnlineBroadcastElement',
  TextElement = 'TextElement'
}

export default ElementTypeNames
