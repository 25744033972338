export const IMAGE_EDITOR_MODAL_CONSTS = {
  altLabelMsg: 'Описание Alt',
  authorLabelMsg: 'Автор',
  characterLimitReachedMsg: 'Достигнут лимит по кол-ву символов',
  descriptionLabelMsg: 'Описание',
  diagonalWatermarkLabelMsg: 'Ватермарка на всё фото',
  fieldIsRequiredMsg: 'Поле обязательно к заполнению',
  fillRequiredFields: 'Заполните обязательные поля',
  fullWidthLabelMsg: 'Фото на всю ширину',
  isEmpty: 'Пусто',
  maxAltLength: 777,
  maxDescriptionLength: 300,
  maxLength: 250,
  maxAuthorLength: 250,
  setAnotherPhotoLabelMsg: 'Установить другое фото',
  warningMsg: 'Внимание',
  closeConfirmationText: 'Внесенные изменения не сохранятся. Вы уверены, что хотите закрыть окно?',
  closeConfirmBtn: 'Да, закрыть',
  makeChangeMsg: 'Нет изменений для сохранения'
}
